import React from "react";

export interface Notification {
    notification: any | null;
}

interface CurrentNotificationContextType {
    currentNotification: Notification | null;
    setCurrentNotification: (notification: Notification) => void;
}

const CurrentNotificationContext = React.createContext<CurrentNotificationContextType | null>(null);

export default CurrentNotificationContext;
