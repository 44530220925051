import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function GenericFooter({ companyName, companyUrl, email }) {
    const { t } = useTranslation();

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 py-4 py-md-5 text-center text-md-left">
                        <p className="small mb-1">
                            <b>{t("footer.Copyright")} ©</b> {new Date().getFullYear()}{" "}
                            <a href={companyUrl}>{companyName}</a>
                        </p>
                        <p className="small mb-1">
                            <b>{t("footer.Contact")}:</b> <a href={"mailto:" + email}>{email}</a>
                        </p>
                    </div>
                    <div className="col-12 col-md-8 py-3 py-md-5">
                        <ul className="nav justify-content-center justify-content-md-end">
                            <GdprConsentLink />
                            <GdprInfoLink />
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export function FooterLink({ children }) {
    return <li className="nav-item">{children}</li>;
}

export function GdprConsentLink() {
    const { t } = useTranslation();

    return (
        <FooterLink>
            <Link to={"/gdpr-consent"} className="nav-link">
                {t("about.Gdpr.Links.Consent")}
            </Link>
        </FooterLink>
    );
}

export function GdprInfoLink() {
    const { t } = useTranslation();

    return (
        <FooterLink>
            <Link className="nav-link" to={"/gdpr-information"}>
                {t("about.Gdpr.Links.Information")}
            </Link>
        </FooterLink>
    );
}

export function DownloadInformationsLink({ url, children }) {
    return (
        <FooterLink>
            <a href={url} className="nav-link" rel="noopener noreferrer" target="_blank">
                {children}
            </a>
        </FooterLink>
    );
}
