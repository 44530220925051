import TimeSchedule from "./TimeSchedule";
import SituationSchedule from "./SituationSchedule";
import ParamValueSchedule from "./ParamValueSchedule";
import { useSelector, useDispatch } from "react-redux";
import { prescriptionScheduleDelete } from "../../redux/prescriptionSlice";
import { scheduleTypes } from "../../codebooks/scheduleTypes";
import ScheduleParams from "./scheduleParams/ScheduleParams";
import tw from "twin.macro";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useTranslation } from "react-i18next";

export default function PrescriptionSchedule({ scheduleId, index, last }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const scheduleType = useSelector((state) => state.prescriptions.schedules.byId[scheduleId].$type);

    let ScheduleComponent = null;

    switch (scheduleType) {
        case scheduleTypes.time:
            ScheduleComponent = TimeSchedule;
            break;
        case scheduleTypes.situation:
            ScheduleComponent = SituationSchedule;
            break;
        case scheduleTypes.paramValue:
            ScheduleComponent = ParamValueSchedule;
            break;
        default:
            throw new Error(`Invalid schedule type ${scheduleType}`);
    }

    return (
        <div tw="flex">
            <div
                css={[
                    tw`flex-1 flex flex-wrap items-center p-2 gap-x-2 gap-y-3`,
                    index === 0 && tw`rounded-t-xl`,
                    last && tw`rounded-b-xl`,
                    index % 2 === 0 && tw`bg-gray-100`,
                ]}
            >
                <div tw="w-6">
                    <span tw="pl-2">{index + 1}.</span>
                </div>

                <ScheduleComponent scheduleId={scheduleId} index={index} />
                <ScheduleParams scheduleId={scheduleId} />

                <IconButton
                    color="primary"
                    size="small"
                    title={t("prescriptions.Edit.RemovePlan")}
                    sx={{ marginLeft: "auto" }}
                    onClick={() => dispatch(prescriptionScheduleDelete({ scheduleId }))}
                >
                    <DeleteIcon />
                </IconButton>
            </div>
        </div>
    );
}
