import MedilogApp from "./MedilogApp";
import { createMedilogMuiTheme } from "./createMedilogMuiTheme";
import { getLangsFromEnv } from "../../shared/getLangsFromEnv";

export function createMedilogProjectConfig() {
    return {
        app: MedilogApp,
        createTheme: createMedilogMuiTheme,
        getLangs: getLangsFromEnv,
    };
}
