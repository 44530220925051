import { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";
import { useFetch } from "../../utils/useFetch";

export default function UpdateData({ externalServicesSettings, selectedDateTime, onDataUpdated }) {
    const { apiGet, apiPost, apiErrors } = useFetch();
    const { t } = useTranslation();
    const [updatePossible, setUpdatePossible] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [dataUpdateResult, setDataUpdateResult] = useState(null);

    useEffect(() => {
        async function fetchUpdatePossible() {
            const response = await apiGet("/web/external-services/update-possible");
            if (response.isOk && response.json === true) setUpdatePossible(true);
        }

        fetchUpdatePossible();
    }, []); // eslint-disable-line

    if (updatePossible === null) return null;

    if (isLoading) return <CircularProgress size={17} disableShrink tw="margin-left[12px] margin-right[11px]" />;

    return updatePossible ? (
        <Fragment>
            <Tooltip title={t("extConnectors.UpdateAllDataTitleContent")}>
                <IconButton color="primary" onClick={() => updateDataAsync()}>
                    <SyncIcon />
                </IconButton>
            </Tooltip>

            {dataUpdateResult && (
                <Dialog open onClose={close}>
                    <DialogCloseButton onClick={close} />
                    <DialogTitle>{t("extConnectors.UpdateAllDataModalTitle")}</DialogTitle>
                    <DialogContent>
                        <ul tw="list-disc">
                            {dataUpdateResult.map((item) => {
                                const name = t(`extConnectors.Status.Names.${item.serviceType}`, item.serviceType);
                                const result = t(
                                    `extConnectors.Status.Results.${item.updateResult}`,
                                    item.updateResult,
                                );

                                return (
                                    <li key={item.serviceType} tw="ml-4 mb-2 text-base">
                                        <strong>{name}:</strong> {result}
                                    </li>
                                );
                            })}
                        </ul>
                    </DialogContent>
                </Dialog>
            )}
        </Fragment>
    ) : null;

    async function updateDataAsync() {
        setIsLoading(true);

        let response = await apiPost("/web/external-services/update-data", {
            date: format(selectedDateTime, "yyyy-MM-dd"),
        });

        if (response.isOk) {
            setDataUpdateResult(response.json);
            onDataUpdated(selectedDateTime);
        }

        setIsLoading(false);
    }
    function close() {
        setDataUpdateResult(null);
    }
}
