export const parameterTypes = {
    number: "Number",
    range: "Range",
    text: "Text",
    time: "Time",
    mood: "Mood",
    radioButton: "RadioButton",
    recordDate: "RecordDate",
    imageMap: "ImageMap",
};
