import Fab from "@mui/material/Fab";
import { useDispatch } from "react-redux";
import { prescriptionCreate } from "../../redux/prescriptionSlice";
import AddIcon from "@mui/icons-material/Add";
import "twin.macro";
import { useTranslation } from "react-i18next";

export default function AddPrescriptionFab({ prescriptionType, groupId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <div tw="sticky bottom-4 mt-4 mr-4 text-right">
            <Fab
                variant="extended"
                size="small"
                color="primary"
                tw="normal-case"
                onClick={() =>
                    dispatch(
                        prescriptionCreate({
                            prescriptionType,
                            initProps: { groupId },
                            stateKey: groupId ? { groupId } : null,
                        }),
                    )
                }
            >
                <AddIcon />
                {t("prescriptions.List.New")}
            </Fab>
        </div>
    );
}
