import { useState, useEffect } from "react";
import { useFetch } from "@profilog/misc/utils/useFetch";

export default function useEnergyType() {
    const [energyType, setEnergyType] = useState();
    const { apiGet, apiPost, apiErrors } = useFetch();
    const [isSaved, setIsSaved] = useState();

    useEffect(() => {
        async function getEnergyType() {
            const response = await apiGet("/web/user/energy-type");
            if (response.isOk) {
                setEnergyType(response.json.energyType);
            }
        }

        getEnergyType();
    }, []);

    async function saveEnergyType(value) {
        setIsSaved();
        const response = await apiPost("/web/user/energy-type", { energyType: value });
        if (response.isOk) {
            setEnergyType(value);
            setIsSaved(true);
        }
    }

    return {
        energyType,
        saveEnergyType,
        isSaved,
        apiErrors,
    };
}
