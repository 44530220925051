import { useTranslation } from "react-i18next";
import ExtConnector from "./ExtConnector";
import useGarmin from "../hooks//useGarminConnect";
import { isConnected } from "../codebooks/externalConnectionStatus";

export default function GarminConnection({ readonly, Layout }) {
    const { t } = useTranslation();
    const { connect, disconnect, garminConnectConnectionInfo, ApiErrors, garminApiError } = useGarmin();

    return (
        <ExtConnector
            title={t("extConnectors.GarminConnect.Title")}
            infoText={t("extConnectors.GarminConnect.InfoText")}
            connected={isConnected(garminConnectConnectionInfo?.status)}
            account={garminConnectConnectionInfo?.accountName}
            connectButtonLabel={t("extConnectors.GarminConnect.ConnectButtonLabel")}
            disconnectButtonLabel={t("extConnectors.GarminConnect.DisconnectButtonLabel")}
            lastImportDate={garminConnectConnectionInfo?.lastImportDate}
            status={garminConnectConnectionInfo?.status}
            onConnectClick={connect}
            onDisconnectClick={disconnect}
            ApiErrors={ApiErrors}
            customError={garminApiError}
            readonly={readonly}
            Layout={Layout}
        />
    );
}
