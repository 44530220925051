import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import "./scss/main.scss";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import * as serviceWorker from "./serviceWorker";
import AppSettings, { initAppSettings } from "@profilog/front/app/AppSettings";
import { initFetchOptions } from "@profilog/misc/utils/FetchOptions";
import AuthOptions from "@profilog/misc/auth/AuthOptions";
import { initI18n } from "@profilog/misc/localization/i18n";
import supportedLangs from "./localization/SupportedLangs.json";
import enTranslation from "./localization/EnTranslation";
import csTranslation from "./localization/CsTranslation";
import skTranslation from "./localization/SkTranslation";
import ruTranslation from "./localization/RuTranslation";
import trTranslation from "./localization/TrTranslation";
import { ThemeProvider } from "@mui/material/styles";
import { projectConfigFactories } from "./projects/projectConfigFactories";
import { Global } from "@emotion/react";
import { GlobalStyles } from "twin.macro";
import { globalStyles } from "./layout/globalStyles";
import store from "@profilog/prescriptions/redux/store";

initAppSettings();

const projectConfigFactory = projectConfigFactories[AppSettings.project];
const projectConfig = projectConfigFactory();

const App = projectConfig.app;
const theme = projectConfig.createTheme();
const { projectLang, forcedLang } = projectConfig.getLangs();

initI18n({
    supportedLangs: supportedLangs.langs,
    resources: {
        en: { translation: enTranslation },
        cs: { translation: csTranslation },
        sk: { translation: skTranslation },
        ru: { translation: ruTranslation },
        tr: { translation: trTranslation },
    },
    projectLang,
    forcedLang,
});
initFetchOptions(AppSettings.apiUrl, AppSettings.project, AppSettings.version);

AuthOptions.isBearer = false;
if (AppSettings.isPatientPreview) {
    AuthOptions.isBearer = true;
    AuthOptions.isOneTimeLogin = true;
}

ReactDOM.render(
    <React.StrictMode>
        <GlobalStyles />
        <Global styles={globalStyles} />

        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <App />
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.NODE_ENV === "production") serviceWorker.register();
else serviceWorker.unregister();

initHead();

function initHead() {
    document.title = process.env.REACT_APP_TITLE;
}
