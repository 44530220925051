import { useTranslation } from "react-i18next";
import ExtConnector from "./ExtConnector";
import useStrava from "../hooks/useStrava";
import { isConnected } from "../codebooks/externalConnectionStatus";

export default function StravaConnection({ readonly, Layout, refresh, onDisconnect }) {
    const { t } = useTranslation();
    const { stravaConnectionInfo, connect, disconnect, ApiErrors, stravaApiError } = useStrava(refresh);

    const infoText = t("extConnectors.Strava.InfoText");

    return (
        <ExtConnector
            title={t("extConnectors.Strava.Title")}
            infoText={infoText}
            connected={isConnected(stravaConnectionInfo?.status)}
            account={stravaConnectionInfo?.userName}
            connectButtonLabel={t("extConnectors.Strava.ConnectButtonLabel")}
            disconnectButtonLabel={t("extConnectors.Strava.DisconnectButtonLabel")}
            lastImportDate={stravaConnectionInfo?.lastImportAt}
            status={stravaConnectionInfo?.status}
            onConnectClick={connect}
            onDisconnectClick={handleDisconnect}
            ApiErrors={ApiErrors}
            customError={stravaApiError}
            readonly={readonly}
            Layout={Layout}
        />
    );

    async function handleDisconnect() {
        await disconnect();
        if (onDisconnect) onDisconnect();
    }
}
