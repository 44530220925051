import { useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ValidationError from "../../errors/ValidationError";
import { useFetch } from "../../utils/useFetch";
import { getUsernameAndToken } from "../../utils/Url";
import PasswordInput from "./PasswordInput";
import { Navigate } from "react-router";
import { Button, TextField } from "@mui/material";
import { useAuth } from "@profilog/misc/auth/useAuth";

export default function ResetPassword({ hideUserName = false, setUserAndRedirect = true }) {
    const { t } = useTranslation();
    const {
        handleSubmit,
        errors,
        register,
        formState: { isSubmitting },
    } = useForm();
    const { apiPost, ApiErrors } = useFetch();
    const [wasPasswordChanged, setWasPasswordChanged] = useState(false);
    const { currentUserData, loginFromApiResponse } = useAuth();

    const userNameAndToken = getUsernameAndToken();

    if (wasPasswordChanged && currentUserData) return <Navigate to="/" replace />;

    return (
        <div tw="lg:w-6/12">
            {wasPasswordChanged ? (
                <p>Heslo bylo obnoveno</p>
            ) : (
                <Fragment>
                    <h2>{t("auth.ResetPassword.Title")}</h2>
                    <form onSubmit={handleSubmit(resetPassword)} tw="flex flex-col space-y-6">
                        <ApiErrors />
                        {!hideUserName && (
                            <TextField
                                label={t("global.Form.UserName")}
                                disabled
                                defaultValue={userNameAndToken.userName}
                                tw="w-full"
                            />
                        )}
                        <PasswordInput
                            label={t("auth.ResetPassword.NewPassword")}
                            name="rp-password"
                            autoComplete="off"
                            inputRef={register({ required: t("auth.PasswordRequired") })}
                            tw="w-full md:w-8/12"
                        />
                        <ValidationError errors={errors} name="rp-password" />
                        <Button type="submit" variant="contained" disabled={isSubmitting}>
                            {t("auth.ResetPassword.Reset")}
                        </Button>
                    </form>
                </Fragment>
            )}
        </div>
    );

    async function resetPassword(data) {
        let newPassword = data["rp-password"];

        const response = await apiPost("/web/user/reset-password", { ...userNameAndToken, newPassword: newPassword });
        if (response.isOk) {
            if (setUserAndRedirect) loginFromApiResponse(response);
            setWasPasswordChanged(true);
        }
    }
}
