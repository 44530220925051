import { prescriptionScheduleUpdate } from "../../redux/prescriptionSlice";
import { useSelector, useDispatch } from "react-redux";
import SituationComboBox from "../codebooks/SituationComboBox";

export default function SituationSchedule({ scheduleId }) {
    const dispatch = useDispatch();
    const situation = useSelector((state) => state.prescriptions.schedules.byId[scheduleId].situation);

    return (
        <div>
            <SituationComboBox
                value={situation}
                onChange={dispatchOptionChange}
                autocompleteProps={{ autoFocus: true }}
                tw="w-64"
            />
        </div>
    );

    function dispatchOptionChange(option) {
        dispatch(prescriptionScheduleUpdate({ scheduleId, updated: { situation: option } }));
    }
}
