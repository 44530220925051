import { configureStore } from "@reduxjs/toolkit";
import prescriptionReducer from "./prescriptionSlice";
import codebookReducers from "./codebooksSlice";

export default configureStore({
    reducer: {
        ...codebookReducers,
        prescriptions: prescriptionReducer,
    },
});
