import { useTranslation } from "react-i18next";
import VocallsAssistant from "../VocallsAssistant";
import { Fragment, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import VisibleParameters from "./VisibleParameters";
import AppSettings from "../../../app/AppSettings";

export default function ParametersSection({
    renderHeader,
    disableParameterChange,
    parameters,
    renderAddParameter,
    renderSaveButton,
    onSaveClick,
    isVocallsEnabled,
    isUserVocallsTester,
    getMedicalSubjectId,
    getRecordDate,
    onSaved,
    hideParameterInfo,
    onHideParameter,
    onSetValue,
    recordDate,
    setRecordDate,
    recordTime,
    setRecordTime,
    onValueChanged,
    renderBellowParams,
}) {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);

    return (
        <Fragment>
            {renderHeader}

            <VisibleParameters
                parameters={parameters}
                hideParameterInfo={hideParameterInfo}
                onHideParameter={onHideParameter}
                onSetValue={onSetValue}
                disableParameterChange={disableParameterChange}
                recordDate={recordDate}
                setRecordDate={setRecordDate}
                recordTime={recordTime}
                setRecordTime={setRecordTime}
                onValueChanged={onValueChanged}
            />

            {renderBellowParams && renderBellowParams()}

            <div tw="mt-5"></div>

            {disableParameterChange || parameters.addableParameters.filter((p) => !p.isHiddenInAdd).length === 0
                ? null
                : renderAddParameter}

            <div>
                <div tw="flex justify-end">
                    {!renderSaveButton ? (
                        <Button
                            variant="contained"
                            tw="w-full sm:w-9/12"
                            onClick={!AppSettings.isDev && AppSettings.isPatientPreview ? null : handleSaveClick}
                            disabled={saving || (!AppSettings.isDev && AppSettings.isPatientPreview)}
                        >
                            {saving && <CircularProgress size={22} tw="-ml-8 mr-2" />}
                            <Fragment>
                                {t("records.Edit.Save")}
                                {AppSettings.isDev && AppSettings.isPatientPreview && " [DEV]"}
                            </Fragment>
                        </Button>
                    ) : (
                        renderSaveButton(handleSaveClick)
                    )}
                </div>
            </div>
            {(isVocallsEnabled || isUserVocallsTester) && (
                <VocallsAssistant
                    onSaved={onSaved}
                    getMedicalSubjectId={getMedicalSubjectId}
                    getRecordDate={getRecordDate}
                />
            )}
        </Fragment>
    );

    async function handleSaveClick() {
        setSaving(true);
        try {
            if (onSaveClick) await onSaveClick();
        } finally {
            setSaving(false);
        }
    }
}
