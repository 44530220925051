import { Fragment, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function DeleteDialog({ trigger, title, children, onConfirmed }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            {trigger && trigger(() => setOpen(true))}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{title ?? t("global.Delete") + "?"}</DialogTitle>
                <DialogContent>{children}</DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={onConfirmed}>
                        {t("global.Delete")}
                    </Button>
                    <Button variant="contained" color="success" onClick={handleClose}>
                        {t("global.Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );

    function handleClose() {
        setOpen(false);
    }
}
