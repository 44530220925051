import { Fragment } from "react";
import MedicImage from "../../assets/images/medic.png";
import "./GenericRecordsLayout.scss";

export default function GenericRecordsLayout({ infoSection, recordEdit, visualization, medicImage = null }) {
    return (
        <Fragment>
            <section className="records-forms-section py-0 py-sm-5">
                <div className="container-sm">
                    <div className="row flex-column-reverse flex-lg-row align-items-start">
                        <div className="info-section d-none d-lg-block col-lg-6 align-self-end">
                            {infoSection && infoSection()}
                            <img src={medicImage ?? MedicImage} alt="Medic" className="medic" />
                        </div>
                        <div className="col-12 col-lg-6 ml-lg-auto p-0 p-md-3 mb-sm-4">
                            <div className="new-record-form">
                                <div className="new-record-form__body pb-2">{recordEdit()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-sm-n5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 rounded-lg bg-white shadow-lg">
                            <div>{visualization()}</div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}
