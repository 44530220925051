import DatePicker from "@profilog/misc/commons/components/DatePicker";
import { TextField } from "@mui/material";
import AppSettings from "@profilog/front/app/AppSettings";

export default function RecordDateParameter({ recordDate, setRecordDate, recordTime, setRecordTime }) {
    return (
        <div tw="flex justify-start items-center">
            <div tw="mr-2">
                <DatePicker
                    selected={recordDate}
                    onChange={(date) => setRecordDate(date)}
                    customInput={
                        <TextField
                            size="small"
                            tw="width[14ch]"
                            inputProps={{
                                readOnly: AppSettings.isMobileApp,
                            }}
                        />
                    }
                    isClearable={AppSettings.isMobileApp}
                />
            </div>
            <TextField type="time" size="small" value={recordTime} onChange={(e) => setRecordTime(e.target.value)} />
        </div>
    );
}
