import IonSlider from "../../../commons/ionSlider/IonSlider";
import { roundTo } from "round-to";

export default function IonSliderParameter({ primaryColorCss, parameter, onValueChanged }) {
    let extraClasses = null;
    let onInitialized = null;
    let onChange = null;
    let onPrettify = null;

    if (parameter.isValueNameDisplayed) {
        onPrettify = (number, isLabel) => onValueNamePrettify(parameter, number, isLabel);
        extraClasses = "irs-value-name";
    }

    if (parameter.type === "Mood") {
        extraClasses = "mood";
        onPrettify = () => "";
        onInitialized = onMoodChange;
        onChange = onMoodChange;
    }

    return (
        <div tw="flex-grow mx-2 sm:mx-0" css={primaryColorCss}>
            <IonSlider
                step={parameter.step}
                min={parameter.min}
                max={parameter.max}
                value={parameter.value}
                defaultValue={parameter.defaultValue}
                displayDecimals={parameter.displayDecimals}
                isNullAllowed={!parameter.isMandatory}
                gridNum={parameter.gridNum}
                isMinValueIgnored={parameter.isMinValueIgnored}
                onValueChanged={(newValue) => onValueChanged(parameter.id, newValue)}
                version={parameter.version}
                extraClasses={extraClasses}
                onInitialized={onInitialized}
                onChange={onChange}
                onPrettify={onPrettify}
            />
        </div>
    );
}

function onMoodChange(data) {
    data.slider.removeClass("smile1 smile2 smile3 smile4 smile5");
    data.slider.toggleClass("smile" + Math.round(data.from));
}

function onValueNamePrettify(parameter, number, isLabel) {
    number = roundTo(number, parameter.displayDecimals);
    if (!isLabel) return number;

    const desc = parameter.descs.find((d) => d.value === number);
    return desc && desc.name ? desc.name : number;
}
