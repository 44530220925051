export function stringToIntOrNull(value) {
    if (value === null || value === undefined) return null;

    const number = Number.parseInt(value);
    return Number.isInteger(number) ? number : null;
}

export function stringToFloatOrNull(value) {
    if (value === null || value === undefined) return null;

    const number = Number.parseFloat(value);
    return !Number.isNaN(number) ? number : null;
}

export function dateToISOStringOrNull(value) {
    if (value === undefined || value === null) return null;
    return value.toISOString();
}

export function stringToStringOrNull(value) {
    if (value === null || value.trim() === "" || value === undefined) return null;

    return value === "" ? null : value;
}

export function minutesToHmsString(value) {
    const truncedValue = Math.trunc(value);

    const hours = Math.trunc(truncedValue / 60);
    const minutes = truncedValue % 60;
    const secs = Math.trunc((value - truncedValue) * 60);

    let result = "";
    if (hours > 0) result += `${hours}h `;
    result += `${minutes}m `;
    if (secs > 0) result += `${secs}s`;

    return result;
}
