import { Fragment, useState } from "react";
import { Button, Collapse } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export default function ExtConnectorInfoText({ text }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    if (!text) return null;

    return (
        <Fragment>
            <Button
                onClick={() => setOpen(!open)}
                edge="left"
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                tw="pl-1 normal-case"
            >
                {t("extConnectors.ImportInfoTitle")}
            </Button>
            <Collapse in={open}>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </Collapse>
        </Fragment>
    );
}
