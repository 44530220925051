import { useEffect, useState } from "react";
import { useFetch } from "@profilog/misc/utils/useFetch";

export default function useProjectUserInitializationSettings() {
    const [settings, setSettings] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const { apiGet, ApiErrors } = useFetch();

    useEffect(() => {
        async function getSettings() {
            const result = await apiGet("/web/user/initialization/project-settings");
            if (result.isOk) {
                setSettings(result.json);
            }
            setIsLoading(false);
        }
        getSettings();
    }, []);

    return { settings, isLoading, ApiErrors };
}
