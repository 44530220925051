import { useTheme } from "@mui/system";
import HorizontalSliderParameter from "./HorizontalSliderParameter";
import IonSliderParameter from "./IonSliderParameter";
import RecordDateParameter from "./RecordDateParameter";
import TextParameter from "./TextParameter";
import ImageMapParameter from "./ImageMapParameter";
import { parameterTypes } from "@profilog/parameters/codebooks/parameterTypes";
import { css } from "twin.macro";
import AppSettings from "../../../app/AppSettings";

export default function ParamComponent({
    parameter,
    onValueChanged,
    recordDate,
    setRecordDate,
    recordTime,
    setRecordTime,
}) {
    const theme = useTheme();

    const primaryColorCss = css`
        .irs--round .irs-from,
        .irs--round .irs-to,
        .irs--round .irs-single,
        .hs-value {
            color: ${theme.palette.primary.main};
        }

        .irs--round .irs-bar,
        .hs-handle {
            background-color: ${theme.palette.primary.main};
        }

        .irs--round .irs-handle,
        .hs-handle {
            border-color: ${theme.palette.primary.main};
        }
    `;

    switch (parameter.type) {
        case parameterTypes.number:
        case parameterTypes.time:
            return (
                <HorizontalSliderParameter
                    primaryColorCss={primaryColorCss}
                    parameter={parameter}
                    onValueChanged={onValueChanged}
                />
            );
        case parameterTypes.range:
        case parameterTypes.mood:
            return (
                <IonSliderParameter
                    primaryColorCss={primaryColorCss}
                    parameter={parameter}
                    onValueChanged={onValueChanged}
                />
            );

        case parameterTypes.text:
            return <TextParameter parameter={parameter} onValueChanged={onValueChanged} />;

        case parameterTypes.recordDate:
            return (
                <RecordDateParameter
                    recordDate={recordDate}
                    setRecordDate={setRecordDate}
                    recordTime={recordTime}
                    setRecordTime={setRecordTime}
                />
            );

        case parameterTypes.imageMap:
            return <ImageMapParameter parameter={parameter} onChange={onValueChanged} />;

        default:
            return null;
    }
}
