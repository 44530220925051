import { useTranslation } from "react-i18next";
import RecordsVisualization from "@profilog/misc/records/RecordsVisualization";
import AppSettings from "@profilog/front/app/AppSettings";

const RecordsReviewComponent = ({
    reviewData,
    recordsVisualizationFetchService,
    linkGenerated,
    recipientEmailAddress,
    isUserContactIncluded,
    isGraphVisible,
}) => {
    const { t, i18n } = useTranslation();

    function createUserContactInfo(reviewData) {
        let info = reviewData.userFullName;
        if (reviewData.userPhoneNumber?.length > 0) {
            if (info.length > 0) info += ", ";
            info += `${t("sharing.Review.Phone")}: ${reviewData.userPhoneNumber}`;
        }
        console.log("user: " + info);

        return info;
    }

    function createMedicalSubjectInfo(reviewData) {
        console.log(reviewData);

        let info = "";
        if (reviewData.medicalSubjectGender === "Man")
            info = reviewData.isMedicalSubjectAnimal ? t("global.Gender.Animal.Male") : t("global.Gender.Human.Male");
        else if (reviewData.MedicalSubjectGender === "Woman")
            info = reviewData.isMedicalSubjectAnimal
                ? t("global.Gender.Animal.Female")
                : t("global.Gender.Human.Female");

        if (reviewData.medicalSubjectYearOfBirth != null) {
            if (info.length > 0) info += ", ";
            info += t("sharing.Review.YearOfBirth").replace("{0}", reviewData.medicalSubjectYearOfBirth);
        }
        if (reviewData.medicalSubjectCity != null) {
            if (info.length > 0) info += ", ";
            info += reviewData.medicalSubjectCity;
        }
        if (reviewData.animalMedicalSubjectSpecies != null) {
            if (info.length > 0) info += ", ";
            info += reviewData.animalMedicalSubjectSpecies;
        }
        if (reviewData.medicalSubjectCountryName != null) {
            if (info.length > 0) info += ", ";
            info += reviewData.medicalSubjectCountryName;
        }

        console.log("selected: " + info);
        return info;
    }

    return (
        <section className="univ__inbody univ__inbody--bglight">
            <div className="container">
                <div className="col-12">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t("sharing.Review.AccessSent")
                                .replace(
                                    "{0}",
                                    new Date(linkGenerated).toLocaleDateString(i18n.language, {
                                        month: "long",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                    }),
                                )
                                .replace("{1}", reviewData.userEmail)
                                .replace(
                                    "{2}",
                                    reviewData.isUserEmailConfirmed
                                        ? t("sharing.Review.EmailConfirmed")
                                        : t("sharing.Review.EmailNotConfirmed"),
                                )
                                .replace("{3}", recipientEmailAddress),
                        }}
                    ></p>
                    <div className="row mb-3">
                        <div className="col-md-6 pt-2">
                            <p className="mb-1">
                                <strong>
                                    {reviewData.isSelectedUserAnimal
                                        ? t("sharing.Review.ObservedAnimalInfo")
                                        : t("sharing.Review.SelectedUserInfo")}
                                </strong>
                            </p>
                            {isUserContactIncluded && (
                                <p className="mb-1 text-primary">
                                    <i>{reviewData.medicalSubjectFullName}</i>
                                </p>
                            )}
                            {
                                <p className="text-primary">
                                    <i>{createMedicalSubjectInfo(reviewData)}</i>
                                </p>
                            }
                        </div>
                        {isUserContactIncluded && (
                            <div className="col-md-6 bg-silver pt-2">
                                <p className="mb-1">
                                    <strong>{t("sharing.Review.SenderAllowedContact")}:</strong>
                                </p>
                                <p className="text-primary">
                                    <i>
                                        {createUserContactInfo(reviewData)},
                                        <a href={` mailto: ${reviewData.userEmail}`}>{reviewData.userEmail}</a>
                                    </i>
                                </p>
                            </div>
                        )}
                    </div>
                    {reviewData.pastAnamnesis != null && (
                        <p className="mb-2">
                            <strong>{t("sharing.Review.PastDiseases")}:</strong>{" "}
                            <span className="text-primary">
                                <i>{reviewData.pastAnamnesis}</i>
                            </span>
                        </p>
                    )}
                    {reviewData.currentAnamnesis != null && (
                        <p className="mb-2">
                            <strong>{t("sharing.Review.CurrentDiseases")}:</strong>{" "}
                            <span className="text-primary">
                                <i>{reviewData.currentAnamnesis}</i>
                            </span>
                        </p>
                    )}
                    {reviewData.familyAnamnesis != null && (
                        <p className="mb-2">
                            <strong>{t("sharing.Review.FamilyDiseases")}:</strong>{" "}
                            <span className="text-primary">
                                <i>{reviewData.familyAnamnesis}</i>
                            </span>
                        </p>
                    )}
                </div>

                <div className="col-12">
                    <RecordsVisualization
                        fetchService={recordsVisualizationFetchService}
                        isGraphAlwaysVisible={isGraphVisible}
                        project={AppSettings.project}
                    />
                </div>
            </div>
        </section>
    );
};

export default RecordsReviewComponent;
