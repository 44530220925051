import AppSettings from "../../app/AppSettings";
import SurveyList from "@profilog/surveys/components/SurveyList";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function Surveys() {
    const { t } = useTranslation();
    return (
        <Fragment>
            <h2>{t("surveys.completed")}</h2>
            <SurveyList medicalSubjectId={AppSettings.user.userMedicalSubjectId} />
        </Fragment>
    );
}
