import { useTranslation } from "react-i18next";
import ExtConnector from "./ExtConnector";
import useOneTouch from "../hooks//useOneTouch";
import { Fragment } from "react";
import { isConnected } from "../codebooks/externalConnectionStatus";

export default function OneTouchConnection({ readonly, Layout }) {
    const { t } = useTranslation();
    const { connect, disconnect, oneTouchConnectionInfo, ApiErrors, errorCode } = useOneTouch();

    return (
        <Fragment>
            <ExtConnector
                title={t("extConnectors.OneTouch.Title")}
                connected={isConnected(oneTouchConnectionInfo?.status)}
                status={oneTouchConnectionInfo?.status}
                lastImportDate={oneTouchConnectionInfo?.lastImportedAt}
                connectButtonLabel={t("extConnectors.OneTouch.ConnectButtonLabel")}
                disconnectButtonLabel={t("extConnectors.OneTouch.DisconnectButtonLabel")}
                onConnectClick={connect}
                onDisconnectClick={disconnect}
                ApiErrors={ApiErrors}
                readonly={readonly}
                Layout={Layout}
            />
            {errorCode && (
                <div className="my-1 validation-error">
                    <ConnectError />
                </div>
            )}
        </Fragment>
    );

    function ConnectError() {
        switch (errorCode) {
            case "MissingCode":
                return t("extConnectors.OneTouch.Error");
            case "InvalidCode":
                return t("extConnectors.OneTouch.Error");
            default:
                return null;
        }
    }
}
