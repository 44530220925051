import { MenuItem, TextField } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import AppSettings from "../../app/AppSettings";

const MedicalSubjectSelector = ({
    personMedicalSubjects,
    animalMedicalSubjects,
    currentUserMedicalSubjectId,
    onMedicalSubjectChanged,
    selectedMedicalSubjectId,
}) => {
    const { t } = useTranslation();

    if (personMedicalSubjects.length === 0 && animalMedicalSubjects.length === 0) return null;

    return (
        <TextField
            select
            size="small"
            onChange={(e) => onMedicalSubjectChanged(e.target.value)}
            defaultValue={selectedMedicalSubjectId}
        >
            {currentUserMedicalSubjectId && !AppSettings.isPetLog && (
                <MenuItem value={currentUserMedicalSubjectId}>{t("persons.Me")}</MenuItem>
            )}
            {personMedicalSubjects.map((person) => (
                <MenuItem key={person.medicalSubjectId} value={person.medicalSubjectId}>
                    {person.name}
                </MenuItem>
            ))}
            {animalMedicalSubjects.length > 0 && (
                <Fragment>
                    {!AppSettings.isPetLog && <MenuItem disabled>───── {t("petlog.Animals")} ─────</MenuItem>}
                    {animalMedicalSubjects.map((animal) => (
                        <MenuItem key={animal.medicalSubjectId} value={animal.medicalSubjectId}>
                            {animal.name}
                        </MenuItem>
                    ))}
                </Fragment>
            )}
        </TextField>
    );
};

export default MedicalSubjectSelector;
