import App from "@profilog/misc/commons/components/App";
import Dashboard from "./pages/Dashboard";
import IcuDemoHome from "./pages/IcuDemoHome";
import AuthOptions from "@profilog/misc/auth/AuthOptions";
import CpzRegistration from "@profilog/front/registration/pages/Registration";
import CpzRegistrationWithInvitation from "@profilog/front/registration/pages/RegistrationWithInvitation";
import SuccessfullyRegistered from "@profilog/front/registration/pages/SuccessfullyRegistered";
import LoginCheck from "@profilog/misc/auth/components/LoginCheck";
import AppSettings, { initAppSettingsFromAppData } from "@profilog/front/app/AppSettings";
import { Outlet } from "react-router";
import NotFound from "../../NotFound";
import Prescriptions from "./pages/Prescriptions";
import AuthPublicPages from "@profilog/misc/auth/AuthPublicPages";
import SharedPublicPages from "../../shared/SharedPublicPages";
import "./IcuDemoLayout.scss";

export default function IcuDemoApp() {
    AuthOptions.isBearer = true;

    return (
        <App
            routes={[
                {
                    element: <NotLoggedInApp />,
                    children: [
                        { path: "/", element: <IcuDemoHome /> },
                        { path: "code", element: <CpzRegistrationWithInvitation /> },
                        { path: "code/:invitationCodeParam", element: <CpzRegistrationWithInvitation /> },
                        { path: "registration", element: <CpzRegistration /> },
                        { path: "successfully-registered", element: <SuccessfullyRegistered /> },
                    ],
                },
                { element: <NotLoggedInApp />, children: [...AuthPublicPages, ...SharedPublicPages] },
                {
                    path: "in",
                    element: <AutoLoginApp />,

                    children: [
                        { path: "dashboard", element: <Dashboard /> },
                        { path: "prescriptions", element: <Prescriptions /> },
                    ],
                },
                { path: "*", element: <NotFound /> },
            ]}
        />
    );
}

function NotLoggedInApp() {
    return <Outlet />;
}

function AutoLoginApp() {
    return (
        <LoginCheck
            onAppDataFetched={(appData) => initAppSettingsFromAppData(appData)}
            isGdprConsentModalUsed={!AppSettings.isPatientPreview}
            hardRedirect
            isDev={AppSettings.isDev}
        >
            <Outlet />
        </LoginCheck>
    );
}
