import { useParams } from "react-router";
import { getSurveyRegistrations } from "@profilog/surveys/surveysRegistry";
import { useScrollToTop } from "@profilog/commons/hooks/useScrollToTop";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function SurveySaved({ NotFound }) {
    const { t } = useTranslation();
    useScrollToTop();

    const { surveyUrlParam } = useParams();
    const reg = getSurveyRegistrations().find((r) => r.url === surveyUrlParam);

    if (!reg) return <NotFound />;

    return (
        <Fragment>
            <h2 tw="mb-5">{t(reg.name) ?? reg.name}</h2>
            {t("surveys.thanks")}
        </Fragment>
    );
}
