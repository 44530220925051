import PrescriptionIcon from "./types/PrescriptionIcon";
import tw from "twin.macro";

export default function PrescriptionContainer({ prescriptionType, buttons, children, footer = null, editing = false }) {
    return (
        <div tw="px-3 py-4 bg-gray-100 rounded-xl border border-solid border-gray-200">
            <div tw="flex flex-col md:flex-row gap-2 justify-between">
                <div tw="flex items-stretch">
                    <div css={[tw`text-gray-400 w-11 pt-4`, `padding-top: ${editing ? 14 : 0}px`]}>
                        <PrescriptionIcon type={prescriptionType} sx={{ width: 30, height: 30, padding: 0 }} />
                    </div>
                    {children}
                </div>
                <div>{buttons}</div>
            </div>
            {footer}
        </div>
    );
}
