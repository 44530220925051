import { MenuItem, TextField } from "@mui/material";

export default function EnergyTypeSelector({ title, selectedValue, onChange, ...props }) {
    const items = [
        { value: 1, name: "kJ" },
        { value: 2, name: "kcal" },
    ];

    return (
        <TextField size="small" select label={title} value={selectedValue} onChange={handleOnChange} fullWidth>
            {items.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                    {item.name}
                </MenuItem>
            ))}
        </TextField>
    );

    function handleOnChange(e) {
        if (!onChange) return;
        const value = parseInt(e.target.value);
        onChange(value);
    }
}
