import { useTranslation } from "react-i18next";
import ExtConnector from "./ExtConnector";
import useWithings from "../hooks//useWithings";
import { isConnected } from "../codebooks/externalConnectionStatus";

export default function WithingsConnection({ readonly, Layout }) {
    const { t } = useTranslation();
    const { withingsConnectionInfo, connect, disconnect, ApiErrors, withingsApiError } = useWithings();

    return (
        <ExtConnector
            providerName="Withings"
            title={t("extConnectors.Withings.Title")}
            infoText={t("extConnectors.Withings.InfoText")}
            connected={isConnected(withingsConnectionInfo?.status)}
            account={""}
            connectButtonLabel={t("extConnectors.Withings.ConnectButtonLabel")}
            disconnectButtonLabel={t("extConnectors.Withings.DisconnectButtonLabel")}
            lastImportDate={withingsConnectionInfo?.lastImportDate}
            status={withingsConnectionInfo?.status}
            onConnectClick={connect}
            onDisconnectClick={disconnect}
            ApiErrors={ApiErrors}
            customError={withingsApiError}
            readonly={readonly}
            Layout={Layout}
        />
    );
}
