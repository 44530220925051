const IsMobile = () => {
    const width = window.innerWidth ?? 0;
    const userAgent = window.navigator.userAgent ?? "";

    if (userAgent) {
        return (
            userAgent.match(/Android/i) ||
            userAgent.match(/webOS/i) ||
            userAgent.match(/iPhone/i) ||
            userAgent.match(/iPad/i) ||
            userAgent.match(/iPod/i) ||
            userAgent.match(/BlackBerry/i) ||
            userAgent.match(/Windows Phone/i) ||
            width < 767
        );
    }
    return width < 767;
};

export default IsMobile;
