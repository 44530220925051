import { weekDays, weekends, workweek } from "../../../codebooks/weekDays";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "twin.macro";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";

export default function WeekDaysPicker({ value, onChange }) {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div tw="text-center">
                <Button size="small" onClick={() => onChange(workweek)}>
                    {t("prescriptions.Edit.WorkingDays")}
                </Button>
                <Button size="small" onClick={() => onChange(weekends)}>
                    {t("prescriptions.Edit.Weekend")}
                </Button>
                <Button size="small" onClick={() => onChange(null)}>
                    {t("prescriptions.Edit.NoRestriction")}
                </Button>
            </div>
            <div tw="text-center">
                <ToggleButtonGroup
                    size="small"
                    value={weekDaysToArray(value)}
                    onChange={(_, value) => onChange(arrayToWeekDays(value))}
                >
                    {Object.keys(weekDays).map((key) => (
                        <ToggleButton key={key} value={key}>
                            {t("prescriptions.WeekDays." + weekDays[key])}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </div>
        </Fragment>
    );
}

function weekDaysToArray(value) {
    var result = [];
    for (let key in weekDays) if ((value & key) > 0) result.push(key);
    return result;
}

function arrayToWeekDays(array) {
    console.log(array);
    let result = 0;
    for (let n of array) result |= Number.parseInt(n);
    return result;
}
