import { css } from "twin.macro";
import { useTheme } from "@mui/material/styles";
import { forwardRef } from "react";

const InlineEditButton = forwardRef(({ children, ...props }, ref) => {
    const theme = useTheme();

    const buttonCss = css`
        color: ${theme.palette.primary.main};
        border-bottom-color: ${theme.palette.primary.main};
        &:hover {
            color: ${theme.palette.primary.light};
            border-bottom-color: ${theme.palette.secondary.light};
        }
    `;

    return (
        <button
            ref={ref}
            type="button"
            tw="m-0 p-0 bg-transparent border-0 border-b border-dashed
                outline-none focus:outline-none
                text-xs"
            css={buttonCss}
            {...props}
        >
            {children}
        </button>
    );
});

export default InlineEditButton;
