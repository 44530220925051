import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { apiPost } from "@profilog/misc/utils/useFetch";
import { getHostName } from "@profilog/misc/utils/Url";
import { Link } from "react-router-dom";

const LinkGenerationForm = ({ medicalSubjectId, isIncludeContactsCheckboxShown, onLinkGenerated }) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm();

    const [isContactCheckboxChecked, setIsContactCheckboxChecked] = useState(false);
    const [recipientEmailAddress, setRecipientEmailAddress] = useState("");
    const [isGenerateButtonDisabled, setIsGenerateButtonDisabled] = useState(true);

    const generateLink = async (data) => {
        let linkGenerationData = {
            medicalSubjectId: medicalSubjectId,
            recipient: data.recipient,
            isContactIncluded: data.isContactIncluded,
            baseUrl: getHostName() + process.env.PUBLIC_URL,
        };
        let generatedLink;
        let result = await apiPost("/sharing/generateLink", linkGenerationData);
        if (result.isOk) {
            generatedLink = result.json;
            onLinkGenerated(generatedLink);
        }
    };

    const handleContactCheckboxChange = (e) => {
        setIsContactCheckboxChecked(e.target.checked);
    };

    const handleRecipientChange = (e) => {
        setRecipientEmailAddress(e.target.value);
        if (e.target.value.length === 0) setIsGenerateButtonDisabled(true);
        else setIsGenerateButtonDisabled(false);
    };

    return (
        <Fragment>
            <p dangerouslySetInnerHTML={{ __html: t("sharing.LinkGenerationForm.Description") }}></p>

            <form onSubmit={handleSubmit(generateLink)}>
                <div className="row align-content-center no-gutters mt-3">
                    {isIncludeContactsCheckboxShown && (
                        <div className="form-group form-check col-12 pl-4">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="link_generation_include_contacts_checkbox"
                                onChange={handleContactCheckboxChange}
                                name="isContactIncluded"
                                ref={register}
                            />
                            <label className="form-check-label" htmlFor="link_generation_include_contacts_checkbox">
                                {t("sharing.LinkGenerationForm.IncludeContacts")}
                            </label>
                        </div>
                    )}

                    <p
                        dangerouslySetInnerHTML={{
                            __html: `${t("sharing.LinkGenerationForm.AccessOverview")} <br /> ${t(
                                "sharing.LinkGenerationForm.RevokeAccess",
                            )}`,
                        }}
                    ></p>
                </div>

                <div className="row mt-4 align-items-end no-gutters">
                    <div className="form-group col-12 col-sm-8">
                        <label htmlFor="link_generation_recipient_email">{t("sharing.LinkGenerationForm.Whom")}</label>
                        <input
                            type="text"
                            id="link_generation_recipient_email"
                            className="form-control"
                            placeholder={t("sharing.LinkGenerationForm.RecipientPlaceholder")}
                            onChange={handleRecipientChange}
                            name="recipient"
                            ref={register}
                        />
                    </div>
                    <div className="form-group col-12 col-sm-4">
                        <button
                            type="submit"
                            className="ml-0 ml-sm-2 btn btn-primary px-4 w-100"
                            disabled={isGenerateButtonDisabled || isSubmitting}
                        >
                            {t("sharing.LinkGenerationForm.GenerateLink")}
                        </button>
                    </div>
                </div>
            </form>

            <div className="mt-3">
                <Link
                    to={`/in/records-review-preview?&medicalSubjectId=${
                        medicalSubjectId !== null ? medicalSubjectId : ""
                    }&recipientEmailAddress=${recipientEmailAddress}&isUserContactIncluded=${isContactCheckboxChecked}`}
                    target="_blank"
                >
                    {t("sharing.LinkGenerationForm.SeePreview")}
                </Link>
            </div>
        </Fragment>
    );
};

export default LinkGenerationForm;
