import { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../utils/useFetch";
import { getHostName } from "../../utils/Url";
import ValidationError from "../../errors/ValidationError";
import { Alert, Button, Chip, TextField } from "@mui/material";
import { useAuth } from "@profilog/misc/auth/useAuth";

export default function EmailChange() {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        errors,
        watch,
        formState: { isSubmitting },
    } = useForm();
    const [isSaved, setIsSaved] = useState(false);
    const { currentUserData, loginFromApiResponse } = useAuth();
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(null);
    const [wasEmailConfirmationSent, setWasEmailConfirmationSent] = useState(false);
    const { apiGet, apiPost, ApiErrors } = useFetch();
    const watchEmail = watch("email");

    const isEmailDifferent = watchEmail !== currentUserData.userName;

    useEffect(() => {
        async function getEmailConfirmationStatus() {
            const response = await apiGet("/web/user/email-confirmation-status");
            if (response.isOk) {
                setIsEmailConfirmed(response.json.isConfirmed);
            }
        }

        getEmailConfirmationStatus();
        // eslint-disable-next-line
    }, [currentUserData.userName]);

    return (
        <form onSubmit={handleSubmit(changeEmail)}>
            <TextField
                size="small"
                label={t("userSettings.Email")}
                name="email"
                inputRef={register({ required: t("auth.EmailRequired") })}
                defaultValue={currentUserData?.userName}
                fullWidth
            />
            <ValidationError errors={errors} name="email" />
            {!isEmailDifferent && (
                <Fragment>
                    {isEmailConfirmed === null && <div>{t("userSettings.EmailConfirmation.CheckingStatus")}</div>}
                    {isEmailConfirmed === true && (
                        <Chip
                            size="small"
                            color="success"
                            tw="mt-2"
                            label={t("userSettings.EmailConfirmation.Confirmed")}
                        />
                    )}
                    {isEmailConfirmed === false && !wasEmailConfirmationSent && (
                        <Button onClick={sendEmailConfirmationEmail} tw="mt-2">
                            {t("userSettings.EmailConfirmation.Confirm")}
                        </Button>
                    )}
                    {isEmailConfirmed === false && wasEmailConfirmationSent && (
                        <Fragment>
                            <div tw="mt-2">{t("userSettings.EmailConfirmation.Sent")}</div>
                            <Button onClick={sendEmailConfirmationEmail} tw="mt-2">
                                {t("userSettings.EmailConfirmation.SendAgain")}
                            </Button>
                        </Fragment>
                    )}
                </Fragment>
            )}
            <ApiErrors />
            {isEmailDifferent && (
                <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    disabled={isSubmitting}
                    tw="mt-2"
                    onClick={changeEmail}
                >
                    {t("userSettings.EmailChange.Change")}
                </Button>
            )}
            {isSaved && (
                <Alert severity="success" tw="mt-2">
                    {t("userSettings.EmailChange.Changed")}
                </Alert>
            )}
        </form>
    );

    async function changeEmail(data) {
        resetState();

        const response = await apiPost("/web/user/change-email", {
            email: data.email,
        });

        if (response.isOk) {
            setIsSaved(true);

            loginFromApiResponse(response);
        }
    }

    async function sendEmailConfirmationEmail() {
        resetState();
        setWasEmailConfirmationSent(false);

        const response = await apiPost("/web/user/send-email-confirmation-email", {
            email: currentUserData.userName,
            hostName: getHostName(),
        });
        if (response.isOk) {
            setWasEmailConfirmationSent(true);
        }
    }

    function resetState() {
        setIsSaved(false);
        setWasEmailConfirmationSent(false);
    }
}
