import { useTranslation } from "react-i18next";
import ExtConnector from "./ExtConnector";
import useGoogleFit from "../hooks//useGoogleFit";
import GoogleSignInButton from "../../auth/components/GoogleSignInButton";
import { CircularProgress } from "@mui/material";
import { isConnected } from "../codebooks/externalConnectionStatus";

export default function GoogleFitConsent({ readonly, refresh, useGoogleBrand, Layout, onDisconnect }) {
    const { t } = useTranslation();
    const { connect, disconnect, googleFitConnectionInfo, gettingState, ApiErrors } = useGoogleFit(refresh);

    if (gettingState) return <CircularProgress />;
    if (
        googleFitConnectionInfo?.status &&
        !isConnected(googleFitConnectionInfo.status) &&
        !googleFitConnectionInfo.allowNewConnections
    )
        return null;

    return (
        <ExtConnector
            title={t("extConnectors.GoogleFit.Title")}
            infoText={t("extConnectors.GoogleFit.InfoText")}
            connected={isConnected(googleFitConnectionInfo?.status)}
            status={googleFitConnectionInfo?.status}
            account={googleFitConnectionInfo?.accountEmail}
            connectButtonLabel={t("extConnectors.GoogleFit.ConnectButtonLabel")}
            disconnectButtonLabel={t("extConnectors.GoogleFit.DisconnectButtonLabel")}
            onConnectClick={connect}
            onDisconnectClick={handleDisconnect}
            ApiErrors={ApiErrors}
            readonly={readonly}
            ConnectButton={useGoogleBrand ? GoogleSignInButton : null}
            Layout={Layout}
        />
    );

    async function handleDisconnect() {
        await disconnect();
        if (onDisconnect) onDisconnect();
    }
}
