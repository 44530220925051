import { useTranslation } from "react-i18next";
import FormSelector from "./FormSelector";

// DEPRECATED, už nepoužívat, místo toho použít linked/commons/components/GenderSelectot.jsx
const GenderSelector = ({ id, selectedValue, name, formRegister, className, disabled }) => {
    const { t } = useTranslation();
    const genders = getGenders(t);

    return (
        <FormSelector
            id={id}
            items={genders}
            selectedValue={selectedValue}
            name={name}
            formRegister={formRegister}
            className={className}
            disabled={disabled}
        />
    );
};

export function getGenders(t) {
    return [
        { value: 0, name: t("global.Gender.Human.Male") },
        { value: 1, name: t("global.Gender.Human.Female") },
        { value: "undefined", name: t("global.Gender.Undefined") },
    ];
}

export default GenderSelector;
