import { useContext, useState } from "react";
import { apiGet, apiPost } from "../utils/useFetch";
import CurrentUserContext from "./CurrentUserContext";
import AuthOptions from "./AuthOptions";
import { getQuery } from "../utils/Url";
import { UserData } from "./UserData";

let oneTimeLoginToken: string | null = null;

export function useAuth() {
    const [apiErrors, setApiErrors] = useState(null);
    const [loginTestState, setLoginTestState] = useState<boolean | null>(null);
    const { currentUserData, setCurrentUserData, isUserInitialized, setIsUserInitialized } =
        useContext(CurrentUserContext);
    const [isInvalidLogin, setIsInvalidLogin] = useState(false);

    return {
        login,
        loginWithUserToken,
        loginTest,
        loginTestState,
        logout,
        isInvalidLogin,
        apiErrors,
        resetApiErrors: () => setApiErrors(null),
        currentUserData,
        setCurrentUserData,
        loginFromApiResponse,
        isUserInitialized,
        setIsUserInitialized,
    };

    async function externalLoginTest() {
        if (AuthOptions.isBearer) {
            const token = getQuery().get("external-token");
            if (token !== null) {
                const response = await apiPost("/web/user/external-login-bearer-token", { token: token });
                if (handleResponse(response, true)) {
                    setLoginTestState(true);
                    return true;
                }

                window.history.replaceState(null, window.document.title, window.location.pathname);
            }
        }

        setLoginTestState(false);
        return false;
    }

    async function login(
        email: string,
        password: string,
        isPermanent: boolean,
        onInsteadOfSetContext: (userName: string) => void,
    ) {
        setApiErrors(null);
        setIsInvalidLogin(false);

        const url = AuthOptions.isBearer ? "/web/user/login-bearer" : "/web/user/login";

        const response = await apiPost(url, { email, password, isPermanent });
        handleResponse(response, isPermanent, onInsteadOfSetContext);
    }

    async function loginWithUserToken(userName: string, token: string) {
        let input = {
            userName: userName,
            token: token,
        };

        const url = AuthOptions.isBearer ? "/web/user/login-with-user-token-bearer" : "/web/user/login-with-user-token";

        const response = await apiPost(url, input);
        handleResponse(response, true);
    }

    async function loginTest() {
        const response: any = await apiGet("/web/appdata");
        if (response.isOk && !response.json.confictingProjectCode) {
            setLoginTestState(true);
            loginFromApiResponse(response);
        } else externalLoginTest();

        setLoginTestState(false);
    }

    async function logout() {
        const response: any = await apiPost("/web/user/logout");
        if (response.isOk) {
            if (AuthOptions.isBearer) {
                localStorage.removeItem("loginToken");
                sessionStorage.removeItem("loginToken");
            }

            setCurrentUserData(null);
        } else {
            setApiErrors(response.errors);
        }
    }

    function loginFromApiResponse(response: any, isPermanent: boolean = true) {
        return handleResponse(response, isPermanent);
    }

    function handleResponse(
        response: any,
        isPermanent: boolean,
        onInsteadOfSetContext: ((userName: string) => void) | null = null,
    ) {
        if (response.isOk) {
            if (AuthOptions.isOneTimeLogin) {
                oneTimeLoginToken = response.json.token;
            } else if (AuthOptions.isBearer) {
                if (isPermanent) localStorage.setItem("loginToken", response.json.token);
                else sessionStorage.setItem("loginToken", response.json.token);
            }

            if (onInsteadOfSetContext) {
                onInsteadOfSetContext(response.json.userName);
                return true;
            }

            let userData: UserData;
            if (response.json.userData)
                userData = {
                    ...response.json.userData,
                    roles: new Set(response.json.userData.roles),
                    permissions: new Set(response.json.userData.permissions),
                    requiredContractConsentProjectIds: new Set(
                        response.json.userData.requiredContractConsentProjectIds,
                    ),
                };
            else
                userData = {
                    id: response.json.id,
                    userName: response.json.userName,
                    projectCode: response.json.projectCode,
                    email: response.json.email,
                    medicalSubjectId: response.json.medicalSubjectId,
                    hasConsentedToLatestGdprTerms: response.json.hasConsentedToLatestGdprTerms,
                    requiredContractConsentProjectIds: new Set(response.json.requiredContractConsentProjectIds),
                    isInitialized: response.json.isInitialized,
                    roles: new Set(response.json.roles),
                    permissions: new Set(response.json.permissions),
                };

            setCurrentUserData(userData);
            return true;
        } else if (response.isUnauthorized) {
            setIsInvalidLogin(true);
        } else {
            setApiErrors(response.errors);
        }

        return false;
    }
}

export function getLoginToken() {
    return AuthOptions.isOneTimeLogin
        ? oneTimeLoginToken
        : sessionStorage.getItem("loginToken") ?? localStorage.getItem("loginToken");
}
