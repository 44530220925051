import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useFetch } from "../../utils/useFetch";
import ResetPasswordDialog from "../../auth/components/ResetPasswordDialog";
import ValidationError from "../../errors/ValidationError";
import PasswordInput from "../../auth/components/PasswordInput";
import { Alert, Button } from "@mui/material";
import { useAuth } from "@profilog/misc/auth/useAuth";

export default function PasswordChange() {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        errors,
        formState: { isSubmitting },
    } = useForm();
    const { apiPost, ApiErrors } = useFetch();
    const [isSaved, setIsSaved] = useState(false);
    const { currentUserData } = useAuth();

    return (
        <Fragment>
            <form onSubmit={handleSubmit(changePassword)}>
                <div>
                    <PasswordInput
                        size="small"
                        label={t("userSettings.PasswordChange.OldPassword")}
                        name="oldPassword"
                        inputRef={register({ required: t("userSettings.PasswordChange.OldPasswordRequired") })}
                        inputProps={{ autoComplete: "current-password" }}
                        fullWidth
                    />
                    <ValidationError errors={errors} name="oldPassword" />
                </div>
                <div>
                    <ResetPasswordDialog
                        getUserName={() => currentUserData.userName}
                        isUserNameDisabled={true}
                        trigger={(open) => <Button onClick={open}>{t("auth.Login.ForgotPassword")}</Button>}
                    />
                </div>
                <div tw="mt-2">
                    <PasswordInput
                        size="small"
                        label={t("userSettings.PasswordChange.NewPassword")}
                        name="newPassword"
                        inputRef={register({ required: t("userSettings.PasswordChange.NewPasswordRequired") })}
                        inputProps={{ autoComplete: "new-password" }}
                        fullWidth
                    />
                    <ValidationError errors={errors} name="newPassword" />
                </div>

                <ApiErrors />

                <Button type="submit" variant="contained" disabled={isSubmitting} tw="mt-4">
                    {t("userSettings.PasswordChange.Change")}
                </Button>

                {isSaved && (
                    <Alert severity="success" tw="mt-4">
                        {t("userSettings.PasswordChange.Changed")}
                    </Alert>
                )}
            </form>
        </Fragment>
    );

    async function changePassword(data) {
        setIsSaved(false);

        const response = await apiPost("/web/user/change-password", {
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
        });

        if (response.isOk) {
            setIsSaved(true);
        }
    }
}
