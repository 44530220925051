import FetchOptions from "../../utils/FetchOptions";
import { useEffect, useState } from "react";
import { useFetch } from "../../utils/useFetch";

export default function useGoogleFit(refresh) {
    const [gettingState, setGettingState] = useState(false);
    const [isConnecting, setIsConnecting] = useState(false);
    const [googleFitConnectionInfo, setGoogleFitConnectionInfo] = useState(null);
    const { apiPost, ApiErrors } = useFetch();

    async function connect(returnUrlQueryString) {
        setIsConnecting(true);
        window.location =
            FetchOptions.apiUrl +
            "/google-fit/connect?ReturnUrl=" +
            encodeURIComponent(
                `${window.location.protocol}//${window.location.host}${window.location.pathname}${
                    returnUrlQueryString ? `?${returnUrlQueryString}` : ""
                }`,
            );
    }

    async function disconnect() {
        const response = await apiPost("/google-fit/disconnect");
        if (response.isOk) await getGoogleFitStatus();
    }

    async function getGoogleFitStatus() {
        setGettingState(true);
        const response = await apiPost("/google-fit/get-state");
        if (response.isOk) {
            setGoogleFitConnectionInfo(response.json || {});
        }
        setGettingState(false);
    }

    useEffect(() => {
        getGoogleFitStatus();
    }, [refresh]); // eslint-disable-line

    return {
        connect,
        disconnect,
        isConnecting,
        googleFitConnectionInfo,
        gettingState,
        ApiErrors,
    };
}
