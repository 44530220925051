import { useFetch } from "@profilog/misc/utils/useFetch";
import { useState, useEffect } from "react";
export default function useCountryCallingCodes() {
    const { apiGet } = useFetch();
    const [isLoading, setIsLoading] = useState(true);
    const [callingCodes, setCallingCodes] = useState([]);

    useEffect(() => {
        async function getCallingCodes() {
            let response = await apiGet("/web/countries/calling-codes");
            if (response.isOk) {
                setCallingCodes(sortCallingCodes(response.json));
            }
            setIsLoading(false);
        }
        getCallingCodes();
    }, []);

    function sortCallingCodes(callingCodes) {
        const sortedCallingCodes = [];
        callingCodes?.forEach((item) => {
            sortedCallingCodes.push({
                value: item.countryId,
                name: `${item.code} (+${item.callingCode})`,
            });
        });
        return sortedCallingCodes.sort();
    }

    return { callingCodes, isLoading };
}
