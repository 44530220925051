import { parameterTypes } from "@profilog/parameters/codebooks/parameterTypes";
import { parseJsonArrayAddIds } from "@profilog/parameters/utils/parseJsonArrayAddIds";
import { stringifyArrayRemoveIds } from "@profilog/parameters/utils/stringifyArrayRemoveIds";
import { SaveRecordsRequestDto, FieldDto, RecordGroupDto } from "./SaveRecordsRequest";
import { SourceDto } from "./sources";

export function mapToValue(parameterType: string, numericValue: number, textValue: string): any {
    if (parameterType === parameterTypes.imageMap) return parseJsonArrayAddIds(textValue);
    else if (parameterType === parameterTypes.text) return textValue;
    else return numericValue;
}

export function createSaveRequest(medicalSubjectId: number, createSession: boolean = false): SaveRecordsRequestDto {
    return {
        medicalSubjectId,
        createSession,
        timeZoneIanaName: Intl.DateTimeFormat().resolvedOptions().timeZone,
        offsetFromUtcInMinutes: null,
        recordGroups: [],
    };
}

export function createRecordPanelManualSource(): SourceDto {
    return {
        origin: null,
        nodes: [
            { type: "web", channel: "rest", provider: "unknown", level: 1 }, // ideálně by zde jako privider mě být konkrétní web
            { type: "sdk", channel: "api", provider: "recordPanel", level: 2 },
            { type: "user", channel: "manualInput", provider: "user", level: 3 },
        ],
    };
}

export function createSurveyManualSource(): SourceDto {
    return {
        origin: null,
        nodes: [
            { type: "web", channel: "rest", provider: "unknown", level: 1 }, // ideálně by zde jako privider mě být konkrétní web
            { type: "sdk", channel: "api", provider: "survey", level: 2 },
            { type: "user", channel: "manualInput", provider: "user", level: 3 },
        ],
    };
}

export function mapToParameterWithRecord(
    parameterSystemName: string,
    parameterType: string,
    value: any,
    textValue: string | null,
    dateFrom: Date,
    dateTo: Date,
    source: SourceDto,
): RecordGroupDto {
    return {
        recordTypeName: parameterSystemName,
        utcFrom: dateFrom,
        utcTo: dateTo,
        source,
        records: [
            {
                utcStart: dateFrom,
                utcEnd: dateTo,
                fields: [mapToField(parameterType, value, textValue)],
            },
        ],
    };
}

function mapToField(parameterType: string, value: any, textValue: string | null): FieldDto {
    let numericValue: number | null = null;

    if (parameterType === parameterTypes.imageMap) textValue = stringifyArrayRemoveIds(value);
    else if (parameterType === parameterTypes.text && textValue === null) textValue = value;
    else numericValue = value;

    return {
        name: "Value",
        numericValue,
        textValue,
    };
}
