import { useEffect, useState } from "react";
import { useFetch } from "@profilog/misc/utils/useFetch";
import "twin.macro";
import { findNameWithUrlBySystemName } from "@profilog/surveys/surveysRegistry";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { DATETIME_FORMAT, DATE_FORMAT } from "@profilog/misc/commons/dateDefaults";
import { useTranslation } from "react-i18next";

export default function SurveyCalendar({ medicalSubjectId, Layout = EmptyLayout }) {
    const { t } = useTranslation();
    const [calendarItems, setCalendarItems] = useState(null);
    const { apiGet, ApiErrors, apiErrors } = useFetch();

    useEffect(() => {
        getCalendarItems();
    }, []); // eslint-disable-line

    if (apiErrors && apiErrors.length > 0)
        return (
            <Layout>
                <ApiErrors />
            </Layout>
        );

    if (!calendarItems || calendarItems.length === 0) return null;

    return (
        <Layout>
            <h2 tw="mb-2">{t("prescriptions.SurveyCalendar.Title")}</h2>
            <div tw="mb-2">{t("prescriptions.SurveyCalendar.Info")}:</div>
            {calendarItems &&
                calendarItems.map((item) => (
                    <div tw="flex items-center space-x-2">
                        <div tw="text-sm">{item.scheduledAtFmt}</div>
                        <Button
                            size="small"
                            tw="normal-case"
                            LinkComponent={Link}
                            to={"/in/surveys/" + item.survey.url + "/?date=" + item.date}
                        >
                            {t(item.survey.name)}
                        </Button>
                    </div>
                ))}
        </Layout>
    );

    async function getCalendarItems() {
        const response = await apiGet(`/web/prescriptions/calendar-surveys?medicalSubjectId=${medicalSubjectId}`);
        if (response.isOk)
            setCalendarItems(
                response.json
                    .filter((ci) => ci.discriminator === "Survey" && !ci.confirmedAt)
                    .map((ci) => ({
                        ...ci,
                        survey: findNameWithUrlBySystemName(ci.actionText),
                        scheduledAtFmt: ci.scheduledAt
                            ? format(new Date(ci.scheduledAt), ci.hasTime ? DATETIME_FORMAT : DATE_FORMAT)
                            : "",
                        date: ci.scheduledAt,
                    }))
                    .filter((ci) => ci.survey !== undefined),
            );
    }
}

function EmptyLayout({ children }) {
    return children;
}
