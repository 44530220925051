import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import useUserInitializationSteps, { STEPS } from "../hooks/useUserInitializationSteps";
import useProjectUserInitializationSettings from "../hooks/useProjectUserUnitializationSettings";
import PersonalInfoInitialization from "../components/PersonalInfoInitialization";
import ExternalConnectionsInitialization from "../components/ExternalConnectionsInitialization";
import CooperationRequestInitialization from "../components/CooperationRequestInitialization";

export default function UserInitialization() {
    const { t } = useTranslation();
    const {
        settings: projectSettings,
        isLoading: isSettingsLoading,
        ApiErrors: ApiSettingsError,
    } = useProjectUserInitializationSettings();
    const { currentStep, next, previous, existsNextStep, existsPreviousStep, ApiErrors } =
        useUserInitializationSteps(projectSettings);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStep]);

    if (isSettingsLoading) return null;
    if (!currentStep) return null;

    let stepComponent;
    switch (currentStep) {
        case STEPS.PERSONAL_INFO:
            stepComponent = (
                <PersonalInfoInitialization
                    projectSettings={projectSettings}
                    next={next}
                    existsNextStep={existsNextStep}
                />
            );
            break;
        case STEPS.EXTERNAL_CONNECTIONS:
            stepComponent = (
                <ExternalConnectionsInitialization
                    projectSettings={projectSettings}
                    next={next}
                    previous={previous}
                    existsNextStep={existsNextStep}
                    existsPreviousStep={existsPreviousStep}
                />
            );
            break;
        case STEPS.COOPERATION_REQUEST:
            stepComponent = (
                <CooperationRequestInitialization
                    previous={previous}
                    next={next}
                    existsPreviousStep={existsPreviousStep}
                />
            );
            break;
        default:
            break;
    }

    return (
        <Fragment>
            <h2>{t("userInitialization.UserInitialization.InitialProfileSettings")}</h2>
            <hr />
            <ApiErrors />
            <ApiSettingsError />
            {stepComponent}
        </Fragment>
    );
}
