import { Fragment, ReactNode, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from "@mui/material";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";
import PrescriptionNotificationSettings from "@profilog/front/user/components/PrescriptionNotificationSettings";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";

export interface NotificationSettingsDialogProps {
    trigger: (open: () => void) => ReactNode;
}

export default function NotificationSettingsDialog({ trigger }: NotificationSettingsDialogProps) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Fragment>
            {trigger && trigger(() => setOpen(!open))}
            <Dialog open={open} onClose={handleClose} fullWidth fullScreen={fullScreen}>
                <DialogCloseButton onClick={handleClose} />
                <DialogTitle>{t("userSettings.PrescriptionNotifications.Title")}</DialogTitle>
                <DialogContent>
                    <PrescriptionNotificationSettings />
                </DialogContent>
            </Dialog>
        </Fragment>
    );

    function handleClose() {
        setOpen(false);
    }
}
