import { SurveyProps } from "./components/SurveyProps";

const componentsBySystemName = new Map<string, React.FC<SurveyProps>>();
const componentsByUrl = new Map<string, React.FC<SurveyProps>>();
const namesWithUrl: Array<{ name: string; url: string }> = [];
const namesWithUrlBySystemName = new Map<string, { name: string; url: string }>();
const registrations: Array<SurveyRegistration> = [];

export interface SurveyRegistration {
    systemName: string;
    name: string;
    url: string;
    component: any;
}

export function addSurvey(reg: SurveyRegistration) {
    if (!reg) return;

    registrations.push(reg);

    componentsBySystemName.set(reg.systemName, reg.component);
    componentsByUrl.set(reg.url, reg.component);
    const nameWithUrl = { name: reg.name, url: reg.url };
    namesWithUrl.push(nameWithUrl);
    namesWithUrlBySystemName.set(reg.systemName, nameWithUrl);
}

export function findSurveyComponentBySystemName(systemName: string) {
    return componentsBySystemName.get(systemName);
}

export function findNameWithUrlBySystemName(systemName: string) {
    return namesWithUrlBySystemName.get(systemName);
}

export function findSurveyComponentByUrl(url: string) {
    return componentsByUrl.get(url);
}

export function getSurveyNamesWithUrl() {
    return namesWithUrl;
}

export function getSurveyRegistrations() {
    return registrations;
}
