import { useState, useEffect, useRef, Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "@profilog/misc/utils/useFetch";
import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    CommonSeriesSettings,
    Legend,
    Tooltip,
    ZoomAndPan,
    VisualRange,
    Animation,
    Grid,
    Border,
    MinorTick,
    MinorGrid,
    Title,
} from "devextreme-react/chart";
import ValidationError from "@profilog/misc/errors/ValidationError";
import { useForm } from "react-hook-form";
import { format, getYear, getDayOfYear, isLeapYear } from "date-fns";
import MilestoneEditModal from "./MilestoneEditModal";
import { Button, TextField, Link, Checkbox, FormControlLabel } from "@mui/material";
import CircularProgressMidParent from "@profilog/commons/components/CircularProgressMidParent";
import TooltipMui from "@mui/material/Tooltip";

export default function Milestones({ medicalSubjectId }) {
    const { t } = useTranslation();
    const { apiGet, apiPut, apiPost, ApiErrors } = useFetch();
    const [birthDate, setBirthdate] = useState(null);
    const [records, setRecords] = useState([]);
    const [recordTypeSettings, setRecordTypeSettings] = useState(null);
    const [milestones, setMilestones] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isBirthDateLoading, setIsBirthDateLoading] = useState(true);
    const [isZoomingEnabled, setIsZoomingEnabled] = useState(true);
    const [ageRange, setAgeRange] = useState(null);
    const { register, handleSubmit, errors } = useForm();
    const chartRef = useRef();
    const [milestoneInfo, setMilestoneInfo] = useState(null);
    const prevVisualRangeRef = useRef();

    async function getBirthdate() {
        setIsBirthDateLoading(true);
        const response = await apiGet("/web/user/birthdate");
        if (response.isOk) {
            if (response.json) {
                const birthDate = new Date(response.json);
                setAgeRange({ startValue: 0, endValue: getDifferenceInYears(birthDate, new Date()) });
                setBirthdate(birthDate);
            }
        }
        setIsBirthDateLoading(false);
    }

    async function getRecords() {
        const request = {
            medicalSubjectId: medicalSubjectId,
            utcFrom: birthDate.toISOString(),
            utcTo: new Date().toISOString(),
            ignorePagination: true,
            recordFilters: [
                {
                    recordTypeName: "WeightInKilograms", // TODO: podpora nového názvu váhy dle nového Recordu (až nové Recordy budou nasazeny)
                },
            ],
        };
        let rawRecords = [];
        let response = await apiPost("/v2/medical-subjects/records/get-raw", request);
        if (response.isOk) {
            rawRecords = rawRecords.concat(response.json.records);
            request.recordFilters = [
                {
                    recordTypeName: "Milestone",
                },
            ];
            response = await apiPost("/v2/medical-subjects/records/get-raw", request);
            if (response.isOk) {
                rawRecords = rawRecords.concat(response.json.records);
            }
        }
        if (rawRecords.length > 0) {
            const groupedRecords = rawRecords
                .map((r) => {
                    const date = new Date(r.utcStartAt);
                    return {
                        recordId: r.id,
                        recordTypeName: r.recordTypeName,
                        date: date,
                        value: r.fields.find((f) => f.name === "value").value,
                        textValue: r.fields.find((f) => f.name === "value").textValue,
                        dateAsNumber: getDifferenceInYears(birthDate, date),
                    };
                })
                .reduce((storage, item) => {
                    var group = item["dateAsNumber"];
                    storage[group] = storage[group] || [];
                    storage[group].push(item);
                    return storage;
                }, []);

            const recordsArray = Object.values(groupedRecords).map((g) => [...g]);

            const compositeRecords = recordsArray.map((g) => {
                const weightRecord = g.find((r) => r.recordTypeName === "WeightInKilograms");
                const milestoneRecord = g.find((r) => r.recordTypeName === "Milestone");
                const compositeRecord = {
                    date: weightRecord?.date ?? milestoneRecord.date,
                    dateAsNumber: weightRecord?.dateAsNumber ?? milestoneRecord.dateAsNumber,
                    weightRecordId: weightRecord?.recordId,
                    weight: weightRecord?.value ?? 0,
                    milestoneRecordId: milestoneRecord?.recordId,
                    milestoneId: milestoneRecord?.value,
                    milestoneText: milestoneRecord?.textValue,
                };
                return compositeRecord;
            });

            setRecords(compositeRecords);
        }
    }

    async function getRecordTypesSettings() {
        const response = await apiGet("/web/parameters");
        if (response.isOk) {
            const weightSettings = response.json.parameterSettings.find((s) => s.systemName === "WeightInKilograms");
            setRecordTypeSettings({ weight: weightSettings });
        }
    }

    async function getMilestones() {
        const response = await apiGet("/v1/medical-subjects/" + medicalSubjectId + "/milestones");

        if (response.isOk) {
            if (milestones) setMilestones(null);
            setMilestones(response.json);
        }
    }

    useEffect(() => {
        getBirthdate();
    }, []);

    useEffect(() => {
        async function getData() {
            setIsLoading(true);
            await Promise.all([getRecords(), getMilestones(), getRecordTypesSettings()]);
            setIsLoading(false);
        }

        if (!birthDate) {
            setIsLoading(false);
            return;
        }
        getData();
    }, [birthDate]);

    const clickEventHandlerCallback = useCallback(
        (e) => {
            e.stopPropagation();
            //nejde jednoduše zjistit souřadnice, kam se v grafu klikne, proto tento hack
            const startXValue = chartRef.current._instance._argumentAxes[0]._viewport.startValue;
            const endXValue = chartRef.current._instance._argumentAxes[0]._viewport.endValue;
            const startXPosition = chartRef.current._instance._argumentAxes[0]._getTranslatedCoord(startXValue);
            const endXPosition = chartRef.current._instance._argumentAxes[0]._getTranslatedCoord(endXValue);
            let differenceX = endXValue - startXValue;
            let pixelsX = endXPosition - startXPosition;
            let totalYears = startXValue + (differenceX / pixelsX) * (e.offsetX - startXPosition);
            let year = Math.round(totalYears);
            let date = new Date(birthDate);
            date.setFullYear(date.getFullYear() + year);
            let days = Math.round(getDays(date.getFullYear()) * (totalYears - year));
            date.setDate(date.getDate() + days);

            const startYValue = chartRef.current._instance._valueAxes[0]._initRange.startValue;
            const endYValue = chartRef.current._instance._valueAxes[0]._initRange.endValue;
            const startYPosition = chartRef.current._instance._valueAxes[0]._getTranslatedCoord(startYValue);
            const endYPosition = chartRef.current._instance._valueAxes[0]._getTranslatedCoord(endYValue);
            let differenceY = startYValue - endYValue;
            let pixelsY = startYPosition - endYPosition;
            let weightInKg = (differenceY / pixelsY) * (e.offsetY - startYPosition) + startYValue;

            setMilestoneInfoObject(null, null, date, Math.round(weightInKg * 10) / 10);
        },
        [birthDate],
    );

    useEffect(() => {
        //nejde jednoduše zjistit souřadnice, kam se v grafu klikne, proto tento hack
        if (chartRef.current) {
            if (isZoomingEnabled) {
                chartRef.current._element.removeEventListener("click", clickEventHandlerCallback);
            } else {
                chartRef.current._element.addEventListener("click", clickEventHandlerCallback);
            }
        }
    }, [isZoomingEnabled]);

    async function saveBirthdate(data) {
        setIsLoading(true);
        const response = await apiPut("/web/user/birthdate", data.birthDate);
        if (response.isOk) {
            setBirthdate(new Date(data.birthDate));
        }
        setIsLoading(false);
    }

    function handlePointClick(e) {
        e.event.stopPropagation();
        setMilestoneInfoObject(
            e.target.data.weightRecordId,
            e.target.data.milestoneRecordId,
            e.target.data.date,
            e.target.data.weight,
            e.target.data.milestoneId,
            e.target.data.milestoneText,
        );
    }

    function handleOptionChange(e) {
        if (
            e.fullName === "argumentAxis.visualRange" &&
            (ageRange?.startValue !== e.value.startValue || ageRange?.endValue !== e.value.endValue)
        ) {
            if (prevVisualRangeRef.current) prevVisualRangeRef.current = null;
            else setAgeRange({ startValue: e.value.startValue, endValue: e.value.endValue });
        }
    }

    function getDifferenceInYears(leftDate, rightDate) {
        return (
            getYear(rightDate) -
            getYear(leftDate) +
            (getDayOfYear(rightDate) - getDayOfYear(leftDate)) / (isLeapYear(rightDate) ? 366.0 : 365.0)
        );
    }

    function getDays(year) {
        return new Date(year, 1, 29).getDate() === 29 ? 366 : 365;
    }

    async function handleModalClosed(isOk) {
        setMilestoneInfo(null);
        if (isOk) {
            await getRecords();
        }
    }

    function customizeTooltip(pointInfo) {
        if (
            (!pointInfo.point.data.milestoneId && !pointInfo.point.data.milestoneText) ||
            pointInfo.seriesName !== "weight"
        ) {
            return { text: null };
        }

        let items = [];
        const dateElement = document.createElement("span");
        dateElement.style.paddingTop = 5;

        const element = document.createElement("span");
        element.textContent = `${t("userSettings.Milestones.Weight")}: ${
            pointInfo.point.data.weight > 0 ? pointInfo.point.data.weight + " kg" : "--"
        }`;
        items.push(element.outerHTML);
        let text;
        if (pointInfo.point.data.milestoneId)
            text = milestones.find((x) => x.id === pointInfo.point.data.milestoneId)?.name;
        element.textContent = `${t("userSettings.Milestones.Milestone")}: ${
            text ?? pointInfo.point.data.milestoneText ?? "--"
        }`;
        items.push(element.outerHTML);
        const years = Math.round(pointInfo.point.data.dateAsNumber * 100) / 100;
        element.textContent = `${t("userSettings.Milestones.Age")}: ${years}`;
        items.push(element.outerHTML);
        dateElement.textContent = format(new Date(pointInfo.point.data.date), "dd.MM.yyyy");
        items.push(dateElement.outerHTML);
        return { text: items.join(" <br />") };
    }

    function setMilestoneInfoObject(
        weightRecordId = null,
        milestoneRecordId = null,
        date = null,
        weight = null,
        milestoneId = null,
        milestoneText = null,
    ) {
        weight = weight === 0 ? null : weight;
        setMilestoneInfo({
            weightRecordId,
            milestoneRecordId,
            date,
            weight,
            milestoneId,
            milestoneText,
        });
    }

    function customizePoint(arg) {
        if ((!arg.data.milestoneId && !arg.data.milestoneText) || arg.series.index > 1) {
            return { size: 5 };
        }
    }

    if (isBirthDateLoading || isLoading)
        return (
            <Fragment>
                <div tw="mr-1 mt-1">
                    <ApiErrors />
                    <CircularProgressMidParent />
                </div>
            </Fragment>
        );

    if (!birthDate) {
        return (
            <Fragment>
                <form onSubmit={handleSubmit(saveBirthdate)} tw="pl-12 pb-5 flex">
                    <div tw="pr-5">
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            id="birthDate"
                            name="birthDate"
                            inputRef={register({ required: t("global.Form.RequiredField") })}
                            label={t("persons.Birthdate")}
                            helperText={t("userSettings.Milestones.BirthDateText")}
                        />
                        <ValidationError errors={errors} name="birthdate" />
                    </div>
                    <div>
                        <Button size="large" variant="contained" type="submit">
                            {t("global.Save")}
                        </Button>
                    </div>
                </form>
            </Fragment>
        );
    }

    let weightRange;
    if (birthDate) {
        weightRange = {
            startValue: Math.min(...records.filter((r) => r.weight >= 0).map((r) => r.weight)) / 1.05,
            endValue: Math.max(...records.filter((r) => r.weight >= 0).map((r) => r.weight)) * 1.05,
        };
    }

    return (
        <Fragment>
            <div tw="mr-1 mt-1">
                <ApiErrors />
                {/* {isLoading || (!birthDate && <CircularProgressMidParent />)} */}
                {
                    <Fragment>
                        {/* {!birthDate && (
                            <form onSubmit={handleSubmit(saveBirthdate)} tw="pl-12 pb-5 flex">
                                <div tw="pr-5">
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        type="date"
                                        id="birthDate"
                                        name="birthDate"
                                        inputRef={register({ required: t("global.Form.RequiredField") })}
                                        label={t("persons.Birthdate")}
                                        helperText={t("userSettings.Milestones.BirthDateText")}
                                    />
                                    <ValidationError errors={errors} name="birthdate" />
                                </div>
                                <div>
                                    <Button size="large" variant="contained" type="submit">
                                        {t("global.Save")}
                                    </Button>
                                </div>
                            </form>
                        )} */}
                        {birthDate && (
                            <Fragment>
                                <div>
                                    <div tw="pl-5 float-left">
                                        <Link href="#" tw="pt-1" onClick={(x) => setMilestoneInfoObject()}>
                                            {t("userSettings.Milestones.WeightOutOfRangeText")}
                                        </Link>
                                    </div>
                                    <div tw="pr-5 float-right">
                                        <TooltipMui
                                            title={t("userSettings.Milestones.ZoomEnabledToolTip")}
                                            placement="top"
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tw="p-0"
                                                        checked={isZoomingEnabled}
                                                        onChange={(e) => {
                                                            prevVisualRangeRef.current = ageRange;
                                                            setIsZoomingEnabled(e.target.checked);
                                                        }}
                                                    />
                                                }
                                                label={t("userSettings.Milestones.ZoomEnabled")}
                                            />
                                        </TooltipMui>
                                    </div>
                                </div>
                                <Chart
                                    id="chart"
                                    ref={chartRef}
                                    dataSource={records}
                                    adjustOnZoom={false}
                                    onPointClick={handlePointClick}
                                    customizePoint={customizePoint}
                                    onOptionChanged={handleOptionChange}
                                >
                                    <Animation enabled={true} />
                                    <Legend visible={false} />
                                    <ArgumentAxis
                                        argumentType="numeric"
                                        visualRange={ageRange}
                                        wholeRange={{
                                            startValue: 0,
                                            endValue: getDifferenceInYears(birthDate, new Date()),
                                        }}
                                        tickInterval={10}
                                        minorTickInterval={2}
                                        allowDecimals={true}
                                    >
                                        <Grid visible={true} />
                                        <MinorGrid visible={true} opacity={0.3} />
                                        <MinorTick visible={true} />
                                        <Title text={t("userSettings.Milestones.Age")} />
                                    </ArgumentAxis>
                                    <CommonSeriesSettings
                                        argumentField="dateAsNumber"
                                        selectionMode="none"
                                        hoverMode="none"
                                        scatter={{
                                            point: {
                                                border: {
                                                    visible: true,
                                                    color: "white",
                                                },
                                            },
                                        }}
                                    >
                                        <Border visible={false} />
                                    </CommonSeriesSettings>
                                    <Series
                                        valueField="weight"
                                        name="weight"
                                        axis="weight"
                                        type="scatter"
                                        order={1}
                                        color={recordTypeSettings.weight.color}
                                        visible={true}
                                    ></Series>
                                    <Series
                                        valueField="weight"
                                        name="weight"
                                        axis="weight"
                                        type={recordTypeSettings.weight.serieType}
                                        order={2}
                                        color={recordTypeSettings.weight.color}
                                        visible={true}
                                    ></Series>
                                    <ValueAxis
                                        position="left"
                                        name="weight"
                                        valueType="numeric"
                                        //tickInterval={10}
                                        visualRangeUpdateMode="keep"
                                        customPosition={0}
                                    >
                                        <Grid visible={true} />
                                        <VisualRange
                                            startValue={weightRange.startValue}
                                            endValue={weightRange.endValue}
                                        />
                                        <Title text={t("userSettings.Milestones.Weight") + " (kg)"} margin={15} />
                                    </ValueAxis>
                                    <ZoomAndPan argumentAxis={isZoomingEnabled ? "both" : "none"} />
                                    <Tooltip
                                        enabled={true}
                                        shared={false}
                                        zIndex={1000}
                                        cornerRadius={5}
                                        customizeTooltip={customizeTooltip}
                                    />
                                </Chart>
                                <div
                                    tw="text-align[center] font-size[small]"
                                    dangerouslySetInnerHTML={{
                                        __html: t("userSettings.Milestones.Text"),
                                    }}
                                />
                            </Fragment>
                        )}
                    </Fragment>
                }
            </div>
            {milestoneInfo && (
                <MilestoneEditModal
                    open={true}
                    medicalSubjectId={medicalSubjectId}
                    date={milestoneInfo.date}
                    weightRecordId={milestoneInfo.weightRecordId}
                    weight={milestoneInfo.weight}
                    milestoneRecordId={milestoneInfo.milestoneRecordId}
                    milestoneId={milestoneInfo.milestoneId}
                    milestoneText={milestoneInfo.milestoneText}
                    milestones={milestones}
                    onClose={handleModalClosed}
                />
            )}
        </Fragment>
    );
}
