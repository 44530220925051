import { useTranslation } from "react-i18next";
import cs from "date-fns/locale/cs";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";
import "../dateDefaults";
import { DATETIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "../dateDefaults";
import { Fragment } from "react";

export default function DatePicker({
    selected,
    onChange,
    className,
    highlightDates,
    placeholder,
    allowFutureDates,
    showTimeSelect,
    showTimeSelectOnly,
    timeCaption,
    timeIntervals,
    customInput,
    autoFocus,
    isClearable,
    usePortal = true,
    dateFormat = null,
}) {
    const { i18n } = useTranslation();

    let maxDate = !allowFutureDates ? new Date() : null;
    if (maxDate !== null && selected > maxDate) maxDate = selected;

    if (!dateFormat) {
        if (showTimeSelect && showTimeSelectOnly) dateFormat = TIME_FORMAT;
        else dateFormat = showTimeSelect ? DATETIME_FORMAT : DATE_FORMAT;
    }

    //fix zascrollovaní na nastavenou hodnotu - při nastaveném portalId to nefunguje
    if (showTimeSelect && showTimeSelectOnly) usePortal = false;

    return (
        <Fragment>
            <ReactDatePicker
                autoFocus={autoFocus}
                selected={selected}
                customInput={customInput}
                onChange={onChange}
                highlightDates={highlightDates}
                locale={i18n.language === "cs" ? cs : null}
                className={
                    !customInput && (showTimeSelect ? "std-datetime-picker" : "std-date-picker") + " " + className
                }
                popperClassName={showTimeSelect && !showTimeSelectOnly ? "std-datetime-popper" : ""}
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                maxDate={maxDate}
                dropdownMode="select"
                dateFormat={dateFormat}
                portalId={usePortal ? "date-picker-root" : undefined}
                placeholderText={placeholder}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                timeCaption={timeCaption}
                timeIntervals={timeIntervals}
                isClearable={isClearable}
            />
        </Fragment>
    );
}
