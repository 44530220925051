import { generateId } from "@profilog/utils/generateId";
import { prescriptionTypes } from "../codebooks/prescriptionTypes";
import { intervalUnits } from "../codebooks/intervalUnits";
import { scheduleTypes } from "../codebooks/scheduleTypes";

export function newPrescription(type, initProps) {
    const prescription = {
        $type: type,
        id: generateId(),
        days: null,
        dateFrom: null,
        dateTo: null,
        schedules: [],

        // Pomocné atributy
        editing: false,

        // Pomocné atributy pro skupinové nastavení schedules
        // TODO: Přesunout ve storu do něčeho jako actions.viewPropsById[] ?
        groupPropsSame: true,
        openScheduleList: false,

        scheduleType: scheduleTypes.time,
        forceScheduleList: type !== prescriptionTypes.drug,

        showSchedules: false,
    };

    clearGroupProps(prescription);

    Object.assign(prescription, initProps);

    switch (type) {
        case prescriptionTypes.drug:
            prescription.drug = null;
            prescription.text = null;
            break;
        case prescriptionTypes.action:
            prescription.action = null;
            prescription.text = null;
            break;
        case prescriptionTypes.measurement:
            prescription.parameters = [];
            break;
        case prescriptionTypes.survey:
            prescription.parameterGroup = null;
            break;
        default:
            break;
    }

    return prescription;
}

export function clearGroupProps(prescription) {
    prescription.Schedule = null;
    prescription.fastTimeScheduleSame = true;
    prescription.amountMaxSet = true; // Použito jen pro kontrolu, zda se má rovnou rozbalit panel.
    prescription.amountNote = null;
    prescription.amountNoteSame = true;
    prescription.weekDays = null;
    prescription.weekDaysSame = true;
    prescription.intervalUnit = intervalUnits.days;

    if (prescription.$type === prescriptionTypes.measurement || prescription.$type === prescriptionTypes.survey)
        prescription.interval = null;
    else prescription.interval = 1;

    prescription.intervalSame = true;
    prescription.intervalUnitSame = true;
}

export const prescriptionGroupProps = ["amountNote", "interval", "intervalUnit", "weekDays"];
export const prescriptionGroupPropsSet = new Set(prescriptionGroupProps);
