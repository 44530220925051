import { Fragment, useEffect, useState } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Box, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useChartPointRecords from "../fetch/useChartPointRecords";
import { format } from "date-fns";
import DeleteDialog from "@profilog/commons/components/DeleteDialog";
import { lighten } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function ChartPointRecordsGrid({
    parameterName,
    medicalSubjectId,
    dateFrom,
    dateTo,
    parameterSystemName,
    onDeleteItem,
    source = null,
}) {
    const pageSize = 15;
    const { t } = useTranslation();
    const [data, setData] = useState({ rows: [], totalRowCount: 0 });
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState();
    const { getRecords, ignoreRecord, ApiGetErrors, ApiDeleteErrors } = useChartPointRecords();

    const columns = [
        {
            field: "parameterName",
            headerName: "Parameter name",
            flex: 1,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "dateFrom",
            headerName: "Datum od",
            type: "dateTime",
            align: "center",
            valueFormatter: (params) => {
                return format(params.value, "dd. MM. yyyy HH:mm:ss");
            },
            flex: 1,
            headerAlign: "center",
            minWidth: 180,
            sortable: false,
        },
        {
            field: "dateTo",
            headerName: "Datum do",
            type: "dateTime",
            align: "center",
            valueFormatter: (params) => {
                return format(params.value, "dd. MM. yyyy HH:mm:ss");
            },
            flex: 1,
            headerAlign: "center",
            minWidth: 180,
            sortable: false,
        },
        {
            field: "value",
            headerName: "Hodnota",
            align: "right",
            flex: 1,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "",
            headerName: "",
            align: "center",
            sortable: false,
            renderCell: (params) => {
                return (
                    <DeleteDialog
                        onConfirmed={() => onDeleteRecordClick(params.row)}
                        trigger={(open) => (
                            <Tooltip title={t("recordsVisualization.DeleteRecord")} placement="left">
                                <IconButton color="primary" onClick={open}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    >
                        {t("recordsVisualization.ReallyDeleteParameterValue")}
                    </DeleteDialog>
                );
            },
            flex: 0.3,
        },
    ];

    async function onDeleteRecordClick(row) {
        setIsLoading(true);
        let result = await ignoreRecord(medicalSubjectId, row.recordId, parameterSystemName);
        if (result.isOk) {
            if (onDeleteItem) onDeleteItem();
            await getData();
        }
        setIsLoading(false);
    }

    function convertData(records) {
        var list = [];

        records.forEach((record) => {
            record.fields.forEach((field) => {
                list.push({
                    parameterName: parameterName,
                    recordId: record.id,
                    value: field.value ?? field.textValue,
                    dateFrom: new Date(record.utcStartAt),
                    dateTo: new Date(record.utcEndAt),
                });
            });
        });

        return list;
    }

    async function getData() {
        if (!medicalSubjectId || !dateFrom || !dateTo || !parameterSystemName) return;
        let result = await getRecords(
            medicalSubjectId,
            dateFrom,
            dateTo,
            parameterSystemName,
            source,
            page + 1,
            pageSize,
        );
        if (result.isOk)
            setData({ rows: convertData(result.json.records), totalRowCount: result.json.pagination.totalCount });
    }

    useEffect(() => {
        async function loadData() {
            setIsLoading(true);
            await getData();
            setIsLoading(false);
        }

        loadData();
    }, [page]);

    const ODD_OPACITY = 0.92;
    const gridBackground = {
        backgroundColor: (theme) => lighten(theme.palette.primary.main, ODD_OPACITY),
    };

    const gridCss = {
        width: "100%",
        [`& .${gridClasses.root}`]: {
            overflow: "hidden",
        },
        [`& .${gridClasses.row}`]: {
            //marginTop: "2px",
            border: "none",
            backgroundColor: "#fff",
            // "&.even": {
            //     backgroundColor: "#ccc",
            // },
        },
        [`& .${gridClasses.columnHeaders}`]: gridBackground,
        [`& .${gridClasses.footerContainer}`]: gridBackground,
        [`& .${gridClasses["columnHeader--moving"]}`]: gridBackground,
    };

    return (
        <Fragment>
            <ApiGetErrors />
            <ApiDeleteErrors />
            <Box sx={gridCss}>
                <DataGrid
                    loading={isLoading}
                    getRowId={(row) => row.recordId}
                    rows={data.rows}
                    rowCount={data.totalRowCount}
                    columns={columns}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    disableColumnSelector
                    disableDensitySelector
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnMenu
                    columnVisibilityModel={{
                        parameterName: false,
                    }}
                    pageSize={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    autoHeight
                    density="compact"
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                />
            </Box>
        </Fragment>
    );
}
