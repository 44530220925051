import { useTranslation } from "react-i18next";
import ExternalConnectionSelectButton from "./ExternalConnectionSelectButton";
import AppSettings from "../../app/AppSettings";
import useGarminConnect from "@profilog/misc/user/hooks//useGarminConnect";
import useGoogleFit from "@profilog/misc/user/hooks//useGoogleFit";
import useOneTouch from "@profilog/misc/user/hooks//useOneTouch";
import useWithings from "@profilog/misc/user/hooks//useWithings";
import useRemoteGoogleFit from "@profilog/misc/user/hooks/useRemoteGoogleFit";
import "./ExternalConnectionSelection.scss";
import { Fragment } from "react";
import { Button } from "@mui/material";
import useHuawei from "@profilog/misc/user/hooks/useHuawei";
import useFitbit from "@profilog/misc/user/hooks/useFitbit";
import useStrava from "@profilog/misc/user/hooks/useStrava";

export default function ExternalConnectionSelection({
    name,
    title,
    isGarminButtonVisible,
    isGoogleButtonVisible,
    isRemoteGoogleFitButtonVisible,
    isOneTouchButtonVisible,
    isWithingsButtonVisible,
    isHuaweiButtonVisible,
    isFitbitButtonVisible,
    isStravaButtonVisible,
    isAndroidButtonVisible,
    isAppleButtonVisible,
    isDiabeticButtonVisible,
    onButtonClick,
}) {
    const { t } = useTranslation();
    const garmin = useGarminConnect();
    const google = useGoogleFit();
    const remoteGoogleFit = useRemoteGoogleFit();
    const withings = useWithings();
    const oneTouch = useOneTouch();
    const huawei = useHuawei();
    const fitbit = useFitbit();
    const strava = useStrava();

    const isLoading =
        garmin.garminConnectConnectionInfo?.status === null ||
        google.googleFitConnectionInfo?.status === null ||
        withings.withingsConnectionInfo?.status === null ||
        oneTouch.oneTouchConnectionInfo?.status === null ||
        remoteGoogleFit.data?.status === null ||
        huawei.huaweiConnectionInfo?.status === null ||
        fitbit.fitbitConnectionInfo?.status === null ||
        strava.stravaConnectionInfo?.status === null;

    return (
        <Fragment>
            <div tw="text-sm">{title}</div>
            <div tw="flex mt-2 flex-wrap gap-2">
                {isGarminButtonVisible && AppSettings.externalServices.garminConnect && (
                    <ExternalConnectionSelectButton
                        stepName={name}
                        title="Garmin connect"
                        isConnected={garmin.garminConnectConnectionInfo?.status === "Connected"}
                        connect={garmin.connect}
                        isLoading={isLoading}
                        onClick={onButtonClick}
                    />
                )}
                {isGoogleButtonVisible &&
                    AppSettings.externalServices.googleFit &&
                    !google.gettingState &&
                    google.googleFitConnectionInfo?.allowNewConnections && (
                        <ExternalConnectionSelectButton
                            stepName={name}
                            title="Google Fit"
                            isConnected={google.googleFitConnectionInfo?.status === "Connected"}
                            connect={google.connect}
                            isLoading={isLoading}
                            onClick={onButtonClick}
                        />
                    )}
                {isRemoteGoogleFitButtonVisible && AppSettings.externalServices.remoteGoogleFit && (
                    <ExternalConnectionSelectButton
                        stepName={name}
                        title={t("extConnectors.RemoteGoogleFit.Title")}
                        isConnected={remoteGoogleFit.data ? remoteGoogleFit.data.status === "Connected" : false}
                        connect={remoteGoogleFit.connect}
                        onClick={onButtonClick}
                    />
                )}
                {isWithingsButtonVisible && AppSettings.externalServices.withings && (
                    <ExternalConnectionSelectButton
                        stepName={name}
                        title="Withings"
                        isConnected={withings.withingsConnectionInfo?.status === "Connected"}
                        connect={withings.connect}
                        isLoading={isLoading}
                        onClick={onButtonClick}
                    />
                )}
                {isHuaweiButtonVisible && AppSettings.externalServices.huawei && (
                    <ExternalConnectionSelectButton
                        stepName={name}
                        title="HUAWEI Health"
                        isConnected={huawei.huaweiConnectionInfo?.status === "Connected"}
                        connect={huawei.connect}
                        isLoading={isLoading}
                        onClick={onButtonClick}
                    />
                )}
                {isFitbitButtonVisible && AppSettings.externalServices.fitbit && (
                    <ExternalConnectionSelectButton
                        stepName={name}
                        title="Fitbit"
                        isConnected={fitbit.fitbitConnectionInfo?.status === "Connected"}
                        connect={fitbit.connect}
                        isLoading={isLoading}
                        onClick={onButtonClick}
                    />
                )}
                {isStravaButtonVisible && AppSettings.externalServices.strava && (
                    <ExternalConnectionSelectButton
                        stepName={name}
                        title="Strava"
                        isConnected={strava.stravaConnectionInfo?.status === "Connected"}
                        connect={strava.connect}
                        isLoading={isLoading}
                        onClick={onButtonClick}
                    />
                )}{" "}
                {/* {isOneTouchButtonVisible && AppSettings.externalServices.oneTouchReveal && (
                    <ExternalConnectionSelectButton
                        stepName={name}
                        title="One Touch"
                        isConnected={oneTouch.isConnected}
                        connect={oneTouch.connect}
                        isLoading={isLoading}
                        onClick={onButtonClick}
                    />
                )} */}
                {/* {isAndroidButtonVisible && AppSettings.externalServices.androidApp && (
                    <ExternalConnectionSelectButton stepName={name} title="Android App" isLoading={isLoading} />
                )}
                {isAppleButtonVisible && AppSettings.externalServices.iphoneApp && (
                    <ExternalConnectionSelectButton stepName={name} title="Apple zdraví" isLoading={isLoading} />
                )} */}
                {isDiabeticButtonVisible && (
                    <Button variant="outlined" color="secondary" onClick={onButtonClick}>
                        {t("userInitialization.ExternalConnectionsInitialization.NotDiabetic")}
                    </Button>
                )}
            </div>
            <div tw="mt-5">
                <Button variant="contained" color="primary" onClick={onButtonClick}>
                    {t("userInitialization.ExternalConnectionsInitialization.Skip")}
                </Button>

                {/* Pro testovani */}
                {/* {isGarminConnected && (
                    <Button variant="contained" color="error" onClick={disconnectGarmin}>
                        Odpojit Garmin
                    </Button>
                )}
                {isGoogleFitConnected && (
                    <Button variant="contained" color="error" onClick={disconnectGoogleFit}>
                        Odpojit Google
                    </Button>
                )} */}
            </div>
        </Fragment>
    );
}
