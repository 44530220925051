import FetchOptions, { reportStatus, DEPLOY_IN_PROGRESS, INVALID_VERSION } from "./FetchOptions";
import { useState } from "react";
import ApiErrorsModal from "../errors/ApiErrorsModal";
import ApiErrorsList from "../errors/ApiErrorsList";
import AuthOptions from "../auth/AuthOptions";
import { getLoginToken } from "../auth/useAuth";

export function useFetch(ignoreServiceUnavailable = false) {
    const [apiErrors, setApiErrors] = useState([]);
    const [pending, setPending] = useState(false);

    return {
        apiGet: _apiGet,
        apiPost: _apiPost,
        apiPut: _apiPut,
        apiPatch: _apiPatch,
        apiDelete: _apiDelete,
        apiDeleteBody: _apiDeleteBody,
        apiErrors,
        ApiErrors: ApiErrors,
        resetApiErrors,
        pending,
    };

    async function _apiGet(url) {
        return await _apiCall(() => apiGet(url, ignoreServiceUnavailable));
    }

    async function _apiPost(url, object) {
        return await _apiCall(() => apiPost(url, object, ignoreServiceUnavailable));
    }

    async function _apiPut(url, object) {
        return await _apiCall(() => apiPut(url, object, ignoreServiceUnavailable));
    }

    async function _apiPatch(url, object) {
        return await _apiCall(() => apiPatch(url, object, ignoreServiceUnavailable));
    }

    async function _apiDelete(url) {
        return await _apiCall(() => apiDelete(url, ignoreServiceUnavailable));
    }

    async function _apiDeleteBody(url, object) {
        return await _apiCall(() => apiDeleteBody(url, object, ignoreServiceUnavailable));
    }

    async function _apiCall(action) {
        setApiErrors([]);
        setPending(true);
        const response = await action();
        if (!response.isOk) setApiErrors(response.errors);
        setPending(false);
        return response;
    }

    function ApiErrors({ title = null, modal = false, translationPrefix = null }) {
        return modal ? (
            <ApiErrorsModal
                title={title}
                apiErrors={apiErrors}
                resetApiErrors={() => setApiErrors(null)}
                translationPrefix={translationPrefix}
            />
        ) : (
            <ApiErrorsList apiErrors={apiErrors} translationPrefix={translationPrefix} />
        );
    }

    function resetApiErrors() {
        setApiErrors([]);
    }
}

export async function apiGet(url, ignoreServiceUnavailable = false) {
    return await apiCall(() => apiFetch("GET", url), ignoreServiceUnavailable);
}

export async function apiPost(url, object, ignoreServiceUnavailable = false) {
    return await apiCall(() => apiFetch("POST", url, object), ignoreServiceUnavailable);
}

export async function apiPut(url, object, ignoreServiceUnavailable = false) {
    return await apiCall(() => apiFetch("PUT", url, object), ignoreServiceUnavailable);
}

export async function apiPatch(url, object, ignoreServiceUnavailable = false) {
    return await apiCall(() => apiFetch("PATCH", url, object), ignoreServiceUnavailable);
}

export async function apiDelete(url, ignoreServiceUnavailable = false) {
    return await apiCall(() => apiFetch("DELETE", url), ignoreServiceUnavailable);
}

export async function apiDeleteBody(url, object, ignoreServiceUnavailable = false) {
    return await apiCall(() => apiFetch("DELETE", url, object), ignoreServiceUnavailable);
}

async function apiCall(action, ignoreServiceUnavailable = false) {
    let result = {
        isOk: false,
        isUnauthorized: false,
        json: null,
        statusCode: null,
        errors: [],
        unhandledError: null,
    };

    try {
        let response = await action();
        result.statusCode = response.status;

        if (response.ok) {
            result.isOk = true;

            const headerVersion = response.headers.get("Api-Version");
            if (headerVersion && headerVersion !== FetchOptions.appVersion) reportStatus(INVALID_VERSION);

            await tryParseJson();
        } else if (response.status === 401) {
            result.isUnauthorized = true;
            result.errors = ["401"];
        } else if (response.status === 401) result.errors = ["401"];
        else if (response.status === 403) result.errors = ["403"];
        else if (response.status === 404) result.errors = ["404"];
        else if (response.status >= 400 && response.status < 500) await tryParseJson();
        else if (response.status === 500) result.errors = ["500"];
        else if (response.status === 503 && !ignoreServiceUnavailable) reportStatus(DEPLOY_IN_PROGRESS);

        async function tryParseJson() {
            let json = await responseAsJson(response);
            if (json != null) {
                result.json = json;
                if (json.errors) result.errors = Object.keys(json.errors);
                else if (json.status >= 400 && json.status < 500) {
                    result.errors.push(json.title);
                }
            }
        }
    } catch (error) {
        result.unhandledError = error.message;
        result.errors.push("FetchError");
    }

    return result;
}

async function responseAsJson(response) {
    try {
        return await response.json();
    } catch {
        return null;
    }
}

export async function apiFetch(method, url, object) {
    const options = {
        method: method,
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Accept-Language": FetchOptions.language,
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest", // https://github.com/dotnet/aspnetcore/issues/9039
            "Project-Code": FetchOptions.project ? FetchOptions.project : "",
            "Access-Control-Allow-Origin": "*",
        },
    };

    if (AuthOptions.isBearer) {
        let loginToken = getLoginToken();
        if (loginToken || !AuthOptions.fallbackToCookies) options.headers.Authorization = `Bearer ${loginToken}`;
    }

    if (object) options.body = JSON.stringify(object);

    return await fetch(FetchOptions.apiUrl + url, options);
}
