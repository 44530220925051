import { useState, useEffect, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "@profilog/misc/utils/useFetch";
import AppSettings from "../../app/AppSettings";
import CooperationRequestCard from "./CooperationRequestCard";
import CooperationTable from "./CooperationsTable";
import CurrentNotificationContext from "@profilog/misc/notifications/CurrentNotificationContext";
import { notificationTypes } from "@profilog/misc/notifications/components/notificationTypes";
import { cooperationRequestTypes } from "@profilog/misc/notifications/components/cooperationRequestTypes";

export default function CooperationRequests() {
    const { t } = useTranslation();
    const { apiGet: apiGetCooperations, ApiErrors: ApiGetCooperationsErrors } = useFetch();
    const [isLoading, setIsLoading] = useState(true);
    const [cooperations, setCooperations] = useState([]);
    const { currentNotification, setCurrentNotification } = useContext(CurrentNotificationContext);

    useEffect(() => {
        if (isLoading) return;

        if (containsCooperationNotificationContext()) {
            resetNotificationContext();
            getCooperations();
        }
        // eslint-disable-next-line
    }, [currentNotification?.notification]);

    useEffect(() => {
        getCooperations();
        setIsLoading(false);
        if (containsCooperationNotificationContext()) resetNotificationContext();
        // eslint-disable-next-line
    }, []);

    function containsCooperationNotificationContext() {
        if (AppSettings.isPatientPreview) return false;

        let notificationType = currentNotification?.notification?.type;
        let cooperationRequestType = currentNotification?.notification?.cooperationRequest?.type;

        if (
            cooperationRequestType == cooperationRequestTypes.fromPatientToTherapist &&
            (notificationType === notificationTypes.cooperationRequestAccepted ||
                notificationType === notificationTypes.cooperationRequestRejected)
        ) {
            return true;
        }

        if (
            cooperationRequestType == cooperationRequestTypes.fromTherapistToPatient &&
            notificationType === notificationTypes.cooperationRequestSent
        ) {
            return true;
        }

        return false;
    }

    function resetNotificationContext() {
        setCurrentNotification((o) => ({ ...o, notification: null }));
    }

    async function getCooperations() {
        let response = await apiGetCooperations(
            `/web/cooperations?medicalSubjectId=${AppSettings.user.userMedicalSubjectId}&state=Active&state=Pending`,
        );
        if (response.isOk) {
            setCooperations(response.json);
        }
    }

    if (isLoading) return null;

    return (
        <Fragment>
            <div>
                <CooperationRequestCard onRequestSent={getCooperations} isMultipleSendAllowed={true} />
            </div>

            <div>
                <h3>{t("communications.CooperationRequests.Table.CooperationList")}</h3>
                <ApiGetCooperationsErrors />
                <CooperationTable cooperations={cooperations} onChangeCooperation={getCooperations} />
            </div>
        </Fragment>
    );
}
