import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../utils/useFetch";
import styles from "./GdprText.module.scss";
import { useScrollToTop } from "../../../../commons/src/hooks/useScrollToTop";

export default function GdprText({ type, customProjectCode }) {
    const { gdprData, ApiErrors } = useGdprText(type, customProjectCode);

    return <GdprTextOutput gdprData={gdprData} ApiErrors={ApiErrors} />;
}

// Hook existuje jen proto, že na stránce se záznamy vyskočí GDPR modal moc brzy (bez textu),
// pak se dloho rednerují grafy a pak teprve doskočí GDPR text.
// Je tedy třeba celý Modal zobrazit až po dokončení useEffectu (po stažení GDPR textu).
export function useGdprText(type, customProjectCode) {
    const { apiGet, ApiErrors } = useFetch();
    const { i18n } = useTranslation();
    const [gdprData, setGdprData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useScrollToTop([type, i18n.language]);

    useEffect(() => {
        async function getProjectGdpr() {
            let url = `/projects/gdpr/${i18n.language}/${type}`;
            if (customProjectCode) url += `/${customProjectCode}`;
            const response = await apiGet(url);
            if (response.isOk) setGdprData(response.json);
            setIsLoading(false);
        }

        getProjectGdpr();
    }, [type, i18n.language]); // eslint-disable-line

    return {
        gdprData,
        isLoading,
        ApiErrors,
    };
}

export function GdprTextOutput({ gdprData, ApiErrors }) {
    return (
        <Fragment>
            {gdprData && <h1 dangerouslySetInnerHTML={{ __html: gdprData.title }} className={styles.title + " pb-4"} />}
            <ApiErrors />
            {gdprData && <div className={styles.text} dangerouslySetInnerHTML={{ __html: gdprData.text }}></div>}
        </Fragment>
    );
}
