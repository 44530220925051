import { useTranslation } from "react-i18next";
import ExtConnector from "./ExtConnector";
import useRemoteGoogleFit from "../hooks/useRemoteGoogleFit";
import { Alert, Button, Dialog, DialogTitle, DialogContent, CircularProgress, Paper } from "@mui/material";
import { Fragment, useState } from "react";
import { isConnected } from "../codebooks/externalConnectionStatus";
import "twin.macro";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";

export default function RemoteGoogleFitConnection({ readonly, refresh, Layout, onDisconnect }) {
    const { t, i18n } = useTranslation();
    const { connect, disconnect, data, ApiErrors, apiError, getSharingInfo } = useRemoteGoogleFit(refresh);
    const [disconnectDialogOpen, setDisconnectDialogOpen] = useState(false);
    const [currentProject, setCurrentProject] = useState(null);
    const [projects, setProjects] = useState([]);

    if (data === null)
        return (
            <Layout>
                <CircularProgress />
            </Layout>
        );

    let customError = null;
    const isSourceDisconnected = isConnected(data.status) && data.isSourceConnected === false;
    if (apiError || isSourceDisconnected) {
        customError = (
            <Fragment>
                {apiError && <div tw="mb-2">{apiError}</div>}
                {isSourceDisconnected && <div>{t("extConnectors.RemoteGoogleFit.SourceDisconnected")}</div>}
            </Fragment>
        );
    }

    const connectButtonHidden = !data.emailConfirmed;
    const connectButtonHiddenReason = (
        <div tw="space-y-2">
            {!data.emailConfirmed && (
                <Alert severity="warning">{t("extConnectors.RemoteGoogleFit.ConfirmedEmailRequired")}</Alert>
            )}
        </div>
    );

    return (
        <Fragment>
            <Dialog open={disconnectDialogOpen} onClose={handleDialogClose}>
                <DialogCloseButton onClick={handleDialogClose} />
                <DialogTitle>{t("extConnectors.RemoteGoogleFit.DisconnectButtonLabel")}</DialogTitle>
                <DialogContent>
                    <div tw="mb-4">
                        {t("extConnectors.RemoteGoogleFit.ConnectionIsSharedInProjects1")}
                        {getFormattedProjectList(projects, i18n.language)}
                        {t("extConnectors.RemoteGoogleFit.ConnectionIsSharedInProjects2")}
                    </div>
                    <Paper variant="outlined" tw="p-4 mb-6 flex flex-col items-center gap-2">
                        <div>{t("extConnectors.RemoteGoogleFit.DisconnectOnlyThisUserText")}</div>
                        <Button
                            onClick={async () => await handleDisconnectShared(false)}
                            variant="contained"
                            tw="normal-case"
                        >
                            {t("extConnectors.RemoteGoogleFit.DisconnectOnlyThisUserButton")}
                        </Button>
                    </Paper>
                    <Paper variant="outlined" tw="p-4 flex flex-col items-center gap-2">
                        <div>{t("extConnectors.RemoteGoogleFit.DisconnectFromAllProjectsText")}</div>
                        <Button
                            onClick={async () => await handleDisconnectShared(true)}
                            variant="contained"
                            color="error"
                            tw="normal-case"
                        >
                            {currentProject}
                            {t("extConnectors.RemoteGoogleFit.DisconnectFromAllProjectsButton")}
                        </Button>
                    </Paper>
                </DialogContent>
            </Dialog>
            <ExtConnector
                title={t("extConnectors.RemoteGoogleFit.Title")}
                subTitle={
                    <Fragment>
                        {t("extConnectors.RemoteGoogleFit.SubTitle")}
                        {data.isLocallyConnected && (
                            <Alert severity="warning" tw="mt-2">
                                {t("extConnectors.RemoteGoogleFit.LocalWillDisconnect")}
                            </Alert>
                        )}
                    </Fragment>
                }
                infoText={t("extConnectors.GoogleFit.InfoText")}
                connected={isConnected(data.status)}
                connectionWarning={isSourceDisconnected}
                account={`HH: ${data.sourceUserName} | Google Fit: ${data.sourceGoogleFitAccount}`}
                connectButtonLabel={t("extConnectors.RemoteGoogleFit.ConnectButtonLabel")}
                disconnectButtonLabel={t("extConnectors.RemoteGoogleFit.DisconnectButtonLabel")}
                connectButtonHidden={connectButtonHidden}
                connectButtonHiddenReason={connectButtonHiddenReason}
                lastImportDate={data.lastImportAt}
                status={data.status}
                onConnectClick={connect}
                onDisconnectClick={async () => await handleDisconnect()}
                ApiErrors={ApiErrors}
                customError={customError}
                readonly={readonly}
                requireConsent={true}
                consentUrl={data?.consentUrl}
                consent1Text={t("extConnectors.RemoteGoogleFit.Consent1")}
                consent2Text={t("extConnectors.RemoteGoogleFit.Consent2")}
                Layout={Layout}
            />
        </Fragment>
    );

    async function handleDisconnect() {
        const sharingInfo = await getSharingInfo();
        if (sharingInfo === null) return; // Tady je nějaký problém! Nemělo by to nastat, ale co kdyby.

        setCurrentProject(sharingInfo.currentProject);
        if (sharingInfo.length === 1) {
            // Bude se odpojovat pouze jediné připojení. Uživatele se na nic neptáme a rovnou odpojujeme.
            await disconnect();
        } else {
            setProjects(sharingInfo.map((p) => `${p.projectName} (${p.count}x)`));
            setDisconnectDialogOpen(true);
        }
        if (onDisconnect) onDisconnect();
    }

    function handleDialogClose() {
        setDisconnectDialogOpen(false);
    }

    async function handleDisconnectShared(withRelated) {
        await disconnect(withRelated);
        setDisconnectDialogOpen(false);
    }
}

function getFormattedProjectList(projects, lang) {
    const listFormatter = new Intl.ListFormat(lang, { style: "long", type: "conjunction" });
    const parts = listFormatter.formatToParts(projects);
    return <Fragment>{parts.map((p) => (p.type === "literal" ? p.value : <strong>{p.value}</strong>))}</Fragment>;
}
