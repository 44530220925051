import { addHours, format } from "date-fns";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

const date1 = addHours(new Date(), -1);
const date2 = addHours(new Date(), -2);
const date3 = addHours(new Date(), -3);
const date4 = addHours(new Date(), -4);

const forms = [
    {
        id: 1,
        name: "Apache II",
        date: format(date1, "dd.MM.yyyy"),
        time: format(date1, "HH:mm"),
        user: "Dr. G. Smiths",
    },
    {
        id: 2,
        name: "Prism",
        date: format(date2, "dd.MM.yyyy"),
        time: format(date2, "HH:mm"),
        user: "Mhr. L. Volkinski",
    },
    { id: 3, name: "SIRS", date: format(date3, "dd.MM.yyyy"), time: format(date3, "HH:mm"), user: "Bc. A. Yiri" },
    {
        id: 4,
        name: "Apache II",
        date: format(date4, "dd.MM.yyyy"),
        time: format(date4, "HH:mm"),
        user: "Dr. G. Smiths",
    },
];

const Title = tw.div`font-size[13px] font-semibold`;
const Info = tw.div`font-size[11px]`;

export default function Surveys() {
    const { t } = useTranslation();

    return (
        <div tw="flex">
            <div tw="font-bold mr-16">{t("icudemo.Forms")}</div>
            <div tw="flex flex-1 space-x-12">
                {forms.map((form) => (
                    <div tw="flex space-x-3 cursor-pointer" key={form.name}>
                        <i className="fas fa-file-alt" tw="font-size[52px]"></i>
                        <div>
                            <Title>{form.name}</Title>
                            <Info>{form.date}</Info>
                            <Info>{form.time}</Info>
                            <Info>{form.user}</Info>
                        </div>
                    </div>
                ))}
            </div>
            <div tw="self-center font-bold mr-6 cursor-pointer text-red-500">{t("FillInNewForm")}</div>
        </div>
    );
}
