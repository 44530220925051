import { useContext, useEffect, useState } from "react";
import { useFetch } from "../../utils/useFetch";
import { getHostName, getQuery } from "../../utils/Url";
import FetchOptions from "../../utils/FetchOptions";
import { useTranslation } from "react-i18next";

export default function useWithings() {
    const { t } = useTranslation();
    const [withingsConnectionInfo, setWithingsConnectionInfo] = useState(null);
    const { apiPost, apiGet, ApiErrors } = useFetch();
    const [isConnecting, setIsConnecting] = useState(false);
    const [withingsApiError, setWithingsApiError] = useState(null);
    const query = getQuery();

    async function connect(returnUrlQueryString) {
        setIsConnecting(true);
        window.location =
            FetchOptions.apiUrl +
            "/withings/connect?ReturnUrl=" +
            encodeURIComponent(
                `${window.location.protocol}//${window.location.host}${window.location.pathname}${
                    returnUrlQueryString ? `?${returnUrlQueryString}` : ""
                }`,
            );
    }

    async function disconnect() {
        const response = await apiPost("/withings/disconnect");
        if (response.isOk) await getWithingsStatus();
    }

    async function getWithingsStatus() {
        const response = await apiGet("/withings/get-state");
        if (response.isOk) {
            setWithingsConnectionInfo(response.json);
        }
    }

    useEffect(() => {
        getWithingsStatus();

        const withingsErrorCode = query.get("withings-error");
        if (withingsErrorCode !== null) {
            switch (withingsErrorCode) {
                case "get-consent":
                    setWithingsApiError(t("extConnectors.Error.GetConsent"));
            }
        }
    }, []);

    return {
        withingsConnectionInfo,
        connect,
        disconnect,
        isConnecting,
        ApiErrors,
        withingsApiError,
    };
}
