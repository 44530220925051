import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiGet } from "@profilog/misc/utils/useFetch";
import { useGroupEditModal } from "../components/GroupEditModal";

export default function GroupsList() {
    const { t } = useTranslation();
    const [groups, setGroups] = useState([]);
    const { openGroupEditModal, GroupEditModal } = useGroupEditModal();

    const handleGroupSaved = () => {
        getGroups();
    };

    async function getGroups() {
        let result = await apiGet("/groups");
        setGroups(result.json);
    }

    useEffect(() => {
        getGroups();
    }, []);

    return (
        <Fragment>
            <GroupEditModal />

            <h2 className="box-title">{t("header.NavBar.Groups")}</h2>
            <div className="my-4">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => openGroupEditModal(null, handleGroupSaved)}
                >
                    {t("groups.NewGroup")}
                </button>
            </div>

            <div className="table-responsive no-more-tables">
                <table className="table va-inherit">
                    <thead>
                        <tr>
                            <th scope="col">{t("groups.Name")}</th>
                            <th scope="col-3">{t("groups.Description")}</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {groups.map((group) => (
                            <tr key={group.id} className="clearfix mt-3">
                                <td data-title={t("groups.Name")}>{group.name}</td>
                                <td data-title={t("groups.Description")}>{group.description}</td>
                                <td className="text-right" data-title={t("groups.Detail")}>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => openGroupEditModal(group.id, handleGroupSaved)}
                                    >
                                        {t("groups.Detail")}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
}
