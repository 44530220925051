import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { prescriptionUpdate } from "../../redux/prescriptionSlice";
import EditPanel from "../commons/EditPanel";
import Repeat from "../schedules/scheduleParams/Repeat";
import WeekDays from "../schedules/scheduleParams/WeekDays";

export default function TimeScheduleGroupSettings({ prescriptionId }) {
    const dispatch = useDispatch();

    return (
        <Fragment>
            <EditPanel>
                {(editing, open, wasOpen) => (
                    <Repeat
                        editing={editing}
                        open={open}
                        wasOpen={wasOpen}
                        saved={prescriptionId > 0}
                        intervalSelector={(state) => state.prescriptions.prescriptions.byId[prescriptionId].interval}
                        intervalUnitSelector={(state) =>
                            state.prescriptions.prescriptions.byId[prescriptionId].intervalUnit
                        }
                        sameSelector={(state) => {
                            const action = state.prescriptions.prescriptions.byId[prescriptionId];
                            return action.intervalSame && action.intervalUnitSame;
                        }}
                        dispatchUpdate={dispatchPrescriptionUpdate}
                    />
                )}
            </EditPanel>
            <EditPanel>
                {(editing, open, wasOpen) => (
                    <WeekDays
                        editing={editing}
                        open={open}
                        wasOpen={wasOpen}
                        saved={prescriptionId > 0}
                        selector={(state) => state.prescriptions.prescriptions.byId[prescriptionId].weekDays}
                        sameSelector={(state) => state.prescriptions.prescriptions.byId[prescriptionId].weekDaysSame}
                        dispatchUpdate={dispatchPrescriptionUpdate}
                    />
                )}
            </EditPanel>
        </Fragment>
    );

    function dispatchPrescriptionUpdate(updated) {
        dispatch(prescriptionUpdate({ prescriptionId, updated }));
    }
}
