import { IconButton, IconButtonProps } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function DialogCloseButton(props: IconButtonProps) {
    return (
        <IconButton tw="absolute right-1 top-1" {...props}>
            <CloseIcon />
        </IconButton>
    );
}
