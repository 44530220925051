import { useState, useEffect } from "react";
import { useFetch } from "../../utils/useFetch";
import useInterval from "../../utils/IntervalHook";

export function useApiIntervalGet(url, searchParams, refreshInterval, initialValue, getValueFn = (json) => json) {
    const [value, setValue] = useState(initialValue);
    const { apiGet, ApiErrors } = useFetch(true);

    useEffect(() => {
        updateValue();
    }, []);

    useInterval(updateValue, refreshInterval && refreshInterval >= 500 ? refreshInterval : 5000);

    return {
        value: value,
        updateValue: updateValue,
        ApiErrors: ApiErrors,
    };

    async function updateValue() {
        if (!url) return;
        let response = await apiGet(
            `${url}${
                searchParams && Object.keys(searchParams).length > 0 ? `?${new URLSearchParams(searchParams)}` : ""
            }`,
        );
        if (response.isOk) {
            const newValue = getValueFn(response.json);
            setValue(newValue);
        }
    }
}
