import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { getErrorMessage } from "../../errors/ValidationError";
import { useFetch } from "../../utils/useFetch";
import { getHostName } from "../../utils/Url";
import { Alert, Button, TextField } from "@mui/material";

export default function ResetPasswordForm({ userName, isUserNameDisabled }) {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        errors,
        formState: { isSubmitting },
    } = useForm();
    const [wasEmailSent, setWasEmailSent] = useState(false);
    const { apiPost, ApiErrors } = useFetch();

    if (wasEmailSent) return <Alert severity="success">{t("auth.ResetPassword.EmailSent")}</Alert>;

    return (
        <form onSubmit={handleSubmit(sendResetPasswordEmail)}>
            {!isUserNameDisabled && (
                <Fragment>
                    <div tw="mb-4">{t("auth.ResetPassword.ResetInfo")}</div>
                    <div tw="mb-4">
                        <TextField
                            autoFocus
                            onFocus={(e) => e.target.select()}
                            type="text"
                            size="small"
                            inputRef={register({ required: t("auth.UserNameRequired") })}
                            inputProps={{ name: "resetPasswordUserName", autoComplete: "off", defaultValue: userName }}
                            disabled={isUserNameDisabled}
                            tw="w-full"
                            helperText={getErrorMessage(errors, "resetPasswordUserName")}
                        />
                    </div>
                </Fragment>
            )}
            <ApiErrors />
            <div tw="flex justify-end">
                <Button type="submit" disabled={isSubmitting}>
                    {t("auth.ResetPassword.SendEmail")}
                </Button>
            </div>
        </form>
    );

    async function sendResetPasswordEmail(data) {
        setWasEmailSent(false);

        const response = await apiPost("/web/user/send-reset-password-email", {
            email: isUserNameDisabled ? userName : data["resetPasswordUserName"],
            hostName: getHostName(),
        });
        if (response.isOk) {
            setWasEmailSent(true);
        }
    }
}
