import LoginCard from "../../../shared/components/LoginCard";

import "./GenericHome.scss";

export default function GenericHome({ disableRegistration = false }) {
    return (
        <section className="home-header">
            <div className="content">
                <div className="container">
                    <div tw="flex justify-center items-center">
                        <div tw="w-full md:w-5/12 mt-20">
                            <LoginCard disableRegistration={disableRegistration} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
