import { useState, useEffect } from "react";
import { useFetch } from "@profilog/misc/utils/useFetch";
import AppSettings from "../../app/AppSettings";

export default function usePrescriptionNotificationSettings(onSaveSettings) {
    const [isLoading, setIsLoading] = useState(true);
    const [settings, setSettings] = useState([]);
    const { apiGet: apiGetPrescriptionNotificationSettings, ApiErrors: ApiGetErrors } = useFetch();
    const { apiPost: apiSave, ApiErrors: ApiSaveErrors } = useFetch();

    useEffect(() => {
        loadPrescriptionNotificationSettings();
    }, []);

    async function loadPrescriptionNotificationSettings() {
        const response = await apiGetPrescriptionNotificationSettings(
            `/web/prescriptions/notification-settings?medicalSubjectId=${AppSettings.user.userMedicalSubjectId}`,
        );
        if (response.isOk) {
            setSettings(response.json);
            setIsLoading(false);
        }
    }

    async function saveAsync() {
        let savePrescriptionNotificationSettingsDto = {
            ...settings,
            medicalSubjectId: AppSettings.user.userMedicalSubjectId,
        };

        const response = await apiSave(
            "/web/prescriptions/notification-settings",
            savePrescriptionNotificationSettingsDto,
        );
        if (response.isOk) {
            if (onSaveSettings) onSaveSettings();
        }
    }

    function setNotificationType(dataPrefix, notificationTypeFlag, value) {
        setSettings((os) => {
            let ns = { ...os };
            if (value) ns[dataPrefix] |= notificationTypeFlag;
            else ns[dataPrefix] &= ~notificationTypeFlag;
            return ns;
        });
    }

    const notificationTypeFlags = {
        web: 1,
        email: 2,
        app: 4,
    };

    const notificationTypes = {
        web: "web",
        email: "email",
        app: "app",
    };

    const dataPrefixes = {
        dailySummary: "dailySummary",
        inTime: "inTime",
        after: "after",
    };

    function createDataKey(dataPrefix, notificationType) {
        return `${dataPrefix}_${notificationType}`;
    }

    return {
        prescriptionNotificationSettings: settings,
        setPrescriptionNotificationSettings: setSettings,
        isLoading,
        notificationTypeFlags,
        notificationTypes,
        dataPrefixes,
        setNotificationType,
        createDataKey,
        saveSettingsAsync: saveAsync,
        refreshSettingsAsync: loadPrescriptionNotificationSettings,
        ApiGetErrors,
        ApiSaveErrors,
    };
}
