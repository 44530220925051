import { useState, useEffect } from "react";
import { useFetch } from "../../utils/useFetch";
import { getQuery } from "../../utils/Url";
import FetchOptions from "../../utils/FetchOptions";

export default function useOneTouch() {
    const { apiPost, apiGet, ApiErrors } = useFetch();
    const query = getQuery();
    const [isConnecting, setIsConnecting] = useState(false);
    const [oneTouchConnectionInfo, setOneTouchConnectionInfo] = useState(null);
    const errorCode = query.get("oneTouchConnectError");

    async function connect() {
        setIsConnecting(true);
        window.location.href = `${FetchOptions.apiUrl}/one-touch/connect`;
    }

    async function disconnect() {
        const response = await apiPost("/one-touch/disconnect");
        if (response.isOk) await getStatus();
    }

    async function getStatus() {
        const response = await apiGet("/one-touch/status");
        if (response.isOk) {
            setOneTouchConnectionInfo(response.json);
        }
    }

    useEffect(() => {
        getStatus();
    }, []);

    return { connect, disconnect, isConnecting, oneTouchConnectionInfo, ApiErrors, errorCode };
}
