import AppSettings from "@profilog/front/app/AppSettings";
import { useTranslation } from "react-i18next";

export function useMainMenu() {
    const { t } = useTranslation();

    if (AppSettings.isPatientPreview) {
        return [
            { to: "records", text: t("header.NavBar.Records") },
            { to: "settings", text: t("loginDisplay.Settings"), isLast: true },
        ];
    }

    return [
        { to: "records", text: t("header.NavBar.Records") },
        { to: "communication", text: t("header.NavBar.Communication") },
        { to: "about-me", text: t("header.NavBar.AboutMe") },
        { to: "settings", text: t("loginDisplay.Settings"), isLast: true },
    ];
}
