import { useFetch } from "../../utils/useFetch";

export default function useChartPointRecords() {
    const { apiPost, ApiErrors: ApiGetErrors } = useFetch();
    const { apiPatch, ApiErrors: ApiDeleteErrors } = useFetch();

    async function getRecords(medicalSubjectId, dateFrom, dateTo, parameterSystemName, source, page, pageSize) {
        let sourceFilters = null;
        if (source) sourceFilters = [{ nodeFilters: source }];

        const dto = {
            medicalSubjectId: medicalSubjectId,
            utcFrom: dateFrom.toISOString(),
            utcTo: dateTo.toISOString(),
            recordFilters: [{ recordTypeName: parameterSystemName, sourceFilters: sourceFilters }],
            pagination: { page: page, pageSize: pageSize },
        };
        return await apiPost("/v2/medical-subjects/records/get-raw", dto);
    }

    async function ignoreRecord(medicalSubjectId, recordId, recordTypeName) {
        const payload = {
            medicalSubjectId,
            recordId,
            recordTypeName,
        };
        return await apiPatch(`/v2/medical-subjects/records/ignore`, payload);
    }

    return {
        getRecords,
        ignoreRecord,
        ApiGetErrors,
        ApiDeleteErrors,
    };
}
