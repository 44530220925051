import React, { Fragment } from "react";
import { useState, useEffect, useRef } from "react";
import RecordsGrid from "./components/RecordsGrid";
import RecordsChart from "./components/RecordsChart";
import CpzRecordsChart from "./components/CpzRecordsChart";
import { useTranslation } from "react-i18next";
import "./RecordsVisualization.scss";
import IsMobile from "../utils/IsMobile";
import { Button, Link } from "@mui/material";
import CircularProgressBlock from "@profilog/commons/components/CircularProgressBlock";
import tw from "twin.macro";
import { useCheckCaloricsTablesImport } from "@profilog/misc/records/fetch/useCheckCaloricsTablesImport";
import PredictedChart from "./components/PredictedChart";
import AppSettings from "@profilog/front/app/AppSettings";
import ActivitiesGrid from "@profilog/misc/records/components/ActivitiesGrid";

const ChartGroupDiv = tw.div`bg-white shadow-updown rounded-md mb-12`;

export default function RecordsVisualization({
    fetchService,
    isGraphAlwaysVisible,
    project,
    isUnivTemplate,
    isForMM,
    isMobile,
    hideGrid,
    hideEmptyCharts,
    hideCaloricsTablesButton,
    hideMessages,
    lifmat,
    chartGroupControlsCss,
    showActivities = false,
    moreParameters = false,
}) {
    const { t } = useTranslation();
    const [isLoaded, setIsLoaded] = useState(false);
    const { parametersData, extConnectorsGetInfoAsync, getLastRecordDateByParameterAsync, GetApiErrors } = fetchService;
    const [chartGroups, setChartGroups] = useState();
    const [visibleChartGroups, setVisibleChartGroups] = useState();
    const [dateFromFirstGroup, setDateFromFirstGroup] = useState();
    const [externalServicesSettings, setExternalServicesSettings] = useState(isUnivTemplate ? null : {});
    const { checkCtImportPending, checkCtImportDone, CheckCtImportApiErrors } = useCheckCaloricsTablesImport(
        externalServicesSettings ? externalServicesSettings.areCaloricsTablesConnected ?? false : null,
    );

    const dayChartGroupRef = useRef(null);
    const dayIndex = IsMobile() || hideEmptyCharts ? 0 : 1;
    const [isOpenNextCharts, setIsOpenNextCharts] = useState(true);

    function getTwoWeeks() {
        const data = {
            filter: { visibleDaysCount: "TwoWeeks", groupingType: "day" },
            index: 1,
            charts: [
                {
                    parameterIds: [
                        parametersData.parameterSettings.find((p) => p.systemName === "EnergyIntakeInKiloJoules").id,
                    ],
                },
                {
                    parameterIds: [
                        parametersData.parameterSettings.find((p) => p.systemName === "Steps").id,
                        parametersData.parameterSettings.find((p) => p.systemName === "HeartRateInBeatsPerMinute").id,
                    ],
                },
                {
                    parameterIds: [
                        parametersData.parameterSettings.find((p) => p.systemName === "SystolicPressure").id,
                        parametersData.parameterSettings.find((p) => p.systemName === "DiastolicPressure").id,
                    ],
                },
            ],
        };

        return data;
    }

    function getTwoWeeksMobile() {
        const data = {
            filter: { visibleDaysCount: "TwoWeeks", groupingType: "day" },
            index: 1,
            charts: [
                {
                    parameterIds: [
                        parametersData.parameterSettings.find((p) => p.systemName === "Steps").id,
                        parametersData.parameterSettings.find((p) => p.systemName === "HeartRateInBeatsPerMinute").id,
                    ],
                },
                {
                    parameterIds: [
                        parametersData.parameterSettings.find((p) => p.systemName === "EnergyIntakeInKiloJoules").id,
                    ],
                },
                {
                    parameterIds: [
                        parametersData.parameterSettings.find((p) => p.systemName === "SystolicPressure").id,
                        parametersData.parameterSettings.find((p) => p.systemName === "DiastolicPressure").id,
                    ],
                },
            ],
        };

        return data;
    }

    function getDay() {
        const data = {
            filter: { visibleDaysCount: "Day", groupingType: "hour" },
            index: 2,
            charts: [
                {
                    parameterIds: [
                        parametersData.parameterSettings.find((p) => p.systemName === "Steps").id,
                        parametersData.parameterSettings.find((p) => p.systemName === "HeartRateInBeatsPerMinute").id,
                    ],
                },
                {
                    parameterIds: [
                        parametersData.parameterSettings.find((p) => p.systemName === "EnergyIntakeInKiloJoules").id,
                    ],
                },
                {
                    parameterIds: [
                        parametersData.parameterSettings.find((p) => p.systemName === "SpO2").id,
                        parametersData.parameterSettings.find((p) => p.systemName === "Odi").id,
                    ],
                },
                { parameterIds: [parametersData.parameterSettings.find((p) => p.systemName === "Glycemia").id] },
            ],
        };

        //http://mlog/T630627
        if (isForMM) {
            data.charts.push({
                parameterIds: [
                    parametersData.parameterSettings.find((p) => p.systemName === "SystolicPressure").id,
                    parametersData.parameterSettings.find((p) => p.systemName === "DiastolicPressure").id,
                ],
            });
        }

        if (lifmat) addLifmatCharts(data.charts);

        return data;
    }

    function getInterval() {
        const data = {
            filter: { visibleDaysCount: project === "dicatil" ? "Week" : "Year", groupingType: "day" },
            index: 3,
            charts: [
                {
                    parameterIds: [
                        parametersData.parameterSettings.find((p) => p.systemName === "WeightInKilograms").id,
                    ],
                },
                { parameterIds: [parametersData.parameterSettings.find((p) => p.systemName === "Glycemia").id] },
            ],
        };

        return data;
    }

    function getPredicted() {
        return [{ parameter: "Glycemia" }];
    }

    function addLifmatCharts(charts) {
        charts.push({
            renderTitle: () => (
                <div tw="flex justify-between">
                    <div>Tvrdý [cl]</div>
                    <div>Alkohol</div>
                    <div>Lehký [dl]</div>
                </div>
            ),
            parameterIds: [
                parametersData.parameterSettings.find((p) => p.systemName === "HardLiquor").id,
                parametersData.parameterSettings.find((p) => p.systemName === "LightAlcohol").id,
            ],
        });
    }

    function getChartGroups() {
        if (isUnivTemplate) {
            if (hideEmptyCharts) return [getDay(), getInterval()];
            if (IsMobile()) return [getDay(), getTwoWeeksMobile(), getInterval()];
            return [getTwoWeeks(), getDay(), getInterval()];
        } else {
            return [
                {
                    visibleDaysCountValues: [null, 30, 14, 7, 1],
                    filter: { visibleDaysCount: 1, measureGroupingType: "hour", stateGroupingType: "second" },
                    charts: [{}],
                },
            ];
        }
    }

    useEffect(() => {
        if (parametersData) {
            setIsLoaded(true);
        }
    }, [parametersData]);

    useEffect(() => {
        if (parametersData && checkCtImportDone) {
            setChartGroups(getChartGroups());
        }
    }, [parametersData, checkCtImportDone]); // eslint-disable-line

    useEffect(() => {
        if (!isUnivTemplate) return;

        async function loadExtConnectorsGetInfo() {
            const response = await extConnectorsGetInfoAsync();
            if (response.isOk) setExternalServicesSettings(response.json);
        }
        loadExtConnectorsGetInfo();
    }, []);

    useEffect(() => {
        async function loadLastRecordDates() {
            if (hideEmptyCharts) {
                let paramIds = [];

                for (let index = 0; index < chartGroups.length; index++) {
                    const chartGroup = chartGroups[index];

                    for (let chartIndex = 0; chartIndex < chartGroup.charts.length; chartIndex++) {
                        const chart = chartGroup.charts[chartIndex];
                        paramIds = paramIds.concat(chart.parameterIds);
                    }
                }

                const response = await getLastRecordDateByParameterAsync(paramIds);
                if (response.isOk) {
                    const visibleGroups = [];
                    for (let index = 0; index < chartGroups.length; index++) {
                        const chartGroup = chartGroups[index];

                        let date = undefined;

                        for (let chartIndex = 0; chartIndex < chartGroup.charts.length; chartIndex++) {
                            const chart = chartGroup.charts[chartIndex];

                            for (let paramIndex = 0; paramIndex < chart.parameterIds.length; paramIndex++) {
                                const paramId = chart.parameterIds[paramIndex];
                                const paramDateString = response.json[paramId];
                                if (paramDateString) {
                                    const paramDate = new Date(paramDateString);
                                    if (date === undefined || paramDate.getTime() > date.getTime()) date = paramDate;
                                }
                            }
                        }

                        visibleGroups.push({ visible: date !== undefined, date: date });
                    }
                    setVisibleChartGroups(visibleGroups);
                }
            } else {
                const visibleGroups = [];
                for (let index = 0; index < chartGroups.length; index++) {
                    visibleGroups.push({ visible: true, date: undefined });
                }
                setVisibleChartGroups(visibleGroups);
            }
        }

        if (chartGroups) loadLastRecordDates();
    }, [chartGroups]);

    function onAddChartGroup(e) {
        let newGroups = [...chartGroups];
        newGroups.push(JSON.parse(JSON.stringify(newGroups[newGroups.length - 1])));
        setChartGroups(newGroups);
    }

    function onRemoveChartGroup(index) {
        let groups = [...chartGroups];
        groups.splice(index, 1);
        setChartGroups(groups);
    }

    function visibleDateChanged(date, scroll) {
        if (scroll) scrollToDayChartGroup();
        setDateFromFirstGroup(date);
    }

    function scrollToDayChartGroup() {
        if (dayChartGroupRef.current) {
            window.scrollTo({
                top: dayChartGroupRef.current.getBoundingClientRect().top + window.pageYOffset - 80,
                behavior: "smooth",
            });
        }
    }

    if (!isLoaded || !checkCtImportDone || !chartGroups || !visibleChartGroups)
        return (
            <CircularProgressBlock
                message={checkCtImportPending ? t("recordsVisualization.LoadingDataFromCaloricsTables") : null}
            />
        );

    const predictorUsers = [
        4788, // Čermák
        209, // Kádě
        166, // Matoulek
        217, // Staňová
        1573, // Staňová
        4333, // Vančo
        34813, // Kubanda

        2951, // MCH
        3795, // LN
        23347, // AR
    ];

    let allowPredictions =
        AppSettings.isDicatil ||
        (AppSettings.isCpz && AppSettings.isPatientPreview && predictorUsers.includes(AppSettings.profilogUserId));

    return (
        <Fragment>
            <GetApiErrors />
            <CheckCtImportApiErrors modal />
            {parametersData && externalServicesSettings && (
                <Fragment>
                    {isUnivTemplate && chartGroups && (
                        <Fragment>
                            {chartGroups.map((chartGroup, index) => (
                                <React.Fragment key={index}>
                                    {visibleChartGroups[index].visible && (
                                        <ChartGroupDiv key={index} ref={index === dayIndex ? dayChartGroupRef : null}>
                                            <CpzRecordsChart
                                                fetchService={fetchService}
                                                chartGroup={chartGroup}
                                                onVisibleDateChanged={index !== dayIndex ? visibleDateChanged : null}
                                                initiateDate={
                                                    index === dayIndex || visibleChartGroups[index].date !== undefined
                                                        ? dateFromFirstGroup === undefined
                                                            ? visibleChartGroups[index].date
                                                            : dateFromFirstGroup
                                                        : undefined
                                                }
                                                externalServicesSettings={externalServicesSettings}
                                                scrollToMe={index === dayIndex ? scrollToDayChartGroup : null}
                                                project={project}
                                                isForMM={isForMM}
                                                isMobile={isMobile}
                                                hideEmptyCharts={hideEmptyCharts}
                                                hideCaloricsTablesButton={hideCaloricsTablesButton}
                                                hideMessages={hideMessages}
                                            />

                                            <div tw="clear-both"></div>
                                        </ChartGroupDiv>
                                    )}
                                </React.Fragment>
                            ))}
                            {allowPredictions &&
                                getPredicted().map((data, index) => (
                                    <React.Fragment key={index}>
                                        <PredictedChart
                                            parameter={data.parameter}
                                            medicalSubjectId={fetchService.medicalSubjectId}
                                        />
                                    </React.Fragment>
                                ))}

                            {moreParameters && (
                                <Fragment>
                                    <div tw="pb-2 text-center">
                                        <Link
                                            color="primary"
                                            tw="font-bold"
                                            href="#chart-group"
                                            onClick={() => setIsOpenNextCharts(!isOpenNextCharts)}
                                        >
                                            {isOpenNextCharts
                                                ? t("recordsVisualization.LessParameters")
                                                : t("recordsVisualization.MoreParameters")}
                                        </Link>
                                    </div>
                                    {isOpenNextCharts && (
                                        <ChartGroupDiv>
                                            <RecordsChart
                                                fetchService={fetchService}
                                                isAllowedSaveVisibilities={true}
                                                chartGroup={{
                                                    visibleDaysCountValues: [null, 30, 14, 7, 1],
                                                    filter: { visibleDaysCount: 7, groupingType: "day" },
                                                    charts: [{}],
                                                }}
                                                isLiteMode={true}
                                            />
                                        </ChartGroupDiv>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                    {isGraphAlwaysVisible && !isUnivTemplate && (
                        <div className="chart-groups">
                            {chartGroups.map((chartGroup, index) => (
                                <React.Fragment key={index}>
                                    <div className="chart-group" key={index}>
                                        <RecordsChart
                                            fetchService={fetchService}
                                            isAllowedSaveVisibilities={index === 0}
                                            chartGroup={chartGroup}
                                        />
                                        {index > 0 && (
                                            <div className="remove-group">
                                                <button
                                                    className="btn btn-link btn-records t-2 p-0 flex-grow-1 d-inline-block text-right font-weight-semibold position-relative"
                                                    onClick={() => onRemoveChartGroup(index)}
                                                >
                                                    {t("recordsVisualization.RemoveChart")}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </React.Fragment>
                            ))}
                            <div className="add-group">
                                <Button onClick={(e) => onAddChartGroup(e)}>
                                    {t("recordsVisualization.AddChartGroup")}
                                </Button>
                            </div>
                        </div>
                    )}
                    {!hideGrid && <RecordsGrid fetchService={fetchService} />}
                    {showActivities && <ActivitiesGrid fetchService={fetchService} />}
                </Fragment>
            )}
        </Fragment>
    );
}
