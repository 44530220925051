import { useSelector, useDispatch } from "react-redux";
import { prescriptionUpdate } from "../../redux/prescriptionSlice";
import { useGetSurveys } from "../../redux/codebooksSlice";
import Prescription from "./Prescription";
import { useTranslation } from "react-i18next";
import CodebookComboBox from "../codebooks/CodebookComboBox";

export default function SurveyPrescription({ prescriptionId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const groupId = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].groupId);
    const parameterGroup = useSelector(
        (state) => state.prescriptions.prescriptions.byId[prescriptionId].parameterGroup,
    );

    return (
        <Prescription prescriptionId={prescriptionId} title={t("prescriptions.Types.Survey")}>
            <div>
                <CodebookComboBox
                    value={parameterGroup}
                    onChange={dispatchOptionChange}
                    getOptionsHook={useGetSurveys}
                    getOptionsArg={{ groupId }}
                    autocompleteProps={{
                        label: t("prescriptions.Types.Survey"),
                        autoFocus: true,
                        autoHighlight: true,
                        autoSelect: true,
                    }}
                    tw="w-72"
                />
            </div>
        </Prescription>
    );

    function dispatchOptionChange(option) {
        dispatch(prescriptionUpdate({ prescriptionId, updated: { parameterGroup: option } }));
    }
}
