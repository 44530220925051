import { css } from "twin.macro";

const containerCss = css`
    width: 100%;

    @media (min-width: 576px) {
        width: 540px;
    }
    @media (min-width: 768px) {
        width: 720px;
    }
    @media (min-width: 992px) {
        width: 960px;
    }
    @media (min-width: 1200px) {
        width: 1140px;
    }
`;

export default function Container({ className, children }) {
    return (
        <div css={containerCss} tw="px-3.5 sm:(mx-auto)" className={className}>
            {children}
        </div>
    );
}
