import { useSelector, useDispatch } from "react-redux";
import { prescriptionUpdate } from "../../redux/prescriptionSlice";
import ActionComboBox from "../codebooks/ActionComboBox";
import Prescription from "./Prescription";
import { useTranslation } from "react-i18next";

export default function ActionPrescription({ prescriptionId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const action = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].action);

    return (
        <Prescription prescriptionId={prescriptionId} title={t("prescriptions.Types.Action")}>
            <div>
                <ActionComboBox
                    value={action}
                    onChange={dispatchOptionChange}
                    autocompleteProps={{ autoFocus: true }}
                    tw="w-64"
                />
            </div>
        </Prescription>
    );

    function dispatchOptionChange(option) {
        dispatch(prescriptionUpdate({ prescriptionId, updated: { action: option } }));
    }
}
