import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../utils/useFetch";
import { useGdprText, GdprTextOutput } from "./GdprText";

export function GdprTextConsentDialog({ open, onConsent, onDisconsent }) {
    const { apiPut, ApiErrors: ApiPostGdprErrors } = useFetch();
    const { t } = useTranslation();
    const { gdprData, isLoading, ApiErrors } = useGdprText("consent");

    async function consentToTerms() {
        let response = await apiPut("/web/user/consent-with-gdpr-terms");
        if (response.isOk) onConsent();
    }

    if (isLoading) return null;

    return (
        <Fragment>
            <ApiPostGdprErrors />
            <Dialog open={open} maxWidth="lg" fullWidth>
                <DialogContent>
                    <GdprTextOutput gdprData={gdprData} ApiErrors={ApiErrors} />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="success" onClick={consentToTerms}>
                        {t("auth.GdprConsent.ConsentButton")}
                    </Button>
                    <Button variant="contained" color="error" onClick={onDisconsent}>
                        {t("auth.GdprConsent.DisconsentButton")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
