import { useState, useEffect, useRef } from "react";
import { useModal } from "@profilog/misc/commons/components/Modal";
import { useForm } from "react-hook-form";
import { useFetch } from "@profilog/misc/utils/useFetch";
import { useTranslation } from "react-i18next";

export function useGroupEditModal() {
    const [groupData, setGroupData] = useState(null);
    const { isModalOpen, openModal, closeModal, Modal } = useModal();

    return { openGroupEditModal, GroupEditModal };

    function openGroupEditModal(groupId, onSaved) {
        setGroupData({ groupId, onSaved });
        openModal();
    }

    function GroupEditModal() {
        const { t } = useTranslation();
        const {
            register,
            handleSubmit,
            errors,
            formState: { isSubmitting },
        } = useForm();
        const nameRef = useRef();
        const [editedGroup, setEditedGroup] = useState({});
        const { apiGet, apiPost, apiPut, ApiErrors } = useFetch();

        useEffect(() => {
            if (isModalOpen && nameRef.current) {
                nameRef.current.focus();
            }
        }, [isModalOpen, editedGroup]);

        useEffect(() => {
            async function getGroup(groupId) {
                if (isModalOpen && groupId) {
                    let result = await apiGet("/groups/" + groupId);
                    setEditedGroup(result.json);
                }
            }
            getGroup(groupData?.groupId);
        }, [isModalOpen, groupData]);

        if (!isModalOpen) return null;

        if (Object.keys(editedGroup).length === 0 && editedGroup.constructor === Object && groupData.groupId !== null) {
            return null;
        }

        async function saveGroup(data) {
            const isOk = groupData.groupId ? await updateGroup(data) : await createGroup(data);
            if (isOk) {
                setEditedGroup({});
                closeModal();
                groupData.onSaved();
            }
        }

        async function updateGroup(data) {
            const updateGroupDto = {
                name: data.name,
                description: data.description,
            };

            const response = await apiPut("/groups/" + groupData.groupId, updateGroupDto);
            return response.isOk;
        }

        async function createGroup(data) {
            const createGroupDto = {
                name: data.name,
                description: data.description,
            };

            const response = await apiPost("/groups", createGroupDto);
            return response.isOk;
        }

        return (
            <Modal title={t("groups.Group")}>
                <form onSubmit={handleSubmit(saveGroup)}>
                    <div className="form-group">
                        <label htmlFor="groupEdit-name">{t("groups.Name")}</label>
                        <input
                            type="text"
                            id="groupEdit-name"
                            className={"form-control" + (errors.name ? " border border-danger" : "")}
                            name="name"
                            defaultValue={editedGroup.name}
                            ref={(e) => {
                                register(e, { required: true });
                                nameRef.current = e;
                            }}
                        />
                        {errors.name && <div>{t("global.RequiredField")}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="groupEdit-description">{t("groups.Description")}</label>
                        <textarea
                            id="groupEdit-description"
                            className="form-control"
                            name="description"
                            defaultValue={editedGroup.description}
                            ref={register}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                            {t("global.Save")}
                        </button>
                        <ApiErrors />
                    </div>
                </form>
            </Modal>
        );
    }
}
