import { CircularProgress } from "@mui/material";

export default function CircularProgressBlock({ message }) {
    return (
        <div
            tw="fixed top[50%] left[50%] transform[translate(-50%, -50%)]
                flex flex-col items-center bg-white p-5 rounded-2xl bg-opacity-90 shadow-updown"
        >
            <CircularProgress />
            {message && <div>{message}</div>}
        </div>
    );
}
