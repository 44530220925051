import { useNavigate, useParams } from "react-router";
import { findSurveyComponentByUrl } from "@profilog/surveys/surveysRegistry";
import AppSettings from "../../app/AppSettings";
import { useScrollToTop } from "@profilog/commons/hooks/useScrollToTop";

export default function Survey({ NotFound }) {
    useScrollToTop();
    const navigate = useNavigate();

    const { surveyUrlParam } = useParams();
    const SurveyComponent = findSurveyComponentByUrl(surveyUrlParam);

    if (!SurveyComponent) return <NotFound />;

    return (
        <SurveyComponent
            medicalSubjectId={AppSettings.user.userMedicalSubjectId}
            onSaved={() => {
                if (AppSettings.isMobileApp) window.location.pathname += "/saved";
                else navigate("saved");
            }}
        />
    );
}
