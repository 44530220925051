import PrescriptionSchedule from "../schedules/PrescriptionSchedule";
import { useSelector, useDispatch } from "react-redux";
import { prescriptionScheduleCreate, prescriptionUpdate } from "../../redux/prescriptionSlice";
import EditPanel from "../commons/EditPanel";
import PrescriptionDuration from "./PrescriptionDuration";
import { FastScheduleSettings } from "./FastScheduleSettings";
import { scheduleTypes } from "../../codebooks/scheduleTypes";
import { stringToStringOrNull } from "@profilog/utils/converting";
import TimeScheduleGroupSettings from "./TimeScheduleGroupSettings";
import tw from "twin.macro";
import { Button, TextField } from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const PrescRowDiv = tw.div`flex-1 flex items-center flex-wrap gap-x-3 gap-y-3 mb-4`;

export default function Prescription({ prescriptionId, children }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const createdByFullName = useSelector(
        (state) => state.prescriptions.prescriptions.byId[prescriptionId].createdByFullName,
    );
    const createdTime = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].createdTime);
    const createdTimeString = createdTime ? format(new Date(createdTime), "dd. MM. yyyy") : null;
    const updatedByFullName = useSelector(
        (state) => state.prescriptions.prescriptions.byId[prescriptionId].updatedByFullName,
    );
    const updatedTime = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].updatedTime);
    const updatedTimeString = updatedTime ? format(new Date(updatedTime), "dd. MM. yyyy") : null;

    const scheduleType = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].scheduleType);
    const amountNote = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].amountNote);
    const amountNoteSame = useSelector(
        (state) => state.prescriptions.prescriptions.byId[prescriptionId].amountNoteSame,
    );

    const forceScheduleList = useSelector(
        (state) => state.prescriptions.prescriptions.byId[prescriptionId].forceScheduleList,
    );

    const openScheduleList = useSelector(
        (state) => state.prescriptions.prescriptions.byId[prescriptionId].openScheduleList,
    );

    return (
        <Fragment>
            <PrescRowDiv>
                {children}

                <FastScheduleSettings prescriptionId={prescriptionId} />

                {!forceScheduleList && (
                    <div>
                        <TextField
                            size="small"
                            sx={{ width: "9ch" }}
                            label={t("prescriptions.Edit.NoteShort")}
                            InputLabelProps={{ shrink: true }}
                            placeholder={amountNoteSame ? "" : t("prescriptions.Edit.Misc")}
                            value={amountNoteSame ? amountNote ?? "" : ""}
                            onChange={(e) =>
                                dispatchPrescriptionUpdate({ amountNote: stringToStringOrNull(e.target.value) })
                            }
                        />
                    </div>
                )}
                <EditPanel>
                    {(editing, open, wasOpen) => (
                        <PrescriptionDuration
                            prescriptionId={prescriptionId}
                            editing={editing}
                            open={open}
                            wasOpen={wasOpen}
                        />
                    )}
                </EditPanel>

                {!forceScheduleList && scheduleType === scheduleTypes.time && (
                    <TimeScheduleGroupSettings prescriptionId={prescriptionId} />
                )}
            </PrescRowDiv>
            <ScheduleList prescriptionId={prescriptionId} showAlways={forceScheduleList || openScheduleList} />
            {prescriptionId > 0 && (
                <div tw="flex flex-wrap justify-between">
                    {createdByFullName && (
                        <div tw="text-xs">
                            {t("prescriptions.Edit.CreatedBy")} {createdTimeString} <strong>{createdByFullName}</strong>
                        </div>
                    )}
                    {updatedByFullName && createdTimeString !== updatedTimeString && (
                        <div tw="text-xs">
                            {t("prescriptions.Edit.EditedBy")} {updatedTimeString} <strong>{updatedByFullName}</strong>
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );

    function dispatchPrescriptionUpdate(updated) {
        dispatch(prescriptionUpdate({ prescriptionId, updated }));
    }
}

function ScheduleList({ prescriptionId, showAlways }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const scheduleType = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].scheduleType);
    const showSchedules = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].showSchedules);
    const schedules = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].schedules);

    return (
        <div>
            {!showAlways && (
                <div>
                    <Button
                        size="small"
                        tw="normal-case px-0"
                        onClick={() => dispatchPrescriptionUpdate({ showSchedules: !showSchedules })}
                        startIcon={!showSchedules ? <AddCircleIcon /> : <RemoveCircleIcon />}
                    >
                        {t("prescriptions.Edit.AdvancedSettings")}
                    </Button>
                </div>
            )}
            {(showAlways || showSchedules) && (
                <div tw="mt-2">
                    {schedules.length === 0 && (
                        <div>
                            <em>{t("prescriptions.Edit.WithoutPlan")}</em>
                        </div>
                    )}
                    {schedules.map((scheduleId, index) => (
                        <PrescriptionSchedule
                            key={scheduleId}
                            scheduleId={scheduleId}
                            index={index}
                            last={index === schedules.length - 1}
                        />
                    ))}
                    <Button
                        size="small"
                        tw="px-0 normal-case"
                        onClick={dispatchScheduleCreate}
                        startIcon={<AddCircleIcon />}
                    >
                        {t("prescriptions.Edit.AddPlan")}
                    </Button>
                </div>
            )}
        </div>
    );

    function dispatchPrescriptionUpdate(updated) {
        dispatch(prescriptionUpdate({ prescriptionId, updated }));
    }

    function dispatchScheduleCreate() {
        dispatch(prescriptionScheduleCreate({ prescriptionId, scheduleType }));
    }
}
