import { useTranslation } from "react-i18next";
import ExtConnector from "./ExtConnector";
import useFitbit from "../hooks/useFitbit";
import { isConnected } from "../codebooks/externalConnectionStatus";

export default function FitbitConnection({ readonly, Layout, refresh, onDisconnect }) {
    const { t } = useTranslation();
    const { fitbitConnectionInfo, connect, disconnect, ApiErrors, fitbitApiError } = useFitbit(refresh);

    return (
        <ExtConnector
            title={t("extConnectors.Fitbit.Title")}
            infoText={t("extConnectors.Fitbit.InfoText")}
            connected={isConnected(fitbitConnectionInfo?.status)}
            account={""}
            connectButtonLabel={t("extConnectors.Fitbit.ConnectButtonLabel")}
            disconnectButtonLabel={t("extConnectors.Fitbit.DisconnectButtonLabel")}
            lastImportDate={fitbitConnectionInfo?.lastImportAt}
            status={fitbitConnectionInfo?.status}
            onConnectClick={connect}
            onDisconnectClick={handleDisconnect}
            ApiErrors={ApiErrors}
            customError={fitbitApiError}
            readonly={readonly}
            Layout={Layout}
        />
    );

    async function handleDisconnect() {
        await disconnect();
        if (onDisconnect) onDisconnect();
    }
}
