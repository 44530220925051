import GenericApp from "../generic/GenericApp";
import HhLogo from "./components/HhLogo";
import "./HhApp.scss";
import HhFooter from "./components/HhFooter";

export default function HhApp() {
    return (
        <GenericApp
            logo={<HhLogo />}
            hidePoweredBy
            renderFooter={() => (
                <HhFooter
                    companyName="MagicWare s.r.o."
                    companyUrl="https://www.magicware.cz"
                    email="info@profilog.com"
                />
            )}
        />
    );
}
