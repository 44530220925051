import { useState, useEffect } from "react";
import FormSelector from "./FormSelector";
import { apiGet } from "@profilog/misc/utils/useFetch";
import { useTranslation } from "react-i18next";

const CountrySelector = ({ id, selectedValue, name, formRegister, onLoadError, className }) => {
    const emptyItem = {
        value: "undefined",
        name: "---",
    };
    const [countries, setCountries] = useState([emptyItem]);
    const { i18n } = useTranslation();

    useEffect(() => {
        getCountries();
        // eslint-disable-next-line
    }, [i18n.language]);

    async function getCountries() {
        let response = await apiGet("/web/countries/?" + new URLSearchParams({ culture: i18n.language }));
        if (response.isOk) {
            setCountries([emptyItem].concat(response.json));
        } else {
            onLoadError(response.errors);
        }
    }

    return (
        <FormSelector
            className={className}
            id={id}
            items={countries}
            selectedValue={selectedValue}
            name={name}
            formRegister={formRegister}
        />
    );
};

export default CountrySelector;
