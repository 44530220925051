import { Fragment, useEffect, useState } from "react";
import { addStatusCallback, INVALID_VERSION, DEPLOY_IN_PROGRESS } from "../../utils/FetchOptions";
import { apiFetch } from "../../utils/useFetch";
import { useTranslation } from "react-i18next";
import { Link, LinearProgress } from "@mui/material";

export default function ApiStatusCheck({ children }) {
    const [status, setStatus] = useState(null);
    const [offlineUntilTime, setOfflineUntilTime] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        addStatusCallback((status) => setStatus(status));
    }, []); // eslint-disable-line

    useEffect(() => {
        if (status === DEPLOY_IN_PROGRESS) setOnlineStatusTimeout();
        if (status === INVALID_VERSION) setReloadTimeout();
    }, [status]);

    if (status === null) return children;

    return (
        <div tw="max-width[600px] p-4 mx-auto text-center">
            {status === DEPLOY_IN_PROGRESS && (
                <Fragment>
                    <Title>
                        {t("apiStatusCheck.Deploy.Title")}
                        <br />
                        {process.env.REACT_APP_TITLE}
                    </Title>

                    <LinearProgress variant="indeterminate" />

                    {offlineUntilTime && (
                        <div tw="mt-4">
                            {t("apiStatusCheck.Deploy.OfflineUntil")} <strong>{offlineUntilTime}</strong>
                        </div>
                    )}
                    <div tw="mt-4">{t("apiStatusCheck.Deploy.Body")}</div>
                </Fragment>
            )}

            {status === INVALID_VERSION && (
                <div className="apistatuscheck">
                    <Title>{t("apiStatusCheck.InvalidVersion.Title")}</Title>
                    <div tw="mb-4">{t("apiStatusCheck.InvalidVersion.Body.AutoReload")}</div>
                    <div tw="mb-4">
                        <Link href=".">{t("apiStatusCheck.InvalidVersion.Reload")}</Link>
                    </div>
                    <div tw="mb-2">
                        {t("apiStatusCheck.InvalidVersion.Problem")}
                        <br />
                        {t("apiStatusCheck.InvalidVersion.ReloadWithoutCache")}
                    </div>
                    <div>
                        <strong>Ctrl+F5</strong> {t("apiStatusCheck.InvalidVersion.On.Windows")} /{" "}
                        <strong>Command + R</strong> {t("apiStatusCheck.InvalidVersion.On.Mac")}
                    </div>
                </div>
            )}
        </div>
    );

    function setOnlineStatusTimeout() {
        window.setTimeout(async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + "/web/appdata/online-status");

            if (response.ok) {
                window.location.reload();
                return;
            }

            try {
                const text = await response.text();
                const regex = /\[OfflineUntil (\d+:\d+)\]/;
                const match = text.match(regex);
                if (match) setOfflineUntilTime(match[1]);
            } catch {}

            setOnlineStatusTimeout();
        }, 1000);
    }
}

function setReloadTimeout() {
    window.setTimeout(async () => window.location.reload(), 10000);
}

function Title({ children }) {
    return <h1 tw="text-xl font-bold mb-4">{children}</h1>;
}
