import { useState, useEffect } from "react";
import { useFetch } from "../../utils/useFetch";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
/*
!!!!
pozor pokud se zde budou měnit veřejné metody a parametry,
je potřeba změnit i všechny ostatní useXXXFetche v této složce, tak aby měli vždy stejné "rozhraní"
!!!!!
*/
export function useMedicalSubjectFetch(
    medicalSubjectId,
    isPatientPreview,
    profilogUserId,
    profilogUserPersonFullName,
    profilogUserEnergyType,
    version = null,
) {
    const { i18n } = useTranslation();
    const { apiGet, ApiErrors: GetApiErrors } = useFetch();
    const { apiPost, ApiErrors: PostApiErrors } = useFetch();
    const { apiPut, ApiErrors: PutApiErrors } = useFetch();
    const [parametersData, setParametersData] = useState();
    const [lastRecordDate, setLastRecordDate] = useState();

    useEffect(() => {
        if (medicalSubjectId == null) return;

        setParametersData();
        const getParametersData = async () => {
            let result = await apiGet(
                "/web/parameters?medicalSubjectId=" +
                    (medicalSubjectId != null ? medicalSubjectId : "") +
                    "&energyType=" +
                    (isPatientPreview && profilogUserEnergyType ? profilogUserEnergyType : ""),
                true,
            );
            if (result.isOk) setParametersData(result.json);
        };

        const getLastRecordDate = async () => {
            let result = await apiGet(
                "/records/getLastRecordDate?medicalSubjectId=" + (medicalSubjectId != null ? medicalSubjectId : ""),
                true,
            );
            if (result.isOk) {
                if (result.json === null) {
                    setLastRecordDate(null);
                } else {
                    setLastRecordDate(new Date(result.json));
                }
            }
        };

        getParametersData();
        getLastRecordDate();
    }, [medicalSubjectId, i18n.language, version]);

    return {
        medicalSubjectId: medicalSubjectId,
        parametersData: parametersData,
        lastRecordDate: lastRecordDate,
        isPatientPreview: isPatientPreview,
        profilogUserId: profilogUserId,
        profilogUserPersonFullName: profilogUserPersonFullName,
        profilogUserEnergyType: profilogUserEnergyType,
        setLastRecordDate: setLastRecordDate,
        getUserDataAsync: getUserData,
        getUserDataForCpzAsync: getUserDataForCpz,
        getGlycemiaDataAsync: getGlycemiaData,
        getRecordDatesAsync: getRecordDates,
        getLastRecordDateByParameterAsync: getLastRecordDateByParameter,
        getUserDataForGridAsync: getUserDataForGrid,
        getFoodListAsync: getFoodList,
        getCaloricsTableAsync: getCaloricsTable,
        getEcgDataAsync: getEcgData,
        getEcgListAsync: getEcgList,
        getStepsAndBpmDataAsync: getStepsAndBpmDataAsync,
        getMessagesAsync: getMessagesAsync,
        markMessagesNotificationsAsReadOrUnreadAsync: markMessagesNotificationsAsReadOrUnreadAsync,
        postChartParameterVisibilitiesAsync: postChartParameterVisibilities,
        extConnectorsGetInfoAsync: extConnectorsGetInfo,
        GetApiErrors: GetApiErrors,
        PostApiErrors: PostApiErrors,
        PutApiErrors: PutApiErrors,
        getPersonalData: getPersonalData,
        getLastActivitiesAsync: getLastActivities,
    };

    async function postChartParameterVisibilities(visibilities) {
        return await apiPost("/web/parameters/chart-visibilities", visibilities);
    }

    async function getMessagesAsync(lastMessage, messagesTrueCommentsFalseAllNull) {
        const params = {};
        params.medicalSubjectId = medicalSubjectId;
        params.messagesTrueCommentsFalseAllNull = messagesTrueCommentsFalseAllNull;
        if (isPatientPreview && profilogUserId) params.profilogUserId = profilogUserId;
        if (lastMessage) params.newerThanCreatedDate = lastMessage.createdDate;

        return await apiGet("/web/messages?" + new URLSearchParams(params));
    }

    async function markMessagesNotificationsAsReadOrUnreadAsync(markMessagesNotificationsAsReadOrUnreadDto) {
        if (isPatientPreview && profilogUserId)
            markMessagesNotificationsAsReadOrUnreadDto = {
                ...markMessagesNotificationsAsReadOrUnreadDto,
                profilogUserId: profilogUserId,
            };
        return await apiPut("/web/messages/set-as-read-or-unread", markMessagesNotificationsAsReadOrUnreadDto);
    }

    async function extConnectorsGetInfo() {
        return await apiPost("/web/external-services/get-info");
    }

    async function getCaloricsTable(selectedDateTime) {
        return await apiGet(
            "/calorics-table/getCaloricsTable?medicalSubjectId=" +
                (medicalSubjectId != null ? medicalSubjectId : "") +
                "&date=" +
                (selectedDateTime != null ? format(selectedDateTime, "yyyy-MM-dd") : "") +
                "&energyType=" +
                (isPatientPreview && profilogUserEnergyType ? profilogUserEnergyType : ""),
            true,
        );
    }
    async function getFoodList(selectedDateTime, groupedBy) {
        return await apiGet(
            "/calorics-table/getFoodList?medicalSubjectId=" +
                (medicalSubjectId != null ? medicalSubjectId : "") +
                "&date=" +
                (selectedDateTime != null ? format(selectedDateTime, "yyyy-MM-dd HH:mm:ss") : "") +
                "&groupedBy=" +
                groupedBy +
                "&energyType=" +
                (isPatientPreview && profilogUserEnergyType ? profilogUserEnergyType : ""),
            true,
        );
    }
    async function getUserData(dataDates, measureGroupedBy, stateGroupedBy, parameterIds, isLiteMode) {
        return await apiGet(
            "/records/all?medicalSubjectId=" +
                (medicalSubjectId != null ? medicalSubjectId : "") +
                "&dateFrom=" +
                (dataDates.startValue != null ? format(dataDates.startValue, "yyyy-MM-dd HH:mm:ss") : "") +
                "&dateTo=" +
                (dataDates.endValue != null ? format(dataDates.endValue, "yyyy-MM-dd HH:mm:ss") : "") +
                "&measureGroupedBy=" +
                (measureGroupedBy == null ? "" : measureGroupedBy) +
                "&stateGroupedBy=" +
                (stateGroupedBy == null ? "" : stateGroupedBy) +
                (parameterIds == null ? "" : "&parameterIds=" + parameterIds.join("&parameterIds=")) +
                "&energyType=" +
                (isPatientPreview && profilogUserEnergyType ? profilogUserEnergyType : "") +
                "&isLiteMode=" +
                isLiteMode,
            true,
        );
    }

    async function getUserDataForCpz(dataDates, groupedBy, parameterIds, isShiftSpo) {
        return await apiGet(
            "/records/all-cpz?medicalSubjectId=" +
                (medicalSubjectId != null ? medicalSubjectId : "") +
                "&dateFrom=" +
                (dataDates.dateFrom != null ? format(dataDates.dateFrom, "yyyy-MM-dd") : "") +
                "&dateTo=" +
                (dataDates.dateTo != null ? format(dataDates.dateTo, "yyyy-MM-dd") : "") +
                "&groupedBy=" +
                (groupedBy == null ? "" : groupedBy) +
                (parameterIds == null ? "" : "&parameterIds=" + parameterIds.join("&parameterIds=")) +
                "&energyType=" +
                (isPatientPreview && profilogUserEnergyType ? profilogUserEnergyType : "") +
                "&isShiftSpo=" +
                isShiftSpo,
            true,
        );
    }

    async function getGlycemiaData(dataDates) {
        return await apiGet(
            "/records/glycemia-cpz?medicalSubjectId=" +
                (medicalSubjectId != null ? medicalSubjectId : "") +
                "&dateFrom=" +
                (dataDates.dateFrom != null ? format(dataDates.dateFrom, "yyyy-MM-dd") : "") +
                "&dateTo=" +
                (dataDates.dateTo != null ? format(dataDates.dateTo, "yyyy-MM-dd") : ""),
            true,
        );
    }

    async function getEcgData(recordId, paneSeconds) {
        return await apiGet(
            "/records/ecg?medicalSubjectId=" +
                (medicalSubjectId != null ? medicalSubjectId : "") +
                "&recordId=" +
                (recordId != null ? recordId : "") +
                "&paneSeconds=" +
                (paneSeconds != null ? paneSeconds : ""),
            true,
        );
    }

    async function getRecordDates(parameterIds) {
        return await apiGet(
            "/records/get-record-dates?medicalSubjectId=" +
                (medicalSubjectId != null ? medicalSubjectId : "") +
                (parameterIds == null ? "" : "&parameterIds=" + parameterIds.join("&parameterIds=")),
            true,
        );
    }

    async function getLastRecordDateByParameter(parameterIds) {
        return await apiGet(
            "/records/getLastRecordDateByParameter?medicalSubjectId=" +
                (medicalSubjectId != null ? medicalSubjectId : "") +
                (parameterIds == null ? "" : "&parameterIds=" + parameterIds.join("&parameterIds=")),
            true,
        );
    }

    async function getUserDataForGrid(dataDates) {
        return await apiGet(
            "/records/all?medicalSubjectId=" +
                (medicalSubjectId != null ? medicalSubjectId : "") +
                "&dateFrom=" +
                (dataDates.dateFrom != null ? format(dataDates.dateFrom, "yyyy-MM-dd") : "") +
                "&dateTo=" +
                (dataDates.dateTo != null ? format(dataDates.dateTo, "yyyy-MM-dd") : ""),
            true,
        );
    }

    async function getStepsAndBpmDataAsync(dateFrom, dateTo) {
        return await apiGet(
            `/records/steps-and-bpm?dateFrom=${format(dateFrom, "yyyy-MM-dd")}&dateTo=${format(
                dateTo,
                "yyyy-MM-dd",
            )}&medicalSubjectId=${medicalSubjectId}`,
        );
    }

    async function getEcgList(dateFrom, dateTo) {
        return await apiGet(
            "/records/getEcgList?medicalSubjectId=" +
                (medicalSubjectId != null ? medicalSubjectId : "") +
                "&dateFrom=" +
                (dateFrom != null ? format(dateFrom, "yyyy-MM-dd") : "") +
                "&dateTo=" +
                (dateTo != null ? format(dateTo, "yyyy-MM-dd") : ""),
        );
    }

    async function getPersonalData() {
        if (!medicalSubjectId) return;
        return await apiGet(`/profilog/patients/${medicalSubjectId}`);
    }

    async function getLastActivities(pageSize, page) {
        if (!medicalSubjectId) return;
        pageSize = pageSize || 10;
        page = page || 1;
        var query = new URLSearchParams({
            medicalSubjectId: medicalSubjectId,
            page: page,
            pageSize: pageSize,
        });
        return await apiGet("/activities/get-last?" + query.toString());
    }
}
