import { Dialog, DialogContent, Alert } from "@mui/material";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";
import { useTranslation } from "react-i18next";

export default function NothingToSaveDialog({ onClose }: { onClose: () => void }) {
    const { t } = useTranslation();

    return (
        <Dialog open onClose={onClose}>
            <DialogCloseButton onClick={onClose} />
            <DialogContent tw="pt-10">
                <Alert severity="warning">{t("records.Edit.SaveError.NothingToSave")}</Alert>
            </DialogContent>
        </Dialog>
    );
}
