import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "@profilog/misc/utils/useFetch";
import { apiGet } from "@profilog/misc/utils/useFetch";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";

export default function VocallsAssistent({ onSaved, getMedicalSubjectId, getRecordDate }) {
    const { t } = useTranslation();
    const [userPhoneNumber, setUserPhoneNumber] = useState("");
    const [isUserPhoneNumberValid, setIsUserPhoneNumberValid] = useState(false);
    const [isDemoCall, setIsDemoCall] = useState(false);
    const { apiPost: vocallsApiPost, ApiErrors: VocallsApiErrors } = useFetch();
    const [callDetails, setCallDetailsPrivate] = useState(createEmptyCallDetails());

    useEffect(() => {
        async function getUserAsync() {
            let response = await apiGet("/web/user-profile");
            if (response.isOk) {
                let phoneNumber = response.json.phoneNumberCallCode;
                if (
                    response.json.phoneNumberCallCode &&
                    response.json.phoneNumber &&
                    response.json.phoneNumber.includes(" ")
                ) {
                    phoneNumber += " ";
                }
                if (phoneNumber) phoneNumber += response.json.phoneNumber;
                else phoneNumber = response.json.phoneNumber;

                if (!phoneNumber) phoneNumber = "";

                setPhoneNumber(phoneNumber);
            }
        }
        getUserAsync();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        async function getCallDetailsAsync() {
            if (callDetails.callStatus === "dialing") {
                if (callDetails.callGuid && callDetails.callGuid !== "00000000-0000-0000-0000-000000000000") {
                    let response = await apiGet(`/vocalls/getCallDetails?callGuid=${callDetails.callGuid}`);

                    if (callDetails.callStateCheckCount <= 120) {
                        //max 10minut
                        if (response.isOk) {
                            //hovor byl ukončen
                            setCallDetails(() => response.json);
                            if (!isDemoCall && onSaved) onSaved();
                        } else {
                            //hovor probíhá (nebo to vyzvání)
                            setTimeout(function () {
                                setCallDetails((oldDetails) => ({ ...oldDetails }));
                            }, 5000);
                        }
                    } else {
                        resetCallDetails();
                    }
                } else {
                    //hovor ještě nezačal, ale klikl jsem na tlačítko
                    if (callDetails.callStateCheckCount <= 120) {
                        //max 10minut
                        setTimeout(function () {
                            setCallDetails((oldDetails) => ({ ...oldDetails }));
                        }, 5000);
                    } else {
                        resetCallDetails();
                    }
                }
            }
        }
        getCallDetailsAsync();
        // eslint-disable-next-line
    }, [callDetails]);

    return (
        <div tw="mt-4">
            <div tw="flex justify-end">
                <TextField
                    size="small"
                    type="tel"
                    label={t("records.Vocalls.Phone")}
                    tw="mr-2"
                    name="phoneNumber"
                    value={userPhoneNumber}
                    onChange={userPhoneNumberHandleChange}
                    placeholder="+420 123 456 789"
                />
                <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={callVacalls}
                    disabled={isCallVocallsButtonDisabled()}
                >
                    {getCallVacallsButtonText()}
                </Button>
            </div>
            <div tw="flex items-center justify-end">
                <FormControlLabel
                    label={t("records.Vocalls.IsDemoCall")}
                    control={<Checkbox checked={isDemoCall} onChange={isDemoCallHandleChange} />}
                />
            </div>
            <VocallsApiErrors modal title={t("records.Vocalls.VocallsMakeCallErrorTitle")} />
        </div>
    );

    function setPhoneNumber(phone) {
        setUserPhoneNumber(phone);
        ensureIsValidPhoneNumber(phone);
    }

    function ensureIsValidPhoneNumber(phone) {
        //"+420 123 456 879" nebo "123 456 789"
        var patt = new RegExp("(^[+][\\s]*([0-9][\\s]*){12}[\\s]*$)|(^[\\s]*([0-9][\\s]*){9}[\\s]*$)");
        var res = patt.test(phone);
        setIsUserPhoneNumberValid(res);
    }

    function userPhoneNumberHandleChange(event) {
        setPhoneNumber(event.target.value);
    }

    function isDemoCallHandleChange(event) {
        setIsDemoCall(event.target.checked === true);
    }

    function isCallVocallsButtonDisabled() {
        return !isUserPhoneNumberValid || callDetails.callStatus === "dialing";
    }

    function getCallVacallsButtonText() {
        switch (callDetails.callStatus) {
            case "dialing":
                return t("records.Vocalls.Dialing");
            default:
                return t("records.Vocalls.MakeCall");
        }
    }

    async function callVacalls() {
        setCallDetails((oldDetails) => ({ ...createEmptyCallDetails(), callStatus: "dialing" }));
        let makeCallRecordDto = {
            medicalSubjectId: getMedicalSubjectId(),
            recordDate: getRecordDate(),
            phoneNumber: userPhoneNumber,
            isDemoCall: isDemoCall,
        };

        let response = await vocallsApiPost("/vocalls", makeCallRecordDto);
        if (response.isOk)
            setCallDetails((oldDetails) => ({
                ...oldDetails,
                callStatus: "dialing",
                callGuid: response.json.callGuid,
            }));
        else resetCallDetails();
    }

    function createEmptyCallDetails() {
        return { callGuid: null, callStatus: null, callStateCheckCount: 0 };
    }

    function setCallDetails(getNewDetails) {
        setCallDetailsPrivate((oldDetails) => {
            let newDetails = getNewDetails(oldDetails);
            let newCallStateCheckCount = oldDetails.callStateCheckCount;
            if (!newDetails) newDetails = oldDetails;

            if (oldDetails.callStatus === newDetails.callStatus) newCallStateCheckCount++;
            else newCallStateCheckCount = 0;

            return {
                ...newDetails,
                callStateCheckCount: newCallStateCheckCount,
            };
        });
    }

    function resetCallDetails() {
        setCallDetailsPrivate(createEmptyCallDetails());
    }
}
