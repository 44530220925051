import { useState, useEffect } from "react";
import { useFetch } from "../../utils/useFetch";
import { format } from "date-fns";

/*
!!!!
pozor pokud se zde budou měnit veřejné metody a parametry,
je potřeba změnit i všechny ostatní useXXXFetche v této složce, tak aby měli vždy stejné "rozhraní"
!!!!!
*/
export function useLinkIdentificatorFetch(linkIdentificator) {
    const { apiGet, ApiErrors: GetApiErrors } = useFetch();
    const [parametersData, setParametersData] = useState();
    const [lastRecordDate, setLastRecordDate] = useState(undefined);

    useEffect(() => {
        if (linkIdentificator == null) return;

        setParametersData();
        const getParametersData = async () => {
            let result = await apiGet("/tmp/parameters/" + linkIdentificator);
            if (result.isOk) setParametersData(result.json);
        };

        const getLastRecordDate = async () => {
            let result = await apiGet("/records/getLastRecordDate/" + linkIdentificator);
            if (result.isOk) {
                if (result.json === null) {
                    setLastRecordDate(null);
                } else {
                    setLastRecordDate(new Date(result.json));
                }
            }
        };

        getParametersData();
        getLastRecordDate();
    }, [linkIdentificator]);

    return {
        parametersData: parametersData,
        lastRecordDate: lastRecordDate,
        getUserDataAsync: getUserData,
        getUserDataForGridAsync: getUserDataForGrid,
        //getFoodListAsync: getFoodList,
        //getCaloricsTableAsync: getCaloricsTable,
        // postChartParameterVisibilitiesAsync: postChartParameterVisibilities,
        GetApiErrors: GetApiErrors,
        // PostApiErrors: PostApiErrors,
    };

    // async function getCaloricsTable(selectedDateTime) {
    //     return await apiGet(
    //         "/calorics-table/getCaloricsTable?medicalSubjectId=" +
    //             (linkIdentificator != null ? linkIdentificator : "") +
    //             "&date=" +
    //             (selectedDateTime != null ? format(selectedDateTime, "yyyy-MM-dd") : ""),
    //         true
    //     );
    // }
    // async function getFoodList(selectedDateTime, groupedBy) {
    //     return await apiGet(
    //         "/calorics-table/getFoodList?medicalSubjectId=" +
    //             (linkIdentificator != null ? linkIdentificator : "") +
    //             "&date=" +
    //             (selectedDateTime != null ? format(selectedDateTime, "yyyy-MM-dd HH:mm:ss") : "") +
    //             "&groupedBy=" +
    //             groupedBy,
    //         true
    //     );
    // }
    async function getUserData(dataDates, groupedBy = null) {
        return await apiGet(
            "/records/" +
                linkIdentificator +
                "?dateFrom=" +
                (dataDates.dateFrom != null ? format(dataDates.dateFrom, "yyyy-MM-dd") : "") +
                "&dateTo=" +
                (dataDates.dateTo != null ? format(dataDates.dateTo, "yyyy-MM-dd") : "") +
                "&groupedBy=" +
                groupedBy,
            true,
        );
    }

    async function getUserDataForGrid(dataDates) {
        return await apiGet(
            "/records/" +
                linkIdentificator +
                "?dateFrom=" +
                (dataDates.dateFrom != null ? format(dataDates.dateFrom, "yyyy-MM-dd") : "") +
                "&dateTo=" +
                (dataDates.dateTo != null ? format(dataDates.dateTo, "yyyy-MM-dd") : ""),
            true,
        );
    }
}
