import { useRef, useState } from "react";
import Portal from "./Portal";
import "./OverlayPortal.scss";

export function useOverlayPortal(closeableByOverlayClick = true, open = false, hideBodyScrollbar = false) {
    const overlayRef = useRef();
    const [isPortalOpen, setIsPortalOpen] = useState(open);

    return {
        isPortalOpen,
        openPortal,
        closePortal,
        OverlayPortal: OverlayPortal,
    };

    function OverlayPortal({ className, children, onOverlayClick = null }) {
        return (
            isPortalOpen && (
                <Portal>
                    <div
                        ref={overlayRef}
                        className={"overlay-portal " + (className ? className : "")}
                        onClick={(e) => {
                            if (e.target != overlayRef.current) return;

                            if (onOverlayClick) onOverlayClick();

                            e.stopPropagation();
                            if (closeableByOverlayClick) {
                                closePortal();
                            }
                        }}
                    >
                        {children}
                    </div>
                </Portal>
            )
        );
    }

    function openPortal() {
        setIsPortalOpen(true);
        if (hideBodyScrollbar) window.document.body.style.overflow = "hidden";
    }

    function closePortal() {
        setIsPortalOpen(false);
        if (hideBodyScrollbar) window.document.body.style.overflow = "auto";
    }
}
