import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import EcgDialog from "../EcgDialog";
import { format } from "date-fns";
import { Button, CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Fade from "@mui/material/Fade";

export default function EcgTable({ fetchService, dateFrom, dateTo, isOpen }) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const { getEcgListAsync } = fetchService;
    const [data, setData] = useState([]);
    const [isCollapsed, setIsCollapsed] = useState(!isOpen);

    useEffect(() => {
        async function getData() {
            setIsLoading(true);
            let response = await getEcgListAsync(dateFrom, dateTo);
            if (response.isOk) {
                setData(response.json);
            }
            setIsLoading(false);
        }

        getData();
    }, [dateFrom, dateTo]);

    if (isLoading) {
        return (
            <div tw="flex h-60 justify-center items-center">
                <Fade
                    in={isLoading}
                    style={{
                        transitionDelay: isLoading ? "1000ms" : "0ms",
                    }}
                    unmountOnExit
                >
                    <CircularProgress />
                </Fade>
            </div>
        );
    }

    return (
        <Fragment>
            {data && data.length > 0 && (
                <Fragment>
                    <div tw="flex justify-center">
                        <Button
                            size="small"
                            tw="underline hover:underline"
                            onClick={() => setIsCollapsed(!isCollapsed)}
                        >
                            {t("ecg.EcgOverview")}
                        </Button>
                    </div>

                    {!isCollapsed && (
                        <div tw="w-full px-3">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("ecg.EcgTable.Date")}</TableCell>
                                        <TableCell align="center">{t("ecg.EcgTable.AvgBpm")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{format(new Date(item.date), "dd. MM. yyyy HH:mm")}</TableCell>
                                            <TableCell align="center">{item.avgBpm}</TableCell>
                                            <TableCell align="right">
                                                <EcgDialog
                                                    fetchService={fetchService}
                                                    recordId={item.recordId}
                                                    recordDate={new Date(item.date)}
                                                    trigger={(open) => (
                                                        <IconButton color="primary" onClick={open}>
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}
