import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import "twin.macro";

const StyledTab = styled((props) => <Tab disableRipple autoCapitalize={false} {...props} />)(({ theme }) => ({
    textTransform: "none",

    "&.Mui-selected": {
        background: "#fff",
        outline: "none",
    },
}));

export default function ChartButtons({ tabIndex, onTabChanged, tabs }) {
    return (
        <Tabs
            value={tabIndex}
            indicatorColor="transparent"
            onChange={(e, newValue) => onTabChanged(newValue)}
            sx={{ padding: 0 }}
        >
            {tabs.map((tab, index) => (
                <StyledTab key={index} label={tab} />
            ))}
        </Tabs>
    );
}
