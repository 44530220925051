import { useEffect, useState } from "react";
import { useOverlayPortal } from "./OverlayPortal";
import "./Modal.scss";

let openModals = 0;
const modalDialogMaxClass = "modal-dialog-max";

export function useModal(isCloseButtonVisible = true, closeableByOverlayClick = true, open = false) {
    const { isPortalOpen, openPortal, closePortal, OverlayPortal } = useOverlayPortal(closeableByOverlayClick, open);

    return { isModalOpen: isPortalOpen, Modal, openModal: openPortal, closeModal: closePortal };

    function Modal({
        title,
        overlayClass,
        dialogClass = "modal-dialog-md",
        footerClass,
        children,
        header,
        footer,
        onClosing,
        onCloseClicked,
        contentClass,
        maximizable,
        maximized,
        hideHeader = false,
        customButtons = null,
        minimizeClass = "far fa-window-minimize",
        maximizeClass = "far fa-window-maximize",
        style,
    }) {
        const [initialMaximized, setInitialMaximized] = useState(maximized);
        const [windowState, setWindowState] = useState(maximized ? 1 : 0);

        if (initialMaximized != maximized) {
            setInitialMaximized(maximized);
            setWindowState(maximized ? 1 : 0);
        }

        useEffect(() => {
            if (isPortalOpen) {
                const oldPadding = document.body.style.paddingRight;

                openModals = Math.max(0, openModals) + 1;

                if (openModals === 1) {
                    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
                    document.body.classList.add("modal-open");
                    document.body.style.paddingRight = scrollbarWidth + "px";
                }

                return () => {
                    openModals--;

                    if (openModals <= 0) {
                        document.body.classList.remove("modal-open");
                        document.body.style.paddingRight = oldPadding;
                    }
                };
            }
        }, [isPortalOpen]);

        return (
            <OverlayPortal className={overlayClass} onOverlayClick={onClosing}>
                <div
                    className={`modal-dialog modal-dialog-scrollable ${dialogClass} ${
                        windowState == 1 ? modalDialogMaxClass : ""
                    }`}
                    role="document"
                    style={style}
                >
                    <div className={"modal-content shadow " + (contentClass ? contentClass : "")}>
                        {!hideHeader && (
                            <div className="modal-header">
                                <div className="modal-title">{title}</div>
                                {header}
                                {customButtons && customButtons()}
                                {!customButtons && maximizable && (
                                    <button type="button" className="window-state" onClick={handleMinMaxClick}>
                                        {windowState === 1 ? (
                                            <i className={minimizeClass}></i>
                                        ) : (
                                            <i className={maximizeClass}></i>
                                        )}
                                    </button>
                                )}
                                {isCloseButtonVisible && (
                                    <button
                                        type="button"
                                        className="window-state window-close"
                                        onClick={() => {
                                            if (onClosing) onClosing();
                                            closePortal();
                                            if (onCloseClicked) onCloseClicked();
                                        }}
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                )}
                            </div>
                        )}
                        <div className="modal-body">{children}</div>
                        {footer && <div className={"modal-footer " + footerClass}>{footer}</div>}
                    </div>
                </div>
            </OverlayPortal>
        );

        function handleMinMaxClick() {
            setWindowState((windowState) => 1 - windowState);
        }
    }
}
