import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../useAuth";
import { apiGet } from "../../utils/useFetch";
import { GdprTextConsentDialog } from "../../user/components/GdprTextConsentDialog";
import { ContractTextConsentDialog } from "../../user/components/ContractTextConsentDialog";
import CircularProgressMidScreen from "@profilog/commons/components/CircularProgressMidScreen";
import { Alert, Button } from "@mui/material";

export default function LoginCheck({
    children,
    onAppDataFetched,
    isGdprConsentModalUsed = false,
    isContractConsentModalUsed = false,
    hardRedirect,
    isDev,
}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadError, setIsLoadError] = useState(false);
    const [isUnauthorized, setIsUnauthorized] = useState(false);
    const [isGdprTextConsentRequired, setIsGdprTextConsentRequired] = useState(false);
    const [requiredContractConsentProjectIds, setRequiredContractConsentProjectIds] = useState([]);
    const [isUserInitializationRequired, setIsUserInitializationRequired] = useState(null);
    const [conflictingProjectCode, setConflictingProjectCode] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { currentUserData, loginFromApiResponse, isUserInitialized, setIsUserInitialized, logout } = useAuth();

    useEffect(() => {
        async function fetchData() {
            const response = await apiGet("/web/appdata", true);

            if (response.isOk && response.json.confictingProjectCode) {
                setConflictingProjectCode(response.json.confictingProjectCode);
                return;
            }
            if (response.isUnauthorized) {
                setIsUnauthorized(true);
                return;
            }

            if (!response.isOk) {
                setIsLoadError(true);
                return;
            }

            setIsUserInitializationRequired(response.json.user.isUserInitializationRequired);
            setIsUserInitialized(response.json.userData.isInitialized);

            if (onAppDataFetched) onAppDataFetched(response.json);

            if (isGdprConsentModalUsed)
                setIsGdprTextConsentRequired(!response.json.userData.hasConsentedToLatestGdprTerms);

            if (isContractConsentModalUsed)
                setRequiredContractConsentProjectIds(response.json.userData.requiredContractConsentProjectIds);

            if (currentUserData == null) loginFromApiResponse(response);

            setIsLoaded(true);
        }

        fetchData();
    }, [currentUserData?.userName, conflictingProjectCode]); // eslint-disable-line

    useEffect(() => {
        if (
            isUserInitializationRequired === true &&
            isUserInitialized === false &&
            location.pathname !== "/in/user-initialization"
        ) {
            navigate("user-initialization", { replace: true });
        }
    }, [isUserInitializationRequired, isUserInitialized, location.pathname]); // eslint-disable-line

    if (conflictingProjectCode)
        return (
            <ProjectCodeConflict
                conflictingProjectCode={conflictingProjectCode}
                setConflictingProjectCode={setConflictingProjectCode}
                logout={logout}
            />
        );

    if (isUnauthorized) return <HomeRedirect hardRedirect={hardRedirect} isDev={isDev} />;

    if (isLoadError)
        return (
            <Fragment>
                <Alert severity="error" tw="fixed left-1/2 top-1/2 [transform: translate(-50%, -50%)] shadow-updown">
                    {t("apiResult.500")}
                </Alert>
                <HomeRedirect hardRedirect={hardRedirect} isDev={isDev} delayMs={1500} />
            </Fragment>
        );

    if (!isLoaded) return <CircularProgressMidScreen />;

    return (
        <Fragment>
            {isGdprConsentModalUsed && (
                <GdprTextConsentDialog
                    open={isGdprTextConsentRequired}
                    onConsent={() => setIsGdprTextConsentRequired(false)}
                    onDisconsent={logout}
                />
            )}
            {isContractConsentModalUsed &&
                requiredContractConsentProjectIds.map((projectId) => (
                    <ContractTextConsentDialog key={projectId} projectId={projectId} onDisconsent={logout} />
                ))}
            {children}
        </Fragment>
    );
}

function ProjectCodeConflict({ conflictingProjectCode, setConflictingProjectCode, logout }) {
    return (
        <div tw="mx-auto w-96 m-10 flex flex-col items-center gap-2">
            <Alert severity="error">
                Development cookie conflict detected.
                <br />
                <br />
                React app project: <strong>{process.env.REACT_APP_PROJECT}</strong>
                <br />
                Logged in user's project: <strong>{conflictingProjectCode}</strong>
                <br />
                <br />
                You can logout or use this React app in another browser.
            </Alert>
            <Button
                variant="contained"
                onClick={async () => {
                    await logout();
                    setConflictingProjectCode(null);
                }}
            >
                Logout
            </Button>
        </div>
    );
}

function HomeRedirect({ hardRedirect, isDev, delayMs }) {
    const [redirect, setRedirect] = useState(!delayMs);

    useEffect(() => {
        if (delayMs) setTimeout(() => setRedirect(true), delayMs);
    }, [setRedirect, delayMs]);

    if (redirect) {
        let root = "/";
        if (isDev) root = process.env.PUBLIC_URL + "/";

        if (hardRedirect) {
            window.location.replace(root);
            return null;
        } else return <Navigate to="root" />;
    }

    return null;
}
