import { createTheme } from "@mui/material/styles";
import { prescriptionColors } from "@profilog/prescriptions/prescriptionColors";

export function createMedilogMuiTheme() {
    const primaryColor = "#f00";
    const secondaryColor = "#00947d";
    const contrastColor = "#fff";
    const textColor = "#24373d";
    const alertColor = "#d22d40";

    return createTheme({
        components: {
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: "white",
                    },
                },
            },
        },
        typography: {
            fontFamily: "Montserrat, sans-serif",
        },
        shape: {
            borderRadius: 5,
        },
        palette: {
            text: { primary: textColor },
            primary: { main: primaryColor },
            secondary: { main: secondaryColor },

            notificationButton: {
                main: primaryColor,
                contrastText: contrastColor,
            },

            unread: {
                main: alertColor,
                contrastText: contrastColor,
            },

            ...prescriptionColors,
        },
    });
}
