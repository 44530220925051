import { Pagination, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { minutesToHmsString } from "@profilog/utils/converting";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { roundTo } from "round-to";

const RecordsGridView = ({ userData, parametersData }) => {
    const { t, i18n } = useTranslation();
    const pageSize = 10;
    const [isSorted, setIsSorted] = useState(false);
    const [pagesCount, setPagesCount] = useState(1);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [visibleRecordValues, setVisibleRecordValues] = useState([]);

    useEffect(() => {
        setPagesCount(Math.ceil(userData.recordValues.length / pageSize));
        userData.recordValues.sort(function compare(a, b) {
            let aDate = Date.parse(a.recordDate);
            let bDate = Date.parse(b.recordDate);
            if (aDate > bDate) return -1;
            if (bDate > aDate) return 1;

            return 0;
        });
        setIsSorted(true);
    }, [userData.recordValues]);

    useEffect(() => {
        if (isSorted) {
            let start = (currentPageIndex - 1) * pageSize;
            let end = start + pageSize;
            let slice = userData.recordValues.slice(start, end);
            setVisibleRecordValues(slice);
        }
    }, [currentPageIndex, isSorted, userData.recordValues]);

    if (!isSorted) return null;

    const recordValueLiteral = (recordValue, parameterSettings) => {
        if (!parameterSettings) return null;

        if (!isNaN(recordValue)) {
            if (parameterSettings.parameterType === "Time") return minutesToHmsString(recordValue);

            return roundTo(parseFloat(recordValue), parameterSettings.decimalPlacesCount).toLocaleString(i18n.language);
        }

        return recordValue;
    };

    function getTranslatedName(parameterId) {
        const parameter = parametersData.parameterSettings.find((p) => p.id === parameterId);
        if (parameter) return parameter.translatedName;
        return null;
    }

    return (
        <Fragment>
            <div tw="w-full overflow-x-auto">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {userData.recordParameterIds.map((parameterId) => {
                                return (
                                    <TableCell key={parameterId} align="center" tw="whitespace-nowrap">
                                        {getTranslatedName(parameterId)}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visibleRecordValues.map((record, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell align="right" tw="whitespace-nowrap">
                                        {new Date(record.recordDate).toLocaleDateString(i18n.language, {
                                            month: "long",
                                            day: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                            second: "numeric",
                                        })}
                                    </TableCell>
                                    {userData.recordParameterIds.map((parameterId) => {
                                        return (
                                            <TableCell key={parameterId} align="right">
                                                {recordValueLiteral(
                                                    record[parameterId],
                                                    parametersData.parameterSettings.find((p) => p.id === parameterId),
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            <Pagination
                count={pagesCount}
                page={currentPageIndex}
                onChange={(_, page) => setCurrentPageIndex(page)}
                tw="mt-1"
            />
        </Fragment>
    );
};

export default RecordsGridView;
