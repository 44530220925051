import { useState, useEffect, useRef, useLayoutEffect, useContext, Fragment } from "react";
import { useFetch } from "../../../utils/useFetch";
import { useTranslation } from "react-i18next";
import "./Communications.scss";
import NewMessageDialog from "./NewMessageDialog";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { getErrorMessage } from "../../../errors/ValidationError";
import CurrentNotificationContext from "../../../notifications/CurrentNotificationContext";
import { notificationTypes } from "../../../notifications/components/notificationTypes";
import CircularProgressMidScreen from "@profilog/commons/components/CircularProgressMidScreen";
import { Button, TextField } from "@mui/material";
import { useTheme } from "@mui/system";
import tw, { css } from "twin.macro";
import FormPanel from "@profilog/commons/components/FormPanel";

const Communications = ({
    medicalSubjectId,
    isProfi,
    ignoreCurrentNotificationContextChanged,
    profilogUserId,
    isDashboard,
    isPatientPreview,
    onCommentConversationClick,
    dialogOverFlowYContainerId,
    canAddMessage = true,
}) => {
    const messagePatter = /^\s*\S+.*/;
    const { t, i18n } = useTranslation();
    const { apiGet, apiPost, apiPut, ApiErrors } = useFetch();
    const [conversations, setConversations] = useState({});
    const [isFirstLoaded, setIsFirstLoaded] = useState(false);
    const [conversationKey, setConversationKey] = useState(null);
    const [canAddAnswer, setCanAddAnswer] = useState(true);
    const [newAnswerHeight, setNewAnswerHeight] = useState(null);
    const { currentNotification, setCurrentNotification } = useContext(CurrentNotificationContext);
    const oldMessagesRef = useRef(null);
    const newAnswerRef = useRef(null);
    const firstMessageRef = useRef(null);
    const lastMessageRef = useRef(null);
    const firstLastMessageRef = useRef(null);
    const messagesRef = useRef(null);
    const muiTheme = useTheme();
    const circleCss =
        !isProfi &&
        css`
            background: ${muiTheme.palette.primary.main}33 !important;
            color: ${muiTheme.palette.primary.main}99 !important;
        `;
    const senderMessageCss =
        !isProfi &&
        css`
            background: ${muiTheme.palette.primary.main}33 !important;
        `;
    const displayDateButtonCss =
        !isProfi &&
        css`
            color: ${muiTheme.palette.primary.main} !important;
            &:hover {
                color: ${muiTheme.palette.unread.main} !important;
            }
        `;

    const conversationCss =
        !isProfi &&
        css`
            &:hover {
                background: ${muiTheme.palette.primary.main}33 !important;
            }
        `;

    const selectedConversationCss =
        !isProfi &&
        css`
            background: ${muiTheme.palette.primary.main}4d !important;
        `;

    const allReadCss =
        !isProfi &&
        css`
            &:before {
                color: ${muiTheme.palette.primary.main} !important;
            }
        `;
    const notReadCountCss =
        !isProfi &&
        css`
            color: ${muiTheme.palette.unread.main} !important;
        `;

    const {
        register,
        handleSubmit,
        reset,
        errors,
        formState: { isSubmitting },
    } = useForm();

    const saveAnswerMessage = async (data) => {
        const m = conversations[conversationKey][0];
        const newMessageDto = {
            date: m.date,
            text: data.newMessage,
            groupId: m.group?.id,
            accessibility: m.accessibility,
            medicalSubjectId: m.medicalSubjectId,
            profilogUserId: isPatientPreview && profilogUserId ? profilogUserId : null,
        };

        const response = await apiPost("/web/messages", newMessageDto);
        if (response.isOk) {
            reset();
            onSave();
        }
    };

    useEffect(() => {
        if (!isFirstLoaded) {
            setConversationKey(null);
            setConversations({});
        }
        async function loadDataAsync() {
            await loadConversationsAsync();
            setIsFirstLoaded(true);
        }
        setIsFirstLoaded(false);
        loadDataAsync();
        // eslint-disable-next-line
    }, [medicalSubjectId, isProfi]);

    useEffect(() => {
        async function loadDataAsync() {
            await loadConversationsAsync();
        }
        if (isFirstLoaded && canRefreshByNotificationContext()) {
            loadDataAsync();
        }

        // eslint-disable-next-line
    }, [medicalSubjectId, isProfi, currentNotification?.notification]);

    useEffect(() => {
        if (!conversationKey) return;

        const loadAsync = async function () {
            const fm = conversations[conversationKey][0];
            await checkGroupRelationsAndConnectionsAsync(fm.medicalSubjectId, fm.group?.id, fm.date);
            if (isFirstLoaded) {
                await loadConversationsAsync();
            }
            updateOverflowMaxHeight();
            scrollDown();
            //pokud jsou konverzace a zprávy pod sebou, tak odscrolovávám dolů...
            if (newAnswerRef?.current && window.innerWidth < 768) {
                newAnswerRef.current.scrollIntoView();
                let aRect = newAnswerRef.current.getBoundingClientRect();
                let yDif = window.innerHeight - aRect.bottom;
                if (yDif > 0) window.scrollBy(0, -yDif);
            }
        };

        loadAsync();
    }, [conversationKey]);

    useLayoutEffect(() => {
        const element = document.getElementById("overflow-y-root");
        let dialogEl;
        if (dialogOverFlowYContainerId) dialogEl = document.getElementById(dialogOverFlowYContainerId);

        window.addEventListener("resize", updateOverflowMaxHeight);
        window.addEventListener("scroll", updateOverflowMaxHeight);
        if (element) element.addEventListener("scroll", updateOverflowMaxHeight);
        if (dialogEl) dialogEl.addEventListener("scroll", updateOverflowMaxHeight);

        updateOverflowMaxHeight();
        return () => {
            window.removeEventListener("resize", updateOverflowMaxHeight);
            window.removeEventListener("scroll", updateOverflowMaxHeight);
            if (element) element.removeEventListener("scroll", updateOverflowMaxHeight);
            if (dialogEl) dialogEl.removeEventListener("scroll", updateOverflowMaxHeight);
        };
    }, []);

    useLayoutEffect(() => {
        const updateAsync = async function () {
            if (newAnswerHeight) {
                updateOverflowMaxHeight();
            }
        };

        updateAsync();
    }, [newAnswerHeight]);

    function canRefreshByNotificationContext() {
        return (
            !ignoreCurrentNotificationContextChanged &&
            (currentNotification?.notification?.type === notificationTypes.message ||
                (currentNotification?.notification?.type === notificationTypes.comment && !getAreOnlyMessagesLoaded()))
        );
    }

    async function loadConversationsAsync() {
        updateOverflowMaxHeight();
        scrollDown();
        const { conv: newConversations, convKey: newConversationKey } = await loadMessagesAsync();

        if (newConversations) setConversations(newConversations);

        if (newConversationKey) {
            setConversationKey(newConversationKey);
        }
        updateOverflowMaxHeight();
        scrollDown();
    }

    function scrollDown() {
        if (oldMessagesRef.current !== null) oldMessagesRef.current.scrollTop = oldMessagesRef.current.scrollHeight;
    }

    function getScrollRatio() {
        if (oldMessagesRef.current !== null) {
            if (
                oldMessagesRef.current.scrollHeight ===
                oldMessagesRef.current.scrollTop + oldMessagesRef.current.clientHeight
            )
                return 1;

            if (oldMessagesRef.current.scrollTop === 0) return 0;

            return oldMessagesRef.current.scrollHeight / oldMessagesRef.current.scrollTop;
        }

        return null;
    }

    function setScrollByRatio(ratio) {
        if (oldMessagesRef.current !== null) {
            if (ratio === 0) {
                oldMessagesRef.current.scrollTop = 0;
                return;
            }

            oldMessagesRef.current.scrollTop = oldMessagesRef.current.scrollHeight / ratio;
        }
    }

    function updateOverflowMaxHeight() {
        if (oldMessagesRef.current && newAnswerRef.current) {
            const scrollRatio = getScrollRatio();
            oldMessagesRef.current.style.height = null;

            let firstMessageTop;
            let lastMessageBottom;
            if (firstLastMessageRef.current) {
                firstMessageTop = firstLastMessageRef.current.getBoundingClientRect().top;
                lastMessageBottom = firstLastMessageRef.current.getBoundingClientRect().bottom;
            } else if (firstMessageRef.current && lastMessageRef.current) {
                firstMessageTop = firstMessageRef.current.getBoundingClientRect().top;
                lastMessageBottom = lastMessageRef.current.getBoundingClientRect().bottom;
            }
            let h;
            if (firstMessageTop && lastMessageBottom) h = lastMessageBottom - firstMessageTop;
            else h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

            oldMessagesRef.current.style.height = h + 20 + "px";
            let mRect = oldMessagesRef.current.getBoundingClientRect();
            let aRect = newAnswerRef.current.getBoundingClientRect();

            let maxHeight = aRect.top - mRect.top - 5; // těch 5px je tu kvuli profilogu kde je to v modalu... jinak se tam přidá další scrollbar
            const opicatskaKonstanta = isProfi ? 630 : 430;
            if (maxHeight > opicatskaKonstanta) maxHeight = opicatskaKonstanta;
            const isLower =
                oldMessagesRef.current.getBoundingClientRect().bottom -
                    oldMessagesRef.current.getBoundingClientRect().top >
                maxHeight;
            oldMessagesRef.current.style.height = maxHeight + "px";

            if (messagesRef.current && newAnswerRef.current) {
                const heightToEnd = window.innerHeight - newAnswerRef.current.getBoundingClientRect().bottom;
                if (
                    !isLower &&
                    //heightToEnd + maxHeight < opicatskaKonstanta &&
                    heightToEnd > 5
                    // newAnswerRef.current.getBoundingClientRect().top - 5 >=
                    //     oldMessagesRef.current.getBoundingClientRect().bottom
                ) {
                    oldMessagesRef.current.style.height =
                        Math.min(heightToEnd + maxHeight, opicatskaKonstanta) -
                        20 -
                        (dialogOverFlowYContainerId ? 20 : 0) +
                        "px";
                }
            }

            if (scrollRatio !== null) setScrollByRatio(scrollRatio);
            else scrollDown();
        }
    }

    function handleNewMessageChange() {
        let aRect = newAnswerRef.current.getBoundingClientRect();

        //zaznamená výšku
        if (newAnswerHeight !== aRect.bottom - aRect.top) {
            setNewAnswerHeight(aRect.bottom - aRect.top);
        }
    }

    function getAreOnlyMessagesLoaded() {
        return !isProfi || !medicalSubjectId;
    }

    async function loadMessagesAsync() {
        let conv = JSON.parse(JSON.stringify(conversations));
        let lastMessage = null;
        if (!canRefreshByNotificationContext()) lastMessage = getLastMessage(conv);
        const params = {};
        if (medicalSubjectId) params.medicalSubjectId = medicalSubjectId;
        params.messagesTrueCommentsFalseAllNull = getAreOnlyMessagesLoaded() ? true : "";
        if (lastMessage) params.newerThanCreatedDate = lastMessage.createdDate;
        if (isDashboard) params.getFakeMessage = true;
        if (isPatientPreview && profilogUserId) params.profilogUserId = profilogUserId;

        const response = await apiGet("/web/messages?" + new URLSearchParams(params));

        if (response.isOk) {
            let messages = response.json;

            let isConvChanged = false;
            let convKey = conversationKey;

            if (lastMessage && messages.length > 0) {
                messages = Object.keys(conv)
                    .map((key) => conv[key])
                    .reduce((acc, oldMesssages) => {
                        return acc.concat(oldMesssages.filter((f) => acc.map((m) => m.id).indexOf(f.id) < 0));
                    }, messages);

                conv = groupToConversations(messages);
                isConvChanged = true;
            } else if (!lastMessage) {
                if (canRefreshByNotificationContext()) {
                    let message = messages.find((m) => m.id === currentNotification.notification.message.id);
                    if (message) convKey = getConversationKey(message);
                }
                conv = groupToConversations(messages);
                isConvChanged = true;
            }

            if (!convKey && isDashboard && Object.keys(conv).length > 0) convKey = Object.keys(conv)[0];

            if (convKey) {
                let messages = conv[convKey].filter((f) => !f.isFromMe && !f.displayedDate && f.id > 0);

                if (messages.length > 0) {
                    const newDisplayedDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'0000'");

                    messages.forEach((f) => {
                        f.displayedDate = newDisplayedDate;
                    });

                    const markMessagesNotificationsAsReadOrUnreadDto = {
                        medicalSubjectId: conv[convKey][0].medicalSubjectId,
                        groupId: conv[convKey][0].group?.id,
                        date: conv[convKey][0].date,
                        displayedDate: newDisplayedDate,
                        messageIds: messages.map((m) => m.id),
                        setAsReadTrueSetAsUnreadFalse: true,
                        profilogUserId: isPatientPreview && profilogUserId ? profilogUserId : null,
                    };

                    isConvChanged = true;
                    readOrUnreadMessagesAsync(markMessagesNotificationsAsReadOrUnreadDto, convKey, null);
                }
            }

            if (isConvChanged) return { conv: conv, convKey: convKey };
        }
        return { conv: null, convKey: null };
    }

    async function readOrUnreadMessagesAsync(markMessagesNotificationsAsReadOrUnreadDto, convKey, dateForRevert) {
        const response = await apiPut(
            "/web/messages/set-as-read-or-unread",
            markMessagesNotificationsAsReadOrUnreadDto,
        );

        if (!response.isOk) {
            setConversations((old) => {
                let conv = JSON.parse(JSON.stringify(old));

                conv[convKey].forEach((f) => {
                    if (markMessagesNotificationsAsReadOrUnreadDto.messageIds.indexOf(f.id) >= 0)
                        f.displayedDate = dateForRevert;
                });

                return conv;
            });
        } else if (canRefreshByNotificationContext()) {
            setCurrentNotification((o) => ({ ...o, notification: null }));
        } else {
            setCurrentNotification((o) => ({ ...o }));
        }
    }

    function getLastMessage(oldConversations) {
        if (!oldConversations) return null;

        let lastMessage;
        Object.keys(oldConversations).forEach((key) =>
            oldConversations[key].forEach((item) => {
                if (!lastMessage || new Date(item.createdDate) > new Date(lastMessage.createdDate)) lastMessage = item;
            }),
        );
        return lastMessage;
    }

    function setOrResetConversationKey(key) {
        setConversationKey((oldKey) => {
            return oldKey === key ? null : key;
        });
    }

    function markTheMessageAsUnread(message) {
        const key = getConversationKey(message);

        setConversations((old) => {
            let conv = JSON.parse(JSON.stringify(old));
            conv[key][conv[key].map((m) => m.id).indexOf(message.id)].displayedDate = null;
            return conv;
        });

        const markMessagesNotificationsAsReadOrUnreadDto = {
            medicalSubjectId: message.medicalSubjectId,
            groupId: message.group?.id,
            date: message.date,
            displayedDate: null,
            messageIds: [message.id],
            setAsReadTrueSetAsUnreadFalse: false,
            profilogUserId: isPatientPreview && profilogUserId ? profilogUserId : null,
        };

        readOrUnreadMessagesAsync(markMessagesNotificationsAsReadOrUnreadDto, key, message.displayedDate);
    }

    function groupToConversations(messagesList) {
        let conv = messagesList.sort(sortForGrouping).reduce((acc, message) => {
            const key = getConversationKey(message);
            if (!acc[key]) acc[key] = [];

            acc[key].push(message);
            return acc;
        }, {});

        Object.keys(conv).forEach((key) => {
            conv[key] = conv[key].sort(sortMessages);
        });

        return conv;
    }

    function sortMessages(m1, m2) {
        if (new Date(m1.createdDate) < new Date(m2.createdDate)) return -1;
        if (new Date(m1.createdDate) > new Date(m2.createdDate)) return 1;
        return m1.id > m2.id ? 1 : -1;
    }

    function getConversationKey(message) {
        let date = message.date;
        if (date) {
            date = new Date(date);
            date.setHours(0, 0, 0, 0);
            date = date.toJSON();
        }

        return message.group?.id + "|" + message.medicalSubjectId + "|" + message.project.id + "|" + date;
    }

    function sortForGrouping(m1, m2) {
        //projekt
        if (m1.project.shortName < m2.project.shortName) return -1;
        if (m1.project.shortName > m2.project.shortName) return 1;
        if (m1.project.id < m2.project.id) return -1;
        if (m1.project.id > m2.project.id) return 1;

        //nedříve zprávy a až pak komentáře
        if (!m1.date && m2.date) return -1;
        if (m1.date && !m2.date) return 1;

        //chtějí to chronologicky, ale nepřečtené zprávy má absolutní přednost
        if (!m1.displayedDate && m2.displayedDate && !m1.isFromMe) return -1;
        if (m1.displayedDate && !m2.displayedDate && !m2.isFromMe) return 1;
        if (!m1.displayedDate && !m2.displayedDate && m1.isFromMe !== m2.isFromMe) {
            if (!m1.isFromMe) return -1;
            else return 1;
        }

        //chtějí to chronologicky (nejnovější první)
        if (new Date(m1.createdDate) < new Date(m2.createdDate)) return 1;
        if (new Date(m1.createdDate) > new Date(m2.createdDate)) return -1;

        //vše níže už je asi zbytečné (createdDate nebude nikdy stejné)... ale nechávám pro případ až se to bude zase měnit...

        //medicalSubjectPerson
        if (m1.medicalSubjectPerson.lastName < m2.medicalSubjectPerson.lastName) return -1;
        if (m1.medicalSubjectPerson.lastName > m2.medicalSubjectPerson.lastName) return 1;
        if (m1.medicalSubjectPerson.firstName < m2.medicalSubjectPerson.firstName) return -1;
        if (m1.medicalSubjectPerson.firstName > m2.medicalSubjectPerson.firstName) return 1;
        if (m1.medicalSubjectPerson.id < m2.medicalSubjectPerson.id) return -1;
        if (m1.medicalSubjectPerson.id > m2.medicalSubjectPerson.id) return 1;

        if (m1.date && m2.date) {
            if (new Date(m1.date) < new Date(m2.date)) return 1;
            if (new Date(m1.date) > new Date(m2.date)) return -1;
        }

        //group
        if (!m1.group && m2.group) return -1;
        if (m1.group && !m2.group) return 1;
        if (m1.group && m2.group) {
            if (m1.group.shortName < m2.group.shortName) return -1;
            if (m1.group.shortName > m2.group.shortName) return 1;
            if (m1.group.id < m2.group.id) return -1;
            if (m1.group.id > m2.group.id) return 1;
        }

        return 0;
    }

    function onSave() {
        const refreshDataAsync = async function () {
            await loadConversationsAsync();
            if (!conversationKey) return;
            const fm = conversations[conversationKey][0];
            await checkGroupRelationsAndConnectionsAsync(fm.medicalSubjectId, fm.group?.id, fm.date);
        };

        refreshDataAsync();
    }

    async function checkGroupRelationsAndConnectionsAsync(medicalSubjectId, groupId, date) {
        const params = {};
        params.medicalSubjectId = medicalSubjectId;
        params.isComment = !!date;
        if (groupId) params.groupId = groupId;

        const response = await apiGet("/web/messages/can-add-response?" + new URLSearchParams(params));

        setCanAddAnswer(response.isOk && response.json.canAdd);
    }

    return (
        <div className="messages" ref={messagesRef}>
            {!isProfi && !isDashboard && <h2 className="box-title">{t("shCommunications.Messages.Messages")}</h2>}

            <ApiErrors />
            {!isFirstLoaded ? (
                <Fragment>
                    <div>{t("shCommunications.Messages.LoadingData")}</div>
                    <CircularProgressMidScreen />
                </Fragment>
            ) : (
                <Fragment>
                    <div tw="md:flex gap-2">
                        {!isDashboard && canAddMessage && (
                            <div tw="w-full md:w-5/12 px-0">
                                <div tw="mb-2">
                                    <NewMessageDialog
                                        trigger={(open) => (
                                            <Button variant="contained" onClick={open} fullWidth>
                                                {isProfi && medicalSubjectId
                                                    ? t("shCommunications.Messages.NewConversationOrComment")
                                                    : t("shCommunications.Messages.NewConversation")}
                                            </Button>
                                        )}
                                        medicalSubjectId={medicalSubjectId}
                                        isProfi={isProfi}
                                        isCommentDef={false}
                                        onSave={onSave}
                                        overlayClass="new-conversation-modal"
                                        hideCommentMessageChoice={!isProfi || !medicalSubjectId}
                                    />
                                </div>
                                <div className={`conversation-list ${isProfi ? "prof-list" : ""}`}>
                                    {Object.keys(conversations).map((key) => renderConversation(key))}
                                </div>
                            </div>
                        )}
                        <div
                            className="conversation-detail"
                            css={[tw`w-full px-0`, !isDashboard && tw`md:(w-7/12 pl-1)`]}
                        >
                            {conversationKey && conversations[conversationKey].length > 0 && (
                                <Fragment>
                                    <div className="messages__old-messages">
                                        <div className="messages__old-messages-container">
                                            {conversations[conversationKey].filter((f) => f.id > 0).length > 0 && (
                                                <div className="messages__old-messages-body" ref={oldMessagesRef}>
                                                    <div tw="mt-auto">
                                                        {conversations[conversationKey].map((m, index, allMessages) =>
                                                            renderMessage(m, index, allMessages),
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {renderNewAnswerForm()}
                                </Fragment>
                            )}
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );

    function renderNewAnswerForm() {
        return (
            <div tw="sticky bottom-0" ref={newAnswerRef}>
                <form onSubmit={handleSubmit(saveAnswerMessage)}>
                    <FormPanel tw="flex p-2 space-x-2 items-center">
                        {canAddAnswer ? (
                            <Fragment>
                                <div tw="flex-1">
                                    <TextField
                                        name="newMessage"
                                        multiline
                                        minRows="2"
                                        maxRows="10"
                                        fullWidth
                                        inputRef={register({
                                            required: t("global.RequiredField"),
                                            pattern: messagePatter,
                                        })}
                                        placeholder={t("shCommunications.Messages.New.WriteTheTextOfTheAnswer")}
                                        error={getErrorMessage(errors, "newMessage") ? true : false}
                                        helperText={getErrorMessage(errors, "newMessage") ?? ""}
                                        onKeyUp={handleNewMessageChange}
                                        onPaste={() => setTimeout(() => handleNewMessageChange(), 100)}
                                        onCut={() => setTimeout(() => handleNewMessageChange(), 100)}
                                    />
                                </div>
                                <div tw="pl-0">
                                    <Button
                                        variant="contained"
                                        size="small"
                                        type="submit"
                                        disabled={isSubmitting || !canAddAnswer}
                                        tw="py-3"
                                    >
                                        {t("shCommunications.Messages.New.Insert")}
                                    </Button>
                                </div>
                            </Fragment>
                        ) : (
                            <div>{t("shCommunications.Messages.ConversationIsClosed")}</div>
                        )}
                    </FormPanel>
                </form>
            </div>
        );
    }

    function renderConversation(key) {
        const parsedKey = key.split("|");
        const groupIdKey = parsedKey[0];
        const medicalSubjectIdKey = parsedKey[1];
        const projectIdKey = parsedKey[2];
        const dateKey = parsedKey[3];
        const areComments = dateKey != "null";
        const messages = conversations[key];
        const notReadCount = messages.filter((f) => !f.displayedDate && !f.isFromMe).length;
        const totalCount = messages.length;

        return (
            <div
                key={key}
                data-group-id={groupIdKey}
                data-medical-subject-id={medicalSubjectIdKey}
                data-project-id-key={projectIdKey}
                data-date={dateKey}
                onClick={() => setOrResetConversationKey(key)}
                css={[conversationCss, conversationKey === key && selectedConversationCss]}
                className={`conversation ${isProfi ? "profi" : "normal"} ${conversationKey === key ? "selected" : ""} ${
                    areComments ? "c-comments" : "c-messages"
                } `}
            >
                <div tw="flex">
                    <div tw="w-10/12">
                        {(!areComments || isProfi) && (
                            <div>
                                {`${isProfi ? `${messages[0].medicalSubjectPerson.fullName} - ` : ""}${
                                    messages[0].group != null
                                        ? messages[0].group.name
                                        : areComments
                                        ? ""
                                        : t("shCommunications.Messages.Conversations.AllTherapists")
                                } ${isProfi ? `(${messages[0].project.shortName})` : ""}`}
                            </div>
                        )}
                        <div className="messages-comments-count">
                            {areComments
                                ? t("shCommunications.Messages.Conversations.Comments") +
                                  " " +
                                  new Date(messages[0].date).toLocaleDateString(i18n.language, {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "numeric",
                                  })
                                : t("shCommunications.Messages.Conversations.Messages")}
                            : &nbsp;
                            <span className="messages-count">{totalCount}</span>
                        </div>
                    </div>
                    <div tw="w-2/12 m-auto flex">
                        <div className="conversation-state" tw="m-auto">
                            <button
                                type="button"
                                className="btn btn-link p-0"
                                onClick={(e) =>
                                    handleCommentConversationButtonClick(e, medicalSubjectIdKey, dateKey, areComments)
                                }
                                disabled={!isCommentConversationButtonEnabled(areComments)}
                            >
                                {notReadCount === 0 ? (
                                    <i
                                        className={`far fa-eye fa-sm ${isProfi ? "profi-" : ""}all-read`}
                                        css={allReadCss}
                                    ></i>
                                ) : (
                                    <span
                                        className={`not-read-count ${notReadCount > 0 ? "unread" : ""}`}
                                        css={notReadCountCss}
                                    >{`${notReadCount}`}</span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function isCommentConversationButtonEnabled(areComments) {
        return !isPatientPreview && areComments && isProfi && onCommentConversationClick;
    }

    function handleCommentConversationButtonClick(event, medSubId, defaultRecordVisibleDate, areComments) {
        if (!isCommentConversationButtonEnabled(areComments)) return;

        if (onCommentConversationClick) {
            event.stopPropagation();

            onCommentConversationClick(medSubId, defaultRecordVisibleDate);
            return;
        }
        //setFeedbackCustomData({ patientMedicalSubjectId: medSubId });
        //setPatientModalContext({ medicalSubjectId: medSubId, defaultRecordVisibleDate: date });
    }

    function getDate(date) {
        if (!date) return "";
        date = new Date(date);
        date.setHours(0, 0, 0, 0);

        return date;
    }

    function renderMessage(message, messageIndex, allMessages) {
        const isFirstMessage = conversations[conversationKey][0].id === message.id;
        const isLastMessage =
            conversations[conversationKey][conversations[conversationKey].length - 1].id === message.id;

        let curRef = null;
        if (isFirstMessage && isLastMessage) curRef = firstLastMessageRef;
        else if (isFirstMessage) curRef = firstMessageRef;
        else if (isLastMessage) curRef = lastMessageRef;

        return (
            <div key={message.id} ref={curRef} className="messages__old-messages-itemcontainer">
                {messageIndex === 0 ||
                getDate(message.createdDate).getTime() !==
                    getDate(allMessages[messageIndex - 1].createdDate).getTime() ? (
                    <div className={`messages__old-messages-dateitem ${messageIndex === 0 && "first"}`}>
                        {new Date(message.createdDate).toLocaleDateString(i18n.language, {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        })}
                    </div>
                ) : (
                    <div className="messages__old-messages-dateitem messages__old-messages-dateitem--empty" />
                )}

                <div
                    className={
                        "messages__old-messages-item" +
                        (message.isFromMe
                            ? " messages__old-messages-item--sender"
                            : " messages__old-messages-item--recipient")
                    }
                >
                    {!message.isFromMe && (
                        <Fragment>
                            {(messageIndex === 0 || message.sender.id !== allMessages[messageIndex - 1].sender.id) && (
                                <div className="messages__old-messages-fullname">{message.sender.fullName}</div>
                            )}

                            <div className={`messages__old-messages-circle ${isProfi ? "profi" : ""}`} css={circleCss}>
                                {message.sender.firstName && message.sender.firstName.charAt(0)}
                                {message.sender.lastName && message.sender.lastName.charAt(0)}
                                <span className="messages__old-messages-tooltip">{message.sender.fullName}</span>
                            </div>
                        </Fragment>
                    )}
                    <div
                        className={`messages__old-messages-message ${isProfi ? "profi" : ""}`}
                        css={message.isFromMe && senderMessageCss}
                    >
                        {(message.text ?? "").split("\n").map((t, i) => (
                            <Fragment key={i}>
                                {i !== 0 && <br />}
                                {t}
                            </Fragment>
                        ))}

                        <div className="dates-info">
                            <div
                                title={
                                    t("shCommunications.Messages.Old.Message.Inserted") +
                                    " " +
                                    new Date(message.createdDate).toLocaleDateString(i18n.language, {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                    })
                                }
                                className="creation-date"
                            >
                                {new Date(message.createdDate).toLocaleTimeString(i18n.language, {
                                    hour: "numeric",
                                    minute: "numeric",
                                })}
                                &nbsp;&nbsp;&nbsp;
                            </div>
                            {message.displayedDate !== null && (
                                <div className="displayed-date">
                                    <button
                                        type="button"
                                        className={`displayed-date-button ${
                                            !message.isFromMe ? (isProfi ? "profi" : "normal") : ""
                                        }`}
                                        css={!message.isFromMe && displayDateButtonCss}
                                        onClick={() => markTheMessageAsUnread(message)}
                                        disabled={message.isFromMe}
                                        title={`${
                                            !message.isFromMe
                                                ? t("shCommunications.Messages.Old.Message.CancelDisplay")
                                                : t("shCommunications.Messages.Old.Message.Displayed")
                                        } ${new Date(message.displayedDate).toLocaleDateString(i18n.language, {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                        })}`}
                                    >
                                        <i className="far fa-eye"></i>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Communications;
