import { apiPost, apiGet } from "./useFetch";

export async function sendTestNotification(onSuccess, onApiErrors) {
    let response = await apiPost("/pushnotifications/test");
    processResponse(response, onSuccess, onApiErrors);
}

export async function postSendingOnTest(value, onSuccess, onApiErrors) {
    let response = await apiPost("/web/push-notifications/sending-on-test", { allowed: value });
    processResponseWithReturnValue(response, onSuccess, onApiErrors, (response) => response.json.allowed);
}

export async function getSendingOnTest(onSuccess, onApiErrors) {
    const response = await apiGet("/web/push-notifications/sending-on-test");
    processResponseWithReturnValue(response, onSuccess, onApiErrors, (response) => response.json.allowed);
}

export async function getNotificationList(onSuccess, onApiErrors) {
    const response = await apiGet("/web/push-notifications/list");
    processResponseWithReturnValue(response, onSuccess, onApiErrors, (response) => response.json);
}

export async function removeNotification(notification, onSuccess, onApiErrors) {
    const response = await apiPost("/web/push-notifications/remove", {
        id: notification.id,
        type: notification.type,
    });
    processResponse(response, onSuccess, onApiErrors);
}

function processResponse(response, onSuccess, onApiErrors) {
    if (response.isOk) {
        onSuccess();
    } else {
        onApiErrors(response.errors);
    }
}

function processResponseWithReturnValue(response, onSuccess, onApiErrors, getValueFromReponse) {
    if (response.isOk) {
        onSuccess(getValueFromReponse(response));
    } else {
        onApiErrors(response.errors);
    }
}
