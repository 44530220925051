import { useTranslation } from "react-i18next";
import EcgChart from "./components/EcgChart";
import { format } from "date-fns";
import "./EcgDialog.scss";
import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { Fragment, useState } from "react";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";

export default function EcgDialog({ displayOpen, trigger, fetchService, recordId, recordDate, onCloseClicked }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(displayOpen);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Fragment>
            {trigger && trigger(() => setOpen(true))}
            <Dialog open={open} onClose={handleClose} maxWidth="xl" fullScreen={fullScreen}>
                <DialogTitle>{`${t("ecg.EcgModalTitle")}: ${format(recordDate, "dd. MM. yyyy HH:mm")}`}</DialogTitle>
                <DialogCloseButton onClick={handleClose} />
                <DialogContent tw="min-width[400px] min-height[400px]">
                    <div className="ecg-chart">
                        <EcgChart fetchService={fetchService} recordId={recordId} />
                    </div>
                </DialogContent>
            </Dialog>
        </Fragment>
    );

    function handleClose() {
        setOpen(false);
        if (onCloseClicked) onCloseClicked();
    }
}
