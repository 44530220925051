import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import tw from "twin.macro";

const DaysLink = ({ linkValue, onClick, collapsed, active, first }) => {
    const { t } = useTranslation();

    function getLinkValueText() {
        if (linkValue === null) return t("recordsVisualization.VisiblePeriod.All");

        if (!isNaN(linkValue)) {
            return linkValue > 1
                ? `${linkValue} ${t("recordsVisualization.VisiblePeriod.Days")}`
                : `${linkValue} ${t("recordsVisualization.VisiblePeriod.Day")}`;
        }

        let text = t("recordsVisualization.VisiblePeriod." + linkValue);
        if (first) text = text[0].toUpperCase() + text.slice(1);
        return text;
    }

    let endIcon = null;
    if (collapsed !== undefined) endIcon = collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />;

    return (
        <Button onClick={onClick} endIcon={endIcon} tw="normal-case font-semibold" css={active && tw`underline!`}>
            {getLinkValueText()}
        </Button>
    );
};

export default DaysLink;
