import { useSelector } from "react-redux";
import { prescriptionTypes } from "../../codebooks/prescriptionTypes";
import ActionPrescription from "./ActionPrescription";
import DrugPrescription from "./DrugPrescription";
import MeasurementPrescription from "./MeasurementPrescription";
import SurveyPrescription from "./SurveyPrescription";

export default function PrescriptionFactory({ prescriptionId, index }) {
    const prescriptionType = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].$type);
    const commonProps = { prescriptionId, index };
    switch (prescriptionType) {
        case prescriptionTypes.drug:
            return <DrugPrescription {...commonProps} />;
        case prescriptionTypes.action:
            return <ActionPrescription {...commonProps} />;
        case prescriptionTypes.measurement:
            return <MeasurementPrescription {...commonProps} />;
        case prescriptionTypes.survey:
            return <SurveyPrescription {...commonProps} />;
        default:
            throw new Error(`Invalid prescription item type`);
    }
}
