import { useTranslation } from "react-i18next";
import ExtConnector from "./ExtConnector";
import useHuawei from "../hooks/useHuawei";
import { isConnected } from "../codebooks/externalConnectionStatus";

export default function HuaweiConnection({ readonly, Layout }) {
    const { t } = useTranslation();
    const { huaweiConnectionInfo, connect, disconnect, ApiErrors, huaweiApiError } = useHuawei();

    return (
        <ExtConnector
            title={t("extConnectors.Huawei.Title")}
            infoText={t("extConnectors.Huawei.InfoText")}
            connected={isConnected(huaweiConnectionInfo?.status)}
            account={huaweiConnectionInfo?.accountName}
            connectButtonLabel={t("extConnectors.Huawei.ConnectButtonLabel")}
            disconnectButtonLabel={t("extConnectors.Huawei.DisconnectButtonLabel")}
            lastImportDate={huaweiConnectionInfo?.lastImportDate}
            status={huaweiConnectionInfo?.status}
            onConnectClick={connect}
            onDisconnectClick={disconnect}
            ApiErrors={ApiErrors}
            customError={huaweiApiError}
            readonly={readonly}
            Layout={Layout}
        />
    );
}
