import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import FoodListView from "./FoodListView";
import Popup from "reactjs-popup";

export default function CaloricsTableView({ caloricsTable }) {
    const { t } = useTranslation();

    function getTotalNutricvalues() {
        return (
            <Fragment>
                <div className="total-info">
                    <span className="total-kj">{Math.round(caloricsTable.totalNutricValues.energy)}</span>&nbsp;
                    <span className="kt-units">{caloricsTable.energyUnit}</span>
                    <span className="total-label">{t("caloricsTable.TotalIntake")}</span>
                    <span className="total-weeks">
                        {getPopover(
                            Math.round(caloricsTable.weekAverageNutricValues.energy),
                            caloricsTable.totalNutricValues.energy,
                            caloricsTable.energyUnit,
                            "right center",
                        )}
                    </span>
                </div>
                <div id="structure">
                    <table className="table">
                        <tbody>
                            {getNutricRow(
                                "Proteins",
                                caloricsTable.totalNutricValues.proteins,
                                getEnergy(caloricsTable.totalNutricValues.proteins, 17, 4),
                                caloricsTable.weekAverageNutricValues.proteins,
                            )}

                            {getNutricRow(
                                "Carbs",
                                caloricsTable.totalNutricValues.carbs,
                                getEnergy(caloricsTable.totalNutricValues.carbs, 17, 4),
                                caloricsTable.weekAverageNutricValues.carbs,
                            )}

                            {getNutricRow(
                                "Fats",
                                caloricsTable.totalNutricValues.fats,
                                getEnergy(caloricsTable.totalNutricValues.fats, 38, 9),
                                caloricsTable.weekAverageNutricValues.fats,
                            )}

                            {getNutricRow(
                                "Fiber",
                                caloricsTable.totalNutricValues.fiber,
                                null,
                                caloricsTable.weekAverageNutricValues.fiber,
                            )}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        );
    }

    function getEnergy(value, kJ, kcal) {
        if (caloricsTable.energyUnit === "kJ") return value * kJ;
        else return value * kcal;
    }

    function getNutricRow(text, value, energy, weekValue) {
        return (
            <tr>
                <th>{t("caloricsTable." + text)}</th>
                <td>{Math.round(value)}g</td>
                <td>{getPercent(energy)}</td>
                <td>{getPopover(weekValue, value, "g", "left center")}</td>
            </tr>
        );
    }

    function getPopover(weekValue, value, unit, position) {
        return (
            <Popup
                trigger={
                    <span className="badge">
                        Ø {weekValue} {unit}
                    </span>
                }
                position={position}
                on={["hover", "focus"]}
                arrow={true}
            >
                {getDataContent(weekValue, value)}
            </Popup>
        );
    }

    function getDataContent(weekValue, value) {
        var percent = (value / weekValue) * 100;
        var text;
        if (percent > 100) {
            text = t("caloricsTable.More");
            percent -= 100;
        } else {
            text = t("caloricsTable.Less");
            if (value === 0) percent = 100;
            else percent = 100 - percent;
        }

        return (
            <div className="popover-title-week">
                {t("caloricsTable.WeekAverage")}
                <br />
                {`${t("caloricsTable.TodayAbout")} ${Math.round(percent, 0)} % ${text}`}
            </div>
        );
    }

    function getPercent(value) {
        if (!value) return "-";
        return Math.round((value / caloricsTable.totalNutricValues.energy) * 100, 0) + "%";
    }

    if (!caloricsTable) return null;

    return (
        <Fragment>
            <div className="food-header" tw="clear-both">
                {getTotalNutricvalues()}
            </div>
            <div tw="clear-both">
                {caloricsTable.courses.map((course) => (
                    <div key={course.id}>
                        <FoodListView
                            courseName={course.name}
                            foodList={course.foodList}
                            className="open"
                            isHiddenHeader={true}
                            isHiddenSumm={true}
                        />
                    </div>
                ))}
            </div>
        </Fragment>
    );
}
