import { createSlice } from "@reduxjs/toolkit";
import { clearGroupProps, newPrescription, prescriptionGroupProps, prescriptionGroupPropsSet } from "./newPrescription";
import { newSchedule } from "./newSchedule";
import { newPrescriptionsState } from "./newPrescriptionsState";
import { removeFromArray } from "@profilog/utils/array";
import { createApiCall, createApiGet, createApiPost, getArgName } from "./toolkit";
import { denormalizePrescription, normalizePrescription } from "./prescriptionNormalization";
import { apiGet } from "@profilog/misc/utils/useFetch";
import differenceInDays from "date-fns/differenceInDays";
import { addDays } from "date-fns";
import { prescriptionTypes, supportsAmount } from "../codebooks/prescriptionTypes";
import { scheduleTypes } from "../codebooks/scheduleTypes";
import _ from "lodash";
import { updateFastTimeScheduleFromSchedules, updateSchedulesFromFastTimeShedule } from "./fastTimeSchedule";

const prescriptionEditApiGet = createApiCall(
    "prescriptions",
    "getEdited",
    async (arg) => await apiGet(`/web/prescriptions/${arg}`),
    null,
    (state, payload) => {
        normalizePrescription(payload.result, state);
        state.prescriptions.byId[payload.result.id].editing = true;
    },
);

const prescriptionListApiGet = createApiGet("prescriptions", "list", prepareGetUrl);

function prepareGetUrl(arg) {
    if (arg && arg.groupId) return `/web/groups/${arg.groupId}/prescriptions`;

    return "/web/medical-subjects/current/prescriptions";
}

const prescriptionSaveApiPost = createApiPost(
    "prescriptions",
    "save",
    "/web/prescriptions",
    (prescriptionId, state) => {
        const result = [];
        const errors = [];

        // TODO: implementovat validace na správném místě (např. přes yup).
        const prescription = state.prescriptions.prescriptions.byId[prescriptionId];
        if (prescription.$type === prescriptionTypes.drug && prescription.drug === null)
            errors.push("tran:prescriptions.Edit.Validations.SelectDrug");

        if (prescription.$type === prescriptionTypes.action && prescription.action === null)
            errors.push("tran:prescriptions.Edit.Validations.SelectAction");

        if (
            prescription.$type === prescriptionTypes.measurement &&
            (!prescription.parameters || prescription.parameters.length === 0)
        )
            errors.push("tran:prescriptions.Edit.Validations.SelectParams");

        if (prescription.$type === prescriptionTypes.survey && prescription.parameterGroup === null)
            errors.push("tran:prescriptions.Edit.Validations.SelectSurvey");

        for (const scheduleId of prescription.schedules) {
            const schedule = state.prescriptions.schedules.byId[scheduleId];
            if (schedule.$type === scheduleTypes.situation && schedule.situation === null)
                errors.push("tran:prescriptions.Edit.Validations.SelectSituation");

            if (schedule.$type === scheduleTypes.paramValue && schedule.parameter === null)
                errors.push("tran:prescriptions.Edit.Validations.SelectParam");
        }

        if (errors.length > 0) throw errors;

        result.push(denormalizePrescription(prescriptionId, state));
        return result;
    },
);

export const prescriptionSlice = createSlice({
    name: "prescriptions",
    initialState: newPrescriptionsState(),
    reducers: {
        prescriptionChangeListTypeUrl: (state, action) => _prescriptionChangeListTypeUrl(state, action.payload),

        prescriptionCancelEdit: (state, action) => _prescriptionCancelEdit(state, action.payload.prescriptionId),

        prescriptionCreate: (state, action) =>
            _prescriptionCreate(
                state,
                action.payload.prescriptionType,
                action.payload.initProps,
                action.payload.stateKey,
            ),

        prescriptionUpdate: (state, action) =>
            _prescriptionUpdate(state, action.payload.prescriptionId, action.payload.updated),

        prescriptionDelete: (state, action) => _prescriptionDelete(state, action.payload.prescriptionId),

        prescriptionScheduleCreate: (state, action) =>
            _prescriptionScheduleCreate(state, action.payload.prescriptionId, action.payload.scheduleType),

        prescriptionScheduleUpdate: (state, action) =>
            _prescriptionScheduleUpdate(state, action.payload.scheduleId, action.payload.updated),

        prescriptionScheduleDelete: (state, action) => _prescriptionScheduleDelete(state, action.payload.scheduleId),
    },
    extraReducers: {
        ...prescriptionSaveApiPost.reducers,
        ...prescriptionEditApiGet.reducers,
        ...prescriptionListApiGet.reducers,
    },
});

export const usePrescriptionSave = prescriptionSaveApiPost.useHook;
export const useGetPrescriptions = prescriptionListApiGet.useHook;
export const useEditPrescription = prescriptionEditApiGet.useHook;

export const {
    prescriptionChangeListTypeUrl,
    prescriptionCancelEdit,
    prescriptionClearNew,
    prescriptionCreate,
    prescriptionUpdate,
    prescriptionDelete,
    prescriptionScheduleCreate,
    prescriptionScheduleUpdate,
    prescriptionScheduleDelete,
} = prescriptionSlice.actions;

export default prescriptionSlice.reducer;

function _prescriptionChangeListTypeUrl(state, listTypeUrl) {
    state.listTypeUrl = listTypeUrl;

    return state;
}

function _prescriptionCancelEdit(state, prescriptionId, stateKey) {
    const prescription = state.prescriptions.byId[prescriptionId];
    prescription.editing = false;

    if (prescriptionId < 0) {
        const argName = getArgName(prescription.groupId ? { groupId: prescription.groupId } : null);
        removeFromArray(state.newPrescriptions[argName][prescription.$type], prescriptionId);
        updateExistsNew(state);
    }

    return state;
}

function updateExistsNew(state) {
    state.existsNew = _.some(Object.values(state.newPrescriptions), (array) => array.length > 0);
}

export function _prescriptionCreate(state, prescriptionType, initProps = null, stateKey = null) {
    let prescription = newPrescription(prescriptionType, initProps);
    state.prescriptions.byId[prescription.id] = prescription;

    if (prescription.id < 0) {
        prepareFirstSchedule(state, prescription, prescription.scheduleType);

        let argName = getArgName(stateKey);
        let statePart = state.newPrescriptions[argName];
        if (!statePart) {
            state.newPrescriptions[argName] = statePart = {};
            for (let type of Object.values(prescriptionTypes)) statePart[type] = [];
        }

        statePart[prescriptionType].push(prescription.id);
        state.existsNew = true;
    }

    return state;
}

export function _prescriptionUpdate(state, prescriptionId, updated) {
    let prescription = state.prescriptions.byId[prescriptionId];
    Object.assign(prescription, updated);

    // Dopočítání data od, počtu dnů a data do dle vyplnění jednotlivých políček
    calculateDuration(prescription, updated);

    // U předpisů měření od první aplikace ponecháme nejvýše jeden parameter.
    if (prescription.dateFrom === null && prescription.parameters && prescription.parameters.length > 1)
        prescription.parameters.length = 1;

    if (updated.fastTimeSchedule !== undefined) updateSchedulesFromFastTimeShedule(state, prescription);

    // Pokud u nového předpisu ručně otvíráme pokročilé možnosti, rovnou založíme první plán
    if (prescriptionId < 0 && updated.showSchedules === true && prescription.schedules.length === 0)
        _prescriptionScheduleCreate(state, prescription.id, prescription.scheduleType);

    if (updated.scheduleType !== undefined) prepareNewSchedules(state, prescription, updated.scheduleType);

    // Hromadné nastavení do schedules:
    if (
        updated.amountNote !== undefined ||
        updated.interval !== undefined ||
        updated.intervalUnit !== undefined ||
        updated.weekDays !== undefined
    ) {
        for (const scheduleId of prescription.schedules) _prescriptionScheduleUpdate(state, scheduleId, updated);
    }

    return state;
}

function prepareFirstSchedule(state, prescription, scheduleType) {
    if (prescription.$type !== prescriptionTypes.drug || prescription.scheduleType !== scheduleTypes.time)
        _prescriptionScheduleCreate(state, prescription.id, scheduleType);
}

function prepareNewSchedules(state, prescription, scheduleType) {
    clearGroupProps(prescription);

    for (let scheduleId of [...prescription.schedules]) _prescriptionScheduleDelete(state, scheduleId);

    prepareFirstSchedule(state, prescription, scheduleType);
}

function calculateDuration(prescription, updated) {
    let dateFrom = prescription.dateFrom !== null ? new Date(prescription.dateFrom) : null;
    let dateTo = prescription.dateTo !== null ? new Date(prescription.dateTo) : null;
    let days = prescription.days !== null ? prescription.days : null;

    // Odnastavení dnů
    if (dateFrom === null && dateTo !== null) prescription.days = null;

    // Vymazání "data do" při změně počtu dnů.
    if (dateFrom !== null && updated.days === null) {
        dateTo = null;
        prescription.dateTo = null;
    }

    // Dopočítání "data do" z "data od" a počtu dnů.
    if (dateFrom !== null && days !== null && (updated.dateFrom !== undefined || updated.days !== undefined)) {
        dateTo = addDays(dateFrom, days - 1);
        prescription.dateTo = dateTo.toISOString();
    }

    // Oprava a dopočítání dnů pokud je vyplněné datum od i do.
    if (dateFrom != null && dateTo != null) {
        // Oprava data do menšího než datum od.
        if (dateTo.getTime() < dateFrom.getTime()) {
            prescription.dateTo = prescription.dateFrom;
            dateTo = dateFrom;
        }
        if (updated.days === undefined) {
            // Dopočítání dnů z data od a data do.
            let diff = differenceInDays(dateTo, dateFrom);
            prescription.days = diff + 1;
        }
    }
}

function _prescriptionDelete(state, prescriptionId) {
    let prescription = state.prescriptions.byId[prescriptionId];

    for (let scheduleId of [...prescription.schedules]) _prescriptionScheduleDelete(state, scheduleId);

    delete state.prescriptions.byId[prescriptionId];

    if (prescription.id < 0) {
        removeFromArray(state.newPrescriptions[prescription.$type], prescription.id);
        updateExistsNew(state);
    }

    return state;
}

export function _prescriptionScheduleCreate(state, prescriptionId, scheduleType, initProps = null) {
    let schedule = newSchedule(prescriptionId, scheduleType, initProps);
    state.schedules.byId[schedule.id] = schedule;

    let prescription = state.prescriptions.byId[prescriptionId];
    prescription.schedules.push(schedule.id);

    // Poslední založený schedule bude sloužit jako scheduleType pro předpis.
    // (Bylo dohodnuto, že v jednom předpisu již budou jen schedules stejného typu).
    prescription.scheduleType = scheduleType;

    schedule.supportsAmount = supportsAmount(prescription.$type);
    schedule.amountNote = prescription.amountNote;

    if (scheduleType === scheduleTypes.time) {
        prescription.fastTimeScheduleSame = false;
        schedule.interval = prescription.interval;
        schedule.intervalUnit = prescription.intervalUnit;
        schedule.weekDays = prescription.weekDays;
    }

    return state;
}

export function _prescriptionScheduleUpdate(state, scheduleId, updated, skipFastScheduleUpdate = false) {
    let schedule = state.schedules.byId[scheduleId];
    Object.assign(schedule, updated);

    checkPrescriptionGroupProps(state, schedule.prescriptionId, Object.keys(updated));

    if (
        (updated.time !== undefined || updated.amount !== undefined) &&
        schedule.$type === scheduleTypes.time &&
        !skipFastScheduleUpdate
    ) {
        updateFastTimeScheduleFromSchedules(state, schedule.prescriptionId);
    }

    checkOpenSheduleList(state, schedule.prescriptionId);

    return state;
}

export function _prescriptionScheduleDelete(state, scheduleId) {
    let schedule = state.schedules.byId[scheduleId];
    delete state.schedules.byId[scheduleId];

    let prescription = state.prescriptions.byId[schedule.prescriptionId];
    removeFromArray(prescription.schedules, schedule.id);

    checkPrescriptionGroupProps(state, schedule.prescriptionId, prescriptionGroupProps);

    if (schedule.$type === scheduleTypes.time) updateFastTimeScheduleFromSchedules(state, prescription.id);

    checkOpenSheduleList(state, prescription.id);

    return state;
}

function checkPrescriptionGroupProps(state, prescriptionId, props) {
    let groupPropsSame = true;
    const prescription = state.prescriptions.byId[prescriptionId];

    for (const key of props) {
        if (!prescriptionGroupPropsSet.has(key)) continue;

        const prescriptionProp = prescription[key];
        if (prescriptionProp === undefined) continue;

        const uniqueValues = _.chain(prescription.schedules)
            .map((scheduleId) => state.schedules.byId[scheduleId][key])
            .uniq()
            .value();

        let allSame = false;

        if (uniqueValues.length === 0) allSame = true;
        else if (uniqueValues.length === 1) {
            const uniqueValue = uniqueValues[0];
            prescription[key] = uniqueValue;
            allSame = true;
        }

        prescription[key + "Same"] = allSame;
        groupPropsSame = groupPropsSame & allSame;
    }

    prescription.groupPropsSame = groupPropsSame;
}

function checkOpenSheduleList(state, prescriptionId) {
    const prescription = state.prescriptions.byId[prescriptionId];

    const amountMaxSet = _.chain(prescription.schedules)
        .map((scheduleId) => state.schedules.byId[scheduleId].amountMax)
        .some((amountMax) => amountMax !== null && amountMax !== "")
        .value();

    prescription.openScheduleList = !prescription.groupPropsSame || !prescription.fastTimeScheduleSame || amountMaxSet;
}
