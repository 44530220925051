import LoginCard from "../../../shared/components/LoginCard";

import "./PlusCareHome.scss";
import IconHearth from "../assets/images/icon-hearth.png";
import IconBottle from "../assets/images/icon-bottle.png";
import IconMed from "../assets/images/icon-med.png";
import { Fragment } from "react";

export default function PlusCareHome() {
    return (
        <Fragment>
            <section className="home-page">
                <div className="content">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-7">
                                <div className="hero-box">
                                    <div className="hero-headers">
                                        <h1 className="text-center">Asistent pro zdravý život</h1>

                                        <div className="hero-icons d-flex flex-wrap">
                                            <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4 icons-wrap">
                                                <div className="icon-img">
                                                    <img src={IconHearth} alt="icon-heart" />
                                                </div>
                                                <span>Telemetrie</span>
                                            </div>

                                            <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4 icons-wrap">
                                                <div className="icon-img">
                                                    <img src={IconBottle} alt="icon-heart" />
                                                </div>
                                                <span>Zdravotní služby</span>
                                            </div>

                                            <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4 icons-wrap">
                                                <div className="icon-img">
                                                    <img src={IconMed} alt="icon-heart" />
                                                </div>
                                                <span>Preventivní prohlídky</span>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <a href="registration" className="btn btn-primary text-white">
                                                Nová registrace
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-5">
                                <LoginCard disableRegistration />
                            </div>
                        </div>

                        <div className="col-md-12 text-center mt-5 mb-4">
                            <h2 className="mb-2">Naše služby</h2>

                            <p>
                                Pomáháme při hledání lékařů, s termíny preventivních prohlídek a s měřením vašich health
                                dat.
                            </p>
                        </div>

                        <div className="row">
                            <div className="col-sm panel">
                                <div className="icon">
                                    <i className="fas fa-table"></i>
                                </div>
                                <h5 className="text-center">Data pod kontrolou.</h5>
                                <div>Ukládejte si vývoj svého zdravotního stavu či aktivit. Sledujte své pokroky!</div>
                            </div>

                            <div className="col-sm panel offset-1">
                                <div className="icon">
                                    <i className="fas fa-heartbeat"></i>
                                </div>
                                <h5 className="text-center">Zprostředkování zdravotních služeb</h5>
                                <p>
                                    Najděte specialistu, kterého potřebujete i ve svém okolí! Hledání lékaře nebylo
                                    nikdy tak snadné a lehké.
                                </p>
                            </div>
                            <div className="col-sm panel offset-1">
                                <div className="icon">
                                    <i className="fas fa-desktop"></i>
                                </div>
                                <h5 className="text-center">Monitorování preventivních prohlídek</h5>
                                <p>
                                    Mějte přehled kdy a ke kterému lékaři se máte dostavit. Pohlídejte si své termíny u
                                    odborníků.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center mt-5 mb-3">
                                <h2 className="mb-3">Které aspekty zdravotního stavu řešíme?</h2>

                                <p>
                                    Je zde mnoho variant příznaků a měření, které systém poskytuje. Ideálním přístupem
                                    je identifikovat primární oblast, na kterou se pacient pod vedením odborníka chce
                                    soustředit a nechat lékaře předepsat parametry pozorování.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6 mb-2">
                                <div className="panel">
                                    <div className="icon">
                                        <i className="fas fa-weight"></i>
                                    </div>
                                    <h5 className="text-center">Hmotnost</h5>
                                    <p className="text-center">
                                        Sledujte svou váhu a její změny. Data mohou sloužit jako podklad při diskusi s
                                        odborníkem.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="panel">
                                    <div className="icon">
                                        <i className="fas fa-running"></i>
                                    </div>
                                    <h5 className="text-center">Pohyb</h5>
                                    <p className="text-center">
                                        Systém sleduje kroky. Tyto údaje slouží jako podklad k monitorování každodenních
                                        pohybových pokroků a celkové kondice.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}
