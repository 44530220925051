import { useFetch } from "@profilog/misc/utils/useFetch";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function useCheckCaloricsTablesImport(connected) {
    const { apiPost, ApiErrors, pending } = useFetch();
    const [checkCtImportDone, setCheckCtImportDone] = useState(false);

    useEffect(() => {
        async function checkKtImport() {
            await apiPost("/calorics-table/check-import");
            setCheckCtImportDone(true);
        }

        if (connected) checkKtImport();
        else if (connected === false) setCheckCtImportDone(true);
    }, [connected]); // eslint-disable-line

    return {
        checkCtImportPending: pending,
        checkCtImportDone,
        CheckCtImportApiErrors,
    };

    function CheckCtImportApiErrors() {
        const { t } = useTranslation();
        return (
            <ApiErrors
                modal
                title={t("recordsVisualization.CaloricsTables")}
                translationPrefix="caloricsTable.apiResult"
            />
        );
    }
}
