import { useState, useEffect } from "react";
import { useFetch } from "@profilog/misc/utils/useFetch";

export default function useUserInitializationPersonalInfo() {
    const { apiGet, apiPut, ApiErrors } = useFetch();
    const [isLoading, setIsLoading] = useState(true);
    const [personalInfo, setPersonalInfo] = useState({});

    useEffect(() => {
        async function getPersonalInfo() {
            const response = await apiGet("/web/user/initialization/personal-info");
            if (response.isOk) {
                setPersonalInfo(response.json);
            }
            setIsLoading(false);
        }
        getPersonalInfo();
    }, []);

    async function savePersonalInfo(personalInfo) {
        const response = await apiPut("/web/user/initialization/personal-info", personalInfo);
        return response.isOk;
    }

    return { personalInfo, savePersonalInfo, isLoading, ApiErrors };
}
