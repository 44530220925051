import { Fragment, useEffect, useState } from "react";
import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    CommonSeriesSettings,
    Legend,
    Label,
    Tick,
    Format,
    Size,
    Animation,
    CommonAxisSettings,
    Grid,
    MinorGrid,
    Pane,
    Font,
} from "devextreme-react/chart";
import { CircularProgress } from "@mui/material";

export default function EcgChart({ fetchService, recordId }) {
    const { getEcgDataAsync, parametersData, GetApiErrors } = fetchService;
    const [ecgData, setEcgData] = useState();
    const [isLoaded, setIsLoaded] = useState();

    useEffect(() => {
        async function getEcgData() {
            if (!recordId || recordId == null) return;
            let result = await getEcgDataAsync(recordId, 10);
            if (result.isOk) setEcgData(result.json);
            setIsLoaded(true);
        }

        setIsLoaded(false);
        if (!parametersData) return;

        getEcgData();
    }, [recordId, parametersData]);

    function getValueAxes() {
        var axes = [];
        for (let index = 0; index < ecgData.paneCount; index++) {
            axes.push(
                <ValueAxis
                    key={index}
                    pane={index + 1}
                    position="left"
                    name={"ecg" + (index + 1)}
                    minorTickCount={4}
                    tickInterval={500}
                    defaultVisualRange={{ startValue: -2500, endValue: 2500 }}
                    visible={false}
                >
                    <Label
                        visible={true}
                        position="inside"
                        indentFromAxis={0}
                        alignment="left"
                        indentFromAxis={20}
                        customizeText={(data) => valueAxisCustomizeLabelText(data, index)}
                    >
                        <Font
                            weight={600}
                            size={window.innerWidth < 1200 ? 15 : Math.round(window.innerWidth / 100) + 2}
                        />
                    </Label>
                    <Tick visible={false} />
                </ValueAxis>,
            );
        }
        return axes;
    }

    function getPanes() {
        var panes = [];
        for (let index = 0; index < ecgData.paneCount; index++) {
            panes.push(<Pane key={index} name={index + 1} />);
        }
        return panes;
    }

    function getSeries() {
        var series = [];
        const parameterSettings = parametersData.parameterSettings.find((p) => p.systemName === "Ecg");

        for (let index = 0; index < ecgData.paneCount; index++) {
            series.push(
                <Series
                    key={index}
                    valueField={"ecg" + (index + 1)}
                    axis={"ecg" + (index + 1)}
                    type="spline"
                    pane={index + 1}
                    color={parameterSettings.color}
                />,
            );
        }
        return series;
    }

    function valueAxisCustomizeLabelText(data, index) {
        return data.value == -2000 ? `${index * 10} - ${(index + 1) * 10} s` + "\n&nbsp;" : "";
    }

    if (!isLoaded)
        return (
            <div tw="flex justify-center">
                <CircularProgress />
            </div>
        );

    return (
        <Fragment>
            <GetApiErrors />
            {ecgData && (
                <Chart dataSource={ecgData.dataSource} adjustOnZoom={true}>
                    <Size
                        height={window.innerWidth < 1200 ? 500 : 5 * Math.round(window.innerWidth / 14)}
                        width={
                            ecgData.paneCount == 3
                                ? window.innerWidth < 1200
                                    ? 800
                                    : 8 * Math.round(window.innerWidth / 14)
                                : window.innerWidth < 1200
                                ? 1200
                                : 12 * Math.round(window.innerWidth / 14)
                        }
                    />
                    <ArgumentAxis argumentType="numeric" hoverMode="none" minorTickCount={4} tickInterval={0.2}>
                        <Label wordWrap="none" visible={true}>
                            <Format formatter={(x) => (x % 1 == 0 ? `${x}` : "")} />
                        </Label>
                    </ArgumentAxis>

                    {getValueAxes()}
                    {getPanes()}
                    {getSeries()}
                    <Animation enabled={true} />
                    <Legend visible={false} />
                    <CommonSeriesSettings
                        argumentField="time"
                        selectionMode="none"
                        hoverMode="none"
                        spline={{
                            point: {
                                visible: false,
                            },
                            width: 1,
                        }}
                    ></CommonSeriesSettings>
                    <CommonAxisSettings endOnTick={true}>
                        <Grid opacity="5" visible={true} />
                        <MinorGrid visible={true} />
                    </CommonAxisSettings>
                </Chart>
            )}
        </Fragment>
    );
}
