import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";
import ResetPasswordForm from "./ResetPasswordForm";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ResetPasswordDialog({ trigger, getUserName, isUserNameDisabled }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            {trigger(() => setOpen(true))}
            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogCloseButton onClick={handleClose} />
                <DialogTitle>{t("auth.ResetPassword.Title")}</DialogTitle>
                <DialogContent dividers>
                    <ResetPasswordForm userName={getUserName()} isUserNameDisabled={isUserNameDisabled} />
                </DialogContent>
            </Dialog>
        </Fragment>
    );

    function handleClose() {
        setOpen(false);
    }
}
