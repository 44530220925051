import { css } from "twin.macro";
import GooglePlayImg from "./GooglePlay.png";

export default function GooglePlayLink({ height = 50, className, appUrl }) {
    if (!appUrl) return null;

    return (
        <a href={appUrl} target="_blank" rel="noreferrer" className={className}>
            <img
                src={GooglePlayImg}
                alt="Nyní na GooglePlay"
                css={css`
                    height: ${height}px;
                `}
            />
        </a>
    );
}
