import { Button } from "@mui/material";

export default function ExternalConnectionSelectButton({ stepName, title, isConnected, connect, isLoading, onClick }) {
    function onCLick() {
        if (!isConnected && connect) {
            connect(`connectionSuccessStep=${stepName}`);
        } else {
            onClick();
        }
    }

    return (
        <Button variant="outlined" color="secondary" disabled={isLoading || isConnected} onClick={onCLick}>
            {title}
        </Button>
    );
}
