import PrescriptionContainer from "./PrescriptionContainer";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useDispatch } from "react-redux";
import { useEditPrescription } from "../redux/prescriptionSlice";
import ApiErrorsModal from "@profilog/misc/errors/ApiErrorsModal";
import "twin.macro";
import { getPrescriptionDesc } from "../services/getPrescriptionDesc";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import DeleteDialog from "@profilog/commons/components/DeleteDialog";
import GroupChip from "./commons/GroupChip";

export default function PrescriptionView({ prescription, allowEdit, onDelete, showGroupName = false }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        thunk: editPrescription,
        pending,
        apiErrors,
        dispatchResetApiErrors,
    } = useEditPrescription(prescription.id);

    const desc = getPrescriptionDesc(prescription, t);

    return (
        <PrescriptionContainer
            prescriptionType={prescription.$type}
            buttons={
                allowEdit && (
                    <Fragment>
                        <IconButton
                            color="primary"
                            size="small"
                            onClick={() => dispatch(editPrescription(prescription.id))}
                            disabled={pending}
                        >
                            <EditIcon />
                        </IconButton>
                        <DeleteDialog
                            title={t("prescriptions.List.Deletion")}
                            trigger={(open) => (
                                <IconButton color="primary" size="small" onClick={open} disabled={pending}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                            onConfirmed={() => onDelete(prescription.id)}
                        >
                            {t("prescriptions.List.ConfirmDelete", { number: prescription.id })}
                        </DeleteDialog>
                    </Fragment>
                )
            }
        >
            <ApiErrorsModal apiErrors={apiErrors} resetApiErrors={dispatchResetApiErrors} />
            <div>
                <div tw="flex flex-wrap pt-1">
                    <div tw="mr-2 w-auto lg:w-64">
                        {desc.title}
                        {desc.subTitle && <div tw="text-xs">{desc.subTitle}</div>}
                        {desc.duration && <div tw="text-xs">{desc.duration}</div>}
                    </div>
                    {/* <div tw="w-auto lg:w-28 pr-2">{desc.scheduleType}</div> */}
                    <div tw="flex-1">
                        {desc.schedules &&
                            desc.schedules.length > 0 &&
                            desc.schedules.map((scheduleDesc) => <div key={scheduleDesc}>{scheduleDesc}</div>)}
                    </div>
                </div>
                {prescription.groupId !== null && (
                    <div tw="text-xs mt-2 flex flex-col items-start sm:(flex-row items-center) flex-wrap gap-2">
                        {showGroupName && prescription.groupName && <GroupChip groupName={prescription.groupName} />}
                        {prescription.createdByFullName && (
                            <div>
                                {t("prescriptions.Edit.CreatedBy")} <strong>{prescription.createdByFullName}</strong>
                            </div>
                        )}
                        {prescription.updatedByFullName &&
                            prescription.updatedByFullName !== prescription.createdByFullName && (
                                <div>
                                    {t("prescriptions.Edit.EditedBy")} <strong>{prescription.updatedByFullName}</strong>
                                </div>
                            )}
                    </div>
                )}
            </div>
        </PrescriptionContainer>
    );
}
