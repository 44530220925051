import { useCallback, useEffect, useRef } from "react";

export function useWindowResize({ started, confirmed, onResizing, forceFirstUpdate = false, confirmDelay = 1000 }) {
    const timeout = useRef(null);
    let lastWidth = useRef(null);

    const resizeCallback = useCallback(() => {
        if (onResizing) onResizing();

        if (timeout.current === null && confirmDelay) {
            if (started) started();
            setTimeout();
        }

        function setTimeout() {
            lastWidth.current = window.innerWidth;
            timeout.current = window.setTimeout(handleTimeout, confirmDelay);
        }

        function handleTimeout() {
            if (lastWidth.current === window.innerWidth) {
                if (confirmed) confirmed(lastWidth.current);
                timeout.current = null;
            } else setTimeout();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (forceFirstUpdate) resizeCallback();
        window.addEventListener("resize", resizeCallback);
        return () => window.removeEventListener("resize", resizeCallback);
    }, []); //eslint-disable-line
}
