import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { getErrorMessage } from "@profilog/misc/errors/ValidationError";
import { useFetch } from "@profilog/misc/utils/useFetch";
import PasswordInput from "@profilog/misc/auth/components/PasswordInput";
import { Button, TextField } from "@mui/material";
import { Navigate, useNavigate } from "react-router";
import ConsentsForm from "@profilog/misc/user/components/ConsentsForm";
import { useAuth } from "@profilog/misc/auth/useAuth";

export default function RegistrationWithInvitationCard({ invitation }) {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        errors,
        formState: { isSubmitting },
    } = useForm();
    const navigate = useNavigate();
    const { currentUserData, loginFromApiResponse } = useAuth();

    const { apiPost, ApiErrors: ApiRegisterError } = useFetch();
    const [isApiRegisterError, setIsApiRegisterError] = useState(false);

    if (currentUserData) {
        return <Navigate to="../in/records" replace />;
    }

    async function registerUserWithInvitation(data) {
        let consents = {};
        consents["PersonalDataProcessing"] = data.PersonalDataProcessing;

        const input = {
            email: data["registerEmail"],
            password: data["registerPassword"],
            InvitationCode: invitation.code,
            consents: consents,
        };

        const response = await apiPost("/web/user/register-with-invitation", input);
        if (response.isOk) {
            if (response.json.isLoginWithoutEmailConfirmationAllowed) {
                loginFromApiResponse(response);
                navigate("../in/records", { replace: true });
            } else {
                navigate("../successfully-registered", { replace: true });
            }
            return null;
        } else {
            setIsApiRegisterError(true);
        }
    }

    const form = (
        <Fragment>
            <form onSubmit={handleSubmit(registerUserWithInvitation)}>
                <p tw="my-4!">{t("registration.YouWillUserEmailAddresToEnter")}</p>
                <div tw="w-full sm:w-1/2 mb-10">
                    <TextField
                        name="registerEmail"
                        label={t("registration.LoginEmail")}
                        defaultValue={invitation.recipientEmailAddress}
                        error={getErrorMessage(errors, "registerEmail") ? true : false}
                        helperText={getErrorMessage(errors, "registerEmail")}
                        inputRef={register({ required: t("auth.EmailRequired") })}
                        fullWidth
                    />
                </div>
                <p>{t("registration.MakeUpYourPassword")}</p>
                <div tw="w-full sm:w-1/2">
                    <PasswordInput
                        name="registerPassword"
                        label={t("registration.Password")}
                        error={getErrorMessage(errors, "registerPassword") ? true : false}
                        helperText={getErrorMessage(errors, "registerPassword")}
                        inputRef={register({
                            required: t("auth.PasswordRequired"),
                            minLength: {
                                value: 3,
                                message: t("auth.PasswordMinLength").replace("{0}", 3),
                            },
                        })}
                        autoComplete="new-password"
                        fullWidth
                    />
                </div>
                <p dangerouslySetInnerHTML={{ __html: t("registration.GdprConsentText") }}></p>
                <div tw="pl-2 pr-2">
                    <ConsentsForm
                        systemNames={["PersonalDataProcessing"]}
                        errors={errors}
                        register={register}
                        tw="pl-2 pr-2"
                    />
                </div>
                <div tw="flex justify-center">
                    <Button type="submit" variant="contained" disabled={isSubmitting} tw="mt-4">
                        {t("auth.Register.Register")}
                    </Button>
                </div>
            </form>
        </Fragment>
    );

    return (
        <Fragment>
            {isApiRegisterError && <ApiRegisterError />}
            {!isApiRegisterError && form}
        </Fragment>
    );
}
