import { TextField } from "@mui/material";

export default function TextParameter({ parameter, onValueChanged }) {
    return (
        <TextField
            size="small"
            multiline
            rows="2"
            fullWidth
            onChange={(e) => onValueChanged(parameter.id, e.target.value)}
        />
    );
}
