import AppSettings from "@profilog/front/app/AppSettings";
import { generateId } from "@profilog/utils/generateId";
import { prescriptionTypes } from "../codebooks/prescriptionTypes";
import { getDayTimesOptions } from "../codebooks/dayTimes";
import { scheduleTypes } from "../codebooks/scheduleTypes";
import { formatAmount, parseAmount } from "../utils/amount";
import {
    _prescriptionCreate,
    _prescriptionUpdate,
    _prescriptionScheduleCreate,
    _prescriptionScheduleUpdate,
} from "./prescriptionSlice";
import i18n from "i18next";

// Denormalizace PŘED odesláním na server.
export function denormalizePrescription(prescriptionId, state) {
    let prescription = state.prescriptions.prescriptions.byId[prescriptionId];
    let result = {
        ...prescription,
        profilogUserId: AppSettings.profilogUserId,
        ...remapPrescription(),
        schedules: prescription.schedules.map((scheduleId) => denormalizeSchedule(scheduleId, state)),
    };

    return result;

    function remapPrescription() {
        switch (prescription.$type) {
            case prescriptionTypes.drug:
                return { ...mapOptionToObjectOrText("drug", prescription.drug) };
            case prescriptionTypes.action:
                return { ...mapOptionToObjectOrText("action", prescription.action) };
            case prescriptionTypes.measurement:
                return { parameters: prescription.parameters.map((p) => ({ id: p.value })) };
            case prescriptionTypes.survey:
                return { parameterGroup: { id: prescription.parameterGroup.value } };
            default:
                throw new Error(`Invalid prescription type ${prescription.$type}`);
        }
    }

    function denormalizeSchedule(scheduleId, state) {
        let schedule = state.prescriptions.schedules.byId[scheduleId];
        let result = {
            ...schedule,
            amount: parseAmount(schedule.amount),
            amountMax: parseAmount(schedule.amountMax),
            ...remapSchedule(),
        };
        return result;

        function remapSchedule() {
            switch (schedule.$type) {
                case scheduleTypes.time:
                    return {
                        time: schedule.time !== null && schedule.time.__isNew__ ? schedule.time.label : null,
                        dayTime: schedule.time != null && !schedule.time.__isNew__ ? schedule.time.value : null,
                    };
                case scheduleTypes.situation:
                    return { ...mapOptionToObjectOrText("situation", schedule.situation) };
                case scheduleTypes.paramValue:
                    return {
                        parameter: { id: schedule.parameter.value, name: schedule.parameter.label },
                        valueFrom: parseAmount(schedule.valueFrom),
                        valueTo: parseAmount(schedule.valueTo),
                    };
                default:
                    throw new Error(`Invalid schedule type ${schedule.$type}`);
            }
        }
    }
}

// Normalizace PO přijmutí ze serveru před vložením do redux store.
export function normalizePrescription(prescription, state) {
    console.log("normalizing");
    const normalizedPrescription = {
        ...prescription,
        schedules: [],
        ...remapPrescription(prescription),
    };

    _prescriptionCreate(state, prescription.$type, { id: prescription.id });
    _prescriptionUpdate(state, prescription.id, normalizedPrescription);

    prescription.schedules.forEach((schedule) => {
        const normalizedSchedule = normalizeSchedule(normalizedPrescription.id, schedule);
        _prescriptionScheduleCreate(state, normalizedPrescription.id, schedule.$type, { id: schedule.id });
        _prescriptionScheduleUpdate(state, schedule.id, normalizedSchedule);
    });

    function remapPrescription() {
        switch (prescription.$type) {
            case prescriptionTypes.drug:
                return { drug: mapToOption(prescription.drug, prescription.text) };
            case prescriptionTypes.action:
                return { action: mapToOption(prescription.action, prescription.text) };
            case prescriptionTypes.measurement:
                return { parameters: prescription.parameters.map((p) => ({ value: p.id, label: p.name })) };
            case prescriptionTypes.survey:
                return { parameterGroup: mapToOption(prescription.parameterGroup) };
            default:
                throw new Error(`Invalid prescription type ${prescription.$type}`);
        }
    }
}

function normalizeSchedule(prescriptionId, schedule) {
    return {
        ...schedule,
        amount: formatAmount(schedule.amount),
        amountMax: formatAmount(schedule.amountMax),
        prescriptionId,
        ...remapSchedule(),
    };

    function remapSchedule() {
        switch (schedule.$type) {
            case scheduleTypes.time:
                if (schedule.dayTime) {
                    let option = getDayTimesOptions(i18n.language)[schedule.dayTime];
                    if (option) return { time: option };
                }

                return { time: { label: schedule.time, value: schedule.time, __isNew__: true } };
            case scheduleTypes.situation:
                return { situation: mapToOption(schedule.situation, schedule.text) };
            case scheduleTypes.paramValue:
                return {
                    parameter: mapToOption(schedule.parameter),
                    valueFrom: formatAmount(schedule.valueFrom),
                    valueTo: formatAmount(schedule.valueTo),
                };
            default:
                throw new Error(`Invalid schedule type ${schedule.$type}`);
        }
    }
}

function mapOptionToObjectOrText(propName, option) {
    return option.__isNew__
        ? { [propName]: null, text: option.label }
        : { [propName]: { id: option.value, name: option.label }, text: null };
}

function mapToOption(object, text = null) {
    return object === null
        ? { __isNew__: true, value: generateId(), label: text }
        : { value: object.id, label: object.name };
}
