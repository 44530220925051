import { useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { apiGet } from "@profilog/misc/utils/useFetch";
import RecordsReviewComponent from "../components/RecordsReviewComponent";
import { useMedicalSubjectFetch } from "@profilog/misc/records/fetch/useMedicalSubjectFetch";
import { Alert } from "@mui/material";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const RecordsReviewPreview = () => {
    let query = useQuery();
    const { t } = useTranslation();
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [medicalSubjectId] = useState(query.get("medicalSubjectId"));
    const recordsVisualizationFetchService = useMedicalSubjectFetch(medicalSubjectId);
    const [reviewData, setReviewData] = useState({});

    useEffect(() => {
        async function getReviewData() {
            let response = await apiGet("/sharing/records-review-preview/" + medicalSubjectId);
            if (response.isOk) {
                setReviewData(response.json);
            } else {
                setIsError(true);
            }
            setIsLoading(false);
        }
        getReviewData();
        // eslint-disable-next-line
    }, [medicalSubjectId]);

    if (isLoading) return null;

    return (
        <Fragment>
            {isError ? (
                <div className="container mt-2 mt-md-5">
                    <div className="row">
                        <div className="col-12">
                            <Alert severity="error">{t("sharing.RecordsReview.AccessRevoked")}</Alert>
                        </div>
                    </div>
                </div>
            ) : (
                <RecordsReviewComponent
                    reviewData={reviewData}
                    recordsVisualizationFetchService={recordsVisualizationFetchService}
                    linkGenerated={new Date()}
                    recipientEmailAddress={query.get("recipientEmailAddress")}
                    isUserContactIncluded={query.get("isUserContactIncluded") === "true"}
                    isGraphVisible={true}
                />
            )}
        </Fragment>
    );
};

export default RecordsReviewPreview;
