import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { Alert } from "@mui/material";

const EmailInvitationSent = ({ emailInvitationResult, onNewShareRequested }) => {
    const { t } = useTranslation();

    const AnotherSendButton = (
        <button type="button" className="btn btn-primary" onClick={onNewShareRequested}>
            {t("sharing.EmailInvitationSent.SendAnotherRecord")}
        </button>
    );

    if (!emailInvitationResult.wasLinkSuccesfullyGenerated) {
        return (
            <Fragment>
                <Alert severity="error">{t("sharing.EmailInvitationSent.LinkWasNotGenerated")}</Alert>
                {AnotherSendButton}
            </Fragment>
        );
    }

    if (!emailInvitationResult.wasInvitationSuccessfullySent) {
        return (
            <Fragment>
                <Alert severity="error">{t("sharing.EmailInvitationSent.InvitationWasNotSent")}</Alert>
                {AnotherSendButton}
            </Fragment>
        );
    }

    return (
        <Fragment>
            <p
                dangerouslySetInnerHTML={{
                    __html: t("sharing.EmailInvitationSent.Sent").replace(
                        "{0}",
                        emailInvitationResult.recipientEmailAddress,
                    ),
                }}
            ></p>
            <p>{t("sharing.EmailInvitationSent.Description")}</p>
            <p>
                {emailInvitationResult.isContactIncluded ? (
                    <span
                        dangerouslySetInnerHTML={{ __html: t("sharing.EmailInvitationSent.SentIncludingContact") }}
                    ></span>
                ) : (
                    <span
                        dangerouslySetInnerHTML={{ __html: t("sharing.EmailInvitationSent.SentNotIncludingContact") }}
                    ></span>
                )}
                <span
                    dangerouslySetInnerHTML={{
                        __html: t("sharing.EmailInvitationSent.SeePreview").replace(
                            "{0}",
                            `target="_blank" href="${process.env.PUBLIC_URL}/records-review/${emailInvitationResult.linkIdentificator}"`,
                        ),
                    }}
                ></span>
            </p>
            <p
                dangerouslySetInnerHTML={{
                    __html: t("sharing.EmailInvitationSent.RevokeAccess").replace(
                        "{0}",
                        `target="_blank" href="/sharing"`,
                    ),
                }}
            ></p>
            {AnotherSendButton}
        </Fragment>
    );
};

export default EmailInvitationSent;
