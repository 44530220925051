import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import ValidationError from "../../errors/ValidationError";
import PasswordInput from "../../auth/components/PasswordInput";
import ApiErrorsList from "../../errors/ApiErrorsList";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";

export default function CaloricsTablesConnectionDialog({ connect, connectErrors, open, onClose, ApiErrors }) {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        errors,
        formState: { isSubmitting },
    } = useForm();

    return (
        <Dialog open={open} onClose={onClose} fullWidth="xs">
            <DialogCloseButton onClick={onClose} />
            <DialogTitle>{t("extConnectors.CaloricsTables.Title")}</DialogTitle>
            <form onSubmit={handleSubmit(submitConnectData)}>
                <DialogContent>
                    {connectErrors && <ApiErrorsList apiErrors={connectErrors} />}
                    <ApiErrors />
                    <div tw="mb-2">
                        <TextField
                            sx={{ width: "100%" }}
                            size="small"
                            name="email"
                            label={t("global.Form.Email")}
                            inputRef={register({ required: t("auth.EmailRequired") })}
                            inputProps={{ autoComplete: "off" }}
                        />
                        <ValidationError errors={errors} name="email" />
                    </div>
                    <div tw="mb-2">
                        <PasswordInput
                            sx={{ width: "100%" }}
                            size="small"
                            name="password"
                            label={t("global.Form.Password")}
                            inputRef={register({ required: t("auth.PasswordRequired") })}
                            inputProps={{ autoComplete: "new-password" }}
                        />
                        <ValidationError errors={errors} name="password" />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={isSubmitting}>
                        {t("extConnectors.Connect")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );

    async function submitConnectData(data) {
        const input = {
            email: data["email"],
            password: data["password"],
        };
        connect(input);
    }
}
