import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { prescriptionScheduleUpdate } from "../../../redux/prescriptionSlice";
import { verifyAmount } from "../../../utils/amount";
import "twin.macro";
import { useTranslation } from "react-i18next";

export default function Amount({ scheduleId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const amount = useSelector((state) => state.prescriptions.schedules.byId[scheduleId].amount);

    return (
        <div>
            <TextField
                size="small"
                tw="w-24"
                label={t("prescriptions.Edit.Amount")}
                value={amount ?? ""}
                onChange={dispatchUpdate}
                onBlur={dispatchUpdate}
            />
        </div>
    );

    function dispatchUpdate(e) {
        dispatchScheduleUpdate({ amount: verifyAmount(e.target.value, e.type === "change") });
    }

    function dispatchScheduleUpdate(updated) {
        dispatch(prescriptionScheduleUpdate({ scheduleId, updated }));
    }
}
