import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { getErrorMessage, hasError } from "@profilog/misc/errors/ValidationError";
import useCountryCallingCodes from "@profilog/commons/hooks/useCountryCallingCodes";
import useUserInitializationPersonalInfo from "../hooks/useUserInitializationPersonalInfo";
import { Fragment } from "react";
import { Button, TextField } from "@mui/material";
import Stack from "@profilog/commons/components/Stack";
import ResponsiveStack from "@profilog/commons/components/ResponsiveStack";
import GenderSelector from "@profilog/commons/components/GenderSelector";
import PhoneNumberField from "@profilog/commons/components/PhoneNumberField";

export default function PersonalInfoInitialization({ projectSettings, next, existsNextStep }) {
    const { t } = useTranslation();
    const {
        register,
        control,
        handleSubmit,
        errors,
        formState: { isSubmitting },
    } = useForm();

    const { callingCodes, isLoading: isCallingCodesLoading } = useCountryCallingCodes();
    const {
        personalInfo,
        savePersonalInfo,
        isLoading: isPersonalInfoLoading,
        ApiErrors: PersonalInfoApiError,
    } = useUserInitializationPersonalInfo();

    async function submitPersonalInfo(data) {
        let paramValues = [];
        if (data.height)
            paramValues = [
                ...paramValues,
                {
                    parameterSystemName: "HeightInCentimetres",
                    value: data.height,
                },
                {
                    parameterSystemName: "WeightInKilograms",
                    value: data.weight,
                },
            ];
        const personalInfo = {
            firstName: data.firstName ? data.firstName : null,
            lastName: data.lastName ? data.lastName : null,
            gender: data.gender && data.gender !== "undefined" ? parseInt(data.gender) : null,
            birthdate: data.birthdate ? data.birthdate : null,
            zipCode: data.zipCode ? data.zipCode : null,
            phoneNumberCountryId:
                data.phoneNumber && data.phoneNumber.length > 0 ? data["phoneNumber-callingCode"] : null,
            phoneNumber: data.phoneNumber,
            initialParameterValues: paramValues,
        };

        const result = await savePersonalInfo(personalInfo);

        if (result === false) return;

        next();
    }

    if (isCallingCodesLoading === true) return null;
    if (isPersonalInfoLoading === true) return null;

    return (
        <Fragment>
            <p>{t("userInitialization.PersonalInfoInitialization.Description")}</p>
            <div tw="mt-5 lg:w-7/12">
                <PersonalInfoApiError />
                <form onSubmit={handleSubmit(submitPersonalInfo)} autoComplete="off">
                    <Stack vertical>
                        {projectSettings.isNameOptional && (
                            <ResponsiveStack>
                                <TextField
                                    error={hasError(errors, "firstName")}
                                    helperText={getErrorMessage(errors, "firstName")}
                                    tw="flex-1"
                                    name="firstName"
                                    label={t("userInitialization.PersonalInfoInitialization.FirstName") + " *"}
                                    defaultValue={personalInfo.firstName}
                                    inputRef={
                                        projectSettings.isNameRequired
                                            ? register({
                                                  required: t("global.Form.RequiredField"),
                                              })
                                            : register
                                    }
                                />
                                <TextField
                                    error={hasError(errors, "lastName")}
                                    helperText={getErrorMessage(errors, "lastName")}
                                    tw="flex-1"
                                    name="lastName"
                                    label={t("userInitialization.PersonalInfoInitialization.LastName") + " *"}
                                    defaultValue={personalInfo.lastName}
                                    inputRef={
                                        projectSettings.isNameRequired
                                            ? register({
                                                  required: t("global.Form.RequiredField"),
                                              })
                                            : register
                                    }
                                />
                            </ResponsiveStack>
                        )}

                        <ResponsiveStack tw="mt-5">
                            {projectSettings.isGenderOptional && (
                                <GenderSelector
                                    control={control}
                                    name="gender"
                                    errors={errors}
                                    tw="flex-1"
                                    noUndefined
                                />
                            )}

                            {projectSettings.isBirthdateOptional && (
                                <TextField
                                    type="date"
                                    name="birthdate"
                                    defaultValue={personalInfo.birthdate?.slice(0, 10)}
                                    error={hasError(errors, "birthdate")}
                                    helperText={getErrorMessage(errors, "birthdate")}
                                    label={t("userInitialization.PersonalInfoInitialization.Birthdate") + " *"}
                                    tw="flex-1"
                                    InputLabelProps={{ shrink: true }}
                                    inputRef={
                                        projectSettings.isBirthdateRequired
                                            ? register({
                                                  required: t("global.Form.RequiredField"),
                                              })
                                            : register
                                    }
                                />
                            )}
                        </ResponsiveStack>

                        <ResponsiveStack tw="mt-5">
                            {projectSettings.isZipCodeOptional && (
                                <TextField
                                    name="zipCode"
                                    defaultValue={personalInfo.zipCode}
                                    error={hasError(errors, "zipCode")}
                                    helperText={getErrorMessage(errors, "zipCode")}
                                    label={t("userInitialization.PersonalInfoInitialization.ZipCode") + " *"}
                                    tw="flex-1"
                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                    inputRef={
                                        projectSettings.isZipCodeRequired
                                            ? register({
                                                  required: t("global.Form.RequiredField"),
                                              })
                                            : register
                                    }
                                />
                            )}

                            {projectSettings.isPhoneOptional && (
                                <PhoneNumberField
                                    name="phoneNumber"
                                    label={t("userInitialization.PersonalInfoInitialization.PhoneNumber") + " *"}
                                    control={control}
                                    errors={errors}
                                    tw="flex-1"
                                    callingCodes={callingCodes}
                                    defaultCallingCode={personalInfo.phoneNumberCountryId}
                                    defaultValue={personalInfo.phoneNumber}
                                    register={
                                        projectSettings.isPhoneRequired
                                            ? register({
                                                  required: t("global.Form.RequiredField"),
                                              })
                                            : register
                                    }
                                />
                            )}
                        </ResponsiveStack>

                        <ResponsiveStack tw="mt-5">
                            {projectSettings.isInitialHeightRequired && (
                                <TextField
                                    type="number"
                                    name="height"
                                    defaultValue={
                                        personalInfo.initialParameterValues?.find(
                                            (v) => v.parameterSystemName === "HeightInCentimetres",
                                        )?.value
                                    }
                                    error={hasError(errors, "height")}
                                    helperText={getErrorMessage(errors, "height")}
                                    label={t("userInitialization.PersonalInfoInitialization.Height") + "*"}
                                    tw="flex-1"
                                    inputProps={{ inputMode: "numeric", min: 30, max: 250, pattern: "[0-9]" }}
                                    inputRef={register({
                                        required: t("global.Form.RequiredField"),
                                    })}
                                />
                            )}

                            {projectSettings.isInitialWeightRequired && (
                                <TextField
                                    type="number"
                                    name="weight"
                                    defaultValue={
                                        personalInfo.initialParameterValues?.find(
                                            (v) => v.parameterSystemName === "WeightInKilograms",
                                        )?.value
                                    }
                                    error={hasError(errors, "weight")}
                                    helperText={getErrorMessage(errors, "weight")}
                                    label={t("userInitialization.PersonalInfoInitialization.Weight") + "*"}
                                    tw="flex-1"
                                    inputProps={{ inputMode: "numeric", min: 2, max: 650, pattern: "[0-9]" }}
                                    inputRef={register({
                                        required: t("global.Form.RequiredField"),
                                    })}
                                />
                            )}
                        </ResponsiveStack>
                    </Stack>
                    <ResponsiveStack reverse tw="mt-5">
                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                            {existsNextStep
                                ? t("userInitialization.UserInitialization.Next")
                                : t("userInitialization.UserInitialization.Done")}
                        </Button>
                    </ResponsiveStack>
                </form>
            </div>
        </Fragment>
    );
}
