import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { getQuery } from "@profilog/misc/utils/Url";
import { useFetch } from "@profilog/misc/utils/useFetch";

export default function useExternalConnectionSteps(next, projectSettings) {
    const { t } = useTranslation();

    const EXTERNAL_CONNECTION_STEPS = {
        ACTIVITY: "activity",
        GLYCEMIA: "glycemia",
        EKG: "ekg",
        KT: "kt",
    };

    const stepSettings = [
        {
            name: EXTERNAL_CONNECTION_STEPS.ACTIVITY,
            title: t("userInitialization.ExternalConnectionsInitialization.ActivityDataSourceQuestion"),
            isGarminButtonVisible: true,
            isGoogleButtonVisible: true,
            isRemoteGoogleFitButtonVisible: true,
            isOneTouchButtonVisible: true,
            isWithingsButtonVisible: true,
            isHuaweiButtonVisible: true,
            isFitbitButtonVisible: true,
            isStravaButtonVisible: false,
            isAndroidButtonVisible: false,
            isAppleButtonVisible: true,
            isDiabeticButtonVisible: false,
            nextStep: EXTERNAL_CONNECTION_STEPS.GLYCEMIA,
        },
        {
            name: EXTERNAL_CONNECTION_STEPS.GLYCEMIA,
            title: t("userInitialization.ExternalConnectionsInitialization.GlycemiaDataSourceQuestion"),
            isGarminButtonVisible: true,
            isGoogleButtonVisible: false,
            isRemoteGoogleFitButtonVisible: true,
            isOneTouchButtonVisible: true,
            isWithingsButtonVisible: false,
            isHuaweiButtonVisible: false,
            isFitbitButtonVisible: false,
            isStravaButtonVisible: false,
            isAndroidButtonVisible: false,
            isAppleButtonVisible: false,
            isDiabeticButtonVisible: true,
            nextStep: EXTERNAL_CONNECTION_STEPS.EKG,
        },
        {
            name: EXTERNAL_CONNECTION_STEPS.EKG,
            title: t("userInitialization.ExternalConnectionsInitialization.EkgDataSourceQuestion"),
            isGarminButtonVisible: true,
            isGoogleButtonVisible: false,
            isRemoteGoogleFitButtonVisible: false,
            isOneTouchButtonVisible: false,
            isWithingsButtonVisible: true,
            isHuaweiButtonVisible: false,
            isFitbitButtonVisible: false,
            isStravaButtonVisible: false,
            isAndroidButtonVisible: false,
            isAppleButtonVisible: true,
            isDiabeticButtonVisible: false,
            nextStep: EXTERNAL_CONNECTION_STEPS.KT,
        },
        {
            name: EXTERNAL_CONNECTION_STEPS.KT,
            title: t("userInitialization.ExternalConnectionsInitialization.KtAccountQuestion"),
            nextStep: null,
        },
    ];
    const { apiGet, apiPut } = useFetch();
    const [isLoadingStep, setIsLoadingStep] = useState(false);
    const [currentExternalConnectionStep, setCurrentExternalConnectionStep] = useState(null);

    const isAfterSuccessfulConnection = getQuery().get("connectionSuccessStep") === currentExternalConnectionStep?.name;

    useEffect(() => {
        async function getCurrentExternalConnectionStep() {
            setIsLoadingStep(true);
            const response = await apiGet("/web/user/initialization/external-connection-step");
            if (response.isOk) {
                setCurrentExternalConnectionStep(
                    stepSettings.find(
                        (s) =>
                            s.name ===
                            (response.json.currentStepName ?? projectSettings.requiredExternalConnectionSteps[0]),
                    ),
                );
            }
            setIsLoadingStep(false);
        }
        getCurrentExternalConnectionStep();
    }, []);

    useEffect(() => {
        if (isAfterSuccessfulConnection) {
            nextExternalConnectionStep();
        }
    }, [isLoadingStep]);

    async function saveExternalConnectionStep(step) {
        if (step) {
            const dto = { currentExternalConnectionStep: step.name };
            await apiPut("/web/user/initialization/external-connection-step", dto);
        }
    }

    async function nextExternalConnectionStep() {
        let testingNextStep = currentExternalConnectionStep;
        do {
            testingNextStep = stepSettings.find((s) => s.name === testingNextStep.nextStep);
        } while (testingNextStep && !projectSettings.requiredExternalConnectionSteps.includes(testingNextStep.name));

        if (testingNextStep) {
            setCurrentExternalConnectionStep(testingNextStep);
            await saveExternalConnectionStep(testingNextStep);
        } else {
            next();
        }
    }

    return { currentExternalConnectionStep, nextExternalConnectionStep, isAfterSuccessfulConnection };
}
