import { Fragment, useEffect, useState } from "react";
import FoodListView from "./FoodListView";
import { CircularProgress } from "@mui/material";

export default function FoodList({ fetchService, selectedDateTime, groupedBy }) {
    const [foodList, setFoodList] = useState();
    const { getFoodListAsync, GetApiErrors } = fetchService;
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const getFoodList = async () => {
            if (!selectedDateTime || selectedDateTime == null) return;
            setIsLoaded(false);
            let result = await getFoodListAsync(selectedDateTime, groupedBy);
            if (result.isOk) setFoodList(result.json);
            setIsLoaded(true);
        };

        setFoodList();
        getFoodList();
    }, [selectedDateTime, groupedBy]);

    if (!isLoaded) return <CircularProgress />;

    return (
        <Fragment>
            <GetApiErrors />
            {foodList && <FoodListView foodList={foodList} className="open" />}
        </Fragment>
    );
}
