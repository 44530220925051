import { useTranslation } from "react-i18next";
import ExtConnectorInfoText from "@profilog/misc/user/components/ExtConnectorInfoText";
import AppStoreLink from "../appStore/AppStoreLink";
import useIPhone from "@profilog/misc/user/hooks//useIPhone";
import { format } from "date-fns";
import { Chip, Tooltip } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";

export default function AppleHealthConnection({ appUrl }) {
    const { t } = useTranslation();
    const { lastImportDate } = useIPhone();

    return (
        <div>
            <h3 tw="mb-2">{t("extConnectors.AppleHealth.Title")}</h3>
            {lastImportDate && (
                <Tooltip title={t("extConnectors.LastImportDateText")} tw="mb-4">
                    <Chip
                        size="small"
                        color="secondary"
                        icon={<SyncIcon />}
                        label={format(new Date(lastImportDate), " d. M. yyyy HH:mm:ss")}
                    ></Chip>
                </Tooltip>
            )}
            <div tw="mb-2">{t("extConnectors.AppleHealth.InstallApp")}</div>
            <div tw="mb-2">
                <AppStoreLink appUrl={appUrl} />
            </div>
            <div tw="mb-2">{t("extConnectors.AppleHealth.Permissions")}</div>
            <ExtConnectorInfoText id="apple-health-info-text" text={t("extConnectors.AppleHealth.InfoText")} />
        </div>
    );
}
