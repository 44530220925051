import { prescriptionScheduleUpdate } from "../../../redux/prescriptionSlice";
import { useDispatch } from "react-redux";
import EditPanel from "../../commons/EditPanel";
import AmountMax from "./AmountMax";
import AmountNote from "./AmountNote";
import Repeat from "./Repeat";
import WeekDays from "./WeekDays";
import Amount from "./Amount";
import { useSelector } from "react-redux";
import { scheduleTypes } from "../../../codebooks/scheduleTypes";
import { Fragment } from "react";
// import Tolerance from "./Tolerance";

export default function ScheduleParams({ scheduleId = null, hasIntervals = false }) {
    const dispatch = useDispatch();
    const isTimeSchedule = useSelector(
        (state) => state.prescriptions.schedules.byId[scheduleId].$type === scheduleTypes.time,
    );
    const supportsAmount = useSelector((state) => state.prescriptions.schedules.byId[scheduleId].supportsAmount);

    return (
        <Fragment>
            {supportsAmount && (
                <Fragment>
                    <Amount scheduleId={scheduleId} />
                    <EditPanel>
                        {(editing, open) => <AmountMax scheduleId={scheduleId} editing={editing} open={open} />}
                    </EditPanel>
                    <EditPanel>
                        {(editing, open) => <AmountNote scheduleId={scheduleId} editing={editing} open={open} />}
                    </EditPanel>
                </Fragment>
            )}
            {isTimeSchedule && (
                <Fragment>
                    <EditPanel>
                        {(editing, open, wasOpen) => (
                            <Repeat
                                saved={scheduleId > 0}
                                scheduleId={scheduleId}
                                editing={editing}
                                open={open}
                                wasOpen={wasOpen}
                                intervalSelector={(state) => state.prescriptions.schedules.byId[scheduleId].interval}
                                intervalUnitSelector={(state) =>
                                    state.prescriptions.schedules.byId[scheduleId].intervalUnit
                                }
                                dispatchUpdate={dispatchScheduleUpdate}
                            />
                        )}
                    </EditPanel>
                    <EditPanel>
                        {(editing, _, wasOpen) => (
                            <WeekDays
                                saved={scheduleId > 0}
                                editing={editing}
                                wasOpen={wasOpen}
                                dispatchUpdate={dispatchScheduleUpdate}
                                selector={(state) => state.prescriptions.schedules.byId[scheduleId].weekDays}
                            />
                        )}
                    </EditPanel>

                    {/* <EditPanel>
                        {(editing, open) => <Tolerance scheduleId={scheduleId} editing={editing} open={open} />}
                    </EditPanel> */}
                </Fragment>
            )}
        </Fragment>
    );

    function dispatchScheduleUpdate(updated) {
        dispatch(prescriptionScheduleUpdate({ scheduleId, updated }));
    }
}
