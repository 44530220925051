import isYesterday from "date-fns/isYesterday";
import isTomorrow from "date-fns/isTomorrow";
import isToday from "date-fns/isToday";
import { addDays, addHours, addMilliseconds, differenceInMinutes, getMinutes, setMinutes } from "date-fns";
import format from "date-fns/format";
import { generateId } from "@profilog/utils/generateId";
import { prescriptionTypes } from "../../codebooks/prescriptionTypes";
import getHours from "date-fns/getHours";

export function groupAndPrepareDrugItems(items, now, shortList, t) {
    const { itemsWithTime, itemsWithoutTime } = splitAndPrepareItems(items, now);

    // Nejprve seskupíme položky s časem a najdeme skupinu "nyní".
    let { groupedItems, nowGroup } = groupItems(itemsWithTime, {}, now, t);

    // Poté zpracujeme položky bez času
    if (itemsWithoutTime.length > 0) {
        for (const item of itemsWithoutTime) {
            if (item.info.isToday) {
                // Pokud je položka na dnešek,přidáme ji do skupiny "nyní".
                nowGroup = ensureNowGroup(groupedItems, nowGroup, now);
                nowGroup.items.push(item);
            } else {
                // Jinak ji přidáme do samostatné skupiny pro jiný den.
                const otherDaysGroup = ensureOtherDaysGroup(groupedItems, item, now);
                otherDaysGroup.items.push(item);
            }
        }
    }

    // Pokud se zobrazuje zkrácený seznam, zjistíme, které skupiny mají zůstat
    let keptGroupKeys = shortList ? getKeptGroupKeys(groupedItems) : null;

    // Ještě jednou projdeme výstup
    for (let entry of Object.entries(groupedItems)) {
        let key = entry[0];
        let group = entry[1];

        // Pokud je skupina určena k odstranění (zobrazuje se shortList), odstraníme ji
        if (keptGroupKeys !== null && !keptGroupKeys.has(key)) delete groupedItems[key];
        // U ponechané skupiny seřadíme její položky
        else group.items.sort(sortItems);
    }

    nowGroup = ensureNowGroup(groupedItems, nowGroup, now);
    nowGroup.isNow = true;

    return groupedItems;
}

function splitAndPrepareItems(items, now) {
    const itemsWithTime = [];
    const itemsWithoutTime = [];

    for (let item of items) {
        if (item.prescriptionType !== prescriptionTypes.drug && item.prescriptionType !== prescriptionTypes.action)
            continue;

        prepareItem(item, now);

        if (item.hasTime) itemsWithTime.push(item);
        else itemsWithoutTime.push(item);
    }

    return { itemsWithTime, itemsWithoutTime };
}

function groupItems(items, defaultValue, now, t) {
    let nowGroup = null;
    const groupedItems = items.reduce((acc, item) => {
        const groupKey = formatGroupKey(item.info.scheduledAtRounded);

        let group = acc[groupKey];
        if (!group) {
            const time = getHours(item.info.scheduledAtRounded);

            acc[groupKey] = group = {
                time:
                    time === 0
                        ? t("prescriptions.DrugCalendar.midnight")
                        : t("prescriptions.DrugCalendar.xHours", { hours: time }),
                diffFromNow: Math.abs(differenceInMinutes(item.info.scheduledAtRounded, now)),
                dayTitle: getDayTitle(item.info, true),
                items: [],
            };

            if (nowGroup === null && item.info.isNow) {
                nowGroup = group;
                group.diffFromNow = 0;
            }
        }

        group.items.push(item);
        return acc;
    }, defaultValue);

    return { groupedItems, nowGroup };
}

function formatGroupKey(date) {
    return format(date, "yyyy-MM-dd HH");
}

function ensureNowGroup(groupedItems, nowGroup, now) {
    if (nowGroup == null) {
        const nowRounded = roundDateToNearestHour(now);
        groupedItems[formatGroupKey(nowRounded)] = nowGroup = {
            time: getHours(nowRounded) + " hod.",
            diffFromNow: 0,
            dayTitle: null,
            items: [],
        };
    }

    return nowGroup;
}

function ensureOtherDaysGroup(groupedItems, item, now) {
    // skupina pro položky bez času (mimo dnešek), bude jako poslední na daný den.
    const groupKey = format(item.info.scheduledAt, "yyyy-MM-dd") + "F";
    let otherDaysGroup = groupedItems[groupKey];

    if (!otherDaysGroup) {
        let endOfDay = addMilliseconds(addDays(item.info.scheduledAt, 1), -1);

        groupedItems[groupKey] = otherDaysGroup = {
            time: "",
            diffFromNow: Math.abs(differenceInMinutes(endOfDay, now)),
            dayTitle: getDayTitle(item.info, false),
            items: [],
        };
    }

    return otherDaysGroup;
}

function getKeptGroupKeys(groupedItems) {
    // Seřadíme skupiny dle nejbližšího času
    let sorted = Object.entries(groupedItems).sort(sortGroups);
    if (sorted.length === 0) return null;

    // První skupinu použijeme jako nejbližší.
    const closestGroupKey = sorted[0][0];

    let keptGroupKeys = new Set();
    keptGroupKeys.add(closestGroupKey);

    const sortedKeys = Object.keys(groupedItems).sort();

    // A zkusíme vzít první skupinu před a první skupinu za nejbližší skupinou.
    const indexOfClosest = sortedKeys.indexOf(closestGroupKey);
    if (indexOfClosest > 1) keptGroupKeys.add(sortedKeys[indexOfClosest - 1]);
    if (indexOfClosest < sortedKeys.length - 1) keptGroupKeys.add(sortedKeys[indexOfClosest + 1]);

    return keptGroupKeys;
}

function prepareItem(item, now) {
    Object.assign(item, {
        id: generateId(),
        info: createCalcInfo(item, now),
    });
}

function sortItems(i1, i2) {
    if (i1.info.sortKey < i2.info.sortKey) return -1;
    if (i1.info.sortKey > i2.info.sortKey) return 1;
    return 0;
}

function sortGroups(entry1, entry2) {
    if (entry1[1].diffFromNow < entry2[1].diffFromNow) return -1;
    if (entry1[1].diffFromNow > entry2[1].diffFromNow) return 1;

    if (entry1[0] < entry2[0]) return -1;
    if (entry1[0] > entry2[0]) return 1;

    return 0;
}

function createCalcInfo(item, now) {
    let scheduledAt = new Date(item.scheduledAt);

    const scheduledAtRounded = roundDateToNearestHour(scheduledAt);

    const calcInfo = {
        sortKey: "",
        scheduledAt,
        scheduledAtFmt: format(scheduledAt, "HH:mm"),
        scheduledAtRounded,
        canBeConfirmed: false,
        isNow: false,
        isFuture: false,
        isToday: isToday(scheduledAt),
        isYesterday: isYesterday(scheduledAt),
        isTomorrow: isTomorrow(scheduledAt),
        isPast: false,
        exactTime: null,
    };

    calcInfo.exactTime =
        getDayTitle(calcInfo, false) + (item.hasTime ? " v " + calcInfo.scheduledAtFmt : " (bez času)");

    calculateNowPastFuture(item, calcInfo, now);
    calculateConfirmation(item, calcInfo);

    createSortKey(item, calcInfo);

    return calcInfo;
}

const NOW_WITHIN_MINS = 60;

function calculateNowPastFuture(item, calcInfo, now) {
    calcInfo.isPast = calcInfo.isYesterday;
    calcInfo.isFuture = calcInfo.isTomorrow;

    if (!item.hasTime) return;

    let diffMinutes = differenceInMinutes(calcInfo.scheduledAt, now);
    if (diffMinutes < 0) calcInfo.isPast = true;
    if (diffMinutes > NOW_WITHIN_MINS) calcInfo.isFuture = true;

    let diffMinutesAbs = Math.abs(diffMinutes);

    if (diffMinutesAbs <= NOW_WITHIN_MINS) calcInfo.isNow = true;
}

function calculateConfirmation(item, calcInfo) {
    calcInfo.canBeConfirmed =
        calcInfo.isNow || item.situation || (!item.hasTime && !calcInfo.isFuture) || (item.hasTime && calcInfo.isPast);
}

function roundDateToNearestHour(date) {
    const minutes = getMinutes(date);
    date = setMinutes(date, 0);
    if (minutes < 30) return date;
    else return addHours(date, 1);
}

function getDayTitle(calcInfo, emptyIfToday) {
    if (calcInfo.isYesterday) return "včera";
    if (calcInfo.isTomorrow) return "zítra";
    return emptyIfToday ? "" : "dnes";
}

function createSortKey(item, calcInfo) {
    calcInfo.sortKey = item.scheduledAt.substring(0, 10) + "|";
    calcInfo.sortKey += (item.confirmedAt !== null ? 0 : 1) + "|";
    calcInfo.sortKey += (item.situation === null ? 0 : 1) + "|";
    calcInfo.sortKey += (calcInfo.canBeConfirmed ? 0 : 1) + "|";
    calcInfo.sortKey += (item.hasTime ? 0 : 1) + "|";
    calcInfo.sortKey += item.scheduledAt.substring(11) + "|";
}
