import { useFetch } from "../utils/useFetch";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { getQuery } from "../utils/Url";
import { setFetchLanguage } from "../utils/FetchOptions";
import { useAuth } from "@profilog/misc/auth/useAuth";

let langs = null;

export function initI18n({
    supportedLangs,
    resources,
    projectLang = null,
    forcedLang = null,
    defaultLang = "en",
    fallbackLang = "en",
    langQueryParamName = "lang",
}) {
    langs = supportedLangs;

    let language = null;

    let langParam = getQuery().get(langQueryParamName);
    if (langParam) language = tryStoreLanguage(langParam);

    if (!language) language = forcedLang;

    if (!language) language = getStoredLanguage();

    if (!language) language = projectLang;

    if (!language) {
        let browserLanguage =
            navigator.languages && navigator.languages.length > 0 ? navigator.languages[0] : navigator.language;

        if (browserLanguage) {
            browserLanguage = browserLanguage.substring(0, 2);

            if (browserLanguage) language = tryStoreLanguage(browserLanguage);
        }
    }

    if (!language) language = defaultLang;

    setDocumentLanguage(language);
    setFetchLanguage(language);
    i18n.use(initReactI18next).init({
        resources: resources,
        lng: language,
        fallbackLng: fallbackLang,
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });
}

function getStoredLanguage() {
    let language = localStorage.getItem("language");
    return langs.indexOf(language) != -1 ? language : null;
}

function tryStoreLanguage(language) {
    if (langs.indexOf(language) !== -1) {
        localStorage.setItem("language", language);
        return language;
    } else return null;
}

export function changeLanguage(language) {
    if (tryStoreLanguage(language)) {
        setFetchLanguage(language);
        setDocumentLanguage(language);
        i18n.changeLanguage(language);
    }
}

export function useChangeLanguage() {
    const { apiPost, ApiErrors } = useFetch();
    const { currentUserData } = useAuth();

    return {
        changeLanguage: apiChangeLanguage,
        ApiErrors: ApiErrors,
        LangApiErrors: LangApiErrors,
    };

    function LangApiErrors() {
        const { t } = useTranslation();
        return <ApiErrors modal title={t("global.ChangeLanguageError")} />;
    }

    async function apiChangeLanguage(language) {
        if (currentUserData) {
            const response = await apiPost("/web/user/culture", { culture: language });
            if (!response.isOk) return;
        }

        changeLanguage(language);
    }
}

function setDocumentLanguage(language) {
    document.documentElement.lang = language;
}
