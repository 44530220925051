import { Fragment, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import AppSettings from "../../app/AppSettings";
import CustomParameterDialog from "./CustomParameterDialog";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";

export default function ParameterListDialog({
    parameters,
    trigger,
    onAddParameter,
    onParameterCreated,
    medicalSubjectId,
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const nonHiddenParameters = parameters.filter((p) => !p.isHiddenInAdd);
    const grouppedParameters = groupParameters(nonHiddenParameters);

    return (
        <Fragment>
            {trigger(() => setOpen(true))}
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" fullScreen={fullScreen}>
                <DialogCloseButton onClick={handleClose} />
                <DialogTitle>{t("records.Parameters.Add.AddParameter")}</DialogTitle>
                <DialogContent>
                    <div tw="md:(flex flex-wrap justify-between)">
                        {Object.keys(grouppedParameters)
                            .sort()
                            .map((key) => {
                                const parsedKey = key.split("|");
                                const measureType = parsedKey[1];
                                const isOwn = parsedKey[2] === "own";
                                return (
                                    <div key={key} tw="flex-1">
                                        <div>
                                            <strong tw="text-red-600 font-semibold underline">
                                                {isOwn
                                                    ? t("records.Parameters.Add.Own")
                                                    : t("records.Parameters.Add." + measureType)}
                                            </strong>
                                        </div>
                                        {grouppedParameters[key].sort(paramsSort).map((parameter) => (
                                            <div
                                                key={parameter.id}
                                                tw="cursor-pointer"
                                                onClick={() => {
                                                    onAddParameter(parameter.id);
                                                    handleClose();
                                                }}
                                            >
                                                <div>{parameter.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                    </div>
                </DialogContent>
                <DialogActions>
                    {AppSettings.isDicatil && (
                        <CustomParameterDialog
                            trigger={(open) => <Button onClick={open}>{t("records.Parameters.Add.Custom")}</Button>}
                            onParameterCreated={(parameter) => {
                                onParameterCreated(parameter);
                                handleClose();
                            }}
                            medicalSubjectId={medicalSubjectId}
                        />
                    )}
                </DialogActions>
            </Dialog>
        </Fragment>
    );

    function handleClose() {
        setOpen(false);
    }
}

function groupParameters(parameters) {
    return parameters.reduce((acc, parameter) => {
        let order;
        if (parameter.isOwn) order = "99";
        else if (parameter.measureType === "Perception") order = "00";
        else if (parameter.measureType === "StateMeasure") order = "01";
        else order = "02";

        const key = order + "|" + parameter.measureType + "|" + (parameter.isOwn ? "own" : "sys");
        if (!acc[key]) acc[key] = [];

        acc[key].push(parameter);
        return acc;
    }, {});
}

function paramsSort(param1, param2) {
    return param1.name.localeCompare(param2.name);
}
