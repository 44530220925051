import { useEffect, useState } from "react";
import LazyTippy from "@profilog/misc/commons/components/LazyTippy";
import InlineEditButton from "./InlineEditButton";

export default function EditPanel({ closeOnClickOutside = true, children }) {
    const [open, setOpen] = useState(false);
    const [wasOpen, setWasOpen] = useState(false);

    useEffect(() => {
        function onKeyDown(event) {
            if (event.keyCode === 27) setOpen(false);
        }

        document.addEventListener("keydown", onKeyDown);
        return () => document.removeEventListener("keydown", onKeyDown);
    }, [open]);

    return (
        <LazyTippy
            visible={open}
            interactive
            theme="light"
            placement="bottom-start"
            maxWidth={480}
            onClickOutside={() => {
                if (closeOnClickOutside) setOpen(false);
            }}
            content={<div tw="mr-2 my-2 px-2">{children(true, open, wasOpen)}</div>}
        >
            <InlineEditButton
                onClick={() => {
                    setOpen(true);
                    setWasOpen(true);
                }}
            >
                {children(false, open, wasOpen)}
            </InlineEditButton>
        </LazyTippy>
    );
}
