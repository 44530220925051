import React, { Fragment } from "react";
import { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    CommonSeriesSettings,
    Legend,
    Tooltip,
    Label,
    Tick,
    Format,
    Size,
    Font,
    ZoomAndPan,
    VisualRange,
    WholeRange,
    Animation,
    CommonAxisSettings,
    Grid,
    TickInterval,
    Border,
    Strip,
    ConstantLine,
} from "devextreme-react/chart";
import DaysLink from "./DaysLink";
import { useTranslation } from "react-i18next";
import DatePicker from "../../commons/components/DatePicker";
import { format, intervalToDuration } from "date-fns";
import CaloricsTable from "../../caloricsTables/components/CaloricsTable";
import "../../caloricsTables/EnergyIntakeModal.scss";
import NewMessageDialog from "../../communications/components/old/NewMessageDialog";
import MessagesTable from "../../communications/components/old/MessagesTable";
import CaloricsTablesLogo from "../../assets/images/logo-kaloricke-tabulky.png";
import UpdateData from "./UpdateData";
import CurrentNotificationContext from "../../notifications/CurrentNotificationContext";
import { notificationTypes } from "../../notifications/components/notificationTypes";
import SourceTypeSelector from "../../user/components/SourceTypeSelector";
import { useFetch } from "../../utils/useFetch";
import IsMobile from "../../utils/IsMobile";
import EcgTable from "./EcgTable";
import RecordsChart from "./RecordsChart";
import { Badge, Button, IconButton, TextField } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CommentIcon from "@mui/icons-material/Comment";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import tw from "twin.macro";
import CircularProgressMidParent from "@profilog/commons/components/CircularProgressMidParent";
import AppSettings from "@profilog/front/app/AppSettings";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ChartPointRecordsGridDialog from "../ChartPointRecordsGridDialog";
import DailyBpmTable from "./DailyBpmTable";
import Fade from "@mui/material/Fade";
import { getSourceInfo } from "@profilog/records/sources";
import DailySleepChart from "@profilog/misc/records/components/DailySleepChart";
import RangeSleepChart from "@profilog/misc/records/components/RangeSleepChart";
import RangeChart from "@profilog/misc/records/components/RangeChart";
import DailyStresAndBodyBatteryChart from "@profilog/misc/records/components/DailyStresAndBodyBatteryChart";

const chartEmptyCss = tw`min-height[100px]`;
const chartCss = tw`px-1.5 pb-2.5 min-height[310px]`;
const chartLongCss = tw`min-height[]`;

export default function CpzRecordsChart({
    fetchService,
    chartGroup,
    onVisibleDateChanged,
    initiateDate,
    externalServicesSettings,
    scrollToMe,
    project,
    isMobile,
    hideEmptyCharts,
    hideMessages,
    hideCaloricsTablesButton,
    isForMM,
}) {
    const { t, i18n } = useTranslation();
    const [userData, setUserData] = useState();
    const [minMaxDate, setMinMaxDate] = useState(null);
    const userDataLoadingTimeRef = useRef();
    const [glycemiaData, setGlycemiaData] = useState();
    const glycemiaDataLoadingTimeRef = useRef();
    const [collapsed, setCollapsed] = useState(false);
    const [visualRange, setVisualRange] = useState();
    const isFirstRun = useRef(true);
    const [isOpenNextCharts, setIsOpenNextCharts] = useState(true);
    const {
        medicalSubjectId,
        parametersData,
        lastRecordDate,
        isPatientPreview,
        profilogUserId,
        profilogUserPersonFullName,
        getUserDataForCpzAsync,
        getGlycemiaDataAsync,
        getRecordDatesAsync,
        getMessagesAsync,
        markMessagesNotificationsAsReadOrUnreadAsync,
        GetApiErrors,
        PostApiErrors,
        PutApiErrors,
    } = fetchService;
    const { apiPost } = useFetch();
    const [caloricTableDate, setCaloricTableDate] = useState(null);
    const [messagesTableDate, setMessagesTableDate] = useState(null);
    const { currentNotification, setCurrentNotification } = useContext(CurrentNotificationContext);
    const [dayConversations, setDayConversations] = useState([]);
    const [highlightDates, setHighlightDates] = useState();
    const [spoRangeType, setSpoRangeType] = useState("midnight");
    const [isMessagesTableIsOpened, setIsMessagesTableIsOpened] = useState(false);
    const selectedPointInfo = useRef(null);

    /// Hodnota, při které se mění barvy křivek. POZOR při změně hodnoty je potřeba změnit hodnotu i v api.
    const spo2ContsValue = 85;

    const weightInKilogramsParam = parametersData.parameterSettings.find((p) => p.systemName === "WeightInKilograms");
    const systolicPressureParam = parametersData.parameterSettings.find((p) => p.systemName === "SystolicPressure");
    const diastolicPressureParam = parametersData.parameterSettings.find((p) => p.systemName === "DiastolicPressure");
    const glycemiaParam = parametersData.parameterSettings.find((p) => p.systemName === "Glycemia");
    const spo2Param = parametersData.parameterSettings.find((p) => p.systemName === "SpO2");
    const ecgParam = parametersData.parameterSettings.find((p) => p.systemName === "Ecg");
    const energyParam = parametersData.parameterSettings.find((p) => p.systemName === "EnergyIntakeInKiloJoules");
    const odiParam = parametersData.parameterSettings.find((p) => p.systemName === "Odi");

    const menuDivRef = useRef();
    const [isOpenToolTipMenu, setIsOpenToolTipMenu] = useState(false);
    const chartsRef = useRef([]);

    function loadHighlightDates() {
        //ČPZ chce podbarvovat pouze dny kdy je něco v kalorických tabulkách
        let highlightOnlyByEnergyIntake = (isFirstSection() || isMiddleSection()) && isForMM;
        Promise.all([
            getHighlightDates(highlightOnlyByEnergyIntake ? [energyParam.id] : getParameterIds()),
            isMiddleSection() && isForMM ? getHighlightDates([spo2Param.id]) : [],
            isMiddleSection() && isForMM ? getHighlightDates([ecgParam.id]) : [],
        ]).then((values) => {
            let result = values;
            if (highlightOnlyByEnergyIntake) {
                let energyEnergyIntakes = values[0];
                let energyEnergyIntakeTimes = values[0].map((m) => m.getTime());
                let spO2 = values[1];
                let ecg = values[2];

                result = [
                    energyEnergyIntakes,
                    spO2.filter((f) => energyEnergyIntakeTimes.includes(f.getTime())),
                    ecg.filter((f) => energyEnergyIntakeTimes.includes(f.getTime())),
                ];
            }

            setHighlightDates(result);
        });
    }

    async function getHighlightDates(parameterIds) {
        let response = await getRecordDatesAsync(parameterIds);
        if (response.isOk) {
            return response.json.map((item) => {
                return new Date(item);
            });
        }
        return [];
    }

    async function handleDataUpdated(updatedDate) {
        setCaloricTableDate(new Date(updatedDate));
        await getUserData();
        await refreshDayConversationsAsync();
        if (isLastSection()) await getGlycemiaData();
    }

    useEffect(() => {
        async function asyncFunc() {
            await refreshDayConversationsAsync();
        }
        if (areMessagesVisible()) asyncFunc();
    }, [messagesTableDate?.getTime()]);

    function getParameterIds() {
        let parameterIds = [];
        for (let index = 0; index < chartGroup.charts.length; index++) {
            const chart = chartGroup.charts[index];
            parameterIds = parameterIds.concat(chart.parameterIds);
        }

        if (isLastSection()) {
            const index = parameterIds.indexOf(glycemiaParam.id);
            if (index > -1) parameterIds.splice(index, 1);
        }

        return parameterIds;
    }

    async function getUserData() {
        let date = new Date();

        let result = await getUserDataForCpzAsync(
            { dateFrom: new Date(visualRange.startValue), dateTo: new Date(visualRange.endValue) },
            chartGroup.filter.groupingType,
            getParameterIds(),
            spoRangeType === "noon",
        );

        userDataLoadingTimeRef.current = new Date().getTime() - date.getTime();

        if (result.isOk) {
            setUserData(result.json);
        }
    }

    async function getMinMaxDate() {
        Promise.all([
            getMinMaxDateAsync("Glycemia", false),
            getMinMaxDateAsync("Glycemia", true),
            getMinMaxDateAsync("WeightInKilograms", false),
            getMinMaxDateAsync("WeightInKilograms", true),
        ]).then((values) => {
            let minGlycemia = values[0];
            let maxGlycemia = values[1];
            let minWeight = values[2];
            let maxWeight = values[3];
            let min = null;
            let max = null;
            if (minGlycemia.isOk && minGlycemia.json.records.length > 0) {
                min = new Date(minGlycemia.json.records[0].utcStartAt);
            }
            if (maxGlycemia.isOk && maxGlycemia.json.records.length > 0) {
                max = new Date(maxGlycemia.json.records[0].utcEndAt);
            }

            if (minWeight.isOk && minWeight.json.records.length > 0) {
                let minw = new Date(minWeight.json.records[0].utcStartAt);
                if (min == null || minw.getTime() < min.getTime()) min = minw;
            }
            if (maxWeight.isOk && maxWeight.json.records.length > 0) {
                let maxw = new Date(maxWeight.json.records[0].utcEndAt);
                if (max == null || maxw.getTime() > max.getTime()) max = maxw;
            }

            if (min !== null && max !== null) {
                setMinMaxDate({
                    min: min,
                    max: max,
                });
            }
        });
    }

    async function getMinMaxDateAsync(recordtypeName, sortDescending) {
        const parametresDto = [{ recordTypeName: recordtypeName }];

        const dto = {
            medicalSubjectId: medicalSubjectId,
            utcFrom: null,
            utcTo: null,
            recordFilters: parametresDto,
            pagination: { page: 1, pageSize: 1, sortDescending: sortDescending },
        };
        return await apiPost("/v2/medical-subjects/records/get-raw", dto);
    }

    async function getGlycemiaData() {
        let date = new Date();
        let result = await getGlycemiaDataAsync({
            dateFrom: new Date(visualRange.startValue),
            dateTo: new Date(visualRange.endValue),
        });

        glycemiaDataLoadingTimeRef.current = new Date().getTime() - date.getTime();

        if (result.isOk) {
            setGlycemiaData(result.json);
        }
    }

    function toggleIsMessagesTableIsOpened() {
        setIsMessagesTableIsOpened((o) => {
            if (!o) refreshDayConversationsAsync(true);
            return !o;
        });
    }

    function isFirstSection() {
        return chartGroup.index === 1;
    }

    function isMiddleSection() {
        return chartGroup.index === 2;
    }

    function isLastSection() {
        return chartGroup.index === 3;
    }

    function setNewVisualRange(newVisualRange) {
        setMessagesTableDate(new Date(newVisualRange.startValue));
        setCaloricTableDate(new Date(newVisualRange.startValue));
        setVisualRange(newVisualRange);

        if (!isFirstRun.current && onVisibleDateChanged && isFirstSection())
            onVisibleDateChanged(new Date(newVisualRange.startValue), false);
    }

    useEffect(() => {
        if (initiateDate && isMiddleSection()) {
            onVisibleDateFromChange(new Date(initiateDate));
        }
    }, [initiateDate]);

    useEffect(() => {
        if (lastRecordDate !== undefined) {
            isFirstRun.current = true;
            let initShowingDate = hideEmptyCharts && initiateDate ? new Date(initiateDate) : new Date();
            let endDate = hideEmptyCharts && initiateDate ? new Date(initiateDate) : new Date();
            initShowingDate.setDate(initShowingDate.getDate() + 1);
            initShowingDate.setHours(0, 0, 0, 0);
            endDate.setDate(endDate.getDate() + 1);
            endDate.setHours(0, 0, 0, 0);

            initShowingDate = getDateFromVisibleLastDaysCount(initShowingDate, -1, chartGroup.filter.visibleDaysCount);
            // if (isMiddleSection()) {
            //     initShowingDate.setDate(initShowingDate.getDate() - 1);
            //     endDate.setDate(endDate.getDate() - 1);
            // }
            setNewVisualRange({ startValue: initShowingDate, endValue: endDate });
        }
    }, [lastRecordDate !== undefined || lastRecordDate?.getTime()]);

    useEffect(() => {
        if (isMiddleSection()) {
            if (!canRefreshByNotificationContext()) return;

            let date = new Date(currentNotification.notification.message.date);
            setNewVisibleDateForMessages(date);
        }
    }, [currentNotification?.notification]);

    useEffect(() => {
        if (isMiddleSection() && AppSettings.defaultRecordVisibleDate) {
            let date = new Date(AppSettings.defaultRecordVisibleDate);
            AppSettings.defaultRecordVisibleDate = null;
            setNewVisibleDateForMessages(date);
        }
    }, []);

    function setNewVisibleDateForMessages(date) {
        date.setHours(0, 0, 0, 0);
        setIsMessagesTableIsOpened(true);
        if (scrollToMe) scrollToMe();

        if (date.getTime() === messagesTableDate?.getTime()) refreshDayConversationsAsync(true);
        else onVisibleDateFromChange(date);
    }

    useEffect(() => {
        if (visualRange == null) return;
        setUserData();
        getUserData();
        if (isLastSection()) {
            setGlycemiaData();
            getGlycemiaData();
        }
    }, [visualRange, spoRangeType]);

    useEffect(() => {
        loadHighlightDates();
        if (isLastSection()) {
            setMinMaxDate();
            getMinMaxDate();
        }
    }, [parametersData]);

    useEffect(() => {
        if (isFirstRun.current) isFirstRun.current = false;
    });

    function canRefreshByNotificationContext() {
        return !isPatientPreview && currentNotification?.notification?.type === notificationTypes.comment;
    }

    function getDateFromVisibleLastDaysCount(oldDate, x, daysCount) {
        let date = new Date(oldDate);

        switch (daysCount) {
            case "Year":
                date.setFullYear(date.getFullYear() + x);
                break;
            case "Month":
                date.setMonth(date.getMonth() + x);
                break;
            case "Week":
                date.setDate(date.getDate() + x * 7);
                break;
            case "TwoWeeks":
                date.setDate(date.getDate() + x * 14);
                break;
            case "Day":
                date.setDate(date.getDate() + x);
                break;
            default:
                date.setDate(date.getDate() + x * daysCount);
                break;
        }

        return date;
    }

    //událost při změně datumu zobrazení
    const onVisibleDateFromChange = (date) => {
        if (isLastSection()) {
            let dateTo = new Date(visualRange.endValue);
            if (date.getTime() >= dateTo.getTime()) {
                dateTo = new Date(date);
                dateTo.setDate(dateTo.getDate() + 1);
            }

            setNewVisualRange({
                startValue: new Date(date),
                endValue: dateTo,
            });
        } else {
            let dateStart = new Date(date);
            dateStart.setHours(0, 0, 0, 0);
            let dateEnd = new Date();
            dateEnd = getDateFromVisibleLastDaysCount(new Date(date), 1, chartGroup.filter.visibleDaysCount);
            dateEnd.setHours(0, 0, 0, 0);

            setNewVisualRange({
                startValue: dateStart,
                endValue: dateEnd,
            });
        }
    };

    function seriesClick(e, index) {
        if (selectedPointInfo.current?.date !== null) {
            if (selectedPointInfo.current?.parameters?.length > 0) {
                setIsOpenToolTipMenu(true);
            } else if (!isMiddleSection() && onVisibleDateChanged) {
                onVisibleDateChanged(selectedPointInfo.current.date, true);
                selectedPointInfo.current.date = null;
            }
        }
    }

    function getChartTitle(chart, dataSource, dataModel) {
        let title = "";
        let firstData;
        let lastData;
        let leftInfo = null;
        let rightInfo = null;

        if (chart.renderTitle) return chart.renderTitle();

        chart.parameterIds.forEach((parameterId, index) => {
            if (parameterId === odiParam.id) return;
            const parameter = parametersData.parameterSettings.find((p) => p.id === parameterId);
            const axes = parametersData.axesSettings.find((x) => x.id === parameter.axisId);
            let unit;

            if (parameterId === weightInKilogramsParam.id || parameter.systemName === "EnergyIntakeInKiloJoules") {
                unit = axes.title;
            }

            let sourceTypes = getSourceTypes(parameterId);
            let selectedSourceType = getSelectedSourceTypes(parameterId);

            if (index > 0) title += " & ";
            title += `${parameter?.translatedName} ${unit ? "[" + unit + "]" : ""}`;

            if (sourceTypes && sourceTypes.length > 0) {
                if (index === 0)
                    firstData = { sourceTypes, selectedSourceType, parameterId, name: parameter?.translatedName };
                else lastData = { sourceTypes, selectedSourceType, parameterId, name: parameter?.translatedName };
            }

            if (isFirstSection() && index < 2 && dataSource) {
                if (parameter.systemName === "EnergyIntakeInKiloJoules" || parameter.systemName === "Steps") {
                    let res = intervalToDuration({
                        start: visualRange.startValue,
                        end: visualRange.endValue,
                    });
                    let count = res.days;
                    let date = null;
                    let sum = 0;
                    const parameterIdText = parameterId.toString();
                    for (let dsIndex = 0; dsIndex < dataSource.length; dsIndex++) {
                        const element = dataSource[dsIndex];
                        let value = element[parameterIdText];
                        if (value === undefined || value === 0 || value === null) continue;
                        let elementDate = new Date(element["recordDate"]).getDate();
                        if (date !== null && date === elementDate) continue;
                        sum += value;
                        date = elementDate;
                    }

                    if (count > 0) {
                        leftInfo = (
                            <div className="calorics-table total-info" tw="float-left pb-1">
                                <span className="badge">
                                    Ø {Math.round((sum / count) * 10) / 10} {unit ?? "kroků"} /{" "}
                                    {t("recordsVisualization.GroupBy.Day")}
                                </span>
                            </div>
                        );
                    }
                }
            }

            if (isMiddleSection() && index === 0 && dataSource) {
                if (parameter.systemName === "EnergyIntakeInKiloJoules" || parameter.systemName === "Steps") {
                    let count = 0;
                    const parameterIdText = parameterId.toString();
                    for (let dsIndex = 0; dsIndex < dataSource.length; dsIndex++) {
                        const element = dataSource[dsIndex];
                        let value = element[parameterIdText];
                        if (value === undefined) continue;
                        count += value;
                    }

                    if (count > 0) {
                        leftInfo = (
                            <div className="calorics-table total-info" tw="float-left pb-1">
                                <span className="total-kj">{count / 5}</span>
                                <span className="kt-units">{unit ?? "kroků"}</span>
                            </div>
                        );
                    }
                } else if (parameter.id === spo2Param.id) {
                    const parameterIdText = parameterId.toString();
                    const odiIdText = odiParam.id.toString();
                    // let index = 0;
                    // const limit = 97;
                    // const change = 3;
                    // const timeInMinutes = 2;
                    // let values = [];
                    // let prevValue = null;
                    // let startDateInHours = null;
                    // let prevTimeInMinutes = null;
                    let avgSpo = 0;
                    let minSpo = 100;
                    let odi = null;

                    for (let dsIndex = 0; dsIndex < dataSource.length; dsIndex++) {
                        const element = dataSource[dsIndex];
                        let value = element[parameterIdText];
                        if (value !== undefined) {
                            avgSpo += value;
                            if (value < minSpo) minSpo = value;
                        }
                        let odiValue = element[odiIdText];
                        if (odiValue !== undefined) odi = odiValue;

                        // let actualDate = new Date(element["recordDate"]);
                        // let actualTimeInMinutes = Math.round(actualDate.getTime() / 1000 / 60);
                        // let actualTimeInHours = Math.round(actualTimeInMinutes / 60);

                        // if (startDateInHours == null) {
                        //     startDateInHours = actualTimeInHours;
                        // } else if (actualTimeInHours - startDateInHours >= 1) {
                        //     startDateInHours = actualTimeInHours;
                        //     values.push(index);
                        //     index = 0;
                        // }

                        // if (
                        //     prevValue != null &&
                        //     value <= limit - change &&
                        //     (prevValue >= limit || actualTimeInMinutes - prevTimeInMinutes >= timeInMinutes)
                        // ) {
                        //     prevValue = value;
                        //     prevTimeInMinutes = actualTimeInMinutes;
                        //     index++;
                        // }

                        // if (prevValue == null || value >= limit) {
                        //     prevValue = value;
                        //     prevTimeInMinutes = actualTimeInMinutes;
                        // }
                    }

                    avgSpo = Math.round((avgSpo / dataSource.length) * 10) / 10;

                    // if (values.length > 0) {
                    //     const lenght = values.length;
                    //     odi = Math.round((values.reduce((a, b) => a + b, 0) / lenght) * 10) / 10;
                    // }

                    leftInfo = (
                        <div className="calorics-table total-info" tw="float-left pb-1">
                            <div>
                                <select
                                    tw="w-full"
                                    value={spoRangeType}
                                    onChange={(e) => setSpoRangeType(e.target.value)}
                                >
                                    <option value="midnight">{t("recordsVisualization.Spo.Midnight")}</option>
                                    <option value="noon">{t("recordsVisualization.Spo.Noon")}</option>
                                </select>
                            </div>
                            <div tw="float-left pt-1">
                                <span className="badge">Ø {avgSpo} %</span>
                            </div>
                        </div>
                    );

                    rightInfo = (
                        <div className="calorics-table total-info" tw="float-right pb-1">
                            <div>
                                <span className="kt-units">ODI</span>
                                <span className="total-kj2">{odi}</span>
                            </div>
                            <div tw="pt-1">
                                <span className="kt-units">min</span>
                                <span className="total-kj2">{minSpo}</span>
                                <span className="kt-units">%</span>
                            </div>
                        </div>
                    );
                }
            }

            {
                /* if (isLastSection() && parameterId === glycemiaParam.id && dataModel && isForMM) && radeji nepovoleno aby nebyl problém s cpz*/
            }
            if (false) {
                const axes = parametersData.axesSettings.find((x) => x.id === parameter.axisId);
                leftInfo = (
                    <div className="calorics-table total-info" tw="float-left pb-1">
                        <span className="kt-units">{t("recordsVisualization.Glycemia.TotalAvg")}</span>
                        <span className="total-kj2">{dataModel.avarage ?? "-"}</span>
                        <span className="kt-units">{axes.title}</span>
                    </div>
                );

                rightInfo = (
                    <div className="calorics-table total-info" tw="float-right pb-1">
                        <span className="kt-units">{t("recordsVisualization.Glycemia.MonthAvg")}</span>
                        <span className="total-kj2">{dataModel.lastMonthAverage ?? "-"}</span>
                        <span className="kt-units">{axes.title}</span>
                        <br />
                        <span className="kt-units">{t("recordsVisualization.Glycemia.WeekAvg")}</span>
                        <span className="total-kj2">{dataModel.lastWeekAverage ?? "-"}</span>
                        <span className="kt-units">{axes.title}</span>
                    </div>
                );
            }
        });

        return (
            <Fragment>
                <div tw="my-1.5 flex-1 text-center">{title}</div>
                <div tw="flex flex-wrap justify-between items-center">
                    <span>{firstData && getSourceTypeSelector(firstData)}</span>
                    <span>{lastData && getSourceTypeSelector(lastData)}</span>
                </div>
                {(leftInfo || rightInfo) && (
                    <div tw="flex flex-wrap justify-between items-center mt-1.5">
                        <div>{leftInfo}</div>
                        <div>{rightInfo}</div>
                    </div>
                )}
            </Fragment>
        );
    }

    function getSourceTypeSelector(data) {
        return (
            <div tw="flex items-center opacity-75">
                <div label tw="font-size[10px] whitespace-nowrap mr-1">
                    priorita {data.name.toLowerCase()}:
                </div>
                <SourceTypeSelector
                    sourceTypes={data.sourceTypes}
                    sourceNames={userData.sourceNames}
                    selectedValue={data.selectedSourceType}
                    handleOnChange={(newSourceType) => setDefaultSourceType(data.parameterId, newSourceType)}
                />
            </div>
        );
    }

    function getSourceTypes(parameterId) {
        let sourceTypes =
            userData && userData.parametersDataWithSourceTypes && userData.parametersDataWithSourceTypes[parameterId];

        if (sourceTypes) {
            let keys = [];
            for (let key in sourceTypes) keys.push(key);
            sourceTypes = keys;
        }

        return sourceTypes;
    }

    function getSelectedSourceTypes(parameterId) {
        return userData && userData.defaultSourceTypes && userData.defaultSourceTypes[parameterId];
    }

    async function setDefaultSourceType(parameterId, newSourceType) {
        if (userData && userData.defaultSourceTypes) {
            if (parameterId === parametersData.parameterSettings.find((p) => p.systemName === "Steps").id)
                await apiPost("/web/user/step-source-type", { sourceType: newSourceType });
            if (
                parameterId ===
                parametersData.parameterSettings.find((p) => p.systemName === "HeartRateInBeatsPerMinute").id
            )
                await apiPost("/web/user/heart-rate-source-type", { sourceType: newSourceType });
            let newDefaultSourceTypes = { ...userData.defaultSourceTypes };
            newDefaultSourceTypes[parameterId] = newSourceType;
            let newUserData = { ...userData, defaultSourceTypes: newDefaultSourceTypes };
            setUserData(newUserData);
        }
    }

    function moveToFirstVisualRange() {
        let endValue = new Date(minMaxDate.min);
        endValue.setFullYear(endValue.getFullYear() + 1);

        setNewVisualRange({ startValue: new Date(minMaxDate.min), endValue: endValue });
    }

    function moveToLastVisualRange() {
        let endValue = new Date(minMaxDate.max);
        endValue.setHours(0, 0, 0, 0);
        endValue.setDate(endValue.getDate() + 1);

        let startValue = new Date(endValue);
        startValue.setFullYear(startValue.getFullYear() - 1);

        setNewVisualRange({ startValue: startValue, endValue: endValue });
    }

    function moveVisualRange(days) {
        const vr = JSON.parse(JSON.stringify(visualRange));
        let daysCount = chartGroup.filter.visibleDaysCount;
        //když máme rok - má se šoupat po mesících
        if (isLastSection()) daysCount = "Month";
        if (isFirstSection()) daysCount = "Day";

        if (vr) {
            if (vr.startValue) {
                vr.startValue = new Date(vr.startValue);
                if (!isMiddleSection()) vr.startValue.setHours(0, 0, 0, 0);
                vr.startValue = getDateFromVisibleLastDaysCount(vr.startValue, days, daysCount);
            }
            if (vr.endValue) {
                vr.endValue = new Date(vr.endValue);
                if (!isMiddleSection()) vr.endValue.setHours(0, 0, 0, 0);
                vr.endValue = getDateFromVisibleLastDaysCount(vr.endValue, days, daysCount);
            }
        }
        setNewVisualRange(vr);
    }

    function filterPoints(points) {
        return points.filter(
            (thing, index, self) =>
                index ===
                self.findIndex(
                    (t) =>
                        t.argument.getTime() === thing.argument.getTime() &&
                        t.seriesName === thing.seriesName &&
                        t.value === thing.value,
                ),
        );
    }

    function customizeTooltip(pointInfo, index) {
        let items = [];
        const dateElement = document.createElement("span");
        dateElement.style.paddingTop = 5;

        if (isDailyGlycemia(index)) {
            let source;
            const parameter = parametersData.parameterSettings.find((x) => x.tag === pointInfo.point.series.tag);
            if (pointInfo.point.data.hasOwnProperty(parameter.id.toString() + "-st")) {
                const sourceId = pointInfo.point.data[parameter.id.toString() + "-st"];
                const sourceInfo = getSourceInfo(sourceId, userData.sourceNames[sourceId]);
                source = sourceInfo.displayName;
            }

            const axisSettings = parametersData.axesSettings.find((x) => x.id === pointInfo.point.series.axis);
            const element = document.createElement("span");
            element.textContent = `${pointInfo.seriesName}: ${pointInfo.value} ${axisSettings.title} ${source}`;
            element.style.color = pointInfo.point.getColor();
            element.className = "active";
            items.push(element.outerHTML);
            dateElement.textContent = format(new Date(pointInfo.point.data.date), "dd.MM.yyyy HH:mm");

            const chartRef = chartsRef.current[index]._element.getBoundingClientRect();

            selectedPointInfo.current = {
                chartIndex: index,
                date: new Date(pointInfo.point.data.date),
                parameters: getParameters(pointInfo, pointInfo.point.data.date),
                x: pointInfo.point.x + chartRef.left + 5,
                y: pointInfo.point.y + chartRef.top + window.scrollY + 5,
            };
        } else {
            filterPoints(pointInfo.points).forEach((point) => {
                const axisSettings = parametersData.axesSettings.find((x) => x.id === point.point.series.axis);
                const parameter = parametersData.parameterSettings.find((x) => x.tag === point.point.series.tag);

                let text;
                if (
                    parameter.id !== glycemiaParam.id &&
                    parameter.id !== weightInKilogramsParam.id &&
                    parameter.systemName !== "SystolicPressure" &&
                    parameter.systemName !== "DiastolicPressure"
                ) {
                    if (point.point.series.type === "rangearea") {
                        text = `${point.seriesName} (min - max): ${point.valueText}`;
                    } else if (parameter.measureType !== "AmountMeasure") {
                        text = `${point.seriesName} ø: ${point.valueText}`;
                    } else {
                        text = `${point.seriesName}: ${point.valueText}`;
                    }
                } else {
                    text = `${point.seriesName}: ${point.valueText}`;
                }
                if (axisSettings && axisSettings.title !== "") text = `${text} ${axisSettings.title}`;

                if (
                    point.point.series.type !== "rangearea" &&
                    point.point.data.hasOwnProperty(parameter.id.toString() + "-st")
                ) {
                    const sourceId = point.point.data[parameter.id.toString() + "-st"];
                    const sourceInfo = getSourceInfo(sourceId, userData.sourceNames[sourceId]);

                    text = `${text} (${sourceInfo.displayName})`;
                }

                if (axisSettings)
                    if (pointInfo.seriesName === point.seriesName && pointInfo.value === point.value) {
                        const element = document.createElement("span");
                        element.textContent = text;
                        element.style.color = pointInfo.point.getColor();
                        element.className = "active";
                        text = element.outerHTML;
                    }
                items.push(text);
            });

            switch (chartGroup.filter.groupingType) {
                case "minute":
                case "hour":
                    dateElement.textContent = format(pointInfo.argument, "dd.MM.yyyy HH:mm");
                    break;
                default:
                    if (isFirstSection() && index === 2)
                        dateElement.textContent = format(pointInfo.argument, "dd.MM.yyyy HH:mm");
                    else dateElement.textContent = format(pointInfo.argument, "dd.MM.yyyy");
                    break;
            }

            const chartRef = chartsRef.current[index]._element.getBoundingClientRect();

            selectedPointInfo.current = {
                chartIndex: index,
                date: pointInfo.argument,
                parameters: getParameters(pointInfo, pointInfo.argument),
                x: pointInfo.point.x + chartRef.left + 5,
                y: pointInfo.point.y + chartRef.top + window.scrollY + 5,
            };
        }

        items.push(dateElement.outerHTML);

        return { text: items.join("\n") };
    }

    function getParameters(pointInfo, date) {
        let parameters = [];
        let parameterIds = [];
        for (let key in pointInfo.point.data) {
            let id = parseInt(key);
            if (!isNaN(id)) {
                if (!parameterIds.includes(id)) {
                    const parameter = parametersData.parameterSettings.find((p) => p.id === id);

                    if (
                        parameter.systemName === "HeartRateInBeatsPerMinute" ||
                        parameter.systemName === "EnergyIntakeInKiloJoules" ||
                        parameter.systemName === "Steps"
                    ) {
                        continue;
                    }

                    parameterIds.push(id);

                    let dateFrom = new Date(date);
                    let dateTo = new Date(date);

                    if (chartGroup.filter.groupingType === "day") {
                        dateFrom.setHours(0, 0, 0, 0);
                        dateTo.setHours(0, 0, 0, 0);
                        dateTo.setDate(dateTo.getDate() + 1);
                    }
                    if (chartGroup.filter.groupingType === "hour") {
                        dateFrom.setHours(dateFrom.getHours(), 0, 0, 0);
                        dateTo.setHours(dateTo.getHours() + 1, 0, 0, 0);
                    }

                    let sourceId = pointInfo.point.data[id + "-st"];
                    let source = null;
                    if (sourceId) source = userData.sourceNames[sourceId];

                    parameters.push({
                        name: parameter.translatedName,
                        dateFrom: dateFrom,
                        dateTo: dateTo,
                        parameterSystemName: parameter.systemName,
                        source: source,
                    });
                }
            }
        }
        return parameters;
    }

    function getValueAxes(chart, dataModel) {
        let settings = getAxisSettings(chart.parameterIds, dataModel);
        if (settings.length === 0) {
            return (
                <ValueAxis position="left" visible={false}>
                    <Tick visible={false} />
                </ValueAxis>
            );
        }

        const kgAxis = dataModel.userParamValueRanges.find((x) => x.axisId === weightInKilogramsParam.axisId);
        const glycemiaAxis = dataModel.userParamValueRanges.find((x) => x.axisId === glycemiaParam.axisId);

        return settings.map((axisSettings) => {
            let start = axisSettings.startValue;
            let end = axisSettings.endValue;
            let tick = axisSettings.tickInterval;

            if (kgAxis && axisSettings.id === weightInKilogramsParam.axisId) {
                start = kgAxis.min - 10;
                end = kgAxis.max + 10;
            }

            if (glycemiaAxis && axisSettings.id === glycemiaParam.axisId) {
                end = (Math.floor((glycemiaAxis.max * 1.2) / 5) + 1) * 5;
                tick = end / 5;
                end = end - tick;
            }

            if (start === null && settings.length === 2) {
                start = 0;
            }
            return (
                <ValueAxis
                    key={axisSettings.id}
                    name={axisSettings.id}
                    allowDecimals={axisSettings.isDecimal}
                    position={axisSettings.positionType}
                    tickInterval={tick}
                    type="continuous"
                    visible={false}
                    visualRangeUpdateMode="auto"
                >
                    <Label
                        visible={true}
                        customizeText={valueAxisCustomizeLabelText}
                        alignment={axisSettings.positionType}
                        //position="inside"
                        font={{ opacity: "0.7" }}
                        indentFromAxis={0}
                    />
                    {chart.parameterIds[0] === glycemiaParam.id && (
                        <Strip startValue={3.9} endValue={10} color={project === "sps" ? "#f5f5f5" : "#e6faf8"} />
                    )}
                    {chart.parameterIds[0] === spo2Param.id && (
                        <ConstantLine
                            value={spo2ContsValue}
                            color="#ff0000"
                            label={{ visible: false }}
                            dashStyle="dash"
                        />
                    )}
                    <Tick visible={false} />
                    <WholeRange startValue={start} endValue={end} />
                    {(isLastSection() || glycemiaAxis) && <VisualRange startValue={start} endValue={end} />}
                </ValueAxis>
            );
        });
    }

    function getAxisSettings(parameterIds, dataModel) {
        let result = [];
        for (let index = 0; index < parameterIds.length; index++) {
            const parameterId = parameterIds[index];
            if (parameterId === odiParam.id) continue;
            const parameter = parametersData.parameterSettings.find((p) => p.id === parameterId);
            const settings = parametersData.axesSettings.find((a) => a.id === parameter.axisId);
            if (
                !dataModel.recordParameterIds.includes(parameterId) &&
                settings.startValue === null &&
                settings.endValue === null
            ) {
                continue;
            }
            if (!result.includes(settings)) result.push(settings);
        }
        return result;
    }

    function argumentAxisCustomizeLabelText(info, index) {
        if (chartGroup.filter.visibleDaysCount === "Day") {
            if (info.max.getTime() === info.value.getTime()) return "";

            let hour = info.value.getHours();

            if (index === 2) {
                if (info.value.getMinutes() === 0 && hour % 1 === 0) return info.valueText;
                else return "";
            }

            if (info.value.getMinutes() === 30 && (hour === 0 || hour % 2 === 0)) return info.valueText;
            else return "";
        }

        if (isDailyGlycemia(index)) {
            if (info.max.getTime() === info.value.getTime()) return "";

            let hour = info.value.getHours();

            if (index === 2) {
                if (info.value.getMinutes() === 0 && hour % 1 === 0) return info.valueText;
                else return "";
            }

            if (info.value.getMinutes() === 30 && (hour === 0 || hour % 2 === 0)) return info.valueText;
            else return "";
        }

        if (chartGroup.filter.visibleDaysCount === "Week") {
            if (info.max.getTime() === info.value.getTime()) return "";

            const hours = info.value.getHours();

            if (hours === 23 || hours === 0 || hours === 1) {
                let str = info.valueText.split(" ");
                return str.shift().toUpperCase() + "\n" + str.join(" ");
            } else return "";
        }

        if (chartGroup.filter.visibleDaysCount === "TwoWeeks") {
            if (info.max.getTime() === info.value.getTime()) return "";

            const hours = info.value.getHours();

            if (hours === 12 || hours === 13 || hours === 11) {
                let str = info.valueText.split(" ");
                return str.shift().toUpperCase() + "\n" + str.join(" ");
            } else return "";
        }

        if (chartGroup.filter.visibleDaysCount === "Year") {
            return info.value.getMonth() + 1;
        }

        return info.valueText;
    }

    function valueAxisCustomizeLabelText(data) {
        return data.min > data.value || data.value === 0 ? "" : data.valueText; // + "\n&nbsp;";
    }

    function getDataSource(parameterIds) {
        let result = [];
        let prevSpo2 = null;
        for (let index = 0; index < userData.recordValues.length; index++) {
            const element = userData.recordValues[index];
            let item = { recordDate: element["recordDate"] };

            let isAny = false;

            for (let index = 0; index < parameterIds.length; index++) {
                const parameterId = parameterIds[index];
                const parameterIdText = parameterId.toString();
                let value = element[parameterIdText];
                if (value === undefined) continue;
                item[parameterIdText] = value;
                if (element.hasOwnProperty(parameterIdText + "-min"))
                    item[parameterIdText + "-min"] = element[parameterIdText + "-min"];
                if (element.hasOwnProperty(parameterIdText + "-max"))
                    item[parameterIdText + "-max"] = element[parameterIdText + "-max"];
                if (element.hasOwnProperty(parameterIdText + "-st"))
                    item[parameterIdText + "-st"] = element[parameterIdText + "-st"];

                if (parameterId === spo2Param.id) {
                    item[parameterIdText + "+" + spo2ContsValue] =
                        value == null
                            ? null
                            : value >= spo2ContsValue
                            ? value
                            : prevSpo2 > spo2ContsValue
                            ? spo2ContsValue
                            : null;
                    item[parameterIdText + "-" + spo2ContsValue] =
                        value == null
                            ? null
                            : value <= spo2ContsValue
                            ? value
                            : prevSpo2 < spo2ContsValue
                            ? spo2ContsValue
                            : null;

                    prevSpo2 = value;
                }

                isAny = true;
            }

            if (isAny) result.push(item);
        }

        if (userData.parametersDataWithSourceTypes) {
            for (let index = 0; index < parameterIds.length; index++) {
                const parameterId = parameterIds[index];
                let data = userData.parametersDataWithSourceTypes[parameterId];
                let defaultSourceTypes = getSelectedSourceTypes(parameterId);
                if (
                    data &&
                    defaultSourceTypes !== undefined &&
                    defaultSourceTypes !== null &&
                    data.hasOwnProperty(defaultSourceTypes)
                )
                    result = result.concat(data[defaultSourceTypes]);
            }
        }
        return result;
    }

    function getTickInterval(index) {
        if (isDailyGlycemia(index)) return <TickInterval minutes={30} />;
        switch (chartGroup.filter.visibleDaysCount) {
            case "Year":
                return <TickInterval months={1} />;
            case "Month":
                return <TickInterval days={7} />;
            case "Week":
                return <TickInterval hours={24} />;
            case "TwoWeeks":
                return <TickInterval hours={12} />;
            case "Day":
                return <TickInterval minutes={30} />;
            default:
                return null;
        }
    }

    function getGlycemiaVisualRange() {
        let startValue = new Date();
        let endValue = new Date();
        startValue.setHours(0, 0, 0, 0);
        endValue.setHours(0, 0, 0, 0);
        endValue.setDate(endValue.getDate() + 1);
        return { startValue, endValue };
    }

    function getWeekends() {
        if (!isFirstSection()) return [];

        let weekends = [];

        for (
            let d = new Date(visualRange.startValue);
            d <= new Date(visualRange.endValue);
            d.setDate(d.getDate() + 1)
        ) {
            let day = d.getDay();
            let startValue;
            let endValue;
            if (day === 6) {
                startValue = new Date(d);
                d.setHours(0, 0, 0, 0);
                endValue = new Date(startValue);
                endValue.setDate(endValue.getDate() + 1);
                endValue.setHours(23, 59, 59);
                weekends.push({ startValue: startValue, endValue: endValue });
            } else if (day === 0 && visualRange.startValue.getDay() === 0) {
                startValue = new Date(d);
                d.setHours(0, 0, 0, 0);
                endValue = new Date(startValue);
                endValue.setHours(23, 59, 59);
                weekends.push({ startValue: startValue, endValue: endValue });
            }
        }

        return weekends;
    }

    function getDateTimeFormat(value, index) {
        switch (chartGroup.filter.visibleDaysCount) {
            case "Year":
                if (isDailyGlycemia(index)) {
                    return value.toLocaleTimeString(i18n.language, {
                        hour: "numeric",
                    });
                }
                return value.toLocaleDateString(i18n.language, {
                    month: "long",
                    day: "numeric",
                });
            case "Month":
            case "Week":
                if (isDailyGlycemia(index)) {
                    return value.toLocaleTimeString(i18n.language, {
                        hour: "numeric",
                    });
                }
                return value.toLocaleDateString(i18n.language, {
                    month: "short",
                    day: "numeric",
                    weekday: "short",
                });
            case "TwoWeeks":
                return value.toLocaleDateString(i18n.language, {
                    month: "short",
                    day: "numeric",
                    weekday: "short",
                });
            case "Day":
                return value.toLocaleTimeString(i18n.language, {
                    hour: "numeric",
                });
            default:
                return null;
        }
    }

    function getSeries(chart, chartIndex, dataModel) {
        let result = [];

        for (let index = 0; index < chart.parameterIds.length; index++) {
            const parameterId = chart.parameterIds[index];
            if (!dataModel.recordParameterIds.includes(parameterId)) continue;
            if (parameterId === odiParam.id) continue;
            const parameterSettings = parametersData.parameterSettings.find((p) => p.id === parameterId);
            result.push({ parameterSettings: parameterSettings, isRange: false });
            if (parameterSettings.systemName === "HeartRateInBeatsPerMinute")
                result.push({ parameterSettings: parameterSettings, isRange: true });
        }

        let point = {};
        if (isDailyGlycemia(chartIndex)) {
            const count = dataModel.recordValues.length;
            point = {
                size: count > 2000 ? 2 : count > 400 ? 3 : 7,
                border: {
                    visible: count < 400,
                },
            };
        }

        let res = result.map((data, index) => {
            return (
                <Series
                    key={index}
                    valueField={
                        data.isRange
                            ? undefined
                            : chart.parameterIds[0] === spo2Param.id
                            ? data.parameterSettings.id + "+" + spo2ContsValue
                            : data.parameterSettings.id
                    }
                    rangeValue1Field={data.isRange ? data.parameterSettings.id + "-min" : undefined}
                    rangeValue2Field={data.isRange ? data.parameterSettings.id + "-max" : undefined}
                    name={data.parameterSettings.translatedName}
                    axis={data.parameterSettings.axisId}
                    type={
                        data.isRange
                            ? "rangeArea"
                            : isMiddleSection() && chart.parameterIds[0] === glycemiaParam.id
                            ? "spline"
                            : data.parameterSettings.serieType === "Bar"
                            ? "stepArea"
                            : data.parameterSettings.serieType
                    }
                    order={data.parameterSettings.order}
                    color={data.isRange ? "#b0daff" : data.parameterSettings.color}
                    visible={true}
                    tag={data.parameterSettings.tag}
                    point={point}
                    width={chart.parameterIds[0] === spo2Param.id ? 1 : 2}
                ></Series>
            );
        });
        let data;
        if (isMiddleSection() && chart.parameterIds[0] === spo2Param.id) {
            data = result[0];
            if (data) {
                res.push(
                    <Series
                        key={2}
                        valueField={data.parameterSettings.id + "-" + spo2ContsValue}
                        name={data.parameterSettings.translatedName}
                        axis={data.parameterSettings.axisId}
                        type={data.parameterSettings.serieType}
                        order={data.parameterSettings.order}
                        color={"#ff0000"}
                        visible={true}
                        tag={data.parameterSettings.tag}
                        point={point}
                        width={1}
                    ></Series>,
                );
            }
        }

        if (isMiddleSection() && chart.parameterIds[0] === glycemiaParam.id) {
            data = result[0];
            res.push(
                <Series
                    key={2}
                    valueField={data.parameterSettings.id}
                    name={data.parameterSettings.translatedName}
                    axis={data.parameterSettings.axisId}
                    type="scatter"
                    order={data.parameterSettings.order - 1}
                    color={data.isRange ? "#b0daff" : data.parameterSettings.color}
                    visible={true}
                    tag={data.parameterSettings.tag}
                    //point={{ visible: false }}
                ></Series>,
            );
        }

        if (isLastSection() && chart.parameterIds[0] === weightInKilogramsParam.id) {
            data = result[0];
            res.push(
                <Series
                    key={2}
                    valueField={data.parameterSettings.id}
                    name={data.parameterSettings.translatedName}
                    axis={data.parameterSettings.axisId}
                    type="scatter"
                    order={data.parameterSettings.order - 1}
                    color={data.isRange ? "#b0daff" : data.parameterSettings.color}
                    visible={true}
                    tag={data.parameterSettings.tag}
                ></Series>,
            );
        }

        if (
            (isFirstSection() && chart.parameterIds[0] === systolicPressureParam.id) ||
            (isForMM && isMiddleSection() && chartIndex === 4)
        ) {
            result.forEach((data, index) => {
                res.push(
                    <Series
                        key={(index + 1) * 2}
                        valueField={data.parameterSettings.id}
                        name={data.parameterSettings.translatedName}
                        axis={data.parameterSettings.axisId}
                        type="scatter"
                        order={data.parameterSettings.order - 1}
                        color={data.parameterSettings.color}
                        visible={true}
                        tag={data.parameterSettings.tag}
                    ></Series>,
                );
            });
        }

        return res;
    }

    async function refreshDayConversationsAsync(isMessagesTableToggledAndOpened) {
        const newConversations = await getDayConversationsAsync(isMessagesTableToggledAndOpened);

        setDayConversations((oldConversations) => {
            if (newConversations) return newConversations;
            else return oldConversations;
        });

        if (newConversations && canRefreshByNotificationContext() && scrollToMe) {
            scrollToMe();
        }
    }

    function getLastMessage(oldConversations) {
        if (!oldConversations) return null;

        let lastMessage;
        Object.keys(oldConversations).forEach((key) =>
            oldConversations[key].forEach((item) => {
                if (!lastMessage || new Date(item.createdDate) > new Date(lastMessage.createdDate)) lastMessage = item;
            }),
        );
        return lastMessage;
    }

    async function getDayConversationsAsync(isMessagesTableToggledAndOpened) {
        let conv = JSON.parse(JSON.stringify(dayConversations));
        let lastMessage = null;
        if (!canRefreshByNotificationContext()) lastMessage = getLastMessage(conv);
        let response = await getMessagesAsync(lastMessage, false, profilogUserId);

        if (response.isOk) {
            let messages = response.json;

            let isConvChanged = false;

            if (lastMessage && messages.length > 0) {
                messages = Object.keys(conv)
                    .map((key) => conv[key])
                    .reduce((acc, oldMesssages) => {
                        return acc.concat(oldMesssages.filter((f) => acc.map((m) => m.id).indexOf(f.id) < 0));
                    }, messages);

                conv = groupToDayConversations(messages);
                isConvChanged = true;
            } else if (!lastMessage) {
                conv = groupToDayConversations(messages);
                isConvChanged = true;
            }

            const mDateKey = getConversationDayKey(messagesTableDate);

            if (mDateKey && conv[mDateKey] && (isMessagesTableIsOpened || isMessagesTableToggledAndOpened)) {
                let messages = conv[mDateKey].filter((f) => !f.isFromMe && !f.displayedDate);

                if (messages.length > 0) {
                    const newDisplayedDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'0000'");

                    messages.forEach((f) => {
                        f.displayedDate = newDisplayedDate;
                    });

                    const markMessagesNotificationsAsReadOrUnreadDto = {
                        medicalSubjectId: messages[0].medicalSubjectId,
                        groupId: messages[0].group?.id,
                        date: messages[0].date,
                        displayedDate: newDisplayedDate,
                        messageIds: messages.map((m) => m.id),
                        setAsReadTrueSetAsUnreadFalse: true,
                    };

                    isConvChanged = true;
                    readOrUnreadMessagesAsync(markMessagesNotificationsAsReadOrUnreadDto, mDateKey, null);
                }
            }

            if (isConvChanged) return conv;
        }
        return null;
    }

    function groupToDayConversations(messages) {
        let conv = messages
            .sort((m1, m2) => {
                if (getConversationDayKey(m1.date) === getConversationDayKey(m2.date))
                    return new Date(m2.createdDate).getTime() - new Date(m1.createdDate).getTime();
                else return new Date(m1.date).getTime() - new Date(m2.date).getTime();
            })
            .reduce((acc, message) => {
                const key = getConversationDayKey(message.date);
                if (!acc[key]) acc[key] = [];

                acc[key].push(message);
                return acc;
            }, {});

        return conv;
    }

    function getConversationDayKey(date) {
        if (!date) return "";
        date = new Date(date);
        date.setHours(0, 0, 0, 0);
        date = format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSS'0000'");

        return date;
    }

    function areMessagesVisible() {
        return !hideMessages && messagesTableDate && isMiddleSection();
    }

    async function readOrUnreadMessagesAsync(markMessagesNotificationsAsReadOrUnreadDto, mDateKey, dateForRevert) {
        const response = await markMessagesNotificationsAsReadOrUnreadAsync(markMessagesNotificationsAsReadOrUnreadDto);

        if (!response.isOk) {
            setDayConversations((old) => {
                let conv = JSON.parse(JSON.stringify(old));

                conv[mDateKey].forEach((f) => {
                    if (markMessagesNotificationsAsReadOrUnreadDto.messageIds.indexOf(f.id) >= 0)
                        f.displayedDate = dateForRevert;
                });

                return conv;
            });
        } else if (canRefreshByNotificationContext()) {
            setCurrentNotification((o) => ({ ...o, notification: null }));
        } else {
            setCurrentNotification((o) => ({ ...o }));
        }
    }

    function markTheMessageAsUnread(message) {
        const key = getConversationDayKey(message.date);

        setDayConversations((old) => {
            let conv = JSON.parse(JSON.stringify(old));
            conv[key][conv[key].map((m) => m.id).indexOf(message.id)].displayedDate = null;
            return conv;
        });

        const markMessagesNotificationsAsReadOrUnreadDto = {
            medicalSubjectId: message.medicalSubjectId,
            groupId: message.group?.id,
            date: message.date,
            displayedDate: null,
            messageIds: [message.id],
            setAsReadTrueSetAsUnreadFalse: false,
        };

        readOrUnreadMessagesAsync(markMessagesNotificationsAsReadOrUnreadDto, key, message.displayedDate);
    }

    function getDatesWithComments() {
        return dayConversations ? Object.keys(dayConversations).map((m) => new Date(m)) : [];
    }

    function getVisibleDateTo() {
        if (!visualRange) return null;

        let dateTo = new Date(visualRange.endValue);
        dateTo.setDate(dateTo.getDate() - 1);
        return dateTo;
    }

    function setVisibleDateTo(date) {
        if (isLastSection()) {
            let dateFrom = new Date(visualRange.startValue);

            if (dateFrom.getTime() > date.getTime()) {
                dateFrom = new Date(date);
            }

            let dateTo = new Date(date);
            dateTo.setDate(dateTo.getDate() + 1);

            setNewVisualRange({
                startValue: dateFrom,
                endValue: dateTo,
            });
        }
    }

    function isDailyGlycemia(index) {
        return isLastSection() && index === 1;
    }

    function getCaloricTableHtml() {
        return (
            <div tw="md:w-6/12 mt-4 px-4 float-right">
                <div className="calorics-table ">
                    <div className="food-box">
                        <CaloricsTable fetchService={fetchService} selectedDateTime={caloricTableDate} />
                    </div>
                </div>
            </div>
        );
    }

    function customizePoint(arg) {
        if (arg.value < 3.9) {
            return { color: "red" };
        }
        if (arg.value > 10) {
            return { color: "orange" };
        }
    }

    // function getSpo2VisualRange() {
    //     let dateFrom = new Date(visualRange.startValue);
    //     let dateTo = new Date(visualRange.endValue);
    //     dateFrom.setHours(12, 0, 0, 0);
    //     dateTo.setHours(12, 0, 0, 0);
    //     return { startValue: dateFrom, endValue: dateTo };
    // }

    function openDailyDetail() {
        if (onVisibleDateChanged && selectedPointInfo.current?.date !== null) {
            onVisibleDateChanged(selectedPointInfo.current.date, true);
            selectedPointInfo.current.date = null;
        }

        setIsOpenToolTipMenu(false);
    }

    async function handleToolTipMenuClose(isAnyChange) {
        setIsOpenToolTipMenu(false);
        if (isAnyChange) await getUserData();
    }

    if (!parametersData) return null;

    const mDateKey = getConversationDayKey(messagesTableDate);
    const messages = dayConversations[mDateKey] ? dayConversations[mDateKey] : [];
    const notReadCount = messages.filter((f) => !f.displayedDate && !f.isFromMe).length;

    return (
        <Fragment>
            <GetApiErrors />
            <PostApiErrors />
            <PutApiErrors />

            {/* Záhlaví, volba datumů */}
            <div tw="flex-col gap-2 flex justify-between items-center px-5 py-2.5 shadow-updown sm:flex-row">
                <div tw="flex-1 text-sm sm:text-xl">
                    <DaysLink
                        linkValue={chartGroup.filter.visibleDaysCount}
                        onClick={() => setCollapsed(!collapsed)}
                        collapsed={collapsed}
                        first={true}
                    />
                </div>

                <div tw="flex-1 flex justify-center items-center space-x-1">
                    {isLastSection() && minMaxDate && (
                        <Button
                            variant="contained"
                            size="small"
                            disableElevation
                            tw="p-1 min-w-min text-xs"
                            onClick={() => moveToFirstVisualRange()}
                            startIcon={<FirstPageIcon tw="-mr-2" />}
                        />
                    )}
                    <Button
                        variant="contained"
                        size="small"
                        disableElevation
                        tw="p-1 min-w-min text-xs"
                        onClick={() => moveVisualRange(-1)}
                        startIcon={<ChevronLeftIcon tw="-mr-2" />}
                    >
                        {isLastSection()
                            ? t("recordsVisualization.VisiblePeriod.Month")
                            : t("recordsVisualization.VisiblePeriod.Day")}
                    </Button>

                    <div>
                        <DatePicker
                            selected={visualRange && visualRange.startValue}
                            onChange={(date) => onVisibleDateFromChange(date)}
                            customInput={
                                <TextField
                                    size="small"
                                    tw="width[11ch] text-xs"
                                    inputProps={{
                                        sx: { p: "5px", fontSize: "12px" },
                                        readOnly: AppSettings.isMobileApp,
                                    }}
                                />
                            }
                            highlightDates={[
                                {
                                    "react-datepicker__day--highlighted": highlightDates ? highlightDates[0] : [],
                                },
                                {
                                    "react-datepicker__day--highlighted-custom-2": getDatesWithComments(),
                                },
                                {
                                    "react-datepicker__day--highlighted-spo2": highlightDates ? highlightDates[1] : [],
                                },
                                {
                                    "react-datepicker__day--highlighted-ecg": highlightDates ? highlightDates[2] : [],
                                },
                                {
                                    "react-datepicker__day--highlighted-ecgAndSpo2": highlightDates
                                        ? highlightDates[1].filter((value) =>
                                              highlightDates[2].some((value2) => value.getTime() === value2.getTime()),
                                          )
                                        : [],
                                },
                            ]}
                        />
                    </div>

                    {visualRange && isFirstSection() && (
                        <div tw="flex space-x-2 text-xs">
                            <span>-</span>
                            <span>{format(getVisibleDateTo(), "dd.MM.yyyy")}</span>
                        </div>
                    )}

                    {isLastSection() && (
                        <div tw="flex items-center space-x-2">
                            <span>-</span>
                            <DatePicker
                                selected={getVisibleDateTo()}
                                onChange={(date) => setVisibleDateTo(date)}
                                customInput={
                                    <TextField
                                        size="small"
                                        tw="width[11ch] text-xs"
                                        inputProps={{ sx: { p: "5px", fontSize: "12px" } }}
                                    />
                                }
                                highlightDates={[
                                    {
                                        "react-datepicker__day--highlighted": highlightDates ? highlightDates[0] : [],
                                    },
                                ]}
                            />
                        </div>
                    )}

                    <Button
                        variant="contained"
                        size="small"
                        disableElevation
                        tw="p-1 min-w-min text-xs"
                        onClick={() => moveVisualRange(1)}
                        endIcon={<ChevronRightIcon tw="-ml-2" />}
                    >
                        {isLastSection()
                            ? t("recordsVisualization.VisiblePeriod.Month")
                            : t("recordsVisualization.VisiblePeriod.Day")}
                    </Button>
                    {isLastSection() && minMaxDate && (
                        <Button
                            variant="contained"
                            size="small"
                            disableElevation
                            tw="p-1 min-w-min text-xs"
                            onClick={() => moveToLastVisualRange()}
                            startIcon={<LastPageIcon tw="-mr-2" />}
                        />
                    )}
                </div>

                <div tw="flex-1 flex justify-end items-center">
                    {isMiddleSection() && !hideCaloricsTablesButton && (
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            disableElevation
                            tw="normal-case text-xs"
                            startIcon={<img src={CaloricsTablesLogo} width={16} alt="Kalorické tabulky" />}
                            onClick={() => window.open("https://www.kaloricketabulky.cz/")}
                        >
                            {t("extConnectors.CaloricsTables.Title")}
                        </Button>
                    )}
                    {visualRange && isMiddleSection() && (
                        <UpdateData selectedDateTime={visualRange.startValue} onDataUpdated={handleDataUpdated} />
                    )}
                    {areMessagesVisible() && (
                        <Fragment>
                            <NewMessageDialog
                                trigger={(open) => (
                                    <IconButton
                                        sx={{
                                            color: (theme) =>
                                                isPatientPreview ? "#2b64af" : theme.palette.primary.main,
                                        }}
                                        onClick={open}
                                    >
                                        <AddCommentIcon />
                                    </IconButton>
                                )}
                                medicalSubjectId={medicalSubjectId}
                                isProfi={false}
                                fixedDate={messagesTableDate}
                                isCommentDef={true}
                                onSave={refreshDayConversationsAsync}
                                isPatientPreview={isPatientPreview}
                                profilogUserId={profilogUserId}
                                profilogUserPersonFullName={profilogUserPersonFullName}
                            />
                            {messages.length > 0 && (
                                <IconButton color="primary" onClick={toggleIsMessagesTableIsOpened}>
                                    <Badge
                                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                        badgeContent={`${notReadCount}/${messages.length}`}
                                        sx={{
                                            "& .MuiBadge-badge": {
                                                backgroundColor: (theme) =>
                                                    notReadCount > 0
                                                        ? theme.palette.unread.main
                                                        : theme.palette.primary.main,
                                                color: (theme) =>
                                                    notReadCount > 0
                                                        ? theme.palette.unread.contrastText
                                                        : theme.palette.primary.contrastText,
                                            },
                                        }}
                                    >
                                        <CommentIcon />
                                    </Badge>
                                </IconButton>
                            )}
                        </Fragment>
                    )}
                </div>
            </div>

            {/* Vykreslení grafů */}
            {!collapsed && (
                <div tw="w-full">
                    {/* Zprávy */}
                    {areMessagesVisible() && isMessagesTableIsOpened && messages.length > 0 && (
                        <div tw="mb-1 pt-1 w-full">
                            <MessagesTable
                                messages={messages}
                                isPatientPreview={isPatientPreview}
                                markTheMessageAsUnread={markTheMessageAsUnread}
                            />
                        </div>
                    )}

                    {/* Kalorické tabulky v prostřední sekci */}
                    {isMiddleSection() && !IsMobile() && getCaloricTableHtml()}

                    {/* Grafy */}
                    <div css={[chartCss, tw`flex flex-wrap relative`, isMiddleSection() && tw`w-full md:w-6/12`]}>
                        <Fade
                            in={!userData || (!glycemiaData && isLastSection())}
                            style={{
                                transitionDelay: !userData || (!glycemiaData && isLastSection()) ? "1000ms" : "0ms",
                            }}
                            unmountOnExit
                        >
                            <CircularProgressMidParent />
                        </Fade>

                        {chartGroup.charts.map((chart, index) => {
                            let dataSource;
                            let dataModel;
                            let isDailyGlycemie = isDailyGlycemia(index) && glycemiaData;
                            if (isDailyGlycemie) {
                                dataSource = glycemiaData.recordValues;
                                dataModel = glycemiaData;
                            } else if (userData) {
                                dataSource = getDataSource(chart.parameterIds);
                                dataModel = userData;
                            }

                            if (dataSource === undefined) return null;

                            if (dataSource && dataSource.length === 0) {
                                if (isMiddleSection() && index > 1) return null;
                                if (isLastSection() && index > 0) return null;
                                if (!isForMM && isFirstSection() && index > 1) return null;
                                if (IsMobile()) return null;
                                if (hideEmptyCharts) return null;
                            }

                            //http://mlog/T630627
                            if (isForMM && isMiddleSection() && index === 4 && dataSource && dataSource.length < 2) {
                                return null;
                            }

                            return (
                                <React.Fragment key={index}>
                                    {!(isFirstSection() && index > 1 && !isOpenNextCharts) &&
                                        (!isForMM ||
                                            (isForMM &&
                                                !(
                                                    isFirstSection() &&
                                                    index > 1 &&
                                                    dataSource &&
                                                    dataSource.length === 0
                                                ))) && (
                                            <Fragment>
                                                {isMiddleSection() &&
                                                    IsMobile() &&
                                                    dataSource &&
                                                    dataSource.length > 0 &&
                                                    index === 1 &&
                                                    getCaloricTableHtml()}

                                                <div css={[tw`w-full`, !isMiddleSection() && tw`md:w-6/12`]}>
                                                    <div
                                                        css={[
                                                            chartCss,
                                                            dataSource && dataSource.length === 0 && chartEmptyCss,
                                                            isLastSection() && chartLongCss,
                                                        ]}
                                                    >
                                                        <div tw="pt-6">
                                                            {getChartTitle(chart, dataSource, dataModel)}
                                                        </div>
                                                        {dataSource &&
                                                            dataSource.length === 0 &&
                                                            (!externalServicesSettings.isGoogleFitConnected &&
                                                            !externalServicesSettings.areCaloricsTablesConnected &&
                                                            !externalServicesSettings.isGarminConnectConnected &&
                                                            !isMobile ? (
                                                                <NoDevice />
                                                            ) : (
                                                                <NoData />
                                                            ))}
                                                        {dataModel && dataSource && dataSource.length > 0 && (
                                                            <Chart
                                                                ref={(c) => {
                                                                    if (c) chartsRef.current[index] = c;
                                                                }}
                                                                dataSource={dataSource}
                                                                adjustOnZoom={false}
                                                                onSeriesClick={(e) => seriesClick(e, index)}
                                                                customizePoint={
                                                                    chart.parameterIds[0] === glycemiaParam.id
                                                                        ? customizePoint
                                                                        : undefined
                                                                }
                                                            >
                                                                <Animation enabled={false} />

                                                                <Legend visible={false} />
                                                                <Size height={isLastSection() ? 350 : 250} />
                                                                <ArgumentAxis
                                                                    argumentType="datetime"
                                                                    hoverMode="none"
                                                                    visualRange={
                                                                        isDailyGlycemie
                                                                            ? getGlycemiaVisualRange()
                                                                            : chart.parameterIds[0] === spo2Param.id
                                                                            ? undefined //getSpo2VisualRange()
                                                                            : visualRange
                                                                    }
                                                                >
                                                                    {getTickInterval(index)}

                                                                    <Label
                                                                        wordWrap="none"
                                                                        visible={true}
                                                                        customizeText={(x) =>
                                                                            argumentAxisCustomizeLabelText(x, index)
                                                                        }
                                                                    >
                                                                        <Font
                                                                            family="Montserrat, sans-serif"
                                                                            size={10}
                                                                        />
                                                                        <Format
                                                                            formatter={(x) =>
                                                                                getDateTimeFormat(x, index)
                                                                            }
                                                                        />
                                                                    </Label>
                                                                    <Tick visible={false} />
                                                                    {getWeekends().map((weekend, index) => {
                                                                        return (
                                                                            <Strip
                                                                                key={index}
                                                                                startValue={weekend.startValue}
                                                                                endValue={weekend.endValue}
                                                                                color="#ededed"
                                                                            />
                                                                        );
                                                                    })}
                                                                </ArgumentAxis>

                                                                <CommonSeriesSettings
                                                                    argumentField="recordDate"
                                                                    selectionMode="none"
                                                                    hoverMode="none"
                                                                    spline={{
                                                                        hoverStyle: {
                                                                            width: 2,
                                                                        },
                                                                        point: {
                                                                            visible: false,
                                                                            size: 3,
                                                                        },
                                                                    }}
                                                                    line={{
                                                                        hoverStyle: {
                                                                            width: 2,
                                                                        },
                                                                        point: {
                                                                            visible: false,
                                                                            size: 3,
                                                                        },
                                                                    }}
                                                                    scatter={{
                                                                        point: {
                                                                            size: 7,
                                                                            border: {
                                                                                visible: true,
                                                                                color: "white",
                                                                            },
                                                                        },
                                                                    }}
                                                                    steparea={{
                                                                        //opacity: 1,
                                                                        point: { visible: false },
                                                                    }}
                                                                >
                                                                    {/* <Point size={7} hoverMode="none" visible={true} /> */}
                                                                    <Border visible={false} />
                                                                </CommonSeriesSettings>
                                                                <CommonAxisSettings>
                                                                    <Grid opacity="0.5" />
                                                                </CommonAxisSettings>

                                                                {getValueAxes(chart, dataModel)}

                                                                {getSeries(chart, index, dataModel)}

                                                                <ZoomAndPan argumentAxis={"none"} />
                                                                <Tooltip
                                                                    enabled={true}
                                                                    shared={true}
                                                                    zIndex={100000000}
                                                                    cornerRadius={5}
                                                                    customizeTooltip={(x) => customizeTooltip(x, index)}
                                                                />
                                                            </Chart>
                                                        )}
                                                        {isMiddleSection() && index === 2 && (
                                                            <div
                                                                className="spo2"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: t("recordsVisualization.Spo.Text"),
                                                                }}
                                                            />
                                                        )}
                                                        {dataSource && dataSource.length > 0 && (
                                                            <div tw="[text-align: center]">
                                                                <span tw="text-xs text-gray-300">
                                                                    {t("recordsVisualization.ChartLoadingTime")}{" "}
                                                                    {Math.round(
                                                                        (isDailyGlycemie
                                                                            ? glycemiaDataLoadingTimeRef.current
                                                                            : userDataLoadingTimeRef.current) / 10,
                                                                    ) / 100}{" "}
                                                                    s.
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}

                                    {/* {isForMM && radeji nepovoleno aby nebyl problém s cpz*/}
                                    {undefined &&
                                        isFirstSection() &&
                                        index === chartGroup.charts.length - 1 &&
                                        isOpenNextCharts &&
                                        visualRange && (
                                            <div tw="w-full pt-4">
                                                <RecordsChart
                                                    fetchService={fetchService}
                                                    isAllowedSaveVisibilities={true}
                                                    chartGroup={{
                                                        visibleDaysCountValues: [],
                                                        filter: { visibleDaysCount: 14, groupingType: "day" },
                                                        charts: [{}],
                                                    }}
                                                    isLiteMode={true}
                                                    initiateDate={visualRange.startValue}
                                                />
                                            </div>
                                        )}

                                    {/* Tlačítko Více grafů */}
                                    {isFirstSection() && index > 1 && dataSource.length > 0 && (
                                        <div tw="w-full text-center pb-2">
                                            <Button
                                                size="small"
                                                variant="contained"
                                                onClick={() => setIsOpenNextCharts(!isOpenNextCharts)}
                                            >
                                                {isOpenNextCharts
                                                    ? t("recordsVisualization.LessCharts")
                                                    : t("recordsVisualization.MoreCharts")}
                                            </Button>
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}

                        {AppSettings.isEdures && isFirstSection() && visualRange && (
                            <Fragment>
                                <div tw="w-full md:w-6/12">
                                    <div tw="flex flex-wrap relative">
                                        <RangeSleepChart
                                            medicalSubjectId={medicalSubjectId}
                                            dateFrom={visualRange.startValue}
                                            dateTo={visualRange.endValue}
                                        />
                                    </div>
                                </div>
                                <div tw="w-full md:w-6/12">
                                    <div tw="flex flex-wrap relative">
                                        <RangeChart
                                            title="Stres & Body battery"
                                            medicalSubjectId={medicalSubjectId}
                                            dateFrom={visualRange.startValue}
                                            dateTo={visualRange.endValue}
                                            parameters={[
                                                {
                                                    name: "Body battery",
                                                    systemName: "BodyBattery",
                                                    valueType: "minmax",
                                                    color: "#ccccff",
                                                    minColor: "#7f7fff",
                                                    maxColor: "#0000ff",
                                                },
                                                {
                                                    name: "Stres",
                                                    systemName: "StressLevel",
                                                    valueType: "avg",
                                                },
                                            ]}
                                            visualRange={<VisualRange startValue={0} endValue={75} />}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        )}

                        {AppSettings.isEdures && isMiddleSection() && visualRange && (
                            <Fragment>
                                <DailySleepChart medicalSubjectId={medicalSubjectId} date={visualRange.startValue} />
                                <DailyStresAndBodyBatteryChart
                                    medicalSubjectId={medicalSubjectId}
                                    date={visualRange.startValue}
                                />
                            </Fragment>
                        )}

                        {/* Mobilní grafy - když nejsou data */}
                        {IsMobile() &&
                            chartGroup.charts.map((chart, index) => {
                                let dataSource;
                                let isDailyGlycemie = isDailyGlycemia(index) && glycemiaData;
                                if (isDailyGlycemie) {
                                    dataSource = glycemiaData.recordValues;
                                } else if (userData) {
                                    dataSource = getDataSource(chart.parameterIds);
                                }

                                if (dataSource && dataSource.length === 0) {
                                    if (isMiddleSection() && index > 1) return null;
                                    if (isLastSection() && index > 0) return null;
                                    if (isFirstSection() && index > 1) return null;
                                }

                                if ((dataSource && dataSource.length > 0) || hideEmptyCharts) return null;

                                return (
                                    <React.Fragment key={index}>
                                        {!(isFirstSection() && index > 1 && !isOpenNextCharts) && (
                                            <Fragment>
                                                <div css={!isMiddleSection() ? tw`w-full md:w-6/12` : tw`w-full`}>
                                                    <div
                                                        css={[chartCss, chartEmptyCss, isLastSection() && chartLongCss]}
                                                    >
                                                        <div tw="pt-2">{getChartTitle(chart)}</div>

                                                        <div>
                                                            {!externalServicesSettings.isGoogleFitConnected &&
                                                            !externalServicesSettings.areCaloricsTablesConnected &&
                                                            !externalServicesSettings.isGarminConnectConnected &&
                                                            !isMobile ? (
                                                                <NoDevice />
                                                            ) : (
                                                                <NoData />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                    </div>
                </div>
            )}

            {!collapsed && isFirstSection() && visualRange && (
                <Fragment>
                    <DailyBpmTable
                        fetchService={fetchService}
                        dateFrom={visualRange.startValue}
                        dateTo={visualRange.endValue}
                    />
                </Fragment>
            )}

            {!collapsed && (isFirstSection() || isMiddleSection()) && visualRange && visualRange.startValue && (
                <EcgTable
                    fetchService={fetchService}
                    dateFrom={visualRange.startValue}
                    dateTo={visualRange.endValue}
                    isOpen={isMiddleSection()}
                />
            )}

            <div
                ref={menuDivRef}
                style={{
                    position: "absolute",
                    left: `${selectedPointInfo.current?.x}px`,
                    top: `${selectedPointInfo.current?.y}px`,
                }}
            >
                <Menu
                    id="lock-menu"
                    anchorEl={menuDivRef.current}
                    open={isOpenToolTipMenu}
                    onClose={() => handleToolTipMenuClose(false)}
                >
                    {!isMiddleSection() && (
                        <MenuItem onClick={() => openDailyDetail()}>
                            {t("recordsVisualization.ShowDailyDetail")}
                        </MenuItem>
                    )}
                    {selectedPointInfo.current?.parameters &&
                        selectedPointInfo.current.parameters.map((parameter, index) => (
                            <ChartPointRecordsGridDialog
                                key={index}
                                parameterName={parameter.name}
                                medicalSubjectId={medicalSubjectId}
                                dateFrom={parameter.dateFrom}
                                dateTo={parameter.dateTo}
                                parameterSystemName={parameter.parameterSystemName}
                                source={parameter.source}
                                trigger={(o) => (
                                    <MenuItem onClick={o}>
                                        {selectedPointInfo.current.parameters.length === 1
                                            ? t("recordsVisualization.ParameterDetail")
                                            : `${t("recordsVisualization.ParameterDetail")} - ${parameter.name}`}
                                    </MenuItem>
                                )}
                                onCloseClicked={handleToolTipMenuClose}
                            />
                        ))}
                </Menu>
            </div>
        </Fragment>
    );
}

function NoDevice() {
    const { t } = useTranslation();

    return (
        <div tw="text-gray-500 flex flex-col items-center text-xs pt-0 sm:pt-5">
            <span>{t("recordsVisualization.NoDeviceConnected")}</span>
            {!IsMobile() && (
                <Button size="small" LinkComponent={Link} to="/in/settings" tw="normal-case">
                    {t("global.Set")}
                </Button>
            )}
        </div>
    );
}

function NoData() {
    const { t } = useTranslation();

    return <div tw="text-gray-500 text-center text-xs pt-0 sm:pt-5">{t("recordsVisualization.NoData")}</div>;
}
