import { Fragment, useEffect, useState } from "react";
import { useFetch } from "@profilog/misc/utils/useFetch";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import DrugCalendarItem from "./DrugCalendarItem";
import { groupAndPrepareDrugItems } from "./drugCalendarItemsPreparation";
import CalendarCustomAction from "./CalendarCustomAction";
import { prescriptionTypes } from "../../codebooks/prescriptionTypes";
import "twin.macro";
import { useTranslation } from "react-i18next";

export default function DrugCalendar({ medicalSubjectId, shortList = false, showGroupName = false }) {
    const { t } = useTranslation();

    const [isPosting, setIsPosting] = useState(false);
    const [calendarItems, setCalendarItems] = useState(null);
    const { apiGet, ApiErrors: ApiGetErrors } = useFetch();
    const { apiPost, ApiErrors: ApiPostErrors } = useFetch();

    useEffect(() => {
        getCalendarItems();
    }, []); // eslint-disable-line

    return (
        <Fragment>
            <div>
                <ApiGetErrors />
                <ApiPostErrors modal />
                {calendarItems &&
                    Object.entries(calendarItems)
                        .sort()
                        .map(([key, group]) => (
                            <div key={key} tw="mt-8 mb-2">
                                {group.isNow && (
                                    <NowSection medicalSubjectId={medicalSubjectId} onClosed={getCalendarItems} />
                                )}
                                <div tw="flex justify-center items-center space-x-1 [font-size: 13px]">
                                    <span>{group.dayTitle}</span>
                                    <span>{group.time}</span>
                                </div>
                                {group.items.map((item) => (
                                    <DrugCalendarItem
                                        key={item.id}
                                        item={item}
                                        onItemChanged={handleItemChanged}
                                        isPosting={isPosting}
                                        showGroupName={showGroupName}
                                    />
                                ))}
                            </div>
                        ))}
                {calendarItems && Object.keys(calendarItems).length === 0 && (
                    <p className="text-left">
                        {t("prescriptions.DrugCalendar.NothingYet")}{" "}
                        <Link component={RouterLink} to="/in/prescriptions">
                            {t("prescriptions.DrugCalendar.AddSomething")}
                        </Link>
                    </p>
                )}
            </div>
        </Fragment>
    );

    async function handleItemChanged(item, isConfirmed) {
        setIsPosting(true);
        const { confirmedAt, prescriptionApplicationId } = await postChange(item, isConfirmed);

        if (item.prescriptionScheduleId === null && !isConfirmed) {
            // schedules mimmo plán se po odškrtnutí na serveru odmažou - je třeba komplet přednačíst seznam
            await getCalendarItems();
        } else {
            let newItems = {};
            for (let key in calendarItems) {
                let group = calendarItems[key];
                newItems[key] = {
                    ...group,
                    items: group.items.map((i) =>
                        i.id !== item.id ? i : { ...item, confirmedAt, prescriptionApplicationId },
                    ),
                };
            }

            setCalendarItems(newItems);
        }

        setIsPosting(false);
    }

    async function getCalendarItems() {
        const response = await apiGet(
            `/web/prescriptions/calendar-items?medicalSubjectId=${medicalSubjectId}&dispersionInMinutes=120`,
        );
        if (response.isOk) setCalendarItems(groupAndPrepareDrugItems(response.json, new Date(), shortList, t));
    }

    async function postChange(item, isConfirmed) {
        const response = await apiPost("/web/prescriptions/calendar-items/change-state", {
            medicalSubjectId,
            scheduledAt: item.scheduledAt,
            dayTime: item.dayTime,
            confirmedAt: isConfirmed ? new Date().toISOString() : null,
            amount: item.amount,
            prescriptionApplicationId: item.prescriptionApplicationId,
            prescriptionScheduleId: item.prescriptionScheduleId,
            hasTime: item.hasTime,
            groupId: item.groupId,
        });
        return response.isOk ? response.json : null;
    }
}

function NowSection({ medicalSubjectId, onClosed }) {
    const { t } = useTranslation();
    return (
        <div tw="border-0 border-t border-solid border-t-gray-300 flex justify-between bg-gradient-to-b">
            <div tw="text-gray-400 font-bold text-sm">{t("prescriptions.Time.Now")}</div>
            <CalendarCustomAction
                medicalSubjectId={medicalSubjectId}
                prescriptionType={prescriptionTypes.drug}
                addButtonText={t("prescriptions.DrugCalendar.AddDrug")}
                onClosed={onClosed}
            />
        </div>
    );
}
