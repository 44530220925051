export interface SourceDto {
    origin: string | null;
    nodes: NodeDto[];
}

export interface NodeDto {
    type: "unknown" | "server" | "web" | "device" | "app" | "sdk" | "user";
    channel:
        | "unknown"
        | "api"
        | "rest"
        | "grpc"
        | "externalRest"
        | "externalGrpc"
        | "manualInput"
        | "voiceInput"
        | "bluetooth";
    provider: string | null;
    level: number;
}

export interface SourceInfo {
    displayName: string;
    path: string;
}

function firstCharToUpper(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function getNodeDisplayName(node: NodeDto): string | null {
    // TODO: Lokalizace
    if (node.type === "app" && (node.channel === "grpc" || node.channel === "rest")) return null;

    if (node.channel === "manualInput") return "Ručně";

    let provider = node.provider;
    if (provider === null || provider === "unknown") return null;
    if (provider === "recordPanel") return "Panel záznamů";
    if (provider === "appleHealthKit") return "Apple HealthKit";

    provider = provider.replace("kalorickeTabulkyFoods", "KT - Jídlo");
    provider = provider.replace("kalorickeTabulkyActivities", "KT - Aktivity");
    provider = provider.replace("kalorickeTabulkyValues", "KT - Hodnoty");
    provider = provider.replace("kalorickeTabulky", "KT");

    provider = firstCharToUpper(provider);
    if (node.provider === "garmin" && node.type === "sdk") provider += " Bluetooth";

    return provider;
}

export function getSourceInfo(sourceId: number, nodes: NodeDto[] | null): SourceInfo {
    if (!nodes) return { displayName: `Id ${sourceId}`, path: "?" };

    const path = nodes.map((node) => `${node.type}-${node.channel}-${node.provider}`).join(" | ");
    let displayName = nodes
        .map((node) => getNodeDisplayName(node))
        .filter((i) => i !== null)
        .join(" - ");

    if (displayName === null || displayName.trim().length === 0) displayName = `Id ${sourceId}`;

    return { displayName, path };
}
