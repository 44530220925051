import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useFetch } from "@profilog/misc/utils/useFetch";
import { useTranslation } from "react-i18next";
import { hasError, getErrorMessage } from "@profilog/misc/errors/ValidationError";
import useCountryCallingCodes from "@profilog/commons/hooks/useCountryCallingCodes";
import { Button, TextField } from "@mui/material";
import Stack from "@profilog/commons/components/Stack";
import ResponsiveStack from "@profilog/commons/components/ResponsiveStack";
import GenderSelector from "@profilog/commons/components/GenderSelector";
import PhoneNumberField from "@profilog/commons/components/PhoneNumberField";
import ApiErrorsList from "@profilog/misc/errors/ApiErrorsList";

const PersonalInfo = () => {
    const { t } = useTranslation();
    const { apiGet: apiGetUser, apiErrors: apiGetUserErrors } = useFetch();
    const { apiPut: apiPutUser, apiErrors: apiPutUserErrors } = useFetch();
    const [isLoading, setIsLoading] = useState(true);

    const {
        register,
        handleSubmit,
        control,
        errors,
        formState: { isSubmitting },
    } = useForm();
    const [user, setUser] = useState({});
    const { callingCodes, isLoading: isLoadingCallingCodes } = useCountryCallingCodes();

    async function getUser() {
        let result = await apiGetUser("/web/user-profile");
        setUser(result.json);
        setIsLoading(false);
    }
    useEffect(() => {
        getUser();
        // eslint-disable-next-line
    }, []);

    if (isLoading || isLoadingCallingCodes) return null;

    const saveUser = async (data) => {
        const currentUserDto = {
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumberCountryId: data.phone.length === 0 ? null : data["phone-callingCode"],
            phoneNumber: data.phone.length === 0 ? null : data.phone,
            gender: data.gender === "undefined" ? null : parseInt(data.gender),
            city: data.city,
            zipCode: data.zipCode,
            birthdate: data.birthdate,
            countryId: parseInt(data.country),
            pastAnamnesis: data.pastAnamnesis,
            currentAnamnesis: data.currentAnamnesis,
            familyAnamnesis: data.familyAnamnesis,
        };

        var response = await apiPutUser("/web/user-profile", currentUserDto);
        if (response.isOk) {
            setIsLoading(true);
            getUser();
        }
    };

    return (
        <div>
            <h2>{t("header.NavBar.AboutMe")}</h2>
            <form onSubmit={handleSubmit(saveUser)}>
                <Stack vertical>
                    <ResponsiveStack>
                        <TextField
                            name="firstName"
                            label={t("persons.Contacts.Name")}
                            defaultValue={user.firstName}
                            error={hasError(errors, "firstName")}
                            tw="flex-1"
                            helperText={getErrorMessage(errors, "firstName")}
                            inputRef={register({
                                required: t("global.Form.RequiredField"),
                            })}
                        />
                        <TextField
                            name="lastName"
                            label={t("persons.Contacts.Surname")}
                            defaultValue={user.lastName}
                            error={hasError(errors, "lastName")}
                            helperText={getErrorMessage(errors, "lastName")}
                            tw="flex-1"
                            inputRef={register({
                                required: t("global.Form.RequiredField"),
                            })}
                        />
                    </ResponsiveStack>
                    <ResponsiveStack>
                        <GenderSelector
                            control={control}
                            name="gender"
                            defaultValue={user.gender ?? "undefined"}
                            errors={errors}
                            tw="flex-1"
                        />
                        <TextField
                            type="date"
                            name="birthdate"
                            label={t("persons.Birthdate")}
                            defaultValue={user.birthdate?.slice(0, 10)}
                            error={hasError(errors, "birthdate")}
                            helperText={getErrorMessage(errors, "birthdate")}
                            tw="flex-1"
                            InputLabelProps={{ shrink: true }}
                            inputRef={register({
                                required: t("global.Form.RequiredField"),
                            })}
                        />
                    </ResponsiveStack>
                    <ResponsiveStack>
                        <PhoneNumberField
                            name="phone"
                            label={t("persons.Contacts.Phone")}
                            control={control}
                            errors={errors}
                            defaultCallingCode={user.phoneNumberCountryId}
                            defaultValue={user.phoneNumber}
                            callingCodes={callingCodes}
                            tw="flex-1"
                            register={register({
                                required: t("global.Form.RequiredField"),
                            })}
                        ></PhoneNumberField>
                        <TextField
                            name="zipCode"
                            label={t("persons.ZipCode")}
                            error={hasError(errors, "zipCode")}
                            helperText={getErrorMessage(errors, "zipCode")}
                            defaultValue={user.zipCode}
                            tw="flex-1"
                            inputRef={register({
                                required: t("global.Form.RequiredField"),
                            })}
                        />
                    </ResponsiveStack>
                    <TextField
                        name="pastAnamnesis"
                        label={t("persons.PastDiseases")}
                        multiline
                        fullWidth
                        rows={3}
                        defaultValue={user.pastAnamnesis}
                        inputRef={register}
                    />

                    <TextField
                        name="currentAnamnesis"
                        label={t("persons.CurrentDiseases")}
                        multiline
                        fullWidth
                        rows={3}
                        defaultValue={user.currentAnamnesis}
                        inputRef={register}
                    />

                    <TextField
                        name="familyAnamnesis"
                        label={t("persons.FamilyDiseases")}
                        multiline
                        fullWidth
                        rows={3}
                        defaultValue={user.familyAnamnesis}
                        inputRef={register}
                    />

                    <ResponsiveStack reverse>
                        <Button type="submit" variant="contained" disabled={isSubmitting}>
                            {t("global.Save")}
                        </Button>
                    </ResponsiveStack>
                    <ApiErrorsList apiErrors={apiGetUserErrors} />
                    <ApiErrorsList apiErrors={apiPutUserErrors} />
                </Stack>
            </form>
        </div>
    );
};

export default PersonalInfo;
