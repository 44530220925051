import { Outlet } from "react-router";
import Box from "./Box";

export default function UnivBoxLayout() {
    return (
        <section className="univ__inbody">
            <div className="container">
                <div className="col-12">
                    <Box>
                        <Outlet />
                    </Box>
                </div>
            </div>
        </section>
    );
}
