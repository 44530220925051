import DatePicker from "@profilog/misc/commons/components/DatePicker";
import { useSelector, useDispatch } from "react-redux";
import { prescriptionUpdate } from "../../redux/prescriptionSlice";
import { dateToISOStringOrNull } from "@profilog/utils/converting";
import { stringToIntOrNull } from "@profilog/utils/converting";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import "twin.macro";
import { getDurationText } from "../../utils/dates";
import { useTranslation } from "react-i18next";
import { prescriptionTypes } from "../../codebooks/prescriptionTypes";
import AppSettings from "@profilog/front/app/AppSettings";

export default function PrescriptionDuration({ prescriptionId, editing, open, wasOpen }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dateFrom = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].dateFrom);
    const dateTo = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].dateTo);
    const days = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].days);
    const prescriptionType = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].$type);

    const parsedDateFrom = dateFrom !== null ? new Date(dateFrom) : null;
    const parsedDateTo = dateTo !== null ? new Date(dateTo) : null;

    const firstApplicationText =
        prescriptionType === prescriptionTypes.survey
            ? t("prescriptions.Edit.FirstFilling")
            : t("prescriptions.Edit.FirstApplication");

    return editing ? (
        <div tw="flex flex-row flex-wrap gap-x-2 gap-y-4">
            <div>
                <Tooltip title={t("prescriptions.Edit.FirstApplicationInfo")}>
                    <DatePicker
                        selected={parsedDateFrom}
                        allowFutureDates
                        onChange={(date) =>
                            dispatch(
                                prescriptionUpdate({
                                    prescriptionId,
                                    updated: { dateFrom: dateToISOStringOrNull(date) },
                                }),
                            )
                        }
                        usePortal={false}
                        customInput={
                            <TextField
                                size="small"
                                label={t("prescriptions.Edit.From")}
                                InputProps={{
                                    placeholder: firstApplicationText,
                                    readOnly: AppSettings.isMobileApp,
                                }}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: 120 }}
                            />
                        }
                        isClearable={AppSettings.isMobileApp}
                    />
                </Tooltip>
            </div>
            <div>
                <TextField
                    autoFocus
                    size="small"
                    label={t("prescriptions.Edit.For")}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{t("prescriptions.Edit.days")}</InputAdornment>,
                    }}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: "10ch" }}
                    disabled={dateFrom === null && dateTo != null}
                    value={days ?? ""}
                    onChange={(e) =>
                        dispatch(
                            prescriptionUpdate({
                                prescriptionId,
                                updated: { days: stringToIntOrNull(e.target.value) },
                            }),
                        )
                    }
                />
            </div>
            <div>
                <DatePicker
                    selected={parsedDateTo}
                    allowFutureDates
                    onChange={(date) =>
                        dispatch(
                            prescriptionUpdate({
                                prescriptionId,
                                updated: { dateTo: dateToISOStringOrNull(date) },
                            }),
                        )
                    }
                    customInput={
                        <TextField
                            size="small"
                            label={t("prescriptions.Edit.To")}
                            InputLabelProps={{ shrink: true }}
                            sx={{ width: 120 }}
                            InputProps={{
                                readOnly: AppSettings.isMobileApp,
                            }}
                        />
                    }
                    isClearable={AppSettings.isMobileApp}
                    usePortal={false}
                />
            </div>
        </div>
    ) : (
        getDuration()
    );

    function getDuration() {
        const { text, isDefault } = getDurationText(parsedDateFrom, days, parsedDateTo, t, prescriptionType);

        if (isDefault && prescriptionId < 0 && !wasOpen) return t("prescriptions.Edit.pickDate");

        return isDefault ? text : <strong>{text}</strong>;
    }
}
