import HhApp from "./hh/HhApp";
import PlusCareApp from "./plusCare/PlusCareApp";
import IcuDemoApp from "./icuDemo/IcuDemoApp";
import { createTheme } from "@mui/material/styles";
import { prescriptionColors } from "@profilog/prescriptions/prescriptionColors";
import { getLangsFromEnv } from "../shared/getLangsFromEnv";
import { createMedilogProjectConfig } from "./medilog/createMedilogProjectConfig";

export const projectConfigFactories = {
    hh: () => ({ app: HhApp, createTheme: createDefaultTheme, getLangs: getLangsFromEnv }),

    medilog: createMedilogProjectConfig,

    pluscare: () => ({ app: PlusCareApp, createTheme: createDefaultTheme, getLangs: getLangsFromEnv }),

    icudemo: () => ({ app: IcuDemoApp, createTheme: createDefaultTheme, getLangs: getLangsFromEnv }),
};

function createDefaultTheme() {
    return createTheme({
        palette: {
            menuBar: { main: "#fff" },
            ...prescriptionColors,
        },
    });
}
