import { Button } from "@mui/material";
import { DATETIME_FORMAT } from "@profilog/misc/commons/dateDefaults";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

export default function SavedSection({ savedRecordDate, onNewRecordClick, hideText = false }) {
    const { t } = useTranslation();

    return (
        <div tw="flex flex-col items-center sm:items-start my-4">
            {!hideText && (
                <h2 tw="text-xl font-semibold mb-4 flex sm:flex-row">
                    {t("records.Edit.RecordSaved").replace("{0}", format(savedRecordDate, DATETIME_FORMAT))}
                </h2>
            )}
            <Button type="button" variant="contained" onClick={onNewRecordClick}>
                {t("records.Edit.AddNextRecord")}
            </Button>
        </div>
    );
}
