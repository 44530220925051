import csLocale from "date-fns/locale/cs";
import skLocale from "date-fns/locale/sk";
import enLocale from "date-fns/locale/en-US";
import ruLocale from "date-fns/locale/ru";

const fnsLocales = {
    cs: csLocale,
    sk: skLocale,
    en: enLocale,
    ru: ruLocale,
};

export default function getFnsLocale(langCode) {
    if (!fnsLocales.hasOwnProperty(langCode)) return fnsLocales["cs"];
    return fnsLocales[langCode];
}
