import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { apiPost } from "@profilog/misc/utils/useFetch";
import { getHostName } from "@profilog/misc/utils/Url";
import { Link } from "react-router-dom";

const EmailInvitationForm = ({ medicalSubjectId, isIncludeContactsCheckboxShown, onInvitationSent }) => {
    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        errors,
        formState: { isSubmitting },
    } = useForm();
    const [isContactCheckboxChecked, setIsContactCheckboxChecked] = useState(false);
    const [recipientEmailAddress, setRecipientEmailAddress] = useState("");
    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);

    const sendEmailInvitation = async (data) => {
        let emailInvitationData = {
            medicalSubjectId: medicalSubjectId,
            recipientEmailAddress: data.recipientEmail,
            isContactIncluded: data.isContactIncluded,
            isCopyForSenderRequired: data.isCopyForSenderChecked,
            baseUrl: getHostName(),
        };
        let result = await apiPost("/sharing/sendEmailInvitation", emailInvitationData);
        if (result.isOk) {
            onInvitationSent(result.json);
        }
    };

    const handleContactCheckboxChange = (e) => {
        setIsContactCheckboxChecked(e.target.checked);
    };

    const handleRecipientChange = (e) => {
        setRecipientEmailAddress(e.target.value);
        if (e.target.value.length === 0) setIsSendButtonDisabled(true);
        else setIsSendButtonDisabled(false);
    };

    return (
        <Fragment>
            <p>{t("sharing.EmailInvitationForm.Description")}</p>

            <form onSubmit={handleSubmit(sendEmailInvitation)}>
                <div className="row align-content-center no-gutters mt-3">
                    {isIncludeContactsCheckboxShown && (
                        <div className="form-group form-check col-12 pl-4">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="invitation_include_contacts_checkbox"
                                onChange={handleContactCheckboxChange}
                                name="isContactIncluded"
                                ref={register}
                            />
                            <label className="form-check-label" htmlFor="invitation_include_contacts_checkbox">
                                {t("sharing.EmailInvitationForm.IncludeContacts")}
                            </label>
                        </div>
                    )}

                    <div className="form-group form-check col-12 pl-4">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="copy_for_sender_checkbox"
                            name="isCopyForSenderChecked"
                            ref={register}
                        />
                        <label className="form-check-label" htmlFor="copy_for_sender_checkbox">
                            {t("sharing.EmailInvitationForm.CopyForSender")}
                        </label>
                    </div>
                </div>

                <p
                    dangerouslySetInnerHTML={{
                        __html: `${t("sharing.EmailInvitationForm.AccessOverview")}<br />${t(
                            "sharing.EmailInvitationForm.RevokeAccess",
                        )}`,
                    }}
                ></p>

                <div className="row mt-4 align-items-end no-gutters">
                    <div className="form-group col-12 col-sm-8">
                        <label htmlFor="invitation_recipient_email">{t("sharing.EmailInvitationForm.Whom")}</label>
                        <input
                            type="text"
                            id="invitation_recipient_email"
                            className="form-control"
                            placeholder="E-mail"
                            name="recipientEmail"
                            onChange={handleRecipientChange}
                            ref={register({ required: true, pattern: emailRegex })}
                        />
                    </div>
                    <div className="form-group col-12 col-sm-4">
                        <button
                            type="submit"
                            className="ml-0 ml-sm-2 btn btn-primary px-4 w-100"
                            disabled={isSendButtonDisabled || isSubmitting}
                        >
                            {t("sharing.EmailInvitationForm.Send")}
                        </button>
                    </div>
                    {errors.recipientEmail && (
                        <div className="validation-error">{t("global.Form.InvalidEmailPattern")}</div>
                    )}
                </div>
            </form>
            <div className="mt-3">
                <Link
                    to={`/in/records-review-preview?&medicalSubjectId=${
                        medicalSubjectId !== null ? medicalSubjectId : ""
                    }&recipientEmailAddress=${recipientEmailAddress}&isUserContactIncluded=${isContactCheckboxChecked}`}
                    target="_blank"
                >
                    {t("sharing.EmailInvitationForm.SeePreview")}
                </Link>
            </div>
        </Fragment>
    );
};

export default EmailInvitationForm;
