import { useTranslation } from "react-i18next";
import { Fragment, useState, useEffect } from "react";
import ResponsiveStack from "@profilog/commons/components/ResponsiveStack";
import { FormControl, FormHelperText, FormControlLabel, Checkbox } from "@mui/material";
import { getErrorMessage } from "@profilog/misc/errors/ValidationError";
import GdprTextDialog from "./GdprTextDialog";
import { useTheme } from "@mui/system";
import { useFetch } from "@profilog/misc/utils/useFetch";

export default function ConsentsForm({ systemNames, errors, register, className, customProjectCode }) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { apiGet, ApiErrors } = useFetch();
    const [consents, setConsents] = useState([]);

    useEffect(() => {
        async function getConsents() {
            let response = await apiGet("/web/consents?systemNames=" + systemNames.join("&systemNames="));
            if (response.isOk) {
                setConsents(response.json);
            }
        }

        getConsents();
    }, [i18n.language]); // eslint-disable-line

    return (
        <Fragment>
            <ApiErrors />
            {consents &&
                consents.map((item, index) => (
                    <ResponsiveStack key={index} className={className}>
                        <FormControl error={getErrorMessage(errors, item.systemName) && item.isRequired ? true : false}>
                            <FormControlLabel
                                name={item.systemName}
                                inputRef={register(
                                    item.isRequired ? { required: t("auth.Register.ConsentRequired") } : {},
                                )}
                                control={<Checkbox />}
                                label={
                                    <Fragment>
                                        {item.text}{" "}
                                        {item.gdprText && (
                                            <GdprTextDialog
                                                customProjectCode={customProjectCode}
                                                trigger={(open) => (
                                                    <span
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            open();
                                                        }}
                                                        tw="underline cursor-pointer"
                                                        style={{ color: theme.palette.primary.main }}
                                                    >
                                                        {item.gdprText}
                                                    </span>
                                                )}
                                            />
                                        )}
                                    </Fragment>
                                }
                            />
                            {item.isRequired && (
                                <FormHelperText>{getErrorMessage(errors, item.systemName)}</FormHelperText>
                            )}
                        </FormControl>
                    </ResponsiveStack>
                ))}
        </Fragment>
    );
}
