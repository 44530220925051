import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const portalRoot = document.getElementById("portal-root");
if (portalRoot == null) throw new Error("<div className='portal-root'> in index.html not found");

export default function Portal({ children }) {
    const modalRef = useRef(document.createElement("div"));

    useEffect(() => {
        const divElement = modalRef.current;

        portalRoot.appendChild(divElement);

        return () => {
            portalRoot.removeChild(divElement);
        };
    }, []);

    return ReactDOM.createPortal(children, modalRef.current);
}
