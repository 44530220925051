import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import CooperationRequestCard from "../../communication/components/CooperationRequestCard";
import { Button } from "@mui/material";

export default function CooperationRequestInitialization({ previous, next, existsPreviousStep }) {
    const { t } = useTranslation();
    const [wasRequestSent, setWasRequestSent] = useState(false);

    function onRequestSent() {
        setWasRequestSent(true);
    }

    function completeInitialization() {
        next();
    }

    return (
        <Fragment>
            <p>{t("userInitialization.CooperationRequestInitialization.Description")}</p>
            <div tw="mt-5">
                <CooperationRequestCard onRequestSent={onRequestSent} isMultipleSendAllowed={false} />
                <div tw="flex mt-5">
                    {/* {existsPreviousStep && (
                        <button type="button" className="btn btn-primary" onClick={previous}>
                            Zpět
                        </button>
                    )} */}
                    <Button
                        variant="contained"
                        color="success"
                        onClick={completeInitialization}
                        //disabled={!wasRequestSent}
                    >
                        {t("userInitialization.UserInitialization.Done")}
                    </Button>
                </div>
            </div>
        </Fragment>
    );
}
