// Zkopírováno z https://gist.github.com/atomiks/520f4b0c7b537202a23a3059d4eec908
import Tippy, { TippyProps } from "@tippyjs/react";
import { forwardRef, useState } from "react";

export interface LazyTippyProps extends TippyProps {
    usePortal?: boolean;
}

const LazyTippy = forwardRef((props: LazyTippyProps, ref: React.Ref<Element>) => {
    const [mounted, setMounted] = useState(false);

    const lazyPlugin = {
        fn: () => ({
            onMount: () => setMounted(true),
            onHidden: () => setMounted(false),
        }),
    };

    const computedProps = { ...props };
    const root = document.getElementById("tippy-root");
    if (props.usePortal && root) computedProps.appendTo = root;

    computedProps.plugins = [lazyPlugin, ...(props.plugins || [])];

    if (props.render) {
        computedProps.render = (...args) => (mounted && props.render ? props.render(...args) : "");
    } else {
        computedProps.content = mounted ? props.content : "";
    }

    return <Tippy {...computedProps} ref={ref} trigger={"mouseenter focus"} />;
});

export default LazyTippy;
