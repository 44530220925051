export function getQuery() {
    return new URLSearchParams(window.location.search);
}

export function getToken() {
    const query = getQuery();
    const token = query.get("token");
    return token;
}

export function getEmailAndToken() {
    const query = getQuery();
    const email = query.get("email");
    const token = query.get("token");
    return { email, token };
}

export function getUsernameAndToken() {
    const query = getQuery();
    const userName = query.get("userName");
    const token = query.get("token");
    return { userName, token };
}

export function getHostName() {
    return window.location.protocol + "//" + window.location.host;
}
