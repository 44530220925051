export function translateError(t, error, translationPrefix) {
    // Chyby s prefixem usr: rovnou vrátíme bez prefixu.
    if (error.startsWith("usr:")) return error.substring(4);

    // Klíče s prefixem tran: přeložíme.
    if (error.startsWith("tran:")) return t(error.substring(5));

    let translated = null;

    // Chybu zkusíme přeložit s předaným prefixem
    translated = translateUsingPrefix(t, error, translationPrefix);
    if (translated !== null) return translated;

    // Chybu zkusíme přeložit s výchozím prefixem "apiresult"
    translated = translateUsingPrefix(t, error, "apiResult");
    if (translated !== null) return translated;

    return error;
}

function translateUsingPrefix(t, error, prefix) {
    if (!prefix) return null;

    const key = prefix + "." + error;

    let translated = t(key);
    if (translated === key) return null;

    return translated;
}
