import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import AppSettings from "@profilog/front/app/AppSettings";
import MedicalSubjectSelector from "@profilog/front/commons/components/MedicalSubjectSelector";
import EmailInvitationForm from "./EmailInvitationForm";
import EmailInvitationSent from "./EmailInvitationSent";
import LinkGenerationForm from "./LinkGenerationForm";
import LinkGenerated from "./LinkGenerated";
import { apiGet } from "@profilog/misc/utils/useFetch";

const SharingCard = ({ allowEmail = true, onLinkShared }) => {
    const { t } = useTranslation();

    const [sharingData, setSharingData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isOpened, setIsOpened] = useState(true);
    const [wereInvitationSent, setWereInvitationSent] = useState(false);
    const [emailInvitationResult, setEmailInvitationResult] = useState(null);
    const [wereLinkGenerated, setWereLinkGenerated] = useState(false);
    const [link, setLink] = useState(null);
    const [selectedMedicalSubjectId, setSelectedMedicalSubjectId] = useState(AppSettings.user.userMedicalSubjectId);
    const [hasSelectedMedicalSubjectAnyContactSet, setHasSelectedMedicalSubjectAnyContactSet] = useState(false);

    useEffect(() => {
        async function getRelatedMedicalSubjects() {
            let response = await apiGet("/sharing/data");
            if (response.isOk) {
                setSharingData(response.json);
            } else {
            }
            setIsLoading(false);
        }
        getRelatedMedicalSubjects();
    }, []);

    useEffect(() => {
        if (!isLoading) setHasSelectedMedicalSubjectAnyContactSet(sharingData.hasCurrentUserAnyContactSet);
    }, [sharingData, isLoading]);

    function handleMedicalSubjectChanged(id) {
        id = parseInt(id);
        setSelectedMedicalSubjectId(id);
        if (id === AppSettings.user.userMedicalSubjectId) {
            setHasSelectedMedicalSubjectAnyContactSet(sharingData.hasCurrentUserAnyContactSet);
        } else {
            let medicalSubject = sharingData.persons.find((p) => p.medicalSubjectId === id);
            if (medicalSubject === undefined)
                medicalSubject = sharingData.animals.find((a) => a.medicalSubjectId === id);
            setHasSelectedMedicalSubjectAnyContactSet(medicalSubject.hasAnyContactInfoSet);
        }
    }

    function handleOnInvitationSent(result) {
        setEmailInvitationResult(result);
        setWereInvitationSent(true);
        onLinkShared();
    }

    function handleOnLinkGenerated(link) {
        setLink(link);
        setWereLinkGenerated(true);
        onLinkShared();
    }

    function handleNewShareRequest() {
        setLink(null);
        setWereLinkGenerated(false);
        setWereInvitationSent(false);
    }

    if (isLoading) return null;

    return (
        <div className="box">
            <div className="d-flex justify-content-between flex-wrap">
                <h2 className="box-title mr-2 mb-4">{t("sharing.SharingCard.SendRecords")}</h2>
                {isOpened && !wereInvitationSent && !wereLinkGenerated && (
                    <div className="text-black-25 mb-4 mb-sm-0">
                        <MedicalSubjectSelector
                            personMedicalSubjects={sharingData.persons}
                            animalMedicalSubjects={sharingData.animals}
                            currentUserMedicalSubjectId={AppSettings.user.userMedicalSubjectId}
                            onMedicalSubjectChanged={handleMedicalSubjectChanged}
                            selectedMedicalSubjectId={selectedMedicalSubjectId}
                        />
                    </div>
                )}
            </div>

            {isOpened ? (
                <Fragment>
                    {allowEmail && (
                        <ul className="nav nav-tabs" id="sharing-tabs" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active text-decoration-none"
                                    id="email-invitation-link"
                                    data-toggle="tab"
                                    href="#emailInvitation"
                                    role="tab"
                                    aria-controls="emailinvitation"
                                    aria-selected="true"
                                >
                                    {t("sharing.SharingCard.EmailInvitation")}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-decoration-none"
                                    id="generate-link-link"
                                    data-toggle="tab"
                                    href="#generateLink"
                                    role="tab"
                                    aria-controls="generatelink"
                                    aria-selected="false"
                                >
                                    {t("sharing.SharingCard.LinkGeneration")}
                                </a>
                            </li>
                        </ul>
                    )}

                    <div className="rounded bg-white min-h-330">
                        <div className="row">
                            <div className="col">
                                <div className="tab-content" id="sharingTabsContent">
                                    {allowEmail && (
                                        <div
                                            className="tab-pane fade show active"
                                            id="emailInvitation"
                                            role="tabpanel"
                                            aria-labelledby="email-invitation-link"
                                        >
                                            {wereInvitationSent ? (
                                                <EmailInvitationSent
                                                    emailInvitationResult={emailInvitationResult}
                                                    onNewShareRequested={handleNewShareRequest}
                                                />
                                            ) : (
                                                <EmailInvitationForm
                                                    medicalSubjectId={selectedMedicalSubjectId}
                                                    isIncludeContactsCheckboxShown={
                                                        hasSelectedMedicalSubjectAnyContactSet
                                                    }
                                                    onInvitationSent={handleOnInvitationSent}
                                                />
                                            )}
                                        </div>
                                    )}
                                    <div
                                        className={allowEmail ? "tab-pane fade" : null}
                                        id="generateLink"
                                        role="tabpanel"
                                        aria-labelledby="generate-link-link"
                                    >
                                        {wereLinkGenerated ? (
                                            <LinkGenerated link={link} onNewShareRequested={handleNewShareRequest} />
                                        ) : (
                                            <LinkGenerationForm
                                                medicalSubjectId={selectedMedicalSubjectId}
                                                isIncludeContactsCheckboxShown={hasSelectedMedicalSubjectAnyContactSet}
                                                onLinkGenerated={handleOnLinkGenerated}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <button className="btn btn-primary btn-sm" type="button" onClick={() => setIsOpened(true)}>
                    {t("sharing.SharingCard.ShowForm")}
                </button>
            )}

            {isOpened && (
                <a className="card__min" href="#close" onClick={() => setIsOpened(false)}>
                    {t("global.Close")}
                </a>
            )}
        </div>
    );
};

export default SharingCard;
