import { Fragment } from "react";
import { useState, useEffect } from "react";
import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    CommonSeriesSettings,
    Legend,
    Tooltip,
    Label,
    Tick,
    Size,
    Font,
    Animation,
    TickInterval,
    Border,
    VisualRange,
} from "devextreme-react/chart";
import { useTranslation } from "react-i18next";
import { format, intervalToDuration } from "date-fns";
import { useFetch } from "../../utils/useFetch";
import { CircularProgress } from "@mui/material";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";

export default function DailySleepChart({ medicalSubjectId, date }) {
    const { t, i18n } = useTranslation();
    const { apiPost } = useFetch();
    const [sleepData, setSleepData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function loadData() {
            setIsLoading(true);
            setSleepData();
            let dateFrom = new Date(date);
            dateFrom.setHours(12, 0, 0, 0);

            let dateTo = new Date(dateFrom);
            dateTo.setDate(dateTo.getDate() + 1);

            let parametresDto = [{ recordTypeName: "SleepSegment" }];

            let dto = {
                medicalSubjectId: medicalSubjectId,
                utcFrom: dateFrom.toISOString(),
                utcTo: dateTo.toISOString(),
                recordFilters: parametresDto,
                ignorePagination: true,
            };
            let response = await apiPost("/v2/medical-subjects/records/get-raw", dto);
            if (response.isOk) setSleepData(transformData(response.json));
            else setSleepData();
            setIsLoading(false);
        }
        loadData();
    }, [date]);

    function transformData(data) {
        let result = [];
        let values = [5, 4, 6, 1];
        let minDate = null;
        let maxDate = null;

        data.records.sort((a, b) => {
            let aDateFrom = new Date(a.utcStartAt);
            let bDateFrom = new Date(b.utcStartAt);

            if (aDateFrom < bDateFrom) return -1;
            if (aDateFrom > bDateFrom) return 1;

            let aDateTo = new Date(a.utcEndAt);
            let bDateTo = new Date(b.utcEndAt);

            if (aDateTo < bDateTo) return -1;
            if (aDateTo > bDateTo) return 1;
            return 0;
        });

        data.records.forEach((record) => {
            let value = record.fields[0].value;

            let dateFrom = new Date(record.utcStartAt);
            let dateTo = new Date(record.utcEndAt);

            if (minDate === null || minDate > dateFrom) minDate = dateFrom;
            if (maxDate === null || maxDate < dateTo) maxDate = dateTo;

            let start = { date: dateFrom };
            let end = { date: dateTo };
            for (let index = 1; index <= 4; index++) {
                let v = values[index - 1];
                start[index] = v === value ? index : null;
                end[index] = v === value ? index : null;
            }
            result.push(start, end);
        });

        return { data: result, range: { minDate, maxDate } };
    }

    function argumentAxisCustomizeLabelText(info) {
        if (info.max.getTime() === info.value.getTime()) return format(info.value, "HH:mm");
        if (info.min.getTime() === info.value.getTime()) return format(info.value, "HH:mm");
    }

    function valueAxisCustomizeLabelText(info) {
        switch (info.value) {
            case 1:
                return "Hlub.";
            case 2:
                return "Lehký";
            case 3:
                return "REM";
            case 4:
                return "Vzhůru";
            default:
                return "";
        }
    }

    function getTime(totalMinutes) {
        return `${Math.floor(totalMinutes / 60)}h ${totalMinutes % 60}m`;
    }

    function getMinutes(date, segment) {
        let startDate = date;
        let endDate = null;
        let prev;
        let isPrev = false;

        for (let index = 0; index < sleepData.data.length; index++) {
            let element = sleepData.data[index];

            if (element.date.getTime() === date.getTime() && element[segment] === segment) {
                if (prev && element.date.getTime() >= prev.date.getTime() && prev[segment] === segment) {
                    startDate = prev.date;
                    endDate = element.date;
                    isPrev = true;
                    break;
                }

                let next = sleepData.data[index + 1];

                if (next && element.date.getTime() <= next.date.getTime() && next[segment] === segment) {
                    endDate = next.date;
                    break;
                }
            }

            prev = element;
        }

        let res = intervalToDuration({
            start: startDate,
            end: endDate,
        });

        return (isPrev ? -1 : 1) * (res.days * 24 * 60 + res.hours * 60 + res.minutes);
    }

    function customizeTooltip(pointInfo) {
        let items = [];

        let minutes = getMinutes(pointInfo.argument, pointInfo.value);
        let end = new Date(pointInfo.argument);
        end.setMinutes(end.getMinutes() + minutes);

        let text;

        text = `${pointInfo.seriesName}`;
        let element = document.createElement("span");
        element.textContent = text;
        element.style.color = pointInfo.point.getColor();
        text = element.outerHTML;
        items.push(text);

        if (minutes < 0) text = format(end, "HH:mm") + " - " + format(pointInfo.argument, "HH:mm");
        else text = format(pointInfo.argument, "HH:mm") + " - " + format(end, "HH:mm");
        element = document.createElement("span");
        element.textContent = text;
        element.style.color = pointInfo.point.getColor();
        text = element.outerHTML;
        items.push(text);

        text = `${getTime(Math.abs(minutes))}`;
        element = document.createElement("span");
        element.textContent = text;
        element.style.color = pointInfo.point.getColor();
        text = element.outerHTML;
        items.push(text);

        return { text: items.join("\n") };
    }

    return (
        <Fragment>
            <div tw="w-full">
                {isLoading && (
                    <div tw="flex justify-center">
                        <CircularProgress />
                    </div>
                )}
                {sleepData && sleepData.data.length > 0 && (
                    <div tw="px-1.5 pb-2.5">
                        <div tw="pt-6 pb-4">
                            <div tw="my-1.5 flex-1 text-center">Spánek</div>
                            <div tw="flex flex-wrap justify-between items-center mt-1.5">
                                <div tw="pl-1.5">
                                    <BedtimeIcon />
                                    <span tw="pl-1 [vertical-align: text-top]">
                                        {format(sleepData.range.minDate, "HH:mm")}
                                    </span>
                                </div>
                                <div>
                                    <span tw="pr-1  [vertical-align: text-top]">
                                        {format(sleepData.range.maxDate, "HH:mm")}
                                    </span>
                                    <AccessAlarmsIcon />
                                </div>
                            </div>
                        </div>
                        <Chart dataSource={sleepData.data}>
                            <Size height={350} />
                            <Animation enabled={false} />
                            <Legend visible={true} verticalAlignment="bottom" horizontalAlignment="center" />
                            <ArgumentAxis argumentType="datetime">
                                <TickInterval minutes={1} />
                                <Label
                                    wordWrap="none"
                                    visible={false}
                                    //overlappingBehavior="none"
                                    //customizeText={argumentAxisCustomizeLabelText}
                                >
                                    <Font family="Montserrat, sans-serif" size={10} />
                                </Label>
                                <Tick visible={false} />
                            </ArgumentAxis>
                            <CommonSeriesSettings
                                argumentField="date"
                                type="area"
                                selectionMode="none"
                                hoverMode="none"
                            >
                                <Border visible={false} />
                            </CommonSeriesSettings>
                            <Series valueField="1" name="Hluboký" color="#004BA0" opacity="1" />
                            <Series valueField="2" name="Lehký" color="#1976D2" opacity="1" />
                            <Series valueField="3" name="REM" color="#AC06BC" opacity="1" />
                            <Series valueField="4" name="Vzhůru" color="#ED79D5" opacity="1" />
                            <ValueAxis visible={false}>
                                <Tick visible={false} />
                                <Label visible={true} customizeText={valueAxisCustomizeLabelText} indentFromAxis={2} />
                                <VisualRange startValue={0} endValue={4} />
                            </ValueAxis>
                            <Tooltip
                                enabled={true}
                                shared={true}
                                zIndex={100000000}
                                cornerRadius={5}
                                customizeTooltip={customizeTooltip}
                            />
                        </Chart>
                    </div>
                )}
            </div>
        </Fragment>
    );
}
