import { useSelector, useDispatch } from "react-redux";
import { prescriptionUpdate } from "../../redux/prescriptionSlice";
import { useGetParameters } from "../../redux/codebooksSlice";
import Prescription from "./Prescription";
import { useTranslation } from "react-i18next";
import CodebookComboBox from "../codebooks/CodebookComboBox";

export default function MeasurementPrescription({ prescriptionId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const parameters = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].parameters);
    const dateFrom = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].dateFrom);
    const allowMultiple = dateFrom != null;

    return (
        <Prescription prescriptionId={prescriptionId} title={t("prescriptions.Types.Record")}>
            <CodebookComboBox
                value={parameters}
                storeAsArray
                onChange={(value) => dispatch(prescriptionUpdate({ prescriptionId, updated: { parameters: value } }))}
                getOptionsHook={useGetParameters}
                limit={null}
                autocompleteProps={{
                    label: t("prescriptions.Edit.Parameters"),
                    autoFocus: true,
                    autoSelect: true,
                    autoHighlight: true,
                    size: "normal",
                    multiple: allowMultiple,
                    blurOnSelect: false,
                    placeholder: !allowMultiple ? t("prescriptions.Edit.Max1Param") : "",
                }}
                tw="min-width[400px]"
            />
        </Prescription>
    );
}
