import { ReactNode } from "react";
import tw from "twin.macro";

interface ResponsiveStackProps {
    className?: string;
    wrap?: boolean;
    reverse?: boolean;
    children: ReactNode;
}

export default function ResponsiveStack(props: ResponsiveStackProps) {
    return (
        <div
            tw="flex gap-x-2 gap-y-3"
            className={props.className}
            css={[
                props.wrap
                    ? tw`flex-col sm:(flex-row flex-wrap)`
                    : props.reverse
                    ? tw`flex-col-reverse sm:flex-row-reverse`
                    : tw`flex-col sm:flex-row`,
            ]}
        >
            {props.children}
        </div>
    );
}
