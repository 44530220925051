import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { copyTextToClipboard } from "@profilog/misc/utils/copyTextToClipboard";
import { Alert } from "@mui/material";

const LinkGenerated = ({ link, onNewShareRequested }) => {
    const { t } = useTranslation();
    const [isLinkCopied, setIsLinkCopied] = useState(false);

    function handleCopyLinkButtonClicked() {
        copyTextToClipboard("link_text_box");
        setIsLinkCopied(true);
    }

    return (
        <Fragment>
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("sharing.LinkGenerated.Description") }}></p>
            <div className="row mb-3 align-items-end no-gutters">
                <div className="input-group">
                    <input type="text" className="form-control" id="link_text_box" readOnly value={link.url} />
                    <div className="input-group-append">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-toggle="tooltip"
                            data-html="true"
                            title={t("sharing.LinkGenerated.CopyLinkToClipboard")}
                            onClick={handleCopyLinkButtonClicked}
                        >
                            <i className="fas fa-clipboard"></i>
                        </button>
                    </div>
                </div>
                {isLinkCopied && <Alert severity="success">{t("sharing.LinkGenerated.LinkCopiedToClipboard")}</Alert>}
            </div>
            <ul>
                <li
                    className="mb-3"
                    dangerouslySetInnerHTML={{
                        __html: t("sharing.LinkGenerated.MailTo").replace(
                            "{0}",
                            `target="_blank" href="mailto:${link.recipient}?subject=${t(
                                "sharing.LinkGenerated.EmailSubject",
                            )}&body=${t("sharing.LinkGenerated.EmailProposalBody")
                                .replaceAll("{0}", "%0D%0A")
                                .replace("{1}", link.url)}"`,
                        ),
                    }}
                ></li>
                <li
                    className="mb-3"
                    dangerouslySetInnerHTML={{
                        __html: t("sharing.LinkGenerated.SeePreview").replace(
                            "{0}",
                            `target="_blank" href="${process.env.PUBLIC_URL}/records-review/${link.identificator}"`,
                        ),
                    }}
                ></li>
                <li
                    className="mb-4"
                    dangerouslySetInnerHTML={{ __html: t("sharing.LinkGenerated.RevokeAccessSharingPage") }}
                ></li>
            </ul>
            <button type="button" className="btn btn-primary" onClick={onNewShareRequested}>
                {t("sharing.LinkGenerated.SendAnotherRecord")}
            </button>
        </Fragment>
    );
};

export default LinkGenerated;
