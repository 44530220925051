import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ChildCampImage from "../../../assets/images/illustration/i4.jpg";
import "./MedilogUseCases.scss";

export default function MedilogUseCases() {
    const { t } = useTranslation();
    return (
        <section className="pt-4">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="card pb-0 mb-4">
                            <div className="card-body">
                                <div className="py-4">
                                    <h4 className="text-primary">{t("useCases.Article4")}</h4>
                                    <img src={ChildCampImage} className="child-camp-image" alt="Child camp" />
                                    <span dangerouslySetInnerHTML={{ __html: t("useCases.Article4Perex") }} />
                                    <Link to="/child-camps">{t("useCases.MoreInfo")}</Link>
                                </div>
                            </div>
                        </div>

                        <div className="box">
                            <div className="py-4">
                                <h4 className="text-primary">{t("useCases.Article1")}</h4>
                                <span dangerouslySetInnerHTML={{ __html: t("useCases.Article1Desc") }} />
                            </div>

                            <div className="py-4">
                                <h4 className="text-primary">{t("useCases.Article2")}</h4>
                                <span dangerouslySetInnerHTML={{ __html: t("useCases.Article2Desc") }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
