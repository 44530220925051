import { useTranslation } from "react-i18next";
import ExternalConnectionSelection from "./ExternalConnectionSelection";
import CalorieTablesConnectionSelection from "./CalorieTablesConnectionSelection";
import AppSettings from "../../app/AppSettings";
import useExternalConnectionSteps from "../hooks/useExternalConnectionSteps";
import { Fragment } from "react";

export default function ExternalConnectionsInitialization({ projectSettings, next }) {
    const { t } = useTranslation();
    const { currentExternalConnectionStep, nextExternalConnectionStep, isAfterSuccessfulConnection } =
        useExternalConnectionSteps(next, projectSettings);

    if (!currentExternalConnectionStep) return null;

    if (isAfterSuccessfulConnection) return null;

    AppSettings.externalServices.caloricsTables = true;

    return (
        <Fragment>
            <div tw="mt-5">
                {currentExternalConnectionStep.name === "kt" && AppSettings.externalServices.caloricsTables ? (
                    <CalorieTablesConnectionSelection
                        title={currentExternalConnectionStep.title}
                        onCalorieTablesConnected={nextExternalConnectionStep}
                    />
                ) : (
                    <ExternalConnectionSelection
                        name={currentExternalConnectionStep.name}
                        title={currentExternalConnectionStep.title}
                        isGarminButtonVisible={currentExternalConnectionStep.isGarminButtonVisible}
                        isGoogleButtonVisible={currentExternalConnectionStep.isGoogleButtonVisible}
                        isRemoteGoogleFitButtonVisible={currentExternalConnectionStep.isRemoteGoogleFitButtonVisible}
                        isOneTouchButtonVisible={currentExternalConnectionStep.isOneTouchButtonVisible}
                        isWithingsButtonVisible={currentExternalConnectionStep.isWithingsButtonVisible}
                        isHuaweiButtonVisible={currentExternalConnectionStep.isHuaweiButtonVisible}
                        isFitbitButtonVisible={currentExternalConnectionStep.isFitbitButtonVisible}
                        isStravaButtonVisible={currentExternalConnectionStep.isStravaButtonVisible}
                        isAndroidButtonVisible={currentExternalConnectionStep.isAndroidButtonVisible}
                        isAppleButtonVisible={currentExternalConnectionStep.isAppleButtonVisible}
                        isDiabeticButtonVisible={currentExternalConnectionStep.isDiabeticButtonVisible}
                        onButtonClick={nextExternalConnectionStep}
                    />
                )}
            </div>
        </Fragment>
    );
}
