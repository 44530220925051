import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";

export default function FoodListView({ courseName, foodList, className, isHiddenHeader, isHiddenSumm }) {
    const { t } = useTranslation();

    function getDataContent(nutricValues) {
        return (
            <Fragment>
                <div className="popover-title">{t("caloricsTable.Ingredients")}</div>
                <div className="popover-content">
                    <strong>{t("caloricsTable.Proteins")}:</strong> {Math.round(nutricValues.proteins)} g
                    <br />
                    <strong>{t("caloricsTable.Carbs")}:</strong> {Math.round(nutricValues.carbs)} g
                    <br />
                    <strong>{t("caloricsTable.Fats")}:</strong> {Math.round(nutricValues.fats)} g
                    <br />
                    <strong>{t("caloricsTable.Fiber")}:</strong> {Math.round(nutricValues.fiber)} g
                </div>
            </Fragment>
        );
    }

    const getNutricValues = (index, nutricValues, energyUnit, className) => {
        return (
            <Fragment>
                <Popup
                    trigger={
                        <span className={className} data-tip data-for={"nutric-values" + index}>
                            {Math.round(nutricValues.energy)} {energyUnit}
                        </span>
                    }
                    position="left center"
                    on={["hover", "focus"]}
                    arrow={true}
                >
                    {getDataContent(nutricValues)}
                </Popup>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <table className={`table ${className}`} tw="w-full">
                <thead>
                    <Fragment>
                        {courseName && (
                            <tr>
                                <th colSpan="4" className="panel-body">
                                    {courseName}
                                </th>
                                <th className="text-align-right">
                                    {getNutricValues(
                                        courseName.length * -1,
                                        foodList.totalNutricValues,
                                        foodList.energyUnit,
                                        "badge",
                                    )}
                                </th>
                            </tr>
                        )}
                        {isHiddenHeader !== true && (
                            <tr>
                                <th>{t("caloricsTable.Time")}</th>
                                <th>{t("caloricsTable.Food")}</th>
                                <th>{t("caloricsTable.Quantity")}</th>
                                <th></th>
                                <th>{t("caloricsTable.Energy")}</th>
                            </tr>
                        )}
                    </Fragment>
                </thead>

                <tbody>
                    {foodList &&
                        foodList.foods.map((food, index) => (
                            <tr key={index} className={food.dishId % 2 == 0 ? "" : "main-dish"}>
                                <td>{food.time}</td>
                                <td>{food.name}</td>
                                <td className="text-align-right text-nowrap">{food.quantity}</td>
                                <td className="text-align-left text-nowrap">{food.unit}</td>
                                <td className="text-align-right text-nowrap">
                                    {getNutricValues(
                                        food.dishId * index + index,
                                        food.nutricValues,
                                        foodList.energyUnit,
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
                {isHiddenSumm !== true && (
                    <tfoot>
                        {foodList && foodList.foods.length > 0 && (
                            <tr>
                                <th colSpan="4">Celkem</th>
                                <td className="text-align-right">
                                    {getNutricValues(
                                        (courseName?.length ?? 1) * -10,
                                        foodList.totalNutricValues,
                                        foodList.energyUnit,
                                        "badge",
                                    )}
                                </td>
                            </tr>
                        )}
                    </tfoot>
                )}
            </table>
        </Fragment>
    );
}
