import { Fragment, useEffect } from "react";
import LocSwitcher from "@profilog/misc/lang/LocSwitcher";
import supportedLangs from "../../localization/SupportedLangs.json";
import { Link, Outlet } from "react-router-dom";
import addNavBarToggler from "@profilog/misc/utils/NavBarToggler";
import { useLocation } from "react-router-dom";
import "./GenericPublicLayout.scss";
import MagicWareFooter from "../../shared/components/MagicWareFooter";

export default function GenericPublicLayout({ hidePoweredBy = true, logo, renderFooter = null }) {
    const location = useLocation();

    useEffect(() => {
        addNavBarToggler();
    }, []);

    return (
        <Fragment>
            <header className={location.pathname === "/" ? "" : "bg-nonhome"}>
                <section className="header--home container">
                    <nav className="navbar navbar-expand-sm navbar-dark">
                        <Link className="navbar-brand" to="/">
                            {logo}
                        </Link>
                        <button
                            className="navbar-toggler d-lg-none"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapsibleNavId"
                            aria-controls="collapsibleNavId"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="fas fa-bars text-white"></i>
                            <i className="fas fa-times text-white"></i>
                        </button>
                        {!hidePoweredBy && <div className="powered-by">Powered by PROFI-LOG.net</div>}

                        <LocSwitcher supportedLangs={supportedLangs} />
                    </nav>
                </section>
            </header>
            <Outlet />
            {renderFooter ? renderFooter() : <MagicWareFooter />}
        </Fragment>
    );
}
