import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EnergyTypeSelector from "./EnergyTypeSelector";
import ApiErrorsList from "../../errors/ApiErrorsList";
import useEnergyType from "@profilog/user/hooks/useEnergyType";
import { Alert } from "@mui/material";

export default function EnergyTypeChange() {
    const { t } = useTranslation();
    const [energyType, setEnergyType] = useState();
    const { energyType: defaultenergyType, saveEnergyType, isSaved, apiErrors } = useEnergyType();

    useEffect(() => {
        if (defaultenergyType) setEnergyType(defaultenergyType);
    }, [defaultenergyType]);

    if (!energyType) return null;

    return (
        <div tw="sm:width[30ch]">
            <ApiErrorsList apiErrors={apiErrors} />

            <EnergyTypeSelector
                title={t("userSettings.Energy.Title")}
                selectedValue={energyType}
                onChange={saveEnergyType}
            />
            {isSaved && (
                <Alert severity="success" tw="mt-2">
                    {t("userSettings.Energy.IsSaved")}
                </Alert>
            )}
        </div>
    );
}
