import AppSettings, { initAppSettingsFromAppData } from "../../app/AppSettings";
import LoginCheck from "@profilog/misc/auth/components/LoginCheck";
import { apiPost } from "@profilog/misc/utils/useFetch";
import { useWindowResize } from "@profilog/utils/useWindowResize";

export default function MainApp({ layout }) {
    if (!AppSettings.isPatientPreview)
        // eslint-disable-next-line
        useWindowResize({
            confirmed: (windowWidth) => apiPost("/web/user/window-settings/", { windowWidth }),
            forceFirstUpdate: true,
        });

    return (
        <LoginCheck
            onAppDataFetched={(appData) => initAppSettingsFromAppData(appData)}
            isGdprConsentModalUsed={!AppSettings.isPatientPreview}
            hardRedirect
            isDev={AppSettings.isDev}
        >
            {layout}
        </LoginCheck>
    );
}
