import { prescriptionTypes, supportsAmount } from "../codebooks/prescriptionTypes";
import { scheduleTypes } from "../codebooks/scheduleTypes";
import { getDayTimeOption } from "../redux/fastTimeSchedule";
import { formatAmount } from "../utils/amount";
import { getDurationText, getIntervalText, getWeekDaysText } from "../utils/dates";
import _ from "lodash";

export function getPrescriptionDesc(prescription, t) {
    const desc = { title: "", duration: "", scheduleType: "", schedules: [] };

    prepareTitle(prescription, desc, t);

    let dateFrom = null;
    let dateTo = null;
    let days = null;

    if (prescription.dateFrom) dateFrom = new Date(prescription.dateFrom);
    if (prescription.dateTo) dateTo = new Date(prescription.dateTo);
    if (prescription.days) days = prescription.days;

    const { text, isDefault } = getDurationText(dateFrom, days, dateTo, t, prescription.$type);
    desc.duration = isDefault ? null : text;

    prepareSchedulesDescs(prescription, desc, t);

    return desc;
}

function prepareTitle(prescription, desc, t) {
    switch (prescription.$type) {
        case prescriptionTypes.drug:
            desc.title = prescription.drug?.name ?? prescription.text;
            break;
        case prescriptionTypes.action:
            desc.title = prescription.action?.name ?? prescription.text;
            break;
        case prescriptionTypes.measurement:
            let len = prescription.parameters?.length ?? 0;
            if (len > 0) {
                const paramLimit = 2;
                let params = prescription.parameters
                    .slice(0, paramLimit)
                    .map((p) => p.name)
                    .join(", ");
                if (len > paramLimit) params += ", ...";

                desc.title = t("prescriptions.Types.Measurement");
                if (len > paramLimit) desc.title += " " + t("prescriptions.Edit.xParams", { params: len });
                desc.subTitle = params;
            } else if (prescription.parameterGroup !== null)
                desc.title = t("prescriptions.Edit.FillIn") + " " + prescription.parameterGroup.name;
            break;
        case prescriptionTypes.survey:
            desc.title = prescription.parameterGroup?.name;
            break;
        default:
            desc.title = null;
            break;
    }
}

function prepareSchedulesDescs(prescription, desc, t) {
    desc.scheduleType = getScheduleType(prescription.schedules, t);
    if (desc.scheduleType === null) {
        desc.scheduleType = t("prescriptions.Edit.withoutPlan");
        return;
    }

    if (tryPrepareFastTimeSchedule(prescription.schedules, desc, t)) return;

    for (let schedule of prescription.schedules)
        desc.schedules.push(getScheduleDesc(schedule, supportsAmount(prescription.$type), t));

    return desc;
}

function getScheduleType(schedules, t) {
    if (!schedules || schedules.length === 0) return null;

    const uniqueTypes = _.chain(schedules)
        .map((s) => s.$type)
        .uniq()
        .value();

    if (uniqueTypes.length === 1) return t("prescriptions.Schedules.By" + uniqueTypes[0]).toLowerCase();
    else return t("prescriptions.Edit.misc");
}

function getScheduleDesc(schedule, supportsAmount, t) {
    let desc = "";

    if ((schedule.amount ?? 0) === 0 && (schedule.amountMax ?? 0) === 0) return desc;

    if (supportsAmount) {
        if (schedule.amount) desc += formatAmount(schedule.amount);

        if (schedule.amountMax) desc += " - " + formatAmount(schedule.amountMax);

        if (schedule.amountNote) desc += " " + schedule.amountNote;
    }

    switch (schedule.$type) {
        case scheduleTypes.time:
            if (schedule.time !== null) desc += " v " + schedule.time;
            else if (schedule.dayTime !== null) desc += " " + t("prescriptions.DayTimes." + schedule.dayTime);

            const interval = getIntervalAndWeekDays(schedule, t);
            if (interval) desc += " " + interval;
            break;

        case scheduleTypes.situation:
            let situationText = schedule.situation?.name ?? schedule.text;
            if (situationText) desc += ` v situaci "${situationText}"`;
            break;

        case scheduleTypes.paramValue:
            desc += ` je-li parametr "${schedule.parameter.name}"`;

            const isValueFrom = schedule.valueFrom !== null;
            const isValueTo = schedule.valueTo !== null;

            if (isValueFrom && isValueTo) desc += ` mezi ${schedule.valueFrom} a ${schedule.valueTo}`;
            else if (isValueFrom) desc += ` větší než ${schedule.valueFrom}`;
            else if (isValueTo) desc += ` menší než ${schedule.valueTo}`;
            break;

        default:
            break;
    }

    return desc;
}

function getIntervalAndWeekDays(schedule, t) {
    let desc = "";

    let intervalText = getIntervalText(schedule.interval, schedule.intervalUnit, t);
    if (!intervalText.isDefault || (schedule.time === null && schedule.dayTime == null))
        desc += " " + intervalText.text;

    let { text: weekDaysText } = getWeekDaysText(schedule.weekDays, null, t);
    if (weekDaysText !== null) desc += " " + weekDaysText;

    return desc;
}

function tryPrepareFastTimeSchedule(schedules, desc, t) {
    const fastTimeSchedule = getFastTimeSchedule(schedules, t);
    if (fastTimeSchedule === null) return false;
    else {
        desc.schedules.push(fastTimeSchedule);
        return true;
    }
}

function getFastTimeSchedule(schedules, t) {
    if (schedules.length === 0) return null;

    let firstSchedule = null;

    const amounts = [];

    for (let i = 0; i < schedules.length; i++) {
        const schedule = schedules[i];

        if (schedule.$type !== scheduleTypes.time) return null;

        if (schedule.amountMax > 0) return null;

        if (schedule.time !== null) return null;

        const dayTimeOption = getDayTimeOption(i, schedules.length);
        const dayTime = dayTimeOption != null ? dayTimeOption.value : null;
        if (schedule.dayTime !== dayTime) return null;

        if (firstSchedule === null) firstSchedule = schedule;
        else if (
            i > 0 &&
            (schedule.amountNote !== firstSchedule.amountNote ||
                schedule.interval !== firstSchedule.interval ||
                schedule.intervalUnit !== firstSchedule.intervalUnit)
        ) {
            return null;
        }

        amounts.push(formatAmount(schedule.amount));
    }

    let scheduleDesc = amounts.join("-");
    if (firstSchedule.amountNote) scheduleDesc += " " + firstSchedule.amountNote;
    const interval = getIntervalAndWeekDays(firstSchedule, t);
    if (interval) scheduleDesc += " " + interval;

    return scheduleDesc;
}
