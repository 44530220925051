import ModeCommentIcon from "@mui/icons-material/ModeComment";
import EmailIcon from "@mui/icons-material/Email";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

export const notificationTypes = {
    message: 0,
    comment: 1,
    cooperationRequestSent: 2,
    cooperationRequestAccepted: 3,
    cooperationRequestRejected: 4,
};

export function getNotificationTypeIcon(notificationType) {
    switch (notificationType) {
        case notificationTypes.comment:
            return ModeCommentIcon;
        case notificationTypes.message:
            return EmailIcon;
        case notificationTypes.cooperationRequestSent:
            return ThumbsUpDownIcon;
        case notificationTypes.cooperationRequestAccepted:
            return ThumbUpIcon;
        case notificationTypes.cooperationRequestRejected:
            return ThumbDownIcon;
        default:
            return CheckBoxOutlineBlankIcon;
    }
}
