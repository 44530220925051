import { Fragment } from "react";
import { useState, useEffect } from "react";
import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    CommonSeriesSettings,
    Legend,
    Tooltip,
    Label,
    Tick,
    Format,
    Size,
    Font,
    Animation,
    TickInterval,
    Border,
} from "devextreme-react/chart";
import { useTranslation } from "react-i18next";
import { format, intervalToDuration } from "date-fns";
import { useFetch } from "../../utils/useFetch";
import { CircularProgress } from "@mui/material";

export default function RangeChart({
    title,
    medicalSubjectId,
    dateFrom,
    dateTo,
    parameters,
    visualRange,
    decimalPoints,
}) {
    const { t, i18n } = useTranslation();
    const { apiPost } = useFetch();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function loadData() {
            setIsLoading(true);
            setData();

            let parametresDto = [];

            parameters.forEach((parameter) => {
                parametresDto.push({
                    recordTypeName: parameter.systemName,
                    bucketSize: { timeUnit: "Day", resolution: 1 },
                });
            });

            let dto = {
                medicalSubjectId: medicalSubjectId,
                utcFrom: dateFrom.toISOString(),
                utcTo: dateTo.toISOString(),
                destinationTimeZoneIanaName: Intl.DateTimeFormat().resolvedOptions().timeZone,
                recordGroups: parametresDto,
            };
            let response = await apiPost("/v2/medical-subjects/records/search", dto);
            if (response.isOk) setData(transformData(response.json));
            else setData();
            setIsLoading(false);
        }
        loadData();
    }, [medicalSubjectId, dateFrom, dateTo, parameters]);

    function transformData(data) {
        let result = [];

        let res = intervalToDuration({
            start: dateFrom,
            end: dateTo,
        });

        parameters.forEach((parameter) => {
            let recordGroup = data.recordGroups.find(
                (recordGroup) => recordGroup.recordTypeName === parameter.systemName,
            );
            for (let i = 0; i < res.days; i++) {
                let day = new Date(dateFrom);
                day.setDate(day.getDate() + i);
                let time = day.getTime();

                let bucket = recordGroup.bucketedRecords.buckets.find(
                    (bucket) => new Date(bucket.utcStartAt).getTime() === time,
                );

                let p = { date: day };
                if (parameter.valueType === "minmax") {
                    p[parameter.systemName + "-min"] = bucket ? round(bucket.aggregatedFields[0].min) : null;
                    p[parameter.systemName + "-max"] = bucket ? round(bucket.aggregatedFields[0].max) : null;
                } else {
                    p[parameter.systemName] = bucket ? round(bucket.aggregatedFields[0].avg) : null;
                }
                result.push(p);
            }
        });

        return result;
    }

    function round(value) {
        if (decimalPoints !== undefined) return parseFloat(value.toFixed(decimalPoints));
        else return value;
    }

    function argumentAxisCustomizeLabelText(info) {
        let str = info.valueText.split(" ");
        return str.shift().toUpperCase() + "\n" + str.join(" ");
    }

    function customizeTooltip(pointInfo) {
        let items = [];

        pointInfo.points.forEach((point) => {
            if (!point.seriesName.endsWith("(min - max)")) {
                let text = `${point.seriesName}: ${point.valueText}`;
                let element = document.createElement("span");
                element.textContent = text;
                //element.style.color = point.point.getColor();
                text = element.outerHTML;
                items.push(text);
            }
        });

        let dateElement = document.createElement("span");
        dateElement.style.paddingTop = 5;
        dateElement.textContent = format(pointInfo.argument, "dd.MM.yyyy");
        items.push(dateElement.outerHTML);

        return { text: items.join("\n") };
    }

    function getSeries() {
        let series = [];
        let key = 1;

        parameters.forEach((parameter) => {
            series.push(
                <Series
                    key={key++}
                    valueField={parameter.valueType === "minmax" ? undefined : parameter.systemName}
                    rangeValue1Field={parameter.valueType === "minmax" ? parameter.systemName + "-min" : undefined}
                    rangeValue2Field={parameter.valueType === "minmax" ? parameter.systemName + "-max" : undefined}
                    type={parameter.valueType === "minmax" ? "rangebar" : "line"}
                    barWidth={10}
                    color={parameter.color}
                    name={parameter.valueType === "minmax" ? `${parameter.name} (min - max)` : `${parameter.name} ø`}
                />,
            );

            if (parameter.valueType === "minmax") {
                series.push(
                    <Series
                        key={key++}
                        valueField={parameter.systemName + "-min"}
                        type="scatter"
                        point={{ size: 10, visible: true }}
                        color={parameter.minColor ?? parameter.color}
                        name={`${parameter.name} min`}
                    />,
                    <Series
                        key={key++}
                        valueField={parameter.systemName + "-max"}
                        type="scatter"
                        point={{ size: 10, visible: true }}
                        color={parameter.maxColor ?? parameter.color}
                        name={`${parameter.name} max`}
                    />,
                );
            }
        });

        return series;
    }

    return (
        <Fragment>
            <div tw="w-full">
                {isLoading && (
                    <div tw="flex justify-center">
                        <CircularProgress />
                    </div>
                )}
                {data && data.length > 0 && (
                    <div tw="px-1.5 pb-2.5">
                        <div tw="pt-6">
                            <div tw="my-1.5 flex-1 text-center">{title}</div>
                        </div>
                        <Chart dataSource={data}>
                            <Size height={250} />
                            <Animation enabled={false} />
                            <Legend
                                visible={false}
                                verticalAlignment="top"
                                horizontalAlignment="right"
                                hoverMode="none"
                            />
                            <ArgumentAxis argumentType="date">
                                <TickInterval days={1} />
                                <Label
                                    wordWrap="none"
                                    visible={true}
                                    overlappingBehavior="none"
                                    customizeText={argumentAxisCustomizeLabelText}
                                >
                                    <Font family="Montserrat, sans-serif" size={10} />
                                    <Format
                                        formatter={(value) =>
                                            value.toLocaleDateString(i18n.language, {
                                                month: "short",
                                                day: "numeric",
                                                weekday: "short",
                                            })
                                        }
                                    />
                                </Label>
                                <Tick visible={false} />
                            </ArgumentAxis>
                            <CommonSeriesSettings argumentField="date" selectionMode="none" hoverMode="none">
                                <Border visible={false} />
                            </CommonSeriesSettings>
                            {getSeries()}
                            <ValueAxis visible={false} tickInterval={25}>
                                <Tick visible={false} />
                                <Label visible={true} indentFromAxis={2} />
                                {visualRange}
                            </ValueAxis>
                            <Tooltip
                                enabled={true}
                                shared={true}
                                zIndex={100000000}
                                cornerRadius={5}
                                customizeTooltip={customizeTooltip}
                            />
                        </Chart>
                    </div>
                )}
            </div>
        </Fragment>
    );
}
