import { Fragment, useEffect, useState } from "react";
import CaloricsTableView from "./CaloricsTableView";
import { CircularProgress } from "@mui/material";
import Fade from "@mui/material/Fade";

export default function CaloricsTable({ fetchService, selectedDateTime }) {
    const [caloricsTable, setCaloricsTable] = useState();
    const { getCaloricsTableAsync, GetApiErrors } = fetchService;
    const [isLoaded, setIsLoaded] = useState();

    useEffect(() => {
        const getCaloricsTable = async () => {
            if (!selectedDateTime || selectedDateTime == null) return;
            let result = await getCaloricsTableAsync(selectedDateTime);
            if (result.isOk) setCaloricsTable(result.json);
            setIsLoaded(true);
        };
        setIsLoaded(false);
        getCaloricsTable();
    }, [selectedDateTime]);

    if (!isLoaded)
        return (
            <Fade
                in={isLoaded}
                style={{
                    transitionDelay: isLoaded ? "1000ms" : "0ms",
                }}
                unmountOnExit
            >
                <CircularProgress />
            </Fade>
        );

    return (
        <Fragment>
            <GetApiErrors />
            {caloricsTable && <CaloricsTableView caloricsTable={caloricsTable} />}
        </Fragment>
    );
}
