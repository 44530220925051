import { Button } from "@mui/material";
import AppSettings from "../../../app/AppSettings";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import MedicalSubjectSelector from "../../../commons/components/MedicalSubjectSelector";

export default function RecordEditHeader({
    hideTitle,
    isHidden,
    setIsHidden,
    hasVisibleParameters,
    medicalSubjects,
    handleMedicalSubjectChanged,
    selectedMedicalSubjectId,
}) {
    const { t } = useTranslation();

    if (hideTitle) return null;

    return (
        <Fragment>
            {AppSettings.isPatientPreview && (
                <Button color="secondary" tw="float-right" onClick={() => setIsHidden((isHidden) => !isHidden)}>
                    {isHidden ? t("records.Edit.Show") : t("records.Edit.Hide")}
                </Button>
            )}

            <h2 tw="text-xl mb-4 flex flex-row sm:items-center">
                <span>
                    {hasVisibleParameters ? t("records.Edit.NewRecord") : t("records.Edit.NewUserHelper.Welcome")}
                </span>
                <div tw="text-black mt-1 mr-4 sm:mt-0 ml-auto">
                    <MedicalSubjectSelector
                        personMedicalSubjects={medicalSubjects.personSubjects}
                        animalMedicalSubjects={medicalSubjects.animalSubjects}
                        currentUserMedicalSubjectId={AppSettings.user.userMedicalSubjectId}
                        onMedicalSubjectChanged={handleMedicalSubjectChanged}
                        selectedMedicalSubjectId={selectedMedicalSubjectId}
                    />
                </div>
            </h2>
        </Fragment>
    );
}
