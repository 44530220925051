import { Fragment, useState } from "react";
import { useRoutes, BrowserRouter, RouteObject } from "react-router-dom";
import CurrentUserContext from "../../auth/CurrentUserContext";
import CurrentNotificationContext, { Notification } from "../../notifications/CurrentNotificationContext";
import EnvInfo from "./EnvInfo";
import ApiStatusCheck from "./ApiStatusCheck";
import ErrorBoundary from "./ErrorBoundary";
import { UserData } from "../../auth/UserData";

export default function App({ routes, basename }: { routes: RouteObject[]; basename?: string }) {
    return (
        <BrowserRouter basename={basename}>
            <BrowserRouterApp routes={routes} />
        </BrowserRouter>
    );
}

function BrowserRouterApp({ routes }: { routes: RouteObject[] }) {
    const [currentUserData, setCurrentUserData] = useState<UserData | null>(null);
    const [isUserInitialized, setIsUserInitialized] = useState<boolean | null>(null);
    const [currentNotification, setCurrentNotification] = useState<Notification>({
        notification: null,
    });
    return (
        <Fragment>
            <ApiStatusCheck>
                <EnvInfo />
                <ErrorBoundary>
                    <CurrentUserContext.Provider
                        value={{
                            currentUserData,
                            setCurrentUserData,
                            isUserInitialized,
                            setIsUserInitialized,
                        }}
                    >
                        <CurrentNotificationContext.Provider value={{ currentNotification, setCurrentNotification }}>
                            {useRoutes(routes)}
                        </CurrentNotificationContext.Provider>
                    </CurrentUserContext.Provider>
                </ErrorBoundary>
            </ApiStatusCheck>
        </Fragment>
    );
}
