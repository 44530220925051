import { generateId } from "@profilog/utils/generateId";
import { intervalUnits } from "../codebooks/intervalUnits";
import { scheduleTypes } from "../codebooks/scheduleTypes";

export function newSchedule(prescriptionId, type, initProps) {
    let schedule = {
        $type: type,
        id: generateId(),
        prescriptionId,
        amount: 1,
        amountMax: null,
        amountNote: null,
        tolerance: null,
        severity: 0,
    };
    switch (type) {
        case scheduleTypes.time:
            schedule.time = null;
            schedule.dayTime = null;
            schedule.interval = 1;
            schedule.intervalUnit = intervalUnits.days;
            schedule.weekDays = null;
            break;
        case scheduleTypes.situation:
            schedule.situation = null;
            schedule.text = null;
            break;
        case scheduleTypes.paramValue:
            schedule.parameter = null;
            schedule.valueFrom = null;
            schedule.valueTo = null;
            schedule.valueDuration = null;
            break;
        default:
            break;
    }

    if (initProps) Object.assign(schedule, initProps);

    return schedule;
}
