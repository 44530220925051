import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "@profilog/misc/utils/useFetch";
import { useForm, Controller, FieldValues, SubmitHandler, Control, FieldErrors, FieldName } from "react-hook-form";
import {
    Alert,
    Button,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormLabel,
    Typography,
    Select,
    MenuItem,
    InputLabel,
} from "@mui/material";
import ResponsiveStack from "@profilog/commons/components/ResponsiveStack";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import getFnsLocale from "@profilog/misc/lang/FnsLocales";

export default function VoicebotAssistantSettings({ medicalSubjectId }: { medicalSubjectId: number }) {
    const { t } = useTranslation();
    const { apiGet, apiPut, ApiErrors } = useFetch();
    const [isChanged, setIsChanged] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [toggleReload, setToggleReload] = useState(false);
    const [settings, setSettings] = useState<AssistatSettings | undefined>();

    const {
        register,
        handleSubmit,
        errors,
        formState: { isSubmitting },
        control,
    } = useForm<AssistatSettingsFieldValues>();

    useEffect(() => {
        async function fetchDataAsync() {
            const response = await apiGet(`/voicebot/AssistantSettings?medicalSubjectId=${medicalSubjectId}`);
            if (response.isOk) {
                setSettings(response.json as AssistatSettings);
            }
        }

        fetchDataAsync();
    }, [toggleReload]);

    if (!settings) return null;

    const submitHandler: SubmitHandler<AssistatSettingsFieldValues> = async (data) => {
        const assistantSettingsDto: AssistatSettings = {
            medicalSubjectId: medicalSubjectId,
            isVoicebotAssistantEnabled: data.isVoicebotAssistantEnabled,
            daysWith1000StepsIn2Days: data.daysWith1000StepsIn2Days,
            preferredTime1ForCalling: data.preferredTime1ForCalling,
            preferredTime2ForCalling: data.preferredTime2ForCalling,
            preferredTime3ForCalling: data.preferredTime3ForCalling,
            preferredTime4ForCalling: data.preferredTime4ForCalling,
            minutesToNextCallAttemp: data.minutesToNextCallAttemp,
            maxAttemptsToCallAgain: data.maxAttemptsToCallAgain,
        };

        let response = await apiPut("/voicebot/AssistantSettings", assistantSettingsDto);
        if (response.isOk) {
            setIsSaved(true);
            setIsChanged(false);
            setToggleReload((o) => !o);
        }
    };

    const onChange = () => {
        setIsChanged(true);
        setIsSaved(false);
    };

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <ApiErrors />
            <div>
                <Typography variant="h6" component="h6">
                    Hlasový asistent
                </Typography>
                <FormControlLabel
                    label="Povolit hlasového asistenta"
                    control={
                        <Checkbox
                            inputRef={register}
                            inputProps={{ name: "isVoicebotAssistantEnabled" }}
                            defaultChecked={settings.isVoicebotAssistantEnabled}
                        />
                    }
                    onChange={onChange}
                />
                <div tw="pb-2">
                    <FormLabel htmlFor="edit-patient-voicebot-assistant-calling">
                        Preferované orientační časy volání hlasového asistenta
                    </FormLabel>
                </div>
                <div tw="flex flex-wrap gap-3">
                    <PreferredTimeForCalling
                        property="preferredTime1ForCalling"
                        label="1. čas"
                        defaultValue={settings.preferredTime1ForCalling}
                        control={control}
                        errors={errors}
                        onChange={onChange}
                    />
                    <PreferredTimeForCalling
                        property="preferredTime2ForCalling"
                        label="2. čas"
                        defaultValue={settings.preferredTime2ForCalling}
                        control={control}
                        errors={errors}
                        onChange={onChange}
                    />
                    <PreferredTimeForCalling
                        property="preferredTime3ForCalling"
                        label="3. čas"
                        defaultValue={settings.preferredTime3ForCalling}
                        control={control}
                        errors={errors}
                        onChange={onChange}
                    />
                    <PreferredTimeForCalling
                        property="preferredTime4ForCalling"
                        label="4. čas"
                        defaultValue={settings.preferredTime4ForCalling}
                        control={control}
                        errors={errors}
                        onChange={onChange}
                    />
                    <FormControl tw="w-36">
                        <InputLabel id="max-attempts-to-call-again-label" title={"Opakované pokusy"}>
                            {"Opakované pokusy"}
                        </InputLabel>
                        <Controller
                            control={control}
                            defaultValue={settings.maxAttemptsToCallAgain}
                            name="maxAttemptsToCallAgain"
                            render={(field) => (
                                <Select
                                    sx={{ textAlign: "center" }}
                                    labelId="max-attempts-to-call-again-label"
                                    id="max-attempts-to-call-again-id"
                                    label={"Opakované pokusy"}
                                    value={field.value}
                                    ref={field.ref}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        onChange();
                                    }}
                                >
                                    <MenuItem key={0} value={0} sx={{ justifyContent: "center" }}>
                                        {"Žádné"}
                                    </MenuItem>
                                    {[1, 2, 3, 4, 5].map((i) => (
                                        <MenuItem key={i} value={i} sx={{ justifyContent: "center" }}>
                                            {i}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                    <FormControl tw="w-36">
                        <InputLabel id="minutes-to-next-call-attemp-label" title={"Další pokus za"}>
                            {"Další pokus za"}
                        </InputLabel>
                        <Controller
                            control={control}
                            defaultValue={settings.minutesToNextCallAttemp}
                            name="minutesToNextCallAttemp"
                            render={(field) => (
                                <Select
                                    sx={{ textAlign: "center" }}
                                    labelId="minutes-to-next-call-attemp-label"
                                    id="minutes-to-next-call-attemp-id"
                                    label={"Další pokus za"}
                                    value={field.value}
                                    ref={field.ref}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        onChange();
                                    }}
                                >
                                    {[5, 15, 30, 60, 90].map((i) => (
                                        <MenuItem key={i} value={i} sx={{ justifyContent: "center" }}>
                                            {i} {"m"}
                                        </MenuItem>
                                    ))}
                                    {[2, 3, 4].map((i) => (
                                        <MenuItem key={i} value={i * 60} sx={{ justifyContent: "center" }}>
                                            {i} {"h"}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                </div>
            </div>
            <ResponsiveStack reverse>
                <Button
                    type="submit"
                    tw="mt-4"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || !isChanged}
                >
                    {t("global.Save")}
                </Button>
                {isSaved && (
                    <Alert severity="success" tw="mt-2">
                        {t("userSettings.Steps.IsSaved")}
                    </Alert>
                )}
            </ResponsiveStack>
        </form>
    );
}

function getTimeStringFromDate(date?: Date | null) {
    if (!date) return null;
    let twoDigits = date.getMinutes() > 9;
    return `${date.getHours()}:${twoDigits ? "" : "0"}${date.getMinutes()}`;
}

function getDateFromTimeString(time?: string) {
    if (!time) return null;
    const minutes = time.split(":")[1];
    const hours = time.split(":")[0];
    let date = new Date();
    date.setMinutes(parseInt(minutes));
    date.setHours(parseInt(hours));
    return date;
}

function PreferredTimeForCalling({
    property,
    label,
    defaultValue,
    control,
    errors,
    onChange,
}: PreferredTimeForCallingProps) {
    const { t, i18n } = useTranslation();

    return (
        <FormControl>
            <div tw="w-40">
                <Controller
                    rules={{
                        validate: (data) => !data || !data.includes("NaN"),
                    }}
                    defaultValue={defaultValue}
                    name={property}
                    control={control}
                    render={(field) => (
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={getFnsLocale((i18n as any).lang)}
                        >
                            <TimePicker
                                views={["hours", "minutes"]}
                                label={label}
                                value={getDateFromTimeString(field.value)}
                                ampm={false}
                                ref={field.ref}
                                onChange={(date) => {
                                    field.onChange(getTimeStringFromDate(date));
                                    onChange();
                                }}
                            />
                        </LocalizationProvider>
                    )}
                />
                {errors[property] && <div className="validation-error">Neplatný formát</div>}
            </div>
        </FormControl>
    );
}

interface PreferredTimeForCallingProps {
    property: FieldName<AssistatSettingsFieldValues>;
    label: React.ReactNode;
    defaultValue?: string | null;
    control: Control<AssistatSettingsFieldValues>;
    errors: FieldErrors<AssistatSettingsFieldValues>;
    onChange: () => void;
}

interface AssistatSettings extends AssistatSettingsFieldValues {
    medicalSubjectId: number;
}

interface AssistatSettingsFieldValues extends FieldValues {
    isVoicebotAssistantEnabled: boolean;
    preferredTime1ForCalling?: string | null;
    preferredTime2ForCalling?: string | null;
    preferredTime3ForCalling?: string | null;
    preferredTime4ForCalling?: string | null;
    minutesToNextCallAttemp: number;
    maxAttemptsToCallAgain: number;
}
