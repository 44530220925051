import { useFetch } from "@profilog/misc/utils/useFetch";
import { Fragment, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CircularProgress, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import { DATETIME_FORMAT } from "@profilog/misc/commons/dateDefaults";
import SurveyDetail from "./SurveyDetail";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

export default function SurveyList({ medicalSubjectId }) {
    const { t, i18n } = useTranslation();
    const { apiGet, ApiErrors } = useFetch();
    const [data, setData] = useState(null);
    const [selectedRecord, setSelectedRecord] = useState(null);

    useEffect(() => {
        async function getData() {
            const response = await apiGet(`/web/medical-subjects/${medicalSubjectId}/surveys`);
            if (response.isOk) setData(response.json);
        }

        getData();
    }, [i18n.language]); // eslint-disable-line

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Fragment>
            <div>
                <ApiErrors />

                {data === null && (
                    <div tw="text-center">
                        <CircularProgress />
                    </div>
                )}

                {data && data.length > 0 && (
                    <Table sx={{ width: "auto" }}>
                        <TableBody>
                            {data.map((item) => (
                                <TableRow key={item.recordId}>
                                    <TableCell>{format(new Date(item.recordDate), DATETIME_FORMAT)}</TableCell>
                                    <TableCell>{item.surveyName}</TableCell>
                                    <TableCell>
                                        <IconButton color="primary" onClick={() => setSelectedRecord(item)}>
                                            <PageviewOutlinedIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                {data && data.length === 0 && <em>{t("surveys.listEmpty")}</em>}
            </div>

            <Dialog
                open={selectedRecord !== null}
                onClose={handleDialogClose}
                maxWidth="xl"
                fullWidth
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    {t("surveys.filledAt")}{" "}
                    {selectedRecord && format(new Date(selectedRecord.recordDate), DATETIME_FORMAT)}
                    <IconButton
                        color="primary"
                        onClick={handleDialogClose}
                        sx={{
                            position: "absolute",
                            right: 4,
                            top: 4,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <SurveyDetail
                        medicalSubjectId={medicalSubjectId}
                        recordId={selectedRecord?.recordId}
                        surveySystemName={selectedRecord?.surveySystemName}
                    />
                </DialogContent>
            </Dialog>
        </Fragment>
    );

    function handleDialogClose() {
        setSelectedRecord(null);
    }
}
