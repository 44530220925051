import { Controller } from "react-hook-form";
import { FormControl, InputLabel, Select } from "@mui/material";

export default function RhfMuiSelect({
    variant,
    label,
    name,
    defaultValue,
    rules,
    errors,
    control,
    children,
    className,
    SelectProps,
}) {
    return (
        <FormControl variant={variant} className={className}>
            <InputLabel>{label}</InputLabel>
            <Controller
                control={control}
                error={errors[name] && true}
                defaultValue={defaultValue}
                name={name}
                rules={rules}
                as={
                    <Select label={label} {...SelectProps}>
                        {children}
                    </Select>
                }
            />
        </FormControl>
    );
}
