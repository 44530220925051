import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "@profilog/misc/commons/components/DatePicker";
import usePrescriptionNotificationSettings from "../hooks/usePrescriptionNotificationSettings";
import {
    Alert,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import ResponsiveStack from "@profilog/commons/components/ResponsiveStack";

export default function PrescriptionNotificationSettings() {
    const { t } = useTranslation();
    const [isChanged, setIsChanged] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const {
        prescriptionNotificationSettings,
        setPrescriptionNotificationSettings,
        isLoading,
        dataPrefixes,
        setNotificationType,
        createDataKey,
        notificationTypeFlags,
        saveSettingsAsync,
        refreshSettingsAsync,
        ApiGetErrors,
        ApiSaveErrors,
    } = usePrescriptionNotificationSettings(onSaveSettings);

    const allNotificationTypes = Object.keys(notificationTypeFlags);

    return (
        <div>
            <ApiGetErrors />
            <ApiSaveErrors />
            {!isLoading && (
                <Fragment>
                    <Alert severity="info" tw="mb-2">
                        {t("userSettings.PrescriptionNotifications.AllowNotifications")}
                    </Alert>
                    <div tw="overflow-x-auto">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">
                                        <Tooltip title={t("userSettings.PrescriptionNotifications.Collumns.Web")}>
                                            <NotificationsIcon />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip title={t("userSettings.PrescriptionNotifications.Collumns.Email")}>
                                            <EmailIcon />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip title={t("userSettings.PrescriptionNotifications.Collumns.MobileApp")}>
                                            <PhoneAndroidIcon />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {t("userSettings.PrescriptionNotifications.Rows.DailySummary")}
                                    </TableCell>
                                    <NotificationTypes
                                        notificationTypes={allNotificationTypes}
                                        dataPrefix={dataPrefixes.dailySummary}
                                        data={prescriptionNotificationSettings.dailySummary}
                                    />
                                    <TableCell>
                                        <DatePicker
                                            selected={getDateFromTimeString(
                                                prescriptionNotificationSettings.dailySummaryTime,
                                            )}
                                            onChange={(date) => onTimeChanged(date, "dailySummaryTime")}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption={t("userSettings.PrescriptionNotifications.TimeSpec.SendIn")}
                                            customInput={
                                                <TextField
                                                    tw="[width: 10ch]"
                                                    label={t("userSettings.PrescriptionNotifications.TimeSpec.SendIn")}
                                                />
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {t("userSettings.PrescriptionNotifications.Rows.InTimePrescription")}
                                    </TableCell>
                                    <NotificationTypes
                                        notificationTypes={allNotificationTypes}
                                        dataPrefix={dataPrefixes.inTime}
                                        data={prescriptionNotificationSettings.inTime}
                                    />
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {t("userSettings.PrescriptionNotifications.Rows.InCaseOfDelay")}
                                    </TableCell>
                                    <NotificationTypes
                                        notificationTypes={allNotificationTypes}
                                        dataPrefix={dataPrefixes.after}
                                        data={prescriptionNotificationSettings.after}
                                    />
                                    <TableCell>
                                        <DatePicker
                                            selected={getDateFromTimeString(prescriptionNotificationSettings.afterTime)}
                                            onChange={(date) => onTimeChanged(date, "afterTime")}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption={t("userSettings.PrescriptionNotifications.TimeSpec.SendAfter")}
                                            customInput={
                                                <TextField
                                                    tw="[width: 10ch]"
                                                    label={t(
                                                        "userSettings.PrescriptionNotifications.TimeSpec.SendAfter",
                                                    )}
                                                />
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>

                    <div>
                        <h4>{t("userSettings.PrescriptionNotifications.DayTimeSettings.Title")}</h4>
                        <div tw="flex flex-wrap gap-3">
                            <DayTimeSettingsRow
                                dayTimeProp={"morningTime"}
                                dayTimeDesc={t("userSettings.PrescriptionNotifications.DayTimeSettings.Morning")}
                            />
                            <DayTimeSettingsRow
                                dayTimeProp={"noonTime"}
                                dayTimeDesc={t("userSettings.PrescriptionNotifications.DayTimeSettings.Noon")}
                            />
                            <DayTimeSettingsRow
                                dayTimeProp={"eveningTime"}
                                dayTimeDesc={t("userSettings.PrescriptionNotifications.DayTimeSettings.Evening")}
                            />
                            <DayTimeSettingsRow
                                dayTimeProp={"nightTime"}
                                dayTimeDesc={t("userSettings.PrescriptionNotifications.DayTimeSettings.Night")}
                            />
                            <DayTimeSettingsRow
                                dayTimeProp={"noTime"}
                                dayTimeDesc={t("userSettings.PrescriptionNotifications.DayTimeSettings.NoTime")}
                            />
                        </div>
                    </div>

                    <ResponsiveStack reverse>
                        <Button variant="contained" tw="mt-4" onClick={() => save()} disabled={!isChanged}>
                            {t("global.Save")}
                        </Button>
                        {isSaved && (
                            <Alert severity="success" tw="mt-2">
                                {t("userSettings.Steps.IsSaved")}
                            </Alert>
                        )}
                    </ResponsiveStack>
                </Fragment>
            )}
        </div>
    );

    function DayTimeSettingsRow({ dayTimeProp, dayTimeDesc }) {
        return (
            <div>
                <DatePicker
                    selected={getDateFromTimeString(prescriptionNotificationSettings.dayTimeSettings[dayTimeProp])}
                    onChange={(date) => onTimeChanged(date, "dayTimeSettings", dayTimeProp)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption={dayTimeDesc}
                    customInput={<TextField label={dayTimeDesc} tw="[width: 10ch]" />}
                />
            </div>
        );
    }

    function NotificationTypes({ data, dataPrefix, notificationTypes }) {
        return notificationTypes.map((nt) => (
            <TableCell key={createDataKey(dataPrefix, nt)} align="center">
                <Checkbox
                    id={createDataKey(dataPrefix, nt)}
                    name={createDataKey(dataPrefix, nt)}
                    checked={data & notificationTypeFlags[nt]}
                    onChange={(e) =>
                        onNotificationTypeChanged(dataPrefix, notificationTypeFlags[nt], e.target.checked === true)
                    }
                />
            </TableCell>
        ));
    }

    async function save(data) {
        await saveSettingsAsync(data);
    }

    function onSaveSettings() {
        refreshSettingsAsync();
        setIsChanged(false);
        setIsSaved(true);
    }

    function getTimeStringFromDate(date) {
        if (!date) return "00:00";
        let twoDigits = date.getMinutes() > 9;
        return `${date.getHours()}:${twoDigits ? "" : "0"}${date.getMinutes()}`;
    }

    function getDateFromTimeString(time) {
        if (!time) return null;
        const minutes = time.split(":")[1];
        const hours = time.split(":")[0];
        let date = new Date();
        date.setMinutes(minutes);
        date.setHours(hours);
        return date;
    }

    function onSettingsChanged() {
        setIsChanged(true);
        setIsSaved(false);
    }

    function onNotificationTypeChanged(dataPrefix, notificationTypeFlag, isChecked) {
        setNotificationType(dataPrefix, notificationTypeFlag, isChecked);
        onSettingsChanged();
    }

    function onTimeChanged(date, timeProperty, timeProperty2) {
        setPrescriptionNotificationSettings((os) => {
            let ns = { ...os };
            if (timeProperty2) {
                ns[timeProperty] = { ...ns[timeProperty] };
                ns[timeProperty][timeProperty2] = getTimeStringFromDate(date);
            } else ns[timeProperty] = getTimeStringFromDate(date);
            return ns;
        });

        onSettingsChanged();
    }
}
