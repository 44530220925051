import { UserData } from "./UserData";
import React from "react";

interface CurrentUserContextType {
    currentUserData: UserData | null;
    setCurrentUserData: (userData: UserData | null) => void;
    isUserInitialized: boolean | null;
    setIsUserInitialized: (isUserInitialized: boolean) => void;
}

const CurrentUserContext = React.createContext<CurrentUserContextType>({
    currentUserData: null,
    setCurrentUserData: () => {},
    isUserInitialized: null,
    setIsUserInitialized: () => {},
});

export default CurrentUserContext;
