export const weekDays = {
    1: "Monday",
    2: "Tuesday",
    4: "Wednesday",
    8: "Thursday",
    16: "Friday",
    32: "Saturday",
    64: "Sunday",
};

export const weekends = 32 | 64;
export const workweek = 1 | 2 | 4 | 8 | 16;
export const wholeWeek = weekends | workweek;
