import { useTranslation } from "react-i18next";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";

export default function ComboBox({
    options,
    value,
    onChange,
    limit = 15,
    loading,
    autocompleteProps,
    storeAsArray,
    className,
}) {
    const { t } = useTranslation();

    const defaultProps = {
        label: undefined,
        size: "small",
        multiple: false,
        freeSolo: false,
        autoSelect: false,
        blurOnSelect: false,
        loadingText: undefined,
        autoHighlight: false,
        selectOnFocus: false,
        noOptionsText: t("global.NoOptions"),
    };

    const props = defaultProps;
    if (autocompleteProps) Object.assign(props, autocompleteProps);

    const filterOptions = createFilterOptions({
        limit: limit,
        trim: true,
    });

    console.log(storeAsArray);
    if (props.freeSolo) props.onInputChange = handleInputChange;
    else props.onChange = (_, newValue) => onChange(!props.multiple && storeAsArray ? [newValue] : newValue);

    if (!props.multiple && storeAsArray && value?.length > 0) value = value[0];

    return (
        <Autocomplete
            {...props}
            options={options !== null ? options : []}
            loading={loading}
            filterOptions={filterOptions}
            value={value ? (Array.isArray(value) ? value : value.label) : null}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderOption={(props, option) => (
                <li {...props} key={option.value}>
                    {option.label}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    InputLabelProps={{ shrink: true }}
                    placeholder={props.placeholder}
                    autoFocus={props.autoFocus}
                />
            )}
            className={className}
        />
    );

    function handleInputChange(_, newValue) {
        if (options === null) return;

        let option = findOption(options, newValue);
        onChange(option);
    }

    function findOption(options, value) {
        if (typeof value === "string") {
            let trimmedValue = value.trim();
            if (trimmedValue === "") return null;

            let lowerValue = trimmedValue.toLowerCase();

            let option = options.find((o) => o.label.toLowerCase() === lowerValue);
            if (option) return option;

            if (!props.freeSolo) return null;

            return { value: value, label: value, __isNew__: true };
        } else return value;
    }
}
