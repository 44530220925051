import App from "@profilog/misc/commons/components/App";
import GenericPublicLayout from "./GenericPublicLayout";
import SharedPublicPages from "../../shared/SharedPublicPages";
import AuthPublicPages from "@profilog/misc/auth/AuthPublicPages";
import GenericHome from "./pages/GenericHome";
import NotFound from "../../NotFound";
import { Outlet } from "react-router-dom";
import GenericMainLayout from "./GenericMainLayout";
import GenericRecordsLayout from "./GenericRecordsLayout";
import CpzRegistration from "@profilog/front/registration/pages/Registration";
import SuccessfullyRegistered from "@profilog/front/registration/pages/SuccessfullyRegistered";
import CooperationRequestAcceptance from "@profilog/front/communication/pages/CooperationRequestAcceptance";
import CooperationRequestRejection from "@profilog/front/communication/pages/CooperationRequestRejection";
import CpzRegistrationWithInvitation from "@profilog/front/registration/pages/RegistrationWithInvitation";
import { getLogoutOnlyPages, getMainAppPages } from "../../main/getMainAppPages";

export default function GenericApp({
    logo,
    home = null,
    hidePoweredBy,
    disableRegistration = false,
    additionalPublicRoutes = [],
    additionalMainRoutes = [],
    recordsLayout = null,
    renderFooter = null,
}) {
    if (recordsLayout == null) recordsLayout = GenericRecordsLayout;

    const routes = [
        {
            element: <GenericPublicLayout logo={logo} hidePoweredBy={hidePoweredBy} renderFooter={renderFooter} />,
            children: [
                { index: true, element: home ?? <GenericHome disableRegistration={disableRegistration} /> },
                {
                    element: <GenericPublicSection />,
                    children: [
                        ...SharedPublicPages,
                        ...AuthPublicPages,
                        { path: "registration", element: <CpzRegistration /> },
                        { path: "code", element: <CpzRegistrationWithInvitation /> },
                        { path: "code/:invitationCodeParam", element: <CpzRegistrationWithInvitation /> },
                        { path: "successfully-registered", element: <SuccessfullyRegistered /> },
                        { path: "cooperation-request-acceptance", element: <CooperationRequestAcceptance /> },
                        { path: "cooperation-request-rejection", element: <CooperationRequestRejection /> },
                        { path: "*", element: <NotFound /> },
                    ],
                },
            ],
        },
        ...additionalPublicRoutes,
        ...getMainAppPages(
            <GenericMainLayout logo={logo} renderFooter={renderFooter} />,
            recordsLayout,
            additionalMainRoutes,
        ),
        ...getLogoutOnlyPages(<GenericMainLayout logo={logo} renderFooter={renderFooter} logoutOnly />),
        {
            path: "mobile-app",
            element: <MobileLayout />,
            children: [
                { path: "registration", element: <CpzRegistration /> },
                { path: "code", element: <CpzRegistrationWithInvitation /> },
                ...getMainAppPages(<EmptyLayout />, recordsLayout),
            ],
        },
    ];
    return <App routes={routes} basename={process.env.PUBLIC_URL} />;
}

function GenericPublicSection() {
    return (
        <section className="mt-3">
            <div className="container">
                <div className="col-12">
                    <Outlet />
                </div>
            </div>
        </section>
    );
}

function EmptyLayout() {
    return <Outlet />;
}

function MobileLayout() {
    return (
        <div className="mobile">
            <Outlet />
        </div>
    );
}
