import { useEffect, useState } from "react";
import tw from "twin.macro";
import { useTranslation } from "react-i18next";
import { DataGrid, GridFeatureModeConstant } from "@mui/x-data-grid";
import { Button, IconButton } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SyncIcon from "@mui/icons-material/Sync";
import CircularProgress from "@mui/material/CircularProgress";

const ChartGroupDiv = tw.div`bg-white shadow-updown rounded-md mb-12`;

export default function ActivitiesGrid({ fetchService }) {
    const { i18n, t } = useTranslation();
    const { getLastActivitiesAsync, GetApiErrors } = fetchService;
    const [activities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState();
    const [cols, setCols] = useState(getColumns());
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [totalRows, setTotalRows] = useState(1);

    useEffect(() => {
        const getActivites = async () => {
            loadData();
        };

        getActivites();
    }, [isDataLoaded, page]);

    useEffect(() => {
        setCols(getColumns());
    }, [i18n.language]);

    return (
        <div tw="pb-4">
            <ChartGroupDiv>
                <div tw="shadow-updown px-5 py-2.5 space-y-2">
                    <GetApiErrors />
                    <div tw="flex items-center gap-x-4 gap-y-2">
                        <div tw="w-1/4">
                            <Button
                                onClick={onCollapseBttnClick}
                                endIcon={isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                                tw="normal-case font-semibold"
                            >
                                {t("activitiesVisualization.Activities")}
                            </Button>
                        </div>
                        <div tw="grow">center</div>
                        <div tw="w-1/4 text-right">
                            <IconButton color="primary" onClick={handleRefreshButtonClick}>
                                {isLoading && <CircularProgress size={22} />}
                                {!isLoading && <SyncIcon />}
                            </IconButton>
                        </div>
                    </div>
                    <div className={isCollapsed ? "collapse" : "chart-group__body"}>
                        {isLoading && <div>Loading&hellip;</div>}
                        {isDataLoaded && activities && (
                            <DataGrid
                                autoHeight={true}
                                rows={activities}
                                loading={isLoading}
                                pageSizeOptions={[5, 10, 25]}
                                pagination={true}
                                page={page}
                                pageSize={pageSize}
                                rowCount={totalRows}
                                columns={cols}
                                rowsPerPageOptions={[pageSize]}
                                disableColumnMenu={true}
                                checkboxSelection={false}
                                isRowSelectable={() => false}
                                paginationMode={GridFeatureModeConstant.server}
                                onPageChange={handlePageChange}
                            />
                        )}
                    </div>
                </div>
            </ChartGroupDiv>
        </div>
    );

    async function loadData() {
        setIsLoading(true);
        var result = await getLastActivitiesAsync(pageSize, page + 1);
        if (result.isOk) {
            const activities = result.json.activities;
            const paging = result.json.paging;
            console.log("paging", paging);
            activities.map(
                (activity, index) => (activity.index = getActivityIndex(paging.page, paging.pageSize, index)),
            );
            setActivities(activities);
            setPage(paging.page - 1);
            setPageSize(paging.pageSize);
            setTotalRows(paging.totalCount);
            setIsLoading(false);
            setIsDataLoaded(true);
            setCols(getColumns());
        }
    }

    async function handleRefreshButtonClick() {
        await loadData();
    }

    function handlePageChange(newPage) {
        setPage(newPage);
    }

    function onCollapseBttnClick() {
        setIsCollapsed(!isCollapsed);
    }

    function getColumns() {
        const dateFormatter = new Intl.DateTimeFormat(i18n.language, { dateStyle: "medium" });
        const timeFormatter = new Intl.DateTimeFormat(i18n.language, { timeStyle: "medium" });
        return [
            {
                field: "index",
                headerName: "",
                sortable: false,
                type: "number",
                width: 100,
                editable: false,
            },
            {
                field: "start",
                headerName: t("activitiesVisualization.Columns.Date"),
                sortable: false,
                type: "dateTime",
                editable: false,
                align: "right",
                headerAlign: "right",
                valueGetter: (params) => {
                    const d = addMinutes(new Date(params.value), params.row.offsetFromUtcInMinutes);
                    return dateFormatter.format(d);
                },
            },
            {
                field: "time",
                headerName: t("activitiesVisualization.Columns.Time"),
                sortable: false,
                editable: false,
                align: "right",
                headerAlign: "right",
                valueGetter: (params) => {
                    const d = addMinutes(new Date(params.row.start), params.row.offsetFromUtcInMinutes);
                    return timeFormatter.format(d);
                },
            },
            {
                field: "duration",
                headerName: t("activitiesVisualization.Columns.Duration"),
                sortable: false,
                type: "number",
                editable: false,
                align: "right",
                headerAlign: "right",
                valueGetter: (params) => {
                    const duration = new Duration(params.value);
                    return duration.toString();
                },
            },
            {
                field: "source",
                headerName: t("activitiesVisualization.Columns.Source"),
                sortable: false,
                editable: false,
                align: "center",
                headerAlign: "center",
            },
            {
                field: "typeName",
                headerName: t("activitiesVisualization.Columns.Type"),
                sortable: false,
                description: "This column has a value getter and is not sortable.",
                align: "center",
                headerAlign: "center",
            },
        ];
    }
}

function getActivityIndex(page, pageSize, index) {
    return (page - 1) * pageSize + index + 1;
}

function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
}

class Duration {
    constructor(totalSeconds) {
        this.totalSeconds = totalSeconds;
        this.seconds = totalSeconds % 60;
        this.minutes = Math.floor(totalSeconds / 60) % 60;
        this.hours = Math.floor(totalSeconds / 3600);
    }

    toString() {
        const secs = this.seconds.toString().padStart(2, "0");
        const mins = this.minutes.toString().padStart(2, "0");
        return `${this.hours}:${mins}:${secs}`;
    }

    static fromSeconds(totalSeconds) {
        return new Duration(totalSeconds);
    }
}
