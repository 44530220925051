import { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import { useFetch } from "../../../utils/useFetch";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../commons/components/DatePicker";
import { getErrorMessage } from "../../../errors/ValidationError";
import { RadioGroup, Button, FormControl, FormControlLabel, Radio, TextField, Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import CircularProgressMidParent from "@profilog/commons/components/CircularProgressMidParent";

const NewMessage = ({
    medicalSubjectId,
    groupId,
    isProfi,
    onSave,
    fixedDate,
    isComment,
    setIsComment,
    hideCommentMessageChoice,
    isForAnswer,
    isPatientPreview,
    profilogUserId,
}) => {
    const messagePatter = /^\s*\S+.*/;
    const { t } = useTranslation();
    const { apiPost, apiGet, ApiErrors } = useFetch();
    const {
        register,
        handleSubmit,
        reset,
        errors,
        formState: { isSubmitting },
        control,
    } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [recordDate, setRecordDate] = useState(null);
    const [recordTime, setRecordTime] = useState(null);
    const [validateRecordDate, setValidateRecordDate] = useState(false);
    const [groups, setGroups] = useState([]);
    const [isGroupSelectionRequired, setIsGroupSelectionRequired] = useState(true); //TODO: udělat nějaké natavení někde

    useEffect(() => {
        async function loadDataAsync() {
            if (!isForAnswer && (!isComment || !hideCommentMessageChoice)) {
                await getGroupsAsync();
            }

            setIsLoading(false);
        }

        loadDataAsync();
        // eslint-disable-next-line
    }, []);

    async function getGroupsAsync() {
        const params = createGroupParams();

        const response = await apiGet(
            `/${isProfi ? "profilog/patientswithgroups/publicgroups" : "web/groups/public/names"}?` +
                new URLSearchParams(params),
        );
        if (response.isOk) {
            setGroups(response.json);
        }
    }

    function createGroupParams() {
        const params = {};

        if (!isProfi) {
            if (medicalSubjectId) params.medicalSubjectId = medicalSubjectId;
            else params.medicalSubjectId = -1;
        } else {
            if (medicalSubjectId) params.medicalSubjectId = medicalSubjectId;
            if (groupId) params.groupId = groupId;
        }

        return params;
    }

    const saveNewMessage = async (data) => {
        let date = getRecordDate();
        if (isComment && !date) {
            setValidateRecordDate(true);
            return;
        }

        const newMessageDto = {
            date: date,
            text: data.newMessage,
            groupId: getGroupId(data),
            accessibility: "Public",
            medicalSubjectId: getMedicalSubjectId(data),
            profilogUserId: isPatientPreview && profilogUserId ? profilogUserId : null,
        };

        const response = await apiPost("/web/messages", newMessageDto);
        if (response.isOk) {
            reset();
            if (onSave) onSave();
        }
    };

    function handleSetRecordDate(date) {
        setValidateRecordDate(false);
        setRecordDate(date);
    }

    function getGroupId(data) {
        if (isComment) return null;
        if (groupId) return groupId;

        if (!data.group || !data.group.id) return null;

        if (typeof data.group.id === "string") return parseInt(data.group.id.split("|")[0]);
        else return data.group.id;
    }

    function getMedicalSubjectId(data) {
        if (medicalSubjectId) return medicalSubjectId;

        return data.group && data.group.id ? parseInt(data.group.id.split("|")[1]) : null;
    }

    function getRecordDate() {
        if (isComment == null || isComment === false) return null;
        if (fixedDate != null) return fixedDate;

        let finalRecordDate = null;

        if (recordDate !== null) {
            finalRecordDate = recordDate;
            if (recordTime !== null) {
                let time = recordTime.split(":");
                finalRecordDate.setHours(time[0]);
                finalRecordDate.setMinutes(time[1]);
            }
        }

        return finalRecordDate;
    }

    function resetNewMessage() {
        setRecordDate(null);
        setRecordTime(null);
    }

    const handleCommentMessageClick = (e) => {
        setValidateRecordDate(false);
        setIsComment(e.target.value === "1");
    };

    function createProfiSuggestionItem(g) {
        return {
            id: `${g.groupId}|${g.medicalSubjectId}`,
            label: `${g.personFullName ? g.personFullName : `(id ${g.medicalSubjectId})`} - ${
                g.groupId ? " " + g.groupName : ""
            } (${g.projectShortName})`,
        };
    }

    function createPatientSuggestionItem(g) {
        return { id: g.groupId, label: g.groupName };
    }

    if (isLoading) return <CircularProgressMidParent />;

    let defaultGroup = null;
    if (isGroupSelectionRequired && groups.length === 1)
        defaultGroup = isProfi ? createProfiSuggestionItem(groups[0]) : createPatientSuggestionItem(groups[0]);

    return (
        <Fragment>
            <div className={`new-message ${!isForAnswer ? "is-modal" : ""}`}>
                <ApiErrors />
                <form onSubmit={handleSubmit(saveNewMessage)} onReset={resetNewMessage}>
                    <div tw="mb-4">
                        {!isForAnswer && (
                            <div tw="mb-2">
                                {!hideCommentMessageChoice && (
                                    <Fragment>
                                        <FormControl>
                                            <RadioGroup defaultValue={isComment ? 1 : 0} row>
                                                <FormControlLabel
                                                    value="0"
                                                    control={<Radio size="small" />}
                                                    label={t("shCommunications.Messages.New.Message")}
                                                    onClick={handleCommentMessageClick}
                                                />
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio size="small" />}
                                                    label={t("shCommunications.Messages.New.Comment")}
                                                    onClick={handleCommentMessageClick}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Fragment>
                                )}
                            </div>
                        )}
                        <TextField
                            size="small"
                            fullWidth
                            multiline
                            minRows="2"
                            maxRows="10"
                            name="newMessage"
                            inputRef={register({
                                required: t("global.RequiredField"),
                                pattern: messagePatter,
                            })}
                            placeholder={
                                isForAnswer
                                    ? t("shCommunications.Messages.New.WriteTheTextOfTheAnswer")
                                    : t("shCommunications.Messages.New.WriteTheText")
                            }
                            label={
                                isComment
                                    ? t("shCommunications.Messages.New.Comment")
                                    : t("shCommunications.Messages.New.Message")
                            }
                            InputLabelProps={{ shrink: true }}
                            error={getErrorMessage(errors, "newMessage") ? true : false}
                            helperText={getErrorMessage(errors, "newMessage")}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: (theme) =>
                                            isPatientPreview ? "#2b64af" : theme.palette.primary.main,
                                    },
                                    "&.Mui-focused.Mui-error fieldset": {
                                        borderColor: (theme) =>
                                            isPatientPreview ? "#d32f2f" : theme.palette.error.main,
                                    },
                                },
                                "& label.Mui-focused": {
                                    color: (theme) => (isPatientPreview ? "#2b64af" : theme.palette.primary.main),
                                },
                                "& label.Mui-focused.Mui-error": {
                                    color: (theme) => (isPatientPreview ? "#d32f2f" : theme.palette.error.main),
                                },
                            }}
                        />
                    </div>
                    <div tw="flex">
                        {fixedDate == null && isComment && (
                            <Fragment>
                                <div tw="flex z-10">
                                    <div tw="mr-2">
                                        <DatePicker
                                            selected={recordDate}
                                            customInput={
                                                <TextField
                                                    size="small"
                                                    label={t("shCommunications.Messages.New.Date")}
                                                    tw="width[12ch]"
                                                />
                                            }
                                            onChange={(date) => handleSetRecordDate(date)}
                                        />
                                    </div>
                                    <TextField
                                        size="small"
                                        type="time"
                                        onChange={(e) => setRecordTime(e.target.value)}
                                    />
                                </div>
                                {!recordDate && validateRecordDate && (
                                    <div className="validation-error">{t("global.RequiredField")}</div>
                                )}
                            </Fragment>
                        )}
                        {!isComment &&
                            !isForAnswer &&
                            !groupId &&
                            (isProfi ? (
                                <div tw="flex-1">
                                    {(groups.length > 0 || isGroupSelectionRequired) && (
                                        <Controller
                                            name="group"
                                            control={control}
                                            rules={
                                                (medicalSubjectId && !isGroupSelectionRequired) || isComment
                                                    ? {}
                                                    : {
                                                          required: t("global.RequiredField"),
                                                      }
                                            }
                                            defaultValue={defaultGroup}
                                            render={(props) => (
                                                <Autocomplete
                                                    size="small"
                                                    blurOnSelect
                                                    autoHighlight
                                                    autoSelect
                                                    noOptionsText={t("global.NoOptions")}
                                                    options={groups.map((g) => createProfiSuggestionItem(g))}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    onChange={(_, data) => props.onChange(data)}
                                                    defaultValue={defaultGroup}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.id}>
                                                                {option.label}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(props) => (
                                                        <TextField
                                                            {...props}
                                                            label={t(
                                                                "shCommunications.Messages.New.PatientAndTherapistsOfHisGroups",
                                                            )}
                                                            InputLabelProps={{ shrink: true }}
                                                            placeholder={
                                                                medicalSubjectId && !isGroupSelectionRequired
                                                                    ? t(
                                                                          "shCommunications.Messages.New.PatientAndTherapistsFromAllItsAllGroups",
                                                                      )
                                                                    : t(
                                                                          "shCommunications.Messages.New.SelectAPatientAndGroup",
                                                                      )
                                                            }
                                                            error={getErrorMessage(errors, "group") ? true : false}
                                                            helperText={getErrorMessage(errors, "group")}
                                                            tw="md:w-8/12"
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    )}
                                </div>
                            ) : (
                                <div tw="flex-1">
                                    <Controller
                                        name="group"
                                        control={control}
                                        rules={
                                            isGroupSelectionRequired && !isComment
                                                ? {
                                                      required: t("global.RequiredField"),
                                                  }
                                                : {}
                                        }
                                        defaultValue={defaultGroup}
                                        render={(props) => (
                                            <Autocomplete
                                                size="small"
                                                blurOnSelect
                                                autoHighlight
                                                autoSelect
                                                noOptionsText={t("global.NoOptions")}
                                                options={groups.map((g) => createPatientSuggestionItem(g))}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                onChange={(_, data) => props.onChange(data)}
                                                defaultValue={defaultGroup}
                                                renderInput={(props) => (
                                                    <TextField
                                                        {...props}
                                                        label={t("shCommunications.Messages.New.Therapist")}
                                                        InputLabelProps={{ shrink: true }}
                                                        placeholder={
                                                            groups.length === 0
                                                                ? t("shCommunications.Messages.New.NoGroup")
                                                                : isGroupSelectionRequired
                                                                ? t("shCommunications.Messages.New.ChooseATherapist")
                                                                : t("shCommunications.Messages.New.AllMyTherapists")
                                                        }
                                                        error={getErrorMessage(errors, "group") ? true : false}
                                                        helperText={getErrorMessage(errors, "group")}
                                                    />
                                                )}
                                                tw="md:w-8/12"
                                            />
                                        )}
                                    />

                                    {!isProfi && (
                                        <small tw="text-gray-400">{t("shCommunications.Messages.New.Info")}</small>
                                    )}
                                </div>
                            ))}
                        <div tw="w-auto ml-auto">
                            {!isForAnswer && ((!fixedDate && isComment) || (!groupId && !isComment)) && (
                                <label>&nbsp;</label>
                            )}

                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: (theme) =>
                                        isPatientPreview ? "#2b64af" : theme.palette.primary.main,
                                    color: (theme) => (isPatientPreview ? "#fff" : theme.palette.primary.contrastText),
                                    transition: (theme) =>
                                        theme.transitions.create(["background-color"], { duration: 140 }),
                                    "&:hover": {
                                        backgroundColor: (theme) =>
                                            isPatientPreview ? "#1e467a" : theme.palette.primary.dark,
                                    },
                                }}
                                disabled={isSubmitting}
                            >
                                {t("shCommunications.Messages.New.Insert")}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default NewMessage;
