import { useTranslation } from "react-i18next";
import ExtConnector from "./ExtConnector";
import useCalorieTables from "../hooks//useCalorieTables";
import CaloricsTablesConnectionDialog from "./CaloricsTablesConnectionDialog";
import { Fragment, useState } from "react";
import { LinearProgress } from "@mui/material";
import { isConnected } from "../codebooks/externalConnectionStatus";

export default function CaloricsTablesConnection({ readonly, Layout }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const {
        isLoading,
        caloricsTableConnectionInfo,
        connect,
        connectErrors,
        disconnect,
        redirectToRegistrationPage,
        error,
        ApiErrors,
    } = useCalorieTables(onConnected);

    function onConnected() {
        handleClose();
    }

    if (isLoading) return <LinearProgress />;

    return (
        <Fragment>
            {open && (
                <CaloricsTablesConnectionDialog
                    open={open}
                    onClose={handleClose}
                    connect={connect}
                    connectErrors={connectErrors}
                    ApiErrors={ApiErrors}
                />
            )}

            <ExtConnector
                infoText={t("extConnectors.CaloricsTables.InfoText")}
                title={t("extConnectors.CaloricsTables.Title")}
                connected={isConnected(caloricsTableConnectionInfo?.status)}
                account={caloricsTableConnectionInfo?.email}
                connectButtonLabel={t("extConnectors.CaloricsTables.ConnectButtonLabel")}
                disconnectButtonLabel={t("extConnectors.CaloricsTables.DisconnectButtonLabel")}
                registerButtonLabel={t("extConnectors.CaloricsTables.RegisterButtonLabel")}
                lastImportDate={caloricsTableConnectionInfo?.lastImportDate}
                status={caloricsTableConnectionInfo?.status}
                onConnectClick={() => setOpen(true)}
                onDisconnectClick={disconnect}
                onRegisterClick={() => redirectToRegistrationPage("/in/settings")}
                customError={error}
                ApiErrors={ApiErrors}
                readonly={readonly}
                Layout={Layout}
            />
        </Fragment>
    );

    function handleClose() {
        setOpen(false);
    }
}
