import AppSettings from "@profilog/front/app/AppSettings";
import DrugCalendar from "@profilog/prescriptions/components/calendar/DrugCalendar";
import "twin.macro";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";

export default function Drugs() {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div tw="flex items-center">
                <div tw="font-bold">{t("icudemo.Medication")}</div>
                <div tw="ml-auto">{t("icudemo.adherence")} 72%</div>
            </div>
            <div tw="text-xs">
                <Link to="../prescriptions">{t("icudemo.AddChangePrescription")}</Link>
            </div>
            <DrugCalendar medicalSubjectId={AppSettings.user.userMedicalSubjectId} shortList />
        </Fragment>
    );
}
