import { useState, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "@profilog/misc/utils/useFetch";
import { Dialog, DialogContent, DialogActions, DialogTitle, TextField, Button } from "@mui/material";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";

export default function CustomParameterDialog({ trigger, onParameterCreated, medicalSubjectId }) {
    const [open, setOpen] = useState(false);
    const nameRef = useRef();
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const { apiPost, ApiErrors } = useFetch();

    return (
        <Fragment>
            {trigger(() => setOpen(true))}
            <Dialog open={open} onClose={handleClose}>
                <DialogCloseButton onClick={handleClose} />

                <form onSubmit={createCustomParameter}>
                    <DialogTitle>{t("records.Parameters.Custom.Title")}</DialogTitle>

                    <DialogContent>
                        <ApiErrors />
                        <div dangerouslySetInnerHTML={{ __html: t("records.Parameters.Custom.Info") }} />
                        <TextField
                            label={t("records.Parameters.Custom.SymptomName")}
                            inputRef={nameRef}
                            inputProps={{ length: 30 }}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            tw="mt-3"
                        />
                        <div tw="mt-3 text-gray-400">
                            <small dangerouslySetInnerHTML={{ __html: t("records.Parameters.Custom.Notice") }} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">{t("records.Parameters.Custom.Add")}</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    );

    async function createCustomParameter(event) {
        event.preventDefault();

        const response = await apiPost("/web/parameters", { name: name, medicalSubjectId: medicalSubjectId });
        if (response.isOk) {
            setName(null);
            onParameterCreated(response.json.createdParameter);
            handleClose();
        }
    }

    function handleClose() {
        setOpen(false);
    }
}
