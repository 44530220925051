import { dayTimes, getDayTimesOptions } from "../codebooks/dayTimes";
import { scheduleTypes } from "../codebooks/scheduleTypes";
import { verifyAmount } from "../utils/amount";
import {
    _prescriptionScheduleCreate,
    _prescriptionScheduleDelete,
    _prescriptionScheduleUpdate,
} from "./prescriptionSlice";
import i18n from "i18next";

export function updateSchedulesFromFastTimeShedule(state, prescription) {
    let amounts = parseFastTimeSchedule(prescription.fastTimeSchedule);

    // Vytvoření, nebo aktualizace schedules dle fastTimeSchedule
    for (let qsIndex = 0; qsIndex < amounts.length; qsIndex++) {
        let dayTimeOption = getDayTimeOption(qsIndex, amounts.length);

        // Vynutit nastavení dayTime v schedule
        if (prescription.schedules.length > qsIndex) {
            const scheduleId = prescription.schedules[qsIndex];
            const schedule = state.schedules.byId[scheduleId];
            schedule.time = dayTimeOption;
        }

        const amount = amounts[qsIndex];

        if (prescription.schedules.length <= qsIndex) {
            _prescriptionScheduleCreate(state, prescription.id, scheduleTypes.time, {
                time: dayTimeOption,
                amount,
            });
        } else {
            const scheduleId = prescription.schedules[qsIndex];
            _prescriptionScheduleUpdate(state, scheduleId, { amount }, true);
        }
    }

    // Odstranění přebytečných schedules.
    if (amounts.length < prescription.schedules.length) {
        for (let index = prescription.schedules.length - 1; index >= amounts.length; index--)
            _prescriptionScheduleDelete(state, prescription.schedules[index]);
    }

    prescription.fastTimeScheduleSame = true;
}

function parseFastTimeSchedule(fastTimeSchedule) {
    let fastTimeSchedules = (fastTimeSchedule ?? "").split("-");
    if (fastTimeSchedules.length === 1 && fastTimeSchedules[0] === "") fastTimeSchedules = [];
    return fastTimeSchedules;
}

export function getDayTimeOption(index, fastTimeSchedulesLength = null) {
    const dayTimesOptions = getDayTimesOptions(i18n.language);

    if (fastTimeSchedulesLength === 1 && index === 0) return null;

    if (index === 0) return dayTimesOptions[dayTimes.morning];
    else if (index === 1) return dayTimesOptions[dayTimes.noon];
    else if (index === 2) return dayTimesOptions[dayTimes.evening];
    else return dayTimesOptions[dayTimes.night];
}

export function checkFastTimeScheduleSame(state, prescriptionId) {
    const prescription = state.prescriptions.byId[prescriptionId];
    prescription.fastTimeScheduleSame = checkMathingTimes();

    function checkMathingTimes() {
        for (let index = 0; index < prescription.schedules.length; index++) {
            const scheduleId = prescription.schedules[index];
            const schedule = state.schedules.byId[scheduleId];

            const dayTimeOption = getDayTimeOption(index, prescription.schedules.length);

            const scheduleValue = schedule.time !== null ? schedule.time.value : null;
            const dayTimeValue = dayTimeOption !== null ? dayTimeOption.value : null;

            if (dayTimeValue === null && scheduleValue === null) continue;
            if (scheduleValue !== dayTimeValue) return false;
        }

        return true;
    }
}

export function updateFastTimeScheduleFromSchedules(state, prescriptionId) {
    checkFastTimeScheduleSame(state, prescriptionId);

    const prescription = state.prescriptions.byId[prescriptionId];
    const amounts = [];
    if (prescription.fastTimeScheduleSame) {
        for (let scheduleId of prescription.schedules) amounts.push(state.schedules.byId[scheduleId].amount);
        if (amounts.length === 1) prescription.fastTimeSchedule = amounts[0];
        else prescription.fastTimeSchedule = amounts.join("-");
    }
}

export function verifyFastTimeSchedule(value, typing) {
    value = value.replace(/ /g, "-");
    const amounts = parseFastTimeSchedule(value);
    for (let i = 0; i < amounts.length; i++) amounts[i] = verifyAmount(amounts[i], typing);
    return amounts.join("-");
}
