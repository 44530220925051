import { useEffect, useState } from "react";
import { useFetch } from "../../utils/useFetch";

export default function useIPhone() {
    const { apiGet, ApiErrors } = useFetch();
    const [lastImportDate, setLastImportDate] = useState();

    useEffect(() => {
        async function getLastImportDate() {
            const response = await apiGet("/iphone/get-last-import-date");
            if (response.isOk) {
                if (response.json === null) {
                    setLastImportDate(null);
                } else {
                    setLastImportDate(new Date(response.json));
                }
            }
        }
        getLastImportDate();
    }, []);

    return { lastImportDate, ApiErrors };
}
