import { Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import ApiErrorsList from "@profilog/misc/errors/ApiErrorsList";
import { prescriptionCancelEdit, usePrescriptionSave } from "../redux/prescriptionSlice";
import PrescriptionContainer from "./PrescriptionContainer";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import "twin.macro";
import PrescriptionFactory from "./types/PrescriptionFactory";
import { useTranslation } from "react-i18next";

export default function PrescriptionEdit({ index, prescriptionId, onSaved }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const prescriptionType = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].$type);

    const { thunk: prescriptionSave, pending, apiErrors, dispatchCleanup } = usePrescriptionSave(prescriptionId);

    return (
        <PrescriptionContainer
            editing
            prescriptionType={prescriptionType}
            buttons={
                <div tw="flex flex-row justify-center md:flex-col items-stretch gap-2">
                    <Button
                        size="small"
                        color="success"
                        variant="outlined"
                        tw="normal-case"
                        disableElevation
                        startIcon={<CheckIcon />}
                        onClick={handleSave}
                        disabled={pending}
                    >
                        {t("global.Save")}
                    </Button>
                    <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        tw="normal-case"
                        disableElevation
                        startIcon={<ClearIcon />}
                        onClick={handleCancel}
                        disabled={pending}
                    >
                        {t("global.Cancel")}
                    </Button>
                </div>
            }
        >
            <div tw="mt-2 space-y-2">
                <PrescriptionFactory prescriptionId={prescriptionId} index={index + 1} />
                {apiErrors && <ApiErrorsList apiErrors={apiErrors} />}
            </div>
        </PrescriptionContainer>
    );

    async function handleSave() {
        const result = await dispatch(prescriptionSave(prescriptionId));
        if (result.meta.requestStatus === "fulfilled") {
            if (onSaved) await onSaved();
            handleCancel();
        }
    }

    async function handleCancel() {
        dispatchCleanup();
        await dispatch(prescriptionCancelEdit({ prescriptionId }));
    }
}
