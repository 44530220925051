import { Fragment, useEffect, useState } from "react";
import { useFetch } from "@profilog/misc/utils/useFetch";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";

export default function ExternalServicesStatusDialog() {
    const { t } = useTranslation();
    const { apiGet, ApiErrors } = useFetch();
    const { apiPost, ApiErrors: RemoveConnsApiErrors, pending: removeConnsPending } = useFetch();
    const [connectors, setConnectors] = useState<Array<any> | null>(null);
    const [closed, setClosed] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function getStatus() {
            const response = await apiGet("/v1/users/current/external-services/status");
            if (!response.isOk) return;

            const result = response.json.filter((c: any) => c.status !== "Connected" && c.status !== "NotConnected");
            if (result.length > 0) setConnectors(result);
        }

        getStatus();
    }, []); // eslint-disable-line
    return (
        <Fragment>
            <ApiErrors modal title={t("extConnectors.Status.ErrorTitle")} />
            <Dialog open={!closed && connectors !== null}>
                <DialogCloseButton onClick={() => setClosed(true)} />
                <DialogTitle>{t("extConnectors.Status.Problem")}</DialogTitle>
                <DialogContent>
                    <div tw="mb-4">{t("extConnectors.Status.ConnectionsExpired")}</div>
                    <ul tw="list-disc mb-4">
                        {connectors?.map((c) => (
                            <li key={c.serviceType} tw="text-base font-bold ml-4">
                                {c.serviceType}
                            </li>
                        ))}
                    </ul>
                    <div tw="mb-4">{t("extConnectors.Status.ConnectAgain")}</div>
                    <RemoveConnsApiErrors />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" tw="normal-case" onClick={navigateToSettings}>
                        {t("extConnectors.Status.GoToSettings")}
                    </Button>
                    <Button
                        color="warning"
                        variant="contained"
                        tw="normal-case"
                        onClick={disconnectInvalidConnections}
                        disabled={removeConnsPending}
                    >
                        {t("extConnectors.Status.ConnectLater")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );

    function navigateToSettings() {
        navigate("/in/settings");
    }

    async function disconnectInvalidConnections() {
        const response = await apiPost(
            "/v1/users/current/external-services/remove-disconnected",
            connectors?.map((c) => c.serviceType),
        );

        if (response.isOk) setConnectors(null);
    }
}
