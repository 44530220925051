import { useState } from "react";
import { Dialog, DialogContent, IconButton, Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import ValidationError from "@profilog/misc/errors/ValidationError";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { useFetch } from "@profilog/misc/utils/useFetch";
import DeleteDialog from "@profilog/commons/components/DeleteDialog";
import AppSettings from "../../app/AppSettings";
import CircularProgressMidParent from "@profilog/commons/components/CircularProgressMidParent";

export default function MilestoneEditModal({
    open,
    medicalSubjectId,
    date,
    weightRecordId,
    weight,
    milestoneRecordId,
    milestoneId,
    milestoneText,
    milestones,
    onClose,
}) {
    const { t, i18n } = useTranslation();
    const { apiPost, apiPut, apiDeleteBody, ApiErrors } = useFetch();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMilestoneId, setSelectedMilestoneId] = useState(
        milestoneId == null && milestoneText ? 0 : milestoneId,
    );
    const { register, handleSubmit, errors } = useForm();

    async function submitForm(data) {
        setIsLoading(true);
        let milestoneId = parseInt(data.milestoneId);
        let text = null;
        if (milestoneId === 0) {
            milestoneId = null;
            text = data.text;
        }

        let date = new Date(data.date);
        date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        const synchronizePayload = {
            medicalSubjectId: medicalSubjectId,
            recordGroups: [],
        };
        let updateWeightPayload = null;
        let updateMilestonePayload = null;
        if (data.weight) {
            if (weightRecordId) {
                updateWeightPayload = {
                    subjectId: medicalSubjectId,
                    recordId: `${weightRecordId}`,
                    recordTypeName: "WeightInKilograms",
                    utcStartedAt: date.toISOString(),
                    utcEndedAt: date.toISOString(),
                    fieldsToUpdate: [
                        {
                            name: "value",
                            numericValue: data.weight,
                        },
                    ],
                };
            } else {
                synchronizePayload.recordGroups.push({
                    recordTypeName: "WeightInKilograms",
                    utcFrom: date.toISOString(),
                    utcTo: date.toISOString(),
                    source: {
                        nodes: [
                            { Type: "web", Channel: "rest", Provider: "unknown", Level: 1 },
                            { Type: "sdk", Channel: "api", Provider: "recordPanel", Level: 2 },
                            { Type: "user", Channel: "manualInput", Provider: "user", Level: 3 },
                        ],
                    },
                    records: [
                        {
                            utcStart: date.toISOString(),
                            utcEnd: date.toISOString(),
                            fields: [{ name: "value", numericValue: data.weight }],
                        },
                    ],
                });
            }
        }
        if (milestoneId || text) {
            if (milestoneRecordId) {
                updateMilestonePayload = {
                    subjectId: medicalSubjectId,
                    recordId: `${milestoneRecordId}`,
                    recordTypeName: "Milestone",
                    utcStartedAt: date.toISOString(),
                    utcEndedAt: date.toISOString(),
                    fieldsToUpdate: [
                        {
                            name: "value",
                            numericValue: milestoneId,
                            textValue: text,
                        },
                    ],
                };
            } else {
                synchronizePayload.recordGroups.push({
                    recordTypeName: "Milestone",
                    utcFrom: date.toISOString(),
                    utcTo: date.toISOString(),
                    source: {
                        nodes: [
                            { Type: "web", Channel: "rest", Provider: "unknown", Level: 1 },
                            { Type: "sdk", Channel: "api", Provider: "recordPanel", Level: 2 },
                            { Type: "user", Channel: "manualInput", Provider: "user", Level: 3 },
                        ],
                    },
                    records: [
                        {
                            utcStart: date.toISOString(),
                            utcEnd: date.toISOString(),
                            fields: [{ name: "value", numericValue: milestoneId, textValue: text }],
                        },
                    ],
                });
            }
        }
        let isOk = true;
        let synchronizeResponse;
        if (synchronizePayload.recordGroups.length > 0) {
            synchronizeResponse = await apiPost("/v2/medical-subjects/records/synchronize", synchronizePayload);
            isOk = synchronizeResponse.isOk;
        }
        let updateWeightResponse;
        if (updateWeightPayload) {
            updateWeightResponse = await apiPut("/v2/medical-subjects/records/update", updateWeightPayload);
            isOk = isOk && updateWeightResponse.isOk;
        }
        let updateMilestoneResponse;
        if (updateMilestonePayload) {
            updateMilestoneResponse = await apiPut("/v2/medical-subjects/records/update", updateMilestonePayload);
            isOk = isOk && updateMilestoneResponse;
        }

        if (isOk) {
            onClose(true);
        }
        setIsLoading(false);
    }

    function getDeleterText() {
        let localizedDate = new Date(date).toLocaleDateString(i18n.language, {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
        });

        return t("recordsVisualization.ReallyDelete") + " " + localizedDate;
    }

    async function deleteRecord() {
        console.log("delete record");
        setIsLoading(true);
        let isOk = true;
        if (weightRecordId) {
            const deleteWeightRecordBody = {
                medicalSubjectId: medicalSubjectId,
                recordTypeName: "WeightInKilograms",
                recordIds: [weightRecordId],
            };
            console.log("deleteWeightRecordBody", deleteWeightRecordBody);
            const response = await apiDeleteBody(`/v2/medical-subjects/records`, deleteWeightRecordBody);
            isOk = response.isOk;
        }

        if (milestoneRecordId) {
            const deleteMilestoneBody = {
                medicalSubjectId: medicalSubjectId,
                recordTypeName: "Milestone",
                recordIds: [milestoneRecordId],
            };
            console.log("deleteMilestoneBody", deleteMilestoneBody);
            const response = await apiDeleteBody(`/v2/medical-subjects/records`, deleteMilestoneBody);
            isOk = isOk && response.isOk;
        }

        if (isOk) {
            onClose(true);
        }
        setIsLoading(false);
    }

    function getMilestones() {
        let result = [{ id: null, name: "---" }];
        result = result.concat(milestones);
        result.push({ id: 0, name: t("userSettings.Milestones.Other") });
        return result;
    }

    if (!open) return null;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="10vw"
            fullWidth
            PaperProps={{
                sx: {
                    margin: 0,
                    width: "calc(30% - 15px)",
                },
            }}
        >
            <div style={{ display: "flex", alignItems: "center", padding: "2px 30px 2px 10px" }}>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 4,
                        top: 4,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogContent sx={{ padding: 0 }}>
                <ApiErrors />
                {isLoading && <CircularProgressMidParent />}
                <form onSubmit={handleSubmit(submitForm)} tw="pt-8 pb-5 pl-5 pr-5">
                    <div tw="pt-5">
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            label={t("userSettings.Milestones.Date")}
                            type="date"
                            id="date"
                            name="date"
                            defaultValue={date ? format(date, "yyyy-MM-dd") : ""}
                            inputRef={register({ required: t("global.Form.RequiredField") })}
                            fullWidth
                        />
                        <ValidationError errors={errors} name="date" />
                    </div>
                    <div tw="pt-5">
                        <TextField
                            type="number"
                            id="weight"
                            name="weight"
                            label={t("userSettings.Milestones.Weight")}
                            defaultValue={weight}
                            InputProps={{ inputProps: { min: 0, max: 400, step: 0.1 } }}
                            inputRef={register({ required: t("global.Form.RequiredField") })}
                            fullWidth
                        />
                        <ValidationError errors={errors} name="weight" />
                    </div>
                    <div tw="pt-5">
                        <TextField
                            select
                            fullWidth
                            SelectProps={{
                                native: true,
                                inputProps: { ref: register, name: "milestoneId" },
                            }}
                            value={selectedMilestoneId}
                            label={t("userSettings.Milestones.Milestone")}
                            onChange={(e) => setSelectedMilestoneId(parseInt(e.target.value))}
                            autoFocus={selectedMilestoneId > 0 || selectedMilestoneId === null}
                        >
                            {getMilestones().map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </TextField>
                    </div>
                    {selectedMilestoneId === 0 && (
                        <div tw="pt-5">
                            <TextField
                                type="text"
                                id="text"
                                name="text"
                                autoFocus={selectedMilestoneId === 0}
                                defaultValue={milestoneText}
                                label={t("userSettings.Milestones.CustomText")}
                                inputRef={register({ required: t("global.Form.RequiredField") })}
                                fullWidth
                            />
                            <ValidationError errors={errors} name="text" />
                        </div>
                    )}
                    <div tw="pt-5">
                        <Button type="submit" disabled={isLoading} variant="contained" size="large">
                            {t("global.Save")}
                        </Button>

                        {(weightRecordId || milestoneRecordId) && (
                            <DeleteDialog
                                onConfirmed={deleteRecord}
                                trigger={(open) => (
                                    <Button
                                        type="button"
                                        tw="float-right background-color[#d32f2f]"
                                        variant="contained"
                                        disabled={isLoading}
                                        onClick={open}
                                        size="large"
                                    >
                                        {t("global.Delete")}
                                    </Button>
                                )}
                            >
                                {getDeleterText()}
                            </DeleteDialog>
                        )}
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );

    function handleClose() {
        if (onClose) onClose(false);
    }
}
