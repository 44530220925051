import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiGet } from "@profilog/misc/utils/useFetch";
import { useParams } from "react-router-dom";
import RecordsReviewComponent from "../components/RecordsReviewComponent";
import { useLinkIdentificatorFetch } from "@profilog/misc/records/fetch/useLinkIdentificatorFetch";
import { Alert } from "@mui/material";

const RecordsReview = () => {
    const { t } = useTranslation();
    const [link, setLink] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { identificator } = useParams();
    const recordsVisualizationFetchService = useLinkIdentificatorFetch(identificator);
    const [reviewData, setReviewData] = useState({});

    useEffect(() => {
        async function getLink() {
            let response = await apiGet("/sharing/getLinkReview/" + identificator);
            if (response.isOk) {
                setLink(response.json);
            } else {
                setIsError(true);
            }
            setIsLoading(false);
        }
        getLink();
    }, [identificator]);

    useEffect(() => {
        async function getReviewData() {
            let response = await apiGet("/sharing/records-review/" + identificator);
            if (response.isOk) {
                setReviewData(response.json);
            } else {
                setIsError(true);
            }
            setIsLoading(false);
        }
        getReviewData();
        // eslint-disable-next-line
    }, [identificator]);

    if (isLoading) return null;

    return (
        <Fragment>
            {isError || link.isAccessRevoked ? (
                <div className="container mt-2 mt-md-5">
                    <div className="row">
                        <div className="col-12">
                            <Alert severity="error">{t("sharing.RecordsReview.AccessRevoked")}</Alert>
                        </div>
                    </div>
                </div>
            ) : (
                <RecordsReviewComponent
                    reviewData={reviewData}
                    recordsVisualizationFetchService={recordsVisualizationFetchService}
                    linkGenerated={link.linkGenerated}
                    recipientEmailAddress={link.recipientEmailAddress}
                    isUserContactIncluded={link.isUserContactIncluded}
                    isGraphVisible={true}
                />
            )}
        </Fragment>
    );
};

export default RecordsReview;
