import { Fragment, useEffect, useState } from "react";
import { useFetch } from "@profilog/misc/utils/useFetch";
import { useRelationEditModal } from "../components/RelationEditModal";
import { useTranslation } from "react-i18next";

const RelatedMedicalSubjectsList = () => {
    const { t } = useTranslation();
    const [personMedicalSubjects, setPersonMedicalSubjects] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState();
    const { apiGet, ApiErrors } = useFetch();

    const { openRelationEditModal, RelationEditModal } = useRelationEditModal(groups);

    const handleRelationSaved = () => {
        getPatientsInGroup(selectedGroup);
    };

    useEffect(() => {
        async function getGroups() {
            let result = await apiGet("/groups");
            setGroups(result.json);
            if (result.json.length > 0) {
                setSelectedGroup(result.json[0]);
            }
        }
        getGroups();
    }, []);

    async function getPatientsInGroup(selectedGroup) {
        if (selectedGroup != null) {
            let result = await apiGet("/groups/" + selectedGroup.id + "/persons");
            setPersonMedicalSubjects(result.json);
        }
    }
    useEffect(() => {
        getPatientsInGroup(selectedGroup);
        return function cleanup() {
            setPersonMedicalSubjects([]);
        };
    }, [selectedGroup]);

    return (
        <Fragment>
            <ApiErrors />
            <RelationEditModal />

            <h2 className="box-title">{t("header.NavBar.Persons")}</h2>

            <div className="d-flex justify-content-between flex-wrap">
                <div>
                    <button
                        type="button"
                        className="btn btn-primary ml-auto my-2"
                        onClick={() => openRelationEditModal(null, selectedGroup?.id, handleRelationSaved)}
                    >
                        {t("persons.NewPerson")}
                    </button>
                </div>
                <div className="dropdown">
                    <button
                        className="btn btn-secondary my-2"
                        type="button"
                        id="groupDropdownButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        {selectedGroup ? selectedGroup.name : "-"}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="groupDropdownButton">
                        {groups.map((group) => (
                            <button
                                key={group.id}
                                type="button"
                                className="dropdown-item"
                                onClick={() => setSelectedGroup(group)}
                            >
                                {group.name}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <div className="table-responsive no-more-tables">
                <table className="table va-inherit">
                    <thead>
                        <tr>
                            <th scope="col">{t("persons.Contacts.Name")}</th>
                            <th scope="col">{t("persons.Contacts.Surname")}</th>
                            <th scope="col">{t("persons.YearOfBirth")}</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {personMedicalSubjects.map((medicalSubject) => (
                            <tr key={medicalSubject.id} className="clearfix mt-3">
                                <td data-title={t("persons.Contacts.Name")}>{medicalSubject.firstName}</td>
                                <td data-title={t("persons.Contacts.Surname")}>{medicalSubject.lastName}</td>
                                <td data-title={t("persons.YearOfBirth")}>{medicalSubject.yearOfBirth}</td>
                                <td className="text-right">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() =>
                                            openRelationEditModal(
                                                medicalSubject.id,
                                                selectedGroup?.id,
                                                handleRelationSaved,
                                            )
                                        }
                                    >
                                        {t("persons.Detail")}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
};

export default RelatedMedicalSubjectsList;
