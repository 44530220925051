import { Alert, Dialog, DialogContent, DialogTitle } from "@mui/material";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";
import { useTranslation } from "react-i18next";
import { translateError } from "./translateError";

export default function ApiErrorsModal({ apiErrors, resetApiErrors, onCloseClicked, title, translationPrefix }) {
    const containsErrors = apiErrors && apiErrors.length > 0;
    const { t } = useTranslation();
    const modalTitle = title ? title : t("global.Error");

    if (containsErrors)
        return (
            <Dialog open={containsErrors} onClose={handleClose}>
                <DialogCloseButton onClick={handleClose} />
                <DialogTitle>{modalTitle}</DialogTitle>
                <DialogContent>
                    {apiErrors.length === 1 && (
                        <Alert severity="error">{translateError(t, apiErrors[0], translationPrefix)}</Alert>
                    )}
                    {apiErrors.length > 1 &&
                        apiErrors.map((e) => (
                            <Alert severity="error" key={e}>
                                {translateError(t, e, translationPrefix)}
                            </Alert>
                        ))}
                </DialogContent>
            </Dialog>
        );
    else return null;

    function handleClose() {
        resetApiErrors();
        if (onCloseClicked) onCloseClicked();
    }
}
