import { useEffect, useState } from "react";
import { useFetch } from "../../utils/useFetch";
import { getQuery } from "../../utils/Url";
import FetchOptions from "../../utils/FetchOptions";
import { useTranslation } from "react-i18next";

export default function useStrava(refresh) {
    const { t } = useTranslation();
    const [stravaConnectionInfo, setStravaConnectionInfo] = useState(null);
    const { apiPost, apiGet, ApiErrors } = useFetch();
    const [isConnecting, setIsConnecting] = useState(false);
    const [stravaApiError, setStravaApiError] = useState(null);
    const query = getQuery();

    async function connect(returnUrlQueryString) {
        setIsConnecting(true);
        window.location =
            FetchOptions.apiUrl +
            "/strava/connect?ReturnUrl=" +
            encodeURIComponent(
                `${window.location.protocol}//${window.location.host}${window.location.pathname}${
                    returnUrlQueryString ? `?${returnUrlQueryString}` : ""
                }`,
            );
    }

    async function disconnect() {
        const response = await apiPost("/strava/disconnect");
        if (response.isOk) await getStravaStatus();
    }

    async function getStravaStatus() {
        const response = await apiGet("/strava/get-state");
        if (response.isOk) {
            setStravaConnectionInfo(response.json || {});
            console.log("getStravaStatus:", response.json);
        }
    }

    useEffect(() => {
        getStravaStatus();

        const stravaErrorCode = query.get("strava-error");
        if (stravaErrorCode !== null) {
            switch (stravaErrorCode) {
                case "get-consent":
                    setStravaApiError(t("extConnectors.Error.GetConsent"));
                    break;
                default:
                    break;
            }
        }
    }, [refresh]); // eslint-disable-line

    return {
        stravaConnectionInfo,
        connect,
        disconnect,
        isConnecting,
        ApiErrors,
        stravaApiError,
    };
}
