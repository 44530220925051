import { Fragment, useState } from "react";
import DatePicker from "@profilog/misc/commons/components/DatePicker";
import { DATETIME_FORMAT } from "@profilog/misc/commons/dateDefaults";
import { useFetch } from "@profilog/misc/utils/useFetch";
import { stringToFloatOrNull, dateToISOStringOrNull } from "@profilog/utils/converting";
import { addHours } from "date-fns/esm";
import { prescriptionTypes } from "../../codebooks/prescriptionTypes";
import "twin.macro";
import { Button, IconButton, FormControl, InputLabel, MenuItem, Popper, Select, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import DrugComboBox from "../codebooks/DrugComboBox";
import ActionComboBox from "../codebooks/ActionComboBox";

const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8];

export default function CustomCalendarAction({ medicalSubjectId, addButtonText, prescriptionType, onClosed }) {
    const { t } = useTranslation();

    const [customAction, setCustomAction] = useState({
        beforeHours: 0,
        exactTime: null,
        text: "",
        amount: 1,
    });

    const [anchorEl, setAnchorEl] = useState(null);
    const { apiPost, ApiErrors, resetApiErrors } = useFetch();
    const [isPosting, setIsPosting] = useState(false);

    const FinalComboBox = prescriptionType === prescriptionTypes.drug ? DrugComboBox : ActionComboBox;

    return (
        <Fragment>
            <button type="button" className="btn btn-link btn-sm" onClick={handleClick}>
                {addButtonText}
            </button>

            <Popper
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={close}
                placement="bottom"
                style={{ zIndex: 100 }}
            >
                <div tw="bg-white rounded-xl shadow-updown p-2 pt-6 relative w-80">
                    <div tw="absolute right-1 top-0.5">
                        <IconButton color="primary" size="small" sx={{ p: 0 }} onClick={close}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div tw="mt-2">
                        <FinalComboBox
                            value={customAction.text}
                            onChange={(newValue) => updateCustomAction({ text: newValue })}
                            tw="w-full"
                        />
                    </div>
                    <div tw="mt-3">
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel>{t("prescriptions.Edit.Applied")}</InputLabel>
                            <Select
                                size="small"
                                label={t("prescriptions.Edit.Applied")}
                                value={customAction.beforeHours}
                                onChange={(e) => updateCustomAction({ beforeHours: Number.parseInt(e.target.value) })}
                            >
                                {hours.map((i) => (
                                    <MenuItem key={i} value={i}>
                                        {i === 0
                                            ? t("prescriptions.Time.Now")
                                            : t("prescriptions.Time.BeforeHours", { hours: i })}
                                    </MenuItem>
                                ))}
                                <MenuItem value={-1}>Přesný čas</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    {customAction.beforeHours === -1 && (
                        <div tw="mt-3">
                            <DatePicker
                                placeholder={DATETIME_FORMAT}
                                allowFutureDates
                                showTimeSelect
                                selected={customAction.exactTime}
                                onChange={(value) => updateCustomAction({ exactTime: value })}
                                usePortal={true}
                                customInput={<TextField size="small" fullWidth />}
                            />
                        </div>
                    )}
                    <div tw="mt-3">
                        <TextField
                            size="small"
                            label={t("prescriptions.Edit.Amount")}
                            sx={{ width: "10ch" }}
                            value={customAction.amount ?? ""}
                            onChange={(e) => updateCustomAction({ amount: stringToFloatOrNull(e.target.value) })}
                        />
                    </div>
                    <ApiErrors />
                    <div tw="mt-3 text-center">
                        <Button
                            size="small"
                            color="success"
                            variant="contained"
                            sx={{ marginRight: 1 }}
                            onClick={async () => (await postCustomAction()) && close()}
                            disabled={isPosting}
                        >
                            Vložit
                        </Button>
                    </div>
                </div>
            </Popper>
        </Fragment>
    );

    function handleClick(event) {
        if (anchorEl === null) newCustomAction();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    function newCustomAction() {
        resetApiErrors();

        setCustomAction({
            ...customAction,
            text: "",
            amount: 1,
        });
    }

    function updateCustomAction(payload) {
        setCustomAction({ ...customAction, ...payload });
    }

    async function postCustomAction() {
        setIsPosting(true);

        let exactDate = new Date().toISOString();
        if (customAction.beforeHours < 0) {
            let date = dateToISOStringOrNull(customAction.exactTime);
            if (date !== null) exactDate = date;
        } else {
            exactDate = addHours(new Date(), -customAction.beforeHours).toISOString();
        }

        const response = await apiPost("/web/prescriptions/calendar-items/change-state", {
            medicalSubjectId,
            prescriptionType,
            scheduledAt: exactDate,
            confirmedAt: exactDate,
            amount: customAction.amount,
            text: customAction.text.label,
            prescriptionApplicationId: null,
            prescriptionScheduleId: null,
        });

        setIsPosting(false);
        return response.isOk;
    }

    function close() {
        setAnchorEl(null);
        if (onClosed) onClosed();
    }
}
