import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiErrorsList from "@profilog/misc/errors/ApiErrorsList";
import { useGetPrescriptions } from "../../redux/prescriptionSlice";
import { useFetch } from "@profilog/misc/utils/useFetch";
import PrescriptionListItem from "./PrescriptionListItem";
import {
    Button,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Tab,
    Tabs,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import AddPrescriptionFab from "./AddPrescriptionFab";
import PrescriptionEdit from "../PrescriptionEdit";
import "twin.macro";
import { prescriptionTypesUrls } from "../../codebooks/prescriptionTypes";
import PrescriptionIcon from "../types/PrescriptionIcon";
import { useTranslation } from "react-i18next";
import CircularProgressMidScreen from "@profilog/commons/components/CircularProgressMidScreen";
import { prescriptionChangeListTypeUrl } from "../../redux/prescriptionSlice";
import { getArgName } from "@profilog/prescriptions/redux/toolkit";
import NotificationSettingsDialog from "../settings/NotificationSettingsDialog";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";

export default function Prescriptions({
    onListTypeChanged,
    groupId,
    allowSettings = true,
    allowEdit = true,
    showGroupName = false,
}) {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { apiDelete, apiErrors: deleteApiErrors, resetApiErrors: resetDeleteApiErrors } = useFetch();
    let listTypeUrl = useSelector((state) => state.prescriptions.listTypeUrl);
    const existsNew = useSelector((state) => state.prescriptions.existsNew);

    const listKey = { groupId };

    const { thunk: getPrescriptions, pending, data, apiErrors } = useGetPrescriptions(listKey);

    useEffect(() => {
        dispatch(getPrescriptions(listKey));
    }, [groupId]); // eslint-disable-line

    const prescriptionType = prescriptionTypesUrls[listTypeUrl];
    const filteredData = data ? data.filter((prescription) => prescription.$type === prescriptionType) : null;

    return (
        <Fragment>
            <div>
                <div tw="flex flex-wrap items-center justify-between">
                    <div tw="w-full md:w-auto">
                        <PrescriptionTypeTabs selectedUrl={listTypeUrl} onChange={handleTabChange} />
                    </div>
                    <div tw="w-full text-right md:w-auto">
                        {allowSettings && (
                            <NotificationSettingsDialog
                                trigger={(open) => (
                                    <Button
                                        tw="normal-case whitespace-nowrap"
                                        onClick={open}
                                        startIcon={<EditNotificationsIcon />}
                                    >
                                        Nastavení upozornění a denních dob
                                    </Button>
                                )}
                            />
                        )}
                    </div>
                </div>
                {deleteApiErrors && <ApiErrorsList apiErrors={deleteApiErrors} />}
                <ApiErrorsList apiErrors={apiErrors} />
                {pending && <CircularProgressMidScreen />}
                <div tw="mt-3 space-y-3">
                    {filteredData &&
                        filteredData.map((prescription) => (
                            <PrescriptionListItem
                                key={prescription.id}
                                prescription={prescription}
                                onSaved={handleSaved}
                                onDelete={handleDelete}
                                allowEdit={allowEdit && prescription.medicalSubjectId !== null}
                                showGroupName={showGroupName}
                            />
                        ))}
                    {filteredData && filteredData.length === 0 && !existsNew && !pending && (
                        <div tw="text-center">
                            <em>{t("prescriptions.List.NoPrescriptions")}</em>
                        </div>
                    )}
                </div>
                <NewPrescriptions prescriptionType={prescriptionType} onSaved={handleSaved} groupId={groupId} />
            </div>

            <AddPrescriptionFab prescriptionType={prescriptionType} groupId={groupId} />
        </Fragment>
    );

    async function handleSaved() {
        await dispatch(getPrescriptions(listKey));
    }

    async function handleDelete(prescriptionId) {
        await apiDelete(`/web/prescriptions/${prescriptionId}`);
        dispatch(getPrescriptions(listKey));
    }

    async function handleTabChange(newTabUrl) {
        resetDeleteApiErrors();
        if (onListTypeChanged) onListTypeChanged(newTabUrl);
        dispatch(prescriptionChangeListTypeUrl(newTabUrl));
    }
}

function PrescriptionTypeTabs({ selectedUrl, onChange }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    if (mobile)
        return (
            <Select
                size="small"
                defaultValue={selectedUrl}
                onChange={(e) => changeListType(e.target.value)}
                tw="mb-2"
                fullWidth
                renderValue={(url) => (
                    <Fragment>
                        <PrescriptionIcon type={prescriptionTypesUrls[url]} sx={{ mr: 1 }} />
                        {t("prescriptions.Types." + prescriptionTypesUrls[url] + "Plural")}
                    </Fragment>
                )}
            >
                {Object.keys(prescriptionTypesUrls).map((url) => (
                    <MenuItem key={url} value={url}>
                        <ListItemIcon>
                            <PrescriptionIcon type={prescriptionTypesUrls[url]} />
                        </ListItemIcon>
                        <ListItemText>{t("prescriptions.Types." + prescriptionTypesUrls[url] + "Plural")}</ListItemText>
                    </MenuItem>
                ))}
            </Select>
        );

    return (
        <Tabs
            value={selectedUrl}
            onChange={(_, newValue) => changeListType(newValue)}
            variant="scrollable"
            allowScrollButtonsMobile
        >
            {Object.keys(prescriptionTypesUrls).map((url) => (
                <Tab
                    key={url}
                    icon={<PrescriptionIcon type={prescriptionTypesUrls[url]} />}
                    iconPosition="start"
                    label={t("prescriptions.Types." + prescriptionTypesUrls[url] + "Plural")}
                    value={url}
                    sx={{ minHeight: "auto" }}
                />
            ))}
        </Tabs>
    );

    function changeListType(newTypeUrl) {
        if (onChange) onChange(newTypeUrl);
    }
}

function NewPrescriptions({ prescriptionType, groupId, onSaved }) {
    const newPrescriptions = useSelector((state) => {
        let statePart = state.prescriptions.newPrescriptions[getArgName(groupId ? { groupId } : null)];
        return statePart ? statePart[prescriptionType] : [];
    });

    return (
        <div tw="mt-3 space-y-3">
            {newPrescriptions.map((prescriptionId) => (
                <PrescriptionEdit key={prescriptionId} prescriptionId={prescriptionId} onSaved={onSaved} />
            ))}
        </div>
    );
}
