import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../utils/useFetch";
import { useForm } from "react-hook-form";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export function ContractTextConsentDialog({ projectId, onConsent, onDisconsent }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);
    const { apiGet, ApiErrors: ApiGetGdprErrors } = useFetch();
    const { apiPut, ApiErrors: ApiPostGdprErrors } = useFetch();
    const [contractText, setContractText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const {
        handleSubmit,
        formState: { isSubmitting },
    } = useForm();

    async function consentWithContract() {
        let response = await apiPut(`/profilog/user/consent-with-contract?projectId=${projectId}`);
        if (response.isOk) setOpen(false);
    }

    useEffect(() => {
        async function getProjectContractText() {
            if (open && projectId) {
                const response = await apiGet(`/profilog/projects/contracts?projectId=${projectId}`);
                if (response.isOk) {
                    setContractText(response.json.text);
                    setIsLoading(false);
                }
            }
        }

        getProjectContractText();
    }, [open, projectId]);

    const children = <div className="container"></div>;
    if (isLoading) return null;

    return (
        <Fragment>
            <ApiPostGdprErrors />
            <Dialog open={open} maxWidth="lg" fullWidth children={children}>
                <DialogTitle>{t("auth.ContractConsent.ConsentTitle")}</DialogTitle>

                <form onSubmit={handleSubmit(consentWithContract)}>
                    <DialogContent>
                        <ApiGetGdprErrors />
                        {contractText && <p dangerouslySetInnerHTML={{ __html: contractText }}></p>}
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained" color="success" disabled={isSubmitting}>
                            {t("auth.ContractConsent.ConsentButton")}
                        </Button>
                        <Button variant="contained" color="error" disabled={isSubmitting} onClick={onDisconsent}>
                            {t("auth.ContractConsent.DisconsentButton")}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    );
}
