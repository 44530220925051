const FetchOptions = {
    apiUrl: null,
    project: null,
    language: null,
    appVersion: null,
    statusCallbacks: [],
};

export async function initFetchOptions(apiUrl, project, appVersion) {
    FetchOptions.apiUrl = apiUrl;
    FetchOptions.project = project;
    FetchOptions.appVersion = appVersion;
}

export async function setFetchLanguage(language) {
    FetchOptions.language = language;
}

export async function addStatusCallback(callback) {
    FetchOptions.statusCallbacks.push(callback);
}

export async function reportStatus(state) {
    FetchOptions.statusCallbacks.forEach((c) => c(state));
}

export default FetchOptions;
export const DEPLOY_IN_PROGRESS = "deploy-in-progress";
export const INVALID_VERSION = "invalid-version";
