import { prescriptionScheduleUpdate } from "../../redux/prescriptionSlice";
import { useSelector, useDispatch } from "react-redux";
import { getDayTimesOptionsArray } from "../../codebooks/dayTimes";
import "twin.macro";
import { useTranslation } from "react-i18next";
import ComboBox from "../codebooks/ComboBox";

export default function TimeSchedule({ scheduleId }) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const time = useSelector((state) => state.prescriptions.schedules.byId[scheduleId].time);

    return (
        <div>
            <ComboBox
                options={getDayTimesOptionsArray(i18n.language)}
                value={time}
                onChange={dispatchOptionChange}
                autocompleteProps={{
                    label: t("prescriptions.Schedules.Time"),
                    freeSolo: true,
                    placeholder: t("prescriptions.Schedules.WithoutTime"),
                }}
                tw="width[11ch]"
            />
        </div>
    );

    function dispatchOptionChange(option) {
        dispatch(prescriptionScheduleUpdate({ scheduleId, updated: { time: option } }));
    }
}
