import App from "@profilog/misc/commons/components/App";
import MedilogHome from "./pages/MedilogHome";
import MedilogAboutService from "./pages/MedilogAboutService";
import MedilogAboutUs from "./pages/MedilogAboutUs";
import MedilogUseCases from "./pages/MedilogUseCases";
import MedilogChildCamps from "./pages/MedilogChildCamps";
import SharedPublicPages from "../../shared/SharedPublicPages";
import AuthPublicPages from "@profilog/misc/auth/AuthPublicPages";
import MedilogPublicLayout from "./MedilogPublicLayout";
import NotFound from "../../NotFound";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router";
import MedilogLogo from "./components/MedilogLogo";
import GenericMainLayout from "../generic/GenericMainLayout";
import GenericRecordsLayout from "../generic/GenericRecordsLayout";
import "./MedilogApp.scss";
import CpzRegistration from "@profilog/front/registration/pages/Registration";
import SuccessfullyRegistered from "@profilog/front/registration/pages/SuccessfullyRegistered";
import { getMainAppPages } from "../../main/getMainAppPages";

export default function MedilogApp() {
    const routes = [
        {
            element: <MedilogPublicLayout />,
            children: [
                { index: true, element: <MedilogHome /> },
                {
                    element: <MedilogPublicSection />,
                    children: [
                        { path: "use-cases", element: <MedilogUseCases /> },
                        { path: "about-service", element: <MedilogAboutService /> },
                        { path: "about-us", element: <MedilogAboutUs /> },
                        {
                            path: "information-security",
                            element: <Navigate to="../gdpr-consent" />,
                        },
                        { path: "child-camps", element: <MedilogChildCamps /> },
                        ...AuthPublicPages,
                        ...SharedPublicPages,
                        { path: "registration", element: <CpzRegistration /> },
                        { path: "successfully-registered", element: <SuccessfullyRegistered /> },
                        { path: "*", element: <NotFound /> },
                    ],
                },
            ],
        },
        ...getMainAppPages(<GenericMainLayout logo={<MedilogLogo />} />, GenericRecordsLayout),
        { path: "mobile-app", children: getMainAppPages(<MedilogMobileLayout />, GenericRecordsLayout) },
    ];
    return <App routes={routes} />;
}

function MedilogPublicSection() {
    return (
        <section className="mt-3">
            <div className="container">
                <div className="col-12">
                    <Outlet />
                </div>
            </div>
        </section>
    );
}

function MedilogMobileLayout() {
    return (
        <div className="mobile">
            <Outlet />
        </div>
    );
}
