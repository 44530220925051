import { useTranslation } from "react-i18next";
import "./NotFound.scss";

export default function NotFound() {
    const { t } = useTranslation();

    return (
        <div className="page-not-found">
            <h1>#404</h1>
            <h2>{t("global.PageNotFound")}</h2>
        </div>
    );
}
