import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { apiGet } from "../../utils/useFetch";
import { getErrorMessage } from "../../errors/ValidationError";
import { getHostName, getQuery } from "../../utils/Url";
import { useAuth } from "../useAuth";
import ApiErrorsList from "../../errors/ApiErrorsList";
import FetchOptions from "../../utils/FetchOptions";
import PasswordInput from "./PasswordInput";
import { Alert, Button, TextField, FormControlLabel, Checkbox } from "@mui/material";
import ResetPasswordDialog from "./ResetPasswordDialog";
import tw, { css } from "twin.macro";

import { Navigate } from "react-router";
import GoogleSignInButton from "./GoogleSignInButton";

const brandColors = {
    google: css`
        color: #d24228;

        &:hover {
            color: lighten(#d24228, 10%);
        }
    `,

    facebook: css`
        color: #305c99;

        &:hover {
            color: lighten(#305c99, 10%);
        }
    `,
    twitter: css`
        color: #00cdff;

        &:hover {
            color: lighten(#00cdff, 10%);
        }
    `,
};

export default function LoginForm({
    title,
    loggedInUrl,
    additionalContent,
    useGoogleBrand,
    displayOnlyAfterFailedLoginTest,
    disableElevation,
    layout = DefaultLayout,
    loginText = null,
    onInsteadOfSetContext,
}) {
    const Layout = layout;

    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        errors,
        formState: { isSubmitting },
    } = useForm();
    const { login, loginTest, loginTestState, loginWithUserToken, isInvalidLogin, apiErrors, currentUserData } =
        useAuth();
    const emailRef = useRef(null);

    const query = getQuery();
    const loginParam = query.get("login");
    const userNameParam = query.get("username");
    const loginTokenParam = query.get("logintoken");

    useEffect(() => {
        if (currentUserData != null || loginParam != null) return;

        if (userNameParam != null && loginTokenParam != null && loginWithUserToken(userNameParam, loginTokenParam))
            return;

        loginTest();

        // eslint-disable-next-line
    }, []);

    if (currentUserData) return <Navigate to={loggedInUrl} />;

    if (displayOnlyAfterFailedLoginTest && loginTestState !== false) return null;

    return (
        <Layout>
            <form onSubmit={handleSubmit(submitLogin)}>
                <div>
                    <TextField
                        size="small"
                        label={t("global.Form.Email")}
                        inputProps={{
                            name: "loginEmail",
                            autoComplete: "username",
                            defaultValue: loginParam,
                        }}
                        inputRef={(ref) => {
                            register(ref, { required: t("auth.EmailRequired") });
                            emailRef.current = ref;
                        }}
                        helperText={getErrorMessage(errors, "loginEmail")}
                        fullWidth
                    />
                </div>

                <div tw="mt-4">
                    <PasswordInput
                        name="loginPassword"
                        size="small"
                        label={t("global.Form.Password")}
                        autoComplete="current-password"
                        inputRef={register({ required: t("auth.PasswordRequired") })}
                        helperText={getErrorMessage(errors, "loginPassword")}
                        fullWidth
                    />
                </div>

                <FormControlLabel
                    disableTypography
                    tw="text-xs"
                    label={t("auth.Login.StayLoggedIn")}
                    control={<Checkbox inputRef={register} inputProps={{ name: "loginPermanent" }} />}
                />

                {isInvalidLogin && (
                    <Alert severity="error" tw="mb-3">
                        {t("auth.Login.InvalidLogin")}
                    </Alert>
                )}
                <ApiErrorsList apiErrors={apiErrors} />

                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    tw="w-full"
                    disableElevation={disableElevation}
                >
                    {loginText ?? t("auth.Login.Login")}
                </Button>

                <ExternalLoginProviders useGoogleBrand={useGoogleBrand} />
            </form>
            <div css={[tw`flex items-center mt-4`, additionalContent ? tw`justify-between` : tw`justify-center`]}>
                <ResetPasswordDialog
                    getUserName={() => emailRef?.current?.value}
                    trigger={(open) => (
                        <Button size="small" onClick={open}>
                            {t("auth.Login.ForgotPassword")}
                        </Button>
                    )}
                />
                {additionalContent}
            </div>
        </Layout>
    );

    async function submitLogin(data) {
        login(data["loginEmail"], data["loginPassword"], data["loginPermanent"], onInsteadOfSetContext);
    }
}

function ExternalLoginProviders({ useGoogleBrand }) {
    const { t } = useTranslation();
    const query = getQuery();
    const externalLoginErrorCode = query.get("externalLoginError");

    const [externalLoginProviders, setExternalLoginProviders] = useState([]);

    useEffect(() => {
        async function getExternalLoginProviders() {
            var externalLoginProviders = await apiGet("/web/user/external-login-providers");
            if (externalLoginProviders.isOk) {
                setExternalLoginProviders(externalLoginProviders.json);
            }
        }
        getExternalLoginProviders();
    }, []);

    return (
        <Fragment>
            {externalLoginProviders !== null && externalLoginProviders.length > 0 && (
                <div tw="flex justify-between mt-4">
                    {externalLoginProviders.map((provider) => (
                        <Fragment>
                            {provider.systemName === "Google" && useGoogleBrand && (
                                <GoogleSignInButton
                                    key={provider.name}
                                    onClick={(e) => {
                                        externalLogin(provider.providerName);
                                        e.preventDefault();
                                    }}
                                ></GoogleSignInButton>
                            )}
                            {(provider.systemName !== "Google" || !useGoogleBrand) && (
                                <Button
                                    size="small"
                                    key={provider.name}
                                    onClick={(e) => {
                                        externalLogin(provider.providerName);
                                        e.preventDefault();
                                    }}
                                    tw="font-size[10px]"
                                    css={brandColors[provider.systemName.toLowerCase()]}
                                >
                                    <i className={`fab fa-${provider.systemName.toLowerCase()}`} tw="mr-1"></i>
                                    <span tw="font-bold">{provider.displayName}</span>
                                </Button>
                            )}
                        </Fragment>
                    ))}
                </div>
            )}
            {externalLoginErrorCode && <Alert severi="error">{getExternalLoginError(externalLoginErrorCode, t)}</Alert>}
        </Fragment>
    );
}

function DefaultLayout({ children }) {
    return <div tw="rounded-xl shadow-updown p-8 bg-white bg-opacity-90">{children}</div>;
}

function externalLogin(provider) {
    const query = new URLSearchParams({
        provider: provider,
        returnUrl: getHostName() + process.env.PUBLIC_URL,
        projectCode: FetchOptions.project,
    });
    window.location.href = `${FetchOptions.apiUrl}/web/user/login-by-external-provider?${query}`;
}

function getExternalLoginError(errorCode, t) {
    switch (errorCode) {
        case "denied":
            return t("auth.External.Error.Denied");
        case "external":
            return t("auth.External.Error.External");
        case "unknown":
            return t("auth.External.Error.Unknown");
        default:
            return null;
    }
}
