import { useTranslation } from "react-i18next";
import { useModal } from "../commons/components/Modal";
import FoodList from "./components/FoodList";
import CaloricsTable from "./components/CaloricsTable";
import "./EnergyIntakeModal.scss";
import { format, add } from "date-fns";

export function useEnergyIntakeModal() {
    const { t } = useTranslation();
    const { isModalOpen, openModal, Modal } = useModal();

    return {
        openEnergyIntakeModal: openModal,
        isEnergyIntakeModalOpen: isModalOpen,
        EnergyIntakeModal: EnergyIntakeModal,
    };

    function EnergyIntakeModal({ fetchService, selectedDateTime, groupedBy }) {
        if (!isModalOpen) return null;

        const children = (
            <div className="food-box">
                {groupedBy === "day" && (
                    <CaloricsTable fetchService={fetchService} selectedDateTime={selectedDateTime} />
                )}
                {groupedBy !== "day" && (
                    <FoodList fetchService={fetchService} selectedDateTime={selectedDateTime} groupedBy={groupedBy} />
                )}
            </div>
        );

        function getPeriod() {
            var time = "";

            if (groupedBy === "hour") {
                time =
                    " " +
                    format(selectedDateTime, "HH:mm") +
                    " - " +
                    format(add(selectedDateTime, { hours: 1 }), "HH:mm");
            } else if (groupedBy === "minutes") {
                time =
                    " " +
                    format(selectedDateTime, "HH:mm") +
                    " - " +
                    format(add(selectedDateTime, { minutes: 1 }), "HH:mm");
            }

            return " " + format(selectedDateTime, "dd. MM. yyyy") + time;
        }

        return (
            <Modal
                title={t("caloricsTable.EnergyIntakeModalTitle") + getPeriod()}
                dialogClass="calorics-table"
                children={children}
            ></Modal>
        );
    }
}
