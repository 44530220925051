import { useTranslation } from "react-i18next";
import GooglePlayLink from "./GooglePlayLink";

export default function GooglePlayApp({ appUrl }) {
    const { t } = useTranslation();

    return (
        <div>
            <h3 tw="mb-2">{t("extConnectors.GooglePlay.Title")}</h3>
            <div tw="mb-2">{t("extConnectors.GooglePlay.InstallApp")}</div>
            <GooglePlayLink appUrl={appUrl} />
        </div>
    );
}
