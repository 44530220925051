import { useSelector } from "react-redux";
import PrescriptionEdit from "../PrescriptionEdit";
import PrescriptionView from "../PrescriptionView";
import "twin.macro";

export default function PrescriptionListItem({ prescription, allowEdit, onDelete, onSaved, showGroupName = false }) {
    const editing = useSelector((state) => state.prescriptions.prescriptions.byId[prescription.id]?.editing);

    return editing ? (
        <PrescriptionEdit prescriptionId={prescription.id} onSaved={onSaved} />
    ) : (
        <PrescriptionView
            prescription={prescription}
            allowEdit={allowEdit}
            onDelete={onDelete}
            showGroupName={showGroupName}
        />
    );
}
