import AppSettings from "@profilog/front/app/AppSettings";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Fragment } from "react";

export default function MedilogStaticLinks() {
    const { t } = useTranslation();

    return (
        <Fragment>
            {!AppSettings.isPetLog && (
                <li className="nav-item">
                    <NavLink className="nav-link" to="/use-cases">
                        {t("header.Menu.UseCases")}
                    </NavLink>
                </li>
            )}
            <li className="nav-item">
                <NavLink className="nav-link" to="/about-service">
                    {t("header.Menu.AboutService")}
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to="/about-us">
                    {t("header.Menu.AboutUs")}
                </NavLink>
            </li>
        </Fragment>
    );
}
