import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

export default function DailyBpmTable({ fetchService, dateFrom, dateTo }) {
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const { getStepsAndBpmDataAsync } = fetchService;
    const [data, setData] = useState([]);

    useEffect(() => {
        async function getData() {
            setIsLoading(true);
            let response = await getStepsAndBpmDataAsync(dateFrom, dateTo);
            if (response.isOk) {
                setData(response.json);
            }
            setIsLoading(false);
        }

        if (open) getData();
    }, [open, dateFrom, dateTo]);

    return (
        <div tw="pb-2">
            <div tw="text-center">
                <Button
                    size="small"
                    onClick={() => {
                        if (!open) setData([]);
                        setOpen(!open);
                    }}
                    tw="underline hover:underline"
                >
                    {t("recordsVisualization.HeartRateOverview")}
                </Button>
            </div>
            {open &&
                (isLoading ? (
                    <div tw="[min-height: 310px] flex justify-center items-center">
                        <CircularProgress />
                    </div>
                ) : (
                    <div tw="w-full px-4">
                        <Table tw="w-full">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("recordsVisualization.DailyBpmTable.Date")}</TableCell>
                                    <TableCell align="right">{t("recordsVisualization.DailyBpmTable.Steps")}</TableCell>
                                    <TableCell align="right">
                                        {t("recordsVisualization.DailyBpmTable.MinBpm")}
                                    </TableCell>
                                    <TableCell align="right">
                                        {t("recordsVisualization.DailyBpmTable.MaxBpm")}
                                    </TableCell>
                                    <TableCell align="right">
                                        {t("recordsVisualization.DailyBpmTable.AvgBpm")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell scope="row">
                                            {new Date(item.date).toLocaleDateString(i18n.language, {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                            })}
                                        </TableCell>
                                        <TableCell align="right">{item.steps}</TableCell>
                                        <TableCell align="right">{item.minBpm}</TableCell>
                                        <TableCell align="right">{item.maxBpm}</TableCell>
                                        <TableCell align="right">{item.avgBpm}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                ))}
        </div>
    );
}
