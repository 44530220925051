import AppSettings from "@profilog/front/app/AppSettings";
import DrugCalendar from "@profilog/prescriptions/components/calendar/DrugCalendar";
import "twin.macro";
import { useTranslation } from "react-i18next";

export default function Medication() {
    const { t } = useTranslation();

    return (
        <div tw="md:w-8/12 lg:w-6/12 mx-auto">
            <h2 tw="text-center">{t("prescriptions.Medication")}</h2>
            <DrugCalendar medicalSubjectId={AppSettings.user.userMedicalSubjectId} />
        </div>
    );
}
