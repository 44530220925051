import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import useCooperationRequests from "../hooks/useCooperationRequests";
import { Fragment, useState } from "react";
import { Alert, Autocomplete, Button, TextField } from "@mui/material";
import { getErrorMessage } from "@profilog/misc/errors/ValidationError";

export default function CooperationRequestCard({ isMultipleSendAllowed, onRequestSent }) {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        formState: { isSubmitting },
    } = useForm();
    const { groups, ApiGetErrors, sendRequest, ApiSendRequestErrors, wasRequestSent, setWasRequestSent } =
        useCooperationRequests(onRequestSent);
    const [group, setGroup] = useState(null);
    const [openPopover, setOpenPopover] = useState(false);

    register("groupId", { required: t("communications.CooperationRequests.Card.SelectTherapist") });

    return (
        <Fragment>
            <h3>{t("communications.CooperationRequests.Card.CooperationWithTherapist")}</h3>
            <p>{t("communications.CooperationRequests.Card.Description")}</p>
            <ApiGetErrors />
            <ApiSendRequestErrors />
            {wasRequestSent ? (
                <Fragment>
                    <Alert severity="success" tw="mb-2">
                        {t("communications.CooperationRequests.Card.RequestSentSuccessfully")}
                    </Alert>
                    {isMultipleSendAllowed && (
                        <Button variant="contained" onClick={() => setWasRequestSent(false)}>
                            {t("communications.CooperationRequests.Card.SendAnotherRequest")}
                        </Button>
                    )}
                </Fragment>
            ) : (
                <form onSubmit={handleSubmit(sendRequest)}>
                    <div>
                        <Autocomplete
                            tw="mb-2"
                            open={openPopover}
                            size="small"
                            popupIcon={null}
                            noOptionsText={t("global.NoOptions")}
                            value={group}
                            onChange={(_, newValue) => {
                                setValue("groupId", newValue ? newValue.id : null, { shouldValidate: true });
                                setGroup(newValue);
                            }}
                            options={groups.map((g) => ({ id: g.groupId, label: g.groupName }))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(e) => setOpenPopover(e.target.value.length > 2)}
                                    error={getErrorMessage(errors, "groupId") ? true : false}
                                    helperText={getErrorMessage(errors, "groupId")}
                                />
                            )}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            autoSelect
                            autoHighlight
                            blurOnSelect
                            onBlur={() => setOpenPopover(false)}
                        />
                    </div>
                    <div tw="flex flex-row-reverse">
                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                            {t("communications.CooperationRequests.Card.SendRequest")}
                        </Button>
                    </div>
                </form>
            )}
        </Fragment>
    );
}
