import MediLogLogo from "../assets/images/logo.png";
import MediLogLogoCs from "../assets/images/logo.cs.png";
import { useTranslation } from "react-i18next";

export default function MedilogLogo() {
    const { i18n } = useTranslation();
    let logo;

    switch (i18n.language) {
        case "cs":
            logo = MediLogLogoCs;
            break;
        default:
            logo = MediLogLogo;
            break;
    }

    return <img src={logo} alt="Medi-log" />;
}
