import { useEffect, useState } from "react";
import { useFetch } from "@profilog/misc/utils/useFetch";
import { useTranslation } from "react-i18next";
import { getToken } from "@profilog/misc/utils/Url";

export default function CooperationRequestRejection() {
    const { t } = useTranslation();
    const [isInProgress, setIsInProgress] = useState(true);
    const { apiPut, ApiErrors } = useFetch();
    const [isOk, setIsOk] = useState(null);

    useEffect(() => {
        async function rejectRequest() {
            const token = getToken();
            const response = await apiPut(`/web/cooperations/requests/reject?token=${token}`);
            setIsOk(response.isOk);
            setIsInProgress(false);
        }

        rejectRequest();
    }, []);

    return (
        <div className="univ__body">
            <div className="container">
                {isInProgress ? (
                    t("global.PleaseWait")
                ) : isOk ? (
                    t("communications.CooperationRequests.Rejection.RequestRejected")
                ) : (
                    <ApiErrors />
                )}
            </div>
        </div>
    );
}
