import { useEffect, useState, Fragment } from "react";
import { useFetch } from "@profilog/misc/utils/useFetch";
import AddParameter from "./AddParameter";
import { apiGet, apiPost } from "@profilog/misc/utils/useFetch";
import AppSettings from "../../app/AppSettings";
import { useTranslation } from "react-i18next";
import ApiErrorsModal from "@profilog/misc/errors/ApiErrorsModal";
import NoParametersSection from "./edit/NoParametersSection";
import NotLoadedSection from "./edit/NotLoadedSection";
import SavedSection from "./edit/SavedSection";
import RecordEditHeader from "./edit/RecordEditHeader";
import ParametersSection from "./edit/ParametersSection";
import { parameterTypes } from "@profilog/parameters/codebooks/parameterTypes";
import "./RecordEdit.scss";
import {
    createRecordPanelManualSource,
    createSaveRequest,
    mapToParameterWithRecord,
    mapToValue,
} from "@profilog/records/recordMappings";
import NothingToSaveDialog from "@profilog/front/records/components/edit/NothingToSaveDialog";

export default function RecordEdit({
    selectedMedicalSubjectId,
    setSelectedMedicalSubjectId,
    onSaved,
    onNewRecordClick,
    disableParameterChange,
    hideTitle = false,
    hideParameterInfo = false,
    renderSaveButton,
    isVocallsEnabled = false,
    renderBellowParams,
    hideSavedText = false,
    allowEmptySave = false,
}) {
    const { t, i18n } = useTranslation();
    const { apiPut: apiPutMedicalSubjectParameter } = useFetch();
    const [isLoaded, setIsLoaded] = useState(false);
    const [apiGetErrors, setApiGetErrors] = useState(null);
    const [apiPostErrors, setApiPostErrors] = useState(null);
    const [savedRecordDate, setSavedRecordDate] = useState(null);
    const [nothingToSave, setNothingToSave] = useState(false);
    const [parameters, setParameters] = useState(null);
    const [medicalSubjects, setMedicalSubjects] = useState(null);
    const [recordDate, setRecordDate] = useState(null);
    const [recordTime, setRecordTime] = useState(null);
    const [isUserVocallsTester, setIsUserVocallsTester] = useState(false);
    const [isHidden, setIsHidden] = useState(AppSettings.isPatientPreview);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [selectedMedicalSubjectId, i18n.language]);

    useEffect(() => {
        async function getUserAsync() {
            let response = await apiGet("/web/user-profile");
            if (response.isOk) setIsUserVocallsTester(response.json.isVocallsTester);
        }
        getUserAsync();
    }, []);

    // Loading / Load error
    if (!isLoaded) return <NotLoadedSection apiGetErrors={apiGetErrors} />;

    // Saved
    if (savedRecordDate !== null)
        return <SavedSection savedRecordDate={savedRecordDate} onNewRecordClick={newRecord} hideText={hideSavedText} />;

    const header = hideSavedText ? null : (
        <RecordEditHeader
            hideTitle={hideTitle}
            isHidden={isHidden}
            setIsHidden={setIsHidden}
            hasVisibleParameters={parameters.visibleParameters.length > 0}
            medicalSubjects={medicalSubjects}
            handleMedicalSubjectChanged={handleMedicalSubjectChanged}
            selectedMedicalSubjectId={selectedMedicalSubjectId}
        />
    );

    if (isHidden) return header;

    const addParameter = (
        <AddParameter
            parameters={parameters.addableParameters}
            noteParameterId={parameters.noteParameterId}
            recordDateParameterId={parameters.recordDateParameterId}
            onAddParameter={handleAddParameter}
            onParameterCreated={handleParameterCreated}
            isEmpty={parameters.visibleParameters.length === 0}
            medicalSubjectId={selectedMedicalSubjectId}
        />
    );

    // Parameters
    if (parameters.visibleParameters.length === 0)
        return <NoParametersSection renderHeader={header} renderAddParameter={addParameter} />;
    else
        return (
            <Fragment>
                <ApiErrorsModal
                    title={t("records.Edit.SaveError.Title")}
                    apiErrors={apiPostErrors}
                    resetApiErrors={() => setApiPostErrors(null)}
                />
                {nothingToSave && <NothingToSaveDialog onClose={() => setNothingToSave(false)} />}
                <ParametersSection
                    renderHeader={header}
                    renderAddParameter={addParameter}
                    disableParameterChange={disableParameterChange}
                    parameters={parameters}
                    onSaveClick={handleSave}
                    isVocallsEnabled={isVocallsEnabled}
                    isUserVocallsTester={isUserVocallsTester}
                    getMedicalSubjectId={getMedicalSubjectId}
                    getRecordDate={getRecordDate}
                    onSaved={onSaved}
                    hideParameterInfo={hideParameterInfo}
                    onHideParameter={hideParameter}
                    onSetValue={handleSetValue}
                    recordDate={recordDate}
                    setRecordDate={setRecordDate}
                    recordTime={recordTime}
                    setRecordTime={setRecordTime}
                    renderSaveButton={renderSaveButton}
                    onValueChanged={handleValueChanged}
                    renderBellowParams={renderBellowParams}
                />
            </Fragment>
        );

    async function fetchData() {
        const params = {};
        if (selectedMedicalSubjectId != null) params.medicalSubjectId = selectedMedicalSubjectId;

        let url = "/manual-record-entry?" + new URLSearchParams(params);

        let response = await apiGet(url);
        if (!response.isOk) {
            setApiGetErrors(response.errors);
            return;
        }

        let editModel = response.json;

        let recordDateParameter = {
            id: -1,
            type: parameterTypes.recordDate,
            name: t("records.Edit.RecordDate"),
            systemName: "RecordDate",
            sortKey: "00031-RecordDate",
            isOwn: false,
            isHiddenInAdd: true,
            isForAnimal: true,
            isForHuman: true,
            descs: {},
        };

        if (editModel.settings.isRecordDateDirectlyVisible) editModel.visibleParameters.push(recordDateParameter);
        else editModel.addableParameters.push(recordDateParameter);

        setParameters({
            visibleParameters: editModel.visibleParameters.map((p) => ({
                ...p,
                value: mapToValue(p.type, p.value, p.textValue),
            })),
            addableParameters: editModel.addableParameters.map((p) => ({
                ...p,
                value: mapToValue(p.type, p.value, p.textValue),
            })),
            rootGroup: editModel.rootGroup,
            noteParameterId: editModel.noteParameterId,
            recordDateParameterId: recordDateParameter.id,
        });
        setMedicalSubjects({
            personSubjects: editModel.relatedPersonMedicalSubjects,
            animalSubjects: editModel.relatedAnimalMedicalSubjects,
        });

        setIsLoaded(true);
    }

    async function handleMedicalSubjectChanged(medicalSubjectId) {
        medicalSubjectId = Number(medicalSubjectId);
        setSelectedMedicalSubjectId(medicalSubjectId);
        setIsLoaded(false);
    }

    function handleValueChanged(parameterId, newValue, isSetForced = false) {
        console.log(`parameter ${parameterId} changed to ${newValue}`);
        setParameters((parameters) => ({
            ...parameters,
            visibleParameters: parameters.visibleParameters.map((p) => {
                if (p.id !== parameterId) return p;
                let result = { ...p, value: newValue };
                if (isSetForced) result.version = result.version === undefined ? 0 : result.version + 1;
                return result;
            }),
        }));
    }

    async function handleAddParameter(parameterId) {
        if (parameterId > 0) {
            let saveMedicalSubjectParameterDto = {
                medicalSubjectId: selectedMedicalSubjectId,
                parameterId: parameterId,
                visibility: 1,
            };
            await apiPutMedicalSubjectParameter("/medical-subject-parameters", saveMedicalSubjectParameterDto);
        }

        setParameters((parameters) => {
            const parameter = parameters.addableParameters.find((p) => p.id === parameterId);
            return {
                ...parameters,
                visibleParameters: parameters.visibleParameters.concat(parameter).sort(paramsSort),
                addableParameters: parameters.addableParameters.filter((p) => p.id !== parameterId),
            };
        });
    }

    function handleParameterCreated(parameter) {
        setParameters((parameters) => ({
            ...parameters,
            visibleParameters: parameters.visibleParameters.concat(parameter),
        }));
    }

    function handleSetValue(parameterId, newValue) {
        handleValueChanged(parameterId, newValue, true);
    }

    async function hideParameter(parameterId) {
        let saveMedicalSubjectParameterDto = {
            medicalSubjectId: selectedMedicalSubjectId,
            parameterId: parameterId,
            visibility: 0,
        };
        await apiPutMedicalSubjectParameter("/medical-subject-parameters", saveMedicalSubjectParameterDto);

        setParameters((parameters) => {
            const parameter = parameters.visibleParameters.find((p) => p.id === parameterId);

            if (parameter.type === parameterTypes.recordDate) {
                setRecordDate(null);
                setRecordTime(null);
            }

            return {
                ...parameters,
                visibleParameters: parameters.visibleParameters.filter((p) => p.id !== parameterId),
                addableParameters: parameters.addableParameters.concat(parameter),
            };
        });
    }

    async function newRecord() {
        if (onNewRecordClick) onNewRecordClick();
        setSavedRecordDate(null);
        setIsLoaded(false);
        await fetchData();
    }

    function getMedicalSubjectId() {
        // u PetLogu je třeba vždy poslat id prvního vybraného uživatele (nikdy se neloguje na přihlášeného uživatele).
        return AppSettings.isPetLog && selectedMedicalSubjectId === null && medicalSubjects.animalSubjects.length > 0
            ? medicalSubjects.animalSubjects[0].medicalSubjectId
            : selectedMedicalSubjectId;
    }

    async function handleSave() {
        let date = getRecordDate();
        if (!date) date = new Date();

        const medicalSubjectId = getMedicalSubjectId();
        const synchronizeRecordsDto = createSaveRequest(medicalSubjectId);
        const source = createRecordPanelManualSource();

        synchronizeRecordsDto.recordGroups = parameters.visibleParameters
            .filter((p) => p.type !== parameterTypes.recordDate && p.value !== null)
            .map((p) => mapToParameterWithRecord(p.systemName, p.type, p.value, null, date, date, source));

        if (synchronizeRecordsDto.recordGroups.length === 0) {
            if (!allowEmptySave) {
                setNothingToSave(true);
                return;
            }
        } else {
            const response = await apiPost(`/v2/medical-subjects/records/synchronize`, synchronizeRecordsDto);
            if (!response.isOk) {
                setApiPostErrors(response.errors);
                return;
            }
        }

        setRecordDate(null);
        setRecordTime(null);
        setSavedRecordDate(new Date());
        if (onSaved) onSaved();
    }

    function getRecordDate() {
        let resultRecordDate = null;

        if (recordDate !== null) {
            resultRecordDate = recordDate;
            if (recordTime !== null) {
                const time = recordTime.split(":");
                resultRecordDate.setHours(time[0]);
                resultRecordDate.setMinutes(time[1]);
            }
        }

        return resultRecordDate;
    }
}

function paramsSort(param1, param2) {
    if (param1.isHiddenInAdd || param2.isHiddenInAdd) {
        let result = param1.isHiddenInAdd - param2.isHiddenInAdd;
        if (result !== 0) return result;

        if (param1.systemName === "Note") return 1;
        if (param2.systemName === "Note") return -1;
    }

    return 0;
}
