import React, { useEffect, useRef } from "react";
import MwHorizontalSlider from "./MwHorizontalSlider";
import "./MwHorizontalSlider.css";

export const defaultHorizontalSliderOptions = {
    hideHandleWhenNotSelected: undefined,
    notSelectedValue: undefined,
};

function _HorizontalSlider({
    mode,
    min,
    max,
    step,
    displayDecimals,
    unit,
    value,
    isNullAllowed,
    defaultValue,
    onValueChanged,
}) {
    const elementRef = useRef(null);

    useEffect(() => {
        new MwHorizontalSlider(
            elementRef.current,
            {
                mode: mode,
                min: min,
                max: max,
                step: step,
                displayDecimals: displayDecimals,
                decimalSeparator: ",",
                onValueChanged: onValueChanged,
                isNullAllowed: isNullAllowed,
                defaultValue: defaultValue,
                ...defaultHorizontalSliderOptions,
            },
            [],
        );
    });

    return (
        <div ref={elementRef} className="hs-container">
            <input type="text" className="hs-value" inputMode="decimal" defaultValue={value} />
            <span className="hs-unit">{unit}</span>
            <span className="hs-handle"></span>
            <div className="hs-axis-container">
                <div className="hs-axis"></div>
            </div>
            <div className="hs-overlay"></div>
        </div>
    );
}

// Jediný update komponenty je při manuálním zvýšení version - jinak se slider při zpětném nastavení zaorkouhlené value posune.
const HorizontalSlider = React.memo(
    _HorizontalSlider,
    (prevProps, nextProps) => nextProps.version === undefined || nextProps.version === prevProps.version,
);

export default HorizontalSlider;
