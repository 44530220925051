import GoogleNormalImg from "./assets/images/google-signin-normal.png";
import GoogleFocusImg from "./assets/images/google-signin-focus.png";
import GooglePressedImg from "./assets/images/google-signin-pressed.png";
import { styled } from "twin.macro";

const GoogleSignInButton = styled.button`
    width: 191px;
    height: 46px;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;

    background: url(${GoogleNormalImg}) top left no-repeat;

    &:focus {
        background: url(${GoogleFocusImg}) top left no-repeat;
    }

    &:active {
        background: url(${GooglePressedImg}) top left no-repeat;
    }
`;
export default GoogleSignInButton;
