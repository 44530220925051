import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import NewMessage from "./NewMessage";
import { Dialog, DialogContent, DialogTitle, /*useMediaQuery,*/ Paper } from "@mui/material";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";
import { keyframes } from "@mui/system";
import { css } from "twin.macro";
import Draggable from "react-draggable";
import CloseNewMessageDialog from "./CloseNewMessageDialog";

export default function NewMessageDialog({
    trigger,
    medicalSubjectId,
    groupId,
    isProfi,
    onSave,
    fixedDate,
    isCommentDef,
    hideCommentMessageChoice,
    isForAnswer,
    isPatientPreview,
    profilogUserId,
    profilogUserPersonFullName,
}) {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [isDialogClosing, setIsDialogClosing] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [isComment, setIsComment] = useState(isCommentDef || isPatientPreview);
    const shakeDurationInSeconds = 0.7;

    const shaking = keyframes`
from, 20%, 53%, 80%, to {
  transform: translate3d(0,0,0);
}

40%, 43% {
  transform: translate3d(0, -30px, 0);
}

70% {
  transform: translate3d(0, -15px, 0);
}

90% {
  transform: translate3d(0,-4px,0);
}
`;
    const shakingDialog = css`
        animation: ${shaking} ${shakeDurationInSeconds}s linear;
    `;

    return (
        <Fragment>
            {trigger && trigger(() => setOpen(true))}
            <Dialog
                open={open}
                onClose={toggleIsDialogClosing}
                // fullScreen={fullScreen}
                fullWidth
                maxWidth="sm"
                PaperComponent={PaperComponent}
                aria-labelledby="new-message-draggable-dialog-title"
                hideBackdrop
                disableScrollLock
                css={isDialogClosing && shakingDialog}
                // keepMounted
            >
                <DialogCloseButton
                    onClick={(e) => handleClose(e, true)}
                    sx={{
                        color: (theme) => (isPatientPreview ? "#fff" : theme.palette.primary.contrastText),
                    }}
                />
                <DialogTitle
                    tw="pr-8"
                    sx={{
                        cursor: "move",
                        backgroundColor: (theme) => (isPatientPreview ? "#2b64af" : theme.palette.primary.main),
                        color: (theme) => theme.palette.primary.contrastText,
                        transition: (theme) => theme.transitions.create(["background-color"], { duration: 140 }),
                        "&:hover": {
                            backgroundColor: (theme) => (isPatientPreview ? "#1e467a" : theme.palette.primary.dark),
                        },
                    }}
                    id="new-message-draggable-dialog-title"
                >
                    {(isComment
                        ? t("shCommunications.Messages.NewComment")
                        : t("shCommunications.Messages.NewMessage")) +
                        (isComment && fixedDate
                            ? ` - ${fixedDate.toLocaleDateString(i18n.language, {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                              })}`
                            : "") +
                        (isPatientPreview && profilogUserId
                            ? " " + `(${t("shCommunications.Messages.ProfilogUser")}: ${profilogUserPersonFullName})`
                            : "")}
                </DialogTitle>
                <DialogContent>
                    <NewMessage
                        medicalSubjectId={medicalSubjectId}
                        groupId={groupId}
                        isProfi={isProfi}
                        onSave={save}
                        fixedDate={fixedDate}
                        isComment={isComment}
                        setIsComment={setIsComment}
                        hideCommentMessageChoice={hideCommentMessageChoice || isPatientPreview}
                        isForAnswer={isForAnswer}
                        isPatientPreview={isPatientPreview}
                        profilogUserId={profilogUserId}
                    />
                </DialogContent>
            </Dialog>
            <CloseNewMessageDialog
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirmClick={(e) => handleClose(e, false)}
            />
        </Fragment>
    );

    function handleClose(event, withConfirmation) {
        if (withConfirmation) {
            event.stopPropagation();
            setConfirmOpen(true);
        } else {
            setOpen(false);
        }
    }

    function save() {
        handleClose();
        if (onSave) onSave();
    }

    function toggleIsDialogClosing() {
        setIsDialogClosing(true);
        setTimeout(() => {
            setIsDialogClosing(false);
        }, shakeDurationInSeconds * 1000 + 500);
    }
}

function PaperComponent(props) {
    return (
        <Draggable
            handle="#new-message-draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            bounds="parent"
        >
            <Paper {...props} />
        </Draggable>
    );
}
