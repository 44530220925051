import { useTranslation } from "react-i18next";

import MobileImage from "../../../assets/images/about/mobile.cs.png";
import HealthyImage from "../../../assets/images/about/healthy.png";
import DoctorImage from "../../../assets/images/about/doctor.png";
import WomanImage from "../../../assets/images/about/woman.png";
import { Fragment } from "react";

export default function About() {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="container">
                <div className="row d-flex">
                    <div className="col-4 d-flex flex-wrap align-items-center img-cnt">
                        <img src={MobileImage} className="my-auto mx-auto" alt="" />
                    </div>
                    <div className="col-7 pb-2 ul-li--greenheart">
                        <h2 id="about__text1" className="h3 font-weight-semibold mt-5 mb-4">
                            {t("about.Text1")}
                        </h2>
                        <div dangerouslySetInnerHTML={{ __html: t("about.Text1Desc") }} />
                    </div>
                </div>
            </div>

            <div className="container--dark" id="zdravy-clovek">
                <div className="container">
                    <div className="row d-flex">
                        <div id="healthy-person" className="col-7 pb-5 ul-li--greenheart">
                            <h2 className="h3 font-weight-semibold mt-5 mb-4">{t("about.Text2")}</h2>
                            <div dangerouslySetInnerHTML={{ __html: t("about.Text2Desc") }} />
                        </div>
                        <div className="col-4 d-flex flex-wrap align-items-center img-cnt">
                            <img src={HealthyImage} className="" alt="illustration - be healthy" />
                        </div>
                    </div>
                </div>
            </div>

            <div id="pacient">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-4 d-flex img-cnt">
                            <img src={WomanImage} className="mt-auto " alt="" />
                        </div>
                        <div id="patient" className="col-7 pt-3 ul-li--greenheart">
                            <h2 className="h3 font-weight-semibold mt-5 mb-4">{t("about.Text3")}</h2>
                            <div dangerouslySetInnerHTML={{ __html: t("about.Text3Desc") }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container--dark" id="lekar">
                <div className="container">
                    <div className="row d-flex">
                        <div id="doctor" className="col-7 py-4 ul-li--greenheart">
                            <h2 className="h3 font-weight-semibold mt-5 mb-4">{t("about.Text4")}</h2>
                            <div dangerouslySetInnerHTML={{ __html: t("about.Text4Desc") }} />
                        </div>
                        <div className="col-4 d-flex img-cnt">
                            <img src={DoctorImage} className="mt-auto" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
