import auth from "@profilog/misc/localization/translations/en/auth.json";
import userSettings from "@profilog/front/localization/translations/en/userSettings.json";
import userSettingsShared from "@profilog/misc/localization/translations/en/userSettings.json";
import patients from "@profilog/front/localization/translations/en/patients.json";
import registration from "@profilog/front/localization/translations/en/registration.json";
import records from "@profilog/front/localization/translations/en/records.json";
import aboutService from "@profilog/front/localization/translations/en/aboutService.json";
import footer from "@profilog/front/localization/translations/en/footer.json";
import groups from "@profilog/front/localization/translations/en/groups.json";
import header from "@profilog/front/localization/translations/en/header.json";
import home from "@profilog/front/localization/translations/en/home.json";
import loginDisplay from "@profilog/front/localization/translations/en/loginDisplay.json";
import about from "@profilog/front/localization/translations/en/about.json";
import aboutUs from "@profilog/front/localization/translations/en/aboutUs.json";
import useCases from "@profilog/front/localization/translations/en/useCases.json";
import persons from "@profilog/front/localization/translations/en/persons.json";
import sharing from "@profilog/front/localization/translations/en/sharing.json";
import cpz from "@profilog/front/localization/translations/en/cpz.json";
import obefis from "@profilog/front/localization/translations/en/obefis.json";
import sps from "@profilog/front/localization/translations/en/sps.json";
import extConnectors from "@profilog/front/localization/translations/en/extConnectors.json";
import prescriptions from "@profilog/front/localization/translations/en/prescriptions.json";
import userInitialization from "@profilog/front/localization/translations/en/userInitialization.json";
import icudemo from "@profilog/front/localization/translations/en/icudemo.json";

import apiResult from "@profilog/misc/localization/translations/en/apiResult.json";
import global from "@profilog/misc/localization/translations/en/global.json";
import recordsVisualization from "@profilog/misc/localization/translations/en/recordsVisualization.json";
import caloricsTable from "@profilog/misc/localization/translations/en/caloricsTable.json";
import apiStatusCheck from "@profilog/misc/localization/translations/en/apiStatusCheck.json";
import communications from "@profilog/front/localization/translations/en/communications.json";
import shCommunications from "@profilog/misc/localization/translations/en/communications.json";
import shNotifications from "@profilog/misc/localization/translations/en/notifications.json";
import feedback from "@profilog/misc/localization/translations/en/feedback.json";
import ecg from "@profilog/misc/localization/translations/en/ecg.json";
import surveys from "@profilog/surveys/localization/translations/en/surveys.json";
import activitiesVisualization from "@profilog/misc/localization/translations/en/activitiesVisualization.json";

export default {
    auth,
    userSettings: { ...userSettings, ...userSettingsShared },
    patients,
    registration,
    records,
    recordsVisualization,
    aboutService,
    footer,
    global,
    groups,
    header,
    home,
    loginDisplay,
    about,
    aboutUs,
    apiResult,
    useCases,
    persons,
    sharing,
    cpz,
    obefis,
    sps,
    caloricsTable,
    apiStatusCheck,
    extConnectors,
    userInitialization,
    communications,
    shCommunications,
    shNotifications,
    feedback,
    ecg,
    prescriptions,
    icudemo,
    surveys,
    activitiesVisualization,
};
