import { Alert } from "@mui/material";
import { translateError } from "./translateError";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import "./ApiErrorsList.css";

export default function ApiErrorsList({ apiErrors, translationPrefix }) {
    const { t } = useTranslation();

    return (
        <Fragment>
            {apiErrors && apiErrors.length > 0 && (
                <div className="api-errors" tw="mb-2">
                    {apiErrors.map((e, index) => (
                        <Alert key={index} severity="error">
                            {translateError(t, e, translationPrefix)}
                        </Alert>
                    ))}
                </div>
            )}
        </Fragment>
    );
}
