import ParamComponent from "./ParamComponent";
import ParameterInfo from "../ParameterInfo";

export default function VisibleParameters({
    parameters,
    hideParameterInfo,
    onHideParameter,
    onSetValue,
    disableParameterChange,
    recordDate,
    setRecordDate,
    recordTime,
    setRecordTime,
    onValueChanged,
}) {
    return parameters.visibleParameters.map((parameter) => (
        <div key={parameter.id} tw="flex items-center gap-0.5 mb-2">
            <div tw="w-3/12 break-words">{parameter.name}</div>
            <div tw="w-8/12">
                <ParamComponent
                    parameter={parameter}
                    onValueChanged={onValueChanged}
                    recordDate={recordDate}
                    setRecordDate={setRecordDate}
                    recordTime={recordTime}
                    setRecordTime={setRecordTime}
                />
            </div>
            <div tw="w-1/12 flex justify-end">
                {!hideParameterInfo && (
                    <ParameterInfo
                        parameter={parameter}
                        onHideParameter={onHideParameter}
                        onSetValue={onSetValue}
                        disableParameterChange={disableParameterChange}
                    />
                )}
            </div>
        </div>
    ));
}
