import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import SharingCard from "../components/SharingCard";
import LinkTable from "../components/LinkTable";
import { apiGet } from "@profilog/misc/utils/useFetch";
import { Link } from "react-router-dom";
import AppSettings, { initAppSettingsFromAppData } from "@profilog/front/app/AppSettings";

const Sharing = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [links, setLinks] = useState([]);

    async function getLinks() {
        let result = await apiGet("/sharing/links");
        if (result.isOk) {
            result.json.sort(function compare(a, b) {
                let aDate = Date.parse(a.generated);
                let bDate = Date.parse(b.generated);
                if (aDate > bDate) return -1;
                if (bDate > aDate) return 1;
                return 0;
            });
            setLinks(result.json);
            setIsLoading(false);
        } else {
            setIsError();
        }
    }

    useEffect(() => {
        getLinks();
    }, []);

    if (isLoading) return null;

    if (isError) return;

    return (
        <Fragment>
            <section className="univ__inbody univ__inbody--bglight">
                <div className="container">
                    {!AppSettings.isPetLog || links.some((l) => l.isSelectedUserAnimal) ? (
                        <SharingCard
                            allowEmail={!AppSettings.isHh && !AppSettings.isPlusCare}
                            onLinkShared={getLinks}
                        />
                    ) : (
                        <div className="box">
                            {t("sharing.SharingCard.AddFirstAnimal")}
                            <span> </span>
                            <Link to={"/in/relations"}>{t("global.here")}</Link>
                        </div>
                    )}
                </div>
            </section>

            <section className="univ__inbody mt-4">
                <div className="container">
                    {!AppSettings.isPetLog && (
                        <div className="box">
                            <h2 className="box-title">{t("sharing.LinkTable.PersonLinks")}</h2>
                            <LinkTable
                                isForAnimals={false}
                                links={links.filter((l) => !l.isMedicalSubjectAnimal)}
                                onReviewAccessRevoked={getLinks}
                            />
                        </div>
                    )}
                    {(AppSettings.isPetLog || links.some((l) => l.isSelectedUserAnimal)) && (
                        <div className="row">
                            <div className="box">
                                <h2 className="box-title">{t("sharing.LinkTable.AnimalLinks")}</h2>
                                <LinkTable
                                    isForAnimals={true}
                                    links={links.filter((l) => l.isMedicalSubjectAnimal)}
                                    onReviewAccessRevoked={getLinks}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </Fragment>
    );
};

export default Sharing;
