import DashboardCharts from "../components/DashboardCharts";
import Surveys from "../components/Surveys";
import Drugs from "../components/Drugs";
import { Fragment, useState } from "react";

import "twin.macro";
import Communications from "@profilog/misc/communications/components/old/Communications";
import AppSettings from "@profilog/front/app/AppSettings";

export default function Dashboard() {
    const [medicalSubjectId] = useState(AppSettings.user.userMedicalSubjectId);
    const [profilogUserId] = useState(AppSettings.profilogUserId);
    const [isPatientPreview] = useState(AppSettings.isPatientPreview);

    return (
        <Fragment>
            <div
                tw="color[#719e87]
                    grid h-screen
                    grid-template-columns[385px 1fr 385px]
                    grid-template-rows[1fr 100px]
                    pl-4 pb-4"
            >
                <div>
                    <div tw="overflow-hidden mb-1 pt-12">
                        <video
                            src="https://demo.icuplus.com/virtualpublic/monitorcam.mov"
                            autoPlay
                            loop
                            tw="width[140%]"
                        />
                    </div>
                    <div tw="overflow-hidden">
                        <div>
                            <video
                                src="https://demo.icuplus.com/virtualpublic/patient.mp4"
                                autoPlay
                                loop
                                tw="width[140%] float-right"
                            />
                        </div>
                    </div>

                    <div tw="clear-both"></div>
                </div>

                <div tw="pl-5 pb-5 flex flex-col items-stretch overflow-y-auto">
                    <DashboardCharts />
                </div>

                <div
                    tw="row-span-2
                        grid h-screen
                        grid-template-rows[2fr 1fr]
                        border-0 border-l-8 border-solid border-color[#505050]"
                >
                    <div tw="p-3 border-t-8 border-solid border-color[#505050] overflow-y-auto">
                        <Drugs />
                    </div>
                    <div tw="border-0 border-t-8 border-solid border-color[#505050] p-3">
                        <Communications
                            medicalSubjectId={medicalSubjectId}
                            isProfi={false}
                            profilogUserId={profilogUserId}
                            isPatientPreview={isPatientPreview}
                            isDashboard={true}
                        />
                    </div>
                </div>

                <div tw="col-span-2 pt-5">
                    <Surveys />
                </div>
            </div>
        </Fragment>
    );
}
