import _ from "lodash";

const fractionsToNumbers = {
    "1/2": 0.5,
    "1/3": 0.3,
    "1/4": 0.25,
    "1/8": 0.125,
    "3/4": 0.75,
};

const numbersToFractions = {};
for (let key in fractionsToNumbers) numbersToFractions[fractionsToNumbers[key]] = key;

export function verifyAmount(value, typing, supportFractions = true) {
    if (value === null || value === undefined) return "";

    value = value.replace(/[.]/g, ",");

    if (!supportFractions) value = value.replace(/[/]/g, "");

    value = removeAllButFirstChar(value, ",");
    value = removeAllButFirstChar(value, "/");
    value = value.replace(/[^\d/,]/g, "");

    if (value.endsWith(",") && !typing) value = _.trim(value.trim(), ",");

    if (value.endsWith("/") && typing) return value;
    const slashPos = value.indexOf("/");
    if (slashPos >= 0 && !fractionsToNumbers[value]) value = value.substring(0, slashPos);

    return value;
}

export function parseAmount(value) {
    if (value === null || value === undefined) return null;

    if (typeof value === "number") return value;

    value = value.trim();
    if (value === "") return null;

    value = value.replace(",", ".");

    let number = fractionsToNumbers[value];
    if (number) return number;

    number = Number.parseFloat(value);
    return Number.isNaN(number) ? 0 : number;
}

export function formatAmount(value) {
    if (value === null) return "";

    let fraction = numbersToFractions[value];
    if (fraction) return fraction;

    let str = String(value);
    str = str.replace(".", ",");
    return str;
}

export function removeAllButFirstChar(value, char) {
    const pos = value.indexOf(char);
    return pos < 0 ? value : value.substring(0, pos + 1) + value.substring(pos + 1).replace(char, "");
}
