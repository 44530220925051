import RecordsReview from "../sharing/pages/RecordsReview";
import GdprConsent from "./pages/GdprConsent";
import GdprInformation from "./pages/GdprInformation";
import { Navigate } from "react-router";

const SharedPublicPages = [
    { path: "records-review/:identificator", element: <RecordsReview /> },

    { path: "gdpr-consent", element: <GdprConsent /> },
    { path: "terms", element: <Navigate to="../gdpr-consent" /> },

    { path: "gdpr-information", element: <GdprInformation /> },
];

export default SharedPublicPages;
