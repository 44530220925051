import i18n from "i18next";

export const dayTimes = {
    morning: "Morning",
    noon: "Noon",
    evening: "Evening",
    night: "Night",
};

const dayTimesOptions = {};
const dayTimesOptionsArrays = {};

export function getDayTimesOptions(lang) {
    const result = dayTimesOptions[lang];
    if (result) return result;

    generateOptionsForLang(lang);
    return dayTimesOptions[lang];
}

export function getDayTimesOptionsArray(lang) {
    const result = dayTimesOptionsArrays[lang];
    if (result) return result;

    generateOptionsForLang(lang);
    return dayTimesOptionsArrays[lang];
}

function generateOptionsForLang(lang) {
    dayTimesOptions[lang] = {};
    dayTimesOptionsArrays[lang] = [];

    for (let value of Object.values(dayTimes)) {
        const option = { value: value, label: i18n.t("prescriptions.DayTimes." + value) };

        dayTimesOptions[lang][value] = option;
        dayTimesOptionsArrays[lang].push(option);
    }
}
