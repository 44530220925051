import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";
import RhfMuiSelect from "./RhfMuiSelect";

const GenderSelector = ({ id, control, name, defaultValue, errors, className, noUndefined }) => {
    const { t } = useTranslation();
    const genders = getGenders(t, noUndefined);

    return (
        <RhfMuiSelect
            id={id}
            variant="outlined"
            name={name}
            errors={errors}
            label={t("global.Gender.Gender")}
            defaultValue={defaultValue ?? (noUndefined ? 0 : "undefined")}
            control={control}
            className={className}
            SelectProps={{ fullWidth: true }}
        >
            {genders.map((g) => (
                <MenuItem key={g.value} value={g.value}>
                    {g.name}
                </MenuItem>
            ))}
        </RhfMuiSelect>
    );
};

export function getGenders(t, noUndefined) {
    const genders = [
        { value: 0, name: t("global.Gender.Human.Male") },
        { value: 1, name: t("global.Gender.Human.Female") },
    ];

    if (!noUndefined) genders.push({ value: "undefined", name: t("global.Gender.Undefined") });

    return genders;
}

export default GenderSelector;
