import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LocSwitcher from "@profilog/misc/lang/LocSwitcher";
import supportedLangs from "../../../localization/SupportedLangs.json";
import { useAuth } from "@profilog/misc/auth/useAuth";
import ApiErrorsModal from "@profilog/misc/errors/ApiErrorsModal";

export default function LoginDisplay({ mobile, children }) {
    const { t } = useTranslation();
    const { logout, currentUserData, apiErrors, resetApiErrors } = useAuth();
    if (currentUserData == null) return null;

    if (mobile) {
        return (
            <div className="user-mobile d-block d-md-none">
                <div className="text-white small">{currentUserData.userName}</div>
                <div className="navbar-nav">
                    <Link className="nav-link text-white d-block fw-600" to={"/in/settings"}>
                        {t("loginDisplay.Settings")}
                    </Link>

                    <button type="submit" className="simulated-a nav-link text-white d-block fw-600" onClick={logout}>
                        {t("loginDisplay.Logout")}
                    </button>
                </div>
                <div className="pb-1"></div>
                <LocSwitcher supportedLangs={supportedLangs} />
                <ApiErrorsModal apiErrors={apiErrors} resetApiErrors={resetApiErrors} />
            </div>
        );
    } else {
        return (
            <div className="user d-none d-md-block">
                {children}
                <div
                    className="user__name dropdown-toggle red cursor-pointer pr-2"
                    aria-haspopup="true"
                    data-toggle="dropdown"
                >
                    {currentUserData.userName}
                </div>
                <div className="dropdown-menu shadow navigation-level2">
                    <button type="button" className="simulated-a nav-link d-block" onClick={logout}>
                        {t("loginDisplay.Logout")}
                    </button>
                </div>
                <LocSwitcher supportedLangs={supportedLangs} />
                <ApiErrorsModal apiErrors={apiErrors} resetApiErrors={resetApiErrors} />
            </div>
        );
    }
}
