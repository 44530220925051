import ProfilogCe from "@profilog/front/cert/components/ProfilogCe";
import Container from "@profilog/commons/layout/Container";
import { useTranslation } from "react-i18next";
import FacebookIcon from "@mui/icons-material/Facebook";
import HomeLink from "@profilog/misc/header/HomeLink";
import EmailIcon from "@mui/icons-material/Email";
import { NavLink } from "react-router-dom";

export default function HhFooter() {
    const { t } = useTranslation();

    return (
        <footer tw="background[#55A] flex-shrink-0 mt-10">
            <Container tw="py-10 text-white text-sm flex flex-col gap-4 md:items-start md:flex-row justify-between">
                <div tw="flex-1">
                    <HomeLink>{t("cpz.Links.Home")}</HomeLink>
                    <br />
                    <NavLink to="/gdpr-consent">Souhlas dle GDPR</NavLink>
                    <br />
                    <NavLink to="/gdpr-information">Informace GDPR</NavLink>
                    <br />
                </div>

                <div tw="flex-1">
                    <strong>{t("cpz.Layout.StayInTouch")}</strong>
                    <br />
                    <a href="https://www.facebook.com/magicware.cz/" target="_blank" rel="noreferrer">
                        <FacebookIcon /> facebook
                    </a>
                    <br />

                    <a href="mailto:info@profilog.com">
                        <EmailIcon /> Napište nám
                    </a>
                </div>

                <div tw="flex-1">
                    <ProfilogCe app="Profilog HH" />
                </div>
            </Container>
        </footer>
    );
}
