import { Typography, Tooltip } from "@mui/material";
import styled from "@mui/styled-engine";
import { getNotificationTypeIcon } from "./notificationTypes";

export default function NotificationDayGroup({ title, notifications, onNotificationClick }) {
    return (
        <Group>
            <GroupHeader>
                <Typography variant="subtitle2">{title}</Typography>
            </GroupHeader>
            {notifications.map((notification) => {
                const Icon = getNotificationTypeIcon(notification.notification.type);
                const tooltip =
                    notification?.content === null || notification.content.trim() === 0
                        ? notification.title
                        : notification.content?.trim();
                return (
                    <Tooltip key={notification.id} title={tooltip} enterDelay={150} leaveDelay={80}>
                        <Message
                            key={notification.id}
                            onClick={(evt) => {
                                onNotificationClick(evt, notification.notification);
                            }}
                        >
                            <MessageContent tw="w-96">
                                <MessageTitle variant="subtitle2">{notification.title}</MessageTitle>
                                <MessageBody component="div" variant="body2" tw="relative">
                                    <div tw="w-full overflow-hidden overflow-ellipsis whitespace-nowrap ">
                                        {notification.content}
                                    </div>
                                </MessageBody>
                            </MessageContent>
                            <MessageTime component="div" tw="w-20">
                                <Icon fontSize="small" />
                                <div>{notification.notificationType}</div>
                                <Typography component="span" variant="body2">
                                    {notification.time}
                                </Typography>
                            </MessageTime>
                        </Message>
                    </Tooltip>
                );
            })}
        </Group>
    );
}

const Group = styled("div")({ textAlign: "center" });

const GroupHeader = styled("div")({
    justifyContent: "center",
    backgroundColor: "#ced5db",
    marginTop: "5px",
    marginBottom: "5px",
    padding: "8px",
});

const Message = styled("div")({
    display: "flex",
    borderBottom: "1px solid gray",
    "&:last-child": {
        borderBottom: "none",
    },
    "&:hover": {
        backgroundColor: "lightgray",
        cursor: "pointer",
    },
});

const MessageContent = styled("div")({
    display: "flex",
    padding: "5px 8px",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
});

const MessageTitle = styled(Typography)({
    textAlign: "left",
});

const MessageBody = styled(Typography)({
    textAlign: "left",
});

const MessageTime = styled(Typography)({
    padding: "5px 8px",
    textAlign: "right",
    width: "5rem",
});
