import React, { useRef, useEffect } from "react";
import "./IonSlider.scss";

const $ = window.$;

function _IonSlider({
    min,
    max,
    step,
    value,
    defaultValue,
    gridNum,
    isNullAllowed,
    isMinValueIgnored,
    displayDecimals,
    onValueChanged,
    className,
    extraClasses,
    onInitialized,
    onChange,
    onPrettify,
    version,
}) {
    if (!isNullAllowed && value === null) value = defaultValue ?? min;

    const inputElement = useRef(null);
    const ionInstance = useRef(null);
    const values = useRef({ confirmed: value, last: null });

    useEffect(() => {
        if (ionInstance.current !== null) {
            console.log("Ion set new value");
            ionInstance.current.update({ from: value });
            setValues(value);
        }
        // eslint-disable-next-line
    }, [version]);

    useEffect(() => {
        console.log("Ion init");

        var sliderOptions = {
            min: min,
            max: max,
            step: step,
            from: value ?? defaultValue,
            grid: true,
            grid_num: gridNum,
            skin: "round",
            hide_min_max: true,
            hide_from_to: false,
            extra_classes: className + " " + extraClasses,
            onFinish: handleFinish,
            onUpdate: handleChange,
            onChange: handleChange,
            prettify:
                onPrettify ??
                ((n, isLabel) => {
                    if (isLabel && values.current.confirmed === null) return "X";

                    if (isMinValueIgnored && n === min) return "N";

                    if (displayDecimals === 0) {
                        return Math.round(n);
                    } else {
                        var factor = Math.pow(10, displayDecimals);
                        return Math.round(n * factor) / factor;
                    }
                }),
        };

        const $input = $(inputElement.current);
        $input.ionRangeSlider(sliderOptions);
        ionInstance.current = $input.data("ionRangeSlider");
        setValues(value);
        if (onInitialized) onInitialized(ionInstance.current.result);

        // eslint-disable-next-line
    }, []);

    let initialValue = value ?? defaultValue;

    return (
        <input
            ref={inputElement}
            type="range"
            step={step}
            min={min}
            max={max}
            defaultValue={initialValue == null ? "" : initialValue}
        />
    );

    function handleChange(data) {
        if (isNullAllowed) {
            if (values.current.confirmed === null) {
                values.current.confirmed = data.from;
                ionInstance.current.drawLabels();
            }
        }

        updateDefaultValueStyle(data.from);

        if (onChange !== null && onChange !== undefined) onChange(data);
    }

    function handleFinish(data) {
        setValues(data.from);
        onValueChanged(values.current.confirmed);
    }

    function setValues(selectedValue) {
        if (!isNullAllowed) {
            values.current.confirmed = selectedValue;
            return;
        }

        if (selectedValue === values.current.last) {
            values.current.confirmed = values.current.confirmed === null ? selectedValue : null;
        } else {
            values.current.confirmed = selectedValue;
        }

        ionInstance.current.drawLabels();
        updateDefaultValueStyle(selectedValue);
        values.current.last = values.current.confirmed;
    }

    function updateDefaultValueStyle(selectedValue) {
        if (!ionInstance.current) return;

        if (isMinValueIgnored)
            ionInstance.current.result.slider.toggleClass(
                "irs-default-value",
                isMinValueIgnored && min === selectedValue,
            );

        ionInstance.current.result.slider.toggleClass("irs-no-value", values.current.confirmed === null);
    }
}

// Jediný update komponenty je při manuálním zvýšení version - jinak se slider zbytečně reinicializuje např. i při zaokrouhlení value.
const IonSlider = React.memo(
    _IonSlider,
    (prevProps, nextProps) => nextProps.version === undefined || nextProps.version === prevProps.version,
);

export default IonSlider;
