import auth from "@profilog/misc/localization/translations/tr/auth.json";
import userSettings from "@profilog/front/localization/translations/tr/userSettings.json";
import userSettingsShared from "@profilog/misc/localization/translations/tr/userSettings.json";
import patients from "@profilog/front/localization/translations/tr/patients.json";
import records from "@profilog/front/localization/translations/tr/records.json";
import aboutService from "@profilog/front/localization/translations/tr/aboutService.json";
import footer from "@profilog/front/localization/translations/tr/footer.json";
import groups from "@profilog/front/localization/translations/tr/groups.json";
import header from "@profilog/front/localization/translations/tr/header.json";
import home from "@profilog/front/localization/translations/tr/home.json";
import loginDisplay from "@profilog/front/localization/translations/tr/loginDisplay.json";
import about from "@profilog/front/localization/translations/tr/about.json";
import aboutUs from "@profilog/front/localization/translations/tr/aboutUs.json";
import useCases from "@profilog/front/localization/translations/tr/useCases.json";
import persons from "@profilog/front/localization/translations/tr/persons.json";
import sharing from "@profilog/front/localization/translations/tr/sharing.json";
import cpz from "@profilog/front/localization/translations/tr/cpz.json";
import obefis from "@profilog/front/localization/translations/tr/obefis.json";
import sps from "@profilog/front/localization/translations/tr/sps.json";
import extConnectors from "@profilog/front/localization/translations/tr/extConnectors.json";
import userInitialization from "@profilog/front/localization/translations/tr/userInitialization.json";
import prescriptions from "@profilog/front/localization/translations/tr/prescriptions.json";
import icudemo from "@profilog/front/localization/translations/tr/icudemo.json";

import apiResult from "@profilog/misc/localization/translations/tr/apiResult.json";
import global from "@profilog/misc/localization/translations/tr/global.json";
import recordsVisualization from "@profilog/misc/localization/translations/tr/recordsVisualization.json";
import caloricsTable from "@profilog/misc/localization/translations/tr/caloricsTable.json";
import apiStatusCheck from "@profilog/misc/localization/translations/tr/apiStatusCheck.json";
import communications from "@profilog/front/localization/translations/tr/communications.json";
import shCommunications from "@profilog/misc/localization/translations/tr/communications.json";
import shNotifications from "@profilog/misc/localization/translations/tr/notifications.json";
import feedback from "@profilog/misc/localization/translations/tr/feedback.json";
import ecg from "@profilog/misc/localization/translations/tr/ecg.json";
import surveys from "@profilog/surveys/localization/translations/tr/surveys.json";
import activitiesVisualization from "@profilog/misc/localization/translations/tr/activitiesVisualization.json";

export default {
    auth,
    userSettings: { ...userSettings, ...userSettingsShared },
    patients,
    records,
    recordsVisualization,
    aboutService,
    footer,
    global,
    groups,
    header,
    home,
    loginDisplay,
    about,
    aboutUs,
    apiResult,
    useCases,
    persons,
    sharing,
    cpz,
    obefis,
    sps,
    caloricsTable,
    apiStatusCheck,
    extConnectors,
    userInitialization,
    communications,
    shCommunications,
    shNotifications,
    feedback,
    ecg,
    prescriptions,
    icudemo,
    surveys,
    activitiesVisualization,
};
