export default class MwHorizontalSlider {
    constructor(container, options) {
        console.log("HS init");

        this._container = container;
        this._axisContainer = container.querySelector(".hs-axis-container");
        this._axis = container.querySelector(".hs-axis");
        this._input = container.querySelector(".hs-value");
        this._handle = container.querySelector(".hs-handle");

        this._options = this.validateOptions(options);

        // const
        if (this._options.mode === "time") {
            this._options.formatter = new TimeFormatter(this._options.min, this._options.max);
            this._axis.classList.add("hs-time");
            this._bgImgUnits = 12;
            this._bgImgWidth = 144;
        } else {
            this._bgImgUnits = 10;
            this._bgImgWidth = 120;
        }

        this._bgImgUnitWidth = this._bgImgWidth / this._bgImgUnits;
        this._mouseScrollSensitivity = 2;

        // fields
        this._isDown = false;
        this._ignoreNextScroll = false;
        this._scrollShift = 0;
        this._lastScrollShift = null;
        this._scrollTimeout = null;

        this._startX = null;
        this._scrollLeft = null;

        this._storedValue = this._options.defaultValue;

        this.bind();
        this.attachEvents();

        this._maxFromZero = this._options.max - this._options.min;

        this.bgImgRepeatCount = this._maxFromZero / this._bgImgUnits / this._options.step;
        this.totalAxisWidth = this._bgImgWidth * this.bgImgRepeatCount;
        this._axis.style.width = this.totalAxisWidth + "px";

        this.renderNumbers();
        this.initialize();
    }

    bind() {
        this.onScroll = this.onScroll.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onInputKeypress = this.onInputKeypress.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.dispatchValueChange = this.dispatchValueChange.bind(this);
    }

    attachEvents() {
        this._axisContainer.addEventListener("scroll", this.onScroll);
        this._input.addEventListener("change", this.onInputChange);
        this._input.addEventListener("focus", this.onInputFocus);
        this._input.addEventListener("keypress", this.onInputKeypress);
        this._axisContainer.addEventListener("mousedown", this.onMouseDown);
        this._axisContainer.addEventListener("mouseleave", this.onMouseLeave);
        this._axisContainer.addEventListener("mouseup", this.onMouseUp);
        this._axisContainer.addEventListener("mousemove", this.onMouseMove);
    }

    initialize() {
        let isActive = false;

        let inputValue = this._input.value;
        let isValueFilledIn = inputValue !== null && inputValue !== "";

        if (!this._options.isNullAllowed || isValueFilledIn) {
            let numberValue = isValueFilledIn
                ? this.stringToValidNumber(inputValue, false)
                : this._storedValue ?? this._options.min;

            if (!isValueFilledIn) this._input.value = this.numberToValidString(numberValue);

            this.scrollToValue(numberValue);
            this._storedValue = numberValue;
            isActive = true;
        } else if (this._storedValue !== null && this._storedValue !== "") {
            this.scrollToValue(this._storedValue);
        }

        this.setActive(isActive);
    }

    validateOptions(options) {
        if (!options) {
            options = {};
        }

        if (options.displayDecimals === null || options.displayDecimals === undefined) options.displayDecimals = 0;

        if (options.decimalSeparator === null || options.displayDecimals === undefined) options.decimalSeparator = ".";

        if (options.min === null || options.min === undefined) options.min = 0;

        if (options.max === null || options.max === undefined) options.max = 10;

        if (options.step === null || options.step === undefined) options.step = 1;

        if (options.isNullAllowed === null || options.isNullAllowed === undefined) options.isNullAllowed = true;

        if (options.hideHandleWhenNotSelected === null || options.hideHandleWhenNotSelected === undefined)
            options.hideHandleWhenNotSelected = true;

        if (options.notSelectedValue === null || options.notSelectedValue === undefined) options.notSelectedValue = "";

        return options;
    }

    renderNumbers() {
        for (let i = 0; i <= this.bgImgRepeatCount; i++) {
            var element = document.createElement("span");
            element.className = "hs-axis-value";
            element.style.left = i * this._bgImgUnits * this._bgImgUnitWidth - 20 + "px";
            element.innerText = this.numberToValidString(
                this._options.min + i * Math.round(this._options.step * this._bgImgUnits),
            );
            this._axis.appendChild(element);
        }
    }

    scrollToValue(value) {
        if (value === null) return;

        this._ignoreNextScroll = true;
        this._axisContainer.scrollLeft = ((value - this._options.min) / this._maxFromZero) * this.totalAxisWidth;
    }

    setActive(isActive) {
        if (isActive) {
            if (this._options.hideHandleWhenNotSelected) this._handle.style.display = "block";
            else this._input.classList.remove("hs-inactive-value");
            this._container.classList.remove("hs-inactive");
        } else {
            if (this._options.hideHandleWhenNotSelected) this._handle.style.display = "none";
            else this._input.classList.add("hs-inactive-value");
            this._container.classList.add("hs-inactive");
            this._input.value = this._options.notSelectedValue;
        }
    }

    stringToValidNumber(valueStr, rountToStep = true) {
        if (valueStr === null || valueStr === "") return null;

        let number = null;

        if (this._options.formatter !== null && this._options.formatter !== undefined) {
            number = this._options.formatter.stringToNumber(valueStr);
        } else {
            if (this._options.decimalSeparator !== ".")
                valueStr = valueStr.replace(this._options.decimalSeparator, ".");

            number = Number(valueStr);
            if (isNaN(number)) number = this._options.min;
        }

        return this.getValidNumber(number, rountToStep);
    }

    getValidNumber(valueNumber, roundToStep = true) {
        const factor = Math.pow(10, this._options.displayDecimals);
        if (roundToStep) valueNumber = Math.round(valueNumber / this._options.step) * this._options.step;
        valueNumber = Math.round(valueNumber * factor) / factor;

        if (valueNumber < this._options.min) valueNumber = this._options.min;
        else if (valueNumber > this._options.max) valueNumber = this._options.max;

        return valueNumber;
    }

    numberToValidString(valueNumber) {
        if (valueNumber === null) return null;

        if (this._options.formatter !== null && this._options.formatter !== undefined) {
            return this._options.formatter.numberToString(valueNumber);
        }

        let valueString = String(valueNumber.toFixed(this._options.displayDecimals));
        if (this._options.decimalSeparator !== ".")
            valueString = valueString.replace(".", this._options.decimalSeparator);

        return valueString;
    }

    // Scroll event
    onScroll() {
        if (this._ignoreNextScroll) {
            this._ignoreNextScroll = false;
            return;
        }

        this.setActive(true);

        let newValueNumber = this.setInputValuesFromScroll();

        if (this._scrollTimeout) {
            clearTimeout(this._scrollTimeout);
            this._lastScrollShift = this._scrollShift;
        }

        this._scrollTimeout = setTimeout(
            function () {
                if (this._lastScrollShift === this._scrollShift) {
                    this.dispatchValueChange(newValueNumber);
                }
            }.bind(this),
            300,
        );
    }

    setInputValuesFromScroll() {
        this._scrollShift = this._axisContainer.scrollLeft / this.totalAxisWidth;
        let newValueNumber = this._options.min + this._scrollShift * this._maxFromZero;

        newValueNumber = this.getValidNumber(newValueNumber);
        this._storedValue = newValueNumber;
        this._input.value = this.numberToValidString(newValueNumber);
        return newValueNumber;
    }

    dispatchValueChange(value) {
        if (this._options.onValueChanged) {
            this._options.onValueChanged(value);
        }
    }

    // Input change, keypress
    onInputChange() {
        let valueNumber = this.stringToValidNumber(this._input.value, false);
        if (valueNumber === null && !this._options.isNullAllowed) valueNumber = this._storedValue ?? this._options.min;

        this._input.value =
            valueNumber !== null ? this.numberToValidString(valueNumber) : this._options.notSelectedValue;
        this.setActive(valueNumber !== null);
        this.scrollToValue(valueNumber);
        if (valueNumber != null) this._storedValue = valueNumber;

        this.dispatchValueChange(valueNumber);
    }

    onInputFocus() {
        this.setActive(true);
        let valueNumber = this._storedValue;
        this._input.value = this.numberToValidString(valueNumber);
        this._input.select();
        this.dispatchValueChange(valueNumber);
    }

    onInputKeypress(event) {
        if (event.key === "Enter" || event.key === "Tab") {
            event.preventDefault();
            this.onInputChange();
            this._input.select();
        }
    }

    // Mouse events
    onMouseDown(event) {
        this._isDown = true;
        this._axisContainer.classList.add("active");
        this._startX = event.pageX - this._axisContainer.offsetLeft;
        this._scrollLeft = this._axisContainer.scrollLeft;
    }

    onMouseLeave() {
        this._isDown = false;
        this._axisContainer.classList.remove("active");
    }

    onMouseUp() {
        this._isDown = false;
        this._axisContainer.classList.remove("active");
    }

    onMouseMove(event) {
        if (!this._isDown) return;

        event.preventDefault();
        let x = event.pageX - this._axisContainer.offsetLeft;
        let walk = (x - this._startX) * this._mouseScrollSensitivity;
        this._axisContainer.scrollLeft = this._scrollLeft - walk;
    }
}

class TimeFormatter {
    stringToNumber(string) {
        if (string === null || string === "") return null;

        const splitted = string.split(":");
        if (splitted.length < 1 || splitted.length > 2) return null;

        let hours = Number(splitted[0]);
        if (isNaN(hours)) return null;

        if (hours < 0) hours = 0;

        let minutes = splitted.length > 1 ? Number(splitted[1]) : 0;
        if (isNaN(minutes) || minutes < 0 || minutes > 59) minutes = 0;

        const result = hours * 60 + minutes;
        return result;
    }

    numberToString(number) {
        var minutes = number % 60;
        var hours = (number - minutes) / 60;
        return hours + ":" + String(minutes).padStart(2, "0");
    }
}
