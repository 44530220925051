import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CurrentUserContext from "@profilog/misc/auth/CurrentUserContext";
import { useFetch } from "@profilog/misc/utils/useFetch";

export const STEPS = {
    PERSONAL_INFO: "personal_info",
    EXTERNAL_CONNECTIONS: "external_connections",
    COOPERATION_REQUEST: "cooperation_request",
};

export default function useUserInitializationSteps(projectSettings) {
    const stepMappings = [
        { step: STEPS.PERSONAL_INFO, propertyName: "isPersonalInfoStepRequired" },
        { step: STEPS.EXTERNAL_CONNECTIONS, propertyName: "isExternalConnectionsStepRequired" },
        { step: STEPS.COOPERATION_REQUEST, propertyName: "isCooperationRequestStepRequired" },
    ];

    const { apiGet, apiPut, ApiErrors } = useFetch();
    const navigate = useNavigate();
    const { setIsUserInitialized } = useContext(CurrentUserContext);
    const [isCooperationStepSkipped, setIsCooperationStepSkipped] = useState(false);
    const [currentStep, setCurrentStep] = useState(null);
    const [nextStep, setNextStep] = useState(null);
    const [previousStep, setPreviousStep] = useState(null);
    const [isFinished, setIsFinished] = useState(false);

    const transitions = [
        {
            step: STEPS.PERSONAL_INFO,
            prev: null,
            next: STEPS.EXTERNAL_CONNECTIONS,
        },
        {
            step: STEPS.EXTERNAL_CONNECTIONS,
            prev: STEPS.PERSONAL_INFO,
            next: STEPS.COOPERATION_REQUEST,
        },
        {
            step: STEPS.COOPERATION_REQUEST,
            prev: STEPS.EXTERNAL_CONNECTIONS,
            next: null,
        },
    ];

    function next() {
        if (nextStep) setCurrentStep(nextStep);
        else finishUserInitialization();
    }

    function previous() {
        if (previousStep) setCurrentStep(previousStep);
    }

    async function finishUserInitialization() {
        let response = await apiPut("/web/user/initialization/finish");
        if (response.isOk) {
            setIsFinished(true);
        }
    }

    useEffect(() => {
        if (isFinished) {
            setIsUserInitialized(true);
            navigate("../../in/records");
        }
    }, [isFinished]);

    useEffect(() => {
        if (!projectSettings) return;
        if (!currentStep) return;

        let testingNextStep = currentStep;
        do {
            testingNextStep = transitions.find((s) => s.step === testingNextStep).next;
        } while (
            (testingNextStep &&
                projectSettings[stepMappings.find((sm) => sm.step === testingNextStep).propertyName] !== true) ||
            (testingNextStep === STEPS.COOPERATION_REQUEST && isCooperationStepSkipped)
        );
        setNextStep(testingNextStep);

        let testingPrevStep = currentStep;
        do {
            testingPrevStep = transitions.find((s) => s.step === testingPrevStep).prev;
        } while (
            testingPrevStep &&
            projectSettings[stepMappings.find((sm) => sm.step === testingPrevStep).propertyName] !== true
        );

        setPreviousStep(testingPrevStep);
    }, [currentStep, projectSettings]);

    useEffect(() => {
        async function getInitializationState() {
            if (!projectSettings) return;

            const response = await apiGet("/web/user/initialization/state");
            if (response.isOk) {
                setIsCooperationStepSkipped(response.json.isCooperationStepSkipped);
                if (response.json.currentStep) {
                    setCurrentStep(response.json.currentStep);
                } else {
                    for (let mapping of stepMappings) {
                        if (projectSettings[mapping.propertyName] === true) {
                            setCurrentStep(mapping.step);
                            break;
                        }
                    }
                }
            }
        }
        getInitializationState();
    }, [projectSettings]);

    useEffect(() => {
        async function setInitializationState(nextStep) {
            if (nextStep) {
                const nextState = { currentStep: nextStep };
                await apiPut("/web/user/initialization/state", nextState);
            }
        }
        setInitializationState(currentStep);
    }, [currentStep]);

    let existsNextStep = nextStep != null;
    let existsPreviousStep = previousStep != null;
    return {
        currentStep,
        next,
        previous,
        existsNextStep,
        existsPreviousStep,
        previous,
        ApiErrors,
    };
}
