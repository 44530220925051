import { Link } from "react-router-dom";
import PrescriptionList from "@profilog/front/prescriptions/pages/PrescriptionList";
import "twin.macro";
import { useTranslation } from "react-i18next";

export default function Prescriptions() {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div tw="my-4">
                <Link to="../Dashboard">&lt; {t("icudemo.BackToDashboard")}</Link>
            </div>
            <PrescriptionList />
        </div>
    );
}
