import { useTranslation } from "react-i18next";
import GdprText from "./GdprText";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Fragment, useState } from "react";

export default function GdprTextDialog({ displayOpen = false, trigger, customProjectCode }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(displayOpen);

    return (
        <Fragment>
            {trigger && trigger(() => setOpen(true))}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <GdprText type="consent" customProjectCode={customProjectCode} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t("global.Close")}</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
