export function getImageMapConfig(unit) {
    const rootUrl = process.env.REACT_APP_API_URL + "/assets/parameters/pain/";
    switch (unit) {
        case "body-front":
            return { imgUrl: rootUrl + "body-front.png", circleRatio: 25 };
        case "body-back":
            return { imgUrl: rootUrl + "body-back.png", circleRatio: 25 };
        case "head-front":
            return { imgUrl: rootUrl + "head-front.png", circleRatio: 15 };
        default:
            return { imgUrl: "", circleRatio: 0 };
    }
}
