import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import ExtConnectorInfoText from "./ExtConnectorInfoText";
import { Alert, Button, Checkbox, Chip, FormControlLabel, Link, Tooltip } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import CancelIcon from "@mui/icons-material/Cancel";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { isDisconnected } from "../codebooks/externalConnectionStatus";

export default function ExtConnector({
    infoText,
    readonly,
    title,
    subTitle,
    account,
    connectButtonLabel,
    connectButtonHidden = false,
    connectButtonHiddenReason,
    registerButtonLabel,
    disconnectButtonLabel,
    connected,
    connectionWarning,
    lastImportDate,
    status,
    onConnectClick,
    onDisconnectClick,
    onRegisterClick,
    customError,
    ApiErrors,
    ConnectButton,
    requireConsent,
    consentUrl,
    consent1Text,
    consent2Text,
    Layout,
}) {
    const { t } = useTranslation();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [consentChecked, setConsentChecked] = useState(false);
    const connectButtonDisabled = requireConsent && (!consentChecked || isButtonDisabled);

    return (
        <Layout>
            <h3 tw="mb-2">{title}</h3>
            {subTitle && <div tw="mb-2">{subTitle}</div>}
            {connected && (
                <div tw="flex flex-wrap gap-1 mb-2">
                    {!connectionWarning && <Chip size="small" color="success" label={t("extConnectors.Connected")} />}
                    {connectionWarning && (
                        <Chip size="small" color="warning" label={t("extConnectors.ConnectionWarning")} />
                    )}
                    {account && <Chip size="small" color="info" label={account} />}
                    {lastImportDate && (
                        <Tooltip title={t("extConnectors.LastImportDateText")}>
                            <Chip
                                size="small"
                                color="secondary"
                                icon={<SyncIcon />}
                                label={format(new Date(lastImportDate), " d. M. yyyy HH:mm:ss")}
                            />
                        </Tooltip>
                    )}
                </div>
            )}
            {status && isDisconnected(status) && (
                <div tw="flex flex-wrap gap-1 mb-2">
                    <Chip size="small" color="warning" label={t("extConnectors.ConnectionIsNotValid")} />
                </div>
            )}
            <ApiErrors />
            {customError && (
                <Alert severity="error" tw="my-2">
                    {customError}
                </Alert>
            )}
            <div tw="mb-2">
                {connected ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => operation(onDisconnectClick)}
                        disabled={isButtonDisabled || readonly}
                        startIcon={<CancelIcon />}
                        tw="normal-case"
                    >
                        {disconnectButtonLabel}
                    </Button>
                ) : (
                    <Fragment>
                        {connectButtonHidden && connectButtonHiddenReason}
                        {!connectButtonHidden && (
                            <Fragment>
                                {requireConsent && (
                                    <FormControlLabel
                                        tw="mb-2"
                                        label={
                                            <Fragment>
                                                {consent1Text}{" "}
                                                <Link
                                                    href={consentUrl}
                                                    target="_blank"
                                                    onClick={(e) => e.stopImmediatePropagation()}
                                                >
                                                    {consent2Text}
                                                </Link>
                                            </Fragment>
                                        }
                                        control={<Checkbox onChange={(e) => setConsentChecked(e.target.checked)} />}
                                    />
                                )}

                                <div tw="flex flex-wrap gap-2">
                                    {ConnectButton && (
                                        <ConnectButton
                                            onClick={() => operation(onConnectClick)}
                                            disabled={connectButtonDisabled || readonly}
                                        />
                                    )}
                                    {!ConnectButton && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddCircleIcon />}
                                            onClick={() => operation(onConnectClick)}
                                            disabled={connectButtonDisabled || readonly}
                                            tw="normal-case"
                                        >
                                            <Fragment>{connectButtonLabel}</Fragment>
                                        </Button>
                                    )}

                                    {onRegisterClick && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => operation(onRegisterClick)}
                                            disabled={isButtonDisabled || readonly}
                                            startIcon={<PersonAddAlt1Icon />}
                                        >
                                            {registerButtonLabel}
                                        </Button>
                                    )}
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </div>

            <ExtConnectorInfoText text={infoText} />
        </Layout>
    );

    function operation(action) {
        setIsButtonDisabled(true);
        setTimeout(() => call(action), 100);
    }

    async function call(action) {
        await action();
        setIsButtonDisabled(false);
    }
}
