import { prescriptionScheduleUpdate } from "../../redux/prescriptionSlice";
import { useSelector, useDispatch } from "react-redux";
import { useGetParameters } from "../../redux/codebooksSlice";
import { Fragment } from "react";
import "twin.macro";
import { useTranslation } from "react-i18next";
import CodebookComboBox from "../codebooks/CodebookComboBox";
import { TextField } from "@mui/material";
import { verifyAmount } from "../../utils/amount";

export default function ParamValueSchedule({ scheduleId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const parameter = useSelector((state) => state.prescriptions.schedules.byId[scheduleId].parameter);
    const valueFrom = useSelector((state) => state.prescriptions.schedules.byId[scheduleId].valueFrom);
    const valueTo = useSelector((state) => state.prescriptions.schedules.byId[scheduleId].valueTo);

    return (
        <Fragment>
            <div>
                <CodebookComboBox
                    getOptionsHook={useGetParameters}
                    value={parameter ?? ""}
                    onChange={(value) => dispatchScheduleUpdate({ parameter: value })}
                    limit={null}
                    autocompleteProps={{
                        label: t("prescriptions.Edit.ParamValue"),
                        loadingText: t("prescriptions.Edit.LoadingParams"),
                        autoHighlight: true,
                        autoSelect: true,
                    }}
                    tw="w-64"
                />
            </div>
            <div>{t("prescriptions.Edit.IsBetween")}</div>
            <div>
                <TextField
                    label={t("prescriptions.Edit.from")}
                    size="small"
                    value={valueFrom}
                    sx={{ width: "10ch" }}
                    InputLabelProps={{ shrink: true }}
                    onChange={dispatchValueFromUpdate}
                    onBlur={dispatchValueFromUpdate}
                />
            </div>
            <div>
                <TextField
                    label={t("prescriptions.Edit.to")}
                    size="small"
                    value={valueTo}
                    sx={{ width: "10ch" }}
                    InputLabelProps={{ shrink: true }}
                    onChange={dispatchValueToUpdate}
                    onBlur={dispatchValueToUpdate}
                />
            </div>
        </Fragment>
    );

    function dispatchValueFromUpdate(e) {
        dispatchScheduleUpdate({ valueFrom: verifyAmount(e.target.value, e.type === "change", false) });
    }

    function dispatchValueToUpdate(e) {
        dispatchScheduleUpdate({ valueTo: verifyAmount(e.target.value, e.type === "change", false) });
    }

    function dispatchScheduleUpdate(updated) {
        dispatch(prescriptionScheduleUpdate({ scheduleId, updated }));
    }
}
