import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { prescriptionScheduleUpdate } from "../../../redux/prescriptionSlice";
import { verifyAmount } from "../../../utils/amount";
import "twin.macro";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";

export default function AmountMax({ scheduleId, editing, open }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const amountMax = useSelector((state) => state.prescriptions.schedules.byId[scheduleId].amountMax);

    return editing ? (
        <div>
            <TextField
                size="small"
                label={t("prescriptions.Edit.Maximum")}
                tw="w-28"
                autoFocus={open}
                value={amountMax ?? ""}
                onChange={dispatchUpdate}
                onBlur={dispatchUpdate}
            />
        </div>
    ) : (
        <Fragment>
            {amountMax ? (
                <strong>
                    {t("prescriptions.Edit.toMax")} {amountMax}
                </strong>
            ) : (
                t("prescriptions.Edit.MaxShort")
            )}
        </Fragment>
    );

    function dispatchUpdate(e) {
        dispatchScheduleUpdate({ amountMax: verifyAmount(e.target.value, e.type === "change") });
    }

    function dispatchScheduleUpdate(updated) {
        dispatch(prescriptionScheduleUpdate({ scheduleId, updated }));
    }
}
