import { TextField, FormControl, Select, MenuItem, InputAdornment } from "@mui/material";
import { Controller } from "react-hook-form";
import { hasError, getErrorMessage } from "@profilog/misc/errors/ValidationError";

export default function PhoneNumberField({
    id,
    name,
    label,
    control,
    errors,
    className,
    defaultValue,
    defaultCallingCode,
    callingCodes,
    register,
}) {
    return (
        <TextField
            type="tel"
            id={id}
            name={name}
            error={hasError(errors, name)}
            helperText={getErrorMessage(errors, name)}
            label={label}
            className={className}
            defaultValue={defaultValue}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <FormControl variant="standard">
                            <Controller
                                control={control}
                                error={errors[`${name}-callingCode`] && true}
                                defaultValue={defaultCallingCode ?? 42}
                                name={`${name}-callingCode`}
                                rules={{ required: true }}
                                as={
                                    <Select disableUnderline>
                                        {callingCodes.map((c) => (
                                            <MenuItem key={c.value} value={c.value}>
                                                {c.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                            />
                        </FormControl>
                    </InputAdornment>
                ),
            }}
            inputRef={register}
        ></TextField>
    );
}
