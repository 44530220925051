import FetchOptions from "./FetchOptions";

export function createApiUrl(url, params) {
    let apiUrl = new URL(url, FetchOptions.apiUrl).href;
    if (!params) return apiUrl;

    if (params) {
        const qs = new URLSearchParams();
        Object.keys(params).forEach((k) => {
            const value = params[k];
            if (Array.isArray(value)) value.forEach((val) => qs.append(k, val));
            else qs.append(k, params[k]);
        });
        apiUrl += `?${qs}`;
    }

    return apiUrl;
}
