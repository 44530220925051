import CodebookComboBox from "./CodebookComboBox";
import { useGetSituations } from "../../redux/codebooksSlice";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function SituationComboBox({ value, onChange, autocompleteProps, className }) {
    const { t } = useTranslation();

    const props = { label: t("prescriptions.Schedules.Situation"), freeSolo: true };
    if (autocompleteProps) Object.assign(props, autocompleteProps);

    return (
        <Fragment>
            <CodebookComboBox
                value={value}
                onChange={onChange}
                getOptionsHook={useGetSituations}
                autocompleteProps={props}
                className={className}
            />
        </Fragment>
    );
}
