import { useState, useEffect } from "react";
import { useModal } from "@profilog/misc/commons/components/Modal";
import { useForm } from "react-hook-form";
import { useFetch } from "@profilog/misc/utils/useFetch";
import GenderSelector from "@profilog/front/commons/components/GenderSelector";
import CountrySelector from "@profilog/front/commons/components/CountrySelector";
import { useTranslation } from "react-i18next";

export function useRelationEditModal(groups) {
    const { isModalOpen, openModal, closeModal, Modal } = useModal();
    const [relationData, setRelationData] = useState(null);

    return { openRelationEditModal, RelationEditModal };

    function openRelationEditModal(medicalSubjectId, selectedGroupId, onSaved) {
        setRelationData({ medicalSubjectId, selectedGroupId, onSaved });
        openModal();
    }

    function RelationEditModal() {
        const anonymizingLiteral = "XXXX";
        const { t } = useTranslation();
        const { apiGet, apiPost, apiPut, ApiErrors } = useFetch();
        const {
            register,
            handleSubmit,
            formState: { isSubmitting },
        } = useForm();
        const [isLoading, setIsLoading] = useState(true);
        const [editedPersonMedicalSubject, setEditedPersonMedicalSubject] = useState({});
        const [isAnonymizable, setIsAnonymizable] = useState(true);
        const [isAnonymizingSubmit, setIsAnonymizingSubmit] = useState(false);

        // useEffect(() => {
        //     if (isOpened) {
        //         nameRef.current.focus();
        //     }
        // }, [isOpened]);

        function canBeAnonymized(personMedicalSubject) {
            if (personMedicalSubject.firstName?.length > 0 && personMedicalSubject.firstName !== anonymizingLiteral)
                return true;
            if (personMedicalSubject.lastName?.length > 0 && personMedicalSubject.lastName !== anonymizingLiteral)
                return true;

            return false;
        }

        useEffect(() => {
            async function getPersonMedicalSubject(id) {
                if (isModalOpen && id) {
                    let result = await apiGet("/groups/" + relationData.selectedGroupId + "/persons/" + id);
                    setEditedPersonMedicalSubject(result.json);
                    setIsAnonymizable(canBeAnonymized(result.json));
                    setIsLoading(false);
                }
            }
            getPersonMedicalSubject(relationData?.medicalSubjectId);
        }, [relationData]);

        if (!isModalOpen) return null;

        if (isLoading && relationData.medicalSubjectId) return null;

        const handleCloseClicked = () => {
            setEditedPersonMedicalSubject({});
        };

        async function savePersonMedicalSubject(data) {
            let isOk = relationData.medicalSubjectId
                ? await updatePersonMedicalSubject(data)
                : await createPersonMedicalSubject(data);

            setIsAnonymizingSubmit(false);
            setIsLoading(true);
            setEditedPersonMedicalSubject({});
            closeModal();
            relationData.onSaved();
        }

        async function updatePersonMedicalSubject(data) {
            let selectedGroupIds = [];
            console.log(data.groups);
            data.groups.forEach((p, i) => {
                if (p === true) {
                    selectedGroupIds = [...selectedGroupIds, i];
                }
            });

            const updateRelatedPersonMedicalSubjectDto = {
                firstName: isAnonymizingSubmit ? anonymizingLiteral : data.firstName,
                lastName: isAnonymizingSubmit ? anonymizingLiteral : data.lastName,
                groupIds: selectedGroupIds,
                gender: data.gender === "undefined" ? null : parseInt(data.gender),
                city: data.city,
                yearOfBirth: parseInt(data.yearOfBirth),
                countryId: parseInt(data.country),
                pastAnamnesis: data.pastAnamnesis,
                currentAnamnesis: data.currentAnamnesis,
                familyAnamnesis: data.familyAnamnesis,
            };

            const response = await apiPut(
                "/groups/" + relationData.selectedGroupId + "/persons/" + relationData.medicalSubjectId,
                updateRelatedPersonMedicalSubjectDto,
            );
            return response.isOk;
        }

        async function createPersonMedicalSubject(data) {
            let selectedGroupIds = [];
            data.groups.forEach((p, i) => {
                if (p === true) {
                    selectedGroupIds = [...selectedGroupIds, i];
                }
            });

            const createRelatedPersonMedicalSubjectDto = {
                firstName: isAnonymizingSubmit ? anonymizingLiteral : data.firstName,
                lastName: isAnonymizingSubmit ? anonymizingLiteral : data.lastName,
                groupIds: selectedGroupIds,
                gender: data.gender === "undefined" ? null : parseInt(data.gender),
                city: data.city,
                yearOfBirth: parseInt(data.yearOfBirth),
                countryId: parseInt(data.country),
                pastAnamnesis: data.pastAnamnesis,
                currentAnamnesis: data.currentAnamnesis,
                familyAnamnesis: data.familyAnamnesis,
            };

            const response = await apiPost(
                "/groups/" + relationData.selectedGroupId + "/persons",
                createRelatedPersonMedicalSubjectDto,
            );
            return response.isOk;
        }

        function anonymize() {
            setIsAnonymizingSubmit(true);

            handleSubmit(savePersonMedicalSubject);
        }

        return (
            <Modal title={t("persons.Person")}>
                <form onSubmit={handleSubmit(savePersonMedicalSubject)}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 p-0 p-md-3 d-block">
                                <div className="card card--form-2">
                                    <div className="card-body">
                                        <p>{t("persons.Desc")}</p>

                                        <div className="inner-card my-4">
                                            <h2 className="h5 card-title font-weight-semibold">
                                                {t("persons.Contacts.Title")}
                                            </h2>

                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group pb-3 row align-items-center">
                                                        <label
                                                            htmlFor="about-me-firstname"
                                                            className="col-sm-12 col-md-3 col-lg-2"
                                                        >
                                                            {t("persons.Contacts.Name")}
                                                        </label>
                                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                                            <input
                                                                type="text"
                                                                id="about-me-firstname"
                                                                className="form-control"
                                                                name="firstName"
                                                                defaultValue={editedPersonMedicalSubject.firstName}
                                                                ref={register}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group pb-3 row align-items-center">
                                                        <label
                                                            htmlFor="about-me-lastname"
                                                            className="col-sm-12 col-md-3 col-lg-2"
                                                        >
                                                            {t("persons.Contacts.Surname")}
                                                        </label>
                                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                                            <input
                                                                type="text"
                                                                id="about-me-lastname"
                                                                className="form-control"
                                                                name="lastName"
                                                                defaultValue={editedPersonMedicalSubject.lastName}
                                                                ref={register}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a
                                                data-toggle="collapse"
                                                href="#personal-data-protection"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="personal-info-security"
                                            >
                                                {t("persons.Contacts.PersonalDataProtectionToggle")}
                                            </a>
                                            <div className="mt-3 collapse" id="personal-data-protection">
                                                <div dangerouslySetInnerHTML={{ __html: t("persons.Contacts.Desc") }} />
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: t("persons.Contacts.AnonymizeText"),
                                                    }}
                                                ></p>
                                                <div className="row">
                                                    {isAnonymizable && (
                                                        <div className="col-auto d-none d-md-flex align-items-end">
                                                            <button
                                                                onClick={() => anonymize()}
                                                                type="submit"
                                                                className="btn btn-secondary"
                                                            >
                                                                {t("persons.Contacts.Anonymize")}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group row">
                                                    <label
                                                        htmlFor="user-group-selector"
                                                        className="col-6 col-md-2 col-lg-2 col-form-label"
                                                    >
                                                        {t("persons.Group")}
                                                    </label>
                                                    <div className="col-6 col-md-4 col-lg-6">
                                                        {groups.map((group, index) => {
                                                            const fieldName = `groups`;
                                                            return (
                                                                <div key={index}>
                                                                    <div className="form-check">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`person-medical-subject-group-id-${group.id}`}
                                                                            className="form-check-input"
                                                                            name={`${fieldName}.${group.id}`}
                                                                            defaultChecked={editedPersonMedicalSubject.groupIds?.includes(
                                                                                group.id,
                                                                            )}
                                                                            ref={register}
                                                                        ></input>
                                                                        <label
                                                                            htmlFor={`person-medical-subject-group-id-${group.id}`}
                                                                            className="form-check-label"
                                                                        >
                                                                            {group.name}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                        {/* <GroupSelector
                          id="user-group-selector"
                          groups={groups}
                          selectedValue={editedPersonMedicalSubject.groupId ?? selectedGroupId}
                          name="group"
                          formRegister={register}
                        /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-lg-6">
                                                <div className="form-group row">
                                                    <label
                                                        htmlFor="user-gender-selector"
                                                        className="col-6 col-md-2 col-lg-4 col-form-label"
                                                    >
                                                        {t("persons.Gender")}
                                                    </label>
                                                    <div className="col-6 col-md-4 col-lg-8">
                                                        <GenderSelector
                                                            id="user-gender-selector"
                                                            name="gender"
                                                            selectedValue={
                                                                editedPersonMedicalSubject.gender ?? "undefined"
                                                            }
                                                            formRegister={register}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label
                                                        htmlFor="about-me-city"
                                                        className="col-6 col-md-2 col-lg-4 col-form-label"
                                                    >
                                                        {t("persons.City")}
                                                    </label>
                                                    <div className="col-6 col-lg-8">
                                                        <input
                                                            type="text"
                                                            id="about-me-city"
                                                            className="form-control"
                                                            name="city"
                                                            defaultValue={editedPersonMedicalSubject.city}
                                                            ref={register}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-lg-6">
                                                <div className="form-group row">
                                                    <label
                                                        htmlFor="about-me-year-of-birth"
                                                        className="col-6 col-md-2 col-lg-4 col-form-label"
                                                    >
                                                        {t("persons.YearOfBirth")}
                                                    </label>
                                                    <div className="col-6 col-md-4">
                                                        <input
                                                            type="number"
                                                            id="about-me-year-of-birth"
                                                            className="form-control"
                                                            name="yearOfBirth"
                                                            defaultValue={editedPersonMedicalSubject.yearOfBirth}
                                                            ref={register}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label
                                                        htmlFor="user-country-selector"
                                                        className="col-6 col-md-2 col-lg-4 col-form-label"
                                                    >
                                                        {t("persons.Country")}
                                                    </label>
                                                    <div className="col-6 col-md-4 col-lg-8">
                                                        <CountrySelector
                                                            id="user-country-selector"
                                                            selectedValue={editedPersonMedicalSubject.countryId}
                                                            name="country"
                                                            formRegister={register}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="about-me-pastanamnesis">{t("persons.PastDiseases")}</label>
                                            <textarea
                                                className="form-control"
                                                id="about-me-pastanamnesis"
                                                name="pastAnamnesis"
                                                defaultValue={editedPersonMedicalSubject.pastAnamnesis}
                                                ref={register}
                                            ></textarea>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="about-me-currentanamnesis">
                                                {t("persons.CurrentDiseases")}
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="about-me-currentanamnesis"
                                                name="currentAnamnesis"
                                                defaultValue={editedPersonMedicalSubject.currentAnamnesis}
                                                ref={register}
                                            ></textarea>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="about-me-familyanamnesis">
                                                {t("persons.FamilyDiseases")}
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="about-me-familyanamnesis"
                                                name="familyAnamnesis"
                                                defaultValue={editedPersonMedicalSubject.familyAnamnesis}
                                                ref={register}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                {t("global.Form.Save")}
                            </button>
                            <ApiErrors />
                        </div>
                    </div>
                </form>
            </Modal>
        );
    }
}
