import { useEffect, useState } from "react";
import { useFetch } from "../../utils/useFetch";
import { getHostName, getQuery } from "../../utils/Url";

export default function useCalorieTables(onConnected) {
    const { apiPost, apiGet, ApiErrors } = useFetch();
    const [isConnectionInfoLoading, setIsConnectionInfoLoading] = useState(true);
    const [isUserInfoLoading, setIsUserInfoLoading] = useState(true);
    const [error, setError] = useState();
    const [connectErrors, setConnectErrors] = useState([]);
    const [caloricsTableConnectionInfo, setCaloricsTableConnectionInfo] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    const registeredUserHash = getQuery().get("hash");

    async function loadCaloricsTableConnectionInfo() {
        if (!registeredUserHash) {
            const response = await apiGet("/calorics-table/get-state");
            if (response.isOk) {
                setCaloricsTableConnectionInfo(response.json);
            }
        }
        setIsConnectionInfoLoading(false);
    }

    useEffect(() => {
        loadCaloricsTableConnectionInfo();
    }, []);

    useEffect(() => {
        async function getUserInfo() {
            if (caloricsTableConnectionInfo) return;

            const response = await apiGet("/calorics-table/user-info");
            if (response.isOk) {
                setUserInfo(response.json);
            }
            setIsUserInfoLoading(false);
        }
        getUserInfo();
    }, [caloricsTableConnectionInfo]);

    // Při callbacku z registrační stránky KT je v query (user) hash parametr, který uložíme
    useEffect(() => {
        if (registeredUserHash && !isUserInfoLoading) {
            saveConnection(userInfo.email, registeredUserHash);
            window.history.replaceState({}, "", `${window.location.origin}${window.location.pathname}`);
            setCaloricsTableConnectionInfo({ ...caloricsTableConnectionInfo, email: userInfo.email });
        }
    }, [registeredUserHash, isUserInfoLoading]);

    async function connect(input) {
        const response = await apiPost("/calorics-table/connect", input);
        if (response.isOk) {
            const model = response.json;
            if (model.isOk) {
                await loadCaloricsTableConnectionInfo();
                onConnected();
            } else {
                setConnectErrors([model.error]);
            }
        }
    }

    async function disconnect() {
        const response = await apiPost("/calorics-table/disconnect");
        if (response.isOk) await loadCaloricsTableConnectionInfo();
    }

    function redirectToRegistrationPage(returnPage) {
        const callbackUrl = encodeURI(getHostName() + process.env.PUBLIC_URL + returnPage);
        const sex = userInfo.gender == 0 ? "&sex=M" : userInfo.gender == 1 ? "&sex=Z" : "";
        const weight = userInfo.weightInKg ? `&weight=${userInfo.weightInKg}` : "";
        const height = userInfo.heightInCm ? `&height=${userInfo.heightInCm}` : "";
        const birthyear = userInfo.yearOfBirth ? `&birthYear=${userInfo.yearOfBirth}` : "";
        window.location = `https://www.kaloricketabulky.cz/registrace-cas-pro-zdravi?pid=CaSpR0Z2&callback=${callbackUrl}&email=${userInfo.email}${sex}${weight}${height}${birthyear}&mode=0&fb=0&google=0`;
    }

    async function saveConnection(email, hash) {
        const input = { email: email, userHash: hash };
        const response = await apiPost("/calorics-table/save-connection", input);
        if (response.isOk) {
            const model = response.json;
            if (model.isOk) {
                onConnected();
            } else {
                setConnectErrors([model.error]);
            }
        }
    }

    const isLoading = isConnectionInfoLoading || isUserInfoLoading;

    return {
        isLoading,
        caloricsTableConnectionInfo,
        connect,
        connectErrors,
        disconnect,
        redirectToRegistrationPage,
        userInfo,
        error,
        ApiErrors,
    };
}
