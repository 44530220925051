import styled from "@mui/styled-engine";
import NotificationDayGroup from "./NotificationDayGroup";
import { useTranslation } from "react-i18next";
import { notificationTypes } from "./notificationTypes";
import { format } from "date-fns";
import { cooperationRequestTypes } from "./cooperationRequestTypes";

export default function NotificationList({ notifications, onNotificationClick }) {
    const { t, i18n } = useTranslation();
    const grouppedNotifications = createDayNotifications(notifications);

    return (
        <Container>
            {grouppedNotifications.map((day) => (
                <NotificationDayGroup
                    key={day.date}
                    title={day.date}
                    notifications={day.notifications}
                    onNotificationClick={onNotificationClick}
                />
            ))}
        </Container>
    );

    function createDayNotifications(notifications) {
        const timeFormatter = new Intl.DateTimeFormat(i18n.language, {
            hour: "numeric",
            minute: "numeric",
        });

        let result = notifications.reduce((acc, notification) => {
            const dateKey = getNotificationDateKey(notification.createdDate);
            let dayNotifications = acc.find((s) => s.dateKey === dateKey);

            if (!dayNotifications) {
                dayNotifications = {
                    dateKey: dateKey,
                    date: getDateDesc(notification.createdDate),
                    notifications: [],
                };
                acc.push(dayNotifications);
            }

            dayNotifications.notifications.push({
                id: notification.id,
                title: getTitle(notification),
                content: getDescription(notification),
                time: timeFormatter.format(new Date(notification.createdDate)),
                notification: notification,
            });
            return acc;
        }, []);

        return result;
    }

    function getTitle(notification) {
        let czFix = getCzFix();
        let result = "";
        switch (notification.type) {
            case notificationTypes.message:
                let messageTitle;
                czFix = getCzFix(notification.message.sourcePerson.gender);
                if (
                    notification.message.isProfi &&
                    notification.targetPerson.id !== notification.message.medicalSubjectPerson.id
                )
                    messageTitle = t("shNotifications.NotificationsInfo.NotificationTitle.XSendProfiMessage");
                else messageTitle = t("shNotifications.NotificationsInfo.NotificationTitle.XSendMessage");

                result = messageTitle
                    .replace("{0}", notification.message.sourcePerson.fullName)
                    .replace("{1}", notification.message.medicalSubjectPerson.fullName);
                break;
            case notificationTypes.comment:
                let commentTitle;
                czFix = getCzFix(notification.message.sourcePerson.gender);
                if (
                    notification.message.isProfi &&
                    notification.targetPerson.id !== notification.message.medicalSubjectPerson.id
                )
                    commentTitle = t("shNotifications.NotificationsInfo.NotificationTitle.XAddProfiComment");
                else commentTitle = t("shNotifications.NotificationsInfo.NotificationTitle.XAddComment");

                result = commentTitle
                    .replace("{0}", notification.message.sourcePerson.fullName)
                    .replace(
                        "{1}",
                        new Date(notification.message.date).toLocaleDateString(i18n.language, {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        }),
                    )
                    .replace("{2}", notification.message.medicalSubjectPerson.fullName);
                break;
            case notificationTypes.cooperationRequestSent:
                if (notification.cooperationRequest.type === cooperationRequestTypes.fromPatientToTherapist) {
                    czFix = getCzFix(notification.cooperationRequest.sourcePerson.gender);
                    result = t(
                        "shNotifications.NotificationsInfo.NotificationTitle.CooperationRequestSentFromPatientToTherapist",
                    )
                        .replace("{0}", notification.cooperationRequest.sourcePerson.fullName)
                        .replace("{1}", notification.cooperationRequest.group.name);
                } else if (notification.cooperationRequest.type === cooperationRequestTypes.fromTherapistToPatient) {
                    //skupina nemá pohlaví... tedy nejde nastavit czFix
                    result = t(
                        "shNotifications.NotificationsInfo.NotificationTitle.CooperationRequestSentFromTherapistToPatient",
                    ).replace("{0}", notification.cooperationRequest.group.name);
                }
                break;
            case notificationTypes.cooperationRequestAccepted:
                if (notification.cooperationRequest.type === cooperationRequestTypes.fromPatientToTherapist) {
                    //skupina nemá pohlaví... tedy nejde nastavit czFix
                    result = t(
                        "shNotifications.NotificationsInfo.NotificationTitle.CooperationRequestAccepted",
                    ).replace("{0}", notification.cooperationRequest.group.name);
                } else if (notification.cooperationRequest.type === cooperationRequestTypes.fromTherapistToPatient) {
                    czFix = getCzFix(notification.cooperationRequest.medicalSubjectPerson.gender);
                    result = t(
                        "shNotifications.NotificationsInfo.NotificationTitle.CooperationRequestAccepted",
                    ).replace("{0}", notification.cooperationRequest.medicalSubjectPerson.fullName);
                }
                break;
            case notificationTypes.cooperationRequestRejected:
                if (notification.cooperationRequest.type === cooperationRequestTypes.fromPatientToTherapist) {
                    //skupina nemá pohlaví... tedy nejde nastavit czFix
                    result = t(
                        "shNotifications.NotificationsInfo.NotificationTitle.CooperationRequestRejected",
                    ).replace("{0}", notification.cooperationRequest.group.name);
                } else if (notification.cooperationRequest.type === cooperationRequestTypes.fromTherapistToPatient) {
                    czFix = getCzFix(notification.cooperationRequest.medicalSubjectPerson.gender);
                    result = t(
                        "shNotifications.NotificationsInfo.NotificationTitle.CooperationRequestRejected",
                    ).replace("{0}", notification.cooperationRequest.medicalSubjectPerson.fullName);
                }
                break;
            default:
                break;
        }

        result = result.replaceAll("{czFix}", czFix);
        return result;
    }

    function getDescription(notification) {
        switch (notification.type) {
            case notificationTypes.message:
            case notificationTypes.comment:
                return notification.message.text;
            default:
                return null;
        }
    }

    function getDateDesc(origDate) {
        let date = new Date(origDate);
        date.setHours(0, 0, 0, 0);

        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        let diffInTime = currentDate.getTime() - date.getTime();
        let diffInDays = diffInTime / (1000 * 3600 * 24);

        if (diffInDays === 0) return t("shNotifications.NotificationsInfo.Today");
        if (diffInDays === 1) return t("shNotifications.NotificationsInfo.Yesterday");

        return date.toLocaleDateString(i18n.language, {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    }
}

const Container = styled("div")({
    position: "relative",
    padding: ".5rem",
});

/** Vygeneruje klíč = řetězec pro zadané datum ale v v 0:00,
 * je to pro seskupování notifikací po dnech. */
function getNotificationDateKey(date) {
    if (!date) return "";
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    date = format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSS'0000'");

    return date;
}

/** K ženskému rodu přidá na konec slova `a` */
function getCzFix(gender) {
    if (gender === 1) return "a";
    else if (gender !== 0) return "(a)";

    return "";
}
