import { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../utils/useFetch";
import {
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip as MuiToolTip,
    InputAdornment,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DatePicker from "../../commons/components/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import getFnsLocale from "@profilog/misc/lang/FnsLocales";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    CommonSeriesSettings,
    Legend,
    Label,
    Tick,
    Format,
    Font,
    ZoomAndPan,
    VisualRange,
    WholeRange,
    Animation,
    Tooltip,
} from "devextreme-react/chart";
import { addDays, startOfDay, format } from "date-fns";
import CircularProgressMidParent from "@profilog/commons/components/CircularProgressMidParent";

export default function PredictedChart({ parameter, medicalSubjectId }) {
    const { apiGet, apiPost, apiDeleteBody, ApiErrors } = useFetch();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState();
    const [ranges, setRanges] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const seriesVisibility = useRef({});
    const [arePredicatedSeriesLine, setArePredicatedSeriesLine] = useState(false);
    const [predictionTime, setPredictionTime] = useState(null);
    const [predictionSteps, setPredictionSteps] = useState();
    const [recordsToDelete, setRecordsToDelete] = useState();
    const stopChangeVisualRange = useRef(false);

    useEffect(() => {
        let dateFrom = startOfDay(new Date()); //startOfDay(new Date(2022, 7, 26));
        let dateTo = addDays(dateFrom, 1);

        setRanges({
            visualRange: { startValue: dateFrom, endValue: dateTo },
            wholeRange: { startValue: dateFrom, endValue: dateTo },
        });
    }, []);

    async function loadData() {
        setIsLoading(true);
        let response = await apiGet(
            `/records/getPredictedData?medicalSubjectId=${medicalSubjectId}&parameter=${parameter}&dateFromUtc=${ranges.wholeRange.startValue.toISOString()}&dateToUtc=${ranges.wholeRange.endValue.toISOString()}`,
        );
        if (response.isOk) setData(() => response.json);
        setIsLoading(false);
    }

    useEffect(() => {
        if (ranges?.wholeRange == null) return;
        loadData();
    }, [ranges?.wholeRange]);

    useEffect(() => {
        stopChangeVisualRange.current = false;
    }, [predictionTime, predictionSteps]);

    async function deletePredictions() {
        stopChangeVisualRange.current = true;
        setIsLoading(true);

        let dto = {
            medicalSubjectId: medicalSubjectId,
            recordIds: recordsToDelete,
            recordTypeName: parameter,
        };

        let response = await apiDeleteBody("/v2/medical-subjects/records", dto);
        if (response.isOk) await loadData();
        else setIsLoading(false);
        setRecordsToDelete();
        stopChangeVisualRange.current = false;
    }

    async function setAllRecordsToDelete() {
        let records = [];
        data.records.forEach((item) => {
            let recordId = item["recordId"];
            if (recordId) records.push(recordId);
        });

        if (records.length > 0) setRecordsToDelete(records);
    }

    function getSeries() {
        return data.series.map((serie, index) => {
            return (
                <Series
                    key={index}
                    valueField={serie.valueField}
                    name={serie.name}
                    axis={serie.axisName}
                    type={serie.isMainSerie || arePredicatedSeriesLine ? "line" : "scatter"}
                    order={serie.order}
                    color={serie.color}
                    visible={seriesVisibility.current[serie.name] ?? true}
                    tag={serie.isMainSerie}
                    point={{
                        size: serie.isMainSerie ? 6 : 4,
                        border: {
                            visible: true,
                        },
                    }}
                    width={serie.isMainSerie ? 3 : 2}
                    // width={chart.parameterIds[0] === spo2Param.id ? 1 : 2}
                ></Series>
            );
        });
    }

    function handleLegendClick(e) {
        const serie = e.target;
        const visible = serie.isVisible();
        if (visible) {
            serie.hide();
        } else {
            serie.show();
        }

        seriesVisibility.current[serie.name] = !visible;
    }

    function moveVisualRange(days) {
        let dateFrom = new Date(ranges.wholeRange.startValue);
        let dateTo = new Date(ranges.wholeRange.endValue);
        dateFrom.setDate(dateFrom.getDate() + days);
        dateTo.setDate(dateTo.getDate() + days);
        stopChangeVisualRange.current = false;
        setRanges({
            visualRange: { startValue: dateFrom, endValue: dateTo },
            wholeRange: { startValue: dateFrom, endValue: dateTo },
        });
    }

    function changeDate(date) {
        let dateFrom = new Date(date);
        let dateTo = new Date(date);
        dateTo.setDate(dateTo.getDate() + 1);
        stopChangeVisualRange.current = false;
        setRanges({
            visualRange: { startValue: dateFrom, endValue: dateTo },
            wholeRange: { startValue: dateFrom, endValue: dateTo },
        });
    }

    function handleOptionChange(e) {
        if (e.fullName === "argumentAxis.visualRange" && !stopChangeVisualRange.current) {
            setRanges({ ...ranges, visualRange: e.value });
        }
    }

    async function handlePointClick(e) {
        if (!e.target.series.tag) {
            setRecordsToDelete([e.target.data.recordId]);
            return;
        }

        let dto = {
            subjectId: medicalSubjectId,
            recordTypeName: parameter,
            utcPredictionTime: e.target.data.recordDate.toISOString(),
            predictedWalkLengthMinutes: predictionTime
                ? predictionTime.getHours() * 60 + predictionTime.getMinutes()
                : 0,
            predictedStepsCount: !predictionSteps || predictionSteps === "" ? 0 : predictionSteps,
        };

        stopChangeVisualRange.current = true;
        setIsLoading(true);
        let response = await apiPost("/v2/medical-subjects/records/make-prediction", dto);
        if (response.isOk) await loadData();
        else setIsLoading(false);
        stopChangeVisualRange.current = false;
    }

    function getDateTimeFormat(value) {
        let minutes = value.getMinutes();
        let hours = value.getHours();

        if (minutes === 0 && hours === 0)
            return value.toLocaleDateString(i18n.language, {
                month: "long",
                day: "numeric",
            });
        else
            return value.toLocaleTimeString(i18n.language, {
                hour: "2-digit",
                minute: "2-digit",
            });
    }

    function customizeTooltip(pointInfo) {
        let items = [];

        if (pointInfo.point.series.tag) {
            let element = document.createElement("span");
            element.textContent = `${pointInfo.seriesName}: ${pointInfo.value} ${data.axis.unit}`;
            element.style.color = pointInfo.point.getColor();
            element.className = "active";
            items.push(element.outerHTML);
            let dateElement = document.createElement("span");
            dateElement.style.paddingTop = 5;
            dateElement.textContent = format(new Date(pointInfo.point.data.recordDate), "HH:mm");
            items.push(dateElement.outerHTML);
        } else {
            let serie = data.series.find((x) => x.name == pointInfo.point.series.name);

            let element = document.createElement("span");
            element.textContent = `Predikce: ${pointInfo.value} ${data.axis.unit}`;
            element.style.color = pointInfo.point.getColor();
            element.className = "active";
            items.push(element.outerHTML);

            items.push(
                createToolTipElement(
                    `na ${format(new Date(pointInfo.point.data.recordDate), "HH:mm")} z ~${format(
                        new Date(pointInfo.point.data.predictedDate),
                        "HH:mm",
                    )}`,
                ),
            );

            items.push(createToolTipElement(`horizont ${serie.valueField} minut`));
            items.push(createToolTipElement("-"));

            let stepsPlan = "";
            if (pointInfo.point.data.predictedStepsCount)
                stepsPlan += `Plán ${pointInfo.point.data.predictedStepsCount} kroků `;
            if (pointInfo.point.data.predictedWalkLengthMinutes)
                stepsPlan += `za ${parseInt(pointInfo.point.data.predictedWalkLengthMinutes / 60, 10).toString()}h ${(
                    pointInfo.point.data.predictedWalkLengthMinutes % 60
                )
                    .toString()
                    .padStart(2, "0")}m`;
            if (stepsPlan.length > 0) items.push(createToolTipElement(stepsPlan));

            if (pointInfo.point.data.predictedStepsCountForHorizon)
                items.push(
                    createToolTipElement(`${pointInfo.point.data.predictedStepsCountForHorizon} kroků za horizont`),
                );
        }

        return { text: items.join("\n") };
    }

    function createToolTipElement(text) {
        let element = document.createElement("span");
        element.textContent = text;
        return element.outerHTML;
    }

    return (
        <Fragment>
            <div tw="shadow-updown">
                <div tw="flex-col gap-2 flex justify-between items-center px-5 py-2.5 shadow-updown sm:flex-row">
                    <div tw="flex-1 flex justify-center items-center space-x-1">
                        <Button
                            variant="contained"
                            size="small"
                            disableElevation
                            tw="p-1 min-w-min text-xs"
                            onClick={() => moveVisualRange(-1)}
                            startIcon={<ChevronLeftIcon tw="-mr-2" />}
                        >
                            {t("recordsVisualization.VisiblePeriod.Day")}
                        </Button>
                        <div>
                            <DatePicker
                                selected={ranges && ranges.wholeRange.startValue}
                                onChange={(date) => changeDate(date)}
                                customInput={
                                    <TextField
                                        size="small"
                                        tw="width[11ch] text-xs"
                                        inputProps={{
                                            sx: { p: "5px", fontSize: "12px" },
                                            readOnly: false,
                                        }}
                                    />
                                }
                            />
                        </div>
                        <Button
                            variant="contained"
                            size="small"
                            disableElevation
                            tw="p-1 min-w-min text-xs"
                            onClick={() => moveVisualRange(1)}
                            endIcon={<ChevronRightIcon tw="-ml-2" />}
                        >
                            {t("recordsVisualization.VisiblePeriod.Day")}
                        </Button>
                    </div>
                </div>
                <div tw="pt-4 pl-4 pr-4 flex gap-6 justify-between flex-wrap">
                    <div tw="flex gap-2">
                        <TextField
                            size="small"
                            type="text"
                            label="Plán chůze"
                            tw="[width: 13ch] appearance-none"
                            value={predictionSteps}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                sx: { textalign: "right" },
                                endAdornment: <InputAdornment position="end">kroků</InputAdornment>,
                            }}
                            onChange={(e) => {
                                stopChangeVisualRange.current = true;
                                setPredictionSteps(e.target.value);
                            }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getFnsLocale(i18n.lang)}>
                            <TimePicker
                                views={["hours", "minutes"]}
                                value={predictionTime}
                                label="Za"
                                ampm={false}
                                slotProps={{ textField: { size: "small", sx: { width: "12ch" } } }}
                                onChange={(e) => {
                                    stopChangeVisualRange.current = true;
                                    setPredictionTime(e);
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                    <EnergyForm />
                    <div tw="flex items-center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={arePredicatedSeriesLine}
                                    onChange={(e) => setArePredicatedSeriesLine(e.target.checked)}
                                />
                            }
                            label={"Spojit predikce čarou"}
                        />
                        <MuiToolTip title="Smazat všechny predikce na tento den" placement="top">
                            <Button variant="contained" size="small" disableElevation onClick={setAllRecordsToDelete}>
                                Smazat predikce
                            </Button>
                        </MuiToolTip>
                    </div>
                </div>

                <div tw="w-full p-2.5 relative">
                    <ApiErrors />
                    {data && (
                        <Chart
                            dataSource={data.records}
                            onLegendClick={handleLegendClick}
                            onOptionChanged={handleOptionChange}
                            onPointClick={handlePointClick}
                        >
                            <ZoomAndPan argumentAxis="both" />
                            <Animation enabled={false} />
                            <Legend visible={true} verticalAlignment="top" horizontalAlignment="center" />
                            <ArgumentAxis
                                argumentType="datetime"
                                hoverMode="none"
                                visualRange={ranges?.visualRange}
                                wholeRange={ranges?.wholeRange}
                            >
                                <Label wordWrap="none" visible={true}>
                                    <Font family="Montserrat, sans-serif" size={10} />
                                    <Format formatter={getDateTimeFormat} />
                                </Label>
                            </ArgumentAxis>
                            <CommonSeriesSettings argumentField="recordDate" selectionMode="none" hoverMode="none" />
                            {getSeries()}
                            <ValueAxis
                                name={data.axis.name}
                                allowDecimals={data.axis.isDecimal}
                                position={data.axis.positionType}
                                tickInterval={4}
                                type="continuous"
                                visualRangeUpdateMode="auto"
                            >
                                {/* <Label
                            visible={true}
                            customizeText={valueAxisCustomizeLabelText}
                            alignment={axisSettings.positionType}
                            //position="inside"
                            font={{ opacity: "0.7" }}
                            indentFromAxis={0}
                        /> */}

                                <Tick visible={false} />
                                <WholeRange startValue={0} endValue={16} />
                                <VisualRange startValue={0} endValue={16} />
                            </ValueAxis>
                            <Tooltip
                                enabled={true}
                                shared={true}
                                zIndex={1000}
                                cornerRadius={5}
                                customizeTooltip={customizeTooltip}
                            />
                        </Chart>
                    )}

                    {isLoading && (
                        <div tw="absolute left-0 top-0 bg-gray-300 bg-opacity-20 w-full h-full">
                            <CircularProgressMidParent />
                        </div>
                    )}
                </div>
            </div>
            <Dialog
                open={recordsToDelete}
                onClose={() => setRecordsToDelete()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {recordsToDelete && recordsToDelete.length === 1
                        ? "Opravdu chcete smazat vybraný záznam?"
                        : "Opravdu chcete smazat všechny predikce za celý den"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Pozor smazání je nevratný proces.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRecordsToDelete()}>Ne</Button>
                    <Button onClick={deletePredictions} autoFocus>
                        Ano
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

function EnergyForm() {
    return (
        <div tw="flex flex-wrap gap-2">
            <TextField
                size="small"
                label="Energie"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">kcal</InputAdornment>,
                }}
                tw="[width: 13ch]"
            />
            <TextField
                size="small"
                label="Sacharidy"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">g</InputAdornment>,
                }}
                tw="[width: 10ch]"
            />
            <TextField
                size="small"
                label="Tuky"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">g</InputAdornment>,
                }}
                tw="[width: 10ch]"
            />
            <TextField
                size="small"
                label="Bílkoviny"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">g</InputAdornment>,
                }}
                tw="[width: 10ch]"
            />
        </div>
    );
}
