import React, { useRef, useState } from "react";
import { css } from "twin.macro";
import { useWindowResize } from "@profilog/utils/useWindowResize";
import { IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UndoIcon from "@mui/icons-material/Undo";
import { generateId } from "@profilog/utils/generateId";

export default function ImageMap({ className, defaultPoints, points, onChange, imgUrl, disabled, circleRatio }) {
    const [imgLoaded, setImgLoaded] = useState(false);
    const [, refresh] = useState({});
    const [lastPoints, setLastPoints] = useState(defaultPoints);
    const imgRef = useRef();
    const canvasRef = useRef();

    useWindowResize({
        onResizing: () => refresh({}),
        confirmDelay: null,
    });

    let circleSize = 0;
    if (imgLoaded) circleSize = Math.round(imgRef.current.clientWidth / circleRatio);

    const pointCss = css`
        position: absolute;
        width: ${circleSize}px;
        height: ${circleSize}px;
        background-color: rgb(255, 0, 0, 0.34);
        border-radius: 100px;
        transform: translate(-50%, -50%);
        pointer-events: none;
    `;

    return (
        <div className={className} tw="relative">
            {!disabled && <ActionButtons onUndoClick={handleUndo} onRemoveAllClick={handleRemoveAll} />}

            <img
                ref={imgRef}
                src={imgUrl}
                alt="Map"
                draggable={false}
                tw="max-w-full max-h-full"
                onLoad={handleImgLoaded}
                onClick={tryAddPoint}
                onMouseMove={handleMouseMove}
                crossOrigin="anonymous"
            />

            {imgLoaded &&
                points &&
                points.map((point) => (
                    <div
                        css={pointCss}
                        key={point.id}
                        style={{
                            left: (point.x * imgRef.current.clientWidth) / 100,
                            top: (point.y * imgRef.current.clientHeight) / 100,
                        }}
                        draggable={false}
                    />
                ))}

            {!disabled && <canvas ref={canvasRef} hidden />}
        </div>
    );

    function tryAddPoint(e) {
        if (disabled) return;

        const relPosition = getRelPosition(e);

        if (isTransparentPixel(relPosition.imgX, relPosition.imgY)) return;

        if (lastPoints) setLastPoints(null);

        const newPoint = {
            id: generateId(),
            x: relPosition.relX * 100,
            y: relPosition.relY * 100,
        };

        onChange(points ? points.concat(newPoint) : [newPoint]);
    }

    function handleImgLoaded() {
        setImgLoaded(true);

        if (!disabled) drawImgToCanvas();
    }

    function handleMouseMove(e) {
        if (!imgLoaded || disabled) return;

        const relPosition = getRelPosition(e);
        imgRef.current.style.cursor = isTransparentPixel(relPosition.imgX, relPosition.imgY) ? "default" : "crosshair";
    }

    function handleRemoveAll() {
        if (points && points.length > 0) setLastPoints(points);
        onChange(null);
    }

    function handleUndo() {
        if (lastPoints) {
            onChange(lastPoints);
            setLastPoints(null);
        } else if (points.length > 0) onChange(points.slice(0, points.length - 1));
    }

    function getRelPosition(e) {
        const event = e.nativeEvent;

        const relX = event.offsetX / event.target.clientWidth;
        const relY = event.offsetY / event.target.clientHeight;

        return {
            relX,
            relY,
            imgX: Math.round(relX * imgRef.current.naturalWidth),
            imgY: Math.round(relY * imgRef.current.naturalHeight),
        };
    }

    function drawImgToCanvas() {
        canvasRef.current.width = imgRef.current.naturalWidth;
        canvasRef.current.height = imgRef.current.naturalHeight;
        const ctx = canvasRef.current.getContext("2d");
        ctx.drawImage(imgRef.current, 0, 0);
    }

    function isTransparentPixel(x, y) {
        const ctx = canvasRef.current.getContext("2d");
        let isTransparent = ctx.getImageData(x, y, 1, 1).data[3] === 0;
        return isTransparent;
    }
}

function ActionButtons({ onUndoClick, onRemoveAllClick }) {
    return (
        <div tw="absolute right-1 top-1 rounded-md shadow-lg">
            <IconButton color="primary" size="small" onClick={onUndoClick}>
                <UndoIcon />
            </IconButton>
            <IconButton color="primary" size="small" onClick={onRemoveAllClick}>
                <DeleteOutlineIcon />
            </IconButton>
        </div>
    );
}
