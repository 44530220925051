import { useState, useEffect } from "react";
import { useFetch } from "@profilog/misc/utils/useFetch";
import AppSettings from "../../app/AppSettings";

export default function useCooperationRequests(onRequestSent) {
    const [groups, setGroups] = useState([]);
    const [wasRequestSent, setWasRequestSent] = useState(false);
    const { apiGet: apiGetGroups, ApiErrors: ApiGetErrors } = useFetch();
    const { apiPost: apiSendRequest, ApiErrors: ApiSendRequestErrors } = useFetch();

    useEffect(() => {
        async function getGroups() {
            const response = await apiGetGroups(
                `/groups/public/names?medicalSubjectId=${AppSettings.user.userMedicalSubjectId}&areGroupsMedicalSubjectIsMemberOfExcluded=true`,
            );
            if (response.isOk) {
                setGroups(response.json);
            }
        }
        getGroups();
    }, []);

    async function sendRequest(data) {
        let cooperationRequestDto = {
            groupId: parseInt(data.groupId),
            medicalSubjectId: AppSettings.user.userMedicalSubjectId,
        };
        const response = await apiSendRequest("/web/cooperations/requests", cooperationRequestDto);
        if (response.isOk) {
            setWasRequestSent(true);
            onRequestSent();
        }
    }

    return { groups, ApiGetErrors, sendRequest, ApiSendRequestErrors, wasRequestSent, setWasRequestSent };
}
