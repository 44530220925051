import { useTranslation } from "react-i18next";

export default function MedilogChildCamps() {
    const { t } = useTranslation();

    return (
        <section className="pt-4">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="card pb-0 mb-4">
                            <div className="card-body">
                                <div className="py-4">
                                    <h4 className="text-primary">{t("useCases.Article4")}</h4>
                                    <span dangerouslySetInnerHTML={{ __html: t("useCases.Article4Desc") }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
