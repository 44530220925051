import { getQuery } from "@profilog/misc/utils/Url";
// import packageJson from "../package.json";

const AppSettings = {
    user: {
        userMedicalSubjectId: 0,
    },
    isDev: false,
    isTest: false,
    isDevOrTest: false,
    isPrerelease: false,
    version: null,
    webPushServerKey: null,
    apiUrl: null,
    project: null,
    isMedilog: false,
    isCpz: false,
    isDicatil: false,
    isEdures: false,
    isHh: false,
    isLifmat: false,
    isMedHubDemo: false,
    isObefis: false,
    isPetLog: false,
    isPlusCare: false,
    isSps: false,
    isVamed: false,
    useGoogleBrand: false,
    isPatientPreview: false,
    profilogPd: false,
    profilogUserId: null,
    profilogUserPersonFullName: "",
    profilogUserEnergyType: null,
    progilogDefaultVisibleDate: null,
    forcedLang: null,
    isMobileApp: false,
    externalServices: {
        caloricsTables: false,
        garminConnect: false,
        googleFit: false,
        oneTouchReveal: false,
        withings: false,
        fitbit: false,
        strava: false,
        huawei: false,
        remoteGoogleFit: false,
        androidApp: {},
        iphoneApp: {},
    },
};

export async function initAppSettings() {
    const packageJson = { version: "0.1.0" };
    AppSettings.isDev = process.env.REACT_APP_ENV === "development";
    AppSettings.isTest = process.env.REACT_APP_ENV === "staging";
    AppSettings.isDevOrTest = AppSettings.isDev || AppSettings.isTest;

    AppSettings.apiUrl = process.env.REACT_APP_API_URL;
    checkMobiles();

    console.log("apiUrl: " + AppSettings.apiUrl);

    AppSettings.project = process.env.REACT_APP_PROJECT;
    AppSettings.isCpz = AppSettings.project === "cpz";
    AppSettings.isDicatil = AppSettings.project === "dicatil";
    AppSettings.isEdures = AppSettings.project === "edures";
    AppSettings.isHh = AppSettings.project === "hh";
    AppSettings.isMedHubDemo = AppSettings.project === "medhubdemo";
    AppSettings.isMedilog = AppSettings.project === "medilog";
    AppSettings.isMladez = AppSettings.project === "mladez";
    AppSettings.isObefis = AppSettings.project === "obefis";
    AppSettings.isPlusCare = AppSettings.project === "pluscare";
    AppSettings.isSps = AppSettings.project === "sps";
    AppSettings.isDtk = AppSettings.project === "dtk";
    AppSettings.isVamed = AppSettings.project === "vamed";

    AppSettings.useGoogleBrand = AppSettings.isHh || AppSettings.isPlusCare;
    AppSettings.isUnivTemplate =
        AppSettings.isCpz ||
        AppSettings.isObefis ||
        AppSettings.isDicatil ||
        AppSettings.isSps ||
        AppSettings.isEdures ||
        AppSettings.isMladez ||
        AppSettings.isMedHubDemo ||
        AppSettings.isHh ||
        AppSettings.isDtk ||
        AppSettings.isPlusCare ||
        AppSettings.isVamed;

    AppSettings.isForMM = AppSettings.isCpz || AppSettings.isDicatil || AppSettings.isEdures;

    AppSettings.version = packageJson.version;
    AppSettings.forcedLang = process.env.REACT_APP_FORCED_LANG;
    AppSettings.projectLang = process.env.REACT_APP_PROJECT_LANG;

    const query = getQuery();
    AppSettings.isPatientPreview = query.get("patientPreview") === "true";
    if (AppSettings.isPatientPreview) {
        let profilogUserId = query.get("profilogUserId");
        AppSettings.profilogUserId = profilogUserId ? parseInt(profilogUserId) : null;
        AppSettings.profilogUserPersonFullName = query.get("profilogUserPersonFullName");
        AppSettings.profilogUserEnergyType = query.get("profilogUserEnergyType");
        AppSettings.profilogPd = query.get("profilogPd") === "true";
        AppSettings.defaultRecordVisibleDate = query.get("defaultRecordVisibleDate");
    } else {
        AppSettings.profilogUserId = null;
        AppSettings.profilogUserPersonFullName = "";
        AppSettings.profilogUserEnergyType = null;
        AppSettings.profilogPd = true;
        AppSettings.defaultRecordVisibleDate = null;
    }

    if (query.get("pre") !== null || AppSettings.isDev) AppSettings.isPrerelease = true;
    AppSettings.arePrescriptionsEnabled =
        AppSettings.isSps ||
        AppSettings.isEdures ||
        AppSettings.isPrerelease ||
        AppSettings.isMedHubDemo ||
        AppSettings.isDicatil;

    console.log("version: " + AppSettings.version);
}

function checkMobiles() {
    const isMobile = window.location.pathname.includes("mobile/");
    const isMobileApp = window.location.pathname.includes("mobile-app/");

    if (isMobile || isMobileApp) AppSettings.isMobileApp = true;
}

export async function initAppSettingsFromAppData(appData) {
    AppSettings.user.userMedicalSubjectId = appData.user.userMedicalSubjectId;

    AppSettings.externalServices = appData.externalServices;
    AppSettings.webPushServerKey = appData.webPushServerKey;
}

export default AppSettings;
