import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function SuccessfullyRegistered() {
    const { t } = useTranslation();

    return (
        <Fragment>
            <p>{t("registration.ThankYou")}</p>
            <p>{t("registration.SuccessfullyRegisteredText")}</p>
        </Fragment>
    );
}
