import ResetPassword from "./components/ResetPassword";
import ConfirmEmail from "./components/ConfirmEmail";

const AuthPublicPages = [
    {
        path: "reset-password",
        element: <ResetPassword />,
    },
    {
        path: "confirm-email",
        element: <ConfirmEmail />,
    },
];

export default AuthPublicPages;
