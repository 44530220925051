import { Fragment, useState } from "react";
import "./ParameterInfo.scss";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";
import CloseIcon from "@mui/icons-material/Close";

export default function ParameterInfo({ parameter, onHideParameter, onSetValue, disableParameterChange }) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    if (parameter.descs.length > 0)
        return (
            <Fragment>
                <IconButton color="primary" size="small" onClick={() => setOpen(true)} edge="end">
                    <HelpOutlineIcon tw="font-size[20px] -mt-1" />
                </IconButton>
                <Dialog open={open} onClose={handleClose}>
                    <DialogCloseButton onClick={handleClose} />
                    <DialogTitle>{parameter.name}</DialogTitle>
                    <DialogContent>
                        {parameter.descs
                            .filter((d) => d.value == null)
                            .map((desc) => (
                                <div key={desc.value} className="param-text">
                                    {desc.description}
                                </div>
                            ))}
                        <div className="param-info">
                            {parameter.descs
                                .filter((d) => d.value !== null)
                                .map((desc) => {
                                    let btnClass = "param-info__row__btn";
                                    let value = null;
                                    if (parameter.type === "Mood") btnClass += " mood-info smile" + desc.value;
                                    else value = parameter.isValueNameDisplayed && desc.name ? desc.name : desc.value;

                                    return (
                                        <div
                                            key={desc.value}
                                            className="param-info__row"
                                            onClick={() => handleSetValue(parameter.id, desc.value)}
                                        >
                                            <div className="param-info__row__value">
                                                <div className={btnClass}>{value}</div>
                                            </div>
                                            <div className="param-info__row__desc">{desc.description}</div>
                                        </div>
                                    );
                                })}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {!disableParameterChange && parameter.isRemovable && (
                            <div tw="flex justify-center">
                                <Button onClick={() => onHideParameter(parameter.id)}>
                                    {t("records.Parameters.Info.RemoveFromTracking")}
                                </Button>
                            </div>
                        )}
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    else if (disableParameterChange || !parameter.isRemovable) return null;
    else
        return (
            <IconButton color="primary" size="small" onClick={() => onHideParameter(parameter.id)} edge="end">
                <CloseIcon />
            </IconButton>
        );

    function handleClose() {
        setOpen(false);
    }

    function handleSetValue(parameterId, value) {
        onSetValue(parameterId, value);
        handleClose();
    }
}
