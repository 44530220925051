import HorizontalSlider from "../../../commons/horizontalSlider/HorizontalSlider";

export default function HorizontalSliderParameter({ primaryColorCss, parameter, onValueChanged }) {
    return (
        <div css={primaryColorCss}>
            <HorizontalSlider
                value={parameter.value}
                defaultValue={parameter.defaultValue}
                unit={parameter.unit}
                min={parameter.min}
                max={parameter.max}
                step={parameter.step}
                displayDecimals={parameter.displayDecimals}
                decimalSeparator=","
                onValueChanged={(newValue) => onValueChanged(parameter.id, newValue)}
                mode={parameter.type === "Time" ? "time" : "numeric"}
                version={parameter.version}
                isNullAllowed={!parameter.isMandatory}
            />
        </div>
    );
}
