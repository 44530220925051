import { useTranslation } from "react-i18next";
import { apiPost } from "@profilog/misc/utils/useFetch";
import { Link } from "react-router-dom";
import { Fragment } from "react";

const LinkTable = ({ isForAnimals, links, onReviewAccessRevoked }) => {
    const { t, i18n } = useTranslation();

    const revokeReviewAccess = async (linkIdentificator) => {
        let result = await apiPost("/sharing/revoke", linkIdentificator);
        if (result.isOk) {
            onReviewAccessRevoked();
        }
    };

    return links.length > 0 ? (
        <Fragment>
            <p dangerouslySetInnerHTML={{ __html: t("sharing.LinkTable.CanRevokeEachShare") }}></p>
            <div className="table-responsive no-more-tables">
                <table className="table table-hover va-inherit">
                    <thead>
                        <tr>
                            <th scope="col">{t("sharing.LinkTable.Active")}</th>
                            <th scope="col">
                                {t(
                                    isForAnimals
                                        ? t("sharing.LinkTable.ObservedAnimal")
                                        : t("sharing.LinkTable.ObservedPerson"),
                                )}
                            </th>
                            <th scope="col">{t("sharing.LinkTable.Recipient")}</th>
                            <th scope="col">{t("sharing.LinkTable.WhenShared")}</th>
                            <th scope="col">{t("sharing.LinkTable.Form")}</th>
                            <th scope="col">{t("sharing.LinkTable.LastReview")}</th>
                            <th scope="col">{t("sharing.LinkTable.Revoke")}</th>
                            <th scope="col">{t("sharing.LinkTable.Link")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {links.map((link) => (
                            <tr
                                key={link.identificator}
                                className={`clearfix mt-3 ${link.isReviewAccessRevoked ? "revoked-sharing" : ""})`}
                            >
                                <td data-title={t("sharing.LinkTable.Active")}>
                                    {link.isReviewAccessRevoked ? t("global.No") : t("global.Yes")}
                                </td>
                                <td
                                    data-title={
                                        isForAnimals
                                            ? t("sharing.LinkTable.ObservedAnimal")
                                            : t("sharing.LinkTable.ObservedPerson")
                                    }
                                >
                                    {link.medicalSubjectFullName}
                                </td>
                                <td data-title={t("sharing.LinkTable.Recipient")}>{link.recipientEmailAddress}</td>
                                <td data-title={t("sharing.LinkTable.WhenShared")}>
                                    {new Date(link.generated).toLocaleDateString(i18n.language, {
                                        month: "long",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                    })}
                                </td>
                                <td data-title={t("sharing.LinkTable.Form")}>
                                    {link.wasSentViaEmail
                                        ? t("sharing.LinkTable.EmailWithLink")
                                        : t("sharing.LinkTable.JustGeneratedLink")}
                                </td>
                                <td data-title={t("sharing.LinkTable.LastReview")}>
                                    {link.lastReviewed === null
                                        ? t("sharing.LinkTable.NotReviewed")
                                        : new Date(link.lastReviewed).toLocaleDateString(i18n.language, {
                                              month: "long",
                                              day: "numeric",
                                              hour: "numeric",
                                              minute: "numeric",
                                          })}
                                </td>
                                <td className="d-md-pushright">
                                    {!link.isReviewAccessRevoked ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            onClick={() => revokeReviewAccess(link.identificator)}
                                        >
                                            {t("sharing.LinkTable.Revoke")}
                                        </button>
                                    ) : (
                                        t("sharing.LinkTable.Revoked")
                                    )}
                                </td>
                                <td className="d-md-pushleft">
                                    <Link target="_blank" to={`/records-review/${link.identificator}`}>
                                        {t("sharing.LinkTable.Link")}
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    ) : (
        <Fragment>
            <p>
                <em>
                    {isForAnimals
                        ? t("sharing.LinkTable.NotSharingAnimalLinksYet")
                        : t("sharing.LinkTable.NotSharingFamilyLinksYet")}
                </em>
            </p>
        </Fragment>
    );
};

export default LinkTable;
