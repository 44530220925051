import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoginForm from "@profilog/misc/auth/components/LoginForm";
import AppSettings from "@profilog/front/app/AppSettings";
import { Button } from "@mui/material";

export default function LoginCard({
    disableRegistration,
    loggedInUrl = "in/records",
    displayOnlyAfterFailedLoginTest,
    disableElevation,
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <LoginForm
            loggedInUrl={loggedInUrl}
            useGoogleBrand={AppSettings.useGoogleBrand}
            additionalContent={
                !disableRegistration && (
                    <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        onClick={() => navigate(process.env.PUBLIC_URL + "/registration")}
                    >
                        {t("auth.Register.NewRegistration")}
                    </Button>
                )
            }
            displayOnlyAfterFailedLoginTest={displayOnlyAfterFailedLoginTest}
            disableElevation={disableElevation}
        />
    );
}
