import LoginCard from "../../../shared/components/LoginCard";
import "twin.macro";

export default function IcuDemoHome() {
    return (
        <div tw="flex h-screen">
            <div tw="width[500px] m-auto">
                <LoginCard loggedInUrl="in/dashboard" displayOnlyAfterFailedLoginTest />
            </div>
        </div>
    );
}
