import { useTranslation } from "react-i18next";
import MedilogAbout from "../components/MedilogAbout";
import LoginCard from "../../../shared/components/LoginCard";
import ShieldImage from "../../../assets/images/shield.png";
import "./MedilogHome.scss";
import { Fragment } from "react";

export default function MedilogHome() {
    const { t } = useTranslation();

    return (
        <Fragment>
            <section className="home-header">
                <div className="content">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-white text-center text-md-left">
                                <h1 className="t-6 font-weight-bold mt-4 mb-4">{t("home.PersonalRecord")}</h1>
                                <p className="t-5" dangerouslySetInnerHTML={{ __html: t("home.PersonalRecordInfo") }} />
                                <ul className="customer-type-list list-unstyled">
                                    <li>
                                        <a href="#healthy-person">
                                            <span className="customer-type-list__icon icon-heart"></span>
                                            <span
                                                className="customer-type-list__label"
                                                dangerouslySetInnerHTML={{ __html: t("home.HealthyPerson") }}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#patient">
                                            <span className="customer-type-list__icon icon-pills"></span>
                                            <span className="customer-type-list__label">{t("home.Patient")}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#doctor">
                                            <span className="customer-type-list__icon icon-doctor"></span>
                                            <span className="customer-type-list__label">{t("home.Doctor")}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#na">
                                            <span className="customer-type-list__icon icon-traveler"></span>
                                            <span className="customer-type-list__label">{t("home.Traveler")}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-12 col-md-5">
                                <LoginCard />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-5">
                <MedilogAbout />
            </section>

            <section className="mb-4 py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-4 d-flex align-items-center justify-content-center">
                            <img src={ShieldImage} alt="Shield" />
                        </div>
                        <div className="col-12 col-sm-8 mt-3 mt-sm-0 text-center text-sm-left">
                            <h2 className="h3 mt-2 mb-4 font-weight-semibold" id="bezpecnostni-informace">
                                {t("home.IsItSafe")}
                            </h2>
                            <div dangerouslySetInnerHTML={{ __html: t("home.IsItSafeAnswer") }} />
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}
