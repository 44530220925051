import { Fragment, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import LoginDisplay from "./LoginDisplay";
import StaticLinks from "../../medilog/components/MedilogStaticLinks";
import AppSettings from "@profilog/front/app/AppSettings";
import addNavBarToggler from "@profilog/misc/utils/NavBarToggler";
import { useMainMenu } from "./useMainMenu";
import NotificationsInfo from "@profilog/misc/notifications/components/NotificationsInfo";

export default function MainHeader({ logo, logoutOnly }) {
    const mainMenu = useMainMenu();

    useEffect(() => {
        addNavBarToggler();
    }, []);

    return (
        <header className="header--page">
            <div className="navbar-container container">
                <nav className="navbar navbar-expand-md">
                    <Link className="navbar-brand" to="records">
                        {logo}
                    </Link>
                    {!logoutOnly && (
                        <Fragment>
                            {/* Mobile only */}
                            <NotificationsInfo
                                className="generic__notifications generic__mobile-notifications d-md-none"
                                isProfi={false}
                            />
                            <button
                                className="navbar-toggler d-lg-none"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapsibleNavId"
                                aria-controls="collapsibleNavId"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <i className="fas fa-bars"></i>
                                <i className="fas fa-times"></i>
                            </button>
                            <div className="collapse navbar-collapse" id="collapsibleNavId">
                                <ul className="navbar-nav mt-0 mt-md-2 mt-lg-0">
                                    {mainMenu.map((item) => (
                                        <li key={item.to} className="nav-item">
                                            <NavLink className="nav-link" to={item.to}>
                                                {item.text}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>

                                {/* Mobile only */}
                                <div className="dropdown-divider d-block d-md-none"></div>
                                <LoginDisplay mobile />
                                {!AppSettings.isHh && !AppSettings.isEdures && !AppSettings.isPlusCare && (
                                    <Fragment>
                                        <div className="dropdown-divider d-block d-md-none"></div>
                                        <ul className="navbar-nav mt-0 mt-md-2 mt-lg-0 d-md-none">
                                            <StaticLinks />
                                        </ul>
                                    </Fragment>
                                )}
                            </div>
                        </Fragment>
                    )}
                </nav>
                {!AppSettings.isPatientPreview && (
                    <LoginDisplay>
                        {/*{ PC only - takto uvnitř je NotificationsInfo to pouze kvuli stylum */}
                        {!logoutOnly && !AppSettings.isPatientPreview && (
                            <NotificationsInfo className="generic__notifications" isProfi={false} />
                        )}
                    </LoginDisplay>
                )}
            </div>
        </header>
    );
}
