import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
    postSendingOnTest,
    getSendingOnTest,
    getNotificationList,
    removeNotification,
} from "@profilog/misc/utils/PushNotifications";
import ApiErrorsList from "@profilog/misc/errors/ApiErrorsList";
import AppSettings from "../../app/AppSettings";
import DeleteIcon from "@mui/icons-material/Delete";
import { Alert, Button, IconButton } from "@mui/material";

export default function PushNotificationsSettings() {
    const { t } = useTranslation();
    const [successText, setSuccessText] = useState(null);
    const [apiErrors, setApiErrors] = useState(null);
    const [isSendingOnTest, setIsSendingOnTest] = useState(null);
    const [notificationList, setNotificationList] = useState(null);

    useEffect(() => {
        async function fetchData() {
            await getSendingOnTest(
                (value) => setIsSendingOnTest(value),
                (errors) => setApiErrors(errors),
            );
            await getNotificationList(
                (list) => setNotificationList(list),
                (errors) => setApiErrors(errors),
            );
        }

        fetchData();
    }, []);

    function reset() {
        setSuccessText(null);
        setApiErrors(null);
    }

    function onSendingOnTestClick() {
        reset();
        postSendingOnTest(
            !isSendingOnTest,
            (value) => {
                if (value) setSuccessText(t("userSettings.Notifications.AllowedSendingFormTestSuccessText"));
                else setSuccessText(t("userSettings.Notifications.ForbiddenSendingFromTestSuccessText"));
                setIsSendingOnTest(value);
            },
            (errors) => setApiErrors(errors),
        );
    }

    function onRemoveNotification(index) {
        reset();
        const notification = notificationList[index];
        removeNotification(
            notification,
            () => setNotificationList((list) => list.filter((x, i) => i !== index)),
            (errors) => setApiErrors(errors),
        );
    }

    return (
        <Fragment>
            <div>
                <h3>{t("userSettings.Notifications.Title")}</h3>
                {successText !== null && (
                    <Alert severity="success" tw="mb-4">
                        {successText}
                    </Alert>
                )}
                <ApiErrorsList apiErrors={apiErrors} />
            </div>
            {AppSettings.isDevOrTest && (
                <Button variant="contained" color="warning" tw="mt-1" onClick={onSendingOnTestClick}>
                    {isSendingOnTest
                        ? t("userSettings.Notifications.ForbiddenSendingFromTest")
                        : t("userSettings.Notifications.AllowedSendingFormTest")}
                </Button>
            )}

            {notificationList !== null && (
                <div tw="mt-4">
                    {notificationList.length > 0 ? (
                        t("userSettings.Notifications.List.HeaderDeviceListText") + ":"
                    ) : (
                        <Alert severity="info">{t("userSettings.Notifications.List.HeaderNoDeviceListText")}</Alert>
                    )}
                    {notificationList.length > 0 && (
                        <div style={{ width: "fit-content" }}>
                            {notificationList.map((notification, index) => (
                                <div tw="mb-2 flex justify-between" key={index}>
                                    <div tw="pr-2">{notification.name}</div>
                                    <IconButton
                                        color="primary"
                                        onClick={() => onRemoveNotification(index)}
                                        title={t("userSettings.Notifications.List.RemoveButtonText")}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );
}
