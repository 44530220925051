import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getSourceInfo } from "@profilog/records/sources";

export default function SourceTypeSelector({ sourceTypes, sourceNames, selectedValue, handleOnChange }) {
    const { t } = useTranslation();
    const selVal = selectedValue !== undefined && selectedValue !== null ? selectedValue : -1;

    function getItems() {
        var items = sourceTypes.map((sourceType) => ({
            value: sourceType,
            sourceInfo: getSourceInfo(sourceType, sourceNames[sourceType]),
        }));
        if (selectedValue !== undefined) return items;
        return [...items, { value: -1, name: t("global.Gender.Undefined") }];
    }

    function onChange(e) {
        if (!handleOnChange) return;

        const value = parseInt(e.target.value);
        if (value < 0) handleOnChange(null);
        else handleOnChange(value);
    }

    return (
        <Select
            value={selVal}
            onChange={onChange}
            sx={{
                "& .MuiSvgIcon-root": { right: 0 },
                "& .MuiSelect-select": { paddingRight: "22px !important" },
                "& .MuiOutlinedInput-input": { px: "4px", py: "1px", fontSize: "10px" },
            }}
            IconComponent={ArrowDropDownIcon}
        >
            {getItems().map((item) => (
                <MenuItem key={item.value} value={item.value} title={item.sourceInfo.path}>
                    {item.sourceInfo.displayName}
                </MenuItem>
            ))}
        </Select>
    );
}
