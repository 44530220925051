import { useTranslation } from "react-i18next";
import { Button, IconButton } from "@mui/material";
import ParameterListDialog from "./ParameterListDialog";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import PostAddIcon from "@mui/icons-material/PostAdd";

export default function AddParameter({
    parameters,
    noteParameterId,
    recordDateParameterId,
    onAddParameter,
    onParameterCreated,
    medicalSubjectId,
}) {
    const { t } = useTranslation();

    const isNoteHidden = parameters.find((p) => p.id === noteParameterId) !== undefined;
    const isTimeHidden = parameters.find((p) => p.id === recordDateParameterId) !== undefined;

    return (
        <div tw="flex justify-between items-center">
            <div>
                {isNoteHidden && (
                    <IconButton
                        color="primary"
                        edge="start"
                        size="sm"
                        onClick={() => handleAddParameter(noteParameterId)}
                        title={t("records.Edit.Note")}
                    >
                        <PostAddIcon />
                    </IconButton>
                )}
                {isTimeHidden && (
                    <IconButton
                        color="primary"
                        size="sm"
                        onClick={() => handleAddParameter(recordDateParameterId)}
                        title={t("records.Edit.RecordDate")}
                    >
                        <MoreTimeIcon />
                    </IconButton>
                )}
            </div>

            <ParameterListDialog
                parameters={parameters}
                medicalSubjectId={medicalSubjectId}
                onParameterCreated={onParameterCreated}
                onAddParameter={handleAddParameter}
                trigger={(open) => <Button onClick={open}>{t("records.Parameters.Add.AddParameter")}</Button>}
            />
        </div>
    );

    function handleAddParameter(parameterId) {
        onAddParameter(parameterId);
    }
}
