import { prescriptionTypes } from "../../codebooks/prescriptionTypes";
import MedicationOutlinedIcon from "@mui/icons-material/MedicationOutlined";
import DirectionsRunOutlinedIcon from "@mui/icons-material/DirectionsRunOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
export default function PrescriptionIcon({ type, sx, color }) {
    switch (type) {
        case prescriptionTypes.drug:
            return <MedicationOutlinedIcon sx={sx} color={color} />;
        case prescriptionTypes.action:
            return <DirectionsRunOutlinedIcon sx={sx} color={color} />;
        case prescriptionTypes.measurement:
            return <AssessmentOutlinedIcon sx={sx} color={color} />;
        case prescriptionTypes.survey:
            return <BallotOutlinedIcon sx={sx} color={color} />;
        default:
            return null;
    }
}
