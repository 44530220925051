import { useEffect, useState } from "react";
import { useFetch } from "../../utils/useFetch";
import { getQuery } from "../../utils/Url";
import FetchOptions from "../../utils/FetchOptions";
import { useTranslation } from "react-i18next";

export default function useRemoteGoogleFit(refresh) {
    const { t } = useTranslation();
    const { apiPost, ApiErrors } = useFetch();
    const [data, setData] = useState(null);
    const [customError, setCustomError] = useState(null);
    const query = getQuery();

    useEffect(() => {
        getState();

        const gcErrorCode = query.get("gc-error");
        if (gcErrorCode !== null) setCustomError(t("extConnectors.Error.GetConsent"));
    }, [refresh]); // eslint-disable-line

    return {
        connect,
        disconnect,
        data,
        ApiErrors,
        customError,
        getSharingInfo,
    };

    async function connect(returnUrlQueryString) {
        window.location =
            FetchOptions.apiUrl +
            "/remote-google-fit/connect?ReturnUrl=" +
            encodeURIComponent(
                `${window.location.protocol}//${window.location.host}${window.location.pathname}${
                    returnUrlQueryString ? `?${returnUrlQueryString}` : ""
                }`,
            );
    }

    async function getSharingInfo() {
        const response = await apiPost("/remote-google-fit/sharing-info");
        if (response.isOk) return response.json;
        return null;
    }

    async function disconnect(withRelated = false) {
        const qs = new URLSearchParams({
            withRelated: withRelated ? "true" : "false",
        });
        const response = await apiPost("/remote-google-fit/disconnect?" + qs);
        if (response.isOk) await getState();
        return response.isOk ? null : "Při odpojení došlo k chybě.";
    }

    async function getState() {
        const response = await apiPost("/remote-google-fit/get-state");
        if (response.isOk) setData(response.json);
    }
}
