import { useSelector } from "react-redux";
import { stringToIntOrNull } from "@profilog/utils/converting";
import { getIntervalText } from "../../../utils/dates";
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { intervalUnits } from "../../../codebooks/intervalUnits";
import { Fragment } from "react";

export default function Repeat({
    editing,
    open,
    wasOpen,
    saved,
    intervalSelector,
    intervalUnitSelector,
    sameSelector = () => true,
    dispatchUpdate,
}) {
    const { t } = useTranslation();
    const interval = useSelector(intervalSelector);
    const intervalUnit = useSelector(intervalUnitSelector);
    const same = useSelector(sameSelector);

    return editing ? (
        <Fragment>
            <TextField
                autoFocus={open}
                size="small"
                label={t("prescriptions.Edit.Repeat")}
                value={interval}
                onChange={(e) => dispatchUpdate({ interval: stringToIntOrNull(e.target.value) })}
                InputLabelProps={{ shrink: true }}
                sx={{ width: "12ch", marginRight: 1 }}
            />
            <FormControl component="fieldset">
                <RadioGroup
                    row
                    size="small"
                    value={intervalUnit}
                    onChange={(e) => dispatchUpdate({ intervalUnit: e.target.value })}
                >
                    {Object.values(intervalUnits).map((value) => (
                        <FormControlLabel
                            key={value}
                            value={value}
                            control={<Radio size="small" />}
                            label={t("prescriptions.IntervalUnits." + value)}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Fragment>
    ) : (
        getInterval()
    );

    function getInterval() {
        if (!same) return <strong>{t("prescriptions.Edit.RepeatDifference")}</strong>;

        const { text, isDefault } = getIntervalText(interval, intervalUnit, t);
        if (!saved && isDefault && !wasOpen) return t("prescriptions.Edit.setRepeat");

        return isDefault ? text : <strong>{text}</strong>;
    }
}
