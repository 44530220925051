import GenericMainHeader from "./components/GenericMainHeader";
import MagicWareFooter from "../../shared/components/MagicWareFooter";
import { Outlet } from "react-router-dom";
import { Fragment } from "react";

export default function GenericMainLayout({ logo, logoutOnly, renderFooter = null }) {
    return (
        <Fragment>
            <GenericMainHeader logo={logo} logoutOnly={logoutOnly} />
            <Outlet />
            {renderFooter ? renderFooter() : <MagicWareFooter />}
        </Fragment>
    );
}
