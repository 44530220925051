import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../utils/useFetch";
import { getEmailAndToken } from "../../utils/Url";
import { useNavigate } from "react-router-dom";
import useInterval from "../../utils/IntervalHook";

export default function ConfirmEmail({
    apiUrl = "/web/user/confirm-email",
    redirectWhenConfirmed = true,
    successMessage = null,
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isInProgress, setIsInProgress] = useState(true);
    const { apiPost, ApiErrors } = useFetch();
    const [isOk, setIsOk] = useState(null);

    useEffect(() => {
        async function confirmEmail() {
            const emailAndToken = getEmailAndToken();
            const url = apiUrl;
            const response = await apiPost(url, emailAndToken);
            setIsOk(response.isOk);
            setIsInProgress(false);
        }

        confirmEmail();
    }, []); // eslint-disable-line

    useInterval(() => {
        if (isOk && redirectWhenConfirmed) {
            navigate("/in/records");
            return null;
        }
    }, 5000);

    if (!successMessage) successMessage = t("auth.ConfirmEmail.Ok");

    return (
        <Fragment>
            <div tw="mb-2">
                {isInProgress ? (
                    t("auth.ConfirmEmail.Wait")
                ) : isOk ? (
                    successMessage
                ) : (
                    <Fragment>
                        <div tw="mb-2">{t("auth.ConfirmEmail.Error")}</div>
                        <ApiErrors />
                        <div tw="mt-2">{t("auth.ConfirmEmail.TryAgain")}</div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
}
