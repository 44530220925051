import { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function PasswordInput({ inputRef, ...rest }) {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    return (
        <TextField
            inputRef={inputRef}
            type={isPasswordVisible ? "text" : "password"}
            {...rest}
            inputProps={{ autoCapitalize: "off" }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton size="small" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                            {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}
