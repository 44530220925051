import { Link } from "react-router-dom";
import { useAuth } from "@profilog/misc/auth/useAuth";

export default function HomeLink({ children, className }) {
    const { currentUserData } = useAuth();

    let url;
    let hard = false;
    if (currentUserData) url = "/in/records";
    else {
        url = "/";
        if (process.env.PUBLIC_URL && process.env.PUBLIC_URL !== "/") hard = true;
    }

    return hard ? (
        <a className={className} href={url}>
            {children}
        </a>
    ) : (
        <Link className={className} to={url}>
            {children}
        </Link>
    );
}
