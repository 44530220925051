import React, { useContext, useState, useEffect, Fragment } from "react";
import { useFetch } from "../../utils/useFetch";
import { CircularProgress, Button, Modal as MuiModal, Paper, Popover, useMediaQuery } from "@mui/material";
import "twin.macro";
import styled from "@mui/styled-engine";
import { useTheme } from "@mui/system";
import NotificationList from "./NotificationList";
import CurrentNotificationContext from "../CurrentNotificationContext";
import { notificationTypes } from "./notificationTypes";
import { useNavigate } from "react-router-dom";
import { cooperationRequestTypes } from "./cooperationRequestTypes";
import { useApiIntervalGet } from "../../commons/components/ApiIntervalGet";

export default function NotificationsInfo({ isProfi, openCommunications, propName = "count", ...props }) {
    const muiTheme = useTheme();
    const { apiGet, apiPut } = useFetch();
    const [isLoading, setIsLoading] = useState(true);
    const isDesktop = useMediaQuery(muiTheme.breakpoints.up("md"));
    const [allNotifications, setAllNotifications] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const { currentNotification, setCurrentNotification } = useContext(CurrentNotificationContext);
    const navigate = useNavigate();

    const MobilePopoverAnchor = styled("div")({
        position: "fixed",
        top: 0,
        left: 0,
    });

    const Modal = styled(MuiModal)({
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "10%",
        overflow: "scroll",
        height: "auto",
        display: "block",
    });

    const NotificationsList = isDesktop ? Popover : Modal;

    useEffect(() => {
        if (anchorEl === null) return;

        async function loadDataAsync() {
            const response = await apiGet("/web/notifications");

            if (response.isOk) {
                setAllNotifications(response.json);
                setIsLoading(false);
            }
        }

        loadDataAsync();
        // eslint-disable-next-line
    }, [anchorEl]);

    const open = Boolean(anchorEl);
    const id = open ? "notifications-popup" : undefined;

    const { value: newNotCount, ApiErrors: CounterApiErrors } = useApiIntervalGet(
        "/web/notifications/new-count",
        null,
        5000,
        0,
        (json) => json["newCount"],
    );

    const params = {};
    params[propName] = newNotCount;

    let elements = React.Children.toArray(props.children).map((el) => {
        return React.cloneElement(el, params);
    });

    return (
        <Fragment>
            <MobilePopoverAnchor />
            <div>
                <Button id="notifications-button" onClick={handleClick}>
                    {elements}
                </Button>
            </div>
            <NotificationsList
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                sx={{
                    maxWidth: "500px",
                    minWidth: "320px",
                }}
            >
                <Paper>
                    {isLoading ? (
                        <div tw="relative">
                            <div tw="flex width[468px] height[120px] flex-col grow">
                                <div tw="overflow-hidden overflow-ellipsis whitespace-nowrap textAlign[center] relative top[30%]">
                                    <CircularProgress />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <NotificationList notifications={allNotifications} onNotificationClick={processNotification} />
                    )}
                </Paper>
            </NotificationsList>
        </Fragment>
    );

    function handleClick(evt) {
        if (newNotCount > 0)
            setAnchorEl((ae) => {
                if (ae === null) {
                    setIsLoading(true);
                    return isDesktop ? evt.currentTarget : MobilePopoverAnchor;
                }
                return null;
            });
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function processNotification(evt, notification) {
        setAnchorEl(null);
        setCurrentNotification({ notification: notification });

        if (isProfi) {
            switch (notification.type) {
                case notificationTypes.message:
                case notificationTypes.comment:
                    if (openCommunications) openCommunications(notification.message.medicalSubjectId);
                    break;
                case notificationTypes.cooperationRequestSent:
                    //fromTherapistToPatient by byla chyba v DB
                    if (notification.cooperationRequest.type === cooperationRequestTypes.fromPatientToTherapist)
                        navigate("/in/cooperation-requests", { replace: true });
                    break;
                case notificationTypes.cooperationRequestAccepted:
                case notificationTypes.cooperationRequestRejected:
                    //fromPatientToTherapist by byla chyba v DB
                    if (notification.cooperationRequest.type === cooperationRequestTypes.fromTherapistToPatient)
                        setNotificationAsReadAsync(notification);
                    break;
                default:
                    // Nemělo by nastat.
                    console.log("Chyba: Neznámý typ notifikace, nelze zobrazit.");
                    break;
            }
        } else {
            switch (notification.type) {
                case notificationTypes.message:
                    navigate("/in/communication", { replace: true });
                    break;
                case notificationTypes.comment:
                    navigate("/in/records", { replace: true });
                    break;
                case notificationTypes.cooperationRequestSent:
                    //fromPatientToTherapist by byla chyba v DB
                    if (notification.cooperationRequest.type == cooperationRequestTypes.fromTherapistToPatient) {
                        navigate(`settings`, { replace: true });
                    }
                    break;
                case notificationTypes.cooperationRequestAccepted:
                case notificationTypes.cooperationRequestRejected:
                    //fromTherapistToPatient by byla chyba v DB
                    if (notification.cooperationRequest.type == cooperationRequestTypes.fromPatientToTherapist) {
                        navigate(`settings`, { replace: true });
                        setNotificationAsReadAsync(notification);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    /** Odešle na server put request, aby se notifikace označila jako přečtená. */
    async function setNotificationAsReadAsync(notification) {
        const markNotificationAsReadDto = {
            notificationId: notification.id,
        };
        await apiPut("/web/notifications/set-as-read", markNotificationAsReadDto);
    }
}
