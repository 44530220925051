export const prescriptionTypes = {
    drug: "Drug",
    action: "Action",
    measurement: "Measurement",
    survey: "Survey",
};

export const defaultPrescriptionTypeUrl = "meds";
export const prescriptionTypesUrls = {
    [defaultPrescriptionTypeUrl]: prescriptionTypes.drug,
    actions: prescriptionTypes.action,
    measurements: prescriptionTypes.measurement,
    surveys: prescriptionTypes.survey,
};

export function supportsAmount(prescriptionType) {
    return prescriptionType === prescriptionTypes.drug || prescriptionType === prescriptionTypes.action;
}
