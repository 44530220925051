import { useTranslation } from "react-i18next";
import useCalorieTables from "@profilog/misc/user/hooks//useCalorieTables";
import CaloricsTablesConnectionDialog from "@profilog/misc/user/components/CaloricsTablesConnectionDialog";
import { Fragment, useState } from "react";
import { Button } from "@mui/material";

export default function CalorieTablesConnectionSelection({ title, onCalorieTablesConnected }) {
    const { t } = useTranslation();
    const { isLoading, connect, connectErrors, redirectToRegistrationPage, ApiErrors } = useCalorieTables(onConnected);
    const [open, setOpen] = useState(false);

    function onConnected() {
        handleClose();
        onCalorieTablesConnected();
    }

    if (isLoading) return null;

    return (
        <Fragment>
            <CaloricsTablesConnectionDialog
                open={open}
                connect={connect}
                onClose={handleClose}
                connectErrors={connectErrors}
                ApiErrors={ApiErrors}
            />

            <div tw="text-sm">{title}</div>
            <div tw="flex space-x-2">
                <Button variant="outlined" color="success" onClick={() => setOpen(true)}>
                    Mám účet
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => redirectToRegistrationPage("/in/user-initialization")}
                >
                    Nemám účet
                </Button>
            </div>
            <div tw="mt-5">
                <Button variant="contained" color="secondary" onClick={onCalorieTablesConnected}>
                    {t("userInitialization.ExternalConnectionsInitialization.Skip")}
                </Button>
            </div>
        </Fragment>
    );

    function handleClose() {
        setOpen(false);
    }
}
