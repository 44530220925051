import { useState } from "react";

const FormSelector = ({ id, items, selectedValue, name, formRegister, className = "form-control", disabled }) => {
    const [selected, setSelected] = useState(selectedValue);

    function handleOnChange(event) {
        setSelected(event.target.value);
    }

    return (
        <select
            id={id}
            className={className}
            name={name}
            value={selected}
            onChange={handleOnChange}
            ref={formRegister}
            disabled={disabled}
        >
            {items.map((item) => (
                <option key={item.value} value={item.value}>
                    {item.name}
                </option>
            ))}
        </select>
    );
};

export default FormSelector;
