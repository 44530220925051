import CodebookComboBox from "./CodebookComboBox";
import { useGetActions } from "../../redux/codebooksSlice";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function ActionComboBox({ value, className, onChange, autocompleteProps }) {
    const { t } = useTranslation();

    const props = { label: t("prescriptions.Types.Action"), freeSolo: true };
    if (autocompleteProps) Object.assign(props, autocompleteProps);

    return (
        <Fragment>
            <CodebookComboBox
                value={value}
                onChange={onChange}
                getOptionsHook={useGetActions}
                autocompleteProps={props}
                className={className}
            />
        </Fragment>
    );
}
