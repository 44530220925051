import { TextField } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "twin.macro";
import { stringToStringOrNull } from "@profilog/utils/converting";
import { prescriptionScheduleUpdate } from "../../../redux/prescriptionSlice";

export default function AmountNote({ scheduleId, editing, open }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const amountNote = useSelector((state) => state.prescriptions.schedules.byId[scheduleId].amountNote);

    return editing ? (
        <div>
            <TextField
                size="small"
                autoFocus
                label={t("prescriptions.Edit.Note")}
                value={amountNote ?? ""}
                onChange={(e) => dispatchUpdate({ amountNote: stringToStringOrNull(e.target.value) })}
            />
        </div>
    ) : (
        <Fragment>{amountNote ? <strong>{amountNote}</strong> : t("prescriptions.Edit.noteShort")}</Fragment>
    );

    function dispatchUpdate(payload) {
        dispatch(prescriptionScheduleUpdate({ scheduleId, updated: payload }));
    }
}
