import ImageMap from "@profilog/commons/components/ImageMap";
import { getImageMapConfig } from "@profilog/parameters/imageMap/getImageMapConfig";

export default function ImageMapParameter({ parameter, onChange }) {
    const { imgUrl, circleRatio } = getImageMapConfig(parameter.unit);

    return (
        <div tw="bg-gray-100 rounded-md overflow-hidden">
            <ImageMap
                imgUrl={imgUrl}
                circleRatio={circleRatio}
                points={parameter.value}
                onChange={(points) => onChange(parameter.systemName, points)}
            />
        </div>
    );
}
