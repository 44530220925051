import Records from "@profilog/front/records/pages/Records";
import PersonalInfo from "@profilog/front/user/pages/PersonalInfo";
import RelatedMedicalSubjectsList from "../relations/pages/RelatedMedicalSubjectsList";
import GroupsList from "../relations/pages/GroupsList";
import UserSettings from "@profilog/front/user/components/UserSettings";
import Sharing from "../sharing/pages/Sharing";
import Communication from "@profilog/front/communication/pages/Communication";
import RecordsReviewPreview from "../sharing/pages/RecordsReviewPreview";
import UserInitialization from "@profilog/front/user/pages/UserInitialization";
import PrescriptionList from "@profilog/front/prescriptions/pages/PrescriptionList";
import Medication from "@profilog/front/prescriptions/pages/Medication";
import MainApp from "@profilog/front/main/components/MainApp";
import Survey from "@profilog/front/surveys/pages/Survey";
import Surveys from "@profilog/front/surveys/pages/Surveys";
import SurveySaved from "@profilog/front/surveys/pages/SurveySaved";
import UnivBoxLayout from "../shared/components/UnivBoxLayout";
import DrugCalendar from "@profilog/prescriptions/components/calendar/DrugCalendar";
import SurveyCalendar from "@profilog/prescriptions/components/calendar/SurveyCalendar";
import NotFound from "../NotFound";
import EmptyLayout from "@profilog/front/layout/components/EmptyLayout";

export function getMainAppPages(layout = null, recordsLayout, additionalRoutes = []) {
    return [
        {
            path: "in",
            element: <MainApp layout={layout ?? <EmptyLayout />} />,
            children: [
                { path: "records-review-preview", element: <RecordsReviewPreview /> },
                {
                    path: "records",
                    element: (
                        <Records Layout={recordsLayout} DrugCalendar={DrugCalendar} SurveyCalendar={SurveyCalendar} />
                    ),
                },
                { path: "sharing", element: <Sharing /> },
                {
                    element: <UnivBoxLayout />,
                    children: [
                        { path: "communication", element: <Communication /> },
                        { path: "about-me", element: <PersonalInfo /> },
                        { path: "settings", element: <UserSettings /> },
                        { path: "medication", element: <Medication /> },
                        { path: "relations", element: <RelatedMedicalSubjectsList /> },
                        { path: "groups", element: <GroupsList /> },
                        { path: "prescriptions", element: <PrescriptionList /> },
                        { path: "prescriptions/:type", element: <PrescriptionList /> },
                        { path: "surveys/:surveyUrlParam", element: <Survey NotFound={NotFound} /> },
                        { path: "surveys/:surveyUrlParam/saved", element: <SurveySaved NotFound={NotFound} /> },
                        { path: "surveys", element: <Surveys /> },
                    ],
                },
                ...additionalRoutes,
            ],
        },
    ];
}

export function getLogoutOnlyPages(logoutOnlyLayout) {
    return [
        {
            path: "in",
            element: <MainApp layout={logoutOnlyLayout} />,
            children: [
                {
                    element: <UnivBoxLayout />,
                    children: [{ path: "user-initialization", element: <UserInitialization /> }],
                },
            ],
        },
    ];
}
