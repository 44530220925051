import { useEffect, useContext, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import PushNotificationsSettings from "./PushNotificationsSettings";
import VoicebotAssistantSettings from "./VoicebotAssistantSettings";
import Milestones from "./Milestones";
import PrescriptionNotificationSettings from "./PrescriptionNotificationSettings";
import GoogleFitConsent from "@profilog/misc/user/components/GoogleFitConsent";
import GarminConnection from "@profilog/misc/user/components/GarminConnection";
import WithingsConnection from "@profilog/misc/user/components/WithingsConnection";
import CaloricsTablesConnection from "@profilog/misc/user/components/CaloricsTablesConnection";
import OneTouchConnection from "@profilog/misc/user/components/OneTouchConnection";
import FitbitConnection from "@profilog/misc/user/components/FitbitConnection";
import HuaweiConnection from "@profilog/misc/user/components/HuaweiConnection";
import RemoteGoogleFitConnection from "@profilog/misc/user/components/RemoteGoogleFitConnection";
import StravaConnection from "@profilog/misc/user/components/StravaConnection";
import EnergyTypeChange from "@profilog/misc/user/components/EnergyTypeChange";
import EmailChange from "@profilog/misc/user/components/EmailChange";
import PasswordChange from "@profilog/misc/user/components/PasswordChange";
import CooperationRequest from "../../communication/components/CooperationRequests";
import AppleHealthConnection from "../../externalServices/components/appleHealth/AppleHealthConnection";
import GooglePlayApp from "../../externalServices/components/googlePlay/GooglePlayApp";
import DeleteAccount from "@profilog/misc/user/DeleteAccount";
import AppSettings from "../../app/AppSettings";
import CurrentNotificationContext from "@profilog/misc/notifications/CurrentNotificationContext";
import { notificationTypes } from "@profilog/misc/notifications/components/notificationTypes";
import { cooperationRequestTypes } from "@profilog/misc/notifications/components/cooperationRequestTypes";
import { findMobileAppUrl } from "../../externalServices/findMobileAppUrl";
import { Alert, Divider, Tab, Tabs } from "@mui/material";

export default function UserSettings({ disabledTabs = new Set() }) {
    const { t } = useTranslation();
    const { currentNotification } = useContext(CurrentNotificationContext);
    const [value, setValue] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const tabs = [];

    if (!disabledTabs.has(0)) tabs.push({ name: "Aplikace", tabIndex: 0 });

    if (!AppSettings.isPatientPreview) {
        if (!disabledTabs.has(1)) tabs.push({ name: t("userSettings.Collaboration"), tabIndex: 1 });
        if (!disabledTabs.has(2)) tabs.push({ name: t("userSettings.Customization"), tabIndex: 2 });
        if (!disabledTabs.has(3)) tabs.push({ name: t("userSettings.NotificationsTitle"), tabIndex: 3 });
    }
    if (AppSettings.isEdures || AppSettings.isCpz) tabs.push({ name: t("userSettings.Milestones.Title"), tabIndex: 4 });
    if (!AppSettings.isPatientPreview) {
        if (!disabledTabs.has(5)) tabs.push({ name: t("userSettings.EmailPwdChange"), tabIndex: 5 });
        if (!disabledTabs.has(6)) tabs.push({ name: t("userSettings.DeleteAccount.Title"), tabIndex: 6 });
    }

    useEffect(() => {
        if (AppSettings.isPatientPreview) return;

        if (
            (currentNotification?.notification?.cooperationRequest?.type ===
                cooperationRequestTypes.fromTherapistToPatient &&
                currentNotification?.notification?.type === notificationTypes.cooperationRequestSent) ||
            (currentNotification?.notification?.cooperationRequest?.type ===
                cooperationRequestTypes.fromPatientToTherapist &&
                (currentNotification?.notification?.type === notificationTypes.cooperationRequestRejected ||
                    currentNotification?.notification?.type === notificationTypes.cooperationRequestAccepted))
        ) {
            setValue(1);
        }
        // eslint-disable-next-line
    }, [currentNotification?.notification]);

    function handleChange(_, newValue) {
        setValue(newValue);
    }

    return (
        <Fragment>
            <h2>{t("loginDisplay.Settings")}</h2>

            {tabs.length === 0 && <Alert severity="info">{t("userSettings.NoTabs")}</Alert>}

            <Tabs
                variant="scrollable"
                value={value}
                onChange={handleChange}
                scrollButtons="auto"
                allowScrollButtonsMobile
                tw="mb-4"
            >
                {tabs.map((item, index) => (
                    <Tab
                        key={index}
                        label={item.name}
                        className="nav-link"
                        value={item.tabIndex}
                        {...a11yProps(item.tabIndex)}
                    />
                ))}
            </Tabs>

            {!disabledTabs.has(0) && (
                <TabPanel value={value} index={0}>
                    <ExternalService
                        visible={AppSettings.externalServices.googleFit}
                        component={GoogleFitConsent}
                        props={{
                            useGoogleBrand: AppSettings.useGoogleBrand,
                            refresh,
                            onDisconnect: updateRefresh,
                        }}
                    />
                    <ExternalService
                        visible={AppSettings.externalServices.remoteGoogleFit}
                        component={RemoteGoogleFitConnection}
                        props={{ refresh, onDisconnect: updateRefresh }}
                    />
                    <ExternalService
                        visible={AppSettings.externalServices.garminConnect}
                        component={GarminConnection}
                    />
                    <ExternalService visible={AppSettings.externalServices.withings} component={WithingsConnection} />
                    <ExternalService
                        visible={AppSettings.externalServices.caloricsTables}
                        component={CaloricsTablesConnection}
                    />
                    <ExternalService
                        visible={AppSettings.externalServices.oneTouchReveal}
                        component={OneTouchConnection}
                    />
                    <ExternalService
                        visible={AppSettings.externalServices.fitbit}
                        component={FitbitConnection}
                        props={{ refresh, onDisconnect: updateRefresh }}
                    />
                    <ExternalService visible={AppSettings.externalServices.huawei} component={HuaweiConnection} />
                    <ExternalService
                        visible={AppSettings.externalServices.strava}
                        component={StravaConnection}
                    />

                    <MobileApp config={AppSettings.externalServices.iPhoneApp} component={AppleHealthConnection} />

                    <MobileApp config={AppSettings.externalServices.androidApp} component={GooglePlayApp} />
                </TabPanel>
            )}

            {!disabledTabs.has(1) && (
                <TabPanel value={value} index={1}>
                    <CooperationRequest />
                </TabPanel>
            )}

            {!disabledTabs.has(2) && (
                <TabPanel value={value} index={2}>
                    <EnergyTypeChange />
                </TabPanel>
            )}

            {!disabledTabs.has(3) && (
                <TabPanel value={value} index={3}>
                    <PushNotificationsSettings />
                    {AppSettings.arePrescriptionsEnabled && (
                        <Fragment>
                            <Divider />
                            <h3>{t("userSettings.PrescriptionNotifications.Title")}</h3>
                            <PrescriptionNotificationSettings />
                        </Fragment>
                    )}
                    <Divider sx={{ paddingTop: "10px" }} />
                    <VoicebotAssistantSettings medicalSubjectId={AppSettings.user.userMedicalSubjectId} />
                </TabPanel>
            )}

            {!disabledTabs.has(4) && (
                <TabPanel value={value} index={4}>
                    <Milestones medicalSubjectId={AppSettings.user.userMedicalSubjectId} />
                </TabPanel>
            )}

            {!disabledTabs.has(5) && (
                <TabPanel value={value} index={5}>
                    <div tw="sm:w-1/2 md:w-1/4">
                        <h3>{t("userSettings.EmailChange.Title")}</h3>
                        <EmailChange />
                        <h3>{t("userSettings.PasswordChange.Title")}</h3>
                        <PasswordChange />
                    </div>
                </TabPanel>
            )}

            {!disabledTabs.has(6) && (
                <TabPanel value={value} index={6}>
                    <DeleteAccount />
                </TabPanel>
            )}
        </Fragment>
    );

    function updateRefresh() {
        setRefresh(refresh + 1);
    }
}

function TabPanel({ children, noPadding, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`settings-tabpanel-${index}`}
            aria-labelledby={`settings-tab-${index}`}
            {...other}
        >
            {value === index && <Fragment>{children}</Fragment>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `settings-tab-${index}`,
        "aria-controls": `settings-tabpanel-${index}`,
    };
}

function ExternalService({ visible, component, props = {} }) {
    let Component = component;
    if (!visible) return null;

    return <Component readonly={AppSettings.isPatientPreview} {...props} Layout={ExternalServiceLayout} />;
}

function MobileApp({ config, component }) {
    let Component = component;
    const url = findMobileAppUrl(config);
    if (!url) return null;

    return (
        <ExternalServiceLayout>
            <Component readonly={AppSettings.isPatientPreview} appUrl={url} />
        </ExternalServiceLayout>
    );
}

function ExternalServiceLayout({ children }) {
    return (
        <Fragment>
            <div tw="mb-5">{children}</div>
            <Divider />
        </Fragment>
    );
}
