import { useEffect, useState } from "react";
import "twin.macro";
import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    CommonSeriesSettings,
    Legend,
    Tooltip,
    ZoomAndPan,
    Animation,
    Grid,
    Border,
    Size,
    Point,
    Tick,
    Label,
} from "devextreme-react/chart";
import { useFetch } from "@profilog/misc/utils/useFetch";
import { format } from "date-fns";
import ChartButtons from "./ChartButtons";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

export default function DashboardCharts() {
    const { t } = useTranslation();
    const [parametersTabIndex, setParametersTabIndex] = useState(0);
    const [visualRangeTabIndex, setVisualRangeTabIndex] = useState(2);
    const { apiGet, ApiErrors } = useFetch();
    const [allData, setAllData] = useState(null);
    const [ranges, setRanges] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const chartsData = [
        { name: "HR", parameterId: 250 },
        { name: "Spo2", parameterId: 274 },
        { name: "BPs", parameterId: 184 },
        { name: "BPd", parameterId: 187 },
        { name: "Temp", parameterId: 1 },
    ];

    async function getAllData() {
        const visualEnd = new Date();
        const visualStart = new Date(visualEnd);
        const wholeEnd = new Date(visualEnd);
        const wholeStart = new Date(visualEnd);
        if (visualRangeTabIndex === 1) {
            visualStart.setHours(visualStart.getHours() - 1);
            wholeStart.setHours(wholeStart.getHours() - 2);
            wholeEnd.setHours(wholeEnd.getHours() + 1);
        }
        if (visualRangeTabIndex === 2) {
            visualStart.setDate(visualStart.getDate() - 1);
            wholeStart.setDate(wholeStart.getDate() - 2);
            wholeEnd.setDate(wholeEnd.getDate() + 1);
        }
        if (visualRangeTabIndex === 3) {
            visualStart.setDate(visualStart.getDate() - 7);
            wholeStart.setDate(wholeStart.getDate() - 14);
            wholeEnd.setDate(wholeEnd.getDate() + 7);
        }

        setRanges({
            visualRange: { startValue: visualStart, endValue: visualEnd },
            wholeRange: { startValue: wholeStart, endValue: wholeEnd },
        });
        setIsLoading(true);

        var parameterIds;
        if (parametersTabIndex === 0) parameterIds = chartsData.map((x) => x.parameterId);
        else parameterIds = [chartsData[parametersTabIndex - 1].parameterId];

        const response = await apiGet(
            "/records/icuplus" +
                "?dateFrom=" +
                format(wholeStart, "yyyy-MM-dd HH:mm:ss") +
                "&dateTo=" +
                format(visualEnd, "yyyy-MM-dd HH:mm:ss") +
                "&parameterIds=" +
                parameterIds.join("&parameterIds="),
        );
        if (response.isOk) {
            setAllData(response.json);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (visualRangeTabIndex > 0) {
            getAllData();
        }
    }, [visualRangeTabIndex, parametersTabIndex]);

    function handleOptionChange(e) {
        if (e.fullName === "argumentAxis.visualRange") {
            setRanges({ visualRange: e.value, wholeRange: ranges.wholeRange });
        }
    }

    function customizeTooltip(pointInfo, unit) {
        return { text: `${pointInfo.value} ${unit}` };
    }

    return (
        <div>
            <div tw="pl-4 flex justify-between background[ linear-gradient(180deg, #fff 45%, #e1e1e1 100%)] sticky top-0 z-index[3]">
                <ChartButtons
                    tabIndex={parametersTabIndex}
                    onTabChanged={(newIndex) => setParametersTabIndex(newIndex)}
                    tabs={[t("icudemo.All")].concat(chartsData.map((x) => x.name))}
                />
                <ChartButtons
                    tabIndex={visualRangeTabIndex}
                    onTabChanged={(newIndex) => setVisualRangeTabIndex(newIndex)}
                    tabs={[t("icudemo.RealTime"), t("icudemo.1Hour"), t("icudemo.1Day"), t("icudemo.1Week")]}
                />
            </div>
            <div tw="overflow-x-hidden background-image[linear-gradient(#ebebeb, white)]">
                {visualRangeTabIndex === 0 && (
                    <div tw="bg-black">
                        <video src="https://demo.icuplus.com/virtualpublic/monitor.mov" autoPlay loop tw="w-full" />
                    </div>
                )}
                <ApiErrors />
                {visualRangeTabIndex > 0 && isLoading && (
                    <CircularProgress tw="position[absolute] top[45%] left[50%] z-10" />
                )}
                {visualRangeTabIndex > 0 &&
                    allData &&
                    allData.map((data, index) => (
                        <div tw="padding-bottom[15px] position[relative]" key={index}>
                            <div
                                tw="position[absolute] font-size[50px] font-weight[bold]"
                                style={{ transform: "scaleX(0.6)", color: data.color }}
                            >
                                <span>
                                    {parametersTabIndex === 0
                                        ? chartsData[index].name
                                        : chartsData[parametersTabIndex - 1].name}
                                </span>
                            </div>
                            <div tw="padding-top[5px]">
                                <Chart
                                    key={index}
                                    dataSource={data.dataSource}
                                    adjustOnZoom={false}
                                    onOptionChanged={handleOptionChange}
                                >
                                    <Size height={200} />
                                    <Animation enabled={false} />
                                    <Legend visible={false} />
                                    <ArgumentAxis
                                        argumentType="datetime"
                                        visualRange={ranges.visualRange}
                                        wholeRange={ranges.wholeRange}
                                    />
                                    <CommonSeriesSettings argumentField="date" selectionMode="none" hoverMode="none">
                                        <Border visible={false} />
                                    </CommonSeriesSettings>
                                    <Series
                                        valueField={"value"}
                                        name={data.name}
                                        axis={"value"}
                                        type={data.serieType}
                                        order={1}
                                        color={data.color}
                                        visible={true}
                                    >
                                        <Point visible={false} />
                                    </Series>
                                    <ValueAxis
                                        position="left"
                                        name={"value"}
                                        visualRangeUpdateMode="keep"
                                        customPosition={0}
                                        visible={false}
                                    >
                                        <Tick visible={false} />
                                        <Grid visible={true} />
                                        <Label visible={false} />
                                    </ValueAxis>
                                    <ZoomAndPan argumentAxis="both" />
                                    <Tooltip
                                        enabled={true}
                                        shared={false}
                                        zIndex={1000}
                                        cornerRadius={5}
                                        customizeTooltip={(x) => customizeTooltip(x, data.unit)}
                                    />
                                </Chart>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}
