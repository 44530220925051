import { Fragment, useEffect } from "react";
import ApiErrorsList from "@profilog/misc/errors/ApiErrorsList";
import { useDispatch } from "react-redux";
import ComboBox from "./ComboBox";

export default function CodebookComboBox({
    value,
    onChange,
    limit = 15,
    getOptionsHook,
    getOptionsArg,
    autocompleteProps,
    storeAsArray,
    className,
}) {
    console.log(storeAsArray);
    const useGetOptions = getOptionsHook;
    const { thunk, data: options, pending, apiErrors } = useGetOptions(getOptionsArg);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(thunk(getOptionsArg));
    }, []); // eslint-disable-line

    const props = { blurOnSelect: true, selectOnFocus: true };
    if (autocompleteProps) Object.assign(props, autocompleteProps);

    return (
        <Fragment>
            <ComboBox
                options={options}
                value={value}
                onChange={onChange}
                limit={limit}
                loading={pending}
                autocompleteProps={props}
                className={className}
                storeAsArray={storeAsArray}
            />
            <ApiErrorsList apiErrors={apiErrors} />
        </Fragment>
    );
}
