import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFetch } from "@profilog/misc/utils/useFetch";
import RegistrationWithInvitationCard from "../components/RegistrationWithInvitationCard";
import { Button, Divider, TextField } from "@mui/material";

export default function RegistrationWithInvitation() {
    const { t } = useTranslation();
    const { apiGet, ApiErrors: ApiGetInvitationError } = useFetch();
    const { invitationCodeParam } = useParams();
    const [invitationCode, setInvitationCode] = useState(invitationCodeParam);
    const [isCodeSubmitted, setIsCodeSubmitted] = useState(invitationCodeParam);
    const [invitation, setInvitation] = useState({});
    const [isGetInvitationError, setIsGetInvitationError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getInvitation() {
            if (isCodeSubmitted) {
                let response = await apiGet("/web/user/registration-invitation/" + invitationCode);
                if (response.isOk) {
                    setInvitation(response.json);
                } else {
                    setIsGetInvitationError(true);
                }
            }
            setIsLoading(false);
        }
        getInvitation();
        //eslint-disable-next-line
    }, [invitationCode, isCodeSubmitted]);

    function handleCodeChange(e) {
        setInvitationCode(e.target.value);
    }

    function handleEnterCodeAgainClicked() {
        setIsCodeSubmitted(false);
        setIsGetInvitationError(false);
    }

    function handleApplyCodeButtonClicked() {
        setIsCodeSubmitted(true);
        setIsLoading(true);
    }

    return (
        <div>
            <h2 tw="mb-3">{t("registration.NewRegistrationWithInvitationCode")}</h2>
            <p tw="mb-4">{t("registration.Welcome")}</p>
            <p>{t("registration.SomeoneSentYouInvitationText")}</p>
            <Divider tw="mb-4" />
            {!isLoading && (
                <Fragment>
                    <div tw="md:w-8/12 mx-auto flex flex-col gap-3 items-center">
                        {isGetInvitationError ? (
                            <Fragment>
                                <ApiGetInvitationError />
                                <Button variant="contained" onClick={handleEnterCodeAgainClicked}>
                                    Zadat kód znovu
                                </Button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                {!isCodeSubmitted && <p>{t("registration.EnterCodeText")}</p>}
                                <TextField
                                    name="invitation-code"
                                    inputProps={{ maxLength: 6, sx: { fontSize: 40, textAlign: "center" } }}
                                    disabled={isCodeSubmitted}
                                    defaultValue={invitationCode}
                                    onChange={handleCodeChange}
                                />
                                {!isCodeSubmitted && (
                                    <Button
                                        variant="contained"
                                        disabled={invitationCode?.length !== 6}
                                        onClick={handleApplyCodeButtonClicked}
                                        tw="mt-2"
                                    >
                                        {t("registration.ApplyCode")}
                                    </Button>
                                )}
                            </Fragment>
                        )}
                    </div>
                    {isCodeSubmitted && invitation && invitation.createdByUserName && (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t("registration.InvitationWasSentByText").replace(
                                    "{0}",
                                    invitation.createdByUserName,
                                ),
                            }}
                        ></p>
                    )}
                    {isCodeSubmitted && !isGetInvitationError && (
                        <RegistrationWithInvitationCard invitation={invitation} />
                    )}
                </Fragment>
            )}
        </div>
    );
}
