import { useEffect, useState } from "react";
import { useFetch } from "../../utils/useFetch";
import { getQuery } from "../../utils/Url";
import { useTranslation } from "react-i18next";
import { createApiUrl } from "../../utils/createApiUrl";

export default function useHuawei() {
    const { t } = useTranslation();
    const [huaweiConnectionInfo, setHuaweiConnectionInfo] = useState(null);
    const { apiPost, apiGet, ApiErrors } = useFetch();
    const [isConnecting, setIsConnecting] = useState(false);
    const [huaweiApiError, setHuaweiApiError] = useState(null);
    const query = getQuery();

    async function connect() {
        setIsConnecting(true);
        const returnUrl = window.location.href.split("?", 2)[0];
        const url = createApiUrl("/huawei/connect", { returnUrl: returnUrl });
        window.location = url;
    }

    async function disconnect() {
        const response = await apiPost("/huawei/disconnect");
        if (response.isOk) await getHuaweiStatus();
    }

    async function getHuaweiStatus() {
        const response = await apiGet("/huawei/get-state");
        if (response.isOk) {
            setHuaweiConnectionInfo(response.json);
        }
    }

    useEffect(() => {
        getHuaweiStatus();

        const huaweiErrorCode = query.get("huawei-error");
        if (huaweiErrorCode !== null) {
            switch (huaweiErrorCode) {
                case "Consent":
                    setHuaweiApiError(t("extConnectors.Error.GetConsent"));
                    break;
                default:
                    setHuaweiApiError(t("extConnectors.Error.UnknownError"));
                    break;
            }
        }
    }, []);

    return {
        huaweiConnectionInfo,
        connect,
        disconnect,
        isConnecting,
        ApiErrors,
        huaweiApiError,
    };
}
