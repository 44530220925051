import { format } from "date-fns";
import { weekDays, weekends, workweek } from "../codebooks/weekDays";
import { DATE_FORMAT } from "@profilog/misc/commons/dateDefaults";
import { prescriptionTypes } from "../codebooks/prescriptionTypes";

export function getIntervalText(interval, intervalUnit, t) {
    if (interval === null) return { text: t("prescriptions.Edit.oneTime"), isDefault: false };

    if (intervalUnit === "Days" && interval === 1) return { text: t("prescriptions.Edit.daily"), isDefault: true };

    const intervalText = interval > 1 ? interval : "";
    return {
        text:
            intervalUnit === "Days"
                ? t("prescriptions.Edit.everyXDays", { days: intervalText })
                : t("prescriptions.Edit.everyXHours", { hours: intervalText }),
        isDefault: false,
    };
}

export function getWeekDaysText(scheduleDays, emptyText = null, t) {
    if (scheduleDays === weekends) return { text: t("prescriptions.Edit.duringWeekend"), isSet: true };
    if (scheduleDays === workweek) return { text: t("prescriptions.Edit.duringWorkingDays"), isSet: true };

    let usedDays = [];
    for (let day in weekDays) {
        if (scheduleDays & day) usedDays.push(weekDays[day]);
    }

    return usedDays.length > 0
        ? { text: usedDays.map((d) => t("prescriptions.WeekDays." + d)).join(", "), isSet: true }
        : { text: emptyText, isSet: false };
}

export function getDurationText(dateFrom, days, dateTo, t, prescriptionType) {
    const dateFromString = dateFrom != null ? format(dateFrom, DATE_FORMAT) : null;
    const dateToString = dateTo != null ? format(dateTo, DATE_FORMAT) : null;

    const firstApplicationText =
        prescriptionType === prescriptionTypes.survey
            ? t("prescriptions.Edit.FirstFilling")
            : t("prescriptions.Edit.FirstApplication");

    let result = t("prescriptions.Edit.from") + " " + (dateFromString ? dateFromString : firstApplicationText);

    if (dateToString) result += " " + t("prescriptions.Edit.to") + " " + dateToString;

    if (days) result += " " + days + " " + t("prescriptions.Edit.days");
    return { isDefault: dateFrom === null && dateTo === null && days === null, text: result };
}
