import { Fragment } from "react";
import { useState, useEffect } from "react";
import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    CommonSeriesSettings,
    Legend,
    Tooltip,
    Label,
    Tick,
    Format,
    Size,
    Font,
    Animation,
    TickInterval,
    Border,
} from "devextreme-react/chart";
import { useTranslation } from "react-i18next";
import { format, intervalToDuration } from "date-fns";
import { useFetch } from "../../utils/useFetch";
import { CircularProgress } from "@mui/material";

export default function RangeSleepChart({ medicalSubjectId, dateFrom, dateTo }) {
    const { t, i18n } = useTranslation();
    const { apiPost } = useFetch();
    const [sleepData, setSleepData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function loadData() {
            setIsLoading(true);
            setSleepData();
            let from = new Date(dateFrom);
            from.setHours(12, 0, 0, 0);

            let to = new Date(dateTo);
            to.setHours(12, 0, 0, 0);

            let parametresDto = [{ recordTypeName: "SleepSegment" }];

            let dto = {
                medicalSubjectId: medicalSubjectId,
                utcFrom: from.toISOString(),
                utcTo: to.toISOString(),
                recordFilters: parametresDto,
                ignorePagination: true,
            };
            let response = await apiPost("/v2/medical-subjects/records/get-raw", dto);
            if (response.isOk) setSleepData(transformData(response.json));
            else setSleepData();
            setIsLoading(false);
        }
        loadData();
    }, [dateFrom, dateTo]);

    function transformData(data) {
        let result = [];
        //hodnoty enumu, podle pořadí v grafu
        let values = [5, 4, 6, 1];

        let days = intervalToDuration({
            start: dateFrom,
            end: dateTo,
        }).days;

        let isAnyValue = false;

        for (let day = 0; day < days; day++) {
            let actualDay = new Date(dateFrom);
            actualDay.setDate(actualDay.getDate() + day);

            let from = new Date(actualDay);
            from.setHours(12, 0, 0, 0);

            let to = new Date(actualDay);
            to.setHours(12, 0, 0, 0);
            to.setDate(to.getDate() + 1);

            let segmentSum = [];

            data.records
                .filter(
                    (record) =>
                        new Date(record.utcStartAt).getTime() >= from.getTime() &&
                        new Date(record.utcEndAt).getTime() <= to.getTime(),
                )
                .forEach((record) => {
                    let duration = intervalToDuration({
                        start: new Date(record.utcStartAt),
                        end: new Date(record.utcEndAt),
                    });

                    let minutes = duration.days * 24 * 60 + duration.hours * 60 + duration.minutes;
                    let value = record.fields[0].value;

                    let sum = segmentSum[value] ?? 0;
                    segmentSum[value] = sum + minutes;
                });

            let record = { date: actualDay };
            if (segmentSum.length === 0) {
                for (let index = 1; index <= 4; index++) {
                    record[index] = 0;
                }
            } else {
                Object.keys(segmentSum).forEach((key) => {
                    let valueIndex = values.indexOf(parseInt(key));
                    if (valueIndex >= 0) record[valueIndex + 1] = segmentSum[key];
                });

                isAnyValue = true;
            }
            result.push(record);
        }

        return isAnyValue ? result : [];
    }

    function argumentAxisCustomizeLabelText(info) {
        let str = info.valueText.split(" ");
        return str.shift().toUpperCase() + "\n" + str.join(" ");
    }

    function valueAxisCustomizeLabelText(info) {
        return info.value / 60 + "h";
    }

    function getTime(totalMinutes) {
        return `${Math.floor(totalMinutes / 60)}h ${totalMinutes % 60}m`;
    }

    function customizeTooltip(pointInfo) {
        let items = [];

        pointInfo.points.forEach((point) => {
            let text = `${point.seriesName}: ${getTime(point.value)}`;
            let element = document.createElement("span");
            element.textContent = text;
            element.style.color = point.point.getColor();
            text = element.outerHTML;
            items.push(text);
        });

        let text = `Celkem: ${getTime(pointInfo.total)}`;
        let element = document.createElement("span");
        element.textContent = text;
        text = element.outerHTML;
        items.push(text);

        let dateElement = document.createElement("span");
        dateElement.style.paddingTop = 5;
        dateElement.textContent = format(pointInfo.argument, "dd.MM.yyyy");
        items.push(dateElement.outerHTML);

        return { text: items.join("\n") };
    }

    return (
        <Fragment>
            <div tw="w-full">
                {isLoading && (
                    <div tw="flex justify-center">
                        <CircularProgress />
                    </div>
                )}
                {sleepData && sleepData.length > 0 && (
                    <div tw="px-1.5 pb-2.5">
                        <div tw="pt-6">
                            <div tw="my-1.5 flex-1 text-center">Spánek</div>
                        </div>
                        <Chart dataSource={sleepData}>
                            <Size height={250} />
                            <Animation enabled={false} />
                            <Legend
                                visible={false}
                                verticalAlignment="top"
                                horizontalAlignment="right"
                                hoverMode="none"
                            />
                            <ArgumentAxis argumentType="date">
                                <TickInterval days={1} />
                                <Label
                                    wordWrap="none"
                                    visible={true}
                                    overlappingBehavior="none"
                                    customizeText={argumentAxisCustomizeLabelText}
                                >
                                    <Font family="Montserrat, sans-serif" size={10} />
                                    <Format
                                        formatter={(value) =>
                                            value.toLocaleDateString(i18n.language, {
                                                month: "short",
                                                day: "numeric",
                                                weekday: "short",
                                            })
                                        }
                                    />
                                </Label>
                                <Tick visible={false} />
                            </ArgumentAxis>
                            <CommonSeriesSettings
                                argumentField="date"
                                type="stackedBar"
                                selectionMode="none"
                                hoverMode="none"
                            >
                                <Border visible={false} />
                            </CommonSeriesSettings>
                            <Series valueField="1" name="Hluboký" color="#004BA0" opacity="1" />
                            <Series valueField="2" name="Lehký" color="#1976D2" opacity="1" />
                            <Series valueField="3" name="REM" color="#AC06BC" opacity="1" />
                            <Series valueField="4" name="Vzhůru" color="#ED79D5" opacity="1" />
                            <ValueAxis visible={false} tickInterval="120">
                                <Tick visible={false} />
                                <Label visible={true} customizeText={valueAxisCustomizeLabelText} indentFromAxis={2} />
                            </ValueAxis>
                            <Tooltip
                                enabled={true}
                                shared={true}
                                zIndex={100000000}
                                cornerRadius={5}
                                customizeTooltip={customizeTooltip}
                            />
                        </Chart>
                    </div>
                )}
            </div>
        </Fragment>
    );
}
