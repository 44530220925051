import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";

export default function CloseNewMessageDialog({ open, setOpen, onConfirmClick }) {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Dialog open={open} onClose={handleClose}>
                <DialogCloseButton onClick={handleClose} />
                <DialogTitle tw="pr-8">
                    {t("shCommunications.Messages.CloseNewMessageDialog.CloseWindowWarning")}
                </DialogTitle>

                <DialogContent>
                    <p>{t("shCommunications.Messages.CloseNewMessageDialog.ThrowAwayTheConcept")}</p>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="success" onClick={() => handleConfirm()} tw="normal-case">
                        {t("shCommunications.Messages.CloseNewMessageDialog.Yes")}
                    </Button>

                    <Button variant="contained" color="error" onClick={handleClose} tw="normal-case">
                        {t("shCommunications.Messages.CloseNewMessageDialog.No")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );

    function handleConfirm() {
        handleClose();
        onConfirmClick();
    }

    function handleClose() {
        if (setOpen) setOpen(false);
    }
}
