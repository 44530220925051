import { useState, useCallback, Fragment } from "react";
import RecordEdit from "../components/RecordEdit";
import RecordsVisualization from "@profilog/misc/records/RecordsVisualization";
import AppSettings from "../../app/AppSettings";
import { useMedicalSubjectFetch } from "@profilog/misc/records/fetch/useMedicalSubjectFetch";
import ExternalServicesStatusDialog from "../../externalServices/components/ExternalServicesStatusDialog";

export default function Records({
    Layout,
    renderBellowParams,
    renderOnSaved,
    DrugCalendar,
    SurveyCalendar,
    hideGrid = false,
    hideSavedText = false,
    allowEmptySave = false,
    moreParameters = false,
}) {
    const [version, setVersion] = useState(0);
    const [saved, setSaved] = useState(false);
    const [selectedMedicalSubjectId, setSelectedMedicalSubjectId] = useState(AppSettings.user.userMedicalSubjectId);
    const fetchService = useMedicalSubjectFetch(
        selectedMedicalSubjectId,
        AppSettings.isPatientPreview,
        AppSettings.profilogUserId,
        AppSettings.profilogUserPersonFullName,
        AppSettings.profilogUserEnergyType,
        version,
    );

    const handleSaveCallback = useCallback(() => {
        setVersion(new Date().getTime());
        setSaved(true);
    }, []); // eslint-disable-line
    const handleSelectedMedicalSubjectChangedCallback = useCallback((medicalSubjectId) => {
        setSelectedMedicalSubjectId(medicalSubjectId);
    }, []);

    return (
        <Fragment>
            <ExternalServicesStatusDialog />
            <Layout
                saved={saved}
                version={version}
                recordEdit={() => (
                    <Fragment>
                        <RecordEdit
                            selectedMedicalSubjectId={selectedMedicalSubjectId}
                            setSelectedMedicalSubjectId={setSelectedMedicalSubjectId}
                            onNewRecordClick={() => setSaved(false)}
                            onSaved={handleSaveCallback}
                            onSelectedMedicalSubjectChanged={handleSelectedMedicalSubjectChangedCallback}
                            disableParameterChange={false}
                            isVocallsEnabled={AppSettings.isDevOrTest}
                            renderBellowParams={renderBellowParams}
                            hideSavedText={hideSavedText}
                            allowEmptySave={allowEmptySave}
                        />
                        {saved && renderOnSaved && renderOnSaved()}
                    </Fragment>
                )}
                visualization={() => (
                    <RecordsVisualization
                        fetchService={fetchService}
                        isGraphAlwaysVisible={false}
                        project={AppSettings.project}
                        isUnivTemplate={AppSettings.isUnivTemplate}
                        isForMM={AppSettings.isForMM}
                        hideGrid={hideGrid}
                        hideMessages={!AppSettings.profilogPd}
                        showActivities={AppSettings.isHh}
                        moreParameters={moreParameters}
                    />
                )}
                drugCalendar={() =>
                    AppSettings.arePrescriptionsEnabled &&
                    DrugCalendar && (
                        <DrugCalendar
                            medicalSubjectId={selectedMedicalSubjectId}
                            shortList
                            showGroupName={AppSettings.isPatientPreview}
                        />
                    )
                }
                surveyCalendar={(CalendarLayout) =>
                    AppSettings.arePrescriptionsEnabled &&
                    SurveyCalendar && (
                        <SurveyCalendar medicalSubjectId={selectedMedicalSubjectId} Layout={CalendarLayout} />
                    )
                }
            />
        </Fragment>
    );
}
