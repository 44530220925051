import tw from "twin.macro";

export default function Stack(props) {
	return (
		<div
			className={props.className}
			tw="flex gap-x-2 gap-y-3"
			css={[
				props.vertical
					? props.reverse
						? tw`flex-col-reverse`
						: tw`flex-col`
					: props.reverse
					? tw`flex-row-reverse`
					: tw`flex-row`,
			]}
		>
			{props.children}
		</div>
	);
}
