import { useState, useEffect, Fragment } from "react";
import RecordsGridView from "./RecordsGridView";
import { useTranslation } from "react-i18next";
import DatePicker from "../../commons/components/DatePicker";
import { Button, IconButton, TextField } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useRef } from "react";
import AppSettings from "@profilog/front/app/AppSettings";

export default function RecordsGrid({ fetchService }) {
    const { t, i18n } = useTranslation();
    const [userData, setUserData] = useState();
    const [isGridVisible, setIsGridVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState();

    const { parametersData, lastRecordDate, getUserDataForGridAsync, GetApiErrors } = fetchService;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const [dataDates, setDataDates] = useState(getDateDates(getDatePlusDays(today, -2), today));
    const tableRef = useRef(null);

    const getUserData = async () => {
        let result = await getUserDataForGridAsync(dataDates, null, null);
        if (result.isOk) {
            setUserData(result.json);
            setIsLoading(false);
            setIsDataLoaded(true);
            if (tableRef) tableRef.current.scrollIntoView();
        }
    };

    useEffect(() => {
        if (!isGridVisible || isDataLoaded) return;
        setIsLoading(true);
        getUserData();
    }, [isGridVisible, isDataLoaded]);

    useEffect(() => {
        setIsDataLoaded(false);
    }, [dataDates]);

    function getDateDates(dateFrom, dateTo) {
        return { dateFrom: dateFrom, dateTo: dateTo };
    }

    function getDatePlusDays(date, days) {
        var newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    }

    function onDataToChange(date) {
        setDataDates({ dateFrom: dataDates.dateFrom, dateTo: date });
    }

    function onDataFromChange(date) {
        setDataDates({ dateFrom: date, dateTo: dataDates.dateTo });
    }

    if (isLoading) return null;

    return (
        <div tw="pb-4">
            <GetApiErrors />

            <div tw="text-center">
                <Button
                    onClick={() => {
                        const willBeVisible = !isGridVisible;
                        setIsGridVisible(willBeVisible);
                        if (!willBeVisible) {
                            setUserData(null);
                            setIsDataLoaded(false);
                        }
                    }}
                >
                    {t("recordsVisualization.TableView")}
                </Button>
            </div>
            {isGridVisible && userData && (
                <Fragment>
                    <div ref={tableRef} tw="mt-2 flex justify-center items-center gap-2">
                        <div>
                            <DatePicker
                                selected={dataDates.dateFrom}
                                onChange={(date) => onDataFromChange(date)}
                                customInput={
                                    <TextField
                                        size="small"
                                        tw="width[13ch]"
                                        inputProps={{
                                            readOnly: AppSettings.isMobileApp,
                                        }}
                                    />
                                }
                            />
                        </div>
                        <div>-</div>
                        <div>
                            <DatePicker
                                selected={dataDates.dateTo}
                                onChange={(date) => onDataToChange(date)}
                                customInput={
                                    <TextField
                                        size="small"
                                        tw="width[13ch]"
                                        inputProps={{
                                            readOnly: AppSettings.isMobileApp,
                                        }}
                                    />
                                }
                            />
                        </div>
                        <div>
                            <IconButton size="small" onClick={() => setIsDataLoaded(false)}>
                                <RefreshIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div tw="mt-2 bg-white rounded-md shadow-updown p-4">
                        {userData.recordValues.length > 0 ? (
                            <RecordsGridView userData={userData} parametersData={parametersData} />
                        ) : (
                            <p>
                                <em>{t("recordsVisualization.NoRecordsFound")}</em>
                            </p>
                        )}
                    </div>
                </Fragment>
            )}
        </div>
    );
}
