import { useSelector } from "react-redux";
import WeekDaysPicker from "./WeekDaysPicker";
import { getWeekDaysText } from "../../../utils/dates";
import { useTranslation } from "react-i18next";

export default function WeekDays({
    dispatchUpdate,
    selector,
    sameSelector = (state) => true,
    editing,
    saved,
    wasOpen,
}) {
    const { t } = useTranslation();
    const weekDays = useSelector(selector);
    const same = useSelector(sameSelector);

    return editing ? (
        <WeekDaysPicker value={weekDays} onChange={(newValue) => dispatchUpdate({ weekDays: newValue })} />
    ) : (
        getWeekDays()
    );

    function getWeekDays() {
        if (!same) return <strong>{t("prescriptions.Edit.WeekDaysDifference")}</strong>;

        const { text, isSet } = getWeekDaysText(weekDays, t("prescriptions.Edit.WeekDaysAll"), t);
        if (!saved && !isSet && !wasOpen) return t("prescriptions.Edit.pickWeekDays");

        return isSet ? <strong>{text}</strong> : text;
    }
}
