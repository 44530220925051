import { createApiGetSlice } from "./toolkit";
import { mapIdNameToOptions } from "./utils";

// Actions
const actionsApiCallSlice = createApiGetSlice("actions", "/web/prescriptions/codebooks/actions", mapIdNameToOptions);
export const useGetActions = actionsApiCallSlice.hook;

// Situations
const situationsApiCallSlice = createApiGetSlice(
    "situations",
    "/web/prescriptions/codebooks/situations",
    mapIdNameToOptions,
);
export const useGetSituations = situationsApiCallSlice.hook;

// Drugs
const drugsApiCallSlice = createApiGetSlice("drugs", "/web/prescriptions/codebooks/drugs", mapIdNameToOptions);
export const useGetDrugs = drugsApiCallSlice.hook;

// Parameters
const parametersApiCallSlice = createApiGetSlice(
    "parameters",
    "/web/prescriptions/codebooks/parameters",
    mapIdNameToOptions,
);
export const useGetParameters = parametersApiCallSlice.hook;

// Surveys
const surveysApiCallSlice = createApiGetSlice(
    "surveys",
    (arg) => {
        let url = "/web/prescriptions/codebooks/surveys";
        if (arg && arg.groupId) url += "?" + new URLSearchParams({ groupId: arg.groupId });
        return url;
    },
    mapIdNameToOptions,
);
export const useGetSurveys = surveysApiCallSlice.hook;

// Reducers
const codebookReducers = {
    actions: actionsApiCallSlice.reducer,
    situations: situationsApiCallSlice.reducer,
    drugs: drugsApiCallSlice.reducer,
    parameters: parametersApiCallSlice.reducer,
    surveys: surveysApiCallSlice.reducer,
};
export default codebookReducers;
