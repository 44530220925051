import { useDispatch, useSelector } from "react-redux";
import { prescriptionUpdate } from "../../redux/prescriptionSlice";
import Prescription from "./Prescription";
import { useTranslation } from "react-i18next";
import DrugComboBox from "../codebooks/DrugComboBox";

export default function DrugPrescription({ prescriptionId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const drug = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].drug);

    return (
        <Prescription prescriptionId={prescriptionId} title={t("prescriptions.Types.Drug")}>
            <DrugComboBox
                value={drug}
                onChange={dispatchOptionChange}
                autocompleteProps={{ autoFocus: true }}
                tw="w-full max-width[16rem] sm:(w-64 max-w-none)"
            />
        </Prescription>
    );

    function dispatchOptionChange(option) {
        dispatchPrescriptionUpdate({ drug: option });
    }

    function dispatchPrescriptionUpdate(updated) {
        dispatch(prescriptionUpdate({ prescriptionId, updated }));
    }
}
