import { Fragment } from "react";
import { useState, useEffect } from "react";
import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    CommonSeriesSettings,
    Legend,
    Tooltip,
    Label,
    Tick,
    Size,
    Font,
    Format,
    Animation,
    Point,
    TickInterval,
    VisualRange,
} from "devextreme-react/chart";
import { useTranslation } from "react-i18next";
import { format, intervalToDuration } from "date-fns";
import { useFetch } from "../../utils/useFetch";
import { CircularProgress } from "@mui/material";

export default function DailyStresAndBodyBatteryChart({ medicalSubjectId, date }) {
    const { t, i18n } = useTranslation();
    const { apiPost } = useFetch();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const dateTo = new Date(date);
    dateTo.setDate(dateTo.getDate() + 1);

    useEffect(() => {
        async function loadData() {
            setIsLoading(true);
            setData();

            Promise.all([getStres(), getBodyBattery()]).then((values) => {
                let result = [];

                let prevRecord = null;
                values[0].forEach((record) => {
                    let value = record.fields[0].value;
                    if (value !== -1) {
                        let dateFrom = new Date(record.utcStartAt);
                        let dateTo = new Date(record.utcEndAt);

                        let start = { date: dateFrom };
                        let end = { date: dateTo };
                        if (value < 0) {
                            start.active = value;
                            end.active = value;
                            start.rest = null;
                            end.rest = null;
                            start.stres = null;
                            end.stres = null;
                        } else if (value <= 25) {
                            start.rest = value;
                            end.rest = value;
                            start.active = null;
                            end.active = null;
                            start.stres = null;
                            end.stres = null;
                        } else {
                            start.stres = value;
                            end.stres = value;
                            start.rest = null;
                            end.rest = null;
                            start.active = null;
                            end.active = null;
                        }

                        result.push(start, end);
                    }

                    if (prevRecord !== null) {
                        if (prevRecord.fields[0].value !== -1 && value === -1) {
                            let date = new Date(prevRecord.utcEndAt);
                            date.setMinutes(date.getMinutes() + 1);
                            let end2 = { date: date };
                            end2.stres = null;
                            end2.rest = null;
                            end2.active = null;
                            result.push(end2);
                        }
                    }

                    prevRecord = record;
                });

                prevRecord = null;
                values[1].forEach((record) => {
                    result.push({ date: new Date(record.utcStartAt), bodyBattery: record.fields[0].value });
                    if (prevRecord !== null) {
                        if (new Date(prevRecord.utcEndAt).getTime() !== new Date(record.utcStartAt).getTime()) {
                            let date = new Date(prevRecord.utcEndAt);
                            date.setMinutes(date.getMinutes() + 1);
                            result.push({ date: date, bodyBattery: null });
                        }
                    }

                    prevRecord = record;
                });
                setData(result);
            });

            setIsLoading(false);
        }
        loadData();
    }, [date]);

    async function getStres() {
        let parametresDto = [{ recordTypeName: "StressLevel" }];

        let dto = {
            medicalSubjectId: medicalSubjectId,
            utcFrom: date.toISOString(),
            utcTo: dateTo.toISOString(),
            recordFilters: parametresDto,
            ignorePagination: true,
        };
        let response = await apiPost("/v2/medical-subjects/records/get-raw", dto);
        if (response.isOk) return sort(response.json.records);
        return [];
    }

    async function getBodyBattery() {
        let parametresDto = [{ recordTypeName: "BodyBattery" }];

        let dto = {
            medicalSubjectId: medicalSubjectId,
            utcFrom: date.toISOString(),
            utcTo: dateTo.toISOString(),
            recordFilters: parametresDto,
            ignorePagination: true,
        };
        let response = await apiPost("/v2/medical-subjects/records/get-raw", dto);
        if (response.isOk) return sort(response.json.records);
        return [];
    }

    function sort(data) {
        data.sort((a, b) => {
            let aDateFrom = new Date(a.utcStartAt);
            let bDateFrom = new Date(b.utcStartAt);

            if (aDateFrom < bDateFrom) return -1;
            if (aDateFrom > bDateFrom) return 1;

            let aDateTo = new Date(a.utcEndAt);
            let bDateTo = new Date(b.utcEndAt);

            if (aDateTo < bDateTo) return -1;
            if (aDateTo > bDateTo) return 1;
            return 0;
        });
        return data;
    }

    function argumentAxisCustomizeLabelText(info) {
        if (info.max.getTime() === info.value.getTime()) return "";
        let hour = info.value.getHours();

        if (hour === 0 || hour % 2 === 0) return info.valueText;
        else return "";
    }

    function getTime(totalMinutes) {
        return `${Math.floor(totalMinutes / 60)}h ${totalMinutes % 60}m`;
    }

    function customizeTooltip(pointInfo) {
        let items = [];

        let element = document.createElement("span");
        if (pointInfo.value < 0) element.textContent = `${pointInfo.seriesName}`;
        else element.textContent = `${pointInfo.seriesName}: ${pointInfo.value}`;
        element.style.color = pointInfo.point.getColor();
        items.push(element.outerHTML);

        return { text: items.join("\n") };
    }

    return (
        <Fragment>
            <div tw="w-full">
                {isLoading && (
                    <div tw="flex justify-center">
                        <CircularProgress />
                    </div>
                )}
                {data && data.length > 0 && (
                    <div tw="px-1.5 pb-2.5">
                        <div tw="pt-6 pb-4">
                            <div tw="my-1.5 flex-1 text-center">Stres & Body Battery</div>
                        </div>
                        <Chart dataSource={data}>
                            <Size height={350} />
                            <Animation enabled={false} />
                            <Legend visible={true} verticalAlignment="bottom" horizontalAlignment="center" />
                            <ArgumentAxis argumentType="datetime" visible={false}>
                                <TickInterval minutes={30} />
                                <Label
                                    wordWrap="none"
                                    visible={true}
                                    //overlappingBehavior="none"
                                    customizeText={argumentAxisCustomizeLabelText}
                                >
                                    <Font family="Montserrat, sans-serif" size={10} />
                                    <Format
                                        formatter={(value) =>
                                            value.toLocaleTimeString(i18n.language, {
                                                hour: "numeric",
                                            })
                                        }
                                    />
                                </Label>
                                <Tick visible={false} />
                                <VisualRange startValue={date} endValue={dateTo} />
                            </ArgumentAxis>
                            <CommonSeriesSettings
                                argumentField="date"
                                type="area"
                                barPadding="1"
                                // selectionMode="none"
                                // hoverMode="none"
                            >
                                {/* <Border visible={false} /> */}
                            </CommonSeriesSettings>
                            <Series valueField="rest" name="Odpočinek" color="#2A88E6" opacity="1" />
                            <Series valueField="stres" name="Stres" color="#DE5909" opacity="1" />
                            <Series valueField="active" name="Aktivní" color="#6C6C6C" opacity="1" />
                            {/* <Series valueField="nodata" name="Neměřitelné" color="white" opacity="1" /> */}
                            <Series
                                type="line"
                                valueField="bodyBattery"
                                name="Body battery"
                                color="green"
                                hoverMode="none"
                            >
                                <Point size={1} />
                            </Series>
                            <ValueAxis visible={false} tickInterval={25}>
                                <Tick visible={false} />
                                <Label visible={true} indentFromAxis={2} />
                                <VisualRange startValue={-1} endValue={100} />
                            </ValueAxis>
                            <Tooltip
                                enabled={true}
                                shared={true}
                                zIndex={100000000}
                                cornerRadius={5}
                                customizeTooltip={customizeTooltip}
                            />
                        </Chart>
                    </div>
                )}
            </div>
        </Fragment>
    );
}
