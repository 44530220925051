import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../utils/useFetch";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";
import { useAuth } from "@profilog/misc/auth/useAuth";

export default function DeleteAccount() {
    const { t } = useTranslation();
    const { apiPost, ApiErrors } = useFetch();
    const { logout } = useAuth();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <p dangerouslySetInnerHTML={{ __html: t("userSettings.DeleteAccount.Warning") }} />

            <Button variant="contained" onClick={() => setOpen(true)}>
                Chci zrušit účet
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogCloseButton onClick={handleClose} />
                <DialogTitle>{t("userSettings.DeleteAccount.Title")}</DialogTitle>
                <DialogContent>
                    {t("userSettings.DeleteAccount.Question")}
                    <ApiErrors />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={deleteAccount} disabled={isButtonDisabled}>
                        {t("userSettings.DeleteAccount.Yes")}
                    </Button>
                    <Button variant="contained" color="success" onClick={handleClose}>
                        {t("userSettings.DeleteAccount.No")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );

    function handleClose() {
        setOpen(false);
    }

    async function deleteAccount() {
        setIsButtonDisabled(true);

        const response = await apiPost("/web/user/delete-account");
        if (response.isOk) {
            logout();
            return;
        }

        setIsButtonDisabled(false);
    }
}
