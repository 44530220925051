import { MenuItem, TextField, Menu } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { scheduleTypes } from "../../codebooks/scheduleTypes";
import { verifyFastTimeSchedule } from "../../redux/fastTimeSchedule";
import { prescriptionUpdate } from "../../redux/prescriptionSlice";
import ParamValueSchedule from "../schedules/ParamValueSchedule";
import Amount from "../schedules/scheduleParams/Amount";
import SituationSchedule from "../schedules/SituationSchedule";
import "twin.macro";
import LazyTippy from "@profilog/misc/commons/components/LazyTippy";
import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import InlineEditButton from "../commons/InlineEditButton";

export function FastScheduleSettings({ prescriptionId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const scheduleType = useSelector((state) => state.prescriptions.prescriptions.byId[prescriptionId].scheduleType);
    const singleScheduleId = useSelector((state) => {
        const schedules = state.prescriptions.prescriptions.byId[prescriptionId].schedules;
        return scheduleType !== scheduleTypes.time && schedules.length === 1 ? schedules[0] : null;
    });

    const forceScheduleList = useSelector(
        (state) => state.prescriptions.prescriptions.byId[prescriptionId].forceScheduleList,
    );

    return (
        <Fragment>
            <InlineEditButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                {t("prescriptions.Schedules.By" + scheduleType)}
            </InlineEditButton>
            <Menu open={menuAnchorEl ? true : false} anchorEl={menuAnchorEl} onClose={handleMenuClose}>
                {Object.values(scheduleTypes).map((st) => (
                    <MenuItem key={st} onClick={() => changeScheduleType(st)}>
                        {t("prescriptions.Schedules.By" + st)}
                    </MenuItem>
                ))}
            </Menu>

            {scheduleType === scheduleTypes.time && !forceScheduleList && (
                <FastTimeSchedule prescriptionId={prescriptionId} />
            )}

            {singleScheduleId && !forceScheduleList && <SingleSchedule scheduleId={singleScheduleId} />}
        </Fragment>
    );

    function changeScheduleType(scheduleType) {
        dispatchPrescriptionUpdate({ scheduleType });
        handleMenuClose();
    }

    function dispatchPrescriptionUpdate(updated) {
        dispatch(prescriptionUpdate({ prescriptionId, updated }));
    }

    function handleMenuClose() {
        setMenuAnchorEl(null);
    }
}

function FastTimeSchedule({ prescriptionId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const fastTimeSchedule = useSelector(
        (state) => state.prescriptions.prescriptions.byId[prescriptionId].fastTimeSchedule,
    );
    const fastTimeScheduleSame = useSelector(
        (state) => state.prescriptions.prescriptions.byId[prescriptionId].fastTimeScheduleSame,
    );

    return (
        <div>
            <LazyTippy theme="light" content={<FastTimeScheduleHelp />}>
                <TextField
                    size="small"
                    tw="w-24"
                    label={t("prescriptions.Edit.Prescription")}
                    InputLabelProps={{ shrink: true }}
                    value={fastTimeScheduleSame ? fastTimeSchedule ?? "" : ""}
                    placeholder={fastTimeScheduleSame ? "_-_-_" : t("prescriptions.Edit.Other")}
                    onChange={dispatchUpdate}
                    onBlur={dispatchUpdate}
                />
            </LazyTippy>
        </div>
    );

    function dispatchUpdate(e) {
        dispatchPrescriptionUpdate({ fastTimeSchedule: verifyFastTimeSchedule(e.target.value, e.type === "change") });
    }

    function dispatchPrescriptionUpdate(updated) {
        dispatch(prescriptionUpdate({ prescriptionId, updated }));
    }
}

function SingleSchedule({ scheduleId }) {
    const supportsAmount = useSelector((state) => state.prescriptions.schedules.byId[scheduleId].supportsAmount);
    const type = useSelector((state) => state.prescriptions.schedules.byId[scheduleId].$type);
    return (
        <Fragment>
            {type === scheduleTypes.situation && <SituationSchedule scheduleId={scheduleId} />}
            {type === scheduleTypes.paramValue && <ParamValueSchedule scheduleId={scheduleId} />}
            {supportsAmount && <Amount scheduleId={scheduleId} />}
        </Fragment>
    );
}

function FastTimeScheduleHelp() {
    const { t } = useTranslation();
    return <div dangerouslySetInnerHTML={{ __html: t("prescriptions.Edit.FastTimeScheduleHelp") }} />;
}
