import ChartPointRecordsGrid from "./components/ChartPointRecordsGrid";
import { format } from "date-fns";
import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { Fragment, useState } from "react";
import DialogCloseButton from "@profilog/commons/components/DialogCloseButton";
import { useRef } from "react";

export default function ChartPointRecordsGridDialog({
    trigger,
    displayOpen,
    parameterName,
    medicalSubjectId,
    dateFrom,
    dateTo,
    parameterSystemName,
    onCloseClicked,
    source = null,
}) {
    const [open, setOpen] = useState(displayOpen ?? false);
    const isAnyChange = useRef(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    function getDateTimeFormat(date) {
        if (date.getHours() + date.getMinutes() + date.getSeconds() === 0) return "dd. MM. yyyy";
        else return "dd. MM. yyyy HH:mm";
    }

    return (
        <Fragment>
            {trigger(() => setOpen(true))}
            <Dialog open={open} onClose={handleClose} fullWidth fullScreen={fullScreen}>
                <DialogTitle>{`${parameterName} ${format(dateFrom, getDateTimeFormat(dateFrom))} - ${format(
                    dateTo,
                    getDateTimeFormat(dateTo),
                )}`}</DialogTitle>
                <DialogCloseButton onClick={handleClose} />
                <DialogContent>
                    <div>
                        <ChartPointRecordsGrid
                            parameterName={parameterName}
                            medicalSubjectId={medicalSubjectId}
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            parameterSystemName={parameterSystemName}
                            source={source}
                            onDeleteItem={() => (isAnyChange.current = true)}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </Fragment>
    );

    function handleClose() {
        setOpen(false);
        if (onCloseClicked) onCloseClicked(isAnyChange.current);
    }
}
