import { Fragment, ReactNode } from "react";
import CeImg from "./ce.png";
import ProfilogLogoImg from "./profilog-logo.png";

export default function ProfilogCe({ app, className, linkCss }: { app: string; className: string; linkCss: any }) {
    return (
        <div className={className} tw="space-y-3">
            <Row
                imgSrc={ProfilogLogoImg}
                imgAlt="Profilog"
                imgWidth={40}
                content={
                    <Fragment>
                        {app} je postaven na platformě{" "}
                        <a
                            href="https://profi-log.net"
                            css={linkCss}
                            tw="whitespace-nowrap"
                            target="_blank"
                            rel="noreferrer"
                        >
                            PROFI-LOG
                        </a>
                    </Fragment>
                }
            />
            <Row
                imgSrc={CeImg}
                imgAlt="CE logo"
                imgWidth={25}
                content="Platforma PROFI-LOG je zdravotnický prostředek s označením CE"
            />
        </div>
    );
}

function Row({
    imgSrc,
    imgAlt,
    imgWidth,
    content,
}: {
    imgSrc: string;
    imgAlt: string;
    imgWidth: number;
    content: ReactNode;
}) {
    return (
        <div tw="flex space-x-2 items-center">
            <div tw="flex[0 0 40px]">
                <img src={imgSrc} alt={imgAlt} width={imgWidth} tw="mx-auto" />
            </div>
            <div tw="text-xs">{content}</div>
        </div>
    );
}
