import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function MedilogAboutUs() {
    const { t } = useTranslation();

    return (
        <Fragment>
            <h2 className="text-primary">{t("aboutUs.AboutUs.Header")}</h2>
            <div dangerouslySetInnerHTML={{ __html: t("aboutUs.AboutUs.Desc") }} />

            <h3 className="text-primary">{t("aboutUs.AboutMW.Header")}</h3>
            <div dangerouslySetInnerHTML={{ __html: t("aboutUs.AboutMW.Desc") }} />

            <h3 className="text-primary">{t("aboutUs.Idea.Header")}</h3>
            <div dangerouslySetInnerHTML={{ __html: t("aboutUs.Idea.Desc") }} />

            <h3 className="text-primary">{t("aboutUs.HowSafeIsIt.Header")}</h3>
            <div dangerouslySetInnerHTML={{ __html: t("aboutUs.HowSafeIsIt.Desc") }} />

            <div dangerouslySetInnerHTML={{ __html: t("aboutUs.Thanks") }} />
        </Fragment>
    );
}
