import { Environment } from "../Environment";
import React, { Fragment } from "react";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ ...this.state, error, errorInfo });
    }

    render() {
        if (this.state.error) {
            return (
                <div tw="container mx-auto p-8 bg-white">
                    <h1 tw="text-4xl text-center text-red-600 mb-4">An unhandled error has occurred</h1>
                    {Environment.isProd && <div>We apologize for the unavailability of the application</div>}
                    <div>
                        <Fragment>
                            <pre tw="bg-red-100 p-4 rounded-2xl shadow-lg">{this.state.error.stack}</pre>
                            {this.state.errorInfo && (
                                <pre tw="bg-yellow-100 p-4 mt-4 rounded-2xl shadow-lg">
                                    errorInfo stack: {this.state.errorInfo.componentStack.toString()}
                                </pre>
                            )}
                        </Fragment>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}
