import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "../localization/i18n";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Fragment } from "react";

export default function LocSwitcher({
    supportedLangs,
    sx = {},
    themeColor = "primary",
    onChanging /* hotfix kvůli pomalé mapě na detailu ČPZ */,
}) {
    const { i18n } = useTranslation();
    const { changeLanguage, LangApiErrors } = useChangeLanguage();

    const customColor = themeColor + ".main";
    const customColors = {
        "& .MuiOutlinedInput-input": {
            color: customColor,
        },
        "&.MuiOutlinedInput-root": {
            backgroundColor: "transparent !important",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: customColor,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: customColor,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: customColor,
        },
        "& .MuiSelect-iconOutlined": {
            color: customColor,
        },
    };

    return (
        <Fragment>
            <Select
                value={i18n.language}
                onChange={handleChange}
                size="small"
                sx={{ ...customColors, ...sx }}
                MenuProps={{ disableScrollLock: true }}
                inputProps={{
                    sx: {
                        padding: "3px 5px",
                        fontWeight: "500",
                    },
                }}
                renderValue={(value) => supportedLangs.langShortNames[value]}
            >
                {supportedLangs.langs.map((language) => (
                    <MenuItem key={language} value={language}>
                        {supportedLangs.langNames[language]}
                    </MenuItem>
                ))}
            </Select>
            <LangApiErrors />
        </Fragment>
    );

    async function handleChange(event) {
        if (onChanging) {
            try {
                onChanging(false);
            } catch {}
        }

        await changeLanguage(event.target.value);

        if (onChanging) {
            try {
                onChanging(true);
            } catch {}
        }
    }
}
