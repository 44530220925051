import { defaultPrescriptionTypeUrl, prescriptionTypes } from "../codebooks/prescriptionTypes";

export function newPrescriptionsState() {
    const state = {
        byId: {},
        list: {},
        listTypeUrl: defaultPrescriptionTypeUrl,
        getEdited: {},
        save: {},
        prescriptions: { byId: {} },
        schedules: { byId: {} },
        newPrescriptions: {},
        existsNew: false,
    };

    return state;
}
