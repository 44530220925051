import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function NoParametersSection({ renderHeader, renderAddParameter }) {
    const { t } = useTranslation();

    return (
        <Fragment>
            {renderHeader}
            <div tw="mb-3 pb-5 flex items-center">
                <ul>
                    <li tw="mb-2" dangerouslySetInnerHTML={{ __html: t("records.Edit.NewUserHelper.ObserveSelf") }} />
                    <li
                        tw="mb-2"
                        dangerouslySetInnerHTML={{ __html: t("records.Edit.NewUserHelper.TrackingRecommendation") }}
                    />
                </ul>
            </div>
            {renderAddParameter}
        </Fragment>
    );
}
