import { get } from "react-hook-form";
import "./ValidationError.css";

export default function ValidationError({ errors, name }) {
    const errorMessage = getErrorMessage(errors, name);

    if (!errorMessage) return null;

    return <div className="validation-error">{errorMessage}</div>;
}

export function hasError(errors, name) {
    const error = get(errors, name);
    if (error) return true;

    return false;
}

export function getErrorMessage(errors, name) {
    const error = get(errors, name);
    if (!error) return null;

    return error.message;
}
