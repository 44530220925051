import { Fragment } from "react";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function MessagesTable({ messages, isPatientPreview, markTheMessageAsUnread }) {
    const { t, i18n } = useTranslation();
    const tdCss = css`
        ${tw`align-top`},
    `;

    return (
        <div tw="py-1 px-1 md:px-6">
            {messages.length > 0 ? (
                <table
                    tw="text-xs divide-y divide-dashed w-full"
                    css={css`
                        td,
                        th {
                            padding: 4px;
                        }
                    `}
                >
                    <thead>
                        <tr>
                            <th tw="w-10"></th>
                            <th align="left">{t("shCommunications.Messages.MessagesTable.Inserted")}</th>
                            <th align="left">{t("shCommunications.Messages.MessagesTable.Author")}</th>
                            <th align="left">{t("shCommunications.Messages.MessagesTable.Comment")}</th>
                        </tr>
                    </thead>
                    <tbody tw="divide-y divide-dashed">
                        {messages.map((message) => (
                            <tr key={message.id}>
                                <td css={tdCss}>
                                    {message.displayedDate && (
                                        <div
                                            title={`${
                                                !message.isFromMe
                                                    ? t("shCommunications.Messages.Old.Message.CancelDisplay")
                                                    : t("shCommunications.Messages.Old.Message.Displayed")
                                            } ${new Date(message.displayedDate).toLocaleDateString(i18n.language, {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                            })}`}
                                        >
                                            <IconButton
                                                sx={{
                                                    padding: 0,
                                                    marginTop: "-2px",
                                                    color: (theme) =>
                                                        isPatientPreview ? "#2b64af" : theme.palette.primary.main,
                                                    "&:hover": {
                                                        color: (theme) => theme.palette.unread.main,
                                                    },
                                                }}
                                                size="small"
                                                onClick={() => markTheMessageAsUnread(message)}
                                                disabled={message.isFromMe}
                                            >
                                                <VisibilityIcon fontSize="14px" />
                                            </IconButton>
                                        </div>
                                    )}
                                </td>
                                <td css={tdCss} data-title={t("shCommunications.Messages.MessagesTable.Inserted")}>
                                    {new Date(message.createdDate).toLocaleDateString(i18n.language, {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                    })}
                                </td>
                                <td
                                    css={tdCss}
                                    data-title={t("shCommunications.Messages.MessagesTable.Author")}
                                    className="messages-col-nowrap"
                                >
                                    {message.sender.fullName}
                                </td>
                                <td
                                    css={tdCss}
                                    data-title={t("shCommunications.Messages.MessagesTable.Comment")}
                                    className=""
                                >
                                    {(message.text ?? "").split("\n").map((t, i) => (
                                        <Fragment key={i}>
                                            {i !== 0 && <br />}
                                            {t}
                                        </Fragment>
                                    ))}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <table tw="text-xs">
                    <tbody>
                        <tr>
                            <td>{t("shCommunications.Messages.MessagesTable.NoMessages")}</td>
                        </tr>
                    </tbody>
                </table>
            )}
        </div>
    );
}
