export type ExternalConnectionStatus =
    | "Connected"
    | "DisconnectedExternally"
    | "DisconnectedOther"
    | "Expired"
    | "NotConnected";

export function isDisconnected(status: ExternalConnectionStatus) {
    return status !== "Connected" && status !== "NotConnected";
}

export function isConnected(status: ExternalConnectionStatus) {
    return status === "Connected";
}
