import { useState } from "react";
import { prescriptionTypes } from "../../codebooks/prescriptionTypes";
import PrescriptionIcon from "../types/PrescriptionIcon";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import tw from "twin.macro";
import GroupChip from "../commons/GroupChip";

export default function DrugCalendarItem({ item, onItemChanged, showGroupName, isPosting }) {
    const [checked, setChecked] = useState(item.confirmedAt !== null);

    let actionText = item.actionText;
    if (item.prescriptionType === prescriptionTypes.drug) {
        let lastBracket = actionText.lastIndexOf("(");
        if (lastBracket !== -1) actionText = actionText.substring(0, lastBracket);
    }

    const isActive = item.info.canBeConfirmed && item.confirmedAt === null;
    const isMissed = isActive && item.info.isPast && !item.situation;
    const isSituation = isActive && item.situation;
    const isTodayWithoutTime = isActive && !item.hasTime && item.info.isToday;
    const isNow = isActive && item.info.isNow && !item.situation;

    const itemCss = [
        tw`relative flex pl-2 pr-3 py-2 text-left items-center mb-2 text-gray-400 [border-radius: 16px] [background: #f5f5f5] [font-size: 13px]`,
        (isSituation || isTodayWithoutTime) && tw`[color: #000] [background: #e6e6e6]`,
        isMissed && tw`[color: #e00814] [background: #fce6e8]`,
        isNow && tw`[color: white] [background: #e00814]`,
        isActive && tw`cursor-pointer`,
    ];

    let themeColor = "prescriptionGeneral";

    if (isNow) themeColor = "prescriptionNow";
    else if (isMissed) themeColor = "prescriptionMissed";

    let amount = item.amount ? item.amount : "";
    if (item.amountMax) amount += " - " + item.amountMax;
    if (amount) amount += "×";

    return (
        <div css={itemCss} onClick={handleChange}>
            <div title={item.info.exactTime}>
                <Checkbox
                    checkedIcon={<CheckIcon />}
                    sx={{
                        margin: "0 14px 0 5px",
                        width: "26px",
                        height: "26px",
                        "&.MuiCheckbox-root": {
                            color: "white",
                            background: "white",
                            opacity: item.info.isFuture ? 0.4 : "none",
                            borderRadius: "3px",
                        },
                        "& .MuiSvgIcon-root": { fontSize: "18px" },
                        "&.Mui-checked": { color: themeColor + ".main" },
                        "&.Mui-checked .MuiSvgIcon-root": {},
                    }}
                    checked={checked}
                    onChange={() => {}}
                    disabled={!item.info.canBeConfirmed}
                />
            </div>

            <div tw="flex-1 w-8/12">
                {showGroupName && item.groupName && (
                    <div tw="mb-0.5">
                        <GroupChip groupName={item.groupName} />
                    </div>
                )}
                <div title={actionText}>
                    <span>{amount}</span> <span>{actionText}</span>
                    {item.situation && <span>, {item.situation}</span>}
                    {item.amountNote && <span>, {item.amountNote}</span>}
                </div>
            </div>

            <div>
                <PrescriptionIcon
                    type={item.prescriptionType}
                    sx={{ width: 34, height: 34 }}
                    color={isNow ? "white" : themeColor}
                />
            </div>
        </div>
    );

    function handleChange() {
        if (!item.info.canBeConfirmed || isPosting) return;

        let newState = !checked;
        setChecked(newState);
        onItemChanged(item, newState);
    }
}
