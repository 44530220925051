import { useTranslation } from "react-i18next";
import AppSettings from "../../app/AppSettings";
import EndCooperationDialog from "./EndCooperationDialog";
import AcceptCooperationDialog from "./AcceptCooperationDialog";
import RejectCooperationDialog from "./RejectCooperationDialog";
import { useFetch } from "@profilog/misc/utils/useFetch";
import { useState, Fragment } from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { cooperationStates } from "./cooperationStates";
import "twin.macro";

export default function CooperationTable({ cooperations, onChangeCooperation }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();
    const { apiPost, apiPut, ApiErrors } = useFetch();

    async function endCooperation(groupId) {
        let response = await apiPost("/web/cooperations/end", {
            medicalSubjectId: AppSettings.user.userMedicalSubjectId,
            groupId: groupId,
        });
        if (response.isOk) {
            onChangeCooperation();
        }
    }

    async function acceptRequest(requestId) {
        setIsSubmitting(true);
        const response = await apiPut(`/web/cooperations/requests/${requestId}/accept`);
        if (response.isOk) {
            setIsSubmitting(false);
            onChangeCooperation();
        } else {
            setIsSubmitting(false);
        }
    }

    async function rejectRequest(requestId) {
        setIsSubmitting(true);
        const response = await apiPut(`/web/cooperations/requests/${requestId}/reject`);
        if (response.isOk) {
            setIsSubmitting(false);
            onChangeCooperation();
        } else {
            setIsSubmitting(false);
        }
    }

    function getCooperationState(cooperation) {
        switch (cooperation.state) {
            case cooperationStates.active:
                return t("communications.CooperationRequests.Table.CooperationStates.Active");
            case cooperationStates.pending:
                return t("communications.CooperationRequests.Table.CooperationStates.Pending");
            default:
                return cooperation.state;
        }
    }

    return cooperations.length > 0 ? (
        <Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("communications.CooperationRequests.Table.Name")}</TableCell>
                        <TableCell>{t("communications.CooperationRequests.Table.CooperationState")}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cooperations.map((cooperation) => (
                        <TableRow key={cooperation.groupId}>
                            <TableCell data-title={t("communications.CooperationRequests.Table.Name")}>
                                {cooperation.groupName}
                            </TableCell>
                            <TableCell data-title={t("communications.CooperationRequests.Table.CooperationState")}>
                                {getCooperationState(cooperation)}
                            </TableCell>
                            <TableCell align="right">
                                {cooperation.state === cooperationStates.pending && (
                                    <Fragment>
                                        <AcceptCooperationDialog
                                            trigger={(open) => (
                                                <Button
                                                    tw="px-2 m-1"
                                                    size="small"
                                                    variant="contained"
                                                    color="success"
                                                    onClick={open}
                                                    disabled={isSubmitting}
                                                >
                                                    {t("communications.CooperationRequests.AcceptCooperation.Accept")}
                                                </Button>
                                            )}
                                            onConfirmClick={() => acceptRequest(cooperation.cooperationRequestId)}
                                        />
                                        <RejectCooperationDialog
                                            trigger={(open) => (
                                                <Button
                                                    tw="px-2 m-1"
                                                    size="small"
                                                    variant="contained"
                                                    color="error"
                                                    onClick={open}
                                                    disabled={isSubmitting}
                                                >
                                                    {t("communications.CooperationRequests.RejectCooperation.Reject")}
                                                </Button>
                                            )}
                                            onConfirmClick={() => rejectRequest(cooperation.cooperationRequestId)}
                                        />
                                    </Fragment>
                                )}
                                {cooperation.state === cooperationStates.active && (
                                    <EndCooperationDialog
                                        trigger={(open) => (
                                            <Button color="warning" onClick={open}>
                                                {t("communications.CooperationRequests.EndCooperation.EndCooperation")}
                                            </Button>
                                        )}
                                        onConfirmClick={() => endCooperation(cooperation.groupId)}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <ApiErrors />
        </Fragment>
    ) : (
        <Fragment>
            <p>
                <em>{t("communications.CooperationRequests.Table.NoCooperations")}</em>
            </p>
        </Fragment>
    );
}
