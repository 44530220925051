import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useFetch } from "@profilog/misc/utils/useFetch";
import ConsentsForm from "@profilog/misc/user/components/ConsentsForm";
import { getErrorMessage } from "@profilog/misc/errors/ValidationError";
import PasswordInput from "@profilog/misc/auth/components/PasswordInput";
import { Button, TextField } from "@mui/material";
import { useAuth } from "@profilog/misc/auth/useAuth";

export default function Registration() {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        errors,
        formState: { isSubmitting },
    } = useForm();
    const navigate = useNavigate();

    const { apiPost, ApiErrors: ApiRegisterError } = useFetch();
    const { loginFromApiResponse } = useAuth();

    async function registerUser(data) {
        let consents = {};
        consents["PersonalDataProcessing"] = data.PersonalDataProcessing;
        consents["MarketingPurpose"] = data.MarketingPurpose;
        consents["Majority"] = data.Majority;

        const input = {
            email: data.email,
            password: data.password,
            consents: consents,
        };

        const response = await apiPost("/web/user/register", input);
        if (response.isOk) {
            if (response.json.isLoginWithoutEmailConfirmationAllowed) {
                loginFromApiResponse(response);
                navigate("../in/records", { replace: true });
            } else {
                navigate("../successfully-registered", { replace: true });
            }
            return null;
        }
    }

    return (
        <Fragment>
            <h2>{t("registration.NewRegistration")}</h2>
            <p>{t("registration.Welcome")}</p>
            <form onSubmit={handleSubmit(registerUser)} autoComplete="off">
                <div tw="sm:w-1/2 mb-1">
                    <TextField
                        type="text"
                        name="email"
                        label={t("global.Form.Email")}
                        inputRef={register({ required: t("auth.EmailRequired") })}
                        autoComplete="username"
                        error={getErrorMessage(errors, "email") ? true : false}
                        helperText={getErrorMessage(errors, "email")}
                        fullWidth
                    />
                </div>
                <p>{t("registration.EmailAsUserName")}</p>
                <div tw="sm:w-1/2">
                    <PasswordInput
                        label={t("registration.Password")}
                        name="password"
                        inputRef={register({
                            required: t("auth.PasswordRequired"),
                            minLength: {
                                value: 3,
                                message: t("auth.PasswordMinLength").replace("{0}", 3),
                            },
                        })}
                        autoComplete="new-password"
                        error={getErrorMessage(errors, "password") ? true : false}
                        helperText={getErrorMessage(errors, "password")}
                        fullWidth
                    />
                </div>
                <p>{t("registration.PasswordRequirementsText")}</p>
                <p>{t("registration.GdprConsentText")}</p>
                <div tw="pl-2 pr-2">
                    <ConsentsForm
                        systemNames={["PersonalDataProcessing", "Majority", "MarketingPurpose"]}
                        errors={errors}
                        register={register}
                        tw="pl-2 pr-2"
                    />
                </div>

                <ApiRegisterError />

                <div tw="flex justify-center">
                    <Button type="submit" variant="contained" disabled={isSubmitting}>
                        {t("registration.Register")}
                    </Button>
                </div>
            </form>
        </Fragment>
    );
}
