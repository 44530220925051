import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { apiGet, apiPost } from "@profilog/misc/utils/useFetch";

export function createApiGet(sliceName, name, url, mapResponse = null) {
    return createApiCall(
        sliceName,
        name,
        async (arg, thunkAPI) => {
            return await apiGet(getUrl(url, arg));
        },
        mapResponse,
    );
}

export function createApiPost(sliceName, name, url, getPostObject, onFulfilled = null) {
    return createApiCall(sliceName, name, async (arg, thunkAPI) => {
        var postObject = getPostObject(arg, thunkAPI.getState());
        return await apiPost(getUrl(url, arg, postObject), postObject, null, onFulfilled);
    });
}

function getUrl(url, arg, object = null) {
    if (typeof url === "function") return url(arg, object);
    else return url;
}

export function createApiCall(sliceName, name, call, mapResponse = null, onFulfilled = null) {
    let typePrefix = sliceName;
    if (name) typePrefix += "/" + name;

    const cleanupActionName = typePrefix + "/cleanup";
    const cleanup = createAction(cleanupActionName);

    const resetApiErrorsActionName = typePrefix + "/resetApiErrors";
    const resetApiErrors = createAction(resetApiErrorsActionName);

    const thunk = createAsyncThunk(typePrefix, async (arg, thunkAPI) => {
        try {
            const response = await call(arg, thunkAPI);

            if (response.isOk) return mapResponse ? mapResponse(response.json) : response.json;
            else return thunkAPI.rejectWithValue(response.errors);
        } catch (e) {
            if (Array.isArray(e)) return thunkAPI.rejectWithValue(e);
            if (e.message) return thunkAPI.rejectWithValue([e.message]);
            return thunkAPI.rejectWithValue([typeof e]);
        }
    });

    return {
        thunk,
        reducers: {
            [thunk.pending]: (state, action) => {
                let targetState = getStatePart(state, null, name);
                const argName = getArgNameFromAction(action);
                const argState = newApiCallState();
                argState.pending = true;
                targetState[argName] = argState;
            },
            [thunk.fulfilled]: (state, action) => {
                const argState = getStatePart(state, null, name, getArgNameFromAction(action));
                argState.data = action.payload;
                if (onFulfilled) onFulfilled(state, action.payload);
                argState.pending = false;
            },
            [thunk.rejected]: (state, action) => {
                let argState = getStatePart(state, null, name, getArgNameFromAction(action));
                argState.pending = false;
                argState.apiErrors = action.payload;
            },
            [cleanup]: (state, action) => {
                let targetState = getStatePart(state, null, name);
                delete targetState[getArgName(action.payload)];
            },
            [resetApiErrors]: (state, action) => {
                let argState = getStatePart(state, null, name, getArgName(action.payload));
                argState.apiErrors = null;
            },
        },
        useHook: (arg) => {
            const dispatch = useDispatch();

            const pending = useSelector((state) => {
                const statePart = getStatePart(state, sliceName, name, getArgName(arg));
                return statePart === undefined ? false : statePart.pending;
            });

            const data = useSelector((state) => {
                const statePart = getStatePart(state, sliceName, name, getArgName(arg));
                return statePart === undefined ? null : statePart.data;
            });

            const apiErrors = useSelector((state) => {
                const statePart = getStatePart(state, sliceName, name, getArgName(arg));
                return statePart === undefined ? null : statePart.apiErrors;
            });

            return {
                thunk,
                pending,
                data,
                apiErrors,
                dispatchResetApiErrors,
                dispatchCleanup,
            };

            function dispatchResetApiErrors() {
                dispatch(resetApiErrors(arg));
            }

            function dispatchCleanup() {
                dispatch(cleanup(arg));
            }
        },
    };
}

function getArgNameFromAction(action) {
    return getArgName(action.meta.arg);
}

export function getArgName(arg) {
    return arg ? JSON.stringify(arg) : "shared";
}

function getStatePart(state, sliceName, name, arg) {
    let currentState = state;
    if (sliceName !== null) currentState = state[sliceName];
    if (name !== null) currentState = currentState[name];
    if (arg) currentState = currentState[arg];
    return currentState;
}

export function createApiGetSlice(sliceName, url, map) {
    const apiCall = createApiGet(sliceName, null, url, map);
    const slice = createSlice({
        name: sliceName,
        initialState: {},
        extraReducers: apiCall.reducers,
    });

    return {
        thunk: apiCall.thunk,
        hook: apiCall.useHook,
        reducer: slice.reducer,
    };
}

export function newApiCallState() {
    return {
        pending: false,
        data: null,
        apiErrors: null,
    };
}
