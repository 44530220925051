import React, { Fragment } from "react";
import { useState, useEffect, useRef } from "react";
import FlagImage from "../../assets/images/flag.png";
import EcgImage from "../../assets/images/heartbeat-solid.png";
import RefreshIcon from "@mui/icons-material/Refresh";
import AppSettings from "@profilog/front/app/AppSettings";
import { minutesToHmsString } from "@profilog/utils/converting";

import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    CommonSeriesSettings,
    Legend,
    Tooltip,
    Label,
    Tick,
    Format,
    Size,
    Font,
    ZoomAndPan,
    Title,
    VisualRange,
    WholeRange,
    Animation,
    CommonAxisSettings,
    Grid,
    TickInterval,
    Border,
    ConstantLine,
} from "devextreme-react/chart";
import DaysLink from "./DaysLink";
import { useTranslation } from "react-i18next";
import DatePicker from "../../commons/components/DatePicker";
import { format } from "date-fns";
// import { getQuery } from "../../utils/Url";
import { useEnergyIntakeModal } from "../../caloricsTables/EnergyIntakeModal";
import "../../caloricsTables/EnergyIntakeModal.scss";
import EcgDialog from "../EcgDialog";
import { Button, CircularProgress, IconButton, MenuItem, TextField } from "@mui/material";
import IsMobile from "@profilog/misc/utils/IsMobile";

export default function RecordsChart({
    fetchService,
    isAllowedSaveVisibilities,
    chartGroup,
    isLiteMode = false,
    initiateDate = undefined,
}) {
    // const otherchartfilters = getQuery().get("otherchartfilters");
    const { t, i18n } = useTranslation();
    const [userData, setUserData] = useState();
    const [visibleLastDaysCount, setVisibleLastDaysCount] = useState(chartGroup.filter.visibleDaysCount);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [ranges, setRanges] = useState();
    const [measureGroupedBy, setMeasureGroupedBy] = useState(chartGroup.filter.measureGroupingType);
    const [stateGroupedBy, setStateGroupedBy] = useState(chartGroup.filter.stateGroupingType);
    const { isEnergyIntakeModalOpen, openEnergyIntakeModal, EnergyIntakeModal } = useEnergyIntakeModal();
    const [energyIntakeModalDateTime, setEnergyIntakeModalDateTime] = useState();
    const [isFirstDataLoad, setIsFirstDataLoad] = useState(true);
    const seriesVisibility = useRef([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const { parametersData, getUserDataAsync, postChartParameterVisibilitiesAsync, GetApiErrors } = fetchService;
    const [charts, setCharts] = useState(chartGroup.charts);
    const previousVisibilitiesSaveState = useRef(null);
    const [selectedRecord, setSelectedRecord] = useState();

    const glycemiaParam = parametersData.parameterSettings.find((p) => p.systemName === "Glycemia");
    const systolicPressureParam = parametersData.parameterSettings.find((p) => p.systemName === "SystolicPressure");
    const diastolicPressureParam = parametersData.parameterSettings.find((p) => p.systemName === "DiastolicPressure");

    async function getUserData() {
        setIsLoaded(false);
        let result = await getUserDataAsync(ranges.wholeRange, measureGroupedBy, stateGroupedBy, null, isLiteMode);

        if (result.isOk) {
            setUserData(result.json);
            setIsLoaded(true);
        }
    }

    function setNewRanges(visualStart, visualEnd, wholeStart, wholeEnd, isManualDate = false) {
        setRanges({
            visualRange: { startValue: visualStart, endValue: visualEnd },
            wholeRange: { startValue: wholeStart, endValue: wholeEnd },
            isManualDate: isManualDate,
        });
    }

    useEffect(() => {
        if (!initiateDate) return;

        let initShowingDate = new Date();
        let endDate = new Date();

        if (initiateDate) {
            initShowingDate = new Date(initiateDate);
            initShowingDate.setDate(initShowingDate.getDate() + chartGroup.filter.visibleDaysCount);
            endDate = new Date(initShowingDate);
        }
        //setGroupedBy(groupedBy);
        initShowingDate = getDateFromVisibleLastDaysCount(initShowingDate, -1, chartGroup.filter.visibleDaysCount);

        if (initiateDate) {
            setNewRanges(initShowingDate, endDate, initShowingDate, endDate);
        } else {
            setRangesFromDateTime(initShowingDate, format(initShowingDate, "HH:mm"));
        }
    }, [initiateDate?.getTime()]);

    useEffect(() => {
        if (ranges?.wholeRange == null) return;
        getUserData();
    }, [ranges?.wholeRange, measureGroupedBy, stateGroupedBy]);

    useEffect(() => {
        if (initiateDate) return;
        actualizeRangeByActualDateTime();
        // actualizeDataFilter();
    }, [visibleLastDaysCount, parametersData]);

    function actualizeRangeByActualDateTime() {
        if (visibleLastDaysCount != null) {
            let newShowingDate;
            if (ranges?.isManualDate) {
                newShowingDate = ranges.visualRange.startValue;
            } else {
                let date = new Date();
                newShowingDate = getDateFromVisibleLastDaysCount(date, -1, visibleLastDaysCount);
            }

            setRangesFromDateTime(newShowingDate, format(newShowingDate, "HH:mm"), ranges?.isManualDate);
        } else {
            setNewRanges(null, null, null, null);
        }
    }

    useEffect(() => {
        if (parametersData) {
            seriesVisibility.current = [];

            if (isFirstDataLoad) {
                let initialSeriesVisibilities = {};
                if (parametersData.hasAnyVisibleParameter) {
                    for (let i = 0; i < parametersData.parameterSettings.length; i++) {
                        const settings = parametersData.parameterSettings[i];
                        initialSeriesVisibilities[settings.id] = settings.isVisible;
                    }
                } else {
                    let axisDisplayOrders = {};
                    let minDisplayOrder = Number.MAX_SAFE_INTEGER;
                    for (let i = 0; i < parametersData.axesSettings.length; i++) {
                        const axis = parametersData.axesSettings[i];
                        if (minDisplayOrder > axis.displayOrder) minDisplayOrder = axis.displayOrder;
                        axisDisplayOrders[axis.id] = axis.displayOrder;
                    }
                    for (let i = 0; i < parametersData.parameterSettings.length; i++) {
                        const settings = parametersData.parameterSettings[i];
                        const axisDisplayOrder = axisDisplayOrders[settings.axisId];

                        initialSeriesVisibilities[settings.id] = axisDisplayOrder === minDisplayOrder;
                    }
                }

                for (let index = 0; index < charts.length; index++) {
                    seriesVisibility.current.push(initialSeriesVisibilities);
                }
            } else {
                for (let index = 0; index < charts.length; index++) {
                    let visibility = {};

                    for (let i = 0; i < parametersData.parameterSettings.length; i++) {
                        const settings = parametersData.parameterSettings[i];
                        visibility[settings.id] = true;
                    }

                    seriesVisibility.current.push(visibility);
                }
            }

            for (let i = 0; i < parametersData.axesSettings.length; i++) {
                const settings = parametersData.axesSettings[i];
                settings.alwaysUpdateRange = !settings.startValue && !settings.endValue;
            }

            setIsFirstDataLoad(false);
        }
    }, [parametersData]);

    // useEffect(() => {
    //     const setAxesRanges = () => {
    //         userData.userParamValueRanges.forEach((range) => {
    //             let axisSettings = parametersData.axesSettings.find((a) => a.id === range.axisId);
    //             if ((!axisSettings.startValue && !axisSettings.endValue) || axisSettings.alwaysUpdateRange) {
    //                 let min = range.min;
    //                 let max = range.max;

    //                 let interval = max - min;

    //                 if (interval === 0) interval = 1;

    //                 let adjustment = interval % 5.0;

    //                 if (adjustment !== 0) interval = interval - adjustment + 5.0;

    //                 let newRange = interval;
    //                 interval /= 10;

    //                 let d = newRange - (max - min);

    //                 let i = Math.floor(d / 2);

    //                 axisSettings.startValueComputed = min - i + interval;
    //                 axisSettings.endValueComputed = max + (d - i) - interval;

    //                 if (axisSettings.startValueComputed < 0) {
    //                     axisSettings.startValueComputed = 0;
    //                     axisSettings.endValueComputed = newRange - interval;
    //                 } else if (axisSettings.startValueComputed > min) {
    //                     axisSettings.startValueComputed = min;
    //                     axisSettings.endValueComputed = max;
    //                 }
    //                 axisSettings.tickIntervalComputed = interval;
    //             }
    //         });
    //     };
    //     if (userData && parametersData) {
    //         setAxesRanges();
    //     }
    // }, [userData]);

    // function actualizeDataFilter() {
    //     if (!initiateDate) {
    //         let grouped = "day";
    //         if (visibleLastDaysCount != null) {
    //             if (visibleLastDaysCount == 1 || visibleLastDaysCount === "Day") {
    //                 grouped = "minute";
    //             } else if (visibleLastDaysCount <= 7) {
    //                 grouped = "hour";
    //             }

    //             let dateTo;
    //             if (visualRange.startValue == null) {
    //                 dateTo = new Date();
    //             } else {
    //                 dateTo = getDateFromVisibleLastDaysCount(
    //                     new Date(visualRange.startValue),
    //                     2,
    //                     visibleLastDaysCount,
    //                     false,
    //                 );
    //             }
    //             if (dateTo > new Date()) dateTo = new Date();
    //             let dateFrom = getDateFromVisibleLastDaysCount(new Date(dateTo), -3, visibleLastDaysCount, true);
    //             setDataDates({ dateFrom: dateFrom, dateTo: dateTo });
    //         } else {
    //             setDataDates({ dateFrom: null, dateTo: null });
    //         }
    //     }
    //     if (!isLiteMode) setGroupedBy(grouped);
    // }

    function getDateFromVisibleLastDaysCount(oldDate, x, daysCount) {
        let date = new Date(oldDate);

        switch (daysCount) {
            case "Year":
                date.setFullYear(date.getFullYear() + x);
                break;
            case "Month":
                date.setMonth(date.getMonth() + x);
                break;
            case "Week":
                date.setDate(date.getDate() + x * 7);
                break;
            case "TwoWeeks":
                date.setDate(date.getDate() + x * 14);
                break;
            case "Day":
                date.setDate(date.getDate() + x);
                break;
            default:
                date.setDate(date.getDate() + x * daysCount);
                break;
        }

        return date;
    }

    const handleLegendClick = (e, index) => {
        const serie = e.target;
        const visible = serie.isVisible();
        if (visible) {
            serie.hide();
        } else {
            serie.show();
        }

        seriesVisibility.current[index][serie._options.valueField] = !visible;

        if (index === 0 && isAllowedSaveVisibilities) {
            let visibilities = [];
            const series = seriesVisibility.current[index];

            for (let key in series) {
                visibilities.push({
                    parameterId: key,
                    isVisible: series[key],
                });
            }

            saveVisibility(visibilities);
        }
    };

    function saveVisibility(visibilities) {
        previousVisibilitiesSaveState.current = saveVisibilityAsync(visibilities);
    }

    async function saveVisibilityAsync(visibilities) {
        if (previousVisibilitiesSaveState.current != null) await previousVisibilitiesSaveState.current;
        return postChartParameterVisibilitiesAsync(visibilities);
    }

    //událost při změně času zobrazení
    const onShowingTimeChange = (time) => {
        setRangesFromDateTime(ranges.visualRange.startValue, time, true);
    };

    //událost při změně datumu zobrazení
    const onShowingDateChange = (date) => {
        setRangesFromDateTime(date, format(ranges.visualRange.startValue, "HH:mm"), true);
    };

    //nastaví visualrange grafu podle datumu a času
    function setRangesFromDateTime(date, time, isManualDate) {
        if (date != null) {
            const timeArray = time == null ? [0, 0] : time.split(":");

            let dateStart = new Date(date);
            dateStart.setHours(timeArray[0]);
            dateStart.setMinutes(timeArray[1]);

            let dateEnd = getDateFromVisibleLastDaysCount(new Date(date), 1, visibleLastDaysCount);
            dateEnd.setHours(timeArray[0]);
            dateEnd.setMinutes(timeArray[1]);

            if (initiateDate) {
                setNewRanges(dateStart, dateEnd, dateStart, dateEnd, isManualDate);
            } else {
                setNewRanges(
                    dateStart,
                    dateEnd,
                    getDateFromVisibleLastDaysCount(new Date(dateStart), -1, visibleLastDaysCount),
                    getDateFromVisibleLastDaysCount(new Date(dateEnd), 1, visibleLastDaysCount),
                    isManualDate,
                );
            }
        } else {
            setNewRanges(null, null, null, null, false);
        }
    }

    const handleOptionChange = (e, index) => {
        if (e.fullName === "argumentAxis.visualRange") {
            //pokud se kliklo na počet dnů zobrazení - tak datum a čas zobrazení neaktualizujeme podle osy x
            if (isEnergyIntakeModalOpen) return;

            setRanges({ ...ranges, visualRange: e.value });
        }
    };

    const handleSeriesClick = (e) => {
        let param = parametersData.parameterSettings.find((p) => p.tag === e.target.series.tag);
        if (param.systemName === "EnergyIntakeInKiloJoules") {
            setEnergyIntakeModalDateTime(e.target.argument);
            openEnergyIntakeModal();
        }

        if (param.systemName === "Ecg") {
            setSelectedRecord({ recordId: e.target.data["ecg"], date: e.target.argument });
        }
    };

    function handleEcgModalClosed() {
        setSelectedRecord(null);
    }

    function sortLegendItems(items) {
        items.forEach((item) => {
            if (!item.series.isVisible()) item.textOpacity = 0.6;
        });

        return items.sort((a, b) => {
            let itemA = a.text.toLowerCase();
            let itemB = b.text.toLowerCase();
            if (itemA < itemB) return -1;
            if (itemA > itemB) return 1;
            return 0;
        });
    }

    function onRemoveChart(index) {
        let newCharts = [...charts];
        newCharts.splice(index, 1);
        seriesVisibility.current.splice(index, 1);
        setCharts(newCharts);
    }

    function onAddChart() {
        let newCharts = [...charts];
        let newChart = JSON.parse(JSON.stringify(newCharts[newCharts.length - 1]));

        let visibilities = {};
        for (let key in seriesVisibility.current[seriesVisibility.current.length - 1]) {
            visibilities[key] = false;
        }

        seriesVisibility.current.push(visibilities);

        newCharts.push(newChart);
        setCharts(newCharts);
    }

    function customizeTooltip(pointInfo, index) {
        let items = [];

        pointInfo.points.forEach((point, index) => {
            const axisSettings = parametersData.axesSettings.find((x) => x.id === point.point.series.axis);
            const parameter = parametersData.parameterSettings.find((x) => x.tag === point.point.series.tag);

            let text;

            if (point.point.series.type === "rangearea") {
                text = `${point.seriesName} (min - max): ${point.valueText}`;
            } else if (parameter.systemName === "Ecg") {
                text = `${point.seriesName}: ${format(pointInfo.argument, "dd.MM.yyyy HH:mm")}`;
            } else if (
                parameter.systemName === "ExerciseTimeInMinute" ||
                parameter.systemName === "DurationInSeconds"
            ) {
                let timeStr = minutesToHmsString(point.value);

                text = `${point.seriesName}: ${timeStr}`;
            } else if (parameter.measureType !== "AmountMeasure") {
                text = `${point.seriesName} ø: ${point.valueText}`;
            } else {
                text = `${point.seriesName}: ${point.valueText}`;
            }

            if (
                axisSettings &&
                axisSettings.title !== "" &&
                parameter.systemName !== "ExerciseTimeInMinute" &&
                parameter.systemName !== "DurationInSeconds"
            )
                text = `${text} ${axisSettings.title}`;

            if (axisSettings)
                if (pointInfo.seriesName === point.seriesName && pointInfo.value === point.value) {
                    const element = document.createElement("span");
                    element.textContent = text;
                    element.style.color = pointInfo.point.getColor();
                    element.className = "active";
                    text = element.outerHTML;
                }
            items.push(text);
        });

        const element = document.createElement("span");
        element.style.paddingTop = 5;
        switch (measureGroupedBy) {
            case "minute":
            case "hour":
                element.textContent = format(pointInfo.argument, "dd.MM.yyyy HH:mm");
                break;
            case "second":
                element.textContent = format(pointInfo.argument, "dd.MM.yyyy HH:mm:ss");
                break;
            default:
                element.textContent = format(pointInfo.argument, "dd.MM.yyyy");
                break;
        }

        items.push(element.outerHTML);

        return { text: items.join("\n") };
    }

    function getValueAxes(chart, chartIndex) {
        //if (isEcgModalOpen) return;

        if (userData.userParamValueRanges.length === 0) {
            return (
                <ValueAxis position="left" visible={false}>
                    <Tick visible={false} />
                </ValueAxis>
            );
        }

        return userData.userParamValueRanges.map((range, index) => {
            const axisSettings = parametersData.axesSettings.find((a) => a.id === range.axisId);
            // let start = axisSettings.startValue ?? axisSettings.startValueComputed;
            // let end = axisSettings.endValue ?? axisSettings.endValueComputed;
            // let tick = axisSettings.tickInterval ?? axisSettings.tickIntervalComputed;

            // if (kgAxis && weightParameter && axisSettings.id === weightParameter.axisId) {
            //     start = kgAxis.min - 10;
            //     end = kgAxis.max + 10;
            //     tick = (end - start) / 10;
            // }

            // if (start === null && settings.length === 2) {
            //     start = 0;
            // }
            return (
                <ValueAxis
                    key={axisSettings.id}
                    name={axisSettings.id}
                    allowDecimals={axisSettings.isDecimal}
                    position={axisSettings.positionType}
                    //tickInterval={tick}
                    visible={false} //{axisSettings.isVisible && chart.axesVisibility[axisSettings.id]}
                    visualRangeUpdateMode="keep"
                    constantLines={
                        axisSettings.Id === 1 ? constantLines : getValueAxisConstantLines(chartIndex, axisSettings.id)
                    }
                >
                    <Label
                        visible={false} //{axisSettings.isVisible && chart.axesVisibility[axisSettings.id]}
                        // customizeText={valueAxisCustomizeLabelText}
                        // alignment={axisSettings.positionType}
                        // position="inside"
                        // font={{ opacity: "0.7" }}
                        // indentFromAxis={0}
                    />
                    <Tick visible={false} />
                    <Title
                        text="" //{axisSettings.isVisible && chart.axesVisibility[axisSettings.id] ? axisSettings.title : ""}
                    />
                    <Grid visible={index === 0} />
                    <WholeRange startValue={range.min} endValue={range.max} />
                    {/* <VisualRange startValue={range.min} endValue={range.max} /> */}
                </ValueAxis>
            );
        });
    }

    function getValueAxisConstantLines(chartIndex, axisSettingsId) {
        let constantLines = [];

        const series = seriesVisibility.current[chartIndex];

        if (axisSettingsId === systolicPressureParam.axisId && series[systolicPressureParam.id.toString()]) {
            constantLines.push({
                value: 120,
                color: "#86B049",
                dashStyle: "dash",
                width: 2,
                label: { visible: false },
            });
        }

        if (axisSettingsId === diastolicPressureParam.axisId && series[diastolicPressureParam.id.toString()]) {
            constantLines.push({
                value: 80,
                color: "#476930",
                dashStyle: "dash",
                width: 2,
                label: { visible: false },
            });
        }

        return constantLines;
    }

    function argumentAxisCustomizeLabelText(info) {
        return info.valueText;
    }

    function valueAxisCustomizeLabelText(data) {
        return data.min > data.value || data.value === 0 ? "" : data.valueText + "\n&nbsp;";
    }

    function getTickInterval() {
        switch (visibleLastDaysCount) {
            case "Year":
                return <TickInterval months={1} />;
            case "Month":
                return <TickInterval days={7} />;
            case "Week":
            case "TwoWeeks":
                return <TickInterval days={1} />;
            case "Day":
                return <TickInterval hours={1} />;
            default:
                return null;
        }
    }

    function getDateTimeFormat(value) {
        let minutes = value.getMinutes();
        let hours = value.getHours();

        if (minutes === 0 && hours === 0)
            return value.toLocaleDateString(i18n.language, {
                month: "long",
                day: "numeric",
            });
        else
            return value.toLocaleTimeString(i18n.language, {
                hour: "2-digit",
                minute: "2-digit",
            });
    }

    function getSeries(chartIndex) {
        let result = [];

        for (let index = 0; index < userData.recordParameterIds.length; index++) {
            const recordParameterId = userData.recordParameterIds[index];
            const parameterSettings = parametersData.parameterSettings.find((p) => p.id === recordParameterId);
            if (!parameterSettings) continue;
            result.push({ parameterSettings: parameterSettings, isRange: false });
        }

        const visibilities = seriesVisibility.current[chartIndex];

        return result.map((data, index) => {
            let point = {};
            if (data.parameterSettings.tag === "text") point = scatterSettings;
            if (data.parameterSettings.systemName === "Ecg") point = ecgSettings;
            if (data.parameterSettings.systemName === glycemiaParam.systemName) {
                let count = userData.recordCounts[glycemiaParam.id];

                if (count > 10000) {
                    point = {
                        size: 1,
                        border: {
                            visible: false,
                        },
                    };
                } else if (count > 7000) {
                    point = {
                        size: 2,
                        border: {
                            visible: false,
                        },
                    };
                } else if (count > 4000) {
                    point = {
                        size: 3,
                        border: {
                            visible: false,
                        },
                    };
                } else if (count > 1000) {
                    point = {
                        size: 4,
                        border: {
                            visible: false,
                        },
                    };
                }
            }
            return (
                <Series
                    key={index}
                    valueField={data.isRange ? undefined : data.parameterSettings.id}
                    rangeValue1Field={data.isRange ? data.parameterSettings.id + "-min" : undefined}
                    rangeValue2Field={data.isRange ? data.parameterSettings.id + "-max" : undefined}
                    name={data.parameterSettings.translatedName}
                    axis={data.parameterSettings.axisId}
                    type={data.isRange ? "rangeArea" : data.parameterSettings.serieType}
                    order={data.parameterSettings.order}
                    color={data.isRange ? "#b0daff" : data.parameterSettings.color}
                    visible={visibilities[data.parameterSettings.id]}
                    tag={data.parameterSettings.tag}
                    point={point}
                    minBarSize={5}
                ></Series>
            );
        });
    }

    function visibleLastDaysCountChanged(value) {
        let measure;
        let state;

        if (value === 1) {
            measure = "hour";
            state = "second";
        } else if (value === 7) {
            measure = "hour";
            state = "minute";
        } else {
            measure = "day";
            state = "hour";
        }

        setUserData();
        if (measure === measureGroupedBy && state === stateGroupedBy && value === visibleLastDaysCount) {
            actualizeRangeByActualDateTime();
        }

        if (measure !== measureGroupedBy) setMeasureGroupedBy(measure);
        if (state !== stateGroupedBy) setStateGroupedBy(state);
        if (value !== visibleLastDaysCount) setVisibleLastDaysCount(value);
        //if (value === visibleLastDaysCount) setIsCollapsed(!isCollapsed);
    }

    function getConstantLines() {
        let lines = [];
        let date = new Date(ranges.wholeRange.startValue);
        date.setHours(0, 0, 0, 0);
        let days = visibleLastDaysCount <= 7 ? 1 : 7;
        if (days === 7 && date.getDay() !== 1) {
            date.setDate(date.getDate() - date.getDay() + 1);
        }

        if (date < ranges.wholeRange.startValue) {
            date.setDate(date.getDate() + days);
        }

        let key = 1;
        while (date < ranges.wholeRange.endValue) {
            lines.push(
                <ConstantLine
                    key={key++}
                    value={new Date(date)}
                    color="#000000"
                    dashStyle="dot"
                    width={1}
                    displayBehindSeries={true}
                    label={{ visible: false }}
                />,
            );
            date.setDate(date.getDate() + days);
        }

        return lines;
    }

    if (!parametersData) return null;

    return (
        <Fragment>
            <GetApiErrors />

            {isEnergyIntakeModalOpen && energyIntakeModalDateTime && (
                <EnergyIntakeModal
                    fetchService={fetchService}
                    selectedDateTime={energyIntakeModalDateTime}
                    groupedBy={measureGroupedBy}
                />
            )}

            {selectedRecord && (
                <EcgDialog
                    displayOpen={true}
                    fetchService={fetchService}
                    recordId={selectedRecord.recordId}
                    recordDate={selectedRecord.date}
                    onCloseClicked={handleEcgModalClosed}
                />
            )}

            <div tw="shadow-updown px-5 py-2.5 space-y-2">
                <div tw="flex items-center gap-x-4 gap-y-2 flex-wrap">
                    {/* {t("recordsVisualization.ShowFrom")} */}
                    <div tw="flex space-x-1">
                        <div>
                            <DatePicker
                                selected={ranges?.visualRange && ranges?.visualRange.startValue}
                                onChange={(date) => {
                                    setUserData();
                                    onShowingDateChange(date);
                                }}
                                customInput={
                                    <TextField
                                        size="small"
                                        tw="width[7em]"
                                        inputProps={{
                                            readOnly: AppSettings.isMobileApp,
                                        }}
                                    />
                                }
                            />
                        </div>

                        <TextField
                            size="small"
                            type="time"
                            value={
                                ranges?.visualRange && ranges?.visualRange.startValue != null
                                    ? format(ranges?.visualRange.startValue, "HH:mm")
                                    : ":"
                            }
                            onChange={(e) => {
                                setUserData();
                                onShowingTimeChange(e.target.value);
                            }}
                        />
                    </div>

                    {/* {otherchartfilters && (
                        <div className="ml-2 d-flex">
                            {t("recordsVisualization.DataFrom")}
                            <DatePicker
                                selected={dataDates.dateFrom}
                                onChange={(date) => setDataDates({ dateFrom: date, dateTo: dataDates.dateTo })}
                                className="form-control ml-2 mr-1"
                            />
                            -
                            <DatePicker
                                selected={dataDates.dateTo}
                                onChange={(date) => setDataDates({ dateFrom: dataDates.dateFrom, dateTo: date })}
                                className="form-control ml-2 mr-1"
                            />
                        </div>
                    )} */}

                    <TextField
                        select
                        size="small"
                        value={measureGroupedBy ?? "day"}
                        label={t("recordsVisualization.MeasureGroupByText")}
                        onChange={(e) => {
                            setUserData();
                            setMeasureGroupedBy(e.target.value);
                        }}
                        tw="width[10em]"
                    >
                        <MenuItem value="day">{t("recordsVisualization.GroupBy.Day")}</MenuItem>
                        <MenuItem value="hour">{t("recordsVisualization.GroupBy.Hour")}</MenuItem>
                        <MenuItem value="minute">{t("recordsVisualization.GroupBy.Minute")}</MenuItem>
                        <MenuItem value="second">{t("recordsVisualization.GroupBy.Second")}</MenuItem>
                    </TextField>

                    <TextField
                        size="small"
                        select
                        label={t("recordsVisualization.StateGroupByText")}
                        value={stateGroupedBy ?? "day"}
                        onChange={(e) => {
                            setUserData();
                            setStateGroupedBy(e.target.value);
                        }}
                        tw="width[8em]"
                    >
                        <MenuItem value="day">{t("recordsVisualization.GroupBy.Day")}</MenuItem>
                        <MenuItem value="hour">{t("recordsVisualization.GroupBy.Hour")}</MenuItem>
                        <MenuItem value="minute">{t("recordsVisualization.GroupBy.Minute")}</MenuItem>
                        <MenuItem value="second">{t("recordsVisualization.GroupBy.Second")}</MenuItem>
                    </TextField>

                    <div tw="justify-self-end">
                        <IconButton
                            onClick={(e) => {
                                setUserData();
                                getUserData();
                            }}
                        >
                            <RefreshIcon color="primary" />
                        </IconButton>
                    </div>
                </div>
                <div>
                    {chartGroup.visibleDaysCountValues.map((value, index) => {
                        return (
                            <DaysLink
                                key={index}
                                linkValue={value}
                                onClick={() => visibleLastDaysCountChanged(value)}
                                first={index === 0}
                                active={value === visibleLastDaysCount}
                            />
                        );
                    })}
                </div>
            </div>

            <div className={isCollapsed ? "collapse" : "chart-group__body"}>
                <div className="charts">
                    {(!userData || !isLoaded) && <CircularProgress />}
                    {userData && userData.recordValues.length === 0 && (
                        <div tw="pl-4">{t("recordsVisualization.NoRecordsFound")}</div>
                    )}
                    {((!initiateDate && ranges) || (initiateDate && userData && userData.recordValues.length > 0)) &&
                        charts.map((chart, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div tw="px-3.5 pb-3.5">
                                        {userData && (
                                            <Chart
                                                dataSource={userData.recordValues}
                                                adjustOnZoom={false}
                                                onLegendClick={(e) => handleLegendClick(e, index)}
                                                onOptionChanged={(e) => handleOptionChange(e, index)}
                                                onPointClick={(e) => handleSeriesClick(e)}
                                                stickyHovering={false}
                                            >
                                                <Animation enabled={false} />
                                                {/* <LoadingIndicator enabled={true} /> */}

                                                <Legend
                                                    visible={true}
                                                    verticalAlignment="top"
                                                    horizontalAlignment="center"
                                                    customizeItems={sortLegendItems}
                                                    markerTemplate={(item, group) => {
                                                        let marker = document.createElementNS(
                                                            "http://www.w3.org/2000/svg",
                                                            "path",
                                                        );
                                                        let background = document.createElementNS(
                                                            "http://www.w3.org/2000/svg",
                                                            "rect",
                                                        );
                                                        let color = item.series.isVisible() ? item.marker.fill : "#888";
                                                        background.setAttribute("fill", color);
                                                        background.setAttribute("opacity", 1);
                                                        background.setAttribute("width", 12);
                                                        background.setAttribute("height", 12);
                                                        background.setAttribute("x", 0);
                                                        background.setAttribute("y", 0);
                                                        group.appendChild(marker);
                                                        group.appendChild(background);
                                                    }}
                                                />
                                                <Size height={charts.length > 1 ? 400 : !isLiteMode ? 450 : 500} />
                                                <ArgumentAxis
                                                    argumentType="datetime"
                                                    hoverMode="none"
                                                    visualRange={ranges?.visualRange}
                                                    wholeRange={ranges?.wholeRange}
                                                >
                                                    {getTickInterval()}
                                                    {getConstantLines()}
                                                    <Label
                                                        wordWrap="none"
                                                        visible={true}
                                                        customizeText={argumentAxisCustomizeLabelText}
                                                    >
                                                        <Font family="Montserrat, sans-serif" size={10} />
                                                        <Format formatter={getDateTimeFormat} />
                                                    </Label>
                                                    <Tick visible={true} />
                                                </ArgumentAxis>

                                                <CommonSeriesSettings
                                                    argumentField="recordDate"
                                                    selectionMode="none"
                                                    hoverMode="none"
                                                    spline={{
                                                        hoverStyle: {
                                                            width: 2,
                                                        },
                                                        point: {
                                                            visible: true,
                                                            size: 3,
                                                        },
                                                    }}
                                                    line={{
                                                        hoverStyle: {
                                                            width: 2,
                                                        },
                                                        point: {
                                                            visible: true,
                                                            size: 3,
                                                        },
                                                    }}
                                                    splinearea={splineAreaSettings}
                                                    scatter={{
                                                        point: {
                                                            size: 7,
                                                            border: {
                                                                visible: true,
                                                                color: "white",
                                                            },
                                                        },
                                                    }}
                                                    steparea={{
                                                        //opacity: 1,
                                                        point: { visible: false },
                                                    }}
                                                >
                                                    {/* <Point size={7} hoverMode="none" visible={true} /> */}
                                                    <Border visible={false} />
                                                </CommonSeriesSettings>
                                                <CommonAxisSettings>
                                                    <Grid opacity="0.5" />
                                                </CommonAxisSettings>
                                                {getValueAxes(chart, index)}
                                                {getSeries(index)}

                                                <ZoomAndPan argumentAxis="both" />
                                                <Tooltip
                                                    enabled={true}
                                                    shared={true}
                                                    zIndex={100000000}
                                                    cornerRadius={5}
                                                    customizeTooltip={(x) => customizeTooltip(x, index)}
                                                />
                                            </Chart>
                                        )}
                                        {index > 0 && (
                                            <div className="remove-chart">
                                                <Button tw="font-semibold" onClick={() => onRemoveChart(index)}>
                                                    {t("recordsVisualization.RemoveChart")}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </React.Fragment>
                            );
                        })}

                    {!isLiteMode && (
                        <div tw="pt-5 pr-4 text-right">
                            <Button tw="font-semibold" onClick={(e) => onAddChart()}>
                                {t("recordsVisualization.AddChart")}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

const constantLines = [
    {
        value: 37,
        color: "#fc3535",
        dashStyle: "dash",
        width: 2,
        label: { visible: false },
    },
];

const splineAreaSettings = {
    opacity: 0.1,
    border: {
        dashStyle: "solid",
        width: 2,
        visible: true,
    },
    hoverStyle: {
        border: {
            dashStyle: "solid",
            width: 2,
            visible: true,
        },
    },
};

const scatterSettings = {
    // valueField: "scatterFixedValue",

    image: {
        url: FlagImage,
        width: 25,
        height: 25,
    },
};

const ecgSettings = {
    image: {
        url: EcgImage,
        width: 25,
        height: 25,
    },
};
