export function removeFromArray(array, o) {
    let index = array.findIndex((i) => i === o);
    if (index !== -1) array.splice(index, 1);
}

export function last(array) {
    return array && array.length ? array[array.length - 1] : null;
}

export function filter(array, filter, top = null) {
    let taken = 0;
    let result = [];

    for (let i = 0; i < array.length; i++) {
        let item = array[i];
        if (filter(item)) {
            result.push(item);
            taken++;
            if (top > 0 && taken === top) break;
        }
    }

    return result;
}
