import { useDispatch, useSelector } from "react-redux";
import { prescriptionChangeListTypeUrl } from "@profilog/prescriptions/redux/prescriptionSlice";
import { useScrollToTop } from "@profilog/commons/hooks/useScrollToTop";
import "twin.macro";
import { prescriptionTypesUrls, defaultPrescriptionTypeUrl } from "@profilog/prescriptions/codebooks/prescriptionTypes";
import { Navigate, useNavigate, useParams } from "react-router";
import Prescriptions from "@profilog/prescriptions/components/list/Prescriptions";
import AppSettings from "../../app/AppSettings";

export default function PrescriptionList() {
    useScrollToTop();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let listTypeUrl = useSelector((state) => state.prescriptions.listTypeUrl);

    const params = useParams();
    if (params.type) {
        const paramsTypeLower = params.type.toLowerCase();
        if (!prescriptionTypesUrls[paramsTypeLower]) return <NavigateDefault />;
        if (listTypeUrl !== params.type) {
            dispatch(prescriptionChangeListTypeUrl(paramsTypeLower));
            return null;
        }
    } else {
        if (listTypeUrl) return <Navigate to={listTypeUrl} replace />;
        return <NavigateDefault />;
    }

    return (
        <Prescriptions
            onListTypeChanged={(newTypeUrl) => navigate(getUrl(newTypeUrl))}
            showGroupName={AppSettings.isPatientPreview}
        />
    );
}

function NavigateDefault() {
    return <Navigate to={getUrl(defaultPrescriptionTypeUrl)} replace />;
}

function getUrl(typeUrl) {
    return (AppSettings.isMobileApp ? "/mobile-app" : "") + "/in/prescriptions/" + typeUrl;
}
