import { findSurveyComponentBySystemName } from "../surveysRegistry";

export default function SurveyDetail({ medicalSubjectId, recordId, surveySystemName }) {
    let SurveyComponent = findSurveyComponentBySystemName(surveySystemName);
    return SurveyComponent ? (
        <SurveyComponent medicalSubjectId={medicalSubjectId} recordId={recordId} readOnly />
    ) : (
        "Unsupported survey type"
    );
}
