import { css } from "twin.macro";

export const globalStyles = css`
    p {
        margin: 1em 0;
    }

    p:first-of-type {
        margin-top: 0;
    }

    h2 {
        font-size: 22px;
        font-weight: 500;
        margin: 0 0 1em 0;
    }

    h3 {
        font-size: 20px;
        font-weight: 500;
        margin: 1em 0;
    }

    h4 {
        font-size: 18px;
        font-weight: 500;
        margin: 1em 0;
    }

    ::-ms-reveal {
        display: none;
    }
`;
